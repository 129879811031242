import {combineReducers} from 'redux';
import deviceReducer from './subReducer/dev_rdc';
import userReducer from './subReducer/user_rdc';
import bdDevSumReducer from './subReducer/devSum_rdc';
import reactReducer from './subReducer/react_rdc';
import appReducer from './subReducer/app_rdc';
import dashReducer from './subReducer/dash_rdc';

export default combineReducers({
    users:userReducer,
    dev:deviceReducer,
    devSummary:bdDevSumReducer,
    react:reactReducer,
    dashboard:dashReducer,
    app:appReducer,
})