import React, { Component } from 'react';
import './TpOptionsList.css';

class TpOptionsList extends Component {
    state = { 
        bShowList:false,
        selItem:{
            name:'',
            _id:0,
        },
        displayOptions:[],
    }

    async componentDidMount() {    
        if(this.props.options)
            await this.setState({displayOptions:this.props.options});
        
        let bDefaulValueExist=false;    
        // let bNotEmpty=false;    
        if(this.props.defaultValue && Array.isArray(this.props.options)){
            for (const item of this.props.options) {
                // bNotEmpty=true;
                if(this.props.defaultValue === item._id){
                    await this.setState({selItem:item});
                    bDefaulValueExist=true;
                    break;
                }
            }
            /** Set Value to defaul 1 */
            if(!bDefaulValueExist && !this.props.placeholder){
                this.setState({selItem:this.props.options[0]});
                // console.log('Did Come In');
            }
        }
        
        // if(this.props.handleTpOptionDefaultValue){

            // this.props.handleTpOptionDefaultValue
            
            this.props.selOption(this.props.id, this.state.selItem);
            
        // }

    }

    selected=(c)=>{
        this.closeSel();
        this.props.selOption(this.props.id, c);
        this.setState({selItem:c});
    }

    dropdown=()=>{
        this.setState({bShowList:true});
    }

    closeSel=()=>{
        this.setState({bShowList:false});
    }

    render() { 
        let selItem = this.state.selItem;
        // console.log(this.state.selItem.name);
        // console.log(displayName);

        let state= this.state;


        
        return ( <>
            <span onClick={this.dropdown}>
                {selItem._id===0 && !this.props.placeholder ?"--":
                (this.props.placeholder && selItem._id===0 ? this.props.placeholder :selItem.name)}
            </span>
            {state.bShowList && <div className='selListPage'
                onClick={this.closeSel}>
                {/* {this.state.list.map(c=>( */}
                {this.state.displayOptions.map((c, ind)=>(
                    <div 
                        key={ind}
                        className='tpSelList'
                        onClick={()=>{this.selected(c)}}>
                        {c.name}
                    </div>
                ))}
            </div>}
        </> );
    }
}
 
export default TpOptionsList;