import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { F_RjNotSupportVersion } from '../RjFunction/RjFn';

function TpSubSubDiaVarChooseDev(props) {

    const [G_DevList, setG_DevList] = useState([]); 

    
    useEffect(()=>{
        async function startUp(){
            if (props.devList) setG_DevList(props.devList);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(varInfo)=>{
        if(varInfo.type>0 && varInfo.type<=5){
            return toast("Sensor Type not supported")
        }
        if(props.chooseDev) {
            props.chooseDev(varInfo);
        }
    }

    

    return (
        <div > 
            <div className='spring_subDialogBoxContainer' style={{zIndex:"200"}}>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_diaFixHeight'>
                        <div className='spring_ruleVarListContainer '>
                            {G_DevList.map((c, ind)=>
                                <div key={`varKey_${ind}`} className="spring_editVarBox hoverPointer wrapTextEllipsis divRelative"
                                onClick={()=>handleConfirm(c)}
                                >
                                    {F_RjNotSupportVersion(c.type) && <div className='redText divAbsolute spring_versionNotSupported'>Version not supported</div>}
                                    <div className={`${F_RjNotSupportVersion(c.type)?"":""}`}>{c.name}</div>
                                </div>
                            )} 
                        </div>          
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpSubSubDiaVarChooseDev;