import React, { Component } from 'react';

//<Tp_Input_titleAtBox id='SnInput' title='Serial No.' maxLen={40}/>

class TpInputNonTitle extends Component {

    clearErrMsg=()=>{
        // console.log("Focus");
        if(this.props.onChange) this.props.onChange();
        document.getElementById(`${this.props.errMsgid}`).innerHTML = "";
    }

    render() { 
        return <div>

            <div className='inputContainer_nonTitle'>
                <div>
                    <input 
                        onKeyDown={this.props.onKeyDown}
                        autoComplete="new-password" 
                        onBlur={this.props.onBlur} 
                        id={this.props.id} 
                        className="inputText_titleAtBox" 
                        onChange={this.clearErrMsg} 
                        maxLength={this.props.maxLen}
                    />                    
                </div>
                <div id = {this.props.errMsgid} className='inputErrMsg'></div>
            </div>

        </div>;
    }
}
 
export default TpInputNonTitle;