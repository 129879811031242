import React, { useEffect, useState } from 'react';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { CgMenuGridO } from "react-icons/cg";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import auth from '../../../service/auth/AuthV2';
import { toast } from 'react-toastify';
import TpManuFrame from './../Version2a/Template/Manu/TpManuFrame';
import TpDiaSetFavBd from './../Version2a/Template/DialogTemplate/TpDiaSetFavBd';
import { sortTemplate } from '../../../function/ArrayFn/arrayFn';
import timeFn from '../../../../src/function/time/timeFn';
import LegoLoader from '../Version2a/Template/LoaderTemplate/legoLoader';



function BuildingOverviewPg(props) {
    // eslint-disable-next-line
    // const [user_id, setUser_id] = useState(1);
    const [G_bLoaded, setG_bLoaded] = useState(false);
    const [G_loadBd, setG_LoadBd] = useState([]);
    const [displayBd, setDisplayBd] = useState([]);
    const [G_BdCount, setG_BdCount] = useState(0);
    const [G_ShowTp, setG_ShowTp] = useState(0);
    const [G_SelFavBd, setG_SelFavBd] = useState({});
    const [G_User_id, setG_User_id] = useState(0);
    const [G_FavList, setG_FavList] = useState([]);
    

    useEffect(()=>{
        async function startUp(){
            /** get own building and shared building under user */
            let {user_id} = auth.getUserInfo();
            let bdInvolve = await sensorOwnerFn.getRelatedBd(user_id);
            let favList = await sensorOwnerFn.getFavBdList(user_id);
            if(bdInvolve.errMsg) {
                setG_bLoaded(true);
                return toast(bdInvolve.errMsg);
            }
            setG_User_id(user_id);
            setG_FavList(favList);
            setG_BdCount(bdInvolve.length);

            let afterSort = sortBdByFav(bdInvolve, favList);
            setG_LoadBd(afterSort);
            setDisplayBd(afterSort);

            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const openManu=()=>{
        setG_ShowTp(1);
        // props.history.push({
        //     pathname: "/data/devoverview/v2amenu",
        //     // _data: {_selBuildingId: this.state.selBuildingId},
        // });
    }
    
    const handleSearch=()=>{
        let searchInput = document.getElementById("searchInput").value.trim();
        if(searchInput==="") {
            setDisplayBd(G_loadBd);
            return
        }
        let filtered = G_loadBd.filter((c)=>c.name.toUpperCase().search(searchInput.toUpperCase()) >= 0);
        
        setDisplayBd(filtered);
        
    }

    // eslint-disable-next-line
    const selBd=async (info)=>{
        await localStorageFn.setBdToken(info);
        props.history.push({
            pathname: "/data/floorinbd",
        });
    }

    const handleCloseManu=()=>{
        setG_ShowTp(0);
    }
    
    const handleTrigFav=(trig_bd)=>{
        setG_SelFavBd(trig_bd)
        setG_ShowTp(2);
    }
    
    const handleConfirmFav=async(trig_bd)=>{
        setG_ShowTp(0);
        if(!trig_bd._id || !trig_bd.sortIdx) return toast("Selected BD info err")
        /** update DB */

        let trigStatus = true;
        if(trig_bd.favUnix > 0) trigStatus = false;
        let trigFav = await sensorOwnerFn.trigFavBd(G_User_id, trig_bd._id, trigStatus);
        if(trigFav.errMsg) return toast(trigFav.errMsg);

        /** update local state */
        let _loadBd = [...G_loadBd];
        let idxPos = _loadBd.findIndex(c=>c._id === trig_bd._id);
        let _FavList = [...G_FavList];
        if(trigStatus)  {   // set to fav
            _loadBd[idxPos].favUnix = timeFn.getUnixNow();
            _FavList.push({user_id:G_User_id, bd_id:trig_bd._id, unix : timeFn.getUnixNow()});
        }
        else {
            _loadBd[idxPos].favUnix = 0;
            _FavList = _FavList.filter(c=>c.bd_id !== trig_bd._id);
        }
        
        setG_FavList(_FavList);

        let afterSort = sortBdByFav(_loadBd, _FavList);
        document.getElementById("searchInput").value = "";
        setG_LoadBd(afterSort);
        setDisplayBd(afterSort);
        /** sort local state */
    }

    const sortBdByFav=(bdList, _favList)=>{
        /** filter fav list */
        let favList = bdList.filter((mainObj) =>
            _favList.some((filterObj) => filterObj.bd_id === mainObj._id)
        );
        /** filter non fav list */
        let nonFavList = bdList.filter((mainObj) =>
            _favList.every((filterObj) => filterObj.bd_id !== mainObj._id)
        );
        // let favList =[];
        // let nonFavList =[];
        for (const eachFavBd of _favList) {
            let favBd = bdList.find(c=>c._id === eachFavBd.bd_id);
            favBd.favUnix = eachFavBd.unix;
        }

        let sortKeys = [
            { key: "favUnix", descOrder: true },
        ];
        favList.sort((a, b) => sortTemplate(a, b, sortKeys));

        sortKeys = [
            { key: "name", descOrder: false },
        ];
        nonFavList.sort((a, b) => sortTemplate(a, b, sortKeys));

        return [...favList, ...nonFavList];

    }


    return (
        <div>
            {G_ShowTp===1 && <TpManuFrame entryFrom ={"Bd"} onclickClose={handleCloseManu}/>}
            {G_ShowTp===2 && <TpDiaSetFavBd 
                bdSel = {G_SelFavBd} 
                onclickClose={handleCloseManu}
                confirmBdFav={handleConfirmFav}/>}

            <div className='headerContainer'>
                <div className ='searchBarContainer' style={{marginRight: "10px"}}>
                        <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                        <div className='searchButton'>
                            <i className='fa fa-search'></i>
                        </div>    
                </div>
                <div onClick={openManu} className='headerIcon'>
                    <CgMenuGridO/>
                </div>
            </div>

            <div className='devOvvBdTitleBar'>
                <div className='spring_OvvTitleName' >
                    <div className='wrapTextEllipsis devOvvBdName'>Building List</div>
                </div>
            </div>
            <div className='devCount sortMiddle'>
                {G_BdCount > 1 ?`${G_BdCount} Buildings`:`${G_BdCount} Building`}
            </div>

            <div className='spring_BdContainer'>
                {displayBd.length > 0 && G_bLoaded && displayBd.map((u, ind)=>(
                    <div className='spring_BdFrame sortHor' key={`dev_${ind}`}>
                        <div className='spring_floorBigText spring_width_80_per hoverPointer'
                        onClick={()=>selBd(u)}>{u.name}</div>
                        <div className='flexAndBothCenter stayRight text_1_1 hoverPointer'
                        onClick={()=>handleTrigFav(u)}>
                            {u.favUnix > 0? <AiFillStar className='yellowText'/> : <AiOutlineStar className='  '/>}
                        </div>
                    </div>
                ))}
            </div>
            {!G_bLoaded && <LegoLoader />}  
            {/* {!G_bLoaded && <div>Loading Select Building Page...</div>} */}
        </div>
    );
}

export default BuildingOverviewPg;

