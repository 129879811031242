import React from 'react'
import C0003BarInstant from './ComponentTemplate/Common/C0003BarInstant'
import C0004GroupStatus from './ComponentTemplate/Common/C0004GroupStatus'
import C0005BarDaily from './ComponentTemplate/Common/C0005BarDaily'
import C0006MultiBarDaily from './ComponentTemplate/Common/C0006MultiBarDaily'

const DashList = (props) => {


    const dashItemList = [
        {componentType: 3, tp:<C0003BarInstant {...props}/>},
        {componentType: 4, tp:<C0004GroupStatus {...props}/>},
        {componentType: 5, tp:<C0005BarDaily {...props}/>},
        {componentType: 6, tp:<C0006MultiBarDaily {...props}/>},
        // 
    ]

    const F_GetRespectiveTp=(compType)=>{        
        let matchTp =  dashItemList.find(c=>c.componentType === compType);
        if(matchTp) return matchTp.tp;
        return <div>No Match</div>
    }


  return (
    <div>
        {F_GetRespectiveTp(props.componentType)}
    </div>
  )
}

export default DashList