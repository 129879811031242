import React, { useEffect, useRef, useState } from 'react';
// import { IoIosArrowBack} from "react-icons/io";
import { FiTrash2} from "react-icons/fi";
import TpDiaChooseDevice from './TpDiaChooseDevice';
import localStorageFn  from '../../../../../function/localStorage/LocalStorageFn';
import v2OwnDevFn from '../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { sortTemplate } from '../../../../../function/ArrayFn/arrayFn';
import TpSubDiaChooseFloor from '../Subdialog/TpSubDiaChooseFloor';
import TpSubDiaChooseArea from '../Subdialog/TpSubDiaChooseArea';
// import { toast } from 'react-toastify';
import LegoLoader from '../LoaderTemplate/legoLoader';
import auth from '../../../../../service/auth/AuthV2';
import v2aComFn from '../../ComFn/V2aComOwnerDevFn';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedList, setNewDevSelList } from '../../../../../reduxStore/actions/dev_selHistoryTrend';

function TpDiaSelectDev(props) {
    const dispatch = useDispatch();  
    const Grx_selectedList = useSelector(getSelectedList);

    const [G_bdDevList_display, setG_bdDevList_display] = useState([]); 
    
    const [G_bdDevList_toDia, setG_bdDevList_toDia] = useState([]); 
    const [G_floorList_toDia, setG_floorList_toDia] = useState([]); 
    const [G_areaList_toDia, setG_areaList_toDia] = useState([]); 
    const [G_bdDevList, setG_bdDevList] = useState([]); 
    
    const [G_MoveToFloor, setG_MoveToFloor] = useState({floor_id:0, floorName:"Others"}); 
    // eslint-disable-next-line
    const [G_MoveToArea, setG_MoveToArea] = useState({area_id:0, areaName:"Others"}); 
    const [G_ShowTp, setG_ShowTp] = useState(0); 
    const [G_bLoaded, setG_bLoaded] = useState(false); 

    const [G_paraList, setG_paraList] = useState([]);
    const [G_sensorDetailsList, setG_sensorDetailsList] = useState([]);
    
    const refManuLevel = useRef(0);

    useEffect(()=>{
        async function startUp(){
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;
            
            let bdInfo  = localStorageFn.getBdToken();
            let userInfo = auth.getUserInfo();

            /** get device in Bd */
            let _devInBd = await v2aComFn.getDevInBd(bdInfo._id);   
            /** filter display dev base on user access level */
            let devList = await v2aComFn.filterDevForSharedUser(bdInfo, userInfo, _devInBd);
            devList = sortArr(devList);            
            setG_bdDevList(devList);

            /** load floor in bd */
            let _floorList = await v2OwnDevFn.getRelatedFloor(bdInfo._id);
            
            /** filter floor with device */
            let floorList = v2aComFn.filterFloorWithDev(_floorList, devList);            
            setG_floorList_toDia(floorList);
            
            /** load area in bd */
            let _allAreaList = await v2OwnDevFn.v2aGetAllAreaUnderBd(bdInfo._id);
            /** filter area with device */
            let allAreaList = v2aComFn.filterAreaWithDev(_allAreaList, devList);
            setG_areaList_toDia(allAreaList);

            /** show selected sensor list */
            let curSelectedList = F_InitShowSelectedSensor(devList);
            setG_bdDevList_display(curSelectedList);


            let _tyList = await v2aComFn.getTyList(devList);
            let _paraList = await v2aComFn.getSensorParaList(_tyList);
            setG_paraList(_paraList);

            /** getSensorList */
            let sensorList = await v2aComFn.getSensorList(_tyList); 
            setG_sensorDetailsList(sensorList);


            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const F_InitShowSelectedSensor=(devList)=>{     
        let _selectedList = JSON.parse(JSON.stringify(Grx_selectedList));
        let selectedList = [];
        for (const eachList of devList) {
            let found = _selectedList.find(c=>c.ht === eachList.type && c.bdDev_id === eachList._id);
            if(found) {
                eachList.bChecked = true;
                selectedList.push(eachList);
            }
        }
        return selectedList;
    }

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "name", descOrder: false },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const backPrevPg=()=>{
        if(props.hanleCloseDia) props.hanleCloseDia();
    }
    const callAddDev=()=>{
        setG_bdDevList_toDia(G_bdDevList);
        setG_ShowTp(1);
    }
    const handleCloseDiaChoose=()=>{
        setG_ShowTp(0);

    }
    const handleConfirmDevList=(dbList)=>{
        setG_ShowTp(0);
        setG_bdDevList(dbList);
        updateDisplayTable(dbList);
    }

    const updateDisplayTable=(dbList)=>{
        let _displayArr = [];
        for (const eachDev of dbList) {
            if(eachDev.bChecked) _displayArr.push(eachDev);
        }
        setG_bdDevList_display(_displayArr);
    }

    const handleRemove=(devInfo)=>{
        /** update table display */
        let _bdDevList_display = G_bdDevList_display.filter(c=>c._id!==devInfo._id);
        setG_bdDevList_display(_bdDevList_display);

        /** update DB */
        let idx = G_bdDevList.findIndex(c=>c._id===devInfo._id);
        let _bdDevList = [...G_bdDevList];
        _bdDevList[idx].bChecked = false;
        setG_bdDevList(_bdDevList);
    }
    // const handleChooseFloor=()=>{
    //     setG_ShowTp(2);
    // }

    const handleConfirmChooseFloor=(devInfo)=>{
        setG_MoveToArea({area_id:0, areaName:"Others"});
        setG_MoveToFloor(devInfo[0]);
        setG_ShowTp(0);
    }
    
    // const handleChooseArea=()=>{
    //     setG_ShowTp(3);
    // }

    const handleConfirmChooseArea=(devInfo)=>{
        setG_MoveToArea(devInfo[0]);
        setG_ShowTp(0);
    }

    const handleMove=async ()=>{    
        /** generate device list ???*/
        // let rx_selectedList = JSON.parse(JSON.stringify(Grx_selectedList));
        // let _bdDevList_display = JSON.parse(JSON.stringify(G_bdDevList_display));
        
        /** filter remaining rdx */
        let rx_remainList = [];
        for (const eachRdx of Grx_selectedList) {
            let found = G_bdDevList_display.find(c=>c.type===eachRdx.ht && c._id === eachRdx.bdDev_id);
            if(found)   rx_remainList.push(eachRdx);
        }


        let devList = [];
        for (const eachDev of G_bdDevList_display) {
            /** get new added device */
            let found = rx_remainList.find(c=>c.ht === eachDev.type && c.bdDev_id === eachDev._id);
            if(!found){
                let a_para = G_paraList.filter(c=>c.sensorType === eachDev.type)
                let a_sensorDetails = G_sensorDetailsList.filter(c=>c.type === eachDev.type)            
                devList.push({
                    ht:eachDev.type,
                    bdDev_id:eachDev._id,
                    dev:eachDev,
                    a_para, 
                    a_sensorDetails,
                });
            }
        }

        dispatch(setNewDevSelList([...rx_remainList, ...devList]));
        // dispatch(setNewDevSelList(devList));
        backPrevPg();
    }
    

    return (
        <div className='spring_dialogBoxContainer'>
            <div className='spring_manuContainer'></div>
            <div className=' spring_dialogBox spring_DiaSelDev'>
                
                {!G_bLoaded && <LegoLoader />}
                {/* {!G_bLoaded && <div>Loading...</div>} */}
                {G_bLoaded && <div className='spring_DiaSelDev_MainContainer'>
                    {G_ShowTp===1 && <TpDiaChooseDevice
                        onclickClose = {handleCloseDiaChoose}
                        onclickConfirm = {handleConfirmDevList}
                        bdDevList = {G_bdDevList_toDia}
                        floorList = {G_floorList_toDia}
                        areaList = {G_areaList_toDia}
                        />}

                    {G_ShowTp ===2 && <TpSubDiaChooseFloor
                        onclickClose = {handleCloseDiaChoose}
                        onclickConfirm = {handleConfirmChooseFloor}
                        skipShowAll = {true}
                        devInBd={G_bdDevList}
                        floorInBd={G_floorList_toDia}
                        />}
                        
                    {G_ShowTp ===3 && <TpSubDiaChooseArea
                        onclickClose = {handleCloseDiaChoose}
                        selectedFloor = {G_MoveToFloor}
                        onclickConfirm = {handleConfirmChooseArea}
                        skipShowAll = {true}           
                        devInBd={G_bdDevList}
                        floorInBd={G_floorList_toDia}
                        areaInDb={G_areaList_toDia}
                        />}
                    
                    <div className='boldText spring_DiaSelDev_Title'>{`Device(s)`}</div>

                    {/* <div className='spring_inputBox spring_listContainer'> */}
                    <div className='spring_DiaSelDev_DevList'>
                        {G_bdDevList_display.map((u, ind)=>(<div key={`dev_${ind}`} >
                            {ind!==0 && <div className='divSeperateLine'></div>}
                            <div className='sortHor spring_TableItemSpace'>
                                <div className='wrapTextEllipsis spring_listContainerItem'>{`[${u.devID}] ${u.name}`}</div>
                                <div className='stayRight flexAndBothCenter  hoverPointer square_25'
                                onClick={()=>handleRemove(u)}>
                                    <FiTrash2 className='redText'/>
                                </div>
                            </div>
                        </div>))}   
                        
                        <div className={`whiteText greenBgc spring_containerAddDev ${G_bdDevList_display.length > 0 ?"spring_addDevTopBorder" : "spring_addDevAllBorder"} hoverPointer`}
                        onClick={callAddDev}> + Select device(s)</div>

                    </div>

                    <div className='sortHor spreadBetween spring_DiaSelDev_ButtonBar'>
                        <div className="spring_roundButton spring_greyColor hoverPointer"
                            onClick={backPrevPg}
                            >Back</div>
                        <div className="roundButton " 
                            onClick={handleMove}
                            >Confirm</div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default TpDiaSelectDev;