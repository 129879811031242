

function validateEmail(email){      
    // console.log(email);
    // eslint-disable-next-line
    let mailFormat =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return (mailFormat.test(email));
    
}



const expModule = {  
    validateEmail,
};
export default expModule;