import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function InputTitleAtBoxShowHideIcon(props) {
    const clearErrMsg=()=>{
        // console.log("Focus");
        document.getElementById(`${props.errMsgid}`).innerHTML = "";
    }

    const showHideInput = () => {
        if(props.showHideInput) props.showHideInput();
    }

    return ( 
        <div>
            <div className='inputContainer_titleAtBox'>
                <div className="inputTitle_titleAtBox">
                    {props.title}
                </div>
                <div className='sortHor'>
                    <input autoComplete="off" 
                        onBlur={props.onBlur} 
                        id={props.id} 
                        className="inputText_titleAtBox" 
                        type={props.type}
                        onChange={clearErrMsg} 
                        maxLength={props.maxLen}/>
                    <div className='spring_marginLeft_n30' onClick={showHideInput}>{props.showInput? <FaEyeSlash /> : <FaEye />}</div>
                </div>
                <div id = {props.errMsgid} className='inputErrMsg'></div>
            </div>

        </div>
     );
}

export default InputTitleAtBoxShowHideIcon;