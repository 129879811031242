import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { FiPlus} from "react-icons/fi";
import { ImCancelCircle} from "react-icons/im";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { toast } from 'react-toastify';
import loraFn from '../../../function/genLoRaPackage';
import ctrlDevFn from '../../../function/DeviceControl/ctrlDevFn';
import socketIOClient from "socket.io-client";
import { notEmptyArr } from '../../../function/ArrayFn/arrayFn';

function ControlTemplate(props) {
    const mqttBroker = ["CloudMQTT", "AWS MQTT"];
    const cmdTimeout = 10000;    // 5 sec
    
    const [G_mqttSel, setG_mqttSel] = useState(1);
    const [G_pbList, setG_pbList] = useState([]);
    const [G_pfList, setG_pfList] = useState([]);
    const [G_piList, setG_piList] = useState([]);
    const [G_pnList, setG_pnList] = useState([]);
    
    const [G_Sending, setG_Sending] = useState(false);
    const [G_CusTopic, setG_CusTopic] = useState(false);
    
    const timeoutRef = useRef(null);
    const refCmdLog = useRef({});


    useEffect(()=>{ 
        let socket;
        async function startUp(){
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);
            
            /** trigger read cycle if data is valid */
            
            // let topic = `v2_CtrlCmd`;
            // let topic = `v2_CtrlCmd_${dev.type}_${dev.devID}`;
            let topic = `v2_CtrlCmd_59_1`;
            socket.on(topic, data => {
                validateNodeReply(data);
                console.log("data :", data);
                
            });
        }
        startUp();

        
        return ()=>{
            // console.log("Graph Clear");
            clearTimeout(timeoutRef.current);
            socket.disconnect();
        }
        // eslint-disable-next-line
    }, []);

    const backPrevPg=()=>{
        props.history.push({pathname: "/reaction/action"});
    }

    const validateNodeReply=(mqttMsg)=>{
        try {
            if(!mqttMsg.ht) return 
            if(!mqttMsg.hi) return 
            if(!mqttMsg.hf) return 
            if(!mqttMsg.hct) return 
            if(!mqttMsg.ft) return 
            
            // console.log("mqttMsg.ht :", mqttMsg.ht);
            // console.log("mqttMsg.hi :", mqttMsg.hi);
            // console.log("mqttMsg.hf :", mqttMsg.hf);
            // console.log("mqttMsg.hct :", mqttMsg.hct);
            // console.log("mqttMsg.ft :", mqttMsg.ft);
            
            let _G_CmdLog = {...refCmdLog.current};
            // console.log("G_CmdLog", _G_CmdLog);
            if(_G_CmdLog.ht === mqttMsg.ht && _G_CmdLog.hi === mqttMsg.hi &&
                _G_CmdLog.hf === mqttMsg.hf && _G_CmdLog.hct === mqttMsg.hct &&
                _G_CmdLog.ft === mqttMsg.ft) {
                    /** Command Sent Success  */
                    clearTimeout(timeoutRef.current);
                    setG_Sending(false);
                    return toast("Success");
                }
                 
        } catch (error) {
            toast(error.message);
            return 
        }
    }

    const fCmdTimeout=async()=>{
        
        // console.log("G_CmdLog", refCmdLog.current);
        let cmdLog = await ctrlDevFn.v2getCmdLog(refCmdLog.current);
        if(notEmptyArr(cmdLog)){
            if(cmdLog[0].GwAck ===0 && cmdLog[0].NodeAck === 0){
                toast('Gateway Not Response');
            }else if(cmdLog[0].NodeAck === 0){
                toast('Node Not Response');
            }
        }
        setG_Sending(false);
        // console.log("cmdLog : ", cmdLog);
    }
    
    const fLimitRange=(min, max, inputId)=>{
        let inVal = parseInt(document.getElementById(inputId).value);
        if(inVal > max) inVal = max;
        if(inVal < min) inVal = min;
        document.getElementById(inputId).value = inVal;
    }
    
    const fNonDecimal=(inputId)=>{
        let inVal = parseInt(document.getElementById(inputId).value);
        document.getElementById(inputId).value = inVal;
    }
    const handle_toggelPb=(ind)=>{
        let _pbList= [...G_pbList];
        _pbList[ind]=!G_pbList[ind];
        setG_pbList(_pbList);
    }
    const handleAddPb=()=>{
        let _pbList= [...G_pbList];
        _pbList.push(false);
        setG_pbList(_pbList);
    }
    const handleAddPf=()=>{
        let _pxList= [...G_pfList];
        _pxList.push(0);
        setG_pfList(_pxList);
    }
    const handleAddPi=()=>{
        let _pxList= [...G_piList];
        _pxList.push(0);
        setG_piList(_pxList);
    }
    const handleAddPn=()=>{
        let _pxList= [...G_pnList];
        _pxList.push(0);
        setG_pnList(_pxList);
    }

    const handle_delPb=(ind)=>{
        let _pbList= [...G_pbList];
        _pbList.splice(ind, 1);
        setG_pbList(_pbList);
    }
    const handle_delPf=(ind)=>{
        let _pxList=[];
        for (let i = 0; i < G_pfList.length; i++) {            
            _pxList.push(parseFloat(document.getElementById(`in_pf_${i}`).value));
        }
        _pxList.splice(ind, 1);
        setG_pfList(_pxList);
        updatePxInputDisplay(_pxList, "f");
    }
    const handle_delPi=(ind)=>{
        let _pxList=[];
        for (let i = 0; i < G_piList.length; i++) {            
            _pxList.push(parseInt(document.getElementById(`in_pi_${i}`).value));
        }
        _pxList.splice(ind, 1);
        setG_piList(_pxList);
        updatePxInputDisplay(_pxList, "i");
    }
    const handle_delPn=(ind)=>{
        let _pxList=[];
        for (let i = 0; i < G_pnList.length; i++) {            
            _pxList.push(parseInt(document.getElementById(`in_pn_${i}`).value));
        }
        _pxList.splice(ind, 1);
        setG_pnList(_pxList);
        updatePxInputDisplay(_pxList, "n");
    }

    const getPxInput=(GpxLen, dataSymbol)=>{
        try {            
            let _pxList=[];
            for (let i = 0; i < GpxLen; i++) {            
                _pxList.push(parseFloat(document.getElementById(`in_p${dataSymbol}_${i}`).value));
            }
            return _pxList
        } catch (error) {
            console.log("getPxInput err:", error.message);
            return {err:true};
        }
    }

    
    const updatePxInputDisplay=(pxArr, dataSymbol)=>{
        // console.log("pxArr", pxArr);
        for (let i = 0; i < pxArr.length; i++) {
            document.getElementById(`in_p${dataSymbol}_${i}`).value = pxArr[i];
        }
    }
    const handleSelBroker=()=>{
        let curBrokerIdx = G_mqttSel;
        curBrokerIdx++;
        if(curBrokerIdx>mqttBroker.length-1) curBrokerIdx=0;
        setG_mqttSel(curBrokerIdx);
    }

        
    const hanldeSend=async ()=>{
        try {
            if(G_Sending) return;
            // console.log("Proceed send");
            let gwId;
            let topic;

            if (!G_CusTopic){
                gwId = parseInt(document.getElementById("in_gwId").value);
                if(!gwId) return toast(`Gateway ID cannot be empty`);   
                topic=`Gw/ServerCmd/${gwId}`;
            }else{
                topic = document.getElementById("in_topic").value.trim();
                if(!topic) return toast(`Topic cannot be empty`);
            }

            let nodeType = parseInt(document.getElementById("in_nodeType").value);
            if(!nodeType) return toast(`Node ID cannot be empty`);

            let nodeId = parseInt(document.getElementById("in_nodeId").value);
            if(!nodeId) return toast(`Node ID cannot be empty`);
            

            let dir = parseInt(document.getElementById("in_Dir").value);
            if(!dir) return toast(`Direction cannot be empty`);

            let fn = parseInt(document.getElementById("in_fn").value);
            if(!fn) return toast(`Function cannot be empty`);

            let devDetails={
                devType:nodeType,
                id:nodeId,
                dir,
                fun:fn
            }

            /** update payload data */
            // let _pbList = getPxInput(G_pbList.length, "b");
            let _pfList = getPxInput(G_pfList.length, "f");
            if(_pfList.err) return toast(`Pf data invalid`);

            let _piList = getPxInput(G_piList.length, "i");
            if(_piList.err) return toast(`Pi data invalid`);

            let _pnList = getPxInput(G_pnList.length, "n");
            if(_pnList.err) return toast(`Pn data invalid`);
            
            let payload ={
                pb:G_pbList,
                pf:_pfList,
                pi:_piList,
                pn:_pnList
            }
            
            let loraPackage = loraFn.genLoRaPackage(devDetails, payload, 2);
            if(loraPackage.error) return toast(`Generate data package error`);

            /** insert gw id */
            loraPackage.gwid = gwId;
            refCmdLog.current = {...loraPackage};
            
            setG_Sending(true);

            // console.log("topic:", topic);
            let sendCmdRel = await ctrlDevFn.v2sendCtrlCmd(topic, loraPackage, G_mqttSel);  // 0 kaola mqtt, 1 aws mqtt
            // console.log("sendCmdRel", sendCmdRel);
            if(G_CusTopic){     // skip checking if custome title
                setG_Sending(false);
                return 
            }
            if(!sendCmdRel.success) {      
                setG_Sending(false);
                return 
            }

            timeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
            
        } catch (error) {
            toast(`Send Cmd Err: ${error.message}`);
            setG_Sending(false);
        } 
    }

    
    return (
        <div>             
            <div className="backContainer">
                <div className="backIconContainer" onClick={()=>backPrevPg()}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Control Template</div>
            </div>
            
            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">MQTT</div>
                <div
                className="flex_1 flexAndBothCenter hoverPointer"
                >
                </div>
            </div>            
            
            <div className={`contentCard`}>
                <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width70px">
                            {`URL :`}
                        </div>
                        <div className="flex_2 hoverPointer"
                        onClick={handleSelBroker}>
                            <div className='trueFalseButton flexAndBothCenter  healthBtnBgc'>
                            {mqttBroker[G_mqttSel]}</div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">Custome Topic</div>
                <div
                className="flex_1 flexAndBothCenter hoverPointer"
                // onClick={handleToggleDesc}
                onClick={() => setG_CusTopic(!G_CusTopic)}
                >
                {!G_CusTopic && (
                    <BsToggleOff className="reactCfgToggleButton reactCfgButtonOff" />
                )}
                {G_CusTopic && (
                    <BsToggleOn className="reactCfgToggleButton reactCfgButtonOn" />
                )}
                </div>
            </div>
            
            {G_CusTopic && <div className={`contentCard`}>
                
                <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width70px">
                            {`Topic :`}
                        </div>
                        <div className="flex_2">
                            <input
                                className=" i_time_contentInput"
                                id="in_topic"
                            ></input>
                        </div>
                    </div>
                </div>
            </div>}
            
            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">Device</div>
                <div className="flex_1 flexAndBothCenter hoverPointer">
                </div>
            </div>

            <div className={`contentCard`}>
                {!G_CusTopic && <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width100px">
                        {`Gateway ID :`}
                        </div>
                        <div className="flex_2">
                            <input
                                type={"number"}
                                className=" i_time_contentInput hideNumberUpDown"
                                id="in_gwId"
                            ></input>
                        </div>
                    </div>
                </div>}
                {!G_CusTopic && <div className="divSeperateLine "></div>}
                
                <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width100px">
                        {`Node Type :`}
                        </div>
                        <div className="flex_2">
                            <input
                                type={"number"}
                                className=" i_time_contentInput hideNumberUpDown"
                                id="in_nodeType"
                            ></input>
                        </div>
                    </div>
                </div>

                <div className="divSeperateLine "></div>
                
                <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width100px">
                        {`Node ID :`}
                        </div>
                        <div className="flex_2">
                            <input
                                type={"number"}
                                className=" i_time_contentInput hideNumberUpDown"
                                id="in_nodeId"
                            ></input>
                        </div>
                    </div>
                </div>

                <div className="divSeperateLine "></div>
                <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width100px">
                        {`Direction :`}
                        </div>
                        <div className="flex_2">
                            <input
                                type={"number"}
                                className=" i_time_contentInput hideNumberUpDown"
                                id="in_Dir"
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="divSeperateLine "></div>
                <div className={`contentCardPadding`}>
                    <div className="sortHor">
                        <div className="rCfg_Width100px">
                        {`Function :`}
                        </div>
                        <div className="flex_2">
                            <input
                                type={"number"}
                                className=" i_time_contentInput hideNumberUpDown"
                                id="in_fn"
                            ></input>
                        </div>
                    </div>
                </div>
            </div>            
            
            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">Pb</div>
                <div
                className="flex_1 flexAndBothCenter hoverPointer"
                onClick={handleAddPb}
                >
                    <FiPlus/>
                </div>
            </div>
            {G_pbList.length > 0 && <div className={`contentCard`}>
                {G_pbList.map((c,ind)=>
                    <div key={`pbList_${ind}`}> 
                        {ind!==0 && <div className="divSeperateLine "></div>}
                        <div className={`contentCardPadding`}>
                            <div className="sortHor">     
                                <div className=' errColor hoverPointer flexAndBothCenter marginR_10px paddingR_10px'
                                    onClick={()=>handle_delPb(ind)}
                                    >
                                        <ImCancelCircle/>
                                </div>
                                <div className="rCfg_Width70px">
                                {`Pb[${ind}] :`}
                                </div>
                                <div className="hoverPointer"
                                onClick={()=>handle_toggelPb(ind)}>
                                    {c===true?
                                        <div className='trueFalseButton flexAndBothCenter  healthBtnBgc'>
                                            true</div>:
                                        <div className='trueFalseButton flexAndBothCenter  errBtnBgc'>
                                            false</div>
                                    }                                
                                </div>      
                            </div>
                        </div>
                    </div>
                )}                
            </div>    }

            
            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">Pf</div>
                <div className="flex_1 flexAndBothCenter hoverPointer"
                onClick={handleAddPf}>
                    <FiPlus/>
                </div>
            </div>
            {G_pfList.length>0 && <div className={`contentCard`}>
                {G_pfList.map((c,ind)=>
                    <div key={`pf_${ind}`}>
                        {ind!==0 && <div className="divSeperateLine "></div>}
                        <div className={`contentCardPadding`}>
                            <div className="sortHor">
                                <div className=' errColor hoverPointer flexAndBothCenter marginR_10px paddingR_10px'
                                    onClick={()=>handle_delPf(ind)}
                                    >
                                        <ImCancelCircle/>
                                </div>
                                <div className="rCfg_Width70px">
                                {`Pf[${ind}] :`}
                                </div>
                                <div className="flex_2">
                                    <input
                                        defaultValue={c}
                                        type="number"
                                        className=" i_time_contentInput hideNumberUpDown"
                                        id={`in_pf_${ind}`}
                                        // onBlur={()=>handleUpdateG_pf(ind)}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>   } 
            
            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">Pi</div>
                <div
                className="flex_1 flexAndBothCenter hoverPointer"
                onClick={handleAddPi}>
                    <FiPlus/>
                </div>
            </div>
            {G_piList.length >0 && <div className={`contentCard`}>
                {G_piList.map((c,ind)=>
                    <div key={`pi_${ind}`}>
                        {ind !== 0 && <div className="divSeperateLine "></div>}
                        <div className={`contentCardPadding`}>
                            <div className="sortHor">
                                <div className=' errColor hoverPointer flexAndBothCenter marginR_10px paddingR_10px'
                                    onClick={()=>handle_delPi(ind)}
                                    >
                                        <ImCancelCircle/>
                                </div>
                                <div className="rCfg_Width70px">
                                {`Pi[${ind}] :`}
                                </div>
                                <div className="flex_2">
                                    <input
                                        defaultValue={c}
                                        type="number"
                                        className=" i_time_contentInput hideNumberUpDown"
                                        id={`in_pi_${ind}`}
                                        onBlur={()=>fLimitRange(-2147483648, 4294967295, `in_pi_${ind}`)}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
            </div>    }

            <div className="sortHor reactCfgCardTitle_out">
                <div className="flex_9">Pn</div>
                <div
                className="flex_1 flexAndBothCenter hoverPointer"
                onClick={handleAddPn}>
                    <FiPlus/>
                </div>
            </div>
            {G_pnList.length >0 && <div className={`contentCard`}>
                {G_pnList.map((c,ind)=>
                    <div key={`pn_${ind}`}>
                        {ind !==0 && <div className="divSeperateLine "></div>}                            
                        <div className={`contentCardPadding`}>
                            <div className="sortHor">
                                <div className=' errColor hoverPointer flexAndBothCenter marginR_10px paddingR_10px'
                                    onClick={()=>handle_delPn(ind)}
                                    >
                                        <ImCancelCircle/>
                                </div>
                                <div className="rCfg_Width70px">
                                {`Pn[${ind}] :`}
                                </div>
                                <div className="flex_2">
                                    <input
                                        defaultValue={c}
                                        type="number"
                                        className=" i_time_contentInput hideNumberUpDown"
                                        id={`in_pn_${ind}`}
                                        onBlur={()=>fNonDecimal(`in_pn_${ind}`)}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>        
                )}        
            </div>  }  

            <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" onClick={hanldeSend}>{G_Sending?"Sending...":"Send"}</div>
            </div>
            
        </div>
    );
}

export default ControlTemplate;
