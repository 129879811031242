import React, { useEffect } from 'react';
import { BsQuestionLg} from "react-icons/bs";

function TpDiaInfo(props) {


    useEffect(()=>{
        async function startUp(){
            
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>
                
                <div className=' spring_dialogBox '>
                    <div style={{marginTop:"-30px"}}>
                        <div className='spring_diaIconFrame spring_shadowBox spring_greenGrdBgc'>
                            {/* <div style={{marginLeft:"-30px"}}> */}
                                <BsQuestionLg style={{marginLeft:"0px"}} className=''/>
                            {/* </div> */}
                        </div>
                    </div>

                    <div className='spring_infoTitle'>Clean</div>
                    <div className='spring_infoContent'>When trigger OFF with clean function, Roger Jr. will trigger cleaning action for 5 mins, and turn off Air-Con automatically after cleaning.</div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>


            </div>
        </div>
    );
}

export default TpDiaInfo;