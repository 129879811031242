import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line
import { createSelector } from 'reselect';

const initState={
    totalDevCnt:0,
    healthyCnt:0,
    offlineCnt:0,
    offlineDev:[],
    // offlineDev:[{pos:'1.1', name:'Outdoor'}];
}

const slice = createSlice({
    name:'currBdSummary',
    initialState:{...initState},
    reducers:{        
        setTotalDevCnt:(state, action)=>{    
            state.totalDevCnt = action.payload.totalDevCnt;
        },
        markSensorOffline:(state, action)=>{            
            let foundSensor = state.offlineDev.find(c=> c.pos === action.payload.pos);
            /** offline dev not in list, add to list */
            if(!foundSensor)state.offlineDev.push({pos:action.payload.pos, name:action.payload.name});
            state.offlineCnt = state.offlineDev.length;
            state.healthyCnt = state.totalDevCnt-state.offlineCnt;
        },

        markSensorOnline:(state, action)=>{            
            let foundSensor = state.offlineDev.findIndex(c=> c.pos === action.payload.pos);
            /** found in list, and remove from list */
            if(foundSensor>=0)  state.offlineDev.splice(foundSensor, 1);
            state.offlineCnt = state.offlineDev.length;
            state.healthyCnt = state.totalDevCnt-state.offlineCnt;
        },
    }
})

/** Export Action */
export const {
    setTotalDevCnt,
    markSensorOffline,
    markSensorOnline
} = slice.actions;


/** reducer */
export default slice.reducer;


/** Action Creator, call action */


/** Selector, read from store */
// export const getRegDevInfo = createSelector(
//     state=> state.dev.regSensor,
//     ({SerialNo, RegCode, type, devID}) => ({SerialNo, RegCode, type, devID})
// )