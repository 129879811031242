

function cvrtSnrRssiToSPer(snr){
    let signalPer = (snr/8)*100;
    if(signalPer > 100) return 100;
    if(signalPer < 15) return 15;
    return (signalPer).toFixed(0);
}

function cvrtBattToSPer(batt, min, max){
    // console.log(batt);
    // console.log(max);
    // console.log(min);
    if(max-min===0) return 0
    if(batt>max) return 100;
    if(batt<min) return 1;
    return (((batt-min)/(max-min))*100).toFixed(0);
}

exports.cvrtBattToSPer=cvrtBattToSPer;
exports.cvrtSnrRssiToSPer=cvrtSnrRssiToSPer;