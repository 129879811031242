import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import v2RjFn from "../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import { toast } from 'react-toastify';
import { isEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { GrRevert } from "react-icons/gr";
import { MdAdd, MdOutlineCancel, MdArrowBackIos} from "react-icons/md";
import { BiPlus, BiSave } from "react-icons/bi";
import { FaGreaterThanEqual, FaGreaterThan, FaEquals, FaLessThanEqual, FaLessThan } from "react-icons/fa";
import TpDiaInsertString from './../../../../Control/Common/Dialog/Input/TpDiaInsertString';
import TpDiaChooseVar from './TpDialog/TpDiaChooseVar';
import TpDiaChooseOpe from './TpDialog/TpDiaChooseOpe';
import TpDiaChooseAction from './TpDialog/TpDiaChooseAction';
import TpDiaSaveSceneToEeprom from './TpDialog/TpDiaSaveSceneToEeprom';


// function TabEditScene(props) {
const TabEditScene=forwardRef((props, ref)=> {
    useImperativeHandle(ref, () => ({
        async update() {      
            let saveRel = await v2RjFn.V2_RjUpdateScene(props.dev._id, G_Scene, G_RulesDisplay);
            if(saveRel.errMsg) return toast(saveRel.errMsg);
            toast("Save Success");
            setG_EditMode(false);
            if(props.afterModify) await props.afterModify();
            
        },
        async deleteSce() { 
            let delRel = await v2RjFn.V2_RjDeleteScene(props.dev._id, G_Scene);
            if(delRel.errMsg) return toast(delRel.errMsg);
            toast("Delete Success");
            setG_EditMode(false);
            if(props.afterModify) await props.afterModify();
            
        // delScene
            
        },


    }));
    const rulesMax = 5;
    const [G_OnlineVar, setG_OnlineVar] = useState([]);
    const [G_RulesDisplay, setG_RulesDisplay] = useState([]);
    const [G_RulesDisplay_DB, setG_RulesDisplay_DB] = useState([]);
    const [G_Scene, setG_Scene] = useState({});
    const [G_Scene_DB, setG_Scene_DB] = useState({});
    const [G_EditMode, setG_EditMode] = useState(false);
    const [G_DiaPage, setG_DiaPage] = useState(0);
    const [G_SpVarName, setG_SpVarName] = useState("");
    const [G_SelCondi, setG_SelCondiIdx] = useState({ruleIdx:0, condiIdx:0});
    const [G_SeRuleIdx, setG_SeRuleIdx] = useState(0);
    const [G_SelRule, setG_SelRule] = useState({AcReq:4, Setpoint:240});
    const [G_NewScene, setG_NewScene] = useState(false);
    const [G_bLoaded, setG_bLoaded] = useState(false);

    useEffect(()=>{
        async function startUp(){            
            let {dev} = props;

            let _RulesDisplay = await loadSceneInfo();
            setG_RulesDisplay(_RulesDisplay.scenePara);
            setG_RulesDisplay_DB(_RulesDisplay.scenePara);
            if(_RulesDisplay.newScene){
                setG_NewScene(true);
                setG_EditMode(true);
            }
            setG_Scene(_RulesDisplay.scene);
            setG_Scene_DB(_RulesDisplay.scene);
            /** get online var */
            let onlineVar = await getOnlineVar(dev._id);
            setG_OnlineVar(onlineVar);
            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("Page Close");
        }
        // eslint-disable-next-line
    }, []);    
    
    const getOnlineVar=async(_id)=>{
        let onlineVar = await v2RjFn.getV2_RjOnlineVar(_id);
        return onlineVar;
    }

    const loadSceneInfo=async()=>{
        try {
            let {dev} = props;
                let sceneInfo = await v2RjFn.getV2_RjSceneInfo(dev._id, props.sceneSel);
                if (isEmptyArr(sceneInfo.condis) || isEmptyArr(sceneInfo.rules)) {
                    let sceneIdx = props.sceneSel;
                    let scene ={
                        Name:"",
                        Rj_bdDevId:props.dev._id,
                        sceneIdx
                    };                
                    let scenePara =[{
                        condi:[{
                            Rj_bdDevId:props.dev._id,
                            condiOpe:1,
                            ruleIdx:1,
                            sceneIdx,
                            targetValue:0,
                            varIdx:-1,
                        }],
                        rule:{
                            Rj_bdDevId:props.dev._id,
                            AcReq:0,
                            Setpoint:240,
                            ruleIdx:1,
                            sceneIdx
                        }
                    }];
                    return {scenePara, scene, newScene:true};
                }
                let {scene, rules, condis} = sceneInfo;
                let scenePara = [];
                /** prepare scene info */
                for (let i = 0; i < rulesMax; i++) {
                    let eachRule = rules.find(c=> c.ruleIdx === i+1);
                    let condiList = condis.filter(c=> c.ruleIdx=== i+1);
                    if(isEmptyArr(condiList) && !eachRule) continue;
                    let max3Condis = condiList.slice(0, 3);
                    scenePara.push({rule: eachRule, condi: max3Condis});
                }
                return {scenePara, scene, newScene:false};
            // }
        } catch (error) {
            toast(`loadScene Err: ${error.message}`);
        }
    }

    const F_GetVarName=(varIdx)=>{
        let found = G_OnlineVar.find(c=>c.varIdx === varIdx);
        // let found = G_OnlineVar.find(c=>c.varIdx === varIdx+varIdShift-1);
        if(!found) return ""
        return found.varName;
    }
    const F_GetActionDesc=(AcReq, Setpoint, _Fan , _Swing)=>{
        let Fan = "A";
        if (_Fan > 0) Fan = _Fan;
        let Swing = "A";
        if (_Swing > 0) Swing = _Swing;

        if(AcReq===1){
            return `On, Fan:${Fan}, Swing:${Swing}`
        }else if(AcReq===2){
            return "Off"
        }else if(AcReq===3){
            return `${(parseFloat(Setpoint)/10).toFixed(1)}°C, Fan:${Fan}, Swing:${Swing}`
        }else if(AcReq===4){
            return `${(parseFloat(Setpoint)/10).toFixed(1) }°C, Fan: ${Fan}, Swing: ${Swing}`
        }else if(AcReq===5){
            return "Off (Clean)"
        }else{
            return "<Choose Action>"
        }
    }
    
    const F_TriggerEditMode=(bCmd)=>{
        setG_EditMode(bCmd?true:false);
    }

    const F_EditSceneName=()=>{
        if(!G_EditMode) return;
        // setG_InputNumber(false);
        setG_DiaPage(1);
    }
    const F_ClickEditSetpoint=(ruleIdx, condiIdx)=>{
        if(!G_EditMode) return;
        setG_SelCondiIdx({ruleIdx, condiIdx})
        let varName = F_GetVarName(G_RulesDisplay[ruleIdx].condi[condiIdx].varIdx);        
        setG_SpVarName(varName);
        setG_DiaPage(2);
    }
    const F_ClickEditVar=(ruleIdx, condiIdx)=>{
        if(!G_EditMode) return;
        setG_SelCondiIdx({ruleIdx, condiIdx})
        // setG_InputNumber(false);
        setG_DiaPage(3);
    }
    const F_ClickEditOpe=(ruleIdx, condiIdx)=>{
        if(!G_EditMode) return;
        setG_SelCondiIdx({ruleIdx, condiIdx})
        // setG_InputNumber(false);
        setG_DiaPage(4);
    }
    const F_ClickEditAction=(ruleIdx)=>{
        if(!G_EditMode) return;
        setG_SelCondiIdx({ruleIdx})
        setG_SeRuleIdx(ruleIdx);
        setG_SelRule(G_RulesDisplay[ruleIdx].rule);
        setG_DiaPage(5);
    }
    const F_CloseDia=()=>{
        setG_DiaPage(0);
        /** call edit title dialog */
    }

    const F_AddNewRule=()=>{
        if(G_RulesDisplay.length >=5) return
        let _RulesDisplay =[];
        let ruleIdx = 0;
        for (let i = 0; i < G_RulesDisplay.length; i++) {
            let condi =[];
            for (const eachCondi of G_RulesDisplay[i].condi) {
                condi.push({...eachCondi});
            }
            let rule = {...G_RulesDisplay[i].rule};
            _RulesDisplay.push({condi, rule});
        }
        ruleIdx = G_RulesDisplay.length+1;

        let emptyCondi=[{
            condiOpe:1,
            targetValue: 0,
            varIdx:-1,
            ruleIdx
        }]
        let newRule={
            AcReq:0,
            Setpoint:0,
            ruleIdx,
        }
        _RulesDisplay.push({condi:emptyCondi, rule:newRule});
        setG_RulesDisplay(_RulesDisplay);
    }

    const handleChangeName=(newName)=>{
        let _Scene = {...G_Scene};
        _Scene.Name= newName;
        setG_Scene(_Scene);
        F_CloseDia();
    }

    const Fin_GetG_RulesDisplay=()=>{
        let _RulesDisplay =[];
        // let ruleIdx = 0;
        for (let i = 0; i < G_RulesDisplay.length; i++) {
            let condi =[];
            for (const eachCondi of G_RulesDisplay[i].condi) {
                condi.push({...eachCondi});
            }
            let rule = {...G_RulesDisplay[i].rule};
            _RulesDisplay.push({condi, rule});
            // ruleIdx = i+1;
        }
        return _RulesDisplay;
    }

    const handleChangeSp=(newValue)=>{
        let _RulesDisplay =[];
        // let ruleIdx = 0;
        for (let i = 0; i < G_RulesDisplay.length; i++) {
            let condi =[];
            for (const eachCondi of G_RulesDisplay[i].condi) {
                condi.push({...eachCondi});
            }
            let rule = {...G_RulesDisplay[i].rule};
            _RulesDisplay.push({condi, rule});
            // ruleIdx = i+1;
        }
        _RulesDisplay[G_SelCondi.ruleIdx].condi[G_SelCondi.condiIdx].targetValue = parseFloat(newValue).toFixed(1);
        setG_RulesDisplay(_RulesDisplay);
        F_CloseDia();
        // const [G_SelCondi, setG_SelCondiIdx] = useState({ruleIdx:0, condiIdx:0});
    }

    const F_ChooseVar=(varInfo)=>{
        let _RulesDisplay = Fin_GetG_RulesDisplay();
        _RulesDisplay[G_SelCondi.ruleIdx].condi[G_SelCondi.condiIdx].varIdx = varInfo.varIdx;
        setG_RulesDisplay(_RulesDisplay);
        F_CloseDia();
    }

    const F_SetOpe=(condiOpe)=>{
        let _RulesDisplay = Fin_GetG_RulesDisplay();
        _RulesDisplay[G_SelCondi.ruleIdx].condi[G_SelCondi.condiIdx].condiOpe = condiOpe;
        setG_RulesDisplay(_RulesDisplay);
        F_CloseDia();
    }
    const handleChangeAction=(AcReq, Setpoint, Fan, Swing)=>{
        if(AcReq === 4 && (Setpoint < 160 || Setpoint>300)) return toast("Invalid Setpoint")
        let _RulesDisplay = Fin_GetG_RulesDisplay();
        _RulesDisplay[G_SeRuleIdx].rule.AcReq = AcReq;
        _RulesDisplay[G_SeRuleIdx].rule.Setpoint = Setpoint;
        _RulesDisplay[G_SeRuleIdx].rule.Fan = Fan;
        _RulesDisplay[G_SeRuleIdx].rule.Swing = Swing;
        setG_RulesDisplay(_RulesDisplay);
        F_CloseDia();
    }
    const handleAddCondi=(rulePos)=>{
        let _RulesDisplay = Fin_GetG_RulesDisplay();
        let emptyCondi={
            condiOpe:1,
            targetValue: 0,
            varIdx:-1,
            ruleIdx:rulePos+1
        }
        _RulesDisplay[rulePos].condi.push(emptyCondi);
        setG_RulesDisplay(_RulesDisplay);
    }
    const handleDelCondi=(ruleIdx, condiIdx)=>{
        let _RulesDisplay = Fin_GetG_RulesDisplay();
        _RulesDisplay[ruleIdx].condi.splice(condiIdx, 1);
        setG_RulesDisplay(_RulesDisplay);
    }
    const handelDelRule=(ruleIdx)=>{
        let _RulesDisplay = Fin_GetG_RulesDisplay();
        _RulesDisplay.splice(ruleIdx, 1);
        setG_RulesDisplay(_RulesDisplay);
    }
    const handelDelScene =async ()=>{
        F_CloseDia();
        if(props.delScene) await props.delScene(G_Scene, G_RulesDisplay);

    }
    const handleSaveScene =async()=>{
        /** check condition */   
        if(G_Scene.Name.trim()==="") return toast("Invalid Name");
        for (const eachRule of G_RulesDisplay) {
            for (const eachCondi of eachRule.condi) {
                if(eachCondi.varIdx < 2 || eachCondi.varIdx > 6) return toast("Invalid Variable");                
            }
            if(eachRule.rule.AcReq <=0) return toast("Insert valid action");
        }
        /** check if  */
        setG_DiaPage(6);
        return

    }
    const handleRevertScene =()=>{
        setG_RulesDisplay(G_RulesDisplay_DB);
        setG_Scene(G_Scene_DB);
        setG_EditMode(false);
    }
    const handleBack =()=>{
        if(props.toScenePg) props.toScenePg();
    }

    const fTrigScene_DiaConfirm=async()=>{
        F_CloseDia();
        if(props.trigSaveScene) {
            // props.trigSaveScene(1, bEeprom);
            props.trigSaveScene(G_Scene, G_RulesDisplay);
        }
    }
    

    return (
        <div className='spring_OverflowY' >
            {G_DiaPage ===1 && <TpDiaInsertString 
                oldString={"Scene Name"}
                onclickClose={F_CloseDia}
                middleText="SET TO"
                // isNumber={G_InputNumber}
                confirmChange={handleChangeName}
                inputLength = {15}/>}

            {G_DiaPage ===2 && <TpDiaInsertString 
                oldString={G_SpVarName}
                onclickClose={F_CloseDia}
                middleText="SET TO"
                isNumber={true}
                confirmChange={handleChangeSp}/>}

            {G_DiaPage ===3 && <TpDiaChooseVar
                onclickClose={F_CloseDia}
                confirmChange={F_ChooseVar}
                varList={G_OnlineVar}/>}

            {G_DiaPage ===4 && <TpDiaChooseOpe
                onclickClose={F_CloseDia}
                confirmChange={F_SetOpe}
            />}
            {G_DiaPage ===5 && <TpDiaChooseAction
                onclickClose={F_CloseDia}
                actionInfo={G_SelRule}
                confirmChange={handleChangeAction}
                acBrand={props.acBrand}
            />}
            
            {G_DiaPage ===6 && <TpDiaSaveSceneToEeprom
                onclickClose={F_CloseDia}
                confirmChange={fTrigScene_DiaConfirm}
                />}

            {!G_bLoaded && <div className='spring_RjEmptyVarList sortVer'>
                <div>Loading...</div>
            </div>}
            {G_bLoaded && <div>

                <div className='sortHor spring_sceneTitleBox'>
                    <div className='flexAndBothCenter hoverPointer'
                    onClick={handleBack}> <MdArrowBackIos/> </div>
                    <div className={`spring_SceneTitle ${G_EditMode?"spring_ruleEditMode hoverPointer":""}`}
                        onClick={F_EditSceneName}>{G_Scene.Name===""?"<Scene Name>":`${G_Scene.Name}`}</div>
                    <div className='sortHor spring_sceneEditModeBox'>
                        {!G_EditMode && <div className='spring_paddingLeft_10 spring_rulesEditIcon hoverPointer'
                        onClick={()=>F_TriggerEditMode(true)}> 
                            <FiEdit/></div>}
                        
                        {G_EditMode && <div className='sortHor'>
                            {!G_NewScene && <div className='spring_paddingLeft_10 spring_rulesEditIcon redText hoverPointer'
                                onClick={()=>handelDelScene()}
                                ><FiTrash2/></div>}
                            <div className='spring_paddingLeft_10 spring_rulesEditIcon greenCheck hoverPointer'
                                onClick={handleSaveScene}><BiSave/></div>
                            {!G_NewScene && <div className='spring_paddingLeft_10 spring_rulesEditIcon hoverPointer'
                                onClick={handleRevertScene}><GrRevert/></div>}
                        </div>}
                        
                    </div>
                </div>
                
                {G_RulesDisplay.map((c, ind_i)=>
                    <div key={`rules_${ind_i}`} className='spring_rulesBar'>
                        {c.condi.map((d, ind_j)=>
                            <div key={`condi_${ind_i}_${ind_j}`} className='sortHor spring_rulesBox'>

                                {ind_j===0 && <div className='spring_ruleFrontText'>WHEN</div>}
                                {ind_j>0 && <div className='spring_ruleFrontText'>AND</div>}

                                <div className={`${G_EditMode?"spring_ruleEditMode hoverPointer":""}`}
                                onClick={()=>F_ClickEditVar(ind_i, ind_j)}>{F_GetVarName(d.varIdx)===""?"<Choose Var.>":F_GetVarName(d.varIdx)}</div>
                                <div className={`spring_ruleOpeBox ${G_EditMode?"spring_OpeEditBox hoverPointer":""}`}
                                onClick={()=>F_ClickEditOpe(ind_i, ind_j)}>
                                    {d.condiOpe===1 && <FaGreaterThan/>}
                                    {d.condiOpe===2 && <FaLessThan/>}
                                    {d.condiOpe===3 && <FaEquals/>}
                                    {d.condiOpe===4 && <FaGreaterThanEqual/>}
                                    {d.condiOpe===5 && <FaLessThanEqual/>}
                                </div>
                                <div className={`${G_EditMode?"spring_ruleEditMode hoverPointer":""}`}
                                onClick={()=>F_ClickEditSetpoint(ind_i, ind_j)}
                                >{parseFloat(d.targetValue).toFixed(1)}</div>

                                {G_EditMode && <div className='spring_rulesEditContainer sortHor'>         
                                    {ind_j < 2 && ind_j === c.condi.length-1 && <div className='spring_rulesEditIcon greenCheck hoverPointer'
                                        onClick={()=>handleAddCondi(ind_i)}><BiPlus/></div>}
                                    {c.condi.length >1  && <div className='spring_rulesEditIcon orangeText hoverPointer'
                                        onClick={()=>handleDelCondi(ind_i, ind_j)}><MdOutlineCancel/></div>}
                                </div>}
                            </div>
                        )} 
                        <div className='spring_ruleSepLine'></div>
                        <div className='spring_actionBox sortHor'>
                            <div className='spring_ruleFrontText'>ACTION</div>
                            <div className={`${G_EditMode?"spring_ruleEditMode hoverPointer":""}`}
                                onClick={()=>F_ClickEditAction(ind_i)}>{F_GetActionDesc(c.rule.AcReq, c.rule.Setpoint, c.rule.Fan, c.rule.Swing)}</div>
                            
                            {G_RulesDisplay.length > 1 && G_EditMode && <div className='spring_rulesEditContainer'>         
                                <div className='spring_rulesEditIcon redText boldText hoverPointer'
                                onClick={()=>handelDelRule(ind_i)}><FiTrash2/></div>
                            </div>}
                        </div>

                    </div>
                )} 

                {G_EditMode && <div className={`spring_rulesBar flexAndBothCenter blueText ${G_RulesDisplay.length >=5 ?"":"hoverPointer"}`}
                onClick={F_AddNewRule}> 
                    {/* <div>Add Rule</div> */}
                    {G_RulesDisplay.length <5 && <MdAdd/> }
                    {G_RulesDisplay.length >=5 && <div className='spring_ruleMaxMsg'>MAX 5 RULES PER SCENE</div> }
                </div>}
            </div>}

        </div>
    );
// }
});

export default TabEditScene;