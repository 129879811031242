import localforage from 'localforage';
import timeFn from "../time/timeFn";

// Configure localforage to use IndexedDB
localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'graphDataDatabase',
  storeName: 'graphDataStore',
});

// Save graph data for a single device by deviceId
const saveDeviceGraphData = async (bdDev_id, data) => {
    try {
        await localforage.setItem(`device-${bdDev_id}`, data);
        // console.log(`Data for device ${bdDev_id} saved to IndexedDB`);
      } catch (e) {
        if (e.name === 'QuotaExceededError') {
          console.log('IndexedDB storage limit reached!');
          // Handle storage cleanup or notify the user
        }
      }
};

// Load graph data for a specific device by deviceId
const loadDeviceGraphData = async (bdDev_id) => {
    const deviceData = await localforage.getItem(`device-${bdDev_id}`);
    if (deviceData) {
      // console.log(`Data for device ${bdDev_id} loaded from IndexedDB`);
      return deviceData;
    } else {
      // console.log(`No data found for device ${bdDev_id}`);
      return null;
    }
};

const mergeDataForDevice = async (bdDev_id, newData) => {
    // Retrieve the current data for the device
    const deviceData = await localforage.getItem(`device-${bdDev_id}`);
  
    if (!deviceData) {
      // console.log(`No data found for device ${bdDev_id} in IndexedDB`);
      await saveDeviceGraphData(bdDev_id, newData);
      return newData;
    } else {
        let mergedData = [...deviceData, ...newData];
        await saveDeviceGraphData(bdDev_id, mergedData);
        return mergedData;
    }
};

// Clear expired data for a specific device by filtering out old data points
const clearExpiredAndMergeDataForDevice = async (bdDev_id, expirationTimeInSec, newData) => {
    // Retrieve the current data for the device
    const deviceData = await localforage.getItem(`device-${bdDev_id}`);
  
    if (!deviceData) {
      // console.log(`No data found for device ${bdDev_id}`);
      return newData;
    }

    const mergedData = [...newData];
    // console.log(newData[0].unix);
    // Loop through the data array (which is sorted from latest to oldest)
    for (let i = 0; i < deviceData.length; i++) {
        // Check if the data point is still valid
        if ((newData[0].unix - deviceData[i].unix) <= expirationTimeInSec) {
            // Add the valid data point to the filtered array
            mergedData.push(deviceData[i]);
        } else {
            // Break the loop as soon as we find the first expired data point
            break;
        }
    }
    
    await localforage.setItem(`device-${bdDev_id}`, mergedData);
    // console.log(`Expired data cleared and new data merged for device ${bdDev_id}`);
    return mergedData;
};

const expModule = {  
    saveDeviceGraphData,
    loadDeviceGraphData,
    mergeDataForDevice,
    clearExpiredAndMergeDataForDevice
};

export default expModule;