import ctrlCmdLib from '../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';

const F_CmdTimeout = async (curLog, setG_diaErrMsg,setG_statusState,cmdTimeoutRef) => {
  let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(curLog);
//   console.log("checkRel", checkRel);
  if (!checkRel || !checkRel.errMsg) {
    setG_diaErrMsg("Cmd DB Err");
    // toast("Cmd DB Err");
  }else if (checkRel.errMsg) {
    setG_diaErrMsg(checkRel.errMsg);
    // toast(checkRel.errMsg);
  }
  setG_statusState(3);
//   clearTimeout(cmdTimeoutRef.current);
  clearTimeout(cmdTimeoutRef);
//   /** trigger failed countdown */
//   statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
};

const F_GetIoTopic = (dev) => {
    let topic = `v2_CtrlCmd_${dev.type}_${dev.devID}`;
    return topic
}

const F_HandleNodeReply = (data, refCmdLog, cmdTimeoutRef, setG_statusState) => {
    let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog);
    if(sentSuccess) {
        clearTimeout(cmdTimeoutRef);
        setG_statusState(2);
        // /** trigger pass countdown */
        // statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
        // // toast ("Success")
        // await handleServerReq(data);
        return true
    }
    return false
}


const expModule = {  
    F_CmdTimeout,
    F_GetIoTopic,
    F_HandleNodeReply,
};
export default expModule;