import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
// eslint-disable-next-line
import { Line } from "react-chartjs-2";
import { FiPlus, FiMinus } from "react-icons/fi";
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import ownerSensorFn from "../../../function/V2_SensorDevice/v2_SensorOwnerFn";
import v2QueryFn from '../../../function/V2_Query/V2_QueryBdDevData';
import timeFn from "../../../component/utilities/timeFn";
import { toast } from 'react-toastify';
// eslint-disable-next-line
import TpCheckbox from "../../template/checkBox/TpCheckbox";
// eslint-disable-next-line
import auth from '../../../service/auth/AuthV2';
// eslint-disable-next-line
import bdDevSettingFn from "../../../function/V2_SensorDevice/v2_bdDevSettingFn";
import SensorMngtFn from '../../../function/sensor/sensorManagementFn';
// eslint-disable-next-line
import TpSmallInputTitleAtBox from '../../template/inputField/SmallInput_titleAtBox';
import moment from "moment";
import TpLineGraph2 from '../../template/v2Graph/TpLineGraph2';
import { FaEye, FaEyeSlash } from "react-icons/fa";
// eslint-disable-next-line
import { BsGraphUp } from "react-icons/bs";

const SensorInBdGraphPg = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(false);
    // eslint-disable-next-line
    const [graphData, setGraphData] = useState([]);
    const [ovvData, setOvvData] = useState([]);
    const [disLines, setDisLines] = useState([]);
    const [devInfoList, setDevInfoList] = useState([]);
    const [totalLine, setTotalLine] = useState(0);
    const [duration, setDuration] = useState(60*24);
    const [customize, setCustomize] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [unit, setUnit] = useState("hour");
    const [loading, setLoading] = useState(true);
    const [defaultData, setDefaultData] = useState({});
    const [loadedDuration, setLoadedDuration] = useState(10080);
    const [leftScaleValue, setLeftScaleValue] = useState({min: null, max: null});
    const [rightScaleValue, setRightScaleValue] = useState({min: null, max: null});
    const [userSetting, setUserSetting] = useState(1);
    // const [lnColDis, setLnColDis] = useState({});
    // const [leftScale, setLeftScale] = useState({});
    // const [rightScale, setRightScale] = useState({});

    const maxLine = 10;

    useEffect(() => {
        async function startUp() {
            // await firstLoad();
            await reloadData();
            setLoaded(true);
            setLoading(false);
        }

        startUp();
        // eslint-disable-next-line
    }, [loaded, duration, startTime, endTime]);

    const firstLoad = async () => {
        console.log("first load");
        let bdDevList = localStorageFn.getWatchList();
        // console.log(bdDevList);
        let bdDev_id = [];
        for(let i = 0; i < bdDevList._id.length; i++) {
            bdDev_id.push(bdDevList._id[i]);
        }
        // console.log(bdDev_id);
        let bdDev_info = await ownerSensorFn.getBdDev_By_bdDevIdList(bdDev_id); 
        // console.log(bdDev_info);
        await genDeviceList(bdDev_info);
        await genDefaultData(bdDev_info);
        // await genGraphData();
        // setMinMaxValue();
        // setLoaded(true);
        // setLoading(false);
        setFirstLoaded(true);
    }

    const reloadData = async () => {
        if(!firstLoaded) await firstLoad();
        console.log("reload");
        // setLoading(true);
        if(firstLoaded) {
            console.log("no first load");
            let ovvData = await genGraphData();
            if(devInfoList.length) {
                // setLnColDis(genLineInd_ForUi(ovvData)); 
                setMinMaxValue(ovvData);
            }
        }

        // console.log(Object.keys(lnColDis).length);
        // setLoaded(true);
        // setLoading(false);
    }

    const genDeviceList = async (bdDev_info) => {
        // console.log(bdDev_info);
        console.log("gendevicelist");
        let devInfoList = [];
        // let viewList = [];
        // let {user_id} = auth.getUserInfo();
        // console.log(user_id);
        let iteration = 0;
        for(const bddev of bdDev_info) {
            let lastData = await v2QueryFn.v2GetData_LastN(bddev.type, bddev._id, 1);
            // console.log(lastData);
            let defParaList = await SensorMngtFn.getParaByTypeList([bddev.type]);
            let paraList = [];
            // let selParaList = [];
            for(const defPara of defParaList) {
                paraList.push({
                    title: defPara.dataName,
                    dataKey: defPara.dataType,
                    unit: defPara.dataUnit,
                    checked: (iteration === 0 || iteration === 1) && defPara.dataType === "temperature" ? true : false,
                    leftScale: (iteration === 0 || iteration === 1) && defPara.dataType === "temperature" ? true : false,
                    rightScale: false,
                    min: undefined,
                    max: undefined
                });

                // if((iteration === 0 || iteration === 1) && defPara.dataType === "temperature") {
                //     selParaList.push({
                //         title: defPara.dataName,
                //         dataKey: defPara.dataType,
                //         unit: defPara.dataUnit,
                //         scale: iteration === 0? "left" : null,
                //         min: null,
                //         max: null
                //     });
                // }
            }
            if(lastData[0].gwID){
                paraList.push({
                    title: "Gw ID",
                    dataKey : "gwID",
                    unit: "",
                    checked: false,
                    leftScale: false,
                    rightScale: false,
                    min: undefined,
                    max: undefined
                })
            }
            if(lastData[0].frequency){
                paraList.push({
                    title: "Freq.",
                    dataKey : "frequency",
                    unit: "(Mhz)",
                    checked: false,
                    leftScale: false,
                    rightScale: false,
                    min: undefined,
                    max: undefined
                })
            }
            // }
            devInfoList.push({_id: bddev._id, name: bddev.name, type: bddev.type, para: paraList, show: false });
            // if(iteration === 0) setLeftScale({ _id: bddev._id, para: "temperature" });
            iteration++;
        }
        // console.log(devInfoList);
        // console.log(viewList);
        setDevInfoList(devInfoList);
        setTotalLine(2);
        // setViewList(viewList);
    }

    const genDefaultData = async (bdDev_info) => {
        console.log("gendefaultdata");
        let defData = {};
        for (const bdDev of bdDev_info) {
            let temp = await v2QueryFn.v2GetnMinB4nUnix(bdDev.type, bdDev._id, timeFn.getUnixNow(), 10080);
            // console.log(temp);
            defData[bdDev._id] = temp;
        }
        // console.log(defData);
        setDefaultData(defData);
    }
    
    const genGraphData = async () => {
        console.log("gengraphdata");
        // console.log(totalLine);
        // console.log(devInfoList);
        console.log(duration);
        console.log(loadedDuration);
        console.log(defaultData);
        let ovvData = [];
        let disLines = [];
        let tempDevInfo = [...devInfoList];
        // let currLeft = {min: null, max: null};
        // let currRight = {min: null, max: null};
        for(const bdDev of tempDevInfo) {
            let currData;
            if(duration > loadedDuration || (customize && moment(startTime).unix() < (moment().unix() - (loadedDuration*60))) || totalLine > Object.keys(defaultData).length) {
                console.log("reload all data");
                if(customize) currData = await v2QueryFn.v2GetBdDevData_T1_T2(bdDev.type, bdDev._id, moment(startTime).unix(), moment(endTime).unix());
                else {
                    setLoadedDuration(duration);
                    let defaults = {...defaultData};
                    currData = await v2QueryFn.v2GetnMinB4nUnix(bdDev.type, bdDev._id, timeFn.getUnixNow(), duration);
                    defaults[bdDev._id] = currData;
                    setDefaultData(defaults);
                }
            } else {
                console.log("no reload data");
                if(customize) {
                    currData = defaultData[bdDev._id].filter((c) => 
                        c.unix >= moment(startTime).unix() && c.unix <= moment(endTime).unix());
                } else currData = defaultData[bdDev._id].filter(c => c.unix > (moment().unix() - (duration*60)));
                // console.log(moment().unix() - (duration*60));   
            }

            if(currData.length) {
                // console.log(moment.unix(currData[0].unix).format(moment.HTML5_FMT.DATETIME_LOCAL));
                // console.log(currData[0].unix);
                let starttime = moment.unix(currData[currData.length - 1].unix).format(moment.HTML5_FMT.DATETIME_LOCAL);
                let endtime = moment.unix(currData[0].unix).format(moment.HTML5_FMT.DATETIME_LOCAL);
                let ttlTime = (currData[0].unix - currData[currData.length - 1].unix) / 60;
                console.log(ttlTime);
                if(ttlTime > 24*60) setUnit("day");
                else if(ttlTime > 60) setUnit("hour");
                else setUnit("minute");
                // console.log(starttime);
                // console.log(endtime);
                if(!startTime || starttime < startTime) {
                    setStartTime(starttime);
                }

                if(!endTime || endtime > endTime) {
                    setEndTime(endtime);
                }
                // setStartTime(currData[currData.length - 1].timestamp > startTime? startTime : currData[currData.length - 1].timestamp);
                // setEndTime(currData[0].timestamp < endTime? endTime : currData[0].timestamp);
            }
            for(const para of bdDev.para) {
                if(para.checked) {
                    for (const eachData of currData) {
                        // if(para.leftScale) {
                        //     if(currLeft.min === null || eachData[para.dataKey] < currLeft.min) currLeft.min = eachData[para.dataKey];
                        //     if(currLeft.max === null || eachData[para.dataKey] > currLeft.max) currLeft.max = eachData[para.dataKey];
                        //     if(!loaded) {
                        //         para.min = currLeft.min;
                        //         para.max = currLeft.max;
                        //     }
                        // }
                        // if(para.rightScale) {
                        //     if(currRight.min === null || eachData[para.dataKey] < currRight.min) currRight.min = eachData[para.dataKey];
                        //     if(currRight.max === null || eachData[para.dataKey] > currRight.max) currRight.max = eachData[para.dataKey];
                        //     if(!loaded) {
                        //         para.min = currRight.min;
                        //         para.max = currRight.max;
                        //     }
                        // }
                        let singleData = {x:eachData.unix*1000, y: eachData[para.dataKey]};
                        if(!Array.isArray(ovvData[`${para.dataKey}_${bdDev._id}`])) ovvData[`${para.dataKey}_${bdDev._id}`]=[singleData];
                        else ovvData[`${para.dataKey}_${bdDev._id}`].push(singleData);
                    }
                    disLines.push({ name: `${bdDev.name}`, title: `${para.title}_${bdDev._id}`, dataKey: `${para.dataKey}_${bdDev._id}`});
                }
            }
            
        }
        console.log(ovvData);
        setOvvData(ovvData);
        console.log(disLines);
        // console.log(disLines[0].title.split("_")[0]);
        setDisLines(disLines);
        // console.log("loaded", loading);
        // if(loading) setMinMaxValue();
        // console.log(currMin);
        // console.log(currMax);
        // setLeftScaleValue(currLeft);
        // setRightScaleValue(currRight);
        // console.log(startTime);
        // console.log(endTime);
        // let dataset = genChartData(ovvData, disLines);
        // let graphOptions = genOptions();
        // console.log(dataset);
        // setGraphData(dataset);
        // setGraphOptions(graphOptions);
        return ovvData;
    }

    const setMinMaxValue = (ovvData) => {
        console.log("setminmax");
        console.log(Object.keys(ovvData).length);
        console.log(totalLine);
        let tempList = [...devInfoList];
        let currLeft = {min: null, max: null};
        let currRight = {min: null, max: null};
        let lsPos = [];
        let rsPos = [];
        console.log(ovvData);
        console.log(tempList);
    
        for(let i = 0; i < tempList.length; i++) {
            for(let j = 0; j < tempList[i].para.length; j++) {
                if(ovvData[`${tempList[i].para[j].dataKey}_${tempList[i]._id}`]) {
                    let max = Math.max(...ovvData[`${tempList[i].para[j].dataKey}_${tempList[i]._id}`].map(o => o.y));
                    // console.log(max);
                    let min = Math.min(...ovvData[`${tempList[i].para[j].dataKey}_${tempList[i]._id}`].map(o => o.y));
                    // console.log(min);
                    if(tempList[i].para[j].leftScale) {
                        // let elementClass = document.getElementById(`${tempList[i]._id}_${tempList[i].para[j]}_left`).classList;
                        // elementClass.remove("icon-deselect");
                        if(currLeft.max === null || max > currLeft.max) currLeft.max = Math.ceil(max);
                        if(currLeft.min === null || min < currLeft.min) currLeft.min = Math.floor(min);
                        lsPos.push({pos1: i, pos2: j});
                            // para.min = currLeft.min;
                            // para.max = currLeft.max;
                            // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currLeft.min;
                            // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currLeft.max;
                    }
                    if(tempList[i].para[j].rightScale) {
                        // let elementClass = document.getElementById(`${tempList[i]._id}_${tempList[i].para[j]}_right`).classList;
                        // elementClass.remove("icon-deselect");
                        if(currRight.max === null || max > currRight.max) currRight.max = Math.ceil(max + 1);
                        if(currRight.min === null || min < currRight.min) currRight.min = Math.floor(min - 1);
                        rsPos.push({pos1: i, pos2: j});
                            // para.min = currRight.min;
                            // para.max = currRight.max;
                            // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currRight.min;
                            // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currRight.max;
                    }
                }
            }
        }
            // console.log(lsPos);
            // console.log(rsPos);
        let diffLeft, diffRight;
        if(currLeft.min !== null && currLeft.max !== null) {
            if(currLeft.max - currLeft.min > 1000) diffLeft = 100;
            else if(currLeft.max - currLeft.min > 100) diffLeft = 10;
            else if(currLeft.max - currLeft.min > 50) diffLeft = 5;
            else diffLeft = 1;

            currLeft.max += diffLeft;
            currLeft.min -= diffLeft;
        }
        if(currRight.max !== null && currRight.min !== null) {
            if(currRight.max - currRight.min > 1000) diffRight = 100;
            else if(currRight.max - currRight.min > 100) diffRight = 10;
            else if(currRight.max - currRight.min > 50) diffRight = 5;
            else diffRight = 1;

            currRight.max += diffRight;
            currRight.min -= diffRight;
        }

        for(const ls of lsPos) {
            tempList[ls.pos1].para[ls.pos2].min = currLeft.min;
            tempList[ls.pos1].para[ls.pos2].max = currLeft.max;
            // document.getElementById(`${tempList[ls.pos1]._id}_${tempList[ls.pos1].para[ls.pos2].dataKey}_min`).value = currLeft.min;
            // document.getElementById(`${tempList[ls.pos1]._id}_${tempList[ls.pos1].para[ls.pos2].dataKey}_max`).value = currLeft.max;
        }
    
        for(const rs of rsPos) {
            tempList[rs.pos1].para[rs.pos2].min = currRight.min;
            tempList[rs.pos1].para[rs.pos2].max = currRight.max;
            // document.getElementById(`${tempList[rs.pos1]._id}_${tempList[rs.pos1].para[rs.pos2].dataKey}_min`).value = currRight.min;
            // document.getElementById(`${tempList[rs.pos1]._id}_${tempList[rs.pos1].para[rs.pos2].dataKey}_max`).value = currRight.max;
        }
        document.getElementById("left_Max").value = currLeft.max;
        document.getElementById("right_Max").value = currRight.max;
        document.getElementById("left_Min").value = currLeft.min;
        document.getElementById("right_Min").value = currRight.min;
            // console.log(tempList);

        console.log(currLeft);
        console.log(currRight);
        setLeftScaleValue(currLeft);
        setRightScaleValue(currRight);
        setDevInfoList(tempList);
    }

    // const genLineInd_ForUi = (ovvData) =>{
    //     let lnColDis = [];
    //     let ctr = 1;
    //     console.log(ovvData);
    //     for (const eachLn in ovvData) {
    //         console.log(eachLn);
    //         lnColDis[eachLn] = ctr;
    //         ctr++;
    //     }
    //     console.log(lnColDis);
    //     return lnColDis;
    // }

    const backPrevPg = () => {
        props.history.push({
            pathname: "/data/sensorinbd",
        });
    }

    const handleCheck = async (devInfo, param) => {
        if(!param.checked && totalLine === maxLine) {
            toast("Only 10 Lines Allowed");
            return;
        }
        if(param.checked) setTotalLine(totalLine - 1);
        else setTotalLine(totalLine + 1);
        console.log(devInfo, param);
        let tempList = [...devInfoList];
        // let vList = [...viewList];
        // console.log(tempList);
        for(const temp of tempList) {
            if(temp._id === devInfo._id) {
                for(const para of temp.para) {
                    if(param.dataKey === para.dataKey) {
                        console.log(para.checked);
                        console.log(para.leftScale);
                        console.log(para.rightScale);
                        para.checked = !param.checked;
                        if(para.leftScale) para.leftScale = false;
                        if(para.rightScale) para.rightScale = false;
                        // if(param.checked) {
                        //     if(userSetting === 1) {
                        //         para.leftScale = true;
                        //         para.rightScale = false;
                        //     }
                        //     else if(userSetting === 2){
                        //         para.rightScale = true;
                        //         para.leftScale = false;
                        //     } 
                        // }
                    }
                }
            }
        }
        setDevInfoList(tempList);
        console.log(totalLine);
        if(param.checked) {
            console.log("param checked");
            if(userSetting === 1) await handleLeftScale(devInfo, param);
            else if(userSetting === 2) await handleRightScale(devInfo, param);
        }
        await reloadData();
        // else await reloadData();
        // setMinMaxValue();
    }

    const handleCheckFromIndicator = async (disLine) => {
        let tempList = [...devInfoList];

        for(const temp of tempList) {
            if(temp.name === disLine.name) {
                for(const para of temp.para) {
                    if(para.dataKey === disLine.dataKey.split("_")[0]) {
                        para.checked = !para.checked;
                        if(para.leftScale) para.leftScale = false;
                        if(para.rightScale) para.rightScale = false;
                    }
                }
            }
        }
        setDevInfoList(tempList);   
        await reloadData();     
    }

    const handleLeftScale = async (devInfo, param) => {
        // let elementClass = document.getElementById(`${devInfo._id}_${param.dataKey}_left`).classList;
        // if (!param.leftScale) elementClass.remove("icon-deselect");
        // else elementClass.add("icon-deselect");
        if(param.leftScale) {
            document.getElementById(`${devInfo._id}_${param.dataKey}_min`).value = "";
            document.getElementById(`${devInfo._id}_${param.dataKey}_max`).value = "";
        }
        let tempList = [...devInfoList];

        for(const temp of tempList) {
            for(const para of temp.para) {
                // if(para.leftScale === true) {
                //     para.leftScale = false;
                //     break;
                // }
                if(temp._id === devInfo._id) {
                    if(param.dataKey === para.dataKey) {
                        para.leftScale = !param.leftScale
                        para.rightScale = false;
                    }
                }
            }
        }
        !param.leftScale && param.checked? setUserSetting(0) : setUserSetting(1);
        await setDevInfoList(tempList);
        // setLoading(true);
        await reloadData();
        // setMinMaxValue();
    }

    const handleRightScale = async (devInfo, param) => {
        // let elementClass = document.getElementById(`${devInfo._id}_${param.dataKey}_right`).classList;
        // if (!param.leftScale) elementClass.remove("icon-deselect");
        // else elementClass.add("icon-deselect");
        
        if(param.rightScale) {
            document.getElementById(`${devInfo._id}_${param.dataKey}_min`).value = "";
            document.getElementById(`${devInfo._id}_${param.dataKey}_max`).value = "";
        }
        let tempList = [...devInfoList];

        for(const temp of tempList) {
            for(const para of temp.para) {
                // if(para.rightScale === true) {
                //     para.rightScale = false;
                //     break;
                // }
                if(temp._id === devInfo._id) {
                    if(param.dataKey === para.dataKey) {
                        para.rightScale = !param.rightScale
                        para.leftScale = false;
                    }
                }
            }
        }
        !param.rightScale && param.checked? setUserSetting(0) : setUserSetting(2);
        await setDevInfoList(tempList);
        await reloadData();
        // setMinMaxValue();
    }

    const handleMin = async (devInfo, param) => {
        let min = document.getElementById(`${devInfo._id}_${param.dataKey}_min`).value;
        console.log(min);
        let tempList = [...devInfoList];

        for(const temp of tempList) {
            if(devInfo._id === temp._id) {
                for(const para of temp.para) {
                    if(param.dataKey === para.dataKey) {
                        if(min === "" || min === null) {
                            console.log("min empty");
                            para.min = undefined;
                        }
                        else para.min = parseInt(min);
                    }
                }
            }
        }
        console.log(tempList);
        setDevInfoList(tempList);
        // await reloadData();
        await genGraphData();
        // setMinMaxValue();
    }

    const handleMax = async (devInfo, param) => {
        let max = document.getElementById(`${devInfo._id}_${param.dataKey}_max`).value;
        console.log(max);

        let tempList = [...devInfoList];

        for(const temp of tempList) {
            if(devInfo._id === temp._id) {
                for(const para of temp.para) {
                    if(param.dataKey === para.dataKey) { 
                        if(max === "" || max === null) {
                            console.log("max empty");
                            para.max = undefined;
                        }
                        else para.max = parseInt(max);
                    }
                }
            }
        }
        console.log(tempList);
        setDevInfoList(tempList);
        // await reloadData();
        await genGraphData();
        // setMinMaxValue();
    }

    const handleAuto = async (devInfo, param) => {
        // document.getElementById(`${devInfo._id}_${param.dataKey}_min`).value = "";
        // document.getElementById(`${devInfo._id}_${param.dataKey}_max`).value = "";

        // let tempList = [...devInfoList];

        // for(const temp of tempList) {
        //     if(devInfo._id === temp._id) {
        //         for(const para of temp.para) {
        //             if(param.dataKey === para.dataKey) {
        //                 para.min = undefined;
        //                 para.max = undefined;
        //             }
        //         }
        //     }
        // }

        // setDevInfoList(tempList);
        await reloadData();
        // setMinMaxValue(ovvData);
    }

    const handleDuration = async (setting) => {
        let currClass = document.getElementById(`toggle_${duration}`).classList;
        currClass.remove("selected");
        let eleClass = document.getElementById(`toggle_${setting}`).classList;
        eleClass.add("selected");
        setDuration(setting);
        if(setting === "custom") setCustomize(!customize);
        else {
            // if(setting === 60) setUnit("minute");
            // else if (setting === 1440) setUnit("hour");
            // else if (setting === 10080) setUnit("day");
            // else if (setting === 43200) setUnit("day");
            setCustomize(false);
            setLoading(true);
            // if(loadedDuration < setting) setFirstLoaded(true);
            // await reloadData();
        }
    }

    const handleStartEndTime = () => {
        let startTime = document.getElementById("in_startTime").value;
        let endTime = document.getElementById("in_endTime").value;
        console.log(startTime);
        console.log(endTime);
        // console.log(moment(startTime).unix());
        setStartTime(startTime);
        setEndTime(endTime);
        // await reloadData();
    }
    
    const handleLeftMinMax = async () => {
        let currLeft = {min: document.getElementById("left_Min").value === "" ?
                            leftScaleValue.min : parseFloat(document.getElementById("left_Min").value), 
                        max: document.getElementById("left_Max").value === "" ?
                            leftScaleValue.max : parseFloat(document.getElementById("left_Max").value)};
        console.log(parseFloat(document.getElementById("left_Min").value));
                            // let currLeft = {min: 0, max: 10};
        // currLeft.min = Number(document.getElementById("left_Min").value);
        // currLeft.max = Number(document.getElementById("left_Max").value);
        console.log(currLeft);
        setLeftScaleValue(currLeft);
        // let min = parseFloat(document.getElementById("left_Min").value);
        // let max = parseFloat(document.getElementById("left_Max").value);
        let tempList = [...devInfoList];
        console.log(leftScaleValue);

        for(const temp of tempList) {
            for(const para of temp.para) {
                console.log(para.leftScale);
                if(para.leftScale === true) {
                    // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currLeft.min;
                    // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currLeft.max;
                    // await handleMin(temp, para);
                    // await handleMax(temp, para);
                    para.min = currLeft.min;
                    para.max = currLeft.max;
                }
                // console.log(para);
            }
        }
        // console.log(tempList);
        setDevInfoList(tempList);
        await genGraphData();
    }
    
    const handleRightMinMax = async () => {
        let currRight = {min: document.getElementById("right_Min").value === "" ?
                            rightScaleValue.min : parseFloat(document.getElementById("right_Min").value), 
                        max: document.getElementById("right_Max").value === "" ?
                            rightScaleValue.max : parseFloat(document.getElementById("right_Max").value)};
        // let currLeft = {min: 0, max: 10};
        // currLeft.min = Number(document.getElementById("left_Min").value);
        // currLeft.max = Number(document.getElementById("left_Max").value);
        console.log(currRight);
        setRightScaleValue(currRight);
        // let min = parseFloat(document.getElementById("left_Min").value);
        // let max = parseFloat(document.getElementById("left_Max").value);
        let tempList = [...devInfoList];
        // console.log(leftScaleValue);

        for(const temp of tempList) {
            for(const para of temp.para) {
                console.log(para.rightScale);
                if(para.rightScale === true) {
                    // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currRight.min;
                    // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currRight.max;
                    // await handleMin(temp, para);
                    // await handleMax(temp, para);
                    para.min = currRight.min;
                    para.max = currRight.max;
                }
                // console.log(para);
            }
        }
        setDevInfoList(tempList);
        await genGraphData();
    }

    const handleShow = (devInfo) => {
        let tempList = [...devInfoList];

        for(const temp of tempList) {
            if(devInfo._id === temp._id)
                temp.show = !devInfo.show;
        }
        console.log(tempList);
        setDevInfoList(tempList);
    }

    return ( 
        <div>
            {loading && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
            {loaded && <div>
                <div className="backContainer divStickOnTop">
                    <div className="backIconContainer" onClick={backPrevPg}>
                        <IoIosArrowBack className="backIcon" />
                    </div>
                    <div className="backText">Graph</div>
                </div>

                <div className="buttonContainer" style={{ marginTop: "10px" }}>
                    <div id="toggle_60" className="toggleButton" onClick={() => handleDuration(60)}>1H</div>
                    <div id="toggle_1440" className="toggleButton selected" onClick={() => handleDuration(60*24)}>1D</div>
                    <div id="toggle_10080" className="toggleButton" onClick={() => handleDuration(60*24*7)}>7D</div>
                    <div id="toggle_43200" className="toggleButton" onClick={() => handleDuration(60*24*30)}>1M</div>
                    <div id="toggle_custom" className="toggleButton" onClick={() => handleDuration("custom")}>Customize</div>
                </div>
                
                {/* <div className='flexOnly spreadBetween'>
                    <input 
                        onBlur={() => handleLeftMinMax()} 
                        id="left_Max"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={leftScaleValue.max}
                        style={{display: leftScaleValue.max === null? "none" : "inline-block"}}
                        // value={leftScaleValue}
                    />
                    <input 
                        onBlur={() => handleRightMinMax()} 
                        id="right_Max"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={rightScaleValue.max}
                        style={{display: rightScaleValue.max === null? "none" : "inline-block"}}
                        // value={rightScaleValue}
                    />  
                </div> */}

                {/* {totalLine > 0 && <div className='graphOvvDevFrame'>
                    {devInfoList.map((c, ind) => (
                        <div key={ind}>
                            {c.para.map((d, idx) => (
                                d.checked &&
                                <div key={idx} style={{position: "relative"}}>
                                    {lnColDis[`${d.datakey}_${c._id}`] && <div style={{ backgroundImage:`var(--g${lnColDis[`${d.datakey}_${c._id}`]})`}} className='sensorGraph_LineColor'></div>}
                                    <div>{lnColDis[`${d.datakey}_${c._id}`]} {c.name} {d.title}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>} */}

                <div className='graphOvvDevFrame'>
                {totalLine > 0 && <div style={{ display: "flex", flexWrap: "wrap", margin: "5px 10px 15px 10px" }}>
                    {disLines.map((c, ind) => (
                        <div key={ind} className='flexOnly' style={{ flex: "1 0 45%" }}>
                            <div style={{ backgroundImage:`var(--h${ind + 1})`}} className='sensorGraph_LineColor'></div>
                            <div className='sensorGraph_Indicator' onClick={() => handleCheckFromIndicator(c)}>{c.name} {c.title.split("_")[0]}</div>
                        </div>
                    ))}
                </div>}

                <TpLineGraph2
                    ovvData={ovvData}
                    disLines={disLines}
                    devInfoList={devInfoList}
                    maxLine={maxLine}
                    unit={unit}
                />
                </div>
                {/* <div className='flexOnly spreadBetween'>
                    <input
                        onBlur={() => handleLeftMinMax()} 
                        id="left_Min"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={leftScaleValue.min}
                        style={{display: leftScaleValue.min === null? "none" : "inline-block"}}
                        // value={leftScaleValue.min}
                    />
                    <input
                        onBlur={() => handleRightMinMax()} 
                        id="right_Min"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={rightScaleValue.min}
                        style={{display: rightScaleValue.min === null? "none" : "inline-block"}}
                        // value={rightScaleValue.min}
                    />
                </div> */}

                {customize && <div className='flexOnly spreadBetween'>
                    <input
                        className=" i_time_contentInput hideNumberUpDown"
                        // placeholder={`< Insert duration >`}
                        type="datetime-local"
                        id="in_startTime"
                        style={{marginLeft: "10px"}}
                        onBlur={() => handleStartEndTime()}
                        onChange={() => handleStartEndTime()}
                        value={startTime}
                    ></input>
                    <input
                        className=" i_time_contentInput hideNumberUpDown"
                        // placeholder={`< Insert duration >`}
                        type="datetime-local"
                        id="in_endTime"
                        style={{marginRight: "10px"}}
                        onBlur={() => handleStartEndTime()}
                        onChange={() => handleStartEndTime()}
                        value={endTime}
                    ></input>
                </div>}

                <div className='contentCard'>
                <div className='graphDevOvv_Title'>Graph Settings</div>
                    <div className='flexAndBothCenter' style={{ marginTop: "10px" }}>
                        <div style={{ fontSize: "0.9rem" }}>Left Max: </div>
                        <input 
                            onBlur={() => handleLeftMinMax()} 
                            id="left_Max"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={leftScaleValue.max}
                            // style={{display: leftScaleValue.max === null? "none" : "inline-block"}}
                            // value={leftScaleValue}
                        />
                        <div style={{ fontSize: "0.9rem" }}>Left Min: </div>
                        <input
                            onBlur={() => handleLeftMinMax()} 
                            id="left_Min"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={leftScaleValue.min}
                            // style={{display: leftScaleValue.min === null? "none" : "inline-block"}}
                            // value={leftScaleValue.min}
                        />
                    </div>
                    <div className='flexAndBothCenter' style={{ margin: "10px 0 10px 0" }}>
                        <div style={{ fontSize: "0.9rem" }}>Right Max: </div>
                        <input 
                            onBlur={() => handleRightMinMax()} 
                            id="right_Max"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={rightScaleValue.max}
                            // style={{display: rightScaleValue.max === null? "none" : "inline-block"}}
                            // value={rightScaleValue}
                        />
                        <div style={{ fontSize: "0.9rem" }}>Right Min: </div>
                        <input
                            onBlur={() => handleRightMinMax()} 
                            id="right_Min"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={rightScaleValue.min}
                            // style={{display: rightScaleValue.min === null? "none" : "inline-block"}}
                            // value={rightScaleValue.min}
                        />
                    </div>
                </div>

                <div>
                    {devInfoList.map((c, ind) => (
                        <div className='contentCard' key={ind}>
                            <div className='flexOnly spreadBetween'>
                                <div className='graphDevOvv_Title'>{c.name}</div>
                                <div className='settingItemIcon' onClick={() => handleShow(c)}>
                                    {c.show ? <FiMinus /> : <FiPlus />}
                                </div>
                            </div>
                            {c.show && <div className='sortVer' style={{marginLeft: "10px"}}>
                                {/* <div className='flexOnly'>
                                    <div className='cb-Title'>V</div>
                                    <div className='cb-Title'>L</div>
                                    <div className='cb-Title'>R</div>
                                </div> */}
                                {c.para.map((d, idx) => (
                                    <div key={idx}>
                                        <div className='flexOnly' style={{marginBottom: "5px"}}>
                                            {/* <TpCheckbox handleCheck={() => handleCheck(c, d)} checked={d.checked} />
                                            <TpCheckbox handleCheck={() => handleLeftScale(c,d)} checked={d.leftScale} />
                                            <TpCheckbox handleCheck={() => handleRightScale(c,d)} checked={d.rightScale} /> */}
                                            <div className='graph-iconstyle' onClick={() => handleCheck(c, d)}>{d.checked? <FaEye /> : <FaEyeSlash />}</div>
                                            <div id={`${c._id}_${d.dataKey}_left`} className={d.leftScale? 'graph-iconstyle' : 'graph-iconstyle icon-deselect'} onClick={() => handleLeftScale(c,d)}><i className="fa fa-line-chart" /></div>
                                            <div id={`${c._id}_${d.dataKey}_right`} className={d.rightScale? 'graph-iconstyle' : 'graph-iconstyle icon-deselect'} onClick={() => handleRightScale(c,d)}><i className="fa fa-line-chart fa-flip-horizontal" /></div>
                                            <div style={{ fontSize: "0.9rem" }}>{d.title}</div>
                                        </div>
                                        {d.checked && <div className='flexOnly' style={{marginBottom: "10px"}}>
                                            <div className='inputContainer_titleAtBox_v2'>
                                                <div className="smallInputTitle_titleAtBox_v2">
                                                    Min
                                                </div>
                                                <div>
                                                    <input 
                                                    onBlur={() => handleMin(c, d)} 
                                                    id={`${c._id}_${d.dataKey}_min`} 
                                                    className="smallInputText_titleAtBox_v2"
                                                    maxLength={20}
                                                    type="number"
                                                    defaultValue={d.min}
                                                    />                    
                                                </div>
                                            </div>
                                            <div className='inputContainer_titleAtBox_v2'>
                                                <div className="smallInputTitle_titleAtBox_v2">
                                                    Max
                                                </div>
                                                <div>
                                                    <input 
                                                    onBlur={() => handleMax(c, d)} 
                                                    id={`${c._id}_${d.dataKey}_max`} 
                                                    className="smallInputText_titleAtBox_v2"
                                                    maxLength={20}
                                                    type="number"
                                                    defaultValue={d.max}
                                                    />                    
                                                </div>
                                            </div>
                                            <div className='roundButtonSmall' onClick={() => handleAuto(c, d)}>Auto</div>
                                        </div>}
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </div>

                {/* <div className=''>
                    <div className='contentCard'>
                        <div className='flexOnly spreadBetween'>
                            <div className='graphDevOvv_Title'>TV RTRH</div>
                            <div className='settingItemIcon'>
                                <FiPlus />
                            </div>
                        </div>
                        <div className='sortVer' style={{marginLeft: "10px"}}>
                            <div>
                                <input type="checkbox" />
                                <span>Temp.</span>
                            </div>
                            <div>
                                <input type="checkbox" />
                                <span>Humid.</span>
                            </div>
                            <div>
                                <input type="checkbox" />
                                <span>Battery</span>
                            </div>
                            <div>
                                <input type="checkbox" />
                                <span>RSSI</span>
                            </div>
                        </div>
                    </div>
                    <div className='contentCard'>
                    <div className='flexOnly spreadBetween'>
                        <div className='graphDevOvv_Title'>TV RTRH</div>
                        <div className='settingItemIcon'>
                                <FiPlus />
                            </div>
                            </div>
                        <div className='sortVer' style={{marginLeft: "10px"}}>
                            <div>
                                <input type="checkbox" />
                                <span>Temp.</span>
                            </div>
                            <div>
                                <input type="checkbox" />
                                <span>Humid.</span>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>}
        </div>
    );
}
 
export default SensorInBdGraphPg;