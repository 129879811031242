import http from "../httpService";


async function getV2_CtCfg(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2appdev/coolingtower/getctcfg";
    let body = {bdDev_id};
    return await http.post(url, body);  
}


async function setV2_CtCfg(ctCfg) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2appdev/coolingtower/setctcfg";
    let body = {ctCfg};
    return await http.post(url, body);  
}


const expModule = {  
    getV2_CtCfg,
    setV2_CtCfg,
};
export default expModule;
