import React, { useEffect, useRef, useState } from 'react'
import SLTabCfgPg from './SLTabCfgPg'
import TpDiaSending from '../../../../Control/Common/Dialog/StatusDialog/TpDiaSending';
import TpDiaSuccess from '../../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess';
import TpDiaFailed from '../../../../Control/Common/Dialog/StatusDialog/TpDiaFailed';
import socketIOClient from 'socket.io-client';
import ctrlCmdLib from '../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';
import SLTabCfgDots from './SLTabCfgDots';
import SLTabSetting from './SLTabSetting';
import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { toast } from 'react-toastify';
import { notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
// import { RiBubbleChartLine } from "react-icons/ri";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineAdjustments, HiOutlineBookOpen  } from "react-icons/hi";

const SubTpSectionLeader = (props) => {
  
  const cmdTimeout = 10000;    // 10 sec
  const msgTimeout = 3000;    // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  
  const [G_ShowingTab, setG_ShowingTab] = useState(2);

  const [G_GwID, setG_GwID] = useState();

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refCmdLog = useRef({});

  const refUploaded_CfgPg = useRef({});
  const refUploaded_Dots = useRef({});
  const refDots_payload = useRef({});
  const refCfgPg_payload = useRef({});

  useEffect(() => {    
    let socket;
    async function startUp() {      
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);       

      await F_LoadGateway();
      
      // await F_LoadDbInfo();

      let { dev } = props;
      let topic = `v2_CtrlCmd_${dev.type}_${dev.devID}`;
      socket.on(topic, async(data) => {
        let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
        if(sentSuccess) {
          clearTimeout(cmdTimeoutRef.current);
          setG_statusState(2);
          /** trigger pass countdown */
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          // toast ("Success")
          // console.log("data", data);
                
          /** handle server request */            
          await handleServerReq(data);
        }
      });

    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);    
    };
    // eslint-disable-next-line
  }, []);

  const F_LoadGateway=async()=>{
    let pairedGw = await v2ctrlFn.getV2_GwPair(props.dev._id);
    if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    let {gwPairList} = pairedGw;
    if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
    setG_GwID(gwPairList[0].gwid);
  }

  // const F_LoadDbInfo = async () => {
  //   let lNodeDotInfo = await v2ctrlFn.getV2_LNodeDots(props.dev._id);
  //   if(lNodeDotInfo.errMsg) return toast(`Cmd Err: ${lNodeDotInfo.errMsg}`);
  //   // console.log(lNodeDotInfo);
  //   if(notEmptyArr(lNodeDotInfo)){
  //     let { pf, pi } = lNodeDotInfo[0].payload;
  //     fDotsDisplayFormula(pi, pf);
  //     // let dotsInfo = [];
  //     // for (let i = 0; i < 8; i++) {
  //     //   if(pf.length === i) break;
  //     //   dotsInfo.push({
  //     //     ht:pi[(i*5)+1], 
  //     //     hi:pi[(i*5)+2], 
  //     //     bdDev_id:pi[(i*5)+3], 
  //     //     comType:pi[(i*5)+4], 
  //     //     dotGwID:pi[(i*5)+5], 

  //     //     loraFreq:pf[i]
  //     //   });
  //     // }
  //     // console.log(dotsInfo);
      
  //     // refUploaded_Dots.current={gwID:pi[0], dotsInfo}
  //   }
  //   let lNodeParaInfo = await v2ctrlFn.getV2_LNodeParaPg(props.dev._id);
  //   if(lNodeParaInfo.errMsg) return toast(`Cmd Err: ${lNodeParaInfo.errMsg}`);
  //   if(notEmptyArr(lNodeParaInfo)) {
  //     let { pi } = lNodeParaInfo[0].payload;
  //     fParaPgDisplayFormula(pi);
  //     // let para=[];
  //     // for (let i = 0; i < 8; i++) {
  //     //   para.push({dataType:pi[(i*2)+1], dataIndex:pi[(i*2)+2]});
  //     // }
  //     // refUploaded_CfgPg.current={pgNo:pi[0], para}
  //   }
  // }

  const F_LoadDbDots = async() => {
    let lNodeDotInfo = await v2ctrlFn.getV2_MrEeprom_byFn(props.dev._id, 3);
    if(lNodeDotInfo.errMsg) return toast(`Cmd Err: ${lNodeDotInfo.errMsg}`);
    // console.log(lNodeDotInfo);
    let pf = [], pi = [];
    if(notEmptyArr(lNodeDotInfo)){
      pf = [ ...lNodeDotInfo[0].payload.pf ];
      pi = [ ...lNodeDotInfo[0].payload.pi ];
    }
    refUploaded_Dots.current.uploadLNodeDots("db", pi, pf);
  }

  const F_LoadDbParaPg = async() => {
    let lNodeParaInfo = await v2ctrlFn.getV2_MrEeprom_byFn(props.dev._id, 2);
    if(lNodeParaInfo.errMsg) return toast(`Cmd Err: ${lNodeParaInfo.errMsg}`);
    let pi = [];
    if(notEmptyArr(lNodeParaInfo)) {
      pi = [ ...lNodeParaInfo[0].payload.pi ]
    }
    refUploaded_CfgPg.current.uploadLNodeParaPg("db", pi);
  }

  const handleServerReq=async(mqttData)=>{
    try {
      // console.log("props", props);
      // console.log("mqttData", mqttData);
        let {hf, pi, pf} = mqttData;
        if(hf===2){         // server set para pg
          // console.log("hf===2");
          let setLNodeParaPgRel = v2ctrlFn.setV2_MrEeprom(props.dev._id, hf, refCfgPg_payload.current);
          if(setLNodeParaPgRel.errMsg) toast (setLNodeParaPgRel.errMsg);
        }else if(hf===3){   // server set Dots
            // ??? refDots_payload.current
          let setLNodeDotsRel = v2ctrlFn.setV2_MrEeprom(props.dev._id, hf, refDots_payload.current);
          if(setLNodeDotsRel.errMsg) toast (setLNodeDotsRel.errMsg);
        }else if (hf===4){  // server query cfg page
          refUploaded_CfgPg.current.uploadLNodeParaPg("node", pi);
          // fParaPgDisplayFormula(pi);
        } else if (hf===5){  // server query dots
          refUploaded_Dots.current.uploadLNodeDots("node", pi, pf);
          // fDotsDisplayFormula(pi, pf);
        }
    } catch (error) {
        console.log("handleServerReq err: ", error.message);
    }
  }

  // const fDotsDisplayFormula = (pi, pf) => {
  //   let dotsInfo = [];
  //   for (let i = 0; i < 8; i++) {
  //     dotsInfo.push({
  //       ht:pi[(i*5)+1], 
  //       hi:pi[(i*5)+2], 
  //       bdDev_id:pi[(i*5)+3], 
  //       comType:pi[(i*5)+4], 
  //       dotGwID:pi[(i*5)+5], 

  //       loraFreq:pf[i]
  //     });
  //   }
  //   refUploaded_Dots.current={gwID:pi[0], dotsInfo}
  // }

  // const fParaPgDisplayFormula = (pi) => {
  //   let para=[];
  //   for (let i = 0; i < 8; i++) {
  //     para.push({dataType:pi[(i*2)+1], dataIndex:pi[(i*2)+2]});
  //   }
  //   refUploaded_CfgPg.current={pgNo:pi[0], para}
  // }

  const fCmdTimeout=async()=>{
    let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
    if(!checkRel || !checkRel.errMsg) {
        setG_diaErrMsg("Cmd DB Err");
        // toast("Cmd DB Err");
    }
    if(checkRel.errMsg) {
        setG_diaErrMsg(checkRel.errMsg);
        // toast(checkRel.errMsg);
    }
    setG_statusState(3);
    clearTimeout(cmdTimeoutRef.current);
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);        
    
  }

  const statusDiaAutoClose=()=>{
    handleCloseDia();
  }

  const handleCloseDia=()=>{
    // clearTimeout(cmdTimeoutRef.current);
    // clearTimeout(statusTimeoutRef.current);    
    setG_statusState(0);
  }

  const F_getDotsPayload = (dotsInfo) => {
    let pi=[G_GwID];
    let pf=[];
    for (const eachDot of dotsInfo) {
      pi.push(eachDot.ht);
      pi.push(eachDot.hi);
      pi.push(eachDot.bdDev_id);
      pi.push(eachDot.comType);
      pi.push(eachDot.dotGwID);

      pf.push(eachDot.loraFreq);
    }

    for(let i = dotsInfo.length; i < 8; i++) {
      pi.push(0);
      pi.push(0);
      pi.push(0);
      pi.push(0);
      pi.push(0);

      pf.push(0);
    }

    return { pi, pf };
  }

  const F_uploadDotsToDb = async(dotsInfo) => {
    setG_statusState(1);
    let payload = F_getDotsPayload(dotsInfo);
    let setLNodeDots = await v2ctrlFn.setV2_MrEeprom(props.dev._id, 3, payload);
    if(setLNodeDots.success) setG_statusState(2);
    if(setLNodeDots.errMsg){
      setG_diaErrMsg("DB Error");
      setG_statusState(3);
    } 
  }

  const F_loadNodeDots =async (pgNo)=>{
    let GwID= G_GwID; 
    let pi=[pgNo];

    let payload = {pi};
    /** get device {type, devID} info */
    let devInfo = {type:props.ht, devID:props.dev.devID};
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(5, GwID, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    
    setG_statusState(1);     // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;

  }

  const F_sendLora_Dots=async (dotsInfo)=>{
    
    let payload = F_getDotsPayload(dotsInfo);
    /** get device {type, devID} info */
    let devInfo = {type:props.ht, devID:props.dev.devID};

    // console.log("devInfo", devInfo);
    // console.log("payload", payload);
    
    // return toast("Testing break")
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(3, G_GwID, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    
    setG_statusState(1);     // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
    refDots_payload.current = payload;
  }
  
  const F_getParaPgPayload = (pgNo, cfgPg) => {
    let pi=[pgNo];
    for (const eachCfg of cfgPg) {
      pi.push(eachCfg.dataType);
      pi.push(eachCfg.dataIndex);
    }
    
    for(let i = cfgPg.length; i < 8; i++) {
      pi.push(0);
      pi.push(0);
    }
    
    return { pi };
  }
  
  const F_uploadParaPgToDb = async(pgNo, cfgPg) => {
    setG_statusState(1);
    let payload = F_getParaPgPayload(pgNo, cfgPg);
    let setLNodeParaPg = await v2ctrlFn.setV2_MrEeprom(props.dev._id, 2, payload);
    if(setLNodeParaPg.success) setG_statusState(2);
    if(setLNodeParaPg.errMsg){
      setG_diaErrMsg("DB Error");
      setG_statusState(3);
    } 
  }
  
  const F_loadNodeCfgPg =async (pgNo)=>{
    let GwID= G_GwID; 
    let pi=[pgNo];

    let payload = {pi};
    /** get device {type, devID} info */
    let devInfo = {type:props.ht, devID:props.dev.devID};
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(4, GwID, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    
    setG_statusState(1);     // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;

  }

  const sendLoRa_cfgPg=async (pgNo, cfgPg)=>{
    // let GwID= G_GwID; // ???
    
    // // // let pi = [parseInt(nAcReq)];    // setpoint, fan speed, swing pos
    // let pi=[pgNo];
    // for (const eachCfg of cfgPg) {
    //   pi.push(eachCfg.dataType);
    //   pi.push(eachCfg.dataIndex);
    // }

    // for(let i = cfgPg.length; i < 8; i++) {
    //   pi.push(0);
    //   pi.push(0);
    // }
    
    let payload = F_getParaPgPayload(pgNo, cfgPg);
    /** get device {type, devID} info */
    let devInfo = {type:props.ht, devID:props.dev.devID};
    // console.log("cfgPg", cfgPg);
    // console.log("payload", payload);
    
    // return toast("Test break")
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(2, G_GwID, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    
    setG_statusState(1);     // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
    refCfgPg_payload.current = payload;
  }

  

  const F_ToCfgDots=()=>{
    setG_ShowingTab(1);
  }
  const F_ToCfgPg=()=>{
    setG_ShowingTab(0);
  }
  const F_ToSettingPg=()=>{
    setG_ShowingTab(2);
  }
  const F_SetGw=(gwid)=>{
    setG_GwID(gwid);
  }
  
  

  return (    
    <div>
      {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
      {G_statusState===1 && <TpDiaSending/>}
      {G_statusState===2 && <TpDiaSuccess onClickDiaClose = {handleCloseDia}/>}
      {G_statusState===3 && <TpDiaFailed onClickDiaClose = {handleCloseDia} diaErrMsg={G_diaErrMsg}/>}

      {G_ShowingTab === 0 && <SLTabCfgPg pos={props.pos}
        uploadNodeCfgPg={sendLoRa_cfgPg}
        loadNodeCfgPg={F_loadNodeCfgPg}
        loadDbCfgPg={F_LoadDbParaPg}
        uploadDbCfgPg={F_uploadParaPgToDb}
        ref = {refUploaded_CfgPg}
      />}

      {G_ShowingTab === 1 && <SLTabCfgDots pos={props.pos}
        loadDbDots={F_LoadDbDots}
        uploadDbDots={F_uploadDotsToDb}
        loadNodeDots={F_loadNodeDots}
        uploadNodeDots={F_sendLora_Dots}
        ref={refUploaded_Dots}    
        gwId = {G_GwID}    
        dev={props.dev}
      />}

      {G_ShowingTab === 2 && <SLTabSetting dev={props.dev}
        gwId = {G_GwID}
        setGwId = {F_SetGw}
        />}

      <div className='spring_TpBottomBar sortHor'>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 0?"spring_shadowBox_Small":""} hoverPointer`} onClick={F_ToCfgPg}>
          <HiOutlineBookOpen  />
          <div className="spring_TpBtmButtonTitle">Para Pg</div>
        </div>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 1?"spring_shadowBox_Small":""} hoverPointer`} onClick={F_ToCfgDots}>
          <HiOutlineAdjustments />
          <div className="spring_TpBtmButtonTitle">Dots</div>
        </div>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 2?"spring_shadowBox_Small":""} hoverPointer`} onClick={F_ToSettingPg}>
          <AiOutlineSetting />
          <div className="spring_TpBtmButtonTitle">Setting</div>
        </div>
        {/* <div className='spring_TpBottomBar_ItemFrame hoverPointer' onClick={F_ToCfgDots}>1</div>
        <div className='spring_TpBottomBar_ItemFrame hoverPointer' onClick={F_ToCfgPg}>2</div>
        <div className='spring_TpBottomBar_ItemFrame hoverPointer' onClick={F_ToSettingPg}>3</div> */}
      </div>
    </div>
  )
}

export default SubTpSectionLeader
