import sumListSer from '../../service/present_V2/sumList';
import queryDataSer from '../../service/queryBdDevData';

async function getByBd_Id(Bd_Id){
    try {
        let result = await sumListSer.getSumList_Bd_Id(Bd_Id);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}

async function getLastNData(type, bdDevId, nCnt){
    let {data} = await queryDataSer.getLastNData(type, bdDevId, nCnt);    
    if(data[0]) return data[0];
    return null;
}

const expModule = {  
    getByBd_Id,
    getLastNData,
};
export default expModule;