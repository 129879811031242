import v2QueryFn from '../../service/V2_Query/V2_QueryV2BdDevData';

async function v2GetData_LastN(type, bdDev_id, nCnt){    
    // console.log("Cone In");
    try {
        let rel = await v2QueryFn.v2GetData_LastN(type, bdDev_id, nCnt);
        // console.log(rel);
        if(rel.statusText==='OK'){
            // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
            return rel.data;
        } 
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg: "Platform Func. Error"};
    }
}

async function v2GetnMinB4nUnix(type, bdDev_id, endUnix, nMin){    
    // console.log("endUnix", endUnix);
    try {
        let rel = await v2QueryFn.v2GetnMinB4nUnix(type, bdDev_id, endUnix, nMin);
        // console.log(rel);
        if(rel.statusText==='OK'){
            // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
            return rel.data;
        } 
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg: "Platform Func. Error"};
    }
}

async function v2GetBdDevData_T1_T2(type, bdDev_id, t1, t2){
    try {
        let rel = await v2QueryFn.v2GetBdDevData_T1_T2(type, bdDev_id, t1, t2);
        // console.log(rel);
        if(rel.statusText==='OK'){
            // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
            return rel.data;
        } 
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg: "Platform Func. Error"};
    }
}


async function v2GetData_LastNOfList(devList){
    try {
        let rel = await v2QueryFn.v2GetData_LastNOfList(devList);
        // console.log(rel);
        if(rel.statusText==='OK'){
            // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
            return rel.data;
        } 
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg: "Platform Func. Error"};
    }
}

async function v2GetDailykWh(info){
    try {
        let rel = await v2QueryFn.v2GetDailykWh(info);
        // console.log(rel);
        if(rel.statusText==='OK'){
            // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
            return rel.data;
        } 
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg: "Platform Func. Error"};
    }
}

async function v2GetDataForDashItem(dashItemQueryList){
    try {
        let rel = await v2QueryFn.v2GetDataForDashItem(dashItemQueryList);
        // console.log(rel);
        if(rel.statusText==='OK'){
            // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
            return rel.data;
        } 
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg: "Platform Func. Error"};
    }
}

const expModule = {  
    v2GetData_LastN,
    v2GetnMinB4nUnix,
    v2GetBdDevData_T1_T2,
    v2GetData_LastNOfList,
    v2GetDailykWh,
    v2GetDataForDashItem
};
export default expModule;