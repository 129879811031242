import React, { useEffect, useState } from 'react';

function TpDiaChooseSerial(props) {

    const G_serialList = [
        { display: "5N1", value: 134217744 },
        { display: "6N1", value: 134217748 },
        { display: "7N1", value: 134217752 },
        { display: "8N1", value: 134217756 },
        { display: "5N2", value: 134217776 },
        { display: "6N2", value: 134217780 },
        { display: "7N2", value: 134217784 },
        { display: "8N2", value: 134217788 },
        { display: "5E1", value: 134217746 },
        { display: "6E1", value: 134217750 },
        { display: "7E1", value: 134217754 },
        { display: "8E1", value: 134217758 },
        { display: "5E2", value: 134217778 },
        { display: "6E2", value: 134217782 },
        { display: "7E2", value: 134217786 },
        { display: "8E2", value: 134217790 },
        { display: "5O1", value: 134217747 },
        { display: "6O1", value: 134217751 },
        { display: "7O1", value: 134217755 },
        { display: "8O1", value: 134217759 },
        { display: "5O2", value: 134217779 },
        { display: "6O2", value: 134217783 },
        { display: "7O2", value: 134217787 },
        { display: "8O2", value: 134217791 }
    ];

    useEffect(()=>{
        async function startUp(){
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(value)=>{
        if(props.confirmChange) {
            props.confirmChange("serial", value);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_diaFixHeight'>
                        <div className='spring_ruleVarListContainer'>
                            {G_serialList.map((c, ind)=>
                                <div key={`varKey_${ind}`} className="spring_ruleVarListBox hoverPointer wrapTextEllipsis"
                                onClick={()=>handleConfirm(c)}
                                >{c.display}</div>
                            )} 
                        </div>
                    </div> 
                                    

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaChooseSerial;