import React from 'react';
import { FiTrash2} from "react-icons/fi";
import { toast } from 'react-toastify';

function TpDeleteDevice(props) {
    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.onclickConfirm) {
            let delConfirm = document.getElementById('rename_input').value.trim();
            if(!delConfirm || delConfirm==="" || delConfirm!=="DELETE") return toast('Key in "DELETE" to confirm');
            props.onclickConfirm();
        }
    }

    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox'>
                    {props.deviceName && <div className='text_1_1 spring_renameBdName'>{`${props.deviceName}`}</div>}
                                        
                    <div className='flexAndBothCenter text_3 redText'
                    style={{margin:"20px 0px 40px 0px"}}>
                        <FiTrash2/>
                    </div>

                    <div> Type "DELETE" to confirm</div>

                    <div className='flexAndBothCenter spring_Margin_30'>
                        <input id="rename_input"
                        autoComplete='off' 
                        className='spring_FramelessInput blueText'
                        // placeholder={"< DELETE >"}
                        style={{textAlign:"center"}}/>
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}
                        >
                            Discard
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TpDeleteDevice;