import { SignJWT } from 'jose';
import { toast } from "react-toastify";

// Generate a key to sign the JWT (replace it with your real secret key)
const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_INTERNAL_KEY);

export async function jwtSignToken(payload) {
    try {
        // Use `jose` to sign the JWT (without setting expiration time)
        const token = await new SignJWT(payload)
            .setProtectedHeader({ alg: 'HS256' }) // Set algorithm
            .setIssuedAt()                        // Issued at timestamp
            .sign(secret);                        // Sign with your secret

        return token;
    } catch (error) {
        console.log(error.message);
        toast('Sign Token Error');
        return null;
    }
}

export async function jwtSignToken_7dayExpiry(payload) {
    try {
        const token = await new SignJWT(payload)
            .setProtectedHeader({ alg: 'HS256' })
            .setIssuedAt()                        // Issued at timestamp
            .setExpirationTime('7d') // Set expiration for 7 days
            .sign(secret); // Sign the token with the secret key
        
        return token;
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null;
    }
}