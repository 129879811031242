import V2DataDownloadSer from '../../service/V2_DataDownload/V2_DataDownloadSer';

async function dataDownloadGenTicket(downloadInfo){    
    // console.log("Cone In");
    try {
        let rel = await V2DataDownloadSer.dataDownloadGenTicket(downloadInfo);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getNotDownloadedTicket(user_id, bd_id){    
    // console.log("Cone In");
    try {
        let rel = await V2DataDownloadSer.getNotDownloadedTicket(user_id, bd_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getDownloadedTicket_lastN(user_id, bd_id, nNumber){    
    // console.log("Cone In");
    try {
        let rel = await V2DataDownloadSer.getDownloadedTicket_lastN(user_id, bd_id, nNumber);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function dataDownloadCsvZip(ticketInfo) {
    try {
        let rel = await V2DataDownloadSer.dataDownloadCsvZip(ticketInfo);        
        return rel;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function dataDownloadPrepFile(downloadInfo){    
    // console.log("Cone In");
    try {
        let rel = await V2DataDownloadSer.dataDownloadPrepFile(downloadInfo);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function dataDownloadMultiCsv(pathList, zipFileName) {
    try {
        let rel = await V2DataDownloadSer.dataDownloadMultiCsv(pathList, zipFileName);        
        return rel;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function clearDownloadHistory(user_id, bd_id){    
    // console.log("Cone In");
    try {
        let rel = await V2DataDownloadSer.clearDownloadHistory(user_id, bd_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

const expModule = {
    dataDownloadGenTicket,
    getNotDownloadedTicket,
    getDownloadedTicket_lastN,
    dataDownloadCsvZip,
    dataDownloadPrepFile,
    dataDownloadMultiCsv,
    clearDownloadHistory
};
export default expModule;