import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaMinus} from "react-icons/fa";
import { isEmptyArr, sortTemplate, swapArray } from '../../../../../function/ArrayFn/arrayFn';
import { FaArrowUp, FaArrowDown} from "react-icons/fa";
import v2OwnDevFn from '../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';


function TpDiaSortDevSortArea(props) {
    const [G_deviceList, setG_deviceList] = useState([]);
    const [G_Loaded, setG_Loaded] = useState(false);

    useEffect(()=>{
        async function startUp(){
            try {
                let selArea = props.areaGroupList.find(c=> c.area_id === props.areaSelected.area_id);
                if(isEmptyArr(selArea.devices)) {
                    setG_Loaded(true);
                    return;
                }
                let sortedArr = sortArr(selArea.devices);
                setG_deviceList(sortedArr);
                // if(props.areaGroupList){
                //     setG_deviceList(props.areaGroupList);
                // }

                setG_Loaded(true);
            } catch (error) {
                toast(error.message);
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    
    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "sortIdx", descOrder: false },
            { key: "name", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();

    }
    const handleConfirm=async()=>{

        let _deviceList = [...G_deviceList];
        let toSortList =[];
        for (let i = 0; i < _deviceList.length; i++) {
            if(_deviceList[i].sortIdx !== i+1) toSortList.push({_id: _deviceList[i]._id, sortIdx:i+1});
        }
        if(toSortList.length <1) return toast("No changes")
        // let updateRel = await v2OwnDevFn.v2aUpdateSortIdx_AreaList(toSortList);
        let updateRel = await v2OwnDevFn.v2aUpdateSortIdx_DevList(toSortList);
        if(updateRel.errMsg) return toast(updateRel.errMsg);
        if(updateRel.Success) {            
            toast("Success");
            if(props.onclickConfirm) props.onclickConfirm();
            // handleClose();  
            return
        }
        toast("Save error");



        // let rel = await v2OwnDevFn.v2aUpdateSortIdx_DevList(G_deviceList);
        // if(rel.errMsg) return toast(rel.errMsg);
        // toast("Success");
        // handleClose();
    }
    
    const handleSortUp=(ind)=>{        
        if(ind<=0) return;
        let _deviceList = [...G_deviceList]
        _deviceList = swapArray(_deviceList, ind-1,ind);
        setG_deviceList(_deviceList);
    }

    const handleSortDown=(ind)=>{
        if(ind>=G_deviceList.length-1) return;
        let _deviceList = [...G_deviceList]
        _deviceList = swapArray(_deviceList, ind,ind+1);
        setG_deviceList(_deviceList);
    }

    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogList spring_diaFixedHeight'
                style={{position:"relative"}}>
                    <div className='spring_diaCloseBtnFixedHeight spring_shadowBox spring_lightGreyGrdBgc hoverPointer spring_index2'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>
                    <div className='text_1_2 spring_renameBdName spring_centerText'
                        >Sort</div>

                    {!G_Loaded && <div>Loading...</div>}       
                    {G_Loaded && <div>
                        <div className='spring_disListFrame'
                        style={{height:"220px"}}>
                            {G_deviceList.map((u, ind)=>(
                                <div key={`dev_${ind}`} className='spring_SortFrameList sortHor'>
                                    <div>{u.name}</div>
                                    <div className='flexAndBothCenter stayRight'>
                                        <FaArrowUp className='spring_SortIcon_dia reactCfgButtonOn hoverPointer'
                                        onClick={()=>handleSortUp(ind)}
                                        />
                                        <FaArrowDown className='spring_SortIcon_dia orangeText hoverPointer'
                                        onClick={()=>handleSortDown(ind)}
                                        />
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                        
                        <div className='flexAndBothCenter spring_Margin_20'>
                            <div className="roundButton" 
                            onClick={handleConfirm}
                            >Confirm</div>
                        </div>
                    </div>}
                    

                </div>

            </div>
        </div>
    );
}

export default TpDiaSortDevSortArea;