import React from "react";
import rtrh from "./rtrh.png";
import accontrol from "./accontrol.png";

const RtrhIcon = ({ ...rest }) => {
  return <img src={rtrh} alt="RTRH Logo" {...rest} />;
};

const AcControlIcon = ({ ...rest }) => {
  return <img src={accontrol} alt="AcControl Logo" {...rest} />;
};

const expModule = {
  RtrhIcon,
  AcControlIcon,
};
export default expModule;
