import React from 'react';
import TpMenuBdPage from './TpMenuBdPage';
import TpMenuFloorPage from './TpMenuFloorPage';
import TpMenuAreaPage from './TpMenuAreaPage';

function TpManuFrame(props) {
    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }

    return (
        <div className='spring_manuFrame'>
            <div className='spring_manuContainer'
            onClick={handleClose}></div>

            <div className='spring_manuBox'>
                {props.entryFrom === "Bd" && <TpMenuBdPage {...props}/>}
                {props.entryFrom === "Floor" && <TpMenuFloorPage {...props}/>}
                {props.entryFrom === "Area" && <TpMenuAreaPage {...props}/>}
                
            </div>
        </div>
    );
}

export default TpManuFrame;