import React, { Component } from 'react';

//<Tp_Input_titleAtBox id='SnInput' title='Serial No.' maxLen={40}/>

class TpSmallInputTitleAtBox extends Component {

    clearErrMsg=()=>{
        // console.log("Focus");
        document.getElementById(`${this.props.errMsgid}`).innerHTML = "";
    }

    render() { 
        return <div>

            <div className='inputContainer_titleAtBox'>
                <div className="smallInputTitle_titleAtBox">
                    {this.props.title}
                </div>
                <div>
                    <input 
                    onBlur={this.props.onBlur} 
                    id={this.props.id} 
                    className="smallInputText_titleAtBox" 
                    onChange={this.clearErrMsg} 
                    maxLength={this.props.maxLen}
                    type={this.props.title?this.props.type:''}
                    />                    
                </div>
                <div id = {this.props.errMsgid} className='inputErrMsg'></div>
            </div>

        </div>;
    }
}
 
export default TpSmallInputTitleAtBox;