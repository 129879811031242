import { isEmptyArr, isEmptyObj, notEmptyArr, sortTemplate } from "../../../function/ArrayFn/arrayFn";
// import timeFn from '../../../function/time/timeFn';

export const F_DashManuPgHeight=(heightPortion)=>{
  if(heightPortion===2) return 156
  return 356
}

// const nDataValidTime_S = 20;
export const F_DashDb_To_DisplayFormat = (componentList, groupList) => {
  let groupArray = F_GroupByGroupId(componentList, groupList);
  let displayFormat = F_ConvertToDisplayFormat(groupArray, componentList);
  return displayFormat;
};

export const F_getDashItemQueryType=(devInvolve, compInfo)=>{
  let queryType = "";
  let qty = 0;
  let devType = devInvolve.devType;
  let bdDev_id = devInvolve.bdDev_id;

  let queryObj = {devType, bdDev_id, queryType, qty};

  let returnArr = [];
  /** query type */
  if(compInfo.componentType===3)  queryObj.queryType = "lastN";
  if(compInfo.componentType===4)  queryObj.queryType = "lastN";
  if(compInfo.componentType===5)  queryObj.queryType = "dailyAccum";
  if(compInfo.componentType===6)  queryObj.queryType = "dailyAccum";
  if(compInfo.componentType===7)  queryObj.queryType = "lastN";
  /** query quantity */
  if(compInfo.componentType===3) queryObj.qty = 1;
  if(compInfo.componentType===4) queryObj.qty = 1;
  if(compInfo.componentType===5) queryObj.qty = 7;
  if(compInfo.componentType===6) queryObj.qty = 7;
  if(compInfo.componentType===7) queryObj.qty = 1;
  /** start end time */
  if(compInfo.componentType===5 ||
    compInfo.componentType===6 ){
      /** pass utc+8 timezone to backend */
    returnArr.push({...queryObj, timeValue:compInfo.StartTime, timezone:8});
    returnArr.push({...queryObj, timeValue:compInfo.EndTime, timezone:8});
  }else{
    returnArr.push(queryObj);
  }


  return returnArr; 
}

export const F_GetQueryIdx = (qInfo, qList)=>{
  let findIndex = qList.findIndex(c=>c.bdDev_id === qInfo.bdDev_id && 
    c.devType === qInfo.devType && 
    c.queryType === qInfo.queryType && 
    // (!Object.hasOwnProperty.call(qInfo, "timeType") || c.timeType === qInfo.timeType) &&
    (!Object.hasOwnProperty.call(qInfo, "timeValue") || c.timeValue === qInfo.timeValue)

    ) 

  return findIndex;
}

// export const F_CheckExistInReduxList=(QueryInfo, PrevList)=>{
//   try {
//     let inPrevList = PrevList.filter(c=>c.bdDev_id === QueryInfo.bdDev_id && 
//       c.devType === QueryInfo.devType && 
//       c.queryType === QueryInfo.queryType && 
//       // (!Object.hasOwnProperty.call(c, "loadTime") || c.loadTime + nDataValidTime_S > timeFn.getUnixNow()) &&
//       (!Object.hasOwnProperty.call(QueryInfo, "timeValue") || c.timeValue === QueryInfo.timeValue) 
//       ) 

//       if(isEmptyArr(inPrevList)) return 0; /** not exist in prev list */
      
//       /** exist in list, need to check whether covered */
//       let covered = inPrevList.filter(c=> c.qty >= QueryInfo.qty)
//       if(notEmptyArr(covered)) return 1  /** covered, exist */
//       else return 0;   /** not covered or not exist */
    
//   } catch (error) {
//     return 0;
//   }
// }

export const F_CheckExistQueryReq=(QueryInfo, QueryList)=>{
  try {
    /** check if not exist in list */
    let existInList = QueryList.filter(c=>c.bdDev_id === QueryInfo.bdDev_id && 
      c.devType === QueryInfo.devType && 
      c.queryType === QueryInfo.queryType && 
      (!Object.hasOwnProperty.call(QueryInfo, "timeValue") || c.timeValue === QueryInfo.timeValue) 
      ) 
    if(isEmptyArr(existInList)) return 0;   /** not exist in list, straight insert */
    /** exist in list */
    let covered = existInList.filter(c=> c.qty >= QueryInfo.qty)
    if(isEmptyArr(covered)) return 1  /** not covered, need to update */
    else return 2   /** covered, no need to do anything. */

    
  } catch (error) {
    return 0;   // not exist
  }
}


const F_GroupByGroupId = (componentList, groupList) => {
  if (isEmptyArr(componentList)) return [];
  let data = componentList;

  let sortKeys = [
    { key: "stackComponent", descOrder: false },
    { key: "sortIdx", descOrder: false },
  ];
  data.sort((a, b) => sortTemplate(a, b, sortKeys));

  // Initialize an empty array for each category
  const separatedData = {};

  data.forEach((item) => {
    const category = item.group_id;

    // Check if there's already an array for this category, if not, create one
    if (!separatedData[category]) {
      separatedData[category] = [];
    }
    // Push the item into the corresponding category array
    separatedData[category].push(item);
  });

  // Now, separatedData is an object where keys are categories, and values are arrays of objects

  let sortKeys_Group = [
    { key: "sortIdx", descOrder: false },
  ];
  groupList.sort((a, b) => sortTemplate(a, b, sortKeys_Group));
  
  // If you want to convert it to an array of arrays
  let arrayForm = [];
  for (const eachGroup of groupList) {
    if(notEmptyArr(separatedData[eachGroup._id])){
        arrayForm.push(separatedData[eachGroup._id]);
        delete separatedData[eachGroup._id];
    }
  }
  if(!isEmptyObj(separatedData)){
    for (const key in separatedData) {
        arrayForm.push(separatedData[key]);
    }
  } 
  return arrayForm;
};

const F_ConvertToDisplayFormat = (groupArray, componentList) => {
    
    let returnArr = [];

    for (const eachGroup of groupArray) {
        let separatedData = {};
        eachGroup.forEach((item) => {
          let _idGroup = item._id;
          const stackComponent = item.stackComponent;
          // Check if there's already an array for this category, if not, create one
          if (stackComponent > 0) {
            _idGroup = stackComponent;
            // separatedData[stackComponent].push(item)
          }
      
          if (!separatedData[_idGroup]) {
            separatedData[_idGroup] = [];
          }
          // Push the item into the corresponding category array
          separatedData[_idGroup].push(item);
        });
        let _group_id = 0;
        if(notEmptyArr(eachGroup) && eachGroup[0].group_id > 0){
            _group_id = eachGroup[0].group_id;
        }
        let compInGroup = componentList.filter(c=>c.group_id===_group_id && c.stackComponent ===0);        
        let sortKeys = [
            { key: "sortIdx", descOrder: false },
        ];
        compInGroup.sort((a, b) => sortTemplate(a, b, sortKeys));
        let tempArr = [];
        for (const eachCompGroup of compInGroup) {
            if(separatedData[eachCompGroup._id]){
                tempArr.push(separatedData[eachCompGroup._id]);
                delete separatedData[eachCompGroup._id];
            }
        }
        if(!isEmptyObj(separatedData)){
            console.log("Not Done Yet, should never come to this line, Precaution Purpose");
            for (const key in separatedData) {
                tempArr.push(separatedData[key]);
            }
        }
        
        returnArr.push(tempArr);
    }
    return returnArr;
};