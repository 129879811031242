import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { VscTriangleDown } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { getEditShareCfg } from "./../../../reduxStore/actions/dev_shareCfg";
import sensorOwnerFn from "../../../function/V2_SensorDevice/v2_SensorOwnerFn";
import { toast } from "react-toastify";
import shareEditToken from '../../../function/localStorage/lsEditShareFn';

function Choose2ndPara(props) {
  const editShareCfg = useSelector(getEditShareCfg);
  const [G_displayList, setG_displayList] = useState([]);
  const [G_loaded, setG_loaded] = useState(false);

  useEffect(() => {
    async function startUp() {
      if (
        !(
          editShareCfg.chooseMode === "Bd" || editShareCfg.chooseMode === "User"
        )
      )
        props.history.push({ pathname: "/device/editshare" });
      let loadRel = await displaySelectionList();
      // console.log(loadRel);
      if (loadRel) setG_loaded(true);
      //   if (editShareCfg.chooseMode === "Bd") await loadUserByBd();
      //   if (editShareCfg.chooseMode === "User") await loadBuildingByUser();
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const displaySelectionList = async () => {
    let newList = [];

    if (editShareCfg.chooseMode === "Bd") {
      let userList = await loadUserByBd();
      // console.log(bdList);
      if (!Array.isArray(userList) || !userList) {
        return toast("Load User List Error");
      }

      for (const user of userList) {
        newList.push({ title: user.email, _id: user._id });
      }
      // console.log(newList);
      // setG_displayList(newBdList);
    }

    if (editShareCfg.chooseMode === "User") {
      // let newBdList = [];
      let bdList = await loadBuildingByUser();
      // console.log(userList);
      if (!Array.isArray(bdList) || !bdList) {
        return toast("Load Building List Error");
      }

      for (const bd of bdList) {
        newList.push({ title: bd.name, _id: bd._id });
      }
    }

    setG_displayList(newList);
    return true;
  };

  const loadBuildingByUser = async () => {
    try {
      let user_id = editShareCfg.selected_user_id;
      let result = await sensorOwnerFn.getBdListByUId(user_id);
      // console.log(result);
      return result;
    } catch (error) {
      return null;
    }
    /**
     *
     * ---Backend---
     * select DISTINCT buidling_id from V2_ShareList_bdDev where active = 1 and user_id = user_id;
     * base on _id retrieve full Building list
     *  select * from <table> where _id in(n1, n2, n3);
     *  use do while loop
     */
  };
  const loadUserByBd = async () => {
    try {
      let bd_id = editShareCfg.selected_bd_id;
      // console.log(bd_id);
      let result = await sensorOwnerFn.getUniqueUserListByBdList([bd_id]);
      // console.log(result);
      return result;
    } catch (error) {
      return null;
    }
    /**
     *
     * select DISTINCT user_id from V2_ShareList_bdDev where active = 1 and buidling_id = bd_id;
     * base on _id retrive full user List
     *  select * from <table> where _id in(n1, n2, n3);
     *  use do while loop
     */
  };

  const backPrevPg = () => {
    // console.log("back");
    props.history.push({ pathname: "/device/editshare" });
  };

  const get2ndParameter = () => {
    if (editShareCfg.chooseMode === "Bd") return "User";
    if (editShareCfg.chooseMode === "User") return "Building";
  };

  const selectedParaName = () => {
    // console.log(editShareCfg);
    if (editShareCfg.chooseMode === "Bd") return editShareCfg.selected_bd_name;
    if (editShareCfg.chooseMode === "User")
      return editShareCfg.selected_user_email;
  };
  const back1stParaSel = () => {
    props.history.push({ pathname: "/device/editshare/choose" });
  };

  const selItem=async(c)=>{
    let user_id =editShareCfg.selected_user_id;
    let email = editShareCfg.selected_user_email;
    let bd_id = editShareCfg.selected_bd_id;
    let bd_name = editShareCfg.selected_bd_name;

    if(editShareCfg.chooseMode === "Bd"){   // 1st cpara is building
      user_id=c._id;
      email=c.title;
    }else{    // ist para is user
      bd_id=c._id;
      bd_name=c.title;
    }
    let cfgInfo = {      
      user_id,
      email,
      bd_id,
      bd_name,
    }
    await shareEditToken.setToken(cfgInfo);
    props.history.push({ pathname: "/device/share" });
  }

  return (
    <div>
      <div className="backContainer">
        <div className="backIconContainer" onClick={backPrevPg}>
          <IoIosArrowBack className="backIcon" />
        </div>
        <div className="backText">{`Edit Shared Device`} </div>
      </div>

      <div
        className="cfgCard cfgChooseBd errBgc cfgCardColor"
        id={`selBdBar`}
        onClick={back1stParaSel}
      >
        <div className="sortHor  ">
          <div
            style={{ color: "var(--greyoutText" }}
            className="cfgBdName"
          >{`${selectedParaName()}`}</div>

          <div className="stayRight stayRightElem">
            <VscTriangleDown />
          </div>
        </div>
      </div>
      <div className="backText">{`Choose ${get2ndParameter()}`} </div>
      <div>
        {G_displayList.length > 0 &&
          G_loaded &&
          G_displayList.map((c, ind) => (
            <div
              key={`bdRow_${ind}`}
              className="cfgOptionSel "
              onClick={() => selItem(c)}
            >
              <div className="wrapTextEllipsis">{c.title}</div>
              {/* <div className='wrapTextEllipsis'>hhhasoiudhaoishfoiahsoihdoiahsfoiahsoidhaoisfhoaosijoaihsoidahsoifhaoishdoaishfaishfoiashdoiahsofihaosidhaoishdoiasdh</div> */}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Choose2ndPara;
