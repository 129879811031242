import http from "../httpService";


async function getV2_AppMember(app_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/getappmember";
    let body = {app_id};
    return await http.post(url, body);  
}


const expModule = {  
    getV2_AppMember,
};
export default expModule;