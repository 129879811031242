import React, { useEffect, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import {FiTrash2} from "react-icons/fi";
import {BsArrowRight} from "react-icons/bs";

import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import SharedUserFn from '../../../function/V2_SensorDevice/v2_SensoSharedUserFn';
// import { getAccessLevelBy_Val, getNextLvlObjBy_Val, getCurUserAccessLvl } from '../../../v2Constant/v2_userLevel';
import userLvlFn from '../../../v2Constant/v2_userLevel';
import { toast } from 'react-toastify';


function ShareUserEditPg_V02(props) {

    // const [G_bd_id, setG_bd_id] = useState(0);
    const [G_sharedUserList, setG_sharedUserList] = useState([]);
    const [G_bSaving, setG_bSaving] = useState(false);
    const [G_bLoaded, setG_bLoaded] = useState(false);
    const [G_curUserLvlv, setG_curUserLvlv] = useState(false);

    useEffect(() => {
        async function startUp() {
            let bd_id = loadBd_id()
            await loadSharedUserInBd(bd_id);
            let curUserLvlv = await userLvlFn.getCurUserAccessLvl();
            setG_curUserLvlv(curUserLvlv)
            setG_bLoaded(true);
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);
        
    const loadSharedUserInBd=async(bd_id)=>{
        try {            
            let sharedUserList = await SharedUserFn.getSharedUserInBd(bd_id);
            for (const eachUser of sharedUserList) {
                eachUser.accessLvlObj = userLvlFn.getAccessLevelBy_Val(eachUser.accessLevel);
            }        
            // console.log("sharedUserList", sharedUserList);
            setG_sharedUserList(sharedUserList);
            // setG_sharedUserList_DB(sharedUserList);
        } catch (error) {
            console.log("loadSharedUserInBd", error.message);
        }
    }

    const backPrevPg=()=>{
        // if(bToBdPage){
        //     props.history.push({pathname: "/data/sensorinbd"});
        // }else{
            props.history.push({pathname: "/data/sensorinbd/setting"});
        // }
        
    }

    const loadBd_id=()=>{
        let bdInfo = localStorageFn.getBdToken();
        // console.log("bdInfo", bdInfo);    
        if(!bdInfo || !bdInfo._id) {
            props.history.push({pathname: "/"});    // kick out from current page
            return 
        }
        return bdInfo._id;
    }   

    const handleDelUser=(ind)=>{
        let userListDisplay = [...G_sharedUserList]
        userListDisplay[ind].bToDelete?
            userListDisplay[ind].bToDelete = false:
            userListDisplay[ind].bToDelete = true
        // console.log("userListDisplay", userListDisplay);
        setG_sharedUserList(userListDisplay)
    } 

    const handleChangeAccessLvl=(ind)=>{
        let userListDisplay = [...G_sharedUserList];
        let newLvl;
        userListDisplay[ind].newAccessLvl?
            newLvl = userListDisplay[ind].newAccessLvl:
            newLvl = userListDisplay[ind].accessLevel
        
        // console.log("userListDisplay[ind]", userListDisplay[ind]);
        // let curUserLvlv = await userLvlFn.getCurUserAccessLvl();
        let nextLvlObj = userLvlFn.getNextLvlObjBy_Val(newLvl, G_curUserLvlv);       
        userListDisplay[ind].newAccessLvl=nextLvlObj.value;
        userListDisplay[ind].newAccessLvlObj=nextLvlObj;
        // console.log("nextLvlObj", nextLvlObj);
        setG_sharedUserList(userListDisplay)
    }

    const handleSave=async ()=>{
        try {
            if(!G_bSaving){                
                setG_bSaving(true)
                let userListDisplay = [...G_sharedUserList];
                if(userListDisplay.length<1) return
                // console.log("userListDisplay", userListDisplay);
                let editRel = await SharedUserFn.editSharedUserInBd(userListDisplay)
                // console.log("editRel", editRel);
                if(editRel.errMsg) return toast(editRel.errMsg);
                if(editRel.success) toast("Success");    
                backPrevPg(true)
            }
        } catch (error) {
            console.log("Save Err:", error.message);
        } finally{
            setG_bSaving(false)
        }
    }

    const handleToAddShare=async ()=>{
        props.history.push({pathname: "/device/sharev2"});
        
    }
    
    

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={()=>backPrevPg()}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Edit Share List</div>
            </div>

            {!G_bLoaded && <div className='cfgCard cfgCardColor '>
                Loading...
            </div>}
            {G_bLoaded && G_sharedUserList.length < 1 && <div className='cfgCard cfgCardColor greyOutText hoverPointer'
            onClick={handleToAddShare}>
                {`< Share list emprt, click to add >`}
            </div>}
            {G_bLoaded && G_sharedUserList.length > 0 && G_sharedUserList.map((c,ind)=>
                <div className='cfgCard cfgCardColor'
                key={`user_${ind}`}>
                    <div className='sortHor'>                    
                        <div className='sortVer maxtLength_80P'>
                            <div className='text_1_1'>
                                {c.bToDelete && <del className='delLineDeco'>
                                    <div className='delTextDeco wrapTextEllipsis'>
                                        {/* junx130@gmail.commmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm */}
                                        {c.email}
                                    </div>
                                </del>}
                                {!c.bToDelete && <div className='delTextDeco wrapTextEllipsis'>
                                    {/* junx130@gmail.commmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm */}
                                    {c.email}
                                </div>}
                            </div>
                            {c.accessLvlObj.name && <div className='sortHor'>
                                <div className='cfgAccessLevelFrame cfgAccessLevelFrameSpacing'
                                onClick={()=>handleChangeAccessLvl(ind)}>
                                    {c.accessLvlObj.name}
                                </div>
                                {c.newAccessLvlObj && 
                                c.newAccessLvlObj.name!==c.accessLvlObj.name  &&
                                !c.bToDelete &&
                                <div className='sortHor'>
                                    <div className='cfgArrowStyle'>
                                        <BsArrowRight/>
                                    </div>
                                    <div className='cfgAccessLevelFrame cfgAccessLevelFrameSpacing'
                                    onClick={()=>handleChangeAccessLvl(ind)}>
                                        {c.newAccessLvlObj.name}
                                    </div>
                                </div>}
                            </div>}    
                        </div>
                        
                        <div className={`stayRight stayRightElem cfgAddEmailButton ${c.bToDelete?"redText":"greyOutIcon"} text_1_2`}
                        onClick={()=>handleDelUser(ind)}
                        >
                            <FiTrash2/>
                        </div>
                    </div>
                </div>
            )}

            {G_bLoaded && <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" 
                onClick={handleSave}
                >Save</div>
            </div>}
        </div>
    );
}

export default ShareUserEditPg_V02;