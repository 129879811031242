import { toast } from "react-toastify";
import { isEmptyArr, sortTemplate } from "../../../../function/ArrayFn/arrayFn";
import v2OwnDevFn from '../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import SensorMngtFn from '../../../../function/sensor/sensorManagementFn';

function F_v2aGetEachFloorDev(bdFloor, devInBd, hide_0DeviceArea){
    let floorDev = [];
    for (const eachFloor of bdFloor) {
        /** group dev in each floor */
        let devIncluded = devInBd.filter(c=> c.floor_id===eachFloor._id);
        let devsInFloor =[];
        for (const eachDevInc of devIncluded) {
            devsInFloor.push({bdDev_id:eachDevInc._id, area_id:eachDevInc.area_id});    // insert bdDev_id into array
        }
        /** filter floor with empty device for non owner user */
        if(hide_0DeviceArea && isEmptyArr(devIncluded)) continue
        floorDev.push({floor_id:eachFloor._id, floorName:eachFloor.name, devsInFloor:[...devIncluded]});
        // floorDev.push({floor_id:eachFloor._id, floorName:eachFloor.name, devsInFloor});
        
        /** remove the dev already put into group from list*/
        devInBd = devInBd.filter(c=> c.floor_id!==eachFloor._id);

    }

    if(!isEmptyArr(devInBd)){
        floorDev.push({floor_id:0, floorName:"Others", devsInFloor:[...devInBd]})
    }
    return floorDev;
    
}

function v2aGroupByArea(areaList, _devList, hide_0DeviceArea){
    let areaGroupList = [];
    let devList = [..._devList];
    for (const eachArea of areaList) {
        let DevInArea = devList.filter(c=>c.area_id === eachArea._id);
        /** filter area with 0 device for non owner level user */
        if(isEmptyArr(DevInArea) && hide_0DeviceArea) continue;

        let curArea = {area_id:eachArea._id, areaName:eachArea.name, devices:[...DevInArea]}
        areaGroupList.push(curArea);
        devList = devList.filter(c=>c.area_id !== eachArea._id);
    }
    if(devList.length>0){
        areaGroupList.push({area_id:0, areaName:"Others", devices:devList});
    }
    return areaGroupList;
}


const getDevInBd = async (bd_id)=>{
    let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(bd_id);
    if(devInBd.errMsg) {
        toast(devInBd.errMsg);
        return [];
    }
    return devInBd;
}

const filterDevForSharedUser=async(bdInfo, userInfo, devInBd)=>{
    /** filter device for shared user */
    if(bdInfo.shareLevel > 2){
        let sharedDev = await v2OwnDevFn.v2aGetShareBdDev_ByUserId_BdId(bdInfo._id, userInfo.user_id);
        if(sharedDev.errMsg){
            toast("Load share device err: ", sharedDev.errMsg)
            return [];
        } 

        let tempArrList = [];
        for (const eachDev of devInBd) {
            let foundIdx = sharedDev.findIndex(c=> c.accessLevel > 0 && c.bdDev_id === eachDev._id);
            if(foundIdx >=0 ) tempArrList.push(eachDev);
        }
        // devInBd = tempArrList;
        return tempArrList;
    }else{
        return devInBd;
    }
}

const sortFloor_byIdx=(floorList)=>{
    let tempArr = [...floorList];
    let sortKeys = [
        { key: "sortIdx", descOrder: false },
        { key: "name", descOrder: false },
    ];
    tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
    return tempArr
}

const getFloorInBd=async(bd_id)=>{
    let floorInBd = await v2OwnDevFn.getRelatedFloor(bd_id);
    if(floorInBd.errMsg) {
        toast(floorInBd.errMsg);
        return {err:true};
    }
    // setG_FloorInBd_Display(floorInBd);
    // setG_FloorInBd_Db(floorInBd);
    return floorInBd
}
const getDeviceOnEachFloor=async(devInBd, bdFloor, shareLevel)=>{
    // let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(bd_id);
    // if(devInBd.errMsg) return toast(devInBd.errMsg);
    /** target  =>  let floorDev = [{floorName : "1st floor", devsInFloor:[{bdDev_id, floor_id, name}]}]   */
    /** group by floor */
    
    let floorDev = await F_v2aGetEachFloorDev(bdFloor, devInBd, shareLevel > 2);
    return floorDev;
}

const filterFloorWithDev=(floorList, devList)=>{
    let newFloorList =[];
    for (const eachFloor of floorList) {
        let found = devList.find(c=>c.floor_id === eachFloor._id);
        if(found)   newFloorList.push(eachFloor);
    }
    return newFloorList;
}

const filterAreaWithDev=(areaList, devList)=>{
    let newAreaList=[];
    for (const eachArea of areaList) {
        let found = devList.find(c=>c.area_id === eachArea._id)
        if (found) newAreaList.push(eachArea);
    }
    return newAreaList;
}

const getTypeList=async(devInBd)=>{
    let tyList = []
    for (const eachDev of devInBd) {
        let found = tyList.find(c=> c === eachDev.type);
        if(!found) tyList.push(eachDev.type);            
    }
    let sensorList = await SensorMngtFn.getSensorListByTypeList(tyList);
    return sensorList;
}

const getTyList=(_devInbd)=>{
    /** Filter duplicated data */
    let uniqueTyDev = Array.from(
        new Set(_devInbd.map((a) => a.type))
    ).map((type) => {
        return _devInbd.find((a) => a.type === type);
    });
    let tyList = [];
    for (const dev of uniqueTyDev) {
        tyList.push(dev.type);
    }
    return tyList;
}

const getSensorParaList = async (tyList)=>{
    try {     
        if(tyList.length<1) return [];
        let paraList = await SensorMngtFn.getParaByTypeList(tyList);
        return paraList;
    } catch (error) {
        toast.error(error.message);    
        return false;
    }

}

const getSensorList=async (tyList)=>{
    let sensorList = await SensorMngtFn.getSensorListByTypeList(tyList);
    return sensorList;
}


const expModule = {
    F_v2aGetEachFloorDev,
    v2aGroupByArea,
    getDevInBd,
    filterDevForSharedUser,
    sortFloor_byIdx,
    getFloorInBd,
    getDeviceOnEachFloor,
    filterFloorWithDev,
    filterAreaWithDev,
    getTypeList,
    getTyList,
    getSensorParaList,
    getSensorList,
};
export default expModule;
  