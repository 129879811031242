import React, { useState } from "react";
import InputValidation from "../../template/Form/inputValidation";
// eslint-disable-next-line
import { Link } from "react-router-dom";
// import UserService from "../../../service/auth/userService";
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';
import InputTitleAtBoxShowHideIcon from './../../template/inputField/Input_titleAtBox_showHideIcon';
import auth from '../../../service/auth/AuthV2';
import { toast } from 'react-toastify';
import logo from '../../Logo/Aploud Logo slogan-01.png';

const UserLogin = (props) => {
  const [G_showPassword, setG_showPassword] = useState(false);
  // const {
  //   // eslint-disable-next-line
  //   inputs,
  //   setInputs,
  //   errors,
  //   setErrors,
  //   validateEmail,
  //   validatePassword,
  // } = InputValidation();

  // const handleChange = (input) => (e) => {
  //   setInputs({ [input]: e.target.value });
  // };

  const handleOnBlur = (errorId) => e => {
    // console.log(e.target);
    let error = InputValidation(e.target.id, e.target.value);
    if(error === null) document.getElementById(errorId).innerHTML = "";
    if(error !== null) {
      // console.log(error);
      document.getElementById(errorId).innerHTML = error;
    }
  }

  const handleSubmit = async () => {
    if(document.getElementById("usernameErrId").innerHTML === ""
      && document.getElementById("passErrId").innerHTML === "")
      await doSubmit();

    // if (e) e.preventDefault();

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // console.log(errors);
    // if (errors) return;
    // if (!captchaStatus) {
    //   setCaptchaError("Please verify reCAPTCHA");
    //   return;
    // }
    // if (validateEmail && validatePassword) doSubmit();
  };

  const doSubmit = async () => {
    // console.log("dosubmit");
    try {
      let loginDetails = getUsernameNPassword();
      // await UserService.login(loginDetails); // localStorage.setItem("token", response.headers["x-auth-token"]);
      //   console.log("header Token: ", response);
      //   console.log("header Token: ", response.data);
      //   auth.loginWithJwt(response.data);
      // headers["x-auth-token"];
      //   window.location = "/notactive";
      // console.log(response);
      //   this.props.history.push("/");
      let loginStatus = await auth.login(loginDetails);
      if(!loginStatus) toast('Incorrect username or password');
      await auth.updateCheckToken();

            // let stored = checkLocalStorage();
      props.history.push({pathname: "/"});
      // window.location = "/";
    } catch (error) {
      console.log(error.message);
      toast('Log In Error');
    }
  };

  const getUsernameNPassword = () => {
    // let email = document.getElementById('emailInput').value.trim();
    let username = document.getElementById('usernameInput').value.trim();
    let password = document.getElementById('passInput').value;
    return({username, password});
};

const showHidePassword = () => {
  setG_showPassword(!G_showPassword);
}

  return (
    <div className="form-container">
      <div className="form_frame spring_shadowBox ">
        <div className="flexAndBothCenter">
          <img alt ="AploudsLogo" src={logo} className='aplouds_logo' />
        </div>
        {/* <div className="form-title">Login</div> */}
        <div className="form-signin">
          {/* <div className="form-card"> */}
          {/* <TpInputTitleAtBox onBlur={handleOnBlur("emailErrId")} id='emailInput' type="text" errMsgid='emailErrId' title='E-mail' maxLen={255}/> */}
          <TpInputTitleAtBox onBlur={handleOnBlur("usernameErrId")} id='usernameInput' type="text" errMsgid='usernameErrId' title='Username' maxLen={255}/>
          <InputTitleAtBoxShowHideIcon
            onBlur={handleOnBlur("passErrId")}
            id='passInput'
            type={G_showPassword? "text" :"password"}
            errMsgid='passErrId'
            title='Password'
            maxLen={255}
            showInput={G_showPassword}
            showHideInput={showHidePassword}
          /> 
            {/* <div className="flexVer">
              <label className="form-label">Username</label>
              <input
                className="form-input"
                type="text"
                id="username"
                name="Username"
                onChange={handleChange("username")}
                onBlur={validateUsername}
              ></input>
            </div>
            <div className="form-error">{errors.username}</div> */}
            {/* <TpInputTitleAtBox
              onBlur={handleOnBlur("passErrId")}
              id='passInput'
              type={G_showPassword? "text" :"password"}
              showHideIcon={true}
              errMsgid='passErrId'
              title='Password'
              showHideInput={G_showPassword}
              showHideFn={showHidePassword()}
              maxLen={255}/> */}
            {/* <div className="flexVer">
              <label className="form-label">Password</label>
              <input
                className="form-input"
                type="password"
                id="password"
                name="Password"
                onChange={handleChange("password")}
                onBlur={validatePassword}
              ></input>
            </div>
            <div className="form-error">{errors.username}</div> */}
          {/* </div> */}
          <div className="buttonContainer" style={{marginTop:"40px"}}>
            <div className="roundButton spring_shadowBox" style={{ backgroundColor: 'var(--btnBlue)' }} onClick={() => handleSubmit()}>Login</div>
          </div>
          {/* <div align="center">
            <button className="form-button" onClick={() => handleSubmit()}>Login</button>
          </div> */}
          {/* <div className="form-btm">
            <div style={{marginBottom: "10px"}}>
              Don't have an account?
              <Link to="/user/register" className="form-inlink">
                Sign Up Now
              </Link>
            </div>
            <div>
              Forget your password?
              <Link to="/user/forgetpassword" className="form-inlink">
                Click here
              </Link>
          </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
