import React, { Component } from "react";
import TpOptionsList from "../../template/SelectionList/TpOptionsList";
import TpDeviceSummary from "./../../template/devSummary/TpDevSummary";
import sumListFn from "../../../function/present_V2/sumListFn";
import areaListFn from "../../../function/present_V2/areaListFn";
import sensorFn from "../../../function/sensor/sensorManagementFn";
import "../../../css/presentV2.css";

class ViewData_OverviewPg extends Component {
  state = {
    dataSummary: [],
    typeList: [],
    bRefresh: false,
  };

  async componentDidMount() {
    try {
      await this.genDisplayList();
      await this.fillUpData();
    } catch (error) {
      console.log(error.message);
    }
  }

  async genDisplayList() {
    try {
      // eslint-disable-next-line
      let user_Id = 1;
      let Bd_Id = 1;
      /** get the summary list in this building */
      let summaryList = await this.getSummaryList(Bd_Id);
      console.log(summaryList);
      let typeArr = [];
      for (const sumItem of summaryList) {
        // console.log(sumItem);
        let found = typeArr.find((c) => c === sumItem.type);
        if (!found) typeArr.push(sumItem.type);
      }
      let typeList = await sensorFn.getParaByTypeList(typeArr);
      // console.log(typeList);
      await this.setState({ typeList });
      /** get area list in building */
      let areaList = await this.getAreaList(Bd_Id);
      // console.log(areaList);
      /** loop through area id, filted with  */
      for (const area of areaList) {
        let filtered = summaryList.filter((c) => c.areaId === area.sortIndex);
        area.devices = filtered;
        // console.log(filtered);
        /** remove the filtered item from list */
        summaryList = summaryList.filter((c) => c.areaId !== area.sortIndex);
      }
      // console.log(areaList);
      // console.log(summaryList);

      /** handle not found area list */
      if (summaryList[0]) {
        let otherAreaList = {
          Bd_Id: Bd_Id,
          areaName: "Other",
          sortIndex: areaList.length + 1,
          devices: summaryList,
        };
        // console.log(otherAreaList);
        areaList.push(otherAreaList);
      }
      console.log(areaList);
      await this.setState({ dataSummary: areaList });
    } catch (error) {
      console.log(error.message);
    }
  }

  async fillUpData() {
    try {
      let dataSummary = [...this.state.dataSummary];
      for (const area of dataSummary) {
        /** Pull data from database one by one */
        if (Array.isArray(area.devices)) {
          for (const dev of area.devices) {
            // console.log(dev.bdDev_id);
            /** Query data for each device */
            let lastData = await sumListFn.getLastNData(
              dev.type,
              dev.bdDev_id,
              1
            );
            // console.log(lastData);
            // console.log(dev);
            /** How to handle display percentage ???*/
            dev.lastData = lastData;
          }
          /** try set state by every area */
          await this.setState({ dataSummary });
          await this.setState({ bRefresh: true });
          await this.setState({ bRefresh: false });
        }
      }
      console.log(this.state.dataSummary);
    } catch (error) {
      console.log(error.message);
    }
  }

  async getSummaryList(Bd_Id) {
    try {
      let sumList = await sumListFn.getByBd_Id(Bd_Id);
      return sumList;
    } catch (error) {
      console.log(error.message);
    }
  }
  async getAreaList(Bd_Id) {
    try {
      let areaList = await areaListFn.getByBd_Id(Bd_Id);
      return areaList;
    } catch (error) {
      console.log(error.message);
    }
  }

  fnGetTitleNUnit(ty, dataKey) {
    try {
      let typeList = [...this.state.typeList];
      let found = typeList.find(
        (c) => c.sensorType === ty && sensorFn.getDataKey(c) === dataKey
      );
      let rtnItem = {
        title: found ? found.dataName : "",
        unit: found ? found.dataUnit : "",
      };
      return rtnItem;
    } catch (error) {
      console.log(error.message);
      let rtnItem = {
        title: "",
        unit: "",
      };
      return rtnItem;
    }
  }

  fmtCvrt_SumDisplay(dbFormat) {
    try {
      let devSumData = {
        deviceName: dbFormat.PresName,
        batteryPercentage: 88,
        signalStrength: 78,
        a_display: [
          // {name: dbFormat.display1, sValue: s1},
          // {name: dbFormat.display2, sValue: s2},
          // {name: dbFormat.displa     y4, sValue: 'Loading...'},
          // {name:'humidity', sValue: '58.8 %'},
          // {name:'inletTemp', sValue: '23.8 C'},
        ],
        a_ctrl: [{ name: dbFormat.ctrl1 }, { name: dbFormat.ctrl2 }],
      };
      if (dbFormat.lastData && this.state.typeList[0]) {
        /** Insert value into a_display ???*/
        for (let i = 1; i < 5; i++) {
          if (
            !(
              dbFormat[`display${i}`] === "" || dbFormat[`display${i}`] === null
            )
          ) {
            let keyDetails = this.fnGetTitleNUnit(
              dbFormat.type,
              dbFormat[`display${i}`]
            );
            devSumData.a_display[i - 1] = {
              name: keyDetails.title,
              sValue: `${dbFormat.lastData[dbFormat[`display${i}`]]} ${
                keyDetails.unit
              }`,
            };
          }
        }
      }

      return devSumData;
    } catch (error) {
      console.log(error.message);
      return;
    }
  }

  getInfo = () => {
    console.log(this.state.tpOptionSelected);
  };

  handleTpOptionSel = (id, selOption) => {
    // console.log(id);
    // console.log(selOption);
    let tpOptionSelected = { ...this.state.tpOptionSelected };
    tpOptionSelected[id] = selOption;
    this.setState({ tpOptionSelected });
  };

  handleCmdClick = async (value) => {
    console.log(`Hanlde Form outside : ${value}`);
  };

  render() {
    return (
      <>
        <h3>Data Overview</h3>
        <TpOptionsList
          id="Sel1" /* Must */
          selOption={this.handleTpOptionSel} /* Must */
          options={this.state.list} /*Must, but Optional */
          placeholder="Selection~~" /*Optional */
          defaultValue={7} /*Optional */
        />
        <div onClick={this.getInfo}>GET INFO</div>

        {this.state.dataSummary.map((u, ind) => (
          <div key={`area_${ind}`}>
            <div
              key={`areaName_${ind}`}
              style={{
                textAlign: "center",
                marginTop: "5px",
                marginBottom: "15px",
                fontSize: "23px",
              }}
            >
              {u.areaName}
            </div>
            <div className="sumCardContainer">
              {!this.state.bRefresh &&
                u.devices.map((k, ind_k) => (
                  <div key={`dev_${ind}_${ind_k}`} className="sumCard">
                    <TpDeviceSummary
                      ctrlCmd={this.handleCmdClick}
                      devSumData={this.fmtCvrt_SumDisplay(k)}
                      devType={k.type}
                    ></TpDeviceSummary>
                  </div>
                ))}
            </div>
          </div>
        ))}
        {/* <TpDeviceSummary 
                ctrlCmd={this.handleCmdClick} 
                devSumData={fmtCvrt_SumDisplay()}
                ></TpDeviceSummary>
            <TpDeviceSummary 
                ctrlCmd={this.handleCmdClick} 
                ></TpDeviceSummary> */}
      </>
    );
  }
}

export default ViewData_OverviewPg;
