import senSharedSer from '../../service/V2_SensorDevice/v2_SensorSharedUser';


async function getBy_bdid_user_accessLvl(buidling_id, user_id, accessLevel){    
    // console.log("Cone In");
    try {
        let rel = await senSharedSer.getBy_bdid_user_accessLvl(buidling_id, user_id, accessLevel);
        // console.log(rel);
        if(rel.statusText==='OK'){
            return rel.data;
        } 
        return [];

    } catch (error) {
        console.log(error.message);
        return [];
    }
}

async function sensorShare(shareInfo){    
    try {
        let rel = await senSharedSer.sensorShare(shareInfo);
        // console.log(rel);
        if(rel.statusText==='OK'){
            return true;
        } 
        return {errMsg:"Share Sensor Error"};

    } catch (error) {
        console.log(error.message);
        return {errMsg:"Share Sensor Error(Exp)"};
    }
}

async function getSharedBd_ByUserId_Bd_id(user_id, bd_id){    
    // console.log("Cone In");
    try {
        let rel = await senSharedSer.getSharedBd_ByUserId_Bd_id(user_id, bd_id);
        // console.log(rel);
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg:"Get Device List Error(Exp)"};
    }
}

async function getSharedBd_ByUserId_Bd_idv2(user_id, bd_id){    
    // console.log("Cone In");
    try {
        let rel = await senSharedSer.getSharedBd_ByUserId_Bd_idv2(user_id, bd_id);
        // console.log(rel);
        return rel.data;

    } catch (error) {
        console.log(error.message);
        return {errMsg:"Get Device List Error(Exp)"};
    }
}

async function getSharedUserInBd(bd_id){    
    // console.log("Cone In");
    try {
        let rel = await senSharedSer.getSharedUserInBd(bd_id)
        // console.log(rel);
        return rel.data;
        // if(rel.statusText==='OK'){
        //     return rel.data;
        // } 
        // return [];

    } catch (error) {
        console.log(error.message);
        return {errMsg:"Get Device List Error(Exp)"};
    }
}

async function editSharedUserInBd(userList){    
    try {
        let rel = await senSharedSer.editSharedUserInBd(userList);
        // console.log(rel);
        return rel.data
        // if(rel.statusText==='OK'){
        //     return rel.data
        // } 
        // return {errMsg:"Edit Shared User Error"};

    } catch (error) {
        console.log(error.message);
        return {errMsg:"Edit Shared User Error(Exp)"};
    }
}


const expModule = {  
    getBy_bdid_user_accessLvl,
    sensorShare,
    getSharedBd_ByUserId_Bd_id,
    getSharedBd_ByUserId_Bd_idv2,
    getSharedUserInBd,
    editSharedUserInBd,
};
export default expModule;