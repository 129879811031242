import React, { useEffect } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { VscTriangleDown } from "react-icons/vsc";
import { resetCfgInfo, setChooseMode } from '../../../reduxStore/actions/dev_shareCfg';
// import { getEditShareCfg } from './../../../reduxStore/actions/dev_shareCfg';
// eslint-disable-next-line
import { useSelector, useDispatch } from 'react-redux';

function EditSharePage(props) {
    // const editShareCfg = useSelector(getEditShareCfg);
    const dispatch = useDispatch(); 

    useEffect(() => {
        async function startUp() {
            /** clear EditShareCfg */
            dispatch(resetCfgInfo());
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);

    const backPrevPg=()=>{
        // console.log("back");
        props.history.push({pathname: "/data/bdoverview"});
    }
    const ChooseBuilding=()=>{
        // console.log("chooseBuilding");
        dispatch(setChooseMode({chooseMode:"Bd"}));
        // console.log(editShareCfg);
        props.history.push({pathname: "/device/editshare/choose"});
    }
    const chooseUser=()=>{
        // console.log("chooseUser");
        dispatch(setChooseMode({chooseMode:"User"}));
        props.history.push({pathname: "/device/editshare/choose"});
        // props.history.push({pathname: "/data/bdoverview"});
    }

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Edit Shared Device</div>
            </div>
            
            <div className='cfgCard cfgChooseBd errBgc cfgCardColor'
            id={`selBdBar`}
            onClick={ChooseBuilding}>
                <div className='sortHor  '>
                    <div style={{color:"var(--greyoutText"}} className='cfgBdName'>{`< Choose Building >`}</div>
                    
                    <div className='stayRight stayRightElem'><VscTriangleDown/></div> 
                </div>
            </div>

            <div className='cfgCard cfgChooseBd errBgc cfgCardColor'
            id={`selBdBar`}
            onClick={chooseUser}>
                <div className='sortHor  '>
                    <div style={{color:"var(--greyoutText"}} className='cfgBdName'>{`< Choose User >`}</div>
                    
                    <div className='stayRight stayRightElem'><VscTriangleDown/></div> 
                </div>
            </div>
        </div>
    );
}

export default EditSharePage;