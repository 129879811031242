import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BsFillTriangleFill } from "react-icons/bs";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";
import { FaMicrochip } from "react-icons/fa6";
import { MdCloudCircle } from "react-icons/md";
import { isEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";

const MRVfdSetting = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadVfdSetting(mode, _pf, _pi) {
      F_FillUpVfdSetting(mode, _pf, _pi);
    },
  }));

  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  const [G_storageMode, setG_storageMode] = useState("db");

  useEffect(() => {
    async function startUp() {
        if (!props.gwId) setG_GwID(0);
        else setG_GwID(props.gwId);
        if(props.handleLoadDbVfdSetting) props.handleLoadDbVfdSetting();
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const F_FillUpVfdSetting=(mode, _pf, _pi)=>{
    if(isEmptyArr(_pf)) {
        F_SetInputVal_Number(`vfd_maxHz_${props.pos}`, null);
        F_SetInputVal_Number(`vfd_minHz_${props.pos}`, null);
        F_SetInputVal_Number(`vfd_step_${props.pos}`, null);
    } else {
        F_SetInputVal_Number(`vfd_maxHz_${props.pos}`, _pf[0]);
        F_SetInputVal_Number(`vfd_minHz_${props.pos}`, _pf[1]);
        F_SetInputVal_Number(`vfd_step_${props.pos}`, _pf[2]);
    }
    if(isEmptyArr(_pi)) {
        F_SetInputVal_Number(`vfd_delay_${props.pos}`, null);
    } else {
        F_SetInputVal_Number(`vfd_delay_${props.pos}`, _pi[0]);
    }
    setG_storageMode(mode);
  }

  const setGw = () => {
    setG_statusState(1);
  };

  const handleCloseDia = () => {
    setG_statusState(0);
  };

  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };

  const F_switchToNoMode = () => {
    if(G_storageMode !== "none") setG_storageMode("none");
  }

  const F_getCtrlMethodInfo = () => {
    let ctrlMethod = {
        maxHz: F_GetInputVal_Number(`vfd_maxHz_${props.pos}`),
        minHz: F_GetInputVal_Number(`vfd_minHz_${props.pos}`),
        delay: F_GetInputVal_Number(`vfd_delay_${props.pos}`),
        step: F_GetInputVal_Number(`vfd_step_${props.pos}`),
    }

    for (const key in ctrlMethod) {
        if (Object.hasOwnProperty.call(ctrlMethod, key)) {
          if(ctrlMethod[key]===null){
            toast(`Invalid input (${key})`)
            return
          }        
        }
    }

    return ctrlMethod;
  }

  const F_handleLoadDb = () => {
    if(G_storageMode === "db") return;
    if(props.handleLoadDbVfdSetting) props.handleLoadDbVfdSetting();
  }

  const F_handleLoadNode = () => {
    if(G_storageMode === "node") return;
    if(props.handleLoadNodeVfdSetting) props.handleLoadNodeVfdSetting();
  }

  const F_saveToDb = () => {
    if(props.handleSendToDbVfdSetting) {
        let ctrlMethod = F_getCtrlMethodInfo();
        props.handleSendToDbVfdSetting(ctrlMethod);
    }
  }

  const F_sendToNode = () => {
    if(props.handleSendToNodeVfdSetting) {
        let passCode = F_GetInputVal_Number(`vfd_pass_${props.pos}`);
        if (passCode === null) return toast(`Invalid input (Password)`);
        let ctrlMethod = F_getCtrlMethodInfo();
        props.handleSendToNodeVfdSetting(ctrlMethod, passCode);
    }
  }

  return (
    <div className="spring_SL_TabPg">
        {G_statusState === 1 && (
        <TpDiaGatewayID
          divInfo={props.dev}
          gwPair={G_GwID}
          onClickDiaClose={handleCloseDia}
          setGwId={handleSetGwDisplay}
        />
      )}

        <div className="sortHor">
            <div className="spring_tpContentTitle">General</div>
            <div className="stayRight" style={{ marginRight: "10px" }}>
            <FaMicrochip
                className={`hoverPointer spring_shadowBox_Small spring_storageModeButton ${G_storageMode === "node"? "reactCfgButtonOn " : "reactCfgButtonOff" }`}
                style={{ marginRight: "10px" }}
                onClick={F_handleLoadNode}
            />
            <MdCloudCircle
                className={`hoverPointer spring_shadowBox_Small spring_storageModeButton ${G_storageMode === "db"? "reactCfgButtonOn " : "reactCfgButtonOff"}`}
                onClick={F_handleLoadDb}
            />
            </div>
        </div>
        <div className="spring_tpContentGroup">
            <div className="sortHor spring_tpContent">
            <div>Pair Gateway</div>
            <div
                className="stayRight blueText spring_tpContentValue hoverPointer"
                onClick={setGw}
            >
                {G_GwID}
            </div>
            </div>
        </div>
        
        <div className="spring_tpContentTitle">Setting</div>
        <div className="spring_tpContentGroup">
            <div className="sortHor spring_tpContent">
                <div>Max Hz</div>
                <input
                    style={{ width: "100px", textAlign: "end" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                    id={`vfd_maxHz_${props.pos}`}
                    placeholder="--"
                    onChange={F_switchToNoMode}
                ></input>
            </div>
        <div className="sortHor spring_tpContent">
          <div>Min Hz</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`vfd_minHz_${props.pos}`}
            placeholder="--"
            onChange={F_switchToNoMode}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Adjust Delay (in s)</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`vfd_delay_${props.pos}`}
            placeholder="--"
            onChange={F_switchToNoMode}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Adjust Step (Hz)</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`vfd_step_${props.pos}`}
            placeholder="--"
            onChange={F_switchToNoMode}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentTitle">Security</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Password</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`vfd_pass_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      {G_storageMode === "node" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_saveToDb}>Save to DB</div>}
      {G_storageMode === "db" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Send To Node</div>}
      {G_storageMode === "none" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Update</div>}
    </div>
  );
});

export default MRVfdSetting;
