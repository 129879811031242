import React, { useEffect, useState } from 'react';
import { isEmptyArr, sortTemplate } from '../../../../../function/ArrayFn/arrayFn';
// import localStorageFn  from '../../../../../function/localStorage/LocalStorageFn';
// import v2OwnDevFn from '../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';

function TpSubDiaChooseArea(props) {

    const [G_Table_Display, setG_Table_Display] = useState([]); 
    const [G_Table_Db, setG_Table_Db] = useState([]); 
    const [G_Selected_id, setG_Selected_id] = useState(-2); 
    
    useEffect(()=>{
        async function startUp(){
            // let bdInfo  = localStorageFn.getBdToken();
            /** load device in bd */
            if(!props.selectedFloor) return
            if(props.selectedFloor.floor_id <= 0) return;            
            let devList = [];
            for (const eachDev of props.devInBd) {
                devList.push({...eachDev});
            }

            let allArea = [];
            for (const eachArea of props.areaInDb) {
                allArea.push({...eachArea});
            }
            let areaList_db = allArea.filter(c=>c.floor_id === props.selectedFloor.floor_id);
            areaList_db = sortArr_byName(areaList_db);
            let areaList = generateInitAreaList(devList, areaList_db, props.selectedFloor.floor_id);
            // let _Table_Display = await generateInitFloorLost(bdInfo._id, areaList);
            
            setG_Table_Db(areaList);
            setG_Table_Display(areaList);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    //bdInfo._id, props.selectedFloor.floor_id, devList, areaList
    const generateInitAreaList=(devList, areaList, floor_id)=>{
        /** load floor in bd */
        let devInFloor = devList.filter(c=>c.floor_id === floor_id);
        let bOthersExist = false;
        for (const eachDev of devInFloor) {
            let foundIdx = areaList.findIndex(c=>c._id === eachDev.area_id);
            if(foundIdx < 0) {
                bOthersExist = true;
                break;
            }
        }
        let _Table_Display=[];
        if (!props.skipShowAll) _Table_Display=[{area_id:-1, areaName:"All"}];
        for (const eachArea of areaList) {
            _Table_Display.push({area_id:eachArea._id, areaName:eachArea.name});                
        }
        if(bOthersExist || props.skipShowAll) _Table_Display.push({area_id:0, areaName:"Others"})
        return _Table_Display;
    }

    const sortArr_byName=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "name", descOrder: false },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }


    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        let areaSelected = G_Table_Db.filter(c=>c.area_id === G_Selected_id);
        if(isEmptyArr(areaSelected)) return
        if(props.onclickConfirm) {
            props.onclickConfirm(areaSelected);
        }
    }
    

    const handleSearch=()=>{
        try {
            let searchInput = document.getElementById('searchInput_choseA').value.trim();
            if(searchInput==="") {
                setG_Table_Display(G_Table_Db);
                return
            }
            let _Table_Db =[...G_Table_Db];
            let filtered = _Table_Db.filter(c=>c.areaName.toLowerCase().includes(searchInput.toLowerCase()));
            setG_Table_Display(filtered);
            /** clear selected if item been filtered */
            let selPos = filtered.findIndex(c=>c.area_id === G_Selected_id);
            if(selPos<0) setG_Selected_id(-2);
        } catch (error) {
            toast("Err: ", error.message)
        }
    }

    const chooseItem=(selectedItem, ind)=>{
        setG_Selected_id(selectedItem.area_id);
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox'>
                                        
                    <div className ='searchBarContainer_non sortHor' style={{margin:"15px 0px 10px 0px", width:"240px"}}>
                        <div style={{width:"80%"}} className="">
                            <input id='searchInput_choseA' 
                                autoComplete='off'
                                onChange={handleSearch} 
                                className='spring_diaSearchInput'
                                style={{width:"100%"}}
                                />
                        </div>
                        <div className='stayRight' style={{marginRight:"10px"}}>
                            <FaSearch/>
                        </div>    
                    </div>

                    <div className='spring_diaItemFrame_SubDia'>
                        {G_Table_Display.map((u, ind)=>(<div key={`devSel_${ind}`} className='sortHor hoverPointer'
                        onClick={()=>chooseItem(u, ind)}>
                            <div id={`selId_${ind}`} className={`spring_TableItem wrapTextEllipsis ${G_Selected_id===u.area_id?"spring_itemSelected":""}`}>
                                {`${u.areaName}`}
                            </div>
                        </div>))}       
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpSubDiaChooseArea;