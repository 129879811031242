import React, {  } from 'react'

const DashCancelConfirmButton = () => {
    


  return (
    <div className='spring_ConfirmButtonBox sortHor spreadEvenly'>
        <div className='spring_acConfirmButton spring_acConfirm_L hoverPointer'>Back</div>
        <div className='spring_acConfirmButton spring_acConfirm_R hoverPointer'>Confirm</div>
    </div>
  )
}

export default DashCancelConfirmButton
