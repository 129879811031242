// eslint-disable-next-line
import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import { Link, useParams } from "react-router-dom";
import InputValidation from "../../template/Form/inputValidation";
import UserService from "../../../service/auth/userService";
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';
import { toast } from 'react-toastify';

const UserForgetPassword = () => {
  // const {
  //   // eslint-disable-next-line
  //   inputs,
  //   setInputs,
  //   errors,
  //   setErrors,
  //   validateUsername,
  //   validateCfmUsername,
  //   validateEmail,
  //   validateCfmEmail,
  // } = InputValidation();
  // const [error, setError] = useState();
  // const { status } = useParams();

  // useEffect(() => {}, [status]);

  // const handleChange = (input) => (e) => {
  //   setInputs({ [input]: e.target.value });
  // };

  const handleOnBlur = (errorId) => e => {
    // console.log(e.target);
    let error = InputValidation(e.target.id, e.target.value, document.getElementById("emailInput").value);
    if(error === null) document.getElementById(errorId).innerHTML = "";
    if(error !== null) {
      // console.log(error);
      document.getElementById(errorId).innerHTML = error;
    }
  }

  const handleSubmit = async () => {
    // if (e) e.preventDefault();

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // console.log(errors);
    // if (errors) return;
    // if (!captchaStatus) {
    //   setCaptchaError("Please verify reCAPTCHA");
    //   return;
    // }
    // if (status === "username") {
    //   if (validateUsername && validateCfmUsername) doSubmit();
    // } else if (status === "email") {
    if (document.getElementById("emailErrId").innerHTML === ""
      && document.getElementById("cfmEmailErrId").innerHTML === "")
      await doSubmit();
    // }
  };

  const doSubmit = async () => {
    try {
      let userData = {};
      // if (status === "username") {
      //   let username = document.getElementById("username").value;
      //   userData.username = username;
      //   userData.email = null;
      // } else if (status === "email") {
        let email = document.getElementById("emailInput").value;
        userData.email = email;
      // }

      let result = await UserService.reqResetPassword(userData); // localStorage.setItem("token", response.headers["x-auth-token"]);
      // console.log(result);
      if (result.status === 203) {
        toast(result.data);
        return;
      }
      //   console.log("header Token: ", response);
      //   console.log("header Token: ", response.data);
      //   auth.loginWithJwt(response.data);
      // headers["x-auth-token"];
      //   window.location = "/notactive";
      // console.log(response);
      //   this.props.history.push("/");
      window.location = "/email/success";
    } catch (error) {
      console.log(error.message);
      toast('Forget Password Error');
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Reset Password</h2>
      <div className="form-signin">
      <TpInputTitleAtBox onBlur={handleOnBlur("emailErrId")} id='emailInput' type="text" errMsgid='emailErrId' title='E-mail' maxLen={255}/>
      <TpInputTitleAtBox onBlur={handleOnBlur("cfmEmailErrId")} id='cfmEmailInput' type="text" errMsgid='cfmEmailErrId' title='Confirm E-mail' maxLen={255}/>
      <div className="buttonContainer" style={{marginTop:"40px"}}>
          <div className="roundButton" onClick={() => handleSubmit()}>Submit</div>
      </div>
        {/* {status === "username" && (
          <div className="form-card">
            <div className="flexVer">
              <label className="form-label">Username</label>
              <input
                className="form-input"
                type="text"
                id="username"
                name="Username"
                onChange={handleChange("username")}
                onBlur={validateUsername}
              ></input>
            </div>
            <div className="form-error">{errors.username}</div>
            <div className="flexVer">
              <label className="form-label">Confirm Username</label>
              <input
                className="form-input"
                type="text"
                id="cfmUsername"
                name="Confirm Username"
                onChange={handleChange("cfmUsername")}
                onBlur={validateCfmUsername}
              ></input>
            </div>
            <div className="form-error">{errors.cfmUsername}</div>
          </div>
        )}
        {status === "email" && (
          <div className="form-card">
            <div className="flexVer">
              <label className="form-label">Email</label>
              <input
                className="form-input"
                type="email"
                id="email"
                name="Email"
                onChange={handleChange("email")}
                onBlur={validateEmail}
              ></input>
            </div>
            <div className="form-error">{errors.email}</div>
            <div className="flexVer">
              <label className="form-label">Confirm Email</label>
              <input
                className="form-input"
                type="email"
                id="cfmEmail"
                name="Confirm Email"
                onChange={handleChange("cfmEmail")}
                onBlur={validateCfmEmail}
              ></input>
            </div>
            <div className="form-error">{errors.cfmEmail}</div>
          </div>
        )} */}
        {/* <div align="center">
          <button className="form-button">Submit</button>
          <div className="form-error">{error}</div>
        </div> */}
        {/* {status === "username" && (
          <p className="form-btm">
            Forget your username?
            <Link to="/user/forgetpassword/email" className="form-inlink">
              Click here to use email
            </Link>
          </p>
        )} */}
        {/* {status === "email" && ( */}
          {/* <p className="form-btm">
            <Link to="/user/forgetpassword/username" className="form-inlink">
              Click here to use usename
            </Link>
          </p> */}
        {/* )} */}
        <div className="form-btm">
          <Link to="/user/login" className="form-inlink">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserForgetPassword;
