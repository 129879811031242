import React, { useEffect, useState } from 'react';
import {notEmptyArr, sortTemplate, isEmptyArr} from '../../../../../function/ArrayFn/arrayFn';
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import sensorMngFn from '../../../../../function/sensor/sensorManagementFn';
import moment from "moment";
import DashItemTitleBar from './Common/DashItemTitleBar';
import { useSelector } from 'react-redux';
import { getFullDashDataList } from '../../../../../reduxStore/actions/dash_Data';
import { toast } from 'react-toastify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PinwheelLoader from '../../../Version2a/Template/LoaderTemplate/pinwheelLoader';

function C0006MultiBarDaily(props) {
    const fullList = useSelector(getFullDashDataList);

    const [G_loaded, setG_loaded] = useState(false);
    const [G_Display, setG_Display] = useState([]);
    const [G_chartData, setG_ChartData] = useState([]);

    useEffect(() => {
        async function startUp() {
            // let bdInfo = localStorageFn.getBdToken();
            let { compInfo, auxList } = props;
            let getDataObj = getData(compInfo.bdDevInvolve_List, fullList, compInfo.StartTime, compInfo.EndTime);
            let _displayInfo = {
                chartData: getDataObj.displayInfo,
                barTitle: auxList.find(c => c.auxId === 'barTitle').auxValue,
                axisTitle: `${auxList.find(c => c.auxId === 'axisName').auxValue} (${auxList.find(c => c.auxId === 'unit').auxValue})`
            };
            let _chartData = genChartData(_displayInfo.chartData);
            setG_Display(_displayInfo);
            setG_ChartData(_chartData);
            setG_loaded(getDataObj.someDataIn);
        }
        startUp();
    }, [props]);

    const getData=(_devInvolve, dataList, _startTime, _endTime)=>{
        let devInvolve = [..._devInvolve];
        /** sort the data list */
        let sortKeys = [
            { key: "dataSortIdx", descOrder: false },
        ];
        devInvolve.sort((a, b) => sortTemplate(a, b, sortKeys));

        /** filter and push the data value */
        let displayInfo = {};
        let someDataIn = true;
        for (const eachDev of devInvolve) {
            let currDisplayInfo = {labels: [], data: []};
            let devData = dataList.filter(c=>
                c.bdDev_id === eachDev.bdDev_id && 
                c.devType === eachDev.devType && 
                c.qty >= 7 && 
                c.queryType === "dailyAccum" &&
                (c.timeValue === _startTime || c.timeValue === _endTime)
            )
            if(isEmptyArr(devData)) someDataIn = false;
            let paraKey = sensorMngFn.getParaKey_int(eachDev.dataType, eachDev.dataIndex);
            try {
                if(notEmptyArr(devData)){
                    let startArr = devData.find(c => c.timeValue === _startTime);
                    let endArr = devData.find(c => c.timeValue === _endTime);
                    for(let i = 0; i < 7; i++) {
                        if(i === 6) currDisplayInfo.labels.push([`Today`, `(Until ${moment.unix(endArr.data[i].unix).tz("Asia/Kuala_Lumpur").format("HH:mm")})`]);
                        else currDisplayInfo.labels.push(moment.unix(startArr.data[i].unix).format("DD/M/YY"));
                        currDisplayInfo.data.push(endArr.data[i][paraKey] - startArr.data[i][paraKey]);
                    }
                    displayInfo[eachDev.bdDev_id] = currDisplayInfo;
                }                
            } catch (error) {
                toast(`Generate data err(Dash): ${error.message}`);
                // currDisplayInfo.data.push(null);
                continue;
            }   
        }
        return { displayInfo, someDataIn };
    }


    const genChartData = (queryData) => {
        let mychart = document.getElementById("MyBarChart_multiDaily");                
        if(!mychart) return ({datasets: []});
        let ctx = mychart.getContext("2d");
        let grLn_1_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_1_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_1_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_1_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_1_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_1_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_1_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);

        let grLn_2_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);

        let grLn_3_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);

        let grLn_4_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);

        let grLn_5_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);

        grLn_1_1.addColorStop(0, "#13225c");
        grLn_1_1.addColorStop(1, "#5f71dd");
        grLn_1_2.addColorStop(0, "#212e60");
        grLn_1_2.addColorStop(1, "#7b89db");
        grLn_1_3.addColorStop(0, "#29335b");
        grLn_1_3.addColorStop(1, "#97a1db");
        grLn_1_4.addColorStop(0, "#323a5c");
        grLn_1_4.addColorStop(1, "#a8afd7");
        grLn_1_5.addColorStop(0, "#3c425e");
        grLn_1_5.addColorStop(1, "#b8bcd4");
        grLn_1_6.addColorStop(0, "#484d65");
        grLn_1_6.addColorStop(1, "#c2c5d2");
        grLn_1_7.addColorStop(0, "#474a59");
        grLn_1_7.addColorStop(1, "#d9d9d9");

        grLn_2_1.addColorStop(0, "#6e1f06");
        grLn_2_1.addColorStop(1, "#df4444");
        grLn_2_2.addColorStop(0, "#702811");
        grLn_2_2.addColorStop(1, "#df6060");
        grLn_2_3.addColorStop(0, "#702f1a");
        grLn_2_3.addColorStop(1, "#db7373");
        grLn_2_4.addColorStop(0, "#7e402b");
        grLn_2_4.addColorStop(1, "#dd8989");
        grLn_2_5.addColorStop(0, "#884e3a");
        grLn_2_5.addColorStop(1, "#df9e9e");
        grLn_2_6.addColorStop(0, "#915f4e");
        grLn_2_6.addColorStop(1, "#dbadad");
        grLn_2_7.addColorStop(0, "#957063");
        grLn_2_7.addColorStop(1, "#e0c7c7");

        grLn_3_1.addColorStop(0, "#095b07");
        grLn_3_1.addColorStop(1, "#47c72f");
        grLn_3_2.addColorStop(0, "#0e620c");
        grLn_3_2.addColorStop(1, "#5acd44");
        grLn_3_3.addColorStop(0, "#1c6f1a");
        grLn_3_3.addColorStop(1, "#73d660");
        grLn_3_4.addColorStop(0, "#2b782a");
        grLn_3_4.addColorStop(1, "#8bd97c");
        grLn_3_5.addColorStop(0, "#3c853b");
        grLn_3_5.addColorStop(1, "#a2dd97");
        grLn_3_6.addColorStop(0, "#4c884c");
        grLn_3_6.addColorStop(1, "#b0dba8");
        grLn_3_7.addColorStop(0, "#5a8c5a");
        grLn_3_7.addColorStop(1, "#cbe6c6");

        grLn_4_1.addColorStop(0, "#055b52");
        grLn_4_1.addColorStop(1, "#30d9b9");
        grLn_4_2.addColorStop(0, "#106b62");
        grLn_4_2.addColorStop(1, "#48d6bb");
        grLn_4_3.addColorStop(0, "#1b7068");
        grLn_4_3.addColorStop(1, "#60d6c0");
        grLn_4_4.addColorStop(0, "#2c7a73");
        grLn_4_4.addColorStop(1, "#78d9c7");
        grLn_4_5.addColorStop(0, "#3c7e78");
        grLn_4_5.addColorStop(1, "#93ddcf");
        grLn_4_6.addColorStop(0, "#477e79");
        grLn_4_6.addColorStop(1, "#afe0d7");
        grLn_4_7.addColorStop(0, "#56827e");
        grLn_4_7.addColorStop(1, "#c2e2dc");

        grLn_5_1.addColorStop(0, "#4e0559");
        grLn_5_1.addColorStop(1, "#c93fd7");
        grLn_5_2.addColorStop(0, "#571062");
        grLn_5_2.addColorStop(1, "#cc58d7");
        grLn_5_3.addColorStop(0, "#5d1b67");
        grLn_5_3.addColorStop(1, "#cb67d5");
        grLn_5_4.addColorStop(0, "#61276a");
        grLn_5_4.addColorStop(1, "#cf7cd7");
        grLn_5_5.addColorStop(0, "#67346f");
        grLn_5_5.addColorStop(1, "#d592db");
        grLn_5_6.addColorStop(0, "#6b3e71");
        grLn_5_6.addColorStop(1, "#d8a4dd");
        grLn_5_7.addColorStop(0, "#825887");
        grLn_5_7.addColorStop(1, "#e4bee8");

        let grArr = [
            [grLn_1_1, grLn_1_2, grLn_1_3, grLn_1_4, grLn_1_5, grLn_1_6, grLn_1_7],
            [grLn_2_1, grLn_2_2, grLn_2_3, grLn_2_4, grLn_2_5, grLn_2_6, grLn_2_7],
            [grLn_3_1, grLn_3_2, grLn_3_3, grLn_3_4, grLn_3_5, grLn_3_6, grLn_3_7],
            [grLn_4_1, grLn_4_2, grLn_4_3, grLn_4_4, grLn_4_5, grLn_4_6, grLn_4_7],
            [grLn_5_1, grLn_5_2, grLn_5_3, grLn_5_4, grLn_5_5, grLn_5_6, grLn_5_7]
        ];

        let datasets=[];
        let labels=[];
        let i = 0;
        for (const data in queryData) {
            if(!labels.length || queryData[data].labels[queryData[data].labels.length - 1][1].replace(/[^0-9]/g, "") > labels[labels.length - 1][1].replace(/[^0-9]/g, ""))
                labels = queryData[data].labels;
            let eachSetting = {
                // yAxisID: `y_${data}`,
                label: data,
                // borderColor: grArr[i],
                // borderColor: 'rgba(75,192,192,1)',
                // datasetKeyProvider:`UniqueKey_${chartId}_${i}`,
                data: queryData[data].data,
                backgroundColor: grArr[i],
                // backgroundColor: bgColor[i],
                // data: 1,
                // hidden: linesName[i]?false:true,
                // hidden: false,
            }
            // let eachSetting = {...comSetting, ...eachCusSetting};
            datasets.push(eachSetting);
            i++;
        }
        // console.log(labels);
        return ({ labels: labels, datasets: datasets });
    }


    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: G_Display.barTitle,
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 20,
                }
            },
            datalabels: {
                color: '#696969',
                anchor: 'end',
                align: 'end',
                formatter: (value, context) => {
                    const datasetArray = [];
                    context.chart.data.datasets.forEach((dataset) => {
                        if(dataset.data[context.dataIndex] !== undefined) {
                            datasetArray.push(dataset.data[context.dataIndex]);
                        }
                    });

                    function totalSum(total, datapoint) {
                        return total + datapoint;
                    }

                    let sum = datasetArray.reduce(totalSum, 0);

                    if(context.datasetIndex === datasetArray.length - 1) {
                        return sum;
                    } else {
                        return '';
                    }
                }
            },
            zoom: {
                pan: {
                  enabled: true,
                  mode: 'y'
                },
                zoom: {
                  wheel: {
                    enabled: true
                  },
                  drag: {
                    enabled: true
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'y'
                },
                // limits: {
                //     x: {
                //         min: props.zoomLimit.min,
                //         max: props.zoomLimit.max
                //     }
                // }
            }
        },
        // scales: genAxes()
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    borderDash: [5, 5],
                },
                ticks: {
                    font: {
                        size: 11,
                        // weight: 'bold',
                    },
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: G_Display.axisTitle,
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: (context) => {
                        // Customize grid line color
                        if (context.tick.value === 0) {
                          return 'rgba(0, 0, 0, 0.1)'; // Color for the 0 grid line
                        } else {
                          return 'rgba(0, 0, 0, 0)'; // Color for other grid lines
                        }
                    },
                    lineWidth: (context) => {
                        // Customize grid line width
                        return context.tick.value === 0 ? 1 : 0; // Width for the 0 grid line and others
                    },
                },
            }
        }
    }


    return ( 
        <div className='spring_dahsItemSize_400_600'>
            <div className='spring_dashItemFrame'>
                <DashItemTitleBar 
                    title={`C6-${props.compInfo.Name}`}
                    // onClickManu={()=>handleClickManu(123)}
                    />
                {/* <img src={req~uire(`./${G_groupInfo.imgName}`)} alt='Device Logo' width={100} height={100} /> */}

                {G_loaded && <div className=''>
                    {/* <div className='flexOnly stayRight'>
                        {G_cutOffDate !== null && <TbArrowBackUp className='hoverPointer' onClick={revertToLatest} />}
                        {G_cutOffDate === null && <TbRefresh className='hoverPointer' onClick={refreshToLatest} />}
                    </div> */}
                    <Bar
                        id={`MyBarChart_multiDaily`}
                        data={G_loaded? G_chartData : {datasets:[]}}
                        options={options}
                        plugins={[ChartDataLabels]}
                    />
                </div>}
                {!G_loaded && <div style={{ position: "relative" }}>
                    <div className='divAbsolute'>
                        <div className='divRelative' style={{top:"80px", left:"280px"}}>
                            <PinwheelLoader style={{top:"900px"}}/>
                        </div>
                    </div>
                </div>}
            </div>
        </div>

    );
}

export default C0006MultiBarDaily;