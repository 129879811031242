import http from "./httpService";

async function getAll() {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/all";
  return await http.get(url);    
}

async function getByLimit(start, limit) {
  let url = `${process.env.REACT_APP_PUBLIC_URL}/devicelist/bylimit/${start}/${limit}`;
  return await http.get(url);
}

async function getByType(type) {  
  // let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/bytype/:tp";
  let url = `${process.env.REACT_APP_PUBLIC_URL}/devicelist/bytype/${type}`;
  return await http.get(url);    
}

async function getByTypenId(body) {  
  // let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/bytype/:tp";
  let url = `${process.env.REACT_APP_PUBLIC_URL}/devicelist/getbytyndevid`;
  return await http.post(url, body);    
}

async function countAll() {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devicelist/countall";
  return await http.get(url); 
}

async function update(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/update";
  //   console.log(obj);
  const body = {      
      _id: obj._id,
      type: obj.type,
      devID: obj.devID,
      // password: user.password,
      battConst: obj.battConst,
      sleepAmp: obj.sleepAmp,
      SimNumber: obj.SimNumber,
      buildingID: obj.buildingID,
    };
    // console.log("did enter");
  return await http.post(url, body);    
}
  

async function add(obj) {
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/register";
  //   console.log(obj);
  const body = {   
      type: obj.type,
      devID: obj.devID,
      // password: user.password,
      battConst: obj.battConst,
      sleepAmp: obj.sleepAmp,
      SimNumber: obj.SimNumber,
      buildingID: obj.buildingID,
    };
    // console.log("did enter");
  return await http.post(url, body); 
}

async function del(obj) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/del";
  const body = {
      _id:obj._id,
      type:obj.type,      
      devID:obj.devID,
    };
  return await http.post(url, body);    
}

async function getdevBy_SnRegCode(SerialNo, RegCode) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/getdevbysndevreg";
  const body = {
      SerialNo:SerialNo,
      RegCode:RegCode,    
    };
  return await http.post(url, body);    
}

async function verifySnRegCode(SerialNo, RegCode) {  
  let url = process.env.REACT_APP_PUBLIC_URL+"/devicelist/verifysnrc";
  const body = {
      SerialNo,
      RegCode,    
    };
  return await http.post(url, body);    
}


const expModule = {  
  getdevBy_SnRegCode,
  getByType,
  getByTypenId,
  getByLimit,
  countAll,
    del,
    add,
    getAll,
    update,
    verifySnRegCode,
};
export default expModule;

  // export default{
  //   update: updateUser,
  //   delete: deleteUser,
  // }