import http from "../httpService";

async function getRelatedBd(user_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getrelated";
  let body = {
    user_id,
  };
  return await http.post(url, body);
}


async function getRelatedBdOwnLevel(user_id) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getrelatedownLevel";
  let body = {
    user_id,
  };
  return await http.post(url, body);
}

async function getRelatedArea(user_id, selectedBuilding) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/area/getrelated";
  let body = {
    user_id,
    selectedBuilding,
  };
  return await http.post(url, body);
}

async function registerSensor(sensorInfo) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/sensorowner/regnewsensor";

  return await http.post(url, sensorInfo);
}

async function getBy_ty_DevId(type, devID) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/sensorowner/getbytyid";

  let body = {
    type: type,
    devID: devID,
  };
  return await http.post(url, body);
}

async function bdUserAccessRight(user_id, bd_id) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/checkvaliduser";

  let body = {
    user_id,
    bd_id,
  };
  return await http.post(url, body);
}

async function getBdDev_By_UserId_BdId(user_id, bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getbddev";

  let body = {
    user_id,
    bd_id,
  };
  return await http.post(url, body);
}

async function getBdDev_By_bdDevIdList(bdDev_idList) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getbddevby_idlist";

  let body = {
    bdDev_idList,
  };
  return await http.post(url, body);
}

async function getCount_BdDev(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getcountbddev";

  let body = {
    bd_id,
  };
  return await http.post(url, body);
}

async function getUniqueUserListByBdList(bdList) {
  let url =
    process.env.REACT_APP_PUBLIC_URL +
    "/devrec/building/getuniqueuserlistbybdlist";

  let body = {
    bdList,
  };
  return await http.post(url, body);
}

async function getBdListByUId(user_id) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getbdlistbyuid";

  let body = {
    user_id,
  };
  return await http.post(url, body);
}


async function addShareUser(bd_id, accessLevel, emailList) {   
  let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/building/sharetoemaillist";
  
  let body = {
      bd_id,
      accessLevel,
      emailList
  };
  return await http.post(url, body);  
}

async function getOwnBdByUserId_bd_id(user_id, bd_id) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getownbdbyuserid_bdid";
  let body = {
    user_id,
    bd_id
  };
  return await http.post(url, body);
}


/** --------------Version 2a------------ */
async function getRelatedFloor(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/floor/getrelated";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}

async function v2aGetDevInBd(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/getdevicesinbd";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}

async function v2aGetRelatedArea(bd_id, floor_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/area/v2agetarea";
  let body = {
    bd_id, 
    floor_id,
  };
  return await http.post(url, body);
}

async function v2aRegisterSensor(sensorInfo) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/sensorowner/v2aregnewsensor";
  let body = {
    sensorInfo,
  };
  return await http.post(url, body);
}

async function v2aUpdateSortIdx_Bd(sortIdx, bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2aupdatesortidx_bd";
  let body = {
    sortIdx, bd_id
  };
  return await http.post(url, body);
}


async function v2aRenameBd(name, bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2arenamebd";
  let body = {
    name, bd_id
  };
  return await http.post(url, body);
}


async function v2aUpdateSortIdx_floorList(floorList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2aupdatesortidx_floor";
  let body = {
    floorList
  };
  return await http.post(url, body);
}


async function v2aRenameFloor(name, floor_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2arenamefloor";
  let body = {
    name, floor_id
  };
  return await http.post(url, body);
}

async function v2aUpdateSortIdx_AreaList(areaList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2aupdatesortidx_area";
  let body = {
    areaList
  };
  return await http.post(url, body);
}

async function v2aRenameArea(name, area_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2arenamearea";
  let body = {
    name, area_id
  };
  return await http.post(url, body);
}

async function v2aDeleteArea(area_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2adeletearea";
  let body = {
    area_id
  };
  return await http.post(url, body);
}

async function v2aDeleteFloor(floor_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2adeletefloor";
  let body = {
    floor_id
  };
  return await http.post(url, body);
}

async function v2aclearFloorArea_id(devList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2aclear_floor_area_id";
  let body = {
    devList
  };
  return await http.post(url, body);
}

async function v2aclearArea_id(devList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/v2aclear_area_id";
  let body = {
    devList
  };
  return await http.post(url, body);
}

async function v2aAddFloor(name, owner_id, buidling_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/floor/insert";
  let body = {
    name, owner_id, buidling_id
  };
  return await http.post(url, body);
}

async function v2aAddArea(name, owner_id, buidling_id, floor_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/area/insert";
  let body = {
    name, owner_id, buidling_id, floor_id
  };
  return await http.post(url, body);
}

async function v2aGetAllAreaUnderBd(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/area/getall";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}


async function v2aUpdateFloorArea_list(devList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bdDev/updatefloorarea_id";
  let body = {
    devList
  };
  return await http.post(url, body);
}


async function v2aUpdateSortIdx_DevList(devList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bdDev/updatedevicesort";
  let body = {
    devList
  };
  return await http.post(url, body);
}

async function v2aRename_Dev(newName, _id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bdDev/rename";
  let body = {
    newName, _id
  };
  return await http.post(url, body);
}

async function v2aDelete_Dev(_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bdDev/delete";
  let body = {
    _id
  };
  return await http.post(url, body);
}

async function v2aSwap_Dev(owner_id, _id, SerialNo, RegCode) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bdDev/swap";
  let body = {
    owner_id, _id, SerialNo, RegCode
  };
  return await http.post(url, body);
}

async function v2aDeteach_Dev(_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bdDev/deteach";
  let body = {
    _id
  };
  return await http.post(url, body);
}

async function v2aGetShareBd_ByUserId_BdId(bd_id, user_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/sharebd/getbyuserid_bdid";
  let body = {
    bd_id, user_id
  };
  return await http.post(url, body);
}

async function v2aGetShareBdDev_ByUserId_BdId(bd_id, user_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/sharebddev/getby_userid_bdid";
  let body = {
    bd_id, user_id
  };
  return await http.post(url, body);
}

async function v2aGetCoOwnedBd_id(user_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getcoowned";
  let body = {
    user_id
  };
  return await http.post(url, body);
}

async function v2aGetBdInfoBy_id(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getbdinfo_byBd_id";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}

async function v2aGetAllFloorInBd(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/floor/getall";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}

async function v2aGetAllAreaInBd(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/area/getall";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}


async function v2aShareDevice(bd_id, userList, shareLevel, devList) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/share/sharedev";
  let body = {
    bd_id, userList, shareLevel, devList
  };
  return await http.post(url, body);
}

async function v2aGetShareUserOfBd(bd_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/bd/getshared";
  let body = {
    bd_id
  };
  return await http.post(url, body);
}

async function v2aDeactivateShareUser(bd_id, shareUser_id) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/devrec/shareuser/deactivate";
  let body = {
    bd_id, shareUser_id
  };
  return await http.post(url, body);
}

async function getFavBdList(user_id) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/getfavbd";
  let body = {
    user_id,
  };
  return await http.post(url, body);
}

async function trigFavBd(user_id, bd_id, bFavStatus) {
  let url =
    process.env.REACT_APP_PUBLIC_URL + "/devrec/building/trigfav";
  let body = {
    user_id,
    bd_id,
    bFavStatus
  };
  return await http.post(url, body);
}




const expModule = {
  getRelatedBd,
  getRelatedBdOwnLevel,
  getRelatedArea,
  getBy_ty_DevId,
  registerSensor,
  bdUserAccessRight,
  getBdDev_By_UserId_BdId,
  getBdDev_By_bdDevIdList,
  getCount_BdDev,
  getUniqueUserListByBdList,
  getBdListByUId,
  addShareUser,
  getOwnBdByUserId_bd_id,
  getRelatedFloor,
  v2aGetDevInBd,
  v2aGetRelatedArea,
  v2aRegisterSensor,
  v2aUpdateSortIdx_Bd,
  v2aRenameBd,
  v2aUpdateSortIdx_floorList,
  v2aRenameFloor,
  v2aUpdateSortIdx_AreaList,
  v2aRenameArea,
  v2aDeleteArea,
  v2aDeleteFloor,  
  v2aclearFloorArea_id,
  v2aclearArea_id,
  v2aAddFloor,
  v2aAddArea,
  v2aGetAllAreaUnderBd,
  v2aUpdateFloorArea_list,
  v2aUpdateSortIdx_DevList,
  v2aRename_Dev,
  v2aDelete_Dev,
  v2aSwap_Dev,
  v2aDeteach_Dev,
  v2aGetShareBd_ByUserId_BdId,
  v2aGetShareBdDev_ByUserId_BdId,
  v2aGetCoOwnedBd_id,
  v2aGetBdInfoBy_id,
  v2aGetAllFloorInBd,
  v2aGetAllAreaInBd,
  v2aShareDevice,
  v2aGetShareUserOfBd,
  v2aDeactivateShareUser,
  getFavBdList,
  trigFavBd,
};
export default expModule;
