import React from 'react';
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';
import auth from '../../../service/auth/AuthV2';
import { toast } from 'react-toastify';

function TempLogIn(props) {

    const handleOnBlur=()=>{        
        
    }
    
    const handleLogin=async ()=>{
        try {
            /** check databases */
            let loginDetails = getEmailNPassword();
            let loginStatus = await auth.login(loginDetails);
            if(!loginStatus) toast('Log In Err');
            await auth.updateCheckToken();

            // let stored = checkLocalStorage();
            props.history.push({pathname: "/"});

              
        } catch (error) {
            console.log(error.message);
            toast('Log In Error');
        }
    }
    

    const getEmailNPassword=()=>{
        let email = document.getElementById('emailInput').value;
        let password = document.getElementById('passInput').value;
        return({email, password})
    }
    

    return (
        <div>
            <div className='sortMiddle'>Temp Login</div>
            <TpInputTitleAtBox onBlur={handleOnBlur} id='emailInput' errMsgid='EmailErrId' title='E-mail' maxLen={255}/>
            <TpInputTitleAtBox onBlur={handleOnBlur} id='passInput' errMsgid='PassErrId' title='Password' maxLen={255}/>
            
            <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" onClick={handleLogin}>Login</div>
            </div>
        </div>
    );
}

export default TempLogIn;