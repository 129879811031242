import v2AppSer from '../../../service/V2_Application/RogerJunior/V2_RogerJuniorSer';

async function getV2_RjScene(rj_id){   
    try {
        let rel = await v2AppSer.getV2_RjScene(rj_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function getV2_RjSceneInfo(rj_id, sceneIdx){    
    try {
        let rel = await v2AppSer.getV2_RjSceneInfo(rj_id, sceneIdx);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function getV2_RjOnlineVar(rj_id){    
    try {
        let rel = await v2AppSer.getV2_RjOnlineVar(rj_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function V2_RjUpdateScene(Rj_id, scene, rules){    
    try {
        let rel = await v2AppSer.V2_RjUpdateScene(Rj_id, scene, rules);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjDeleteScene(Rj_id, scene){    
    try {
        let rel = await v2AppSer.V2_RjDeleteScene(Rj_id, scene);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjUpdateVarList(Rj_id, varList){    
    try {
        let rel = await v2AppSer.V2_RjUpdateVarList(Rj_id, varList);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjUpdatePairRtrh(Rj_id, varList){    
    try {
        let rel = await v2AppSer.V2_RjUpdatePairRtrh(Rj_id, varList);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjGetSchedule(Rj_id){    
    try {
        let rel = await v2AppSer.V2_RjGetSchedule(Rj_id);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjSetSchedule(Rj_id, scheList){    
    try {
        let rel = await v2AppSer.V2_RjSetSchedule(Rj_id, scheList);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjGetAcBrand(){    
    try {
        let rel = await v2AppSer.V2_RjGetAcBrand();
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function V2_RjSetFeedbackInfo(bdDev_id, fbInfo){    
    try {
        let rel = await v2AppSer.V2_RjSetFeedbackInfo(bdDev_id, fbInfo);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function V2_RjGetFeedbackInfo(bdDev_id){    
    try {
        let rel = await v2AppSer.V2_RjGetFeedbackInfo(bdDev_id);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjLogCleanFilter(bdDev_id, maintenanceInfo, filterInfo){    
    try {
        let rel = await v2AppSer.V2_RjLogCleanFilter(bdDev_id, maintenanceInfo, filterInfo);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjLastRjCleanFilterInfo(bdDev_id){    
    try {
        let rel = await v2AppSer.V2_RjLastRjCleanFilterInfo(bdDev_id);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_RjGetLastNMaintenance(bdDev_id, nCount){    
    try {
        let rel = await v2AppSer.V2_RjGetLastNMaintenance(bdDev_id, nCount);
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}



const expModule = {  
    getV2_RjScene,
    getV2_RjSceneInfo,
    getV2_RjOnlineVar,
    V2_RjUpdateScene,
    V2_RjDeleteScene,
    V2_RjUpdateVarList,
    V2_RjUpdatePairRtrh,
    V2_RjGetSchedule,
    V2_RjSetSchedule,
    V2_RjGetAcBrand,
    V2_RjSetFeedbackInfo,
    V2_RjGetFeedbackInfo,
    V2_RjLogCleanFilter,
    V2_RjLastRjCleanFilterInfo,
    V2_RjGetLastNMaintenance
};
export default expModule;