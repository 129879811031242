import http from "../httpService";


async function v2GetData_LastN(type, bdDev_id, nCnt) {        

    let url = process.env.REACT_APP_PUBLIC_URL+"/query/bddev/getlastndata";
    let body = {
        type, 
        _id:bdDev_id, 
        nCnt
    };
    // console.log("body", body);
    return await http.post(url, body);  
}

async function v2GetnMinB4nUnix(type, bdDev_id, endUnix, nMin) {        

    let url = process.env.REACT_APP_PUBLIC_URL+"/query/bddev/getnMinb4nUnix";
    let body = {
        type, 
        _id:bdDev_id, 
        endUnix,
        nMin
    };
    // console.log(body);
    return await http.post(url, body);  
}

async function v2GetBdDevData_T1_T2(type, bdDev_id, t1, t2) {        
    /** check indexedDB for this bddev_id data */
    let url = process.env.REACT_APP_PUBLIC_URL+"/query/bddev/gett1t2data";
    let body = {
        type, 
        _id:bdDev_id, 
        t1,
        t2
    };
    // console.log(body);
    return await http.post(url, body);  
}



async function v2GetData_LastNOfList(devList) {        

    let url = process.env.REACT_APP_PUBLIC_URL+"/query/bddev/getlastndata_bylist";
    let body = {devList};
    // console.log("body", body);
    return await http.post(url, body);  
}

async function v2GetDailykWh(info) {
    let url = process.env.REACT_APP_PUBLIC_URL+"/query/bddev/getdailykwh";
    let body = info;
    return await http.post(url, body);  
}

async function v2GetDataForDashItem(dashItemQueryList) {
    let url = process.env.REACT_APP_PUBLIC_URL+"/query/bddev/getdatafordashitem";
    let body = dashItemQueryList;
    return await http.post(url, body);  
}

const expModule = {  
    v2GetData_LastN,
    v2GetnMinB4nUnix,
    v2GetBdDevData_T1_T2,
    v2GetData_LastNOfList,
    v2GetDailykWh,
    v2GetDataForDashItem
};
export default expModule;