import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoIosArrowBack } from "react-icons/io";
import sensorOwnerFn from '../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { toast } from 'react-toastify';
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { RiSignalWifiErrorLine} from 'react-icons/ri';
import { GiBattery25} from 'react-icons/gi';
import { TbUnlink} from 'react-icons/tb';
import { BiSearch} from 'react-icons/bi';

// import SensorMngtFn from '../../../../function/sensor/sensorManagementFn';
import { CgMenuGridO } from "react-icons/cg";
// import { setTotalDevCnt } from '../../../../reduxStore/actions/devStatusSummary';
import auth from '../../../../service/auth/AuthV2';
import localStorageFn  from '../../../../function/localStorage/LocalStorageFn';
import TpV2aDevice from '../Template/TpV2aDevice';

import v2OwnDevFn from '../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import v2aComFn from '../ComFn/V2aComOwnerDevFn';
import v2DataQuery from '../../../../function/V2_Query/V2_QueryBdDevData';
import v2aDevStateConstant from '../ComFn/v2aDevStatusConstant';
import { isEmptyArr, notEmptyArr, sortTemplate } from '../../../../function/ArrayFn/arrayFn';
import TpManuFrame from './../Template/Manu/TpManuFrame';
import TpDiaRename from './../Template/DialogTemplate/TpDiaRename';
// import PinwheelLoader from '../Template/LoaderTemplate/pinwheelLoader';
import LegoLoader from '../Template/LoaderTemplate/legoLoader';
import TpDiaAddNewSubgroup from './../Template/DialogTemplate/TpDiaAddNewSubgroup';
import TpDiaSortDevChooseArea from './../Template/DialogTemplate/TpDiaSortDevChooseArea';
import TpDiaSortDevSortArea from './../Template/DialogTemplate/TpDiaSortDevSortArea';
import TpFeatureManu from './../Template/FeatureManu/TpFeatureManu';
import timeFn from "../../../../function/time/timeFn";

function V2aDeviceInFloor(props) {
    const C_HideShowDelay = 500;

    // eslint-disable-next-line
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [bdDev_loaded, setBdDev_loaded] = useState([]);
    // eslint-disable-next-line
    const [bdName, setBdName] = useState("");
    const [G_BdInfo, setG_BdInfo] = useState({});

    const [devsInArea, setDevsInArea] = useState([]);
    const [devsInArea_db, setdevsInArea_db] = useState([{devices:[]}]);
    const [paraList, setparaList] = useState([]);
    const [sensorList, setsensorList] = useState([]);

    const [gUser_id, setUser_id] = useState(0);
    const [G_Owner_id, setG_Owner_id] = useState(0);
    const [G_bLoaded, setG_bLoaded] = useState(false);
// eslint-disable-next-line
    const [wListDisplay, setwListDisplay] = useState(false);
    
    const [G_TotalDevInFloor, setG_TotalDevInFloor] = useState(0);
    const [G_aHideArea, setG_aHideArea] = useState([]);
    const [G_bSearchActive, setG_bSearchActive] = useState(false);
    const [G_StateCurFloor, setG_StateCurFloor] = useState([]); 
    const [G_ShowTp, setG_ShowTp] = useState(0);
    const [G_InOtherFloor, setG_InOtherFloor] = useState(false);
    const [G_AreaSelected, setG_AreaSelected] = useState([]); 
    const [G_ShareLevel, setG_ShareLevel] = useState(0);
    
    const refReloadTimer = useRef(null);
    const ref_aHideArea = useRef([]);
    const refPageClosed = useRef(false);
    
    const refPgReload = useRef(0);
    const refSkipClose = useRef(false);
    const ref_PgCloseBreakLastN = useRef(false);

    useEffect(()=>{
        async function startUp(){
            try {
                refSkipClose.current=true;
                /** direct user if page not selected */
                let bdInfo  = localStorageFn.getBdToken();
                
                if(!bdInfo) return props.history.push({pathname: "/data/bdoverview"});  /** no valid info, direct to overview page */
                let {user_id} = auth.getUserInfo();
                
                setG_Owner_id(bdInfo.owner_id);
                setG_BdInfo(bdInfo);
                
                if(bdInfo.shareLevel > 0){  /** if this is share account */
                    
                    /**check latest access level */
                    let userInfo = auth.getUserInfo();
                    let shareInfo = await v2OwnDevFn.v2aGetShareBd_ByUserId_BdId(bdInfo._id, userInfo.user_id);
                    if(!notEmptyArr(shareInfo)){    // active = 0 will not show here
                        toast("Building access denied");
                        backToBdOvv();
                        return 
                    }
                    if(bdInfo.shareLevel !== shareInfo[0].shareLevel){
                        await localStorageFn.updateBdToken_shareLevel(shareInfo[0].shareLevel);
                    }
                    setG_ShareLevel(shareInfo[0].shareLevel);
                    // accessLevel = shareInfo[0].shareLevel;
                }



                setBdName(bdInfo.name); /** set building name */
                setUser_id(user_id);
                
                /** check whether bd_id is authorized for current user. */
                let accessRight = await authUserAccessRight(user_id, bdInfo._id);   /** jump page here if no access right */
                if(!accessRight) return     /** stop program here after change page */
                /** load device  */
                let floorInBd = await getFloorInBd(bdInfo._id);
                if(floorInBd.err) return toast("Load Devices Failed");
                let floorDev = await getDeviceOnEachFloor(bdInfo._id, floorInBd, bdInfo.shareLevel, user_id);
                let currentFloor = floorDev.filter(c=>c.floor_id===bdInfo.floor_id);
                if(isEmptyArr(currentFloor)){
                    setG_bLoaded(true);
                    backToFloorLv();
                    return toast("Floor not exist");
                }
                let sortedArr = sortArr(currentFloor[0].devsInFloor);
                currentFloor[0].devsInFloor = [...sortedArr];
                if(refPageClosed.current) return
                if(isEmptyArr(currentFloor[0].devsInFloor)){
                    setG_bLoaded(true);
                    // backToFloorLv();
                    toast("No Device Here");
                    // return toast("No Device Here");
                }
                /** get type & bd_id of each floor */
                let dev_idList =[];
                for (const eachDev of currentFloor[0].devsInFloor) {
                    dev_idList.push({type: eachDev.type, bdDev_id : eachDev._id});
                }
                // return
                let _devInbd = currentFloor[0].devsInFloor;
                
                let _tyList = await v2aComFn.getTypeList(_devInbd);                
                if(_tyList.errMsg) return toast("Get type list err:", _tyList.errMsg);

                /** Get Type List In building */
                let typeList = v2aComFn.getTyList(_devInbd);
                /** getSensorList */
                let _sensorList = await v2aComFn.getSensorList(typeList); 
                setsensorList(_sensorList);
                /** load paraList */
                let _paraList = await v2aComFn.getSensorParaList(typeList);
                /** load area list, here will render data */
                let _areaList = await loadArea(bdInfo._id, bdInfo.floor_id);
                if(!_areaList) return        /** DB return error */
                if(refPageClosed.current) return
                let areaGroupList;
                if(bdInfo.floor_id === 0){      // display Others tab                    
                    setG_InOtherFloor(true);
                    /** get Device under others */
                    areaGroupList = [{areaName:currentFloor[0].floorName, devices:[...currentFloor[0].devsInFloor]}];
                    await F_ClearFloorArea_id(areaGroupList)
                    setDevsInArea(areaGroupList);
                    setdevsInArea_db(areaGroupList);
                    setG_TotalDevInFloor(currentFloor[0].devsInFloor.length);
                }else{      // display designed floor
                    /** group device into area list */               
                    setG_InOtherFloor(false);
                    areaGroupList = v2aComFn.v2aGroupByArea(_areaList, currentFloor[0].devsInFloor, bdInfo.shareLevel > 4);
                    await F_ClearArea_id(areaGroupList);
                    setDevsInArea(areaGroupList);
                    setdevsInArea_db(areaGroupList);
                    let nTotalDev = 0;
                    for (const eachArea of areaGroupList) {
                        nTotalDev+= eachArea.devices.length;
                    }
                    setG_TotalDevInFloor(nTotalDev);
                }
                let _aHideArea = F_setAreaShowHide(areaGroupList);
                // setG_BdInfo(bdInfo);
                // setBdName(bdInfo.name); /** set building name */
                // setsensorList(sensorList);
                setparaList(_paraList);
                setG_aHideArea(_aHideArea);
                setG_bLoaded(true);
                if(ref_PgCloseBreakLastN.current) return;
                /** query each last data from server */
                let lastDataGroup = await v2DataQuery.v2GetData_LastNOfList(dev_idList);
                /** get dev status of cur floor*/
                if(ref_PgCloseBreakLastN.current) return;
                let devStatusEachFloor = await v2aDevStateConstant.determineDevState(currentFloor, lastDataGroup, _tyList);
                if(ref_PgCloseBreakLastN.current) return;
                setG_StateCurFloor (devStatusEachFloor);
            } catch (error) {
                toast(error.message);
                backToFloorLv();
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
            clearTimeout(refReloadTimer.current);    
            if(!refSkipClose.current)    refPageClosed.current = true;
            ref_PgCloseBreakLastN.current = true;
        }
        // eslint-disable-next-line
    }, [refPgReload.current]);


    const F_ClearFloorArea_id=async(groupList)=>{
        let otherArea = groupList.filter(c=>c.areaName === "Others");
        if(otherArea.length < 1) return;
        let listToClear = [];
        if(otherArea[0].devices){
            for (const eachDev of otherArea[0].devices) {
                if(eachDev.floor_id !== 0 || eachDev.area_id !== 0){
                    listToClear.push({_id:eachDev._id});
                }
            }
        }
        if(listToClear.length > 0 ){
            let clearRel = await sensorOwnerFn.v2aclearFloorArea_id(listToClear);
            if(clearRel.erMsg) toast("Clear Subgroup _id : ",clearRel.erMsg);
        }
    }
    

    const F_ClearArea_id=async(groupList)=>{
        let otherArea = groupList.filter(c=>c.areaName === "Others");
        if(otherArea.length < 1) return;
        let listToClear = [];
        if(otherArea[0].devices){
            for (const eachDev of otherArea[0].devices) {
                if(eachDev.area_id !== 0){
                    listToClear.push({_id:eachDev._id});
                }
            }
        }
        if(listToClear.length > 0 ){
            let clearRel = await sensorOwnerFn.v2aclearArea_id(listToClear);
            if(clearRel.erMsg) toast("Clear Subgroup _id : ",clearRel.erMsg);
        }
        
    }

    const F_setAreaShowHide=(areaGroupList, bHideAll)=>{
        let _aHideArea = [];
        let b1stPushed = false;
        for (let i = 0; i < areaGroupList.length; i++) {
            if(areaGroupList[i].devices.length < 1) {
                _aHideArea.push(true);
                continue;
            }
            else if (areaGroupList[i].devices.length > 0 && !b1stPushed){
                if(bHideAll)_aHideArea.push(true);
                else _aHideArea.push(false);
                b1stPushed = true;
                continue
            }
            _aHideArea.push(true);
        }
        return _aHideArea;
        // setG_aHideArea(_aHideArea)
    }

    const getFloorInBd=async(bd_id)=>{
        let floorInBd = await v2OwnDevFn.getRelatedFloor(bd_id);
        if(floorInBd.errMsg) {
            toast(floorInBd.errMsg);
            return {err:true};
        }
        return floorInBd
    }

    const getDeviceOnEachFloor=async(bd_id, bdFloor, shareLevel, user_id)=>{
        let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(bd_id);
        if(devInBd.errMsg) {
            toast(devInBd.errMsg);
            return [];
        }        
        
        let _devInBd = devInBd;
        /** filter device for shared user */
        if(shareLevel > 2){  /** if this is share account */
            /** load device sharing list */
            let sharedDev = await v2OwnDevFn.v2aGetShareBdDev_ByUserId_BdId(bd_id, user_id);
            if(sharedDev.errMsg){
                toast("Load share device err: ", sharedDev.errMsg)
                return [];
            } 

            let tempArrList = [];
            for (const eachDev of devInBd) {
                let foundIdx = sharedDev.findIndex(c=> c.accessLevel > 0 && c.bdDev_id === eachDev._id);
                if(foundIdx >=0 ) tempArrList.push(eachDev);
            }
            _devInBd = tempArrList;

        }
        /** group by floor */
        let floorDev = await v2aComFn.F_v2aGetEachFloorDev(bdFloor, _devInBd);      
        return floorDev;
    }

    // const getSensorList=async (tyList)=>{
    //     let sensorList = await SensorMngtFn.getSensorListByTypeList(tyList);
    //     return sensorList;
    // }

    // const getTypeList=async(devInBd)=>{
    //     let tyList = []
    //     for (const eachDev of devInBd) {
    //         let found = tyList.find(c=> c === eachDev.type);
    //         if(!found) tyList.push(eachDev.type);            
    //     }
    //     let sensorList = await SensorMngtFn.getSensorListByTypeList(tyList);
    //     return sensorList;
    // }

    // const getTyList=(_devInbd)=>{
    //     /** Filter duplicated data */
    //     let uniqueTyDev = Array.from(
    //         new Set(_devInbd.map((a) => a.type))
    //     ).map((type) => {
    //         return _devInbd.find((a) => a.type === type);
    //     });
    //     let tyList = [];
    //     for (const dev of uniqueTyDev) {
    //         tyList.push(dev.type);
    //     }
    //     return tyList;
    // }

    // const getSensorParaList = async (tyList)=>{
    //     try {     
    //         if(tyList.length<1) return [];
    //         let paraList = await SensorMngtFn.getParaByTypeList(tyList);
    //         return paraList;
    //     } catch (error) {
    //         toast.error(error.message);    
    //         return false;
    //     }

    // }
    

    
    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "sortIdx", descOrder: false },
            { key: "name", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const loadArea=async(bd_id, floor_id)=>{
        let areaRel = await sensorOwnerFn.v2aGetRelatedArea(bd_id, floor_id);
        areaRel = sortArr(areaRel);
        if(areaRel.errMsg) {
            toast(`Load area err: ${areaRel.errMsg}`)
            return false
        }
        return areaRel;
    }

    const authUserAccessRight=async(user_id, bd_id)=>{
        let bdAceesRight = await sensorOwnerFn.bdUserAccessRight(user_id, bd_id);
        /** DB Error */
        if(bdAceesRight.errMsg) {
            toast(bdAceesRight.errMsg);
            props.history.push({
                pathname: "/data/bdoverview",
                // _data: {_selBuildingId: this.state.selBuildingId},
            });
            return false
        }
        /** No Access Right */
        if(!bdAceesRight){
            toast("Access Denied");
            props.history.push({
                pathname: "/data/bdoverview",
                // _data: {_selBuildingId: this.state.selBuildingId},
            });
            return false
        }
        return true;
    }

    const backToBdOvv=()=>{
        props.history.push({
            pathname: "/data/bdoverview",
            // _data: {_selBuildingId: this.state.selBuildingId},
        });
    }

    const backToFloorLv=()=>{
        props.history.push({
            pathname: "/data/floorinbd",
            // _data: {_selBuildingId: this.state.selBuildingId},
        });
    }
    

    // eslint-disable-next-line 
    // const loadBdDevOfUser=async(user_id, bd_id)=>{
    //     try {
    //         /** load device and area in building belong to current user*/
    //         let devInBd = await sensorOwnerFn.getBdDev_By_UserId_BdId(user_id, bd_id);        
    //         /** filter device been move to other building */
    //         devInBd = devInBd.filter(c=>c.buidling_id === bd_id);
    //         /** Add total device count into redux */
    //         dispatch(setTotalDevCnt({totalDevCnt:devInBd.length}));
    //         setBdDev_loaded(devInBd);
    //         // setBdDev_Display(devInBd);
    //         return devInBd;
    //     } catch (error) {
    //         toast("Connection Error (Load Device)")
    //         return [];
    //     }
    // }

    const fRefreshHideShow=()=>{
        setG_aHideArea(ref_aHideArea.current);
    }
    const handleSearch =()=>{
        clearTimeout(refReloadTimer.current);    
        let _aHideArea = F_setAreaShowHide(devsInArea_db, true);
        setG_aHideArea(_aHideArea);
        let nTotalDev=0;
        let searchInput = document.getElementById('searchInput').value.trim();
        if(searchInput==="") {
            setDevsInArea(devsInArea_db);
            ref_aHideArea.current = F_setAreaShowHide(devsInArea_db);
            setG_bSearchActive(false);
            nTotalDev = F_GetTotalDev(devsInArea_db);
            setG_TotalDevInFloor(nTotalDev);
            refReloadTimer.current = setTimeout(fRefreshHideShow, C_HideShowDelay);
            return            
        }
        let _devsInArea_db=[];
        for (let i = 0; i < devsInArea_db.length; i++) {    
            let devMatchFilter = devsInArea_db[i].devices.filter(c=>c.name.toLowerCase().includes(searchInput.toLowerCase()));
            if(isEmptyArr(devMatchFilter)) continue;
            _devsInArea_db.push({areaName:devsInArea_db[i].areaName, devices:[...devMatchFilter]});
        }

        ref_aHideArea.current = F_setAreaShowHide(_devsInArea_db);
        setDevsInArea(_devsInArea_db);
        nTotalDev = F_GetTotalDev(_devsInArea_db);
        setG_TotalDevInFloor(nTotalDev);
        setG_bSearchActive(true);
        
        refReloadTimer.current = setTimeout(fRefreshHideShow, C_HideShowDelay);
    }

    const F_GetTotalDev=(areaGroupList)=>{
        let nTotalDev = 0;
        for (const eachArea of areaGroupList) {
            nTotalDev+= eachArea.devices.length;
        }
        return nTotalDev;
    }

    const openManu =()=>{
        setG_ShowTp(1);
        // props.history.push({
        //     pathname: "/data/devoverview/v2asubmonmenu",
        //     // _data: {_selBuildingId: this.state.selBuildingId},
        // });
    }
    
    // eslint-disable-next-line
    const toSingleSensorPg =async(dev)=>{
        await localStorage.setItem("v2_singleSensor_bdDev_id", dev._id);
            
        props.history.push({
            pathname: "/device/singlesensor",
            // _data: {_selBuildingId: this.state.selBuildingId},
        });
    }
    
    const toWatchList = () => {
        props.history.push({
            pathname: "/data/sensorinbd/graph"
        });
    }

    const handleHideShowGroup=(ind)=>{
        let _aHideArea = [...G_aHideArea];
        _aHideArea[ind] = !_aHideArea[ind];
        setG_aHideArea(_aHideArea);
    }

    const handleCloseManu=()=>{
        setG_ShowTp(0);
    }

    const openRenameDia=()=>{
        setG_ShowTp(2);
    }
    const openAddSubgroupDia=()=>{
        setG_ShowTp(3);
    }
    
    const openSortDevDia=()=>{
        setG_ShowTp(4);
    }

    const handleRename=async(newName)=>{
        /** update DB */
        let updateRel = await v2OwnDevFn.v2aRenameFloor(newName, G_BdInfo.floor_id);
        if(updateRel.errMsg) {
            toast(updateRel.errMsg);
            setG_ShowTp(0);
            return 
        }
        /** change state */
        let _BdInfo = {...G_BdInfo};
        _BdInfo.floorName = newName;
        setG_BdInfo(_BdInfo);
        /** change local storage */
        await localStorageFn.updateBdToken_FloorName(newName);

        setG_ShowTp(0);

    }
    
    const handleAddSubgroup=async (newName)=>{  
        let bdInfo  = localStorageFn.getBdToken();
        let rel = await v2OwnDevFn.v2aAddArea(newName, bdInfo.owner_id, bdInfo._id, bdInfo.floor_id);
        if(rel.errMsg) return toast(rel.errMsg);
        refPgReload.current++
        refSkipClose.current=true;        
        setG_bLoaded(false);
        setG_ShowTp(0);
    }

    const handleSortAreaSelected=async (areaInfo)=>{
        setG_AreaSelected(areaInfo);
        setG_ShowTp(5);
    }
    
    const handleRefreshPage=async ()=>{
        refPgReload.current++;
        refSkipClose.current=true;        
        setG_bLoaded(false);
        setG_ShowTp(0);
        // setG_AreaSelected(areaInfo);
        // setG_ShowTp(5);
    }

    // const handleRefreshPage=async ()=>{
    //     refPgReload.current++;
    //     refSkipClose.current=true;        
    //     setG_bLoaded(false);
    //     setG_ShowTp(0);
    //     // setG_AreaSelected(areaInfo);
    //     // setG_ShowTp(5);
    // }
    

    const handleRenameDev=async (devInfo, areaInfo, newName)=>{
        let _devsInArea = [];
        for (const eachDev of devsInArea) {
            let devices=[...eachDev.devices];
            let foundIndex = devices.findIndex(c=> c._id === devInfo._id);
            if(foundIndex>=0) devices[foundIndex].name = newName;
            _devsInArea.push({
                area_id:eachDev.area_id, 
                areaName:eachDev.areaName, 
                devices,
            }) 
        }
        setDevsInArea(_devsInArea);
    }

    


    return (
        <div>
            <TpFeatureManu/>

            {G_ShowTp===1 && <TpManuFrame 
                entryFrom ={"Area"} 
                onclickClose={handleCloseManu} 
                onclickOpenRenameDia = {openRenameDia}
                onclickOpenAddGroup = {openAddSubgroupDia}
                onclickOpenSortDevDia={openSortDevDia}
                inOthersFloor={G_InOtherFloor}
                shareLevel = {G_ShareLevel}
                />}
            {G_ShowTp===2 && <TpDiaRename         
                OldName = {G_BdInfo.floorName} 
                onclickClose={handleCloseManu}
                onclickConfirm = {handleRename}/>}
            {G_ShowTp===3 && <TpDiaAddNewSubgroup  
                onclickClose={handleCloseManu}
                onclickConfirm = {handleAddSubgroup}
                existingGroup={devsInArea}
                />}
            {G_ShowTp===4 && <TpDiaSortDevChooseArea
                onclickClose={handleCloseManu}
                areaGroupList={devsInArea}
                onclickChooseItem={handleSortAreaSelected}
                />}
            {G_ShowTp===5 && <TpDiaSortDevSortArea
                onclickClose={handleCloseManu}
                areaGroupList={devsInArea}
                areaSelected={G_AreaSelected}
                onclickConfirm = {handleRefreshPage}
                />}

            <div className='headerContainer'>
                <div onClick={backToFloorLv} className='headerIconLeft'>
                    <IoIosArrowBack className="backIcon" />
                </div>
                <div className ='searchBarContainer' style={{margin: "10px"}}>
                        <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                        <div className='searchButton'>
                            <i className='fa fa-search'></i>
                        </div>    
                </div>
                <div onClick={openManu} className='headerIcon'>
                    <CgMenuGridO/>
                </div>
            </div>
            <div className='devOvvBdTitleBar '>
                <div className='spring_OvvTitleName' >
                    <div className='wrapTextEllipsis devOvvBdName hoverPointer' onClick={backToBdOvv}>{G_BdInfo.name}</div>
                    {G_BdInfo.name && <div>{`>`}</div>}
                    <div className='wrapTextEllipsis devOvvBdName hoverPointer' onClick={backToFloorLv}>{G_BdInfo.floorName}</div>
                </div>
            </div>

            <div className='devCount flexAndBothCenter'>
                {G_bSearchActive && <BiSearch className='spring_marginRight5'/>}
                <div>
                    {G_TotalDevInFloor > 1 ?`${G_TotalDevInFloor} Devices`:`${G_TotalDevInFloor} Device`}
                </div>
            </div>

            {G_bLoaded && <div className='devOvvStatusContainer'>
                <div className='sortHor devOvvEachStatus'>
                    <GiBattery25 className='spring_smallText'/>
                    {/* <div className='bdOvvStatusCnt'> */}
                    <div className={`bdOvvStatusCnt ${v2aDevStateConstant.F_GetDevErrStateCnt(1, G_StateCurFloor, G_StateCurFloor[0]) > 0 ?"redText":""}`}>
                        {v2aDevStateConstant.F_GetDevErrStateCnt(1, G_StateCurFloor, G_StateCurFloor[0])}
                    </div>
                </div>
                <div className='sortHor devOvvEachStatus'>
                    <RiSignalWifiErrorLine className=''/>
                    {/* <div className='bdOvvStatusCnt'> */}
                    <div className={`bdOvvStatusCnt ${v2aDevStateConstant.F_GetDevErrStateCnt(2, G_StateCurFloor, G_StateCurFloor[0]) > 0 ?"redText":""}`}>
                        {v2aDevStateConstant.F_GetDevErrStateCnt(2, G_StateCurFloor, G_StateCurFloor[0])}
                    </div>
                </div>
                <div className='sortHor devOvvEachStatus'>
                    <TbUnlink className=''/>
                    {/* <div className='bdOvvStatusCnt'> */}
                    <div className={`bdOvvStatusCnt ${v2aDevStateConstant.F_GetDevErrStateCnt(3, G_StateCurFloor, G_StateCurFloor[0]) > 0 ?"redText":""}`}>
                        {v2aDevStateConstant.F_GetDevErrStateCnt(3, G_StateCurFloor, G_StateCurFloor[0])}
                    </div>
                </div>
            </div>}

            {/* {!G_bLoaded && <div>Loading...</div>} */}
            
            {!G_bLoaded && <LegoLoader />}
            <div>
                {G_bLoaded && devsInArea.length>0 && devsInArea.map((c, ind)=>(
                    <div  key={`areaName_${ind}`}>
                        <div>
                            <div className='sortHor flexAndBothCenter '>
                                <div className=''>
                                    <div className={`bdOvvAreaName sortMiddle ${c.devices.length < 1 ?"":"hoverPointer"}`}
                                    onClick={()=>handleHideShowGroup(ind)}>
                                        <div>{`${ind+1}. ${c.areaName}`}</div>
                                        <div className={`${c.devices.length < 1 ?"divCollapse":""}`}>
                                            <div className={`spring_paddingLeft10 spring_width30px ${G_aHideArea[ind] ?"divCollapse":""}`}>
                                                <VscTriangleUp className=''/>
                                            </div>
                                            <div className={`spring_paddingLeft10 spring_width30px ${G_aHideArea[ind] ?"":"divCollapse"}`}>
                                                <VscTriangleDown className=''/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`bdOvvAreaDevCnt flexAndBothCenter sorthor ${c.devices.length < 1 ?"":"hoverPointer"}`}
                                    onClick={()=>handleHideShowGroup(ind)}>
                                        {G_bSearchActive && <BiSearch className='spring_marginRight5'/>}
                                        <div> 
                                            {c.devices.length > 1?`${c.devices.length} Devices`:`${c.devices.length} Device `}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`bdOvvDevContainer ${G_aHideArea[ind] || c.devices.length < 1?"divCollapse":""}`}>
                                {c.devices.length > 0 && G_bLoaded && !G_aHideArea[ind] && c.devices.map((u, ind_k)=>(
                                    <div className='devBdFrame' key={`dev_${ind}_${ind_k}`} >
                                        <TpV2aDevice
                                            key={`${ind+1}.${ind_k+1}_key`}
                                            user_id={gUser_id} 
                                            owner_id={G_Owner_id}
                                            dev={u} 
                                            pos={`${ind+1}.${ind_k+1}.`}
                                            a_para = {paraList.filter(c=> c.sensorType === u.type)}
                                            a_sensorDetails={sensorList.filter(c=> c.type === u.type)}
                                            callRenameDev={(newName)=>handleRenameDev(u, c, newName)}
                                            callRefreshPage={handleRefreshPage}                                            
                                            shareLevel = {G_ShareLevel}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {wListDisplay && <div className='floatButton' onClick={() => toWatchList()}>To Watch List</div>}
        </div>
    );
}

export default V2aDeviceInFloor;