import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from 'reselect';
// import { useDispatch } from 'react-redux';
// import api from "../middleware/api";
import { apiCallBegan } from './../middleware/api';

const slice = createSlice({
    name:'friendList',
    initialState:{
        list:[]
    },
    reducers:{
        friendAdd:(state, action)=>{
            state.list = [...action.payload];
        }
    }
})

/** Export Action */
export const {friendAdd} = slice.actions;

/** reducer */
export default slice.reducer;

/** Action Creator, call action */
export const loadFriendList = ()=> apiCallBegan({
    onSuccess:friendAdd.type,
})

/** Selector, read from store */
export const getFriendList = createSelector(
    state=>state.users.friendList.list,
    frList => frList
)