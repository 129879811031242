import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FaGreaterThanEqual, FaLessThan } from "react-icons/fa";
import TpSubSubDiaVarChooseDev from "./TpDialog/TpSubSubDiaVarChooseDev";
import v2OwnDevFn from "../../../../../../function/V2_SensorDevice/v2_SensorOwnerFn";
import TpSubSubDiaVarChoosePara from "./TpDialog/TpSubSubDiaVarChoosePara";
import sensorFn from "../../../../../../function/sensor/sensorManagementFn";
import { isEmptyArr, notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import { toast } from "react-toastify";
import { GrRevert } from "react-icons/gr";
import { F_GetLoRaDataType, F_GetLoRaDataType_px } from "./RjFunction/RjFn";
import v2RjFn from "../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";

const TabSettingFeedbackPair = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    send() {
      fTriggerSendLoRa();
        // if(props.confirmSend) props.confirmSend();
    },
    async saveFeedbackInfoToDb(){
      // fbInfo.current.expertMode = G_ExpertMode;
      let setFbRel = await v2RjFn.V2_RjSetFeedbackInfo(props.dev._id, fbInfo.current)
      if(setFbRel.errMsg) return toast(`Set Feedback Info Err: ${setFbRel.errMsg}`)
      // setG_FbIndo_Db(fbInfo.current);
      fbInfo_DB.current = fbInfo.current;   // update DB info
    },
    discardChanges(){
      fDisplayUpdate(fbInfo_DB.current);
    }
  }));
  const [G_FeedbackActive, setG_FeedbackActive] = useState(false);
  const [G_CompareOpe, setG_CompareOpe] = useState(0);
  const [G_DevList, setG_DevList] = useState([]);

  const [G_TabPage, setG_TabPage] = useState(0);
  const [G_ParaList, setG_ParaList] = useState([]);
  const [G_SelectedDev, setG_SelectedDev] = useState({});
  const [G_SelectedPara, setG_SelectedPara] = useState({});
  // const [G_FbIndo_Db, setG_FbIndo_Db] = useState({});
  
  const [G_ExpertMode, setG_ExpertMode] = useState(false);
  const [G_CommType, setG_CommType] = useState(1);

  const timeoutRef = useRef(null);
  const fbInfo = useRef({});
  const fbInfo_DB = useRef({});

  useEffect(() => {
    async function startUp() {
      let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(props.dev.buidling_id);
      await loadFeedbackInfo();
      
      setG_DevList(devInBd);
    }
    startUp();

    return () => {
      // alert("Page Close");
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);

  const loadFeedbackInfo = async() => {
    let fbInfo = await v2RjFn.V2_RjGetFeedbackInfo(props.dev._id);
    if(fbInfo.errMsg) return toast("Load Feedback Info Error");
    if(isEmptyArr(fbInfo))  return;
    // setG_FbIndo_Db(fbInfo[0]);
    fbInfo_DB.current = fbInfo[0];
    // set set
    fDisplayUpdate(fbInfo[0]);
  };

  const fDisplayUpdate=async(fbInfo)=>{
    setG_FeedbackActive(fbInfo.active);
    if(fbInfo.active===1 || fbInfo.active===true){
      // set sel Dev
      let devInfo = await v2OwnDevFn.getBy_ty_DevId(fbInfo.ht, fbInfo.hi);
      if(devInfo.errMsg) return toast("Load Pair Sensor Error (DB)");
      if(devInfo.data && notEmptyArr(devInfo.data)){
        setG_SelectedDev(devInfo.data[0]);
      }else{
        return toast("Load Pair Sensor Error");
      }

      let paraList = await sensorFn.getParaByTypeList([devInfo.data[0].type]);
      setG_ParaList(paraList);

      let sParaType = F_GetLoRaDataType_px(fbInfo.paraType);
      
      let paraFound = paraList.find(c=>c.dataType === sParaType && c.dataIndex === fbInfo.paraIdx);
      setG_SelectedPara(paraFound);
      // set sel Para
      setG_CompareOpe(fbInfo.compareOp);
      fSetValueAfterDelay(fbInfo.onThreshold);

      let bExpertMode = fbInfo.expertMode===1 || fbInfo.expertMode===true;
      setG_ExpertMode(bExpertMode);

      if(bExpertMode){
        // Set Comm Type
        setG_CommType(fbInfo.comType);
        if(fbInfo.comType===1){   // direct lora
          document.getElementById(`Rj_LoRaFreq_${props.pos}`).value = fbInfo.loraFreq;
        }else{  // broker link
          document.getElementById(`Rj_FbGwID_${props.pos}`).value = fbInfo.GwId_Feedback;
        }
      }

    }
    
    
  }
  const fSetValueAfterDelay = (value) => {
    document.getElementById(`RjOnThres_${props.pos}`).value = value;
  };
  const fToggleActive = () => {
    fSettingChanges();
    if (!G_FeedbackActive) {
      // when user trigger on, need to set value
      // document.getElementById(`RjOnThres_${props.pos}`).value = 0.01;
      timeoutRef.current = setTimeout(()=>fSetValueAfterDelay(0.01), 100);
    }
    setG_FeedbackActive(!G_FeedbackActive);
  };
  const fToggleExpertMode = () => {
    fSettingChanges();
    setG_ExpertMode(!G_ExpertMode);
  }
  
  const fToggleOpe = () => {
    fSettingChanges();
    if (G_CompareOpe === 1) setG_CompareOpe(0);
    else setG_CompareOpe(1);
  };
  const fClosePage = () => {
    setG_TabPage(0);
  };
  const fCallSelPage = (nPg) => {
    setG_TabPage(nPg);
  };
  const fCallChooseParaPg = (nPg) => {
    if (isEmptyArr(G_ParaList)) return toast("Please Choose Pair Sensor First");    
    fSettingChanges();
    setG_TabPage(nPg);
  };

  const fHandleSelectDev = async (info) => {
    if (G_SelectedDev.type !== info.type) {
      setG_SelectedPara({});
    }
    setG_SelectedDev(info);

    let paraList = await sensorFn.getParaByTypeList([info.type]);
    setG_ParaList(paraList);    
    fSettingChanges();
    fClosePage();
    // setG_PrevSensorType(G_SelSensor.type);
  };
  const fHandleChoosePara = async (info) => {
    setG_SelectedPara(info);    
    fSettingChanges();
    fClosePage();
  };
  
  const fBackToMoreManu=()=>{
    if(props.fToTab) props.fToTab(31);
    if(props.discardChanges) props.discardChanges();
  }
  
  const F_CurCommTypeDisplay=()=>{
    if(G_CommType===1) return 'LoRa'
    else if(G_CommType===2) return 'Broker'
    else return '--'    
  }
  
  const F_ToggleCommType=()=>{
    fSettingChanges();
    if(G_CommType===1) setG_CommType(2);
    else setG_CommType(1);
  }

  const fSettingChanges=()=>{
    if(props.settingChanges) props.settingChanges();
  }
  
  const fTriggerSendLoRa=()=>{
    // validate information
    let valRel = fValidateFbInfo();
    if(!valRel.rel) return
    // if pass, proceed to send LoRa
    if(props.confirmSend) props.confirmSend(valRel.info);
    fbInfo.current = valRel.info;
  }


  const fValidateFbInfo=()=>{
    let info={
      active:G_FeedbackActive,
      ht:0,
      hi:0,
      comType:1,    // 1 = direct lora, 2 = broker link
      GwId_RJ:0,
      GwId_Feedback:0,
      paraType:0,
      paraIdx:0,
      compareOp:0,
      loraFreq:0,
      onThreshold:0,
      expertMode:G_ExpertMode,
    }
    if(!G_FeedbackActive) return {rel:true, info};
    // validate ht, hi
    if(!Object.hasOwnProperty.call(G_SelectedDev, "devID") || 
    !Object.hasOwnProperty.call(G_SelectedDev, "type")) {
      toast("Invalid Pair Sensor")
      return {rel:false, info};
    }
    info.ht = G_SelectedDev.type;
    info.hi = G_SelectedDev.devID;

    // validate parameter      
    if(!Object.hasOwnProperty.call(G_SelectedPara, "dataType") || 
    !Object.hasOwnProperty.call(G_SelectedPara, "dataIndex")){
      toast("Invalid Pair Parameter")
      return {rel:false, info};
    }    
    info.paraType = F_GetLoRaDataType(G_SelectedPara.dataType);
    info.paraIdx = G_SelectedPara.dataIndex;
    
    // validate on threshold
    info.compareOp=G_CompareOpe;
    info.onThreshold = parseFloat(document.getElementById(`RjOnThres_${props.pos}`).value);
    if(isNaN(info.onThreshold)){
      toast("Invalid On Threshold Value");
      return {rel:false, info};
    }
    
    // validate non expert mode, insert the default value
    if(!G_ExpertMode){
      info.comType = 1; // 
      info.loraFreq = 433.0;
      return {rel:true, info};
    }
    
    // expert mode on, validate info
    if(G_CommType===1){   // direct lora
      info.comType = 1; 
      info.loraFreq = parseFloat(document.getElementById(`Rj_LoRaFreq_${props.pos}`).value);
      if(isNaN(info.loraFreq)){
        toast("Invalid LoRa Frequency");
        return {rel:false, info};
      }
    }else{
      info.comType = 2; 
      info.GwId_Feedback = parseFloat(document.getElementById(`Rj_FbGwID_${props.pos}`).value);
      if(isNaN(info.GwId_Feedback)){
        toast("Invalid Feedback Gw ID");
        return {rel:false, info};
      }
    }
    // RJ Gw insert at main program
    return {rel:true, info};

  }

  


  return (
    <div className="spring_editScenePg">
      {G_TabPage === 1 && (
        <TpSubSubDiaVarChooseDev
          devList={G_DevList}
          onclickClose={fClosePage}
          chooseDev={fHandleSelectDev}
        />
      )}

      {G_TabPage === 2 && (
        <TpSubSubDiaVarChoosePara
          onclickClose={fClosePage}
          paraList={G_ParaList}
          choosePara={fHandleChoosePara}
        />
      )}

      
      <div className='sortHor' style={{marginBottom:"5px"}}>
          <div className="spring_ManuTitle">Feedback Sensor</div>
          <div className='spring_ManuTitleBackIcon stayRight hoverPointer'
          onClick={fBackToMoreManu}
          >
              <GrRevert/>
          </div>
      </div>

      <div className="spring_RjSettingGroupTitle">Setting</div>
      <div className="spring_editScheGroup">
        <div className="spring_editItemBar sortHor">
          <div className="spring_editLeftTitle">Active</div>
          <div
            className="spring_scheActive_Icon flexAndBothCenter hoverPointer"
            onClick={fToggleActive}
          >
            {!G_FeedbackActive ? (
              <BsToggleOff />
            ) : (
              <BsToggleOn className="reactCfgButtonOn" />
            )}
          </div>
        </div>

        {(G_FeedbackActive===1 || G_FeedbackActive===true) && (
          <div>
            <div className="spring_editItemBar sortHor">
              <div className="spring_editLeftTitle">Pair Sensor</div>
              <div
                className="spring_editRightItem hoverPointer"
                onClick={() => fCallSelPage(1)}
              >
                <div className="blueText">{`${
                  G_SelectedDev.name ? G_SelectedDev.name : "<Choose Sensor>"
                }`}</div>
              </div>
            </div>

            <div className="spring_editItemBar sortHor">
              <div className="spring_editLeftTitle">Pair Parameter</div>
              <div
                className="spring_editRightItem hoverPointer"
                onClick={() => fCallChooseParaPg(2)}
              >
                <div className="blueText">{`${
                  G_SelectedPara.dataName
                    ? G_SelectedPara.dataName
                    : "<Choose Para>"
                }`}</div>
              </div>
            </div>

            <div className="spring_editItemBar sortHor">
              <div className="spring_editLeftTitle">On Threshold</div>
              <div className="spring_editRightItem">
                <div
                  className="spring_FbSelButton hoverPointer"
                  onClick={fToggleOpe}
                >
                  {/* {`${G_CompareOpe===1?"<":">="}`} */}
                  {G_CompareOpe === 0 ? <FaGreaterThanEqual /> : <FaLessThan />}
                </div>
              </div>
              {/* <div className="spring_editRightItem">
              <div>0.02</div>
            </div> */}
              <input
                style={{ width: "50px", textAlign: "end" }}
                type={"number"}
                className=" i_time_contentInput hideNumberUpDown stayRight"
                id={`RjOnThres_${props.pos}`}
                placeholder="--"
                onChange={fSettingChanges}
              ></input>
            </div>
          </div>
        )}
      </div>

      {(G_FeedbackActive===1 || G_FeedbackActive===true) && <div>      
        <div className='spring_RjSettingGroupTitle'>Expert Mode</div>
        <div className="spring_editScheGroup">
          <div className="spring_editItemBar sortHor">
            <div className="spring_editLeftTitle">Expert Mode</div>
            <div
              className="spring_scheActive_Icon flexAndBothCenter hoverPointer"
              onClick={fToggleExpertMode}
            >
              {!G_ExpertMode ? (
                <BsToggleOff />
              ) : (
                <BsToggleOn className="reactCfgButtonOn" />
              )}
            </div>
          </div>
          
          {(G_ExpertMode===1 ||G_ExpertMode===true) && <div>
            <div className="spring_editItemBar sortHor">
              <div className="spring_editLeftTitle">Comm. Type</div>
                <div className="spring_SL_SelButton hoverPointer stayRight"
                onClick={F_ToggleCommType}
                >
                  {F_CurCommTypeDisplay()}
                  </div>
              </div>
            
            {(G_CommType!==2) && <div className="spring_editItemBar sortHor">
              <div className="spring_editLeftTitle">LoRa Frequency</div>
              
              <input
                    style={{ width: "100px", textAlign: "end" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown stayRight"
                    id={`Rj_LoRaFreq_${props.pos}`}
                    placeholder="--"
                    onChange={fSettingChanges}
                  ></input>
            </div>}

            
            {(G_CommType===2) && <div className="spring_editItemBar sortHor">
              <div className="spring_editLeftTitle">Feedback GW ID</div>
              <input
                    style={{ width: "50px", textAlign: "end" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown stayRight"
                    id={`Rj_FbGwID_${props.pos}`}
                    placeholder="--"
                    onChange={fSettingChanges}
                  ></input>
            </div>}

          </div>}
        </div>
      </div>}      
      <div style={{marginBottom:"20px"}}></div>

    </div>
  );
});

export default TabSettingFeedbackPair;
