import React from 'react'
import DashCancelConfirmButton from './../ComponentTemplate/Common/Common/DashCancelConfirmButton';
import { F_DashManuPgHeight } from '../DashboardFunction';

const CompRename = (props) => {
  return (
    <div className='spring_dashManuBox spring_OverflowY' style={{height:`${F_DashManuPgHeight(props.heightPortion)}px`}}>
      <div>Rename1</div>
      <div>Rename2</div>
      <div>Rename3</div>
      <div>Rename4</div>
      <div>Rename5</div>
      <div>Rename6</div>
      <div>Rename7</div>
      <div>Rename8</div>
      <div>Rename9</div>
      <div>Rename0</div>
      <div>Rename1</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      <div>Rename2</div>
      
      
      <div className='spring_DashButtonStayBtm'>
        <DashCancelConfirmButton/>
      </div>
    </div>
  )
}

export default CompRename
