import React from 'react';
import { toast } from 'react-toastify';

function TpSwapDevice(props) {

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }

    const handleConfirm=()=>{
        
        if(props.onclickConfirm) {
            let sSn = document.getElementById('sn_input').value.trim();
            let sRegCode = document.getElementById('regcode_input').value.trim();
            if(!sSn || sSn==="" ||!sRegCode || sRegCode==="" ) return toast('Invalid Input');
            props.onclickConfirm(sSn, sRegCode);
        }
    }

    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className='spring_dialogBoxStayLeft'>
                    <div className='spring_diaMainTitle'
                        style={{margin:"20px 0px 0px 60px "}}>{`${props.isDeteach?"Attach":"Replace"}`}</div>
                    {props.deviceName && <div className='boldText text_1_2'
                        style={{margin:"0px 0px 25px 60px "}}
                        >{`${props.deviceName}`}</div>}
                                    
                    <div className='spring_diaMainTitle'>With new device:</div>
                    <div className='spring_diaSmallTitle'>Serial No.</div>
                    <div className='flexAndBothCenter '>
                        <input id="sn_input"
                        autoComplete='off' 
                        className='spring_FramelessInput blueText'
                        // placeholder={"< DELETE >"}
                        style={{textAlign:"center"}}/>
                    </div>
                    <div className='spring_diaSmallTitle'>Register code</div>
                    <div className='flexAndBothCenter '>
                        <input id="regcode_input"
                        autoComplete='off' 
                        className='spring_FramelessInput blueText'
                        // placeholder={"< DELETE >"}
                        style={{textAlign:"center"}}/>
                    </div >
                    <div className='sortHor' style={{margin:"10px 0px 10px 0px "}}>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}
                        >
                            Discard
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TpSwapDevice;