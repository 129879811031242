import React, { useEffect, useRef, useState } from 'react'
import SubTpGraph from '../Version2a/Template/SubTemplate/SubTpGraph'
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedList, clearTimeRange, clearTimeRange_Data, getSelDevData, getSelectedList, getTimeRange, getTimeRange_data, removeFromDevSelList, setHisDataSet, setNewDevSelList, setTimeRange, setTimeRange_Data } from '../../../reduxStore/actions/dev_selHistoryTrend';
import v2DataQuery from '../../../function/V2_Query/V2_QueryBdDevData';
import timeFn from '../../../function/time/timeFn';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import TpDiaSelectDev from '../Version2a/Template/DialogTemplate/TpDiaSelectDev';
import { BiSortAlt2 } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { TbArrowBackUp } from "react-icons/tb";
import { MdExitToApp } from "react-icons/md";
import { GrFormAdd } from "react-icons/gr";
import { BsFillPlayFill } from "react-icons/bs";
import LegoLoader from '../Version2a/Template/LoaderTemplate/legoLoader';
import TpDiaSortObj from '../Version2a/Template/DialogTemplate/TpDiaSortObj';
import { notEmptyArr } from '../../../function/ArrayFn/arrayFn';


const HisDataSensor = (props) => {
  const  building_TzDiff_s = 8*60*60;   // replace later ???
  const C_dataExpDuration_s = 120;  // 2 mins different

  const dispatch = useDispatch();  
  const Grx_selectedList = useSelector(getSelectedList);
  const Grx_selDevData = useSelector(getSelDevData);
  const Grx_TimeRange = useSelector(getTimeRange);
  const Grx_TimeRange_Data = useSelector(getTimeRange_data);
  
  
  const [G_DisStartUnix, setG_DisStartUnix] = useState(0);
  const [G_DisEndUnix, setG_DisEndUnix] = useState(0);

  const [G_DataStart_Unix, setG_DataStart_Unix] = useState(0);
  const [G_DataEnd_Unix, setG_DataEnd_Unix] = useState(0);

  const [G_ShowDialogBox, setG_ShowDialogBox] = useState(0);
  const [G_loaded, setG_loaded] = useState(false);

  const loadTimeDelay = useRef(null);

  const history = useHistory();

  useEffect(() => {
    async function startUp() {
      setG_loaded(false);
      let {uiStart_Unix, uiEnd_Unix} = F_StartEndTime_PlaceHolder();      
      dispatch(setTimeRange({startUnix:uiStart_Unix, endUnix:uiEnd_Unix}));
      let {dataStartUnix, dataEndUnix} = Grx_TimeRange_Data;
      
      if(dataStartUnix!==0 && dataEndUnix!==0){ /** use previous set display range */
        setG_DataStart_Unix(dataStartUnix);
        setG_DataEnd_Unix(dataEndUnix);
        await F_GetDataForEachSelSensor(dataStartUnix, dataEndUnix);
      }else{  /** use default display range */
        setG_DataStart_Unix(uiStart_Unix);
        setG_DataEnd_Unix(uiEnd_Unix);
        await F_GetDataForEachSelSensor(uiStart_Unix, uiEnd_Unix);
      }
      
      /** set display range */
      setG_DisStartUnix(uiStart_Unix);
      setG_DisEndUnix(uiEnd_Unix);

      // await F_GetDataForEachSelSensor(dataStart_Unix, dataEnd_Unix);
      loadTimeDelay.current = setTimeout(F_LoadDelay, 100);
      
    }
    startUp();

    return ()=>{
      // alert("Page Close");
      clearTimeout(loadTimeDelay.current);
  }
  }, [Grx_selectedList]);
  // Grx_selectedList


  const F_LoadDelay=()=>{
    setG_loaded(true);
    clearTimeout(loadTimeDelay.current);
  }
  /** check redux main load time, if load time expired, reload all set   */

  const F_GetDataForEachSelSensor=async(dataStart_Unix, dataEnd_Unix, forceReload)=>{      
    let reduxDataSet = JSON.parse(JSON.stringify(Grx_selDevData));
    let stillValidData = [];
    /** remove expired data */
    if(forceReload !== "forceReload"){
      for (const eachDataSet of reduxDataSet) {
        if(eachDataSet.loadTime + C_dataExpDuration_s > timeFn.getUnixNow()){ // still valid
          stillValidData.push(eachDataSet);
        }
      }
    }

    /** get selected sensor not in list*/
    for (const eachSelDev of Grx_selectedList) {
      let dataExist = stillValidData.find(c=>c.ht===eachSelDev.ht && c.bdDev_id===eachSelDev.bdDev_id);
      if(!dataExist){   // not in the list, need to query
        let data = await v2DataQuery.v2GetBdDevData_T1_T2(eachSelDev.ht, eachSelDev.bdDev_id, dataStart_Unix, dataEnd_Unix);
        stillValidData.push({
          ht: eachSelDev.ht, 
          bdDev_id:eachSelDev.bdDev_id,
          loadTime:timeFn.getUnixNow(),
          startTime:dataStart_Unix,
          endTime:dataEnd_Unix,
          data
        })
      }
    }
    dispatch(setHisDataSet(stillValidData));
    
  }

  const F_SetTimeRange =async ()=>{
    let startTime = document.getElementById('In_His_StartTime').value;
    let endTime = document.getElementById('In_His_EndTime').value;
    
    /** time format checking */
    if(!startTime) return toast("Invalid Start Time");
    if(!endTime) return toast("Invalid End Time");
    if(startTime>=endTime) return toast("Invalid Time Range");

    /** convert date time to unix */
    let startUnix = timeFn.getUnix_DateTimeLocal_UTC0(startTime)-building_TzDiff_s;
    let endUnix = timeFn.getUnix_DateTimeLocal_UTC0(endTime)-building_TzDiff_s;
    dispatch(setTimeRange({startUnix, endUnix}));

    /** check if beyond current data time range */
    let newStartUnix = G_DataStart_Unix;
    let newEndUnix = G_DataEnd_Unix;
    let bBeyondRange = false;

    if(startUnix < G_DataStart_Unix ){
      bBeyondRange=true;
      newStartUnix = startUnix;
    }
    if(endUnix > G_DataEnd_Unix) { 
      bBeyondRange=true;
      newEndUnix = endUnix;
    }

    /** if new range + old range > 2 weeks, no need keep old range */
    if(newEndUnix - newStartUnix > 2*7*24*60*60){
      newStartUnix = startUnix;   /** set according time input */
      newEndUnix = endUnix;      /** set according time input */
    }

    if(bBeyondRange){
      /** reload data */
      setG_loaded(false);
      await F_GetDataForEachSelSensor(newStartUnix, newEndUnix, "forceReload");
      loadTimeDelay.current = setTimeout(F_LoadDelay, 100);
    }

    /** set data range */
    setG_DataStart_Unix(newStartUnix);
    setG_DataEnd_Unix(newEndUnix);
    dispatch(setTimeRange_Data({startUnix:newStartUnix, endUnix:newEndUnix}));
    /** set display range */
    setG_DisStartUnix(startUnix);
    setG_DisEndUnix(endUnix);

  }

  const F_StartEndTime_PlaceHolder=()=>{
    let {startUnix, endUnix} = Grx_TimeRange;   
    /** set to UI display */
    let unixNow = timeFn.getUnixNow();
    if(startUnix===0 || endUnix===0){   // not set before, use default 24h
      /** set Ui time */
      document.getElementById('In_His_StartTime').value = timeFn.getStringInputTime_ByUnix_UTC0(unixNow - 24*60*60 + building_TzDiff_s);
      document.getElementById('In_His_EndTime').value = timeFn.getStringInputTime_ByUnix_UTC0(unixNow + building_TzDiff_s);      
      return {uiStart_Unix:unixNow - 24*60*60, uiEnd_Unix:unixNow}
      // return {dataStart_Unix:unixNow - 24*60*60, dataEnd_Unix:unixNow}
    }else{    // set before, use back previous value
      /** set Ui time */
      document.getElementById('In_His_StartTime').value = timeFn.getStringInputTime_ByUnix_UTC0(startUnix + building_TzDiff_s);
      document.getElementById('In_His_EndTime').value = timeFn.getStringInputTime_ByUnix_UTC0(endUnix + building_TzDiff_s);
      return {uiStart_Unix:startUnix, uiEnd_Unix:endUnix}
      // return {dataStart_Unix:startUnix, dataEnd_Unix:endUnix}
    }
  }

  const F_ExitHisPg=()=>{
    history.push({pathname: "/data/deviceinfloor"}); 
    dispatch(clearSelectedList());  // clear monitoring list
    dispatch(clearTimeRange());  
    dispatch(clearTimeRange_Data());  
    
  }
  const F_BackHisPg=()=>{
    history.push({pathname: "/data/deviceinfloor"}); 
    // dispatch(clearSelectedList());  // clear monitoring list
    
  }

  const F_CallSortingPg=()=>{
    setG_ShowDialogBox(2);
  }

  const F_AddSensor=()=>{
    setG_ShowDialogBox(1);
  }

  const F_CloseDialog=()=>{
    setG_ShowDialogBox(0);
  }

  const F_RemoveSelDev=(devInfo)=>{
    dispatch(removeFromDevSelList(devInfo));
  }
  
  const F_HandleConfirmSort=(sortedDevList)=>{
    if(notEmptyArr(sortedDevList)){
      dispatch(setNewDevSelList(sortedDevList));
    }
    F_CloseDialog();
  }

  return (
    <div className='spring_HisPg'>
      {G_ShowDialogBox===1 && <TpDiaSelectDev hanleCloseDia={F_CloseDialog}/>}
      {G_ShowDialogBox===2 && <TpDiaSortObj 
        objList = {Grx_selectedList} 
        onClickSortConfirm={F_HandleConfirmSort}
        hanleCloseDia={F_CloseDialog}/>}

      <div className='spring_HisTopStick'>
        <div className='sortHor spring_HisTitleBar'>
          <div className='spring_HisTitle'>History</div>
          <div className='sortHor spring_HisIconContainer'>
            <div className='spring_HisIcon spring_hisIcon_Sort hoverPointer' onClick={F_CallSortingPg}><BiSortAlt2/></div>
            <div className='spring_HisIcon spring_hisIcon_Back hoverPointer' onClick={F_BackHisPg}><TbArrowBackUp/></div>
            <div className='spring_HisIcon spring_hisIcon_Exit hoverPointer' onClick={F_ExitHisPg}><MdExitToApp/></div>
          </div>
        </div>

        <div className='spring_hisSettingTab sortHor'>
          <div className='sortHor spring_hisSetTime'> 
            <div className="spring_hisTimeName">Start :</div>
            <input id='In_His_StartTime' type='datetime-local' className='spring_diaSearchInput'/>
          </div>
          <div className='sortHor spring_hisSetTime'> 
            <div className='spring_hisTimeName'>End :</div>
            <input id='In_His_EndTime' type='datetime-local' className='spring_diaSearchInput'/>            
            
            <div className='sortHor spring_hisSetTimeButton'>
              <div className='spring_HisSetTimeIconFrame hoverPointer' onClick={F_SetTimeRange}><BsFillPlayFill/></div>
            </div>
          </div>
          
        </div>
      </div>
      {/* {!G_loaded && <LegoLoader />} */}
      <div style={{paddingTop:"120px"}}>
        {G_loaded!==true && <div><LegoLoader/></div>}
        {/* <LegoLoader/> */}
        <div className='spring_hisDisplayContainer'>
          {G_loaded===true && Grx_selectedList.map((u, ind_u) => (
            <div key={`hisData_${ind_u}`} className='devBdFrame spring_hisDisplayLayout'>
              <div className='spring_HisGraphTitleBar'>
                <div className='sortHor'>
                  <div className='tpDevice_subtitle'>{u.pos} [{u.dev.devID}] {u.a_sensorDetails[0].name}</div>
                  <div className='spring_HisGraphCancel hoverPointer' onClick={()=>F_RemoveSelDev(u)}><ImCancelCircle/></div>
                </div>
                <div className='wrapTextEllipsis spring_HisGraphTitle'>{`${u.dev.name}`}</div>
              </div>
              <SubTpGraph 
                user_id={u.user_id}
                dev={u.dev} 
                pos={u.pos}
                a_para = {[...u.a_para]}
                a_sensorDetails={[...u.a_sensorDetails]}            
                bIsHistoryGraph={true}
                hisStartUnix={G_DisStartUnix}
                hisEndUnix={G_DisEndUnix}
                // TpManu={G_TpDevmanu}
              />
            </div>
          ))}
          {G_loaded===true && <div className='devBdFrame spring_hisDisplayLayout hoverPointer' onClick={F_AddSensor}>
            <div className='spring_HisGraph_AddDev'>
              <GrFormAdd className='spring_HisGraph_AddDevIcon'/>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default HisDataSensor
