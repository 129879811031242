import React, { useEffect, useState } from 'react';
import {isEmptyArr, notEmptyArr, sortTemplate} from '../../../../../function/ArrayFn/arrayFn';
import sensorMngFn from '../../../../../function/sensor/sensorManagementFn';
import DashItemTitleBar from './Common/DashItemTitleBar';
import { useSelector } from 'react-redux';
import { getFullDashDataList } from '../../../../../reduxStore/actions/dash_Data';
import { toast } from 'react-toastify';
import PinwheelLoader from '../../../Version2a/Template/LoaderTemplate/pinwheelLoader';
import CompRename from './../../ComponentManu/CompRename';
import C0004Edit from './../../ComponentManu/C0004Edit';

function C0004GroupStatus(props) {
    const fullList = useSelector(getFullDashDataList);

    const [G_loaded, setG_loaded] = useState(false);
    const [G_Display, setG_Display] = useState([]);
    const [G_compSettings, setG_compSettings] = useState({});
    const [G_compDisplayPg, setG_compDisplayPg] = useState(0);

    useEffect(() => {
        async function startUp() {
            let { compInfo, auxList } = props;
            // console.log("auxList", auxList);
            getData(compInfo.bdDevInvolve_List, fullList, auxList);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
    }, [props]);

    const getData=(_devInvolve, dataList, auxList)=>{
        let devInvolve = [..._devInvolve];
        /** sort the data list */
        let sortKeys = [
            { key: "dataSortIdx", descOrder: false },
        ];
        devInvolve.sort((a, b) => sortTemplate(a, b, sortKeys));

        /** filter and push the data value */
        let displayInfo = [];
        let someDataIn = true;
        for (const eachDev of devInvolve) {
            let devData = dataList.filter(c=>
                c.bdDev_id === eachDev.bdDev_id && 
                c.devType === eachDev.devType && 
                c.qty >= 1 && 
                c.queryType === "lastN"
            )
            if(isEmptyArr(devData)) someDataIn = false;
            let paraKey = sensorMngFn.getParaKey_int(eachDev.dataType, eachDev.dataIndex);
            
            try {
                if(notEmptyArr(devData)){
                    if (Object.hasOwnProperty.call(devData[0].data[0], paraKey)) {
                        // let paraValue = devData[0][paraKey];
                        let paraValue = devData[0].data[0][paraKey];
                        if (typeof paraValue === 'undefined') {
                            displayInfo.push({Name: eachDev.Name, value: 0, status: "Err"});
                            continue
                        }                        
                        let compareVal = 0;
                        if(paraValue >= eachDev.para_1){
                            compareVal = 1;
                        }
                        displayInfo.push({Name: eachDev.Name, value: compareVal, status: "Ok"});
                    }
                }                
            } catch (error) {
                toast(`Generate data err(Dash): ${error.message}`);
                displayInfo.push({Name: eachDev.Name, value: 0, status: "Err"});
                continue;
            }   
        }

        let _compSettings = {}
        let auxSize = auxList.find(c => c.auxId === 'size').auxValue;
        if(auxSize === "full") _compSettings.size = 1;
        else if(auxSize === "half") _compSettings.size = 2;
        _compSettings.imgName = auxList.find(c => c.auxId === 'imgName').auxValue;
        
        setG_loaded(someDataIn);
        setG_Display(displayInfo);
        setG_compSettings(_compSettings);
    }

    const handleClickRename=()=>{
        setG_compDisplayPg(1);
    }
    const handleClickEdit=()=>{
        setG_compDisplayPg(2);
    }

    return ( 
        <div className={G_compSettings.size === 1? 'spring_dahsItemSize_400_300' : 'spring_dahsItemSize_200_300'}>
            <div className='spring_dashItemFrame divRelative' >
                {/* <div className='spring_GreyOutBox'></div> */}
                <DashItemTitleBar 
                    title={`C4-${props.compInfo.Name}`}
                    // onClickManu={()=>handleClickManu()}
                    onClickRename={handleClickRename}
                    onClickEdit = {handleClickEdit}
                    heightPortion={G_compSettings.size}
                    />
                <div className=''>
                    {G_compDisplayPg === 2 && <C0004Edit/>}
                    {G_compDisplayPg === 1 && <CompRename heightPortion={G_compSettings.size}/>}
                    {G_compDisplayPg === 0 && <div>
                        {G_loaded && <div className=''>
                            {G_Display.map((u, ind) => (
                                <div key={`Comp_${props.compInfo._id}_${ind}`} className='spring_groupStatus sortHor flexVerCenter'>
                                    <div>{u.Name}</div>
                                    <div className='sortHor stayRight'>
                                        <div className={`spring_idleIndicator ${u.value >= 1? "spring_greenIndicator" : ""}`}></div>
                                        <div className={`spring_idleIndicator ${u.value < 1? "spring_redIndicator" : ""}`}></div>
                                    </div>
                                    {/* {u.value >= 1 && <div>On</div>}
                                    {u.value < 1 && <div>Off</div>} */}
                                </div>
                            ))}
                        </div>}
                        
                        {!G_loaded && <div style={{ position: "relative" }}>
                            <div className='divAbsolute'>
                                <div className='divRelative' style={{top: G_compSettings.size === 1? "80px" : "15px", left:"130px"}}>
                                    <PinwheelLoader style={{top:"900px"}}/>
                                </div>
                            </div>
                        </div>}

                    </div>}

                </div>
            </div>
        </div>
    );
}

export default C0004GroupStatus;