import React, { useEffect, useState } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";

const SLTabSetting = (props) => {
  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const handleCloseDia = () => {
    setG_statusState(0);
  };
  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };
  const setGw = () => {
    setG_statusState(1);
  };

  return (
    <div className="spring_SL_TabPg">
      {G_statusState === 1 && (
        <TpDiaGatewayID
          divInfo={props.dev}
          gwPair={G_GwID}
          onClickDiaClose={handleCloseDia}
          setGwId={handleSetGwDisplay}
        />
      )}

      <div className="sortHor">
        <div>Choose Gateway</div>
        <div className="stayRight blueText hoverPointer" onClick={setGw}>
          {G_GwID}
        </div>
      </div>
    </div>
  );
};

export default SLTabSetting;
