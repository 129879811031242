import React, { useEffect } from 'react';

function TpDiaSaveSceneToEeprom(props) {
    
    useEffect(()=>{
        async function startUp(){

        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(bEeporom)=>{
        if(props.confirmChange) {
            props.confirmChange(bEeporom);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_ruleVarListContainer'>
                        {/* <div className='spring_opeContainer' style={{paddingTop:"30px"}}>
                            <div className='spring_SceneSaveEeprom hoverPointer'
                            onClick={()=>handleConfirm()}>
                                <div>
                                    {`Store to Roger's memory `}
                                    <HiOutlineChip className='spring_RjSceneChipIcon'/>
                                </div>
                            </div>
                            <div className='spring_SceneSaveEeprom hoverPointer'
                            onClick={()=>handleConfirm()}>{`Trigger only `}</div>
                        </div> */}
                        <div className='spring_uploadSceText_Container'>
                            <div className='spring_uploadSceText'>
                                Save
                            </div>
                            {/* <div>To Roger Jr.</div> */}
                        </div>
                    </div>

                                  

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaSaveSceneToEeprom;