import React, { useEffect, useState, useRef } from 'react';
import SubTpGraph from './SubTemplate/SubTpGraph';
import TpList, { C_CtrlTp_List } from '../Template/TpList';
import v2QueryFn from "../../../../function/V2_Query/V2_QueryBdDevData";
import { cvrtSnrRssiToSPer } from '../../../../function/calculation/snrRssiBatt';
import timeFn from '../../../../component/utilities/timeFn';
import moment from 'moment';
import { HiDotsVertical } from "react-icons/hi";
import { MdSignalCellular4Bar, MdSignalCellular3Bar, MdSignalCellular2Bar, 
    MdSignalCellular1Bar, MdSignalCellular0Bar } from "react-icons/md";
import { GiBattery100, GiBattery75, GiBattery50, GiBattery25, GiBattery0 } from "react-icons/gi";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { TbReplace, TbPlugConnectedX, TbPlugConnected} from "react-icons/tb";
import v2OwnDevFn from '../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import socketIOClient from "socket.io-client";
import TpDiaRename from './DialogTemplate/TpDiaRename';
import { toast } from 'react-toastify';
import TpDeleteDevice from './DialogTemplate/TpDeleteDevice';
import TpSwapDevice from './DialogTemplate/TpSwapDevice';
import devFn from '../../../../function/device/sensorDevice';
import TpDeteachDev from './DialogTemplate/TpDeteachDev';
import { isEmptyArr } from '../../../../function/ArrayFn/arrayFn';
import { BsFileEarmarkBarGraph, BsFillBookmarkPlusFill, BsFillBookmarkXFill } from "react-icons/bs";
import { HiChevronRight } from "react-icons/hi";

import localStorageFn  from '../../../../function/localStorage/LocalStorageFn';
import { useDispatch, useSelector } from 'react-redux';
import { addToDevSelList, getSelectedList, removeFromDevSelList } from '../../../../reduxStore/actions/dev_selHistoryTrend';

import SensorMngtFn from '../../../../function/sensor/sensorManagementFn';

function TpV2aDevice(props) {    
    const dispatch = useDispatch();  
    const devSelectedList = useSelector(getSelectedList);

    const [G_TpShow, setG_TpShow] = useState(0);
    const [G_signalPer, setG_signalPer] = useState();
    const [G_battVolt, setG_battVolt] = useState(null);
    const [G_deviceName, setG_deviceName] = useState("");
    
    const [G_WarningMsg, setG_WarningMsg] = useState("");
    const [G_bShowWarningBar, setG_bShowWarningBar] = useState(false);
    const [G_addCompare, setG_addCompare] = useState(false);

    const [G_TpDevmanu, setG_TpDevmanu] = useState(0);
    const [G_count, setG_count] = useState(0);
    const timerRef = useRef(null);
    const ref1stLoad = useRef(1);

    useEffect(()=>{
        timerRef.current = setInterval(() => {
            setG_count(count=>count+1);
        }, 120000);       /** 120000 , 2 mins*/
        
        let socket_dataLog, socket_dataReceiver;
        async function startUp(){
            socket_dataLog = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
            socket_dataReceiver = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATARECEIVER);

            setG_deviceName(props.dev.name);
            await loadLastData();
            if(ref1stLoad.current !== 2){
                try {
                    // let found = C_CtrlTp_List.find(c=>c.ht === props.dev.type);      // auto detect
                    // if(found)   setG_TpShow(1);
                    if(props.dev.type===47) setG_TpShow(1);     // show control setting by default

                } catch (error) {
                    toast(error.message);
                }
            }

            ref1stLoad.current = 2;

            let{type, _id}= props.dev;
            let topic = `v2_${type}_${_id}`;
            socket_dataLog.on(topic, data => {
                setG_count(count=>count+1);
            });
            socket_dataReceiver.on(topic, data => {
                setG_count(count=>count+1);
            });
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
            socket_dataLog.disconnect();
            socket_dataReceiver.disconnect();
            clearInterval(timerRef.current);
        }
        // eslint-disable-next-line
    }, [G_count]);

    const loadLastData = async () => {
        try {   // 
            if(!props.dev) return false;
            let watchListToken = localStorageFn.getWatchList();
            if(watchListToken) {
                if(watchListToken._id.find(c => c === props.dev._id)) setG_addCompare(true);
            }
            let _lastData = await v2QueryFn.v2GetData_LastN(props.dev.type, props.dev._id, 1);
            if(isEmptyArr(_lastData)) {
                updateWarningBar("Not connected");
                return false;
            }
            // console.log(_lastData);
            if(_lastData[0].SNR || _lastData[0].SNR===0) setG_signalPer(cvrtSnrRssiToSPer(_lastData[0].SNR));

            let _offline_mins = 10;   /** production value => 10 */
            let _unixNow = timeFn.getUnixNow();
            let _offTime = (_unixNow - _lastData[0].unix) / 60;
            let _offDuration;
            let _offlineTime="";
            if(_offTime > _offline_mins){
                // setG_offlineTime(moment.unix(_lastData[0].unix).format('yyyy-MM-DD, HH:mm'));
                let _Time = (moment.unix(_lastData[0].unix).format('yyyy-MM-DD, HH:mm'));

                if(_offTime > 1440)
                    _offDuration = `${Math.round(_offTime / 1440)} D ago`;
                else if(_offTime > 60)
                    _offDuration = `${Math.round(_offTime / 60)} h ago`;
                else
                    _offDuration = `${Math.round(_offTime)} m ago`;

                _offlineTime=`Offline since ${_Time} (${_offDuration})`
                // setG_offlineDuration(_offDuration);
            }
            // let _battList = await bdDevSettingFn.getBattList(props.dev._id);
            updateWarningBar(_offlineTime);

            // get batt value
            let sensorList = await SensorMngtFn.getSensorListByTypeList([props.dev.type]);
            // console.log(sensorList);
            if(sensorList[0].sensorVersion === 1){
                if(sensorList[0].type === 1 || sensorList[0].type === 2){
                    setG_battVolt(_lastData[0].battVoltage);
                }
            }else if(sensorList[0].sensorVersion === 2){
                if(sensorList[0].battKey.trim() !== ""){
                    setG_battVolt(_lastData[0][sensorList[0].battKey.trim()]);
                }
            }

            return true;
        } catch (error) {
            toast(error.message)
        }
        
    }

    // const setTpShow=(nTpNo)=>{
    //     setG_TpShow(nTpNo);
    // }

    const signalIcon = () => {
        if (G_signalPer > 90)
            return <MdSignalCellular4Bar />
        else if(G_signalPer > 65)
            return <MdSignalCellular3Bar />
        else if (G_signalPer > 40)
            return <MdSignalCellular2Bar />
        else if (G_signalPer > 15)
            return <MdSignalCellular1Bar />;
        else
            return <MdSignalCellular0Bar className='errColor' />;
    }

    const updateWarningBar = (_offlineTime) => {
        let _bShowWarningBar = true;
        if(props.dev.devID===0){
            setG_WarningMsg("Device not attach");
        }else if(_offlineTime !== ""){
            setG_WarningMsg(_offlineTime);
        }else{
            _bShowWarningBar = false;
        }
        setG_bShowWarningBar(_bShowWarningBar);
        // if(_offlineTime !== "" || props.dev.devID===0) {
        //     setG_WarningMsg(_offlineTime);
        //     setG_bShowWarningBar(true);
        // }else{
        //     setG_bShowWarningBar(false);
        // }
        // G_WarningMsg

    }

    const battIcon = () => {
        // console.log(G_battVolt);
        // if(G_battVolt === null) return <GiBattery100 />;

        if (G_battVolt < 2.85)
            return <GiBattery0 className='errColor' />
        else if(G_battVolt < 2.9)
            return <GiBattery25 />
        else if (G_battVolt < 2.95)
            return <GiBattery50 />
        else if (G_battVolt < 3.0)
            return <GiBattery75 />;
        else
            return <GiBattery100 />;
    }

    const handleCallDevMenu = () => {
        setG_TpDevmanu(1);
    }
    const handleCloseDevManu = () => {
        setG_TpDevmanu(0);
    }

    const handleCallRename = () => {
        setG_TpDevmanu(2);
    }
    
    
    const handleRename = async(newName) => {
        let renameRel = await v2OwnDevFn.v2aRename_Dev(newName, props.dev._id);
        if(renameRel.errMsg) {
            toast(renameRel.errMsg);
            return
        }
        toast("Success");
        setG_deviceName(newName);
        handleCloseDevManu();
        if (props.callRenameDev) props.callRenameDev(newName);
    }

    const handleDeleteDev=()=>{
        setG_TpDevmanu(3);
    }

    const confirmDeleteDev=async()=>{
        let delrel = await v2OwnDevFn.v2aDelete_Dev(props.dev._id);
        if(delrel.errMsg) return toast("Delete Err: ", delrel.errMsg);
        if(delrel.Success){
            toast("Success");
            if(props.callRefreshPage) props.callRefreshPage();
            return 
        } 
        toast("Delete Err");
    }
    const handleReplaceDev=()=>{
        setG_TpDevmanu(4);
    }
    const confirmSwapDev=async(SerialNo, RegCode)=>{
        /**check Sn and Reg code*/
        let verResult = await devFn.verifySnRegCode(SerialNo, RegCode);
        if(verResult.errMsg) return toast(verResult.errMsg);
        /** set  */
        let swapRel = await v2OwnDevFn.v2aSwap_Dev(props.owner_id, props.dev._id, SerialNo, RegCode);
        if(swapRel.errMsg) return toast(swapRel.errMsg);
        if(swapRel.Success){
            toast("Success");
            if(props.callRefreshPage) props.callRefreshPage();
            return 
        } 
        toast("Swap Err");
    }
    const handleDeteachDev=()=>{
        setG_TpDevmanu(5);
    }
    const confirmDeteachDev=async()=>{
        /**check Sn and Reg code*/
        let detResult = await v2OwnDevFn.v2aDeteach_Dev(props.dev._id);
        if(detResult.errMsg) toast(detResult.errMsg);
        if(detResult.Success){
            toast("Success");
            if(props.callRefreshPage) props.callRefreshPage();
            return 
        } 
        toast("Detach Err");
    }

    const handleCompareGraph = async () => {
        setG_TpDevmanu(0);
        if(!G_addCompare) await localStorageFn.addToWatchList(props.dev._id);
        else await localStorageFn.removeFromWatchList(props.dev._id);
        setG_addCompare(!G_addCompare);
    }

    const togglesetTpShow=()=>{
        if(G_TpShow===0)    setG_TpShow(1);
        else     setG_TpShow(0);
    }

    const handleAddToSelList=()=>{  
        let {dev, pos, a_para, a_sensorDetails} = props;
        dispatch(addToDevSelList({
            ht:dev.type, 
            bdDev_id:dev._id,
            dev, pos, a_para, a_sensorDetails
        }));
    }

    const handleRemoveFromSelList=()=>{
        dispatch(removeFromDevSelList({ht:props.dev.type, bdDev_id:props.dev._id}));        
    }
    
    const F_DevSelected=()=>{
        let found = devSelectedList.find(c=>c.ht === props.dev.type && c.bdDev_id === props.dev._id);
        if(found)   return true
        return false;
    }

    const F_WithActiveCtrlTp=()=>{
        let found = C_CtrlTp_List.find(c=>c.ht === props.dev.type)
        if(found) return true
        return false;
    }

    return (
        <div className=''>
            {G_TpDevmanu===2 && <TpDiaRename         
                OldName = {G_deviceName} 
                onclickClose={handleCloseDevManu}
                onclickConfirm = {handleRename}
                />}

            {G_TpDevmanu===3 && <TpDeleteDevice
                deviceName = {G_deviceName}
                onclickClose={handleCloseDevManu}
                onclickConfirm = {confirmDeleteDev}
                />}

            {G_TpDevmanu===4 && <TpSwapDevice
                deviceName = {G_deviceName}
                isDeteach = {props.dev.devID===0}
                onclickClose={handleCloseDevManu}
                onclickConfirm = {confirmSwapDev}                
                />}

            {G_TpDevmanu===5 && <TpDeteachDev
                deviceName = {G_deviceName}
                onclickClose={handleCloseDevManu}
                onclickConfirm = {confirmDeteachDev}
                />}
            

            <div className={`tpDevice_container ${G_bShowWarningBar?"errBgc":""}`}>
                <div className='divRelative'>
                    {F_DevSelected()===false ? 
                        <div className='teDevice_SensorSelBox hoverPointer' onClick={handleAddToSelList}><BsFillBookmarkPlusFill/></div> :
                        <div className='teDevice_SensorSelBox_Selected hoverPointer' onClick={handleRemoveFromSelList}><BsFillBookmarkXFill/></div>
                    }
                </div>
                <div className='sortHor spreadBetween '>
                    <div className='sortHor spreadBetween tpDevice_subtitleFrame'>
                        <div className='tpDevice_subtitle'>{props.pos} [{props.dev.devID}] {props.a_sensorDetails[0].name}</div>
                        
                        <div className=' stayRight'>{signalIcon()}</div>
                        {/* <div className=' tpDevice_icons '><GiBattery100 /></div> */}
                        {G_battVolt !== null && <div className=' tpDevice_icons '>{battIcon()}</div>}
                        <div className=' tpDevice_icons '>
                            <HiDotsVertical className='hoverPointer' onClick={handleCallDevMenu}/>
                            {G_TpDevmanu===1 && <div className='spring_devManuContainer spring_index100'
                                onClick={handleCloseDevManu}></div>}
                            {G_TpDevmanu===1 && <div style={{position:"relative"}}>
                                <div className='spring_devManuFrame spring_shadowBox spring_index101'
                                style={{position:"absolute" }}>

                                    <div>
                                        <div className='sortHor spring_devManuItem hoverPointer'
                                            onClick={handleCompareGraph}>
                                            <BsFileEarmarkBarGraph />
                                            {!G_addCompare && <div className='spring_marginLeft10'>Add to Compare</div>}
                                            {G_addCompare && <div className='spring_marginLeft10'>Remove Compare</div>}
                                        </div>
                                    </div>

                                    {props.shareLevel <= 4 && <div>
                                        <div className='spring_seperationLine'></div>
                                        <div className='sortHor spring_devManuItem hoverPointer'
                                        onClick={handleCallRename}>
                                            <FiEdit3/>
                                            <div className='spring_marginLeft10'>Rename</div>
                                        </div>
                                    </div>}
                                    
                                    {props.shareLevel <= 2 && props.dev.devID!==0 && <div className=''>
                                        <div className='spring_seperationLine'></div>
                                        <div className='sortHor spring_devManuItem hoverPointer'
                                        onClick={handleReplaceDev}>
                                            <TbReplace/>
                                            <div className='spring_marginLeft10'>Replace</div>
                                        </div>

                                        <div className='spring_seperationLine'></div>
                                        <div className='sortHor spring_devManuItem hoverPointer'
                                        onClick={handleDeteachDev}>
                                            <TbPlugConnectedX/>
                                            <div className='spring_marginLeft10'>Detach</div>
                                        </div>
                                    </div>}

                                    {props.shareLevel <= 2 && props.dev.devID===0 && <div>
                                        <div className='spring_seperationLine'></div>
                                        <div className='sortHor spring_devManuItem hoverPointer'
                                        onClick={handleReplaceDev}>
                                            <TbPlugConnected/>
                                            <div className='spring_marginLeft10'>Attach</div>
                                        </div>
                                    </div>}
                                    {props.shareLevel <= 2 && <div>
                                        <div className='spring_seperationLine'></div>
                                        <div className='sortHor spring_devManuItem hoverPointer'
                                        onClick={handleDeleteDev}>
                                            <FiTrash2 className='redText'/>
                                            <div className='spring_marginLeft10'>Delete</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>}
                        </div>
                    </div>
                    
                </div>
                <div className='sortHor'>
                    <div className='tpDevice_title'>{G_deviceName}</div>
                    {F_WithActiveCtrlTp()===true && <div className='spring_TpChangeTab hoverPointer' onClick={togglesetTpShow}><HiChevronRight/></div>}
                </div>
                <div className={`tpDevice_offline sortMiddle ${G_bShowWarningBar?"":"divHidden"}`}>
                    {`${G_WarningMsg===""?"N/A":`${G_WarningMsg}`}`}
                </div>

            </div>

            {/* <div className='sortHor spreadEvenly'>
                <div className={`${G_TpShow === 1 ?"spring_SelectedTab":"" } hoverPointer`} onClick={()=>setTpShow(1)}> Control </div>
                <div className={`${G_TpShow === 0 ?"spring_SelectedTab":"" } hoverPointer`} onClick={()=>setTpShow(0)}> Graph </div>
            </div> */}

            <div className=''>
                {G_TpShow === 1 && <TpList 
                    ht = {props.dev.type} {...props}
                    />}
                {G_TpShow === 0 && <SubTpGraph 
                    user_id={props.user_id}
                    dev={props.dev} 
                    pos={props.pos}
                    a_para = {[...props.a_para]}
                    a_sensorDetails={[...props.a_sensorDetails]}
                    TpManu={G_TpDevmanu}
                />}
            </div>      
        </div>
    );
}

export default TpV2aDevice;