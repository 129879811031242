import React, { useEffect, useState } from 'react';
import appFn from '../../../../../../function/V2_Application/V2_ApplicationFn';
import { toast } from 'react-toastify';
import slaveCtrlFn from '../../../Common/AppSlaveControl/AppSlaveControl';
import TpDiaGatewayID from './../../../Common/Dialog/Input/TpDiaGatewayID';
import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import TpSelectSensorKey from './../../../Common/Dialog/Input/TpSelectSensorKey';
import TpDiaLoadSensorInBd from './../../../Common/Dialog/Input/TpDiaLoadSensorInBd';

function TpCondensorLoopMaster(props) {
    const C_App_id = 1;
    const C_Dev_info = {
        bdDev_id: 25,
        hi: 11,
        ht: 39,
        inUse: 1,
        type: 39
    };

    const [G_statusState, setG_statusState] = useState(0);

    const [G_appMemberList, setG_appMemberList] = useState([]);
    const [G_gwPair, setG_gwPair] = useState(0);
    

    useEffect(() => {
        async function startUp() {
            /** load slave under this application */
            loadAppMember();
            await loadGwPair();
            console.log(props);

        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);

    const loadAppMember = async()=>{
        let getMemberRel = await appFn.getV2_AppMember(C_App_id);
        if(getMemberRel.errMsg) return toast(`Load member err.`);
        // let {appMemberRel:appMember} = getMemberRel;
        console.log("getMemberRel", getMemberRel);
        setG_appMemberList(getMemberRel);
    }

    const handleCallGwId=()=>{
        setG_statusState(11);
    }
    const handleSetGwDisplay=(gwid)=>{
        setG_gwPair(gwid);
    }
    const handleCloseDia=()=>{
        setG_statusState(0);
    }
    const handleSelTuningVar=()=>{
        setG_statusState(12);
    }
    const handleSelSensor=()=>{
        setG_statusState(13);
    }
    
    const loadGwPair=async ()=>{
        try { 
            let pairedGw = await v2ctrlFn.getV2_GwPair(C_Dev_info.bdDev_id);
            if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
            let {gwPairList} = pairedGw;
            if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
            setG_gwPair(gwPairList[0].gwid);
        } catch (error) {
            console.log("loadGwPair err: ", error.message);
        }
    }
    


    return (
        <div>
            {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
            {G_statusState===11 && <TpDiaGatewayID 
                divInfo = {C_Dev_info} 
                gwPair = {G_gwPair} 
                onClickDiaClose = {handleCloseDia}
                setGwId = {handleSetGwDisplay}
            />}

            {G_statusState===12 && <TpSelectSensorKey
                onClickDiaClose = {handleCloseDia}
                onClickSelSensor = {handleSelSensor}
            />}
            {G_statusState===13 && <TpDiaLoadSensorInBd
                onClickDiaClose = {handleCloseDia}
            />}
            
            <div className='spring_tpFrame spring_shadowBox'>
                
                <div className='spring_tpTitle'>Master</div>
                
                <div className='spring_cardFrame'>

                    <div className='spring_HeaderText'>
                            Slave Control
                    </div>
                    <div className='spring_ContentArea'>
                        {G_appMemberList.map((u, ind)=>(       
                            <div key={`slaveCtrlKey_${ind}`}>
                                {slaveCtrlFn.getSlaveControl(u.type, u, C_Dev_info, G_gwPair)}
                            </div>
                        ))} 
                    </div>
                    

                </div>
                
                <div className='spring_seperationLine'></div>
                <div className=' spring_cardFrame '>
                    <div className='spring_ContentHeader sortHor'>
                        <div className='spring_HeaderText'>
                            Gateway Pair
                        </div>
                        <div className='spring_rightInput blueText hoverPointer'
                        onClick={handleCallGwId}>{`${G_gwPair>0 ?G_gwPair: "-"}`}</div>
                    </div>
                </div>
                
                <div className='spring_seperationLine'></div>
                <div className=' spring_cardFrame '>
                    <div className='spring_ContentHeader'>
                        <div className='spring_HeaderText'>
                            Tuning Variable Pair
                        </div>        
                        <div className='spring_smallerText_08 blueText hoverPointer'
                        onClick={handleSelTuningVar}>
                            <div>
                                [115] DPM Chiller Plantroom,
                            </div>                              
                            <div>
                                Active Power
                            </div>                        
                        </div>                        
                    </div>
                </div>

                <div className='spring_seperationLine'></div>
                <div className=' spring_cardFrame '>
                    <div className='spring_ContentHeader'>
                        <div className='spring_HeaderText'>
                            Retune Variable Pair
                        </div>        
                        <div className='spring_smallerText_08 blueText hoverPointer'>
                            <div>
                                [115] DPM Chiller Plantroom,
                            </div>                              
                            <div>
                                Active Power
                            </div>                        
                        </div>                        
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TpCondensorLoopMaster;