import React, { useEffect, useState } from 'react';

function TpDiaInsertString(props) {

    const [G_OldString, setG_OldString] = useState("--"); 
    const [G_middleText, setG_middleText] = useState("--"); 
    const [G_MaxLength, setG_MaxLength] = useState(80); 

    
    useEffect(()=>{
        async function startUp(){
            if (props.oldString) setG_OldString(props.oldString);
            if (props.middleText) setG_middleText(props.middleText);
            if(props.inputLength) setG_MaxLength(props.inputLength);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        let newName = document.getElementById("in_newName").value;
        if(props.confirmChange) {
            props.confirmChange(newName);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_inputTpTopText'> {G_OldString}</div>
                    <div className='spring_inputTpMidText'>{G_middleText}</div>
                    <div className="searchBarContainer_non"
                    style={{margin:"30px 0px"}}>
                        <input id='in_newName' 
                            autoComplete='off'
                            className='spring_diaSearchInput blueText'
                            style={{width:"100%", marginRight:"10px"}}
                            type={`${props.isNumber?"number":"text"}`}
                            maxLength={G_MaxLength}
                            />
                    </div>    

                                  

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Discard
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaInsertString;