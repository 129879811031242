import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  try {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (error.response.status === 403) {
      // relog in required
      // console.log("handling user exp:", error);
      toast("Re-login required after 7 days");
      window.location.reload(false); // call a refresh, auth will auto redirect to login page
      // window.location = "/"; // call a full reload
      return;
    }

    if (!expectedError) {
      // log the error message for unexpected error
      console.log("Interceptor Logging Error: ", error);
      toast("Connection Err");
    }
    return Promise.reject(error);
  } catch (error) {
    console.log(`axios.interceptors err: ${error.message}`);
  }
});

function setJwt(jwt) {
  //set token to all header, common = all, post = only to post method
  axios.defaults.headers.common["aploud-auth-token"] = jwt;
}

function setV2Jwt(jwt) {
  axios.defaults.headers.common["aploudv2-auth-token"] = jwt;
}

const expModule = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  setV2Jwt,
};
export default expModule;

// export default {
//   get: axios.get,
//   post: axios.post,
//   put: axios.put,
//   delete: axios.delete,
//   setJwt,
// };
