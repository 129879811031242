import React, { useEffect, useState } from 'react';
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { F_RjGetFanSpeed, F_RjScheAction } from './RjFunction/RjFn';
import TpDiaChooseScheAction from './TpDialog/TpDiaChooseScheAction';
import TpDiaInsertString from './../../../../Control/Common/Dialog/Input/TpDiaInsertString';
import TpDiaChooseFsSwing from './TpDialog/TpDiaChooseFsSwing';
import TpDiaChooseScene from './TpDialog/TpDiaChooseScene';
import timeFn from "../../../../../../function/time/timeFn";
import { getBitByIdx, setBitByIdx, clearBitByIdx } from '../../../../../../function/common/common';
import { BiSave } from "react-icons/bi";
import { GrRevert } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";
import { toast } from 'react-toastify';

function TabScheListEdit(props) {
    
    const [G_ScheDisplay, setG_ScheDisplay] = useState({});
    const [G_InputPg, setG_InputPg] = useState(0);
    const [G_SceneList, setG_SceneList] = useState([]);

    useEffect(()=>{
        async function startUp(){
            if(props.scheSel) {
                setG_ScheDisplay(props.scheSel);
                fin_preSetDateTime(props.scheSel.startUnix, props.scheSel.scheType);
            }
            if(props.sceneList) {
                setG_SceneList(props.sceneList);
            }
        }
        startUp();

        return ()=>{
            // alert("Page Close");
        }
        // eslint-disable-next-line
    }, []);    

    const fin_preSetDateTime=(startUnix, scheType)=>{
        /** set time  */
        try {
            document.getElementById('in_scheTime').value = timeFn.getUnixTime(startUnix);            
            if(scheType===2){   // event type
                let unixDate = timeFn.getUnixDate(startUnix);
                document.getElementById('in_scheDate').value = unixDate

            }
        } catch (error) {
            
        }
    }

    const fToggleActive=()=>{
        let _ScheDisplay = {...G_ScheDisplay};
        // _ScheDisplay.active = !_ScheDisplay.active;
        if(_ScheDisplay.active<1) _ScheDisplay.active = 1;
        else _ScheDisplay.active=0;
        setG_ScheDisplay(_ScheDisplay);
    }
    const fTogglePriority=()=>{
        let _ScheDisplay = {...G_ScheDisplay};
        if(_ScheDisplay.priority<1) _ScheDisplay.priority = 1;
        else _ScheDisplay.priority=0;
        setG_ScheDisplay(_ScheDisplay);

    }
    
    const fChooseAction=()=>{
        /** call choose action page */
        setG_InputPg(1);
    }
    const fCloseInputPage=()=>{
        setG_InputPg(0);
    }
    const fConfirmAction=(nAct)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        _ScheDisplay.action = nAct;
        if(_ScheDisplay.action===1){
            _ScheDisplay.Setpoint = parseFloat(_ScheDisplay.Setpoint).toFixed(0);
        }
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }
    const fCallInputPage=(nPg)=>{
        /** call choose action page */
        setG_InputPg(nPg);
    }
    const handleChangeSp=(nVar)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        let dp = 1;
        if(_ScheDisplay.action ===1 ) dp = 0;
        let Sp = (parseFloat(nVar)).toFixed(dp);
        if(Sp<16)Sp =16;
        else if(Sp>30)Sp =30;
        _ScheDisplay.Setpoint = Sp;
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }
    const handleChangeName=(nVar)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        _ScheDisplay.name = nVar;
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }
    

    const fConfirmFanSpeed=(nAct)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        _ScheDisplay.Fan = nAct;
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }
    const fConfirmSwingPos=(nAct)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        _ScheDisplay.Swing = nAct;
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }
    
    const fGetSceneName=(_SceneList, nVar)=>{
        let found = _SceneList.find(c=>c.sceneIdx === nVar);
        if(found) return found.Name;
        return "";
    }

    const fConfirmChooseScene=(sceneSel)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        _ScheDisplay.sceneIdx = sceneSel.sceneIdx;
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }

    const fToggleScheTy=()=>{
        let _ScheDisplay = {...G_ScheDisplay};
        _ScheDisplay.scheType ++;
        if(_ScheDisplay.scheType > 2){
            _ScheDisplay.scheType=1;
        }
        setG_ScheDisplay(_ScheDisplay);
        setG_InputPg(0);
    }
    
    const toggleDow=(idx)=>{
        let _ScheDisplay = {...G_ScheDisplay};
        if(getBitByIdx(_ScheDisplay.DOW, idx)){      // is on
            _ScheDisplay.DOW = clearBitByIdx(_ScheDisplay.DOW, idx);
        }else{      // is off
            _ScheDisplay.DOW = setBitByIdx(_ScheDisplay.DOW, idx);
        }
        setG_ScheDisplay(_ScheDisplay);
    }

    const handleBackTo=()=>{
        if(props.backToScheList) props.backToScheList();
    }


    const validateSche=()=>{
        /** name can be empty */

        /** valid date format if event type */
        if(G_ScheDisplay.scheType===2){     // event schedule
            let date = document.getElementById("in_scheDate").value;
            if(!date) return {errMsg:"Insert valid date"};
        }
        /** valid time format */
        let inTime = document.getElementById("in_scheTime").value;
        if(!inTime) return {errMsg:"Insert valid time"};
        /** sceneIdx cannot be empty */
        if(G_ScheDisplay.sceneIdx <= 0 && G_ScheDisplay.action===3) return {errMsg:"Scene not selected"};
        return true
    }

    const handleSaveSche=()=>{
        let valRel = validateSche();
        if(valRel.errMsg) return toast(valRel.errMsg);

        let _ScheDisplay = {...G_ScheDisplay};
        /** get time info */
        let inTime = document.getElementById("in_scheTime").value;
        if(_ScheDisplay.scheType === 1){     // weekly mode
            /** use time to generate today unix */
            _ScheDisplay.startUnix = timeFn.cHH_mmToUnix_v2(inTime);
        }else{      //
            /** get date and time to generate unix */
            let inDate = document.getElementById("in_scheDate").value;
            _ScheDisplay.startUnix = timeFn.getUnix_from_Date_Time(inDate, inTime);
        }
        if(props.saveEdit) props.saveEdit(_ScheDisplay);
    }

    const handleDelSche=()=>{
        if(props.delSche) props.delSche();
    }
    



    return (
        <div className='spring_editScenePg'>
            {G_InputPg === 1 && <TpDiaChooseScheAction
                onclickClose={fCloseInputPage}
                confirmChange={fConfirmAction}
                />}
            {G_InputPg ===2 && <TpDiaInsertString 
                oldString={"Setpoint"}
                onclickClose={fCloseInputPage}
                middleText="SET TO"
                isNumber={true}
                confirmChange={handleChangeSp}/>}
            {G_InputPg ===3 && <TpDiaChooseFsSwing
                onclickClose={fCloseInputPage}
                confirmChange={fConfirmFanSpeed}
                />}
            {G_InputPg ===4 && <TpDiaChooseFsSwing
                onclickClose={fCloseInputPage}
                confirmChange={fConfirmSwingPos}
                />}
            {G_InputPg ===5 && <TpDiaChooseScene
                varList = {G_SceneList}
                onclickClose={fCloseInputPage}
                confirmChange={fConfirmChooseScene}
                />}
            {G_InputPg ===6 && <TpDiaInsertString 
                oldString={"Name"}
                onclickClose={fCloseInputPage}
                middleText="SET TO"
                // isNumber={true}
                confirmChange={handleChangeName}
                inputLength={20}
                />}

            
            <div className='sortHor'>
                <div className='sortHor stayRight' style={{marginTop:"5px"}}>
                    <div className='spring_paddingLeft_10 spring_rulesEditIcon redText hoverPointer'
                        onClick={()=>handleDelSche()}
                        ><FiTrash2/></div>
                    <div className='spring_paddingLeft_10 spring_rulesEditIcon greenCheck hoverPointer'
                        onClick={handleSaveSche}
                        ><BiSave/></div>
                    <div className='spring_paddingLeft_10 spring_rulesEditIcon hoverPointer'
                        onClick={handleBackTo}
                        ><GrRevert/></div>
                </div>
                
            </div>

            
            <div className='spring_editScheGroup'>
                <div className='spring_editItemBar sortHor'>
                    <div className='spring_editLeftTitle'>Name:</div>
                    <div className='spring_editRightItem  blueText hoverPointer'
                    onClick={()=>fCallInputPage(6)}>
                        { (G_ScheDisplay.name && G_ScheDisplay.name.trim()!=="")?
                            <div>{G_ScheDisplay.name}</div>:
                            <div>{"< Insert name >"}</div>}
                        </div>
                </div>
                <div className='spring_editItemBar sortHor'>
                    <div className='spring_editLeftTitle'>Active</div>
                    <div className='hoverPointer spring_editRightItem spring_editIcon ' 
                    onClick={fToggleActive}>
                        {G_ScheDisplay.active ? 
                            <div className='reactCfgButtonOn flexAndBothCenter'><BsToggleOn/></div>:
                            <div className='flexAndBothCenter'><BsToggleOff/></div>}
                    </div>
                </div>
                <div className='spring_editItemBar sortHor'>
                    <div className='spring_editLeftTitle'>Priority</div>
                    <div className='hoverPointer spring_editRightItem spring_editIcon ' 
                    onClick={fTogglePriority}>
                        {G_ScheDisplay.priority >0? 
                            <div className='reactCfgButtonOn flexAndBothCenter'><BsToggleOn/></div>:
                            <div className='flexAndBothCenter'><BsToggleOff/></div>}
                    </div>
                </div>                
            </div>

            <div className='spring_editScheGroup'>
                <div className='spring_editItemBar '>
                    <div className='sortHor'>
                        <div className='spring_editLeftTitle'>Repeat:</div>
                        <div className='spring_editRightItem hoverPointer spring_editIcon'
                        onClick={fToggleScheTy}>
                            {G_ScheDisplay.scheType===1 ? 
                                <div className='reactCfgButtonOn flexAndBothCenter'><BsToggleOn/></div>:
                                <div className='flexAndBothCenter'><BsToggleOff/></div>}
                            {/* {F_RjScheType(G_ScheDisplay.scheType)} */}
                        </div>
                    </div>
                    
                </div>
                {G_ScheDisplay.scheType === 1 && <div className='sortHor spring_DowBar flexAndBothCenter'>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 0)?"spring_scheDowSelBox_sel":""} redText`} 
                        onClick={()=>toggleDow(0)}>S</div>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 1)?"spring_scheDowSelBox_sel":""}`}
                        onClick={()=>toggleDow(1)}>M</div>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 2)?"spring_scheDowSelBox_sel":""}`}
                        onClick={()=>toggleDow(2)}>T</div>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 3)?"spring_scheDowSelBox_sel":""}`}
                        onClick={()=>toggleDow(3)}>W</div>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 4)?"spring_scheDowSelBox_sel":""}`}
                        onClick={()=>toggleDow(4)}>T</div>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 5)?"spring_scheDowSelBox_sel":""}`}
                        onClick={()=>toggleDow(5)}>F</div>
                    <div className={`hoverPointer spring_scheDowSelBox ${getBitByIdx(G_ScheDisplay.DOW, 6)?"spring_scheDowSelBox_sel":""} redText`}
                        onClick={()=>toggleDow(6)}>S</div>
                </div>}
                <div className={`spring_editItemBar sortHor ${G_ScheDisplay.scheType === 2?"":"divHidden div0Height"}`}>
                    <div className='spring_editLeftTitle'>Date:</div>
                    <div className='spring_editRightItem  blueText'
                    style={{paddingRight:"0px"}}>
                        <input id='in_scheDate' 
                            autoComplete='off'
                            className='spring_diaSearchInput blueText'
                            style={{marginRight:"0px", backgroundColor:"var(--cardWhite)"}}
                            // defaultValue={"2023-01-08"}
                            type={`date`}
                            />
                    </div>
                </div>
                
                <div className='spring_editItemBar sortHor'>
                    <div className='spring_editLeftTitle'>Time:</div>
                    <div className='spring_editRightItem  blueText'
                    style={{paddingRight:"0px"}}>
                        <input id='in_scheTime' 
                            autoComplete='off'
                            className='spring_diaSearchInput blueText '
                            style={{marginRight:"0px", backgroundColor:"var(--cardWhite)"}}
                            // defaultValue={()=>timeFn.getUnixTime(G_ScheDisplay.startUnix)}
                            type={`time`}
                            />
                    </div>
                </div>
            </div>
                        
            <div className='spring_editScheGroup'>
                <div className='spring_editItemBar sortHor'>
                    <div className='spring_editLeftTitle'>Action:</div>
                    <div className='spring_editRightItem hoverPointer blueText'
                    onClick={fChooseAction}>{F_RjScheAction(G_ScheDisplay.action)}</div>
                </div>
                {(G_ScheDisplay.action ===1 || G_ScheDisplay.action ===2) && <div>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Setpoint:</div>
                        <div className='spring_editRightItem  blueText hoverPointer'
                            onClick={()=>fCallInputPage(2)}>{(parseFloat(G_ScheDisplay.Setpoint)).toFixed(1)} °C</div>
                    </div>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Fan Speed:</div>
                        <div className='spring_editRightItem  blueText hoverPointer'
                        onClick={()=>fCallInputPage(3)} >{F_RjGetFanSpeed(G_ScheDisplay.Fan)}</div>
                    </div>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Swing  Pos:</div>
                        <div className='spring_editRightItem blueText hoverPointer'
                        onClick={()=>fCallInputPage(4)} >{F_RjGetFanSpeed(G_ScheDisplay.Swing)}</div>
                    </div>
                </div>}
                {G_ScheDisplay.action ===3 && <div className='spring_editItemBar sortHor'>
                    <div className='spring_editLeftTitle'>Scene:</div>
                    <div className='spring_editRightItem hoverPointer'
                    onClick={()=>fCallInputPage(5)}>
                        {(G_ScheDisplay.sceneIdx) <= 0 ?
                            <div className='blueText'>{"< Choose scene>"}</div>:
                            <div className='blueText'>{fGetSceneName(G_SceneList, G_ScheDisplay.sceneIdx)}</div>}

                    </div>
                </div>}

            </div>

        </div>
    );
}

export default TabScheListEdit;