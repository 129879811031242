import React from 'react';
import { FaMinus} from "react-icons/fa";


function TpSelectSensorKey(props) {
    
    const handleClose=()=>{
        if(!props.onClickDiaClose) return;
        props.onClickDiaClose();
    }

    const handleChooseSensor=()=>{
        console.log("handleChooseSensor");
        if(!props.onClickSelSensor) return;
        props.onClickSelSensor();
    }

    return (
        <div>
            <div className='spring_dialogBoxContainer '>
                <div className='spring_dialogBox  spring_shadowBox'>
                    <div className='spring_diaCloseBtn spring_shadowBox spring_lightGreyGrdBgc hoverPointer'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>

                    <div className='spring_diaTitle spring_diaSpaceBelow'>
                        Choose Variable
                    </div>

                    <div className='blueText hoverPointer'
                    onClick={handleChooseSensor}>
                        {`[11] Chiller Plant DPM`}
                    </div>
                    <div className='blueText spring_diaSpaceBelow hoverPointer'>
                        {`Active Power`}
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default TpSelectSensorKey;