import React, { useEffect, useState } from 'react';
import {notEmptyArr, sortTemplate, isEmptyArr} from '../../../../../function/ArrayFn/arrayFn';
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import sensorMngFn from '../../../../../function/sensor/sensorManagementFn';
import moment from "moment";
import DashItemTitleBar from './Common/DashItemTitleBar';
import { useSelector } from 'react-redux';
import { getFullDashDataList } from '../../../../../reduxStore/actions/dash_Data';
import { toast } from 'react-toastify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PinwheelLoader from '../../../Version2a/Template/LoaderTemplate/pinwheelLoader';

function C0005BarDaily(props) {
    const fullList = useSelector(getFullDashDataList);
    
    const [G_loaded, setG_loaded] = useState(false);
    const [G_Display, setG_Display] = useState([]);
    const [G_chartData, setG_ChartData] = useState([]);
    // const [G_barChartSettings, setG_barChartSettings] = useState([]);
    // const [G_cutOffDate, setG_cutOffDate] = useState(null);
    // const [G_minMax, setG_minMax] = useState({min: 0, max: null});
    // const [G_refresh, setG_refresh] = useState(false);

    useEffect(() => {
        async function startUp() {
            // let bdInfo = localStorageFn.getBdToken();
            let { compInfo, auxList } = props;
            // console.log("compinfo", compInfo);
            let { _displayInfo, _loaded } = getData(compInfo.bdDevInvolve_List, fullList, compInfo.StartTime, compInfo.EndTime);
            // console.log("displayInfo", _displayInfo);
            _displayInfo.barTitle = auxList.find(c => c.auxId === 'barTitle').auxValue;
            _displayInfo.axisTitle = `${auxList.find(c => c.auxId === 'axisName').auxValue} (${auxList.find(c => c.auxId === 'unit').auxValue})`;
            let _chartData = genChartData(_displayInfo);
            // let compAuxInfo = await v2SystemFn.V2_GetCompAuxByCompId(compInfo._id);
            // let inBd = await checkIfDevInBd(compAuxInfo.tieParaList.map(c => c.bdDev_id), bdInfo._id);
            // if(!inBd) return;
            // // console.log(compAuxInfo);
            // let barTitle = compAuxInfo.auxList.find(c => c.auxId === 1).auxValue;
            // let axisTitle = `${compAuxInfo.auxList.find(c => c.auxId === 2).auxValue} (${compAuxInfo.auxList.find(c => c.auxId === 3).auxValue})`;
            // let result = await genDailykWhData(compAuxInfo);
            // // let minMax = getMinMax(result);
            // let _chartData = genChartData(result);
            // // console.log(_chartData);
            // setG_barChartSettings({title: barTitle, axis: axisTitle});
            // // setG_minMax(minMax);
            setG_Display(_displayInfo);
            setG_ChartData(_chartData);
            setG_loaded(_loaded);
        }
        startUp();
    }, [props]);

    const getData=(_devInvolve, dataList, _startTime, _endTime)=>{
        let devInvolve = [..._devInvolve];
        /** sort the data list */
        let sortKeys = [
            { key: "dataSortIdx", descOrder: false },
        ];
        devInvolve.sort((a, b) => sortTemplate(a, b, sortKeys));

        /** filter and push the data value */
        let _displayInfo = {labels: [], data: []};
        let _loaded = true;
        for (const eachDev of devInvolve) {
            let devData = dataList.filter(c=>
                c.bdDev_id === eachDev.bdDev_id && 
                c.devType === eachDev.devType && 
                c.qty >= 7 && 
                c.queryType === "dailyAccum" &&
                (c.timeValue === _startTime || c.timeValue === _endTime)
            )
            // console.log("filtered data", devData);
            if(isEmptyArr(devData)) _loaded = false;
            let paraKey = sensorMngFn.getParaKey_int(eachDev.dataType, eachDev.dataIndex);
            // console.log("paraKey", paraKey);
            try {
                if(notEmptyArr(devData)){
                    // if (Object.hasOwnProperty.call(paraKey)) {
                        let startArr = devData.find(c => c.timeValue === _startTime);
                        let endArr = devData.find(c => c.timeValue === _endTime);
                        // console.log(startArr, endArr);
                        for(let i = 0; i < 7; i++) {
                            if(i === 6) _displayInfo.labels.push([`Today`, `(Until ${moment.unix(endArr.data[i].unix).tz("Asia/Kuala_Lumpur").format("HH:mm")})`]);
                            else _displayInfo.labels.push(moment.unix(startArr.data[i].unix).format("DD/M/YY"));
                            _displayInfo.data.push(endArr.data[i][paraKey] - startArr.data[i][paraKey]);
                        }
                        // let paraValue = devData[0][paraKey];

                        // let paraValue = devData[0].data[0][paraKey];
                        // console.log("paraValue", paraValue);
                        // if (typeof paraValue === 'undefined') {
                        //     _displayInfo.data.push(null);
                        //     continue
                        // }
                        // _displayInfo.data.push(paraValue);                        
                        // let compareVal = 0;
                        // if(paraValue >= eachDev.para_1){
                        //     compareVal = 1;
                        // }
                        // _displayInfo.push({Name: eachDev.Name, value: compareVal, status: "Ok"});
                    // }
                }                
            } catch (error) {
                toast(`Generate data err(Dash): ${error.message}`);
                _displayInfo.data.push(null);
                continue;
            }   
        }
        return { _displayInfo, _loaded };
    }



    const genChartData = (queryData) => {
        let mychart = document.getElementById("MyBarChart_daily");                
        if(!mychart) return ({datasets: []});
        let ctx = mychart.getContext("2d");
        // console.log(mychart.width);
        let grLn_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        // let grLn_8 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        // let grLn_9 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        // let grLn_10 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        grLn_1.addColorStop(0, "#13225c");
        grLn_1.addColorStop(1, "#5f71dd");
        grLn_2.addColorStop(0, "#212e60");
        grLn_2.addColorStop(1, "#7b89db");
        grLn_3.addColorStop(0, "#29335b");
        grLn_3.addColorStop(1, "#97a1db");
        grLn_4.addColorStop(0, "#323a5c");
        grLn_4.addColorStop(1, "#a8afd7");
        grLn_5.addColorStop(0, "#3c425e");
        grLn_5.addColorStop(1, "#b8bcd4");
        grLn_6.addColorStop(0, "#484d65");
        grLn_6.addColorStop(1, "#c2c5d2");
        grLn_7.addColorStop(0, "#474a59");
        grLn_7.addColorStop(1, "#d9d9d9");
        // grLn_8.addColorStop(0, "#56c914");
        // grLn_8.addColorStop(1, "#1999d7");
        // grLn_9.addColorStop(0, "#1914c9");
        // grLn_9.addColorStop(1, "#d110d2");
        // grLn_10.addColorStop(0, "#172c53");
        // grLn_10.addColorStop(1, "#10d28d");

        let grArr = [grLn_1, grLn_2, grLn_3, grLn_4, grLn_5, grLn_6, grLn_7];

        return ({
            labels: queryData.labels,
            datasets: [{
                data: queryData.data,
                backgroundColor: grArr
            }]
        });
    }

    // const getMinMax = (currData) => {
    //     let _minMax = JSON.parse(JSON.stringify(G_minMax));
    //     // console.log(lastNData);
    //     for(const data of currData) {
    //         // console.log(data);
    //         // if(_minMax.min === null || data.y < _minMax.min) _minMax.min = data.y;
    //         if(_minMax.max === null || data.y > _minMax.max) _minMax.max = data.y;
    //     }
    //     // console.log(_minMax);
    //     return _minMax;
    // }

    // const genAxes = () => {
    //     let axes = {};

    //     for(let i = 0; i < 7; i++) {
    //         axes[`y_${i}`] = {
    //             id: `y_${i}`,
    //             // type: "category",
    //             display: i === 0? true : false,     // hide the number scale of Y axis
    //             // position: position,
    //             min: G_minMax.min,
    //             max: G_minMax.max,
    //             title: {
    //                 display: true,
    //                 text: G_barChartSettings.axis,
    //             }
    //         };
    //     }
    //     // console.log(axes);
    //     return axes;
    // }


    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: G_Display.barTitle,
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 20,
                }
            },
            datalabels: {
                color: '#696969',
                anchor: 'end',
                align: 'end',
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    borderDash: [5, 5],
                },
                ticks: {
                    font: {
                        size: 11,
                        // weight: 'bold',
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: G_Display.axisTitle,
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: (context) => {
                        // Customize grid line color
                        if (context.tick.value === 0) {
                          return 'rgba(0, 0, 0, 0.1)'; // Color for the 0 grid line
                        } else {
                          return 'rgba(0, 0, 0, 0)'; // Color for other grid lines
                        }
                    },
                    lineWidth: (context) => {
                        // Customize grid line width
                        return context.tick.value === 0 ? 1 : 0; // Width for the 0 grid line and others
                    },
                },
            }
        }
    }

    // const refreshToLatest = () => {
    //     setG_refresh(!G_refresh);
    // }

    // const revertToLatest = () => {
    //     document.getElementById("c5_cutOffDate").value = null;
    //     setG_cutOffDate(null);
    //     setG_refresh(!G_refresh);
    // }

    // const handleCutOffDate = () => {
    //     let cutOffDate = document.getElementById("c5_cutOffDate").value;
    //     // console.log(cutOffDate);
    //     setG_cutOffDate(cutOffDate);
    //     setG_refresh(!G_refresh);
    //     // setG_loaded(false);
    // }

    return ( 
        <div className='spring_dahsItemSize_400_600'>
            <div className='spring_dashItemFrame'>
                <DashItemTitleBar 
                    title={`C5-${props.compInfo.Name}`}
                    // onClickManu={()=>handleClickManu(123)}
                    />
                {/* <img src={req~uire(`./${G_groupInfo.imgName}`)} alt='Device Logo' width={100} height={100} /> */}

                {G_loaded && <div className=''>
                    <Bar
                        id={`MyBarChart_daily`}
                        data={G_loaded? G_chartData : {datasets:[]}}
                        options={options}
                        plugins={[ChartDataLabels]}
                    />
                </div>}
                {!G_loaded && <div style={{ position: "relative" }}>
                    <div className='divAbsolute'>
                        <div className='divRelative' style={{top:"80px", left:"280px"}}>
                            <PinwheelLoader style={{top:"900px"}}/>
                        </div>
                    </div>
                </div>}
            </div>
        </div>

        // <div>
        //     {G_loaded && <div className='spring_Large_Template'>
        //         <div className='sortHor'>
        //             <div>Cut Off Date: </div>
        //             <input type='date' id='c5_cutOffDate' />
        //             <div className='roundButtonSmall' onClick={handleCutOffDate}>Confirm</div>
        //         </div>
        //         <div className='flexOnly stayRight'>
        //             {G_cutOffDate !== null && <TbArrowBackUp className='hoverPointer' onClick={revertToLatest} />}
        //             {G_cutOffDate === null && <TbRefresh className='hoverPointer' onClick={refreshToLatest} />}
        //         </div>
        //         {/* <Bar
        //             id={`MyBarChart_daily`}
        //             data={G_loaded? G_chartData : {datasets:[]}}
        //             options={options}
        //         /> */}
        //     </div>}
        // </div>
    );
}

export default C0005BarDaily;