import React from 'react';
import {FaCheck, FaMinus} from "react-icons/fa";


function TpDiaSuccess(props) {
    
    const handleClose=()=>{
        if(!props.onClickDiaClose) return;
        props.onClickDiaClose();
    }

    return (
        <div>
            <div className='spring_dialogBoxContainer spring_index110'>
                <div className='spring_dialogBox spring_shadowBox'>
                    <div className='spring_diaCloseBtn spring_shadowBox spring_lightGreyGrdBgc hoverPointer'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>
                    <div className='spring_diaIcon_Bigger spring_diaIconFrame spring_shadowBox spring_greenGrdBgc'>
                        <FaCheck/>                        
                    </div>
                    <div className='spring_diaStatusText'>
                        Success
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpDiaSuccess;