import React, { useEffect, useState } from 'react';
import TpClCoolingTower from './Template/TpClCoolingTower';
import appFn from '../../../../../function/V2_Application/V2_ApplicationFn';
import { toast } from 'react-toastify';
import TpCondensorLoopMaster from './Template/TpCondensorLoopMaster';
import TpClSimulator from './Template/TpClSimulator';

function PgCondensorLoop(props) {
    const C_App_id = 1;

    const [G_appMemberList, setG_appMemberList] = useState([]);

    useEffect(() => {
        async function startUp() {
            await loadAppMember();
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);

    const loadAppMember = async()=>{
        let getMemberRel = await appFn.getV2_AppMember(C_App_id);
        if(getMemberRel.errMsg) return toast(`Load member err.`);
        // let {appMemberRel:appMember} = getMemberRel;
        // console.log("getMemberRel", getMemberRel);
        setG_appMemberList(getMemberRel);
    }

    return (
        <div className='flexWrap '>
            <TpCondensorLoopMaster/>
            {G_appMemberList.map((u, ind)=>(
                <TpClCoolingTower key={`appMember_${ind}`} devInfo ={u} bSending={true}/>
            ))} 
            <TpClSimulator/>
        </div>
    );
}

export default PgCondensorLoop;