import React, { useEffect, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { toast } from 'react-toastify';
import ownerSensorFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn'
import { AiOutlineBell, AiOutlineDotChart} from 'react-icons/ai';
import { CgMenuGridO } from "react-icons/cg";
import { BsBookmarkPlus } from "react-icons/bs";
import { BiSync } from "react-icons/bi";
import { MdGridOff, MdGridOn } from "react-icons/md";
import SingleSensorParaSlot from './SingleSensorParaSlot';



function SingleSensor(props) {

    const [G_bdDev_info, setG_bdDev_info] = useState({});

    useEffect(()=>{
        async function startUp(){
            /** check v2_singleSensor_bdDev_id */
            let bdDev_id = validate_bdDev_id();
            /** load sensor info */
            await loadSnesorInfo(bdDev_id);
            
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const validate_bdDev_id =()=>{
        let bdDev_id = localStorage.getItem("v2_singleSensor_bdDev_id");
        console.log(bdDev_id);
        if(!bdDev_id) return props.history.push({pathname: "/data/sensorinbd"});
        /** check whether this sensor is belong to this sensor ???*/

        /** check whether this sensor is shared sensor */
        return bdDev_id;
    }

    const loadSnesorInfo=async (bdDev_id)=>{
        let bdDev_info = await ownerSensorFn.getBdDev_By_bdDevIdList([bdDev_id]);
        if(bdDev_info.errMsg) return toast(bdDev_info.errMsg);  /** read db err */
        if(!Array.isArray(bdDev_info) || bdDev_info.length < 1) return      /** bdDev empty */
        setG_bdDev_info(bdDev_info[0]) 
        console.log(bdDev_info);
    }

    const openManu=()=>{
        
        console.log("openManu");
    }

    const backPrevPg=()=>{
        console.log("backPrevPg");
        props.history.push({
            pathname: "/data/sensorinbd",
            // _data: {_selBuildingId: this.state.selBuildingId},
        });
    }

    const handleSearch=()=>{
        console.log('handleSearch');
    }

    const compareChartPg=()=>{
        console.log('compareChartPg');
    }

    

    
    return (
        <div>
            <div className='headerContainer'>
                <div className="singleSensorBackContainer">
                    <div className="backIconContainer" onClick={backPrevPg}>
                        <IoIosArrowBack className="backIcon"/>
                    </div>
                    {/* <div className='backText'>Add</div> */}                    
                </div>
                <div className ='searchBarContainer' style={{marginRight: "10px"}}>
                        <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                        <div onClick={handleSearch} className='searchButton'>
                            <i className='fa fa-search'></i>
                        </div>    
                </div>                          
                <div onClick={compareChartPg} className='headerIcon'>                    
                    < AiOutlineDotChart/>
                </div>
                <div onClick={openManu} className='headerIcon'>
                    <CgMenuGridO/>
                    {/* <i className='fa fa-ellipsis-v'></i> */}
                </div>
            </div>

            <div className='ssPHeader'>
                <div className='bellIcon'>
                    <BsBookmarkPlus style={{color:"var(--grey6)"}}/>
                </div>
                <div className='ssPHeaderDevName'>
                    {`${G_bdDev_info.name} [${G_bdDev_info.devID}]`}
                </div>
                <div className='bellIcon'>
                    <AiOutlineBell style={{color:"var(--grey6)"}}/>
                </div>
            </div>

            <div className='sortHor spreadBetween'>
                <div>
                    <MdGridOff style={{color:"var(--grey6)"}}/>
                </div>
                <div className='sortHor'>
                    <div className='timeSelect'>24h</div>
                    <div className='timeSelect'>1W</div>
                    <div className='timeSelect'>1M</div>
                </div>
                <div>
                    <MdGridOn style={{color:"var(--grey6)"}}/>
                </div>

            </div>

            <div className='sortHor spreadBetween'>
                <div>L</div>
                <div className='singleSensorGraph'>Graph</div>
                <div>R</div>
            </div>

            <div className='sortHor spreadBetween'>
                <div>
                    <div>10/11/20</div>
                    <div>15:20</div>
                </div>
                {/* <div className='roundButton'> relaod</div> */}
                <div>
                    <div>10/11/20</div>
                    <div>18:20</div>
                </div>
            </div>

            <div className='sortHor spreadBetween'>
                <div className='sortHor'>
                    <div><BiSync/> </div>
                    <div>Last Update: 8 Mar 2021, 18:16</div>
                </div>
                <BiSync/> 
            </div>

            <div className='seperationLine'></div>

            <SingleSensorParaSlot/>
        </div>
    );
}

export default SingleSensor;