import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosAdd} from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import cssFn from '../../../function/CSS/cssFunction';
import { chooseFloor } from '../../../reduxStore/actions/dev_regSensor';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { toast } from 'react-toastify';

function ChooseFloor(props) {
    const dispatch = useDispatch();

    // const [user_id, setUser_id] = useState(1);
    const [loadFloorList, setLoadFloorList] = useState([]);
    const [displayFloorList, setDisplayFloorList] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState({});

    const regDevice = useSelector(state=> state.dev.regSensor);

    useEffect(()=>{
        async function startUp(){
            /** setUser_id */            
            let selectedBuilding_id = 0;
            if (!regDevice.bNewBuilding) selectedBuilding_id = regDevice.buildingId;
            await getRelatedFloor(selectedBuilding_id);
        }
        startUp();

        return ()=>{
            // alert("Choose Device Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const backPrevPg=()=>{
        props.history.push({
            pathname: "/device/add",
            focusStep:3,
        });
    }

    const getRelatedFloor=async (selectedBuilding_id)=>{
        /** get related floor */
        let relatedFloor = await sensorOwnerFn.getRelatedFloor(selectedBuilding_id);
        // let relatedArea = await sensorOwnerFn.getRelatedArea(user_id, selectedBuilding_id);
        if(relatedFloor.errMsg) return toast.dark(relatedFloor.errMsg);
        setLoadFloorList(relatedFloor);
        setDisplayFloorList(relatedFloor);

    }

    const handleSearch=()=>{
        let searchInput = document.getElementById('searchInput').value.trim();
        if(searchInput==="") {
            setDisplayFloorList(loadFloorList);
            return
        }
        let filtered = loadFloorList.filter((c)=>c.name.toUpperCase().search(searchInput.toUpperCase()) >= 0);
        setDisplayFloorList(filtered);
    }


    const addNewArea=()=>{
        // console.log('addNewBd');
        
        props.history.push({
            pathname: "/device/add/addnewfloor",
            // _data: {_devName: devName},
        });
    }

    const selArea=(ind, area)=>{
        /** high light the select area when click area in table list */
        for (let i = 0; i < displayFloorList.length; i++) {
            cssFn.cssShowStatus(i===ind, `areaId_${i}`, 'tableItemSelected');            
        }        
        setSelectedFloor(area);

    }

    const handleConfirm=()=>{
        if(!selectedFloor._id) return toast.error('No monitoring list selected')
        let selFloor = {floorId:selectedFloor._id, bFloorName:selectedFloor.name};
        dispatch(chooseFloor(selFloor));
        return props.history.push({
            pathname: "/device/add",
            focusStep:3,
        });
    }
    
    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Select Group</div>
            </div>

            <div className='spaceBothSide testClass' style={{marginTop:"20px"}}>
                <div className="spring_fullButton spring_blueColor hoverPointer" onClick={addNewArea}>
                    <div className='buttonWithIcon '>
                        <IoIosAdd className="backIcon" style={{fontSize:'22px'}}/> New
                    </div>
                </div>

                <div className ='searchBarContainer_non' style={{marginTop:"15px"}}>
                    <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                    <div onClick={handleSearch} className='searchButton'>
                        <i className='fa fa-search'></i>
                    </div>    
                </div>
                <div className='gapOntopTable tableContainerFullWidth' 
                    style={{height: "60vh",
                    color:'var(--titleColor)'
                    }}>
                    {displayFloorList.map((c, ind)=>
                        <div 
                            className='tableItem' 
                            key={`areaKey_${ind}`}
                            id={`areaId_${ind}`}
                            onClick={()=>selArea(ind, c)}
                        > {c.name}</div>
                    )} 
                </div>
                
                <div className="buttonContainer buttonSpreadEvenly" 
                    style={{
                        marginTop:"25px",
                        marginBottom:"20px",
                        }}>
                    <div className="spring_roundButton spring_greyColor hoverPointer" onClick={backPrevPg}>
                        Back
                    </div>
                    <div className="roundButton" onClick={handleConfirm}>Confirm</div>
                </div>
            </div>
        </div>
    );
}

export default ChooseFloor;