import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { FiPlus} from "react-icons/fi";
import { BsCheckLg} from "react-icons/bs";
// import {ImCheckboxChecked, ImCheckboxUnchecked} from "react-icons/im";
import {BsDot} from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import { setBdInfo, storeEmailList, switchAccessLvl, setAccessLevel } from '../../../reduxStore/actions/dev_shareCfg';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
// import sensorSharedUserFn from '../../../function/V2_SensorDevice/v2_SensoSharedUserFn';
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
// import v2authFn from '../../../service/auth/AuthV2';
import cssFn from '../../../function/CSS/cssFunction';
import { toast } from 'react-toastify';
// import shareDevFn from '../../../function/V2_SensorDevice/v2_SensoSharedUserFn';
import shareEditToken from '../../../function/localStorage/lsEditShareFn';
import { isEmptyArr, notEmptyArr, sortTemplate } from '../../../function/ArrayFn/arrayFn';

function ShareDevicePg(props) {
    const dispatch = useDispatch();
    const rdx_shareCfgDetails = useSelector(state=> state.dev.shareSensor);
    const [G_receiverEmail, setG_receiverEmail] = useState([]);
    // const [G_devInArea, setG_devInArea] = useState([]);
    const [G_editMode, setG_editMode] = useState(false);
    const [G_sharing, setG_sharing] = useState(false);
    const [G_floorInfo, setG_floorInfo] = useState([{floorName:"-", areaList:[]}]);
    const [G_TotalDevice, setG_TotalDevice] = useState(0);
    const [G_HideArea, setG_HideArea] = useState([{floor_id:-1, area_id:-1}]);
    const [G_HideFloor, setG_HideFloor] = useState([]);
    
    const [G_DevsSel, setG_DevsSel] = useState([]);
    /** direct to pos */
    const emailRef = useRef(null);
    const refManuLevel = useRef(0);

    useEffect(()=>{
        async function startUp(){  
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;

            /** load building selected */
            let bdInfo = localStorageFn.getBdToken();
            // dispatch(setChooseBd_id({ selected_bd_id: bdInfo._id, selected_bd_name: bdInfo.name }));
            dispatch(setBdInfo({ bd_id: bdInfo._id, bdName: bdInfo.name }));

            await loadBdDev(bdInfo._id);

            let editCfg = shareEditToken.getToken();
            // console.log("editCfg", editCfg);
            if(!editCfg){       // share new device
                laodEmailList();
            }else{      // edit share device
                setG_editMode(true);
                loadEditEmail(editCfg);
                dispatch(setAccessLevel({accessLevel:parseInt(editCfg.shareLevel)/2}));
                if(editCfg.shareLevel>2){   /** lower than co owner, need to load device */
                    let _DevsSel=[];
                    let sharedDev = await sensorOwnerFn.v2aGetShareBdDev_ByUserId_BdId(bdInfo._id, editCfg.user_id)
                    for (const eachDev of sharedDev) {
                        _DevsSel.push(eachDev.bdDev_id);                   }
                    setG_DevsSel(_DevsSel);
                }
                

            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const loadBdDev =async (bd_id)=>{
        /** get bdDev in building */        
        let devInBd = await sensorOwnerFn.v2aGetBdDevInBd(bd_id);
        if(devInBd.erMsg) toast(devInBd.erMsg);
        setG_TotalDevice(devInBd.length);   /** set total device quantity */
        /** get floor in bd */
        let floorInBd = await sensorOwnerFn.v2aGetAllFloorInBd(bd_id);
        if(floorInBd.erMsg) toast(floorInBd.erMsg);
        /** get area in bd */
        let areaInBd = await sensorOwnerFn.v2aGetAllAreaInBd(bd_id);
        if(areaInBd.erMsg) toast(areaInBd.erMsg);

        groupDevIntoLevel(devInBd, floorInBd, areaInBd);

    }

    
    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "sortIdx", descOrder: false },
            { key: "name", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const groupDevIntoLevel=(_devs, _floors, _areas)=>{
        let remainingDev = sortArr(_devs);
        let in_floors = sortArr(_floors);
        let in_areas = sortArr(_areas);

        let floorInfo = [];
        for (const eachFloor of in_floors) {
            /** get area in floor */
            let areaInFloor=in_areas.filter(c=>c.floor_id === eachFloor._id);
            /** get device in floor */
            let remainingDev_area = remainingDev.filter(c=> c.floor_id === eachFloor._id);
            let areaInfo = [];
            for (const eachArea_infloor of areaInFloor) {
                let selDev = remainingDev_area.filter(c=> c.area_id===eachArea_infloor._id);
                selDev = sortArr(selDev);
                /** insert area dev */
                areaInfo.push({area_id:eachArea_infloor._id, areaName:eachArea_infloor.name, devInArea:[...selDev]});
                remainingDev_area = remainingDev_area.filter(c=> c.area_id!==eachArea_infloor._id);
            }
            remainingDev = remainingDev.filter(c=> c.floor_id !== eachFloor._id);
            if(notEmptyArr(remainingDev_area)) areaInfo.push({area_id:0, areaName:"Others", devInArea:[...remainingDev_area]});

            /** push to floor info */
            floorInfo.push({floor_id:eachFloor._id, floorName:eachFloor.name, areaList:areaInfo});
        }
        if(notEmptyArr(remainingDev)) floorInfo.push({floor_id:0, floorName:"Others", areaList:[{area_id:0, areaName:"Others", devInArea:remainingDev}]});
        setG_floorInfo(floorInfo);
        

    }


    const  laodEmailList = ()=>{        
        setG_receiverEmail(rdx_shareCfgDetails.receiverEmail);
    }

    const loadEditEmail = (shareInfo)=>{        
        setG_receiverEmail([shareInfo.username]);
        dispatch(storeEmailList({receiverEmail:[shareInfo.username]}));
    }

    // const loadEditBdName=(shareInfo)=>{        
    //     dispatch(setBdInfo({bdName:shareInfo.bd_name, bd_id:shareInfo.bd_id}));
    //     // dispatch(setBdInfo({bdName:c.name, bd_id:c._id}))
    // }

    const backPrevPg=()=>{
        let pathname ="/data/bdoverview"
        let editCfg = shareEditToken.getToken();
        if(editCfg){
            pathname="/device/share/edit"
        }else if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        props.history.push({pathname});
    }
    // const chooseBuilding=()=>{
    //     return toast("Not allow to change building")
    //     if(G_editMode) return       // not allow user to change building in edit mode
    //     props.history.push({pathname: "/device/share/selbd"});
    // }
    const handleShare=async ()=>{
        setG_sharing(true);
        let shareUserValid = validateShareUser();
        if(!shareUserValid) return  setG_sharing(false); // validation error

        /** device list */
        if(G_DevsSel.length <=0 && rdx_shareCfgDetails.accessLevel!== 1) return toast("No device selected");
        
        /** get user list info */

        /** get access level */
        let shareLevel = rdx_shareCfgDetails.accessLevel * 2;
        let shareRel = await sensorOwnerFn.v2aShareDevice(rdx_shareCfgDetails.bd_id, 
            rdx_shareCfgDetails.receiverEmail, 
            shareLevel, 
            G_DevsSel)
        if(shareRel.errMsg) return toast(`Err: ${shareRel.errMsg}`);
        if(shareRel.Success) {
            toast("Success");
            backPrevPg()
        } 
        return
    }


    const validateShareUser =()=>{
        try {
            /** check Builing Selected */
            // let bBdNoSelected=false;
            // if(!(rdx_shareCfgDetails.bd_id > 0)) {
            //     /** set err color */
            //     cssFn.cssShowStatus(false, "selBdBar", "cfgCardColor");            
            //     bBdNoSelected=true;
            // }
            /** check receiver */
            let bReceiverEmpty=false;
            if(!Array.isArray(rdx_shareCfgDetails.receiverEmail) || rdx_shareCfgDetails.receiverEmail.length < 1) {            
                emailRef.current.scrollIntoView({behavior: "smooth"});
                cssFn.cssShowStatus(false, "receiverBar", "cfgCardColor"); 
                bReceiverEmpty=true;        
            }
            /** Check Selected Device */
            // let bNoDevSelected;
            // let bNoDevSelected=true;
            // let devInArea = [...G_devInArea];
            // for (let i = 0; i < G_devInArea.length; i++) {
            //     if(!Array.isArray(G_devInArea[i].devices)) continue
            //     for (let j = 0; j < G_devInArea[i].devices.length; j++) {
            //         // let displayId = targetId.replace("devCheckBox","devCheckDisplay");
            //         let bHidden = document.getElementById(`devCheckDisplay_${i}_${j}`).classList.contains('divHidden');
            //         // let bHidden = document.getElementById(`devCheckDisplay_${i}_${j}`).classList;
            //         devInArea[i].devices[j].selected=!bHidden;        
            //         if(!bHidden && bNoDevSelected) bNoDevSelected=false;
            //     }
            // }
            // if(bNoDevSelected){
            //     /** Set err color */
            //     cssFn.cssShowStatus(false, "devSelBar", "cfgCardColor"); 
            // }

            // let valErr = bBdNoSelected || bReceiverEmpty || bNoDevSelected;
            let valErr = bReceiverEmpty;
            /** validation error, return null */
            if(valErr) return null;     

            return true
            // return devInArea;
        } catch (error) {
            toast('Validate Inpout Err');
            return null
        }
        
    }

    const setAcessLevel=()=>{
        dispatch(switchAccessLvl());        
    }

    const addEmail=()=>{
        // dispatch(switchAccessLvl());
        if(G_editMode) return       // not allow user to change building in edit mode
        props.history.push({pathname: "/device/share/addemail"});
    }    
    

    const handleSelDev=(devInfo)=>{
        let _DevsSel = [...G_DevsSel];
        let idx = _DevsSel.findIndex(c=> c===devInfo._id);
        if(idx >= 0){   // check --> uncheck
            _DevsSel.splice(idx, 1);
        }else{      //  uncheck --> check
            _DevsSel.push(devInfo._id);
        }
        setG_DevsSel(_DevsSel);
    }

    const handleSelAll=()=>{
        try {
            if(G_DevsSel.length >= G_TotalDevice){   // some selected, clear all
                setG_DevsSel([]);
            }else{      // non selected, choose all
                let _DevsSel= [];
                for (const  eachFloor of G_floorInfo) {
                    if(isEmptyArr(eachFloor.areaList)) continue;
                    for (const eachArea of eachFloor.areaList) {
                        if(isEmptyArr(eachArea.devInArea)) continue;
                        for (const eachDev of eachArea.devInArea) {
                            _DevsSel.push(eachDev._id);
                        }
                    }
                }
                setG_DevsSel(_DevsSel);
            }
        } catch (error) {
            toast("Select all err: ", error.message)
        }
    }
    
    const handleHideArea=(floorInfo, areaInfo)=>{
        let _HideArea = [...G_HideArea];
        let foundIdx = _HideArea.findIndex(Gc=>Gc.floor_id === floorInfo.floor_id && Gc.area_id === areaInfo.area_id);
        if(foundIdx>=0){    /** need to unhide, splice the array */
            _HideArea.splice(foundIdx,1);
        }else{      /** need to hide, push the array */
            _HideArea.push({floor_id:floorInfo.floor_id, area_id:areaInfo.area_id});
        }
        setG_HideArea(_HideArea);
    }
    
    const handleHideFloor=(floorInfo)=>{
        let _HideFloor = [...G_HideFloor];
        let foundIdx = _HideFloor.findIndex(c=> c===floorInfo.floor_id);
        if(foundIdx>=0){
            _HideFloor.splice(foundIdx,1);
        }else{
            _HideFloor.push(floorInfo.floor_id);
        }
        setG_HideFloor(_HideFloor);
    }
    


    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                {!G_editMode && <div className='backText'>Share</div>}
                {G_editMode && <div className='backText'>Edit Shared Device</div>}
            </div>


            {/* <div className={`cfgCard cfgChooseBd errBgc cfgCardColor`}
            id={`selBdBar`}
            // onClick={chooseBuilding}
            >
                <div className='sortHor  '>
                    {rdx_shareCfgDetails.bdName && <div className='cfgBdName'>{rdx_shareCfgDetails.bdName}</div>}
                    {!rdx_shareCfgDetails.bdName && <div style={{color:"var(--greyoutText"}} className='cfgBdName'>{`< Choose Building >`}</div>}
                    
                    {!G_editMode && <div className='stayRight stayRightElem'><VscTriangleDown/></div> }
                </div>
            </div> */}
            

            <div ref={emailRef}  className='sortHor cfgSubTitle'>
                <div>Share To</div>
                {!G_editMode && <div className='stayRight stayRightElem cfgAddEmailButton'
                onClick={addEmail}>
                    <FiPlus/>
                </div>}
            </div>
            <div className='cfgCard cfgCardColor errBgc'
            id={`receiverBar`}>
                {G_receiverEmail.length > 0 && G_receiverEmail.map((c,ind)=>
                    <div className='cfgEmailWidth wrapTextEllipsis'
                    key={`rcvEmailKey_${ind}`}>
                        <BsDot/>{` ${c}`}
                    </div>
                )}
                {G_receiverEmail.length <1 && 
                    <div className='cfgEmailWidth wrapTextEllipsis hoverPointer'
                    style={{color:"var(--greyoutText"}}
                    onClick={addEmail}>
                        {`< Click to add receiver >`}
                    </div>
                }
                
            </div>
            
            <div className='cfgCard cfgCardColor'>
                <div className='sortHor'>
                    <div>Access Level</div>
                    <div className='stayRight stayRightElem cfgAccessLevelFrame'
                    style={{width:"86px"}}
                    onClick={setAcessLevel}>
                        {rdx_shareCfgDetails.accessLvlText}
                    </div>
                </div>
                {rdx_shareCfgDetails.accessLevel===1 && <div className='cfgSmallDesc'>Full access to all devices</div>}
                {rdx_shareCfgDetails.accessLevel===2 && <div className='cfgSmallDesc'>Can Edit and View devices</div>}
                {rdx_shareCfgDetails.accessLevel===3 && <div className='cfgSmallDesc'>Can View devices</div>}
            </div>




            <div className={`${rdx_shareCfgDetails.accessLevel===1?"divCollapse":""}`}>
                <div className='sortHor sortMiddle'>
                    <div className='cfgSubTitle'>Device to share:</div>
                    <div className='stayRight hoverPointer spring_marginRight15'
                    onClick={handleSelAll}
                    > 
                        <div className='spring_checkBox'>
                            <BsCheckLg className={`${G_DevsSel.length >= G_TotalDevice?"":"divHidden"}`}/>   
                            
                        </div>
                    </div>

                </div>

                {G_floorInfo.map((c, ind_j)=>(
                <div key ={`floor_${ind_j}`}>
                    <div className='cfgSubTitle spring_FloorTitle sortHor'>
                        <div className='boldText'>{c.floorName}</div> 
                        {/* <VscTriangleDown className='stayRight'/> */}
                        <div className='stayRight hoverPointer sortMiddle' onClick={()=>handleHideFloor(c)}>
                            {G_HideFloor.findIndex(Gc=>Gc===c.floor_id) >=0?<VscTriangleDown />:<VscTriangleUp /> }
                        </div>
                    </div>
                    <div className={`spring_cfgCard cfgCardColor ${G_HideFloor.findIndex(Gc=>Gc===c.floor_id) >=0?"divCollapse":"" }`}>
                        {c.areaList.map((u, ind_k)=>(
                            <div key={`area_${ind_j}_${ind_k}`} className={`${u.devInArea.length > 0 ? "":"divCollapse"}`}>
                                {c.floorName!=="Others" && <div className='sortHor spring_marginRight5'>
                                    <div className='boldText'>{`${u.areaName}:`}</div>

                                    <div className='stayRight hoverPointer sortMiddle' onClick={()=>handleHideArea(c, u)}>
                                        {G_HideArea.findIndex(Gc=>Gc.floor_id===c.floor_id && Gc.area_id===u.area_id) >=0?<VscTriangleDown />:<VscTriangleUp /> }
                                    </div>
                                </div>}
                                {u.devInArea.map((e, ind_l)=>(
                                    <div key={`dev_${ind_j}_${ind_k}_${ind_l}`} className={`${G_HideArea.findIndex(Gc=>Gc.floor_id===c.floor_id && Gc.area_id===u.area_id) >=0?"divCollapse":"" }`}>
                                        <div className='spring_MarginTop_5'></div>
                                        <div className='sortHor spring_itemFrame'>
                                            <div>{`${e.name}`}</div>
                                            <div className='stayRight hoverPointer'
                                            onClick={()=>handleSelDev(e)}> 
                                                <div className='spring_checkBox'>
                                                    <BsCheckLg className={`${G_DevsSel.findIndex(Gc=>Gc===e._id)>=0?"":"divHidden"}`}/>   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className='spring_MarginTop_10'></div>
                            </div>
                        ))}
                        
                    </div>

                </div>
                ))}
            </div>


            <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" onClick={handleShare}>{G_sharing?"Updating...":"Share"}</div>
            </div>
        </div>
    );
}

export default ShareDevicePg;