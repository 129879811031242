import React, { useEffect, useState } from "react";
import v2ctrlFn from "../../../../../../function/V2_Control/V2_ControlFn";
import { toast } from "react-toastify";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { BsFillTriangleFill } from "react-icons/bs";

const TabMorePage = (props) => {
  const [G_SoftwareVer, setG_SoftwareVer] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);
  const [G_InputPg, setG_InputPg] = useState(0);

  useEffect(() => {
    async function startUp() {
      if (props.lastData) {
        if (props.lastData.pi_0) {
          // got valid last data
          // let nBrand = props.lastData.pi_0;
          // let found = _AcList.find(c=>c.nBrand === nBrand);
          // let sBrandName="";
          // if(found) sBrandName=found.name;

          // let _SettingPara={
          //     nBrand,
          //     sBrandName,
          //     sensorLink : props.lastData.pb_4,     // 0 = local
          //     upperBand : props.lastData.pf_8,
          //     lowerBand : props.lastData.pf_9,
          //     coolingStrength:props.lastData.pi_3,
          //     heatingStrength:props.lastData.pi_4,
          //     rapidPulse:props.lastData.pn_0,
          // }
          setG_SoftwareVer(props.lastData.pf_6);
          // setG_SettingDisplay(_SettingPara);
          // setG_SettingDB(_SettingPara);
        } else {
          // no data send to server before
          // setG_SettingDisplay(C_DefaultPara);
        }
      }

      await GW_loadGwPair(props.dev._id);
    }
    startUp();

    return () => {
      // alert("Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const GW_loadGwPair = async (bdDev_id) => {
    try {
      let pairedGw = await v2ctrlFn.getV2_GwPair(bdDev_id);
      if (pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
      let { gwPairList } = pairedGw;
      if (!notEmptyArr(gwPairList))
        return toast("No gateway pair to this device");
      setG_GwID(gwPairList[0].gwid);
    } catch (error) {
      toast("loadGwPair err: ", error.message);
    }
  };

  const fCloseInputPage = () => {
    setG_InputPg(0);
  };

  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    /** set GwId on global page */
    if (props.fSetGw_id) props.fSetGw_id(gwid);
  };

  const fCallInputPage = (nPg) => {
    /** call choose action page */
    setG_InputPg(nPg);
  };

  const fToBasicTab = () => {
    if (props.fToTab) props.fToTab(33);
  };
  const fToFbSensorTab = () => {
    if (props.fToTab) props.fToTab(32);
  };
  const fToIrHzTab = () => {
    if (props.fToTab) props.fToTab(34);
  };
  const fToFilterTab = () => {
    if (props.fToTab) props.fToTab(35);
  };  
  const fToMaintenanceListTab = () => {
    if (props.fToTab) props.fToTab(37);
  };


  return (
    <div className="spring_editScenePg">
      {G_InputPg === 11 && (
        <TpDiaGatewayID
          divInfo={props.dev}
          gwPair={G_GwID}
          onClickDiaClose={fCloseInputPage}
          setGwId={handleSetGwDisplay}
        />
      )}

      <div className="spring_RjSettingGroupTitle">Gateway</div>
      <div className="spring_editScheGroup">
        <div className="spring_editItemBar sortHor hoverPointer" 
        onClick={() => fCallInputPage(11)}>
          <div className="spring_editLeftTitle">Gateway ID:</div>
          <div
            className="spring_editRightItem  blueText "
            // onClick={}
          >
            {G_GwID > 0 ? (
              <div>{G_GwID}</div>
            ) : (
              <div>{"< Insert Gateway ID >"}</div>
            )}
          </div>
        </div>
      </div>

      <div className="spring_RjSettingGroupTitle">Setting</div>

      
      <div className="spring_editScheGroup_SmallSpaceContainer">
        <div className="spring_editScheGroup_SmallSpace">
            <div
            className="spring_editItemBar sortHor hoverPointer"
            onClick={fToBasicTab}
            >
            <div className="spring_editLeftTitle">Air-Con & Control</div>            
            <div className="spring_editRightItem  flexAndBothCenter">
                <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
            </div>
            </div>
        </div>
        {G_SoftwareVer>=2.02 && <div className="spring_editScheGroup_SmallSpace">
            <div className="spring_editItemBar sortHor hoverPointer"
            onClick={fToFbSensorTab}>
            <div className="spring_editLeftTitle">Feedback Sensor</div>
            <div className="spring_editRightItem  flexAndBothCenter">
                <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
            </div>
            </div>
        </div>}
        {G_SoftwareVer>=2.02 && <div className="spring_editScheGroup_SmallSpace">
            <div className="spring_editItemBar sortHor hoverPointer"
            onClick={fToIrHzTab}>
            <div className="spring_editLeftTitle">Infrared Hz</div>
            <div className="spring_editRightItem  flexAndBothCenter">
                <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
            </div>
            </div>
        </div>}
      </div>


      {G_SoftwareVer>=2.02 &&  <div>
        <div className="spring_RjSettingGroupTitle">Service & Maintenance</div>
        
        
        <div className="spring_editScheGroup_SmallSpaceContainer">
          <div className="spring_editScheGroup_SmallSpace">
              <div
              className="spring_editItemBar sortHor hoverPointer"
              onClick={fToFilterTab}
              >
              <div className="spring_editLeftTitle">Filter Cleaning</div>            
              <div className="spring_editRightItem  flexAndBothCenter">
                  <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
              </div>
              </div>
          </div>
          <div className="spring_editScheGroup_SmallSpace">
              <div className="spring_editItemBar sortHor hoverPointer"
              onClick={fToMaintenanceListTab}>
              <div className="spring_editLeftTitle">Maintenance Log</div>
              <div className="spring_editRightItem  flexAndBothCenter">
                  <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
              </div>
              </div>
          </div>
        </div>
        
      </div>}


      <div className="spring_RjSettingGroupTitle">About</div>
      <div className="spring_editScheGroup">
        <div className="spring_editItemBar sortHor">
          <div className="spring_editLeftTitle">Software Version:</div>
          <div className="spring_editRightItem">
            <div>{`${G_SoftwareVer === 0 ? "--" : G_SoftwareVer}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabMorePage;
