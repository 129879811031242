import v2CtSer from '../../service/V2_AppDevice/V2_CoolingTowerCfgSer';

async function getV2_CtCfg(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2CtSer.getV2_CtCfg(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function setV2_CtCfg(ctCfg){    
    // console.log("Cone In");
    try {
        let rel = await v2CtSer.setV2_CtCfg(ctCfg);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

const expModule = {  
    getV2_CtCfg,
    setV2_CtCfg,
};
export default expModule;