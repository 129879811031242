import v2AppSer from '../../service/V2_Application/V2_ApplicationSer';

async function getV2_AppMember(app_id){    
    // console.log("Cone In");
    try {
        let rel = await v2AppSer.getV2_AppMember(app_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}


const expModule = {  
    getV2_AppMember,
};
export default expModule;