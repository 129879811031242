
import reducers from './reducer/reducer';
import {configureStore} from '@reduxjs/toolkit';
import api from './middleware/api';


export default function cfgStore(){
    return configureStore({
        reducer : reducers,
        middleware:(getDefaultMiddleware) => getDefaultMiddleware()
            // .concat(logger({ destination: "console" }))
            // .concat(toast)
            .concat(api)
            ,
    })
}