import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import LegoLoader from '../Version2a/Template/LoaderTemplate/legoLoader';
import TpNewRequest from './DataDownloadTab/TpNewRequest';
import TpRequestList from './DataDownloadTab/TpRequestList';
import TpHistoryRequestList from './DataDownloadTab/TpHistoryRequestList';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
// import V2DataDownloadFn from '../../../function/V2_DataDownload/V2_DataDownloadFn';
// import auth from '../../../service/auth/AuthV2';
// import { toast } from 'react-toastify';

function DataDownload_main(props) {
    const [G_bLoaded, setG_bLoaded] = useState(false); 
    const [G_ShowTab, setG_ShowTab] = useState(0);
    const [G_bdInfo, setG_bdInfo] = useState();
    const [G_showLeftArrow, setG_showLeftArrow] = useState(false);
    const [G_showRightArrow, setG_showRightArrow] = useState(false);

    const refManuLevel = useRef(0);
    const navRef = useRef(null);

    useEffect(() => {
        async function startUp() {
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;

            let bdInfo  = localStorageFn.getBdToken();
            // let userInfo = auth.getUserInfo();
            // console.log(bdInfo);
            setG_bdInfo(bdInfo);

            setG_bLoaded(true);

        }
        
        startUp();
        
        if (navRef.current) {
            navRef.current.addEventListener('scroll', handleScroll);
            handleScroll(); // Check initial scroll position
        }

        return ()=>{
            if (navRef.current) {
                navRef.current.removeEventListener('scroll', handleScroll);
            }
        }
    }, []);

    const handleScroll = () => {
        if (navRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
          setG_showLeftArrow(scrollLeft > 0);
          setG_showRightArrow(scrollWidth > clientWidth + scrollLeft);
        }
    };

    const backPrevPg=()=>{
        let pathname ="/data/bdoverview"
        if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const handleChangeTab=(pgNo)=>{
        setG_ShowTab(pgNo);
    }

    const scrollLeft = () => {
        if (navRef.current) {
          navRef.current.scrollLeft -= 80; // Adjust the scrolling amount as needed
        }
      };
    
    const scrollRight = () => {
        if (navRef.current) {
          navRef.current.scrollLeft += 80; // Adjust the scrolling amount as needed
        }
    };

    // const clearCache = async () => {
    //     let userInfo = auth.getUserInfo();
    //     let result = await V2DataDownloadFn.clearDownloadHistory(userInfo.user_id, G_bdInfo._id);
    //     if(result === "OK") {
    //         toast("History cleared");
    //         setG_ShowTab(0);
    //     }
    //     else {
    //         toast("Error clearing history");
    //     }
    // }

    return ( 
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Data Download</div>
                {/* <div className='stayRight flexAndBothCenter headerIcon hoverPointer' onClick={toTicketPage}>
                    <CgMenuRound />
                </div> */}
            </div>
            
            <div className='flexAndBothCenter spring_MarginTop_10'>
                {G_showLeftArrow? <FaAngleLeft className='spring_ddPgArrow hoverPointer ' onClick={scrollLeft} /> : <div style={{ width: '10vw' }}></div>}
                <div className='spring_tabContainer ' ref={navRef}>
                    <div className={`spring_ddPgSelItem hoverPointer ${G_ShowTab===0?"spring_shadowBox_Small":""}`}
                    onClick={()=>handleChangeTab(0)}>New Request</div>
                    <div className={`spring_ddPgSelItem hoverPointer ${G_ShowTab===1?"spring_shadowBox_Small":""}`}
                    onClick={()=>handleChangeTab(1)}>Request List</div>
                    <div className={`spring_ddPgSelItem hoverPointer ${G_ShowTab===2?"spring_shadowBox_Small":""}`}
                    onClick={()=>handleChangeTab(2)}>History</div>
                </div>
                {G_showRightArrow? <FaAngleRight className='spring_ddPgArrow hoverPointer ' onClick={scrollRight} /> : <div style={{ width: '10vw' }}></div>}
            </div>

            {!G_bLoaded && <LegoLoader />}
            {G_bLoaded && <div className=''>


                <div className='spring_ddTitleContainer'>
                    <div className="spring_ManuTitle">{G_bdInfo.name}</div>
                </div>

                {G_ShowTab === 0 && <TpNewRequest bdInfo={G_bdInfo} handleChangeTab={handleChangeTab} />}
                {G_ShowTab === 1 && <TpRequestList bdInfo={G_bdInfo} handleChangeTab={handleChangeTab} />}
                {G_ShowTab === 2 && <TpHistoryRequestList bdInfo={G_bdInfo} handleChangeTab={handleChangeTab} />}

                {/* <div className='spring_inputBox'>
                    <div className='spring_RjSettingGroupTitle'>Duration</div>
                    <div className='spring_editScheGroup'>
                        <div className='spring_editItemBar sortHor'> 
                            <div className="spring_editLeftTitle">Start Month:</div>
                            <div className='spring_editRightItem  blueText hoverPointer '>
                                <input id='In_Download_Start' type='month' className='spring_ddYearMonthInput'/>
                            </div>
                        </div>
                        <div className='spring_editItemBar sortHor'> 
                            <div className='spring_editLeftTitle'>End Month:</div>
                            <div className='spring_editRightItem  blueText hoverPointer '>
                                <input id='In_Download_End' type='month' className='spring_ddYearMonthInput'/>        
                            </div>    
                        </div>
                    </div>
                </div>

                <div className='spring_inputBox'>
                    <div className="sortHor">
                        <div className='spring_RjSettingGroupTitle'>Description</div>
                        <div className="spring_RjSettingGroupTitle spring_RjSettingGroupTitle_stayRight">{`${G_MaxChar - G_CharCnt}/${G_MaxChar}`}</div>
                    </div>
                    <div className="spring_editScheGroup_SmallSpaceContainer">
                        <div className="spring_editScheGroup_SmallSpace">
                            <div
                            className="spring_editItemBar sortHor hoverPointer"
                            // onClick={fToFbSensorTab}
                            >
                            <textarea
                                id={`r_description`}
                                rows="2"
                                className="spring_filterTextArea"
                                placeholder="<Insert description>"
                                onChange={fCap40Char}
                            />
                            </div>
                        </div>
                    </div>
                </div>

                {G_ShowTp===1 && <TpDiaChooseDevice
                    onclickClose = {handleCloseDiaChoose}
                    onclickConfirm = {handleConfirmDevList}
                    bdDevList = {G_bdDevList_toDia}
                    floorList = {G_floorList_toDia}
                    areaList = {G_areaList_toDia}
                />}

                <div className='spring_inputBox'>
                    <div className="sortHor">
                        <div className='spring_RjSettingGroupTitle'>{`Device(s)`}</div>
                        <div className="spring_RjSettingGroupTitle_stayRight hoverPointer" style={{ fontSize: '1rem'}} onClick={callAddDev}>
                            <FaPlusSquare className='greenText' />
                        </div>
                    </div>

                    <div className='spring_editScheGroup'>
                        {G_bdDevList_display.map((u, ind)=>(<div key={`dev_${ind}`} >
                            {ind!==0 && <div className='divSeperateLine'></div>}
                            <div className='sortHor spring_TableItemSpace'>
                                <div className='spring_listContainerItem'>{`[${u.devID}] ${u.name}`}</div>
                                <div className='stayRight flexAndBothCenter  hoverPointer square_25'
                                onClick={()=>handleRemove(u)}>
                                    <FiTrash2 className='redText'/>
                                </div>
                            </div>
                        </div>))}   
                            
                        <div className={`whiteText greenBgc spring_containerAddDev ${G_bdDevList_display.length > 0 ?"spring_addDevTopBorder" : "spring_addDevAllBorder"} hoverPointer`}
                        onClick={callAddDev}> + Add device(s)</div>

                    </div>
                </div>

                <div className='sortHor spreadEvenly spring_2BtnBtmMargin'>
                    <div className="roundButton" onClick={handleCheckout}>Checkout</div>
                </div> */}
            </div>}

        </div>
    );
}

export default DataDownload_main;