import React, { useEffect, useState } from 'react';
import { sortTemplate } from '../../../../../../../function/ArrayFn/arrayFn';

function TpDiaChooseVar(props) {

    const [G_VarList, setG_VarList] = useState([]); 

    
    useEffect(()=>{
        async function startUp(){
            if (props.varList) {
                let varList = props.varList.filter(c=>c.varIdx > 1 && c.varIdx < 7);
                let sortKeys = [{ key: "varIdx", descOrder: false }];
                varList.sort((a, b) => sortTemplate(a, b, sortKeys));
                setG_VarList(varList);
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(varInfo)=>{
        if(props.confirmChange) {
            props.confirmChange(varInfo);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_ruleVarListContainer' style={{paddingTop:"30px"}}>
                        {G_VarList.map((c, ind)=>
                            <div key={`varKey_${ind}`} className="spring_ruleVarListBox hoverPointer"
                            onClick={()=>handleConfirm(c)}
                            >{c.varName}</div>
                        )} 
                    </div>

                                  

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaChooseVar;