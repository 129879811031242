import React, {forwardRef,useEffect,useImperativeHandle,useState,} from "react";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { MdOutlineFileUpload } from "react-icons/md";
import {clearBitByIdx,getBitByIdx,setBitByIdx,} from "../../../../../../function/common/common";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";

// const TabEditScene=forwardRef((props, ref)=> {
const MRChTabSystemSetting = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadSysSetting(_pb, _pf, _pi) {
      F_FillUpSysSetting(_pb, _pf, _pi);
    },
  }));

  const C_InitChSystemSetting = {
    active: false,
    CD: null,
    maxCap: null,
    minCap: null,
    chillerActive: 0,
    cycleTime: null,
    queryOt: null,
    kwThreshold: null,
  };
  const C_aChiller = [1, 2, 3, 4, 5];

  // eslint-disable-next-line
  const [G_ChSystemSetting, setG_ChSystemSetting] = useState(
    C_InitChSystemSetting
  );
  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const F_ToggleActive = () => {
    let _ChSystemSetting = JSON.parse(JSON.stringify(G_ChSystemSetting));
    _ChSystemSetting.active = !_ChSystemSetting.active;
    setG_ChSystemSetting(_ChSystemSetting);
  };
  const F_GetChillerActive = (nChNo) => {
    let _ChSystemSetting = JSON.parse(JSON.stringify(G_ChSystemSetting));
    return getBitByIdx(_ChSystemSetting.chillerActive, nChNo);
  };
  const F_ToggleChActive = (nChNo) => {
    let _ChSystemSetting = JSON.parse(JSON.stringify(G_ChSystemSetting));

    let curActive = getBitByIdx(_ChSystemSetting.chillerActive, nChNo);
    if (curActive)
      _ChSystemSetting.chillerActive = clearBitByIdx(
        _ChSystemSetting.chillerActive,
        nChNo
      );
    else
      _ChSystemSetting.chillerActive = setBitByIdx(
        _ChSystemSetting.chillerActive,
        nChNo
      );

    setG_ChSystemSetting(_ChSystemSetting);
  };

  const handleCloseDia = () => {
    setG_statusState(0);
  };
  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };
  const setGw = () => {
    setG_statusState(1);
  };

  const F_handleUplaod = () => {
    if (props.handleUploadSetting) props.handleUploadSetting();
  };

  const F_FillUpSysSetting=(_pb, _pf, _pi)=>{
    let _ChSystemSetting = JSON.parse(JSON.stringify(G_ChSystemSetting));

    _ChSystemSetting.active = _pb[0];
    
    F_SetInputVal_Number(`sys_cd_${props.pos}`, _pi[0]/(60*1000));
    F_SetInputVal_Number(`sys_min_${props.pos}`, _pi[1]);
    F_SetInputVal_Number(`sys_max_${props.pos}`, _pi[2]);
    F_SetInputVal_Number(`sys_QueryOT_${props.pos}`, _pi[3]/1000);
    _ChSystemSetting.chillerActive = _pi[4];

    F_SetInputVal_Number(`sys_kWTh_${props.pos}`, _pf[0]);

    setG_ChSystemSetting(_ChSystemSetting);
  }

  const F_DownladSysSetting = () => {
    let _ChSystemSetting = JSON.parse(JSON.stringify(G_ChSystemSetting));
    let _SettingInfo = {
      CD: F_GetInputVal_Number(`sys_cd_${props.pos}`),
      maxCap: F_GetInputVal_Number(`sys_max_${props.pos}`),
      minCap: F_GetInputVal_Number(`sys_min_${props.pos}`),
      // cycleTime:F_GetInputVal_Number("sys_cycleTime"),
      queryOt: F_GetInputVal_Number(`sys_QueryOT_${props.pos}`),
      kwThreshold: F_GetInputVal_Number(`sys_kWTh_${props.pos}`),
      active: _ChSystemSetting.active,
      chillerActive: _ChSystemSetting.chillerActive,
    };
    let passCode = F_GetInputVal_Number(`sys_pass_${props.pos}`);
    /** Data validation */
    for (const key in _SettingInfo) {
      if (Object.hasOwnProperty.call(_SettingInfo, key)) {
        if (_SettingInfo[key] === null) {
          toast(`Invalid input (${key})`);
          return;
        }
      }
    }
    if (passCode === null) return toast(`Invalid input (Password)`);

    /** trigger parent function */
    if (props.handleDownloadSysSetting)
      props.handleDownloadSysSetting(_SettingInfo, passCode);
  };

  return (
    <div className="spring_SL_TabPg">
      {G_statusState === 1 && (
        <TpDiaGatewayID
          divInfo={props.dev}
          gwPair={G_GwID}
          onClickDiaClose={handleCloseDia}
          setGwId={handleSetGwDisplay}
        />
      )}

      <div className="sortHor">
        <div className="spring_tpContentTitle">General</div>
        <MdOutlineFileUpload
          className="reactCfgButtonOn stayRight hoverPointer"
          style={{ marginRight: "20px" }}
          onClick={F_handleUplaod}
        />
      </div>

      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Logic Active</div>
          <div
            className="stayRight spring_tpContentValue hoverPointer"
            onClick={F_ToggleActive}
          >
            {G_ChSystemSetting.active ? (
              <BsToggleOn className="reactCfgButtonOn" />
            ) : (
              <BsToggleOff />
            )}
          </div>
          {/* <div className="stayRight blueText spring_tpContentValue">
            1
          </div> */}
        </div>
        <div className="sortHor spring_tpContent">
          <div>Pair Gateway</div>
          <div
            className="stayRight blueText spring_tpContentValue hoverPointer"
            onClick={setGw}
          >
            {G_GwID}
          </div>
        </div>
      </div>

      <div className="spring_tpContentTitle">Chiller</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Cool Down (min)</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`sys_cd_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Max Limit</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`sys_max_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Min Limit</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`sys_min_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        {/* <div className="sortHor spring_tpContent">
          <div>Cycle time (sec)</div>
          <input
            style={{ width: "100px", textAlign:"end"}}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue" 
            id={`sys_cycleTime`}
            placeholder="--"
          ></input>
        </div> */}
        <div className="sortHor spring_tpContent">
          <div>Chiller active</div>
          <div className="stayRight spring_tpContentValue sortHor">
            {C_aChiller.map((u, ind) => (
              <div key={`ch_Key_${props.pos}_${ind}`}>
                <div
                  className={`spring_chSelFrame hoverPointer ${
                    F_GetChillerActive(ind) ? "spring_chSelFrame_Active" : ""
                  }`}
                  onClick={() => F_ToggleChActive(ind)}
                >
                  {ind + 1}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="spring_tpContentTitle">Power Meter</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Query Timeout (sec)</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`sys_QueryOT_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>kW Threshold (kW)</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`sys_kWTh_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentTitle">Security</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Password</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`sys_pass_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentSendButton hoverPointer"
        onClick={F_DownladSysSetting}
      >
        Update
      </div>

      <div style={{ marginBottom: "25px" }}></div>
    </div>
  );
});

export default MRChTabSystemSetting;
