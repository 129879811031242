import React, { Component } from 'react';
// import { FiLogOut } from "react-icons/fi";

class TpSettingSel extends Component {
    handleOnclick=()=>{
        if(this.props.onClick){
            this.props.onClick();
        }
    }
    render() { 
        return <div>
                <div onClick={this.handleOnclick} className='settingItem'>
                    <div className='settingItemIcon'>
                        {this.props.iconClass()}
                    </div>
                    <div className='settingItemText'> {this.props.textDisplay} </div>
                </div>
        </div>;
    }
}
 
export default TpSettingSel;