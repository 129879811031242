import React, { useEffect, useRef, useState } from 'react';
import { FaSyncAlt} from "react-icons/fa";
import TpDiaGatewayID from './../../../Common/Dialog/Input/TpDiaGatewayID';
import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { toast } from 'react-toastify';
import { notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import ctrlCmdLib from '../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';
import TpDiaSending from './../../../Common/Dialog/StatusDialog/TpDiaSending';
import TpDiaSuccess from './../../../Common/Dialog/StatusDialog/TpDiaSuccess';
import TpDiaFailed from './../../../Common/Dialog/StatusDialog/TpDiaFailed';
import socketIOClient from 'socket.io-client';

function TpClSimulator(props) {
    // const G_DevInfo = {
    //     bdDev_id: 27,
    //     hi: 11,
    //     ht: 41,
    //     inUse: 1,
    //     type: 41
    // };
    
    const cmdTimeout = 10000;    // 10 sec
    const msgTimeout = 3000;    // 3 sec

    const [G_statusState, setG_statusState] = useState(0);
    const [G_coolingTowerIdx] = useState([0,1,2,3]);
    const [G_retuneVar] = useState([1,2,3,4]);

    const [G_gwPair, setG_gwPair] = useState(0);
    const [G_diaErrMsg, setG_diaErrMsg] = useState("");
    const [G_DevInfo, setG_DevInfo] = useState({});
    
    const cmdTimeoutRef = useRef(null);
    const statusTimeoutRef = useRef(null);
    const refCmdLog = useRef({});

    useEffect(() => {
        let socket;
        async function startUp() {
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);    
            await loadGwPair(props.dev);

            setG_DevInfo(props.dev);

            let topic = `v2_CtrlCmd`;
            socket.on(topic, async(data) => {
                // console.log("data", data);
                let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
                if(sentSuccess) {
                    clearTimeout(cmdTimeoutRef.current);
                    setG_statusState(2);
                    /** trigger pass countdown */
                    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
                    // toast ("Success")
                    await handleServerReq(data);
                }
                /** handle server request */
                
            });
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);

    const loadGwPair=async (DevInfo)=>{
        try { 
            let pairedGw = await v2ctrlFn.getV2_GwPair(DevInfo._id);
            if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
            let {gwPairList} = pairedGw;
            if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
            setG_gwPair(gwPairList[0].gwid);
        } catch (error) {
            console.log("loadGwPair err: ", error.message);
        }
    }
    
    const handleCallGwId=()=>{
        setG_statusState(11);
    }
    
    const handleCloseDia=()=>{
        clearTimeout(cmdTimeoutRef.current);
        clearTimeout(statusTimeoutRef.current);    
        setG_statusState(0);
    }

    const handleSetGwDisplay=(gwid)=>{
        setG_gwPair(gwid);
    }

    const getDataInput=()=>{
        let pf=[];
        for (let i = 0; i < G_coolingTowerIdx.length; i++) {
            let in1 = parseFloat(document.getElementById(`in_kW_${i}`).value);
            if(isNaN(in1)) in1=0;
            pf.push(in1);
            document.getElementById(`in_kW_${i}`).value = in1;
        }
        for (let i = 0; i < G_retuneVar.length; i++) {
            let in1 = parseFloat(document.getElementById(`in_retune_${i}`).value);
            if(isNaN(in1)) in1=0;
            pf.push(in1);
            document.getElementById(`in_retune_${i}`).value = in1;
        }
        // in_kW_
        return pf;
    }

    const handleUploadCfg=async()=>{
        let pf = getDataInput();
        let payload = {pf};
        
        /** get gw info */
        if(G_gwPair < 1) return toast(`No gateway pair to this device`);

        /** get device {type, devID} info */
        let devInfo = {type:G_DevInfo.type, devID:G_DevInfo.devID}
        // return 
        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(2, G_gwPair, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

        setG_statusState(1);     // go to sending

        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    const handleSyncCfg=async ()=>{
        /** get gw info */
        if(G_gwPair < 1) return toast(`No gateway pair to this device`);

        /** get device {type, devID} info */
        let devInfo = {type:G_DevInfo.type, devID:G_DevInfo.devID}
        
        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(3, G_gwPair, devInfo, [], 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

        setG_statusState(1);     // go to sending
        
        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }
    
    const fCmdTimeout=async()=>{
        let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
        if(!checkRel || !checkRel.errMsg) {
            setG_diaErrMsg("Cmd DB Err");
            // toast("Cmd DB Err");
        }
        if(checkRel.errMsg) {
            setG_diaErrMsg(checkRel.errMsg);
            // toast(checkRel.errMsg);
        }
        setG_statusState(3);
        clearTimeout(cmdTimeoutRef.current);
        /** trigger failed countdown */
        statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);        
        
    }

    const statusDiaAutoClose=()=>{
        clearTimeout(cmdTimeoutRef.current);
        clearTimeout(statusTimeoutRef.current);    
        setG_statusState(0);
    }

    const handleServerReq=async(mqttData)=>{
        try {
            let {hf} = mqttData;
            if(hf===2){         // 
                console.log("hf===2");
            }else if (hf===3){  // sync done
                console.log("hf===3");
                let {pf} = mqttData;
                document.getElementById(`in_kW_0`).value = pf[0];
                document.getElementById(`in_kW_1`).value = pf[1];
                document.getElementById(`in_kW_2`).value = pf[2];
                document.getElementById(`in_kW_3`).value = pf[3];
    
                document.getElementById(`in_retune_0`).value = pf[4];
                document.getElementById(`in_retune_1`).value = pf[5];
                document.getElementById(`in_retune_2`).value = pf[6];
                document.getElementById(`in_retune_3`).value = pf[7];
            }
        } catch (error) {
            console.log("handleServerReq err: ", error.message);
        }
    }

    return (
        <div>
            
            {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
            {G_statusState===1 && <TpDiaSending/>}
            {G_statusState===2 && <TpDiaSuccess onClickDiaClose = {handleCloseDia}/>}
            {G_statusState===3 && <TpDiaFailed onClickDiaClose = {handleCloseDia} diaErrMsg={G_diaErrMsg}/>}
            
            {G_statusState===11 && <TpDiaGatewayID 
                divInfo = {G_DevInfo} 
                gwPair = {G_gwPair} 
                onClickDiaClose = {handleCloseDia}
                setGwId = {handleSetGwDisplay}
            />}

            <div className='spring_tpFrame spring_shadowBox'>
                <div className='sortHor'>           
                    <div className='spring_tpTitle'>Simulator</div>
                    <div className='hoverPointer spring_syncButton spring_rightInput'  
                        onClick={handleSyncCfg}>
                            <FaSyncAlt/>
                        </div>
                </div>
                <div className='spring_cardFrame'>
                    <div className='spring_ContentHeader'>
                        <div className='spring_HeaderText'>
                            kW pattern simulator
                        </div>
                        <div className='spring_ContentArea'>
                            {G_coolingTowerIdx.map((u, ind)=>(      
                                <div key={`key_ct_${ind}`}>
                                    <div className='sortHor'>
                                        <div>{`${u} cooling tower`}</div>
                                        <div className='stayRight sortHor'>
                                            <input id={`in_kW_${ind}`} className='spring_rightInput spring_gwInputBar spring_marginRight10' type="number"/>
                                            <div className='spring_marginLeft5'>kW</div>
                                        </div>
                                    </div>
                                </div>
                            ))} 
                            

                        </div>
                    </div>
                </div>
                
                <div className='spring_seperationLine'></div>

                <div className='spring_cardFrame'>
                    <div className='spring_ContentHeader'>
                        <div className='spring_HeaderText'>
                            Re-tune variable simulator
                        </div>
                        <div className='spring_ContentArea'>
                            {G_retuneVar.map((u, ind)=>(      
                                <div key={`key_retune_${ind}`}>
                                    <div className='sortHor'>
                                        <div>{`Re-tune var ${u}`}</div>
                                        <div className='stayRight sortHor'>
                                            <input id={`in_retune_${ind}`} className='spring_rightInput spring_gwInputBar spring_marginRight10' type="number"/>
                                        </div>
                                    </div>
                                </div>
                            ))} 
                        </div>
                    </div>
                </div>
                
                <div className='spring_seperationLine'></div>

                <div className=' spring_cardFrame '>
                    <div className='spring_ContentHeader sortHor'>
                        <div className='spring_HeaderText'>
                            Gateway Pair
                        </div>
                        <div className='spring_rightInput blueText hoverPointer'
                        onClick={handleCallGwId}>{`${G_gwPair>0 ?G_gwPair: "-"}`}</div>
                    </div>
                </div>
                
                <div className='sortHor spreadEvenly'>
                    {/* <div className='spring_DiaButton spring_BtmBtn_Grey spring_shadowBox hoverPointer'
                    // onClick={handleDiscard}
                    >Discard</div> */}
                    <div className='spring_DiaButton spring_BtmBtn_Green spring_shadowBox hoverPointer'
                    onClick={handleUploadCfg}
                    >Save</div>
                </div>

            </div>
        </div>
    );
}

export default TpClSimulator;