import {createAction} from '@reduxjs/toolkit';
import axios from 'axios';

export const apiCallBegan = createAction("api/CallBegan");
export const apiCallSuccess = createAction("api/CallSuccess");
export const apiCallFailed = createAction("api/CallFailed");

const api = ({dispatch}) => next => async action =>{
    if(action.type !== apiCallBegan.type) return next(action);
    next(action);

    const {onSuccess} = action.payload;
    try {
        // const Rel = await axios('http://jsonplaceholder.typicode.com/users');
        const Rel = await axios.request({
            baseURL:'http://jsonplaceholder.typicode.com',
            url:'/users',
        })
        
        dispatch(apiCallSuccess(Rel.data));   // keep a record at redux
        if(onSuccess) dispatch({type: onSuccess, payload : Rel.data})
    } catch (error) {
        console.log(error.message);
        dispatch(apiCallFailed(error.message));   // keep a record at redux
    }
}


export default api;