import React, { useEffect, useState, useRef } from 'react';
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import "chartjs-adapter-moment";
import zoomPlugin from 'chartjs-plugin-zoom';
import v2QueryFn from "../../../../../function/V2_Query/V2_QueryBdDevData";
// import { GoSettings } from "react-icons/go";
import { GiSettingsKnobs } from "react-icons/gi";
import TpChooseSensorPara from './../DialogTemplate/TpChooseSensorPara';
import TpSetMinMax from './../DialogTemplate/TpSetMinMax';
import socketIOClient from "socket.io-client";
import { BsCircleFill } from "react-icons/bs";
import { HiSelector } from "react-icons/hi";
import { toast } from 'react-toastify';
import PinwheelLoader from './../LoaderTemplate/pinwheelLoader';
import { isEmptyArr, notEmptyArr, isEmptyObj } from '../../../../../function/ArrayFn/arrayFn';
import TpSbDevNoData from './../Signboard/TpSbDevNoData';
// eslint-disable-next-line
import GradientIcon from '../../../../template/SVG fill/gradientIcon';
import { useSelector } from 'react-redux';
import { getSelDevData } from '../../../../../reduxStore/actions/dev_selHistoryTrend';
import indexedDbFn from "../../../../../function/IndexedDB/indexedDbFn";
import timeFn from "../../../../../component/utilities/timeFn";

Chart.register(zoomPlugin);

function SubTpGraph(props) {
    const Grx_selDevData = useSelector(getSelDevData);

    const [G_lastData, setG_lastData] = useState({});
    const [G_defaultParam, setG_defaultParam] = useState([]);
    const [G_loaded, setG_loaded] = useState(false);
    const [G_chartData, setG_chartData] = useState({datasets: []});
    const [G_lineData, setG_lineData] = useState({});
    const [G_minmax, setG_minmax] = useState({});
    const [G_aHideLine, setG_aHideLine] = useState([false, false]);   
    // eslint-disable-next-line 
    const [G_Pos, setG_Pos] = useState("0");

    const [G_LongList, setG_LongList] = useState([]);
    const [G_statusState, setG_statusState] = useState(0);
    const [G_currSelected, setG_currSelected] = useState(0);

    const [G_scaleStatus, setG_scaleStatus] = useState(0);
    const [G_scaleMinMax, setG_scaleMinMax] = useState([{min: null, max: null}, {min: null, max: null}]);
    const [G_zoomLimit, setG_zoomLimit] = useState({min: null, max: null});

    const [count, setCount] = useState(0);
    const [G_emptyData, setG_emptyData] = useState(true);


    // const loadTimeDelay = useRef(null);



    const timerRef = useRef(null);
    const refPageClosed = useRef(false);
    // const refPgReload = useRef(0);
    // const refSkipClose = useRef(false);

    useEffect(()=>{
        // if (navigator.storage && navigator.storage.estimate) {
        //     navigator.storage.estimate().then(estimate => {
        //       console.log(`Quota: ${estimate.quota / (1024 * 1024)} MB`);
        //       console.log(`Usage: ${estimate.usage / (1024 * 1024)} MB`);
        //     });
        // }
        if (props.bIsHistoryGraph) setG_loaded(false);

        refPageClosed.current=false;
        timerRef.current = setInterval(() => {
            setCount(count=>count+1);
            // await reloadData();
        }, 120000);       /** 120000 , 2 mins*/

        let socket_dataLog, socket_dataReceiver;
        async function startUp(){
            if (!props.bIsHistoryGraph) {   
                /** only call socket io on latest graph */
                socket_dataLog = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
                socket_dataReceiver = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATARECEIVER);
            }
            let defDisplay = firstLoad();
            if(refPageClosed.current) return console.log("Returned 1");
            let loadRel= await loadGraph(defDisplay);
            if(loadRel){
                if(refPageClosed.current) return console.log("Returned 2");
                if(props.pos) setG_Pos(props.pos);
                setG_emptyData(false)
            }
            
            
            // loadTimeDelay.current = setTimeout(F_LoadDelay, 100);

            // if (!props.bIsHistoryGraph) setG_loaded(true);
            setG_loaded(true);
            if (!props.bIsHistoryGraph) {   
                let{type, _id}= props.dev;
                let topic = `v2_${type}_${_id}`;
                socket_dataLog.on(topic, data => {
                    setCount(count=>count+1);
                });
                socket_dataReceiver.on(topic, data => {
                    setCount(count=>count+1);
                });
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
            refPageClosed.current=true;
            if (!props.bIsHistoryGraph) {   
                socket_dataLog.disconnect();
                socket_dataReceiver.disconnect();
            }
            clearInterval(timerRef.current);
            // clearTimeout(loadTimeDelay.current);
        }
        // eslint-disable-next-line
    }, [count, Grx_selDevData, props.hisStartUnix, props.hisEndUnix]);

    // const F_LoadDelay=()=>{
    //     console.log("Times up");
    //     setG_loaded(true);
    //     clearTimeout(loadTimeDelay.current);
    // }

    const firstLoad = () => {
        if(!props.a_para) return;
        let  a_para  = [...props.a_para];
        if(!a_para) return;
        if(G_defaultParam.length > 0) return [...G_defaultParam];
        // ??
        // max default parameter is 2
        let _defaultParam = a_para.filter((c) => c.defaultDisplay > 0);
        _defaultParam.sort((a,b) => a.defaultDisplay - b.defaultDisplay);
        if(_defaultParam.length > 2)
            _defaultParam.slice(0, 2);

        
        setG_defaultParam([..._defaultParam]);
        // setG_paraList(_paraList);
        setG_LongList(a_para);


        return [..._defaultParam];
    }

    const loadGraph = async (_defDisplay) => {
        try {
            let defDisplay = [..._defDisplay];
            let { dev, a_para } = props;
            if(!dev) return false;
            let lastNthData = [];
            if(props.bIsHistoryGraph){
                let curDevDataSet = Grx_selDevData.find(c=>c.bdDev_id === props.dev._id && c.ht === props.dev.type);
                if(curDevDataSet) {
                    if(props.hisStartUnix >0 && props.hisEndUnix>0){    // valid start end time
                        lastNthData = curDevDataSet.data.filter(c=>c.unix >= props.hisStartUnix && c.unix <= props.hisEndUnix );
                    }else{      // default start end
                        lastNthData = curDevDataSet.data;
                    }
                    if(notEmptyArr(lastNthData)){
                        setG_lastData(lastNthData[0]);
                    }
                }
                else return false;
            }else{
                const savedData = await indexedDbFn.loadDeviceGraphData(dev._id);
                // console.log(savedData);
                const currUnix = timeFn.getUnixNow();
                if(!isEmptyArr(savedData) && currUnix - savedData[0].unix < 28800) {
                    lastNthData = savedData;
                    let _lastData = savedData[0];
                    // let _lastData;
                    let newData = [];
                    /** 1 minute refresh */
                    if(currUnix - savedData[0].unix > 1 * 60) {
                        /** query last nth data from last updated unix to curr unix */
                        newData = await v2QueryFn.v2GetBdDevData_T1_T2(dev.type, dev._id, savedData[0].unix+1, currUnix);
                        // console.log("newData", newData);
                        if(!isEmptyArr(newData)) {
                            // lastNthData = await indexedDbFn.mergeDataForDevice(dev._id, newData);
                            lastNthData = await indexedDbFn.clearExpiredAndMergeDataForDevice(dev._id, 28800, newData);
                            _lastData = lastNthData[0];
                        }
                    }
                    setG_lastData(_lastData);
                } else {
                    let lastData = await v2QueryFn.v2GetData_LastN(dev.type, dev._id, 1);
                    if(isEmptyArr(lastData)) return false;
                    setG_lastData(lastData[0]);
                    lastNthData = await v2QueryFn.v2GetnMinB4nUnix(dev.type, dev._id, lastData[0].unix+1, 8 * 60);
                    await indexedDbFn.saveDeviceGraphData(dev._id, lastNthData)
                }
            }


            let minmax = {};
            for(const eachKey of a_para) {
                let min, max;
                if(eachKey.dataIndex === -1) {
                    min = Math.min(...lastNthData.map(o => o[`${eachKey.dataType}`]));
                    max = Math.max(...lastNthData.map(o => o[`${eachKey.dataType}`]));
                } else {
                    min = Math.min(...lastNthData.map(o => o[`${eachKey.dataType}_${eachKey.dataIndex}`]));
                    max = Math.max(...lastNthData.map(o => o[`${eachKey.dataType}_${eachKey.dataIndex}`]));
                }
                minmax[eachKey.dataName] = ({ min: min, max: max });
            }
            setG_minmax(minmax);

            let _zoomLimit = {};
            if(G_zoomLimit.min === null || G_zoomLimit.min > lastNthData[lastNthData.length - 1].unix*1000)
                _zoomLimit.min = lastNthData[lastNthData.length - 1].unix*1000;
            else
                _zoomLimit.min = G_zoomLimit.min;
            if(G_zoomLimit.max === null || G_zoomLimit.max < lastNthData[0].unix*1000)
                _zoomLimit.max = lastNthData[0].unix*1000;
            else
                _zoomLimit.max = G_zoomLimit.max;
            
            setG_zoomLimit(_zoomLimit);
            
            let lineData={};
            for(const eachData of lastNthData) {
                for (const eachKey of a_para) {
                    let singleData = {}
                    if(eachKey.dataIndex === -1)
                        singleData = {x: eachData.unix*1000, y: eachData[`${eachKey.dataType}`]};
                    else
                        singleData = {x: eachData.unix*1000, y: eachData[`${eachKey.dataType}_${eachKey.dataIndex}`]};
                    if(!Array.isArray(lineData[eachKey.dataName])) lineData[eachKey.dataName]=[singleData];
                    else lineData[eachKey.dataName].push(singleData);
                    
                }
            }
            setG_lineData(lineData);
            let chartData = genChartData(lineData, defDisplay);
            setG_chartData(chartData);
            return true;
        
        } catch (error) {
            // toast("loadGraph err:", error.message)
            console.log(`loadGraph err: ${error.message}`);
        }
        
    }

    
    const genLineColor = () => {
        let mychart = document.getElementById(`${G_Pos}_linegraph`);
        if (!mychart) return [];
        let ctx = mychart.getContext("2d");
        // const ctx = canvas.getContext("2d");
        let grLn_1 = ctx.createLinearGradient(0, 0, mychart.width, 0);
        let grLn_2 = ctx.createLinearGradient(0, 0, mychart.width, 0);
    
        grLn_1.addColorStop(0, "#ff94b2");
        grLn_1.addColorStop(1, "#7d77ff");
        grLn_2.addColorStop(0, "#00ffed");
        grLn_2.addColorStop(1, "#00b8ba");
        return [grLn_1, grLn_2];
      };

    const genChartData = (lineData, defDisplay) => {
        try {
            if(isEmptyObj(lineData)) return;
            let lineColor = genLineColor();
            let datasets = [];
            let counter = 0;
            for(const eachKey of defDisplay) {
                datasets.push({
                    yAxisID: eachKey.dataName,
                    label: eachKey.dataName,
                    // hidden: true,
                    hidden: G_aHideLine[counter],
                    data: lineData[eachKey.dataName],
                    backgroundColor: lineColor[counter],
                    borderColor: lineColor[counter],
                    borderWidth: 1,
                    pointRadius: 1.5,
                });
                counter++;
            }
            return ({datasets});
        } catch (error) {
            console.log("genChartData error:", error.message);
            // toast(`genChartData error: ${error.message}`)
        }
    }

    const genAxesOptions = () => {
        if (Object.keys(G_lineData).length === 0 || !G_defaultParam.length) return;
        let _defaultParam = [...G_defaultParam];
        let axes = {};
        let counter = 0;
        let unit = "";
        for(const eachKey of _defaultParam) {
            if(counter === 0) {
                let ttlTime = (G_lineData[eachKey.dataName][0].x - G_lineData[eachKey.dataName][G_lineData[eachKey.dataName].length-1].x) / 60000;
                if(ttlTime > 24*60) unit = "day";
                else if(ttlTime > 60) unit = "hour";
                else unit = "minute";
            }
            axes[eachKey.dataName] = {
                type: "linear",
                position: counter === 0? "left" : "right",
                title: {
                    display: false,
                    //   text: line,
                },
                grid: {
                    display: false,
                    // drawTicks: false
                },
                ticks: {
                    display: !G_aHideLine[counter],
                    font: {
                        size: 11
                    }
                }
            };
            if(G_scaleMinMax[counter].min !== null || G_scaleMinMax[counter].max !== null) {
                G_scaleMinMax[counter].min === null? axes[eachKey.dataName].min = G_minmax[eachKey.dataName].min :
                                                    axes[eachKey.dataName].min = G_scaleMinMax[counter].min;
                G_scaleMinMax[counter].max === null? axes[eachKey.dataName].max = G_minmax[eachKey.dataName].max :
                                                    axes[eachKey.dataName].max = G_scaleMinMax[counter].max;
            }
            counter++;
        }
        axes.x = {
            type: "time",
            time: {
                unit: unit,
                // stepSize: 15
            },
            grid: {
                display: false
            },
            ticks: {
                font: {
                    size: 11
                }
            }
        };
        return axes;
      };

    let options = {
        scales: genAxesOptions(),
        plugins: {
            legend: {
                display: false
            },
            zoom: {
                pan: {
                  enabled: true,
                  mode: 'x'
                },
                zoom: {
                  wheel: {
                    enabled: true
                  },
                  drag: {
                    enabled: true
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'x'
                },
                limits: {
                    x: {
                        min: G_zoomLimit.min,
                        max: G_zoomLimit.max
                    }
                }
            }
        }
    }

    const toggleGraph = async (paraIdx) => {
        try {
            // setG_loaded(false);
            let _aHideLine = [...G_aHideLine];  
            _aHideLine[paraIdx] = !_aHideLine[paraIdx];
            setG_aHideLine(_aHideLine);
            
            let {datasets} = G_chartData;
            let _datasets = [...datasets];      // <--- 2nd tier spread
            _datasets[paraIdx].hidden = _aHideLine[paraIdx];
            setG_chartData({datasets:_datasets});
        } catch (error) {
            toast(error.message);
        }
    }

    const closeDialog=()=>{
        setG_statusState(0);
        setG_currSelected(0);
        setG_scaleStatus(0);
    }
    
    const callChooseParaTp = (idx, _id) => {
        setG_statusState(idx);
        setG_currSelected(_id);
    }

    const handleParaSel = async (info) => {
        let _defParaList = [...G_defaultParam];
        _defParaList[G_statusState-1] = info;
        setG_defaultParam(_defParaList);
        closeDialog();
        let chartData = genChartData(G_lineData, _defParaList);
        setG_chartData(chartData);
    }

    const callSetMinMaxTp = (idx) => {
        if(props.TpManu===0 || typeof props.TpManu === 'undefined'){
            setG_scaleStatus(idx);
        }
    }

    const handleSetMinMax = async () => {
        let _min = document.getElementById('tpDialog_min').value;
        let _max = document.getElementById('tpDialog_max').value;
        let _scaleMinMax = [...G_scaleMinMax];
        _min !== ""?  _scaleMinMax[G_scaleStatus-1].min = parseFloat(_min) :  _scaleMinMax[G_scaleStatus-1].min = null;
        _max !== ""? _scaleMinMax[G_scaleStatus-1].max = parseFloat(_max) : _scaleMinMax[G_scaleStatus-1].max = null ;
        setG_scaleMinMax(_scaleMinMax);
        closeDialog();
    }

    const handleResetMinMax = async () => {
        let _scaleMinMax = [...G_scaleMinMax];
        _scaleMinMax[G_scaleStatus-1].min = null;
        _scaleMinMax[G_scaleStatus-1].max = null;
        setG_scaleMinMax(_scaleMinMax);
        closeDialog();
    }

    return (
        <div className=''>
            {/* <div className='' style={{height:"0px"}}>
                <svg style={{ width:0, height:0, position: 'absolute' }} aria-hidden="true" focusable="false">
                    <linearGradient id="purple-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                        <stop offset="0%" stopColor="#ff94b2" />
                        <stop offset="100%" stopColor="#7d77ff" />
                    </linearGradient>
                </svg>
                <svg style={{ width:0, height:0, position: 'absolute' }} aria-hidden="true" focusable="false">
                    <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                        <stop offset="0%" stopColor="#00ffed" />
                        <stop offset="100%" stopColor="#00b8ba" />
                    </linearGradient>
                </svg>
            </div> */}
            {(G_statusState!==0 || G_scaleStatus!==0) && <div className='spring_dialogBlurBg'></div>}
            {G_statusState!==0 && <TpChooseSensorPara 
                selList = {G_LongList}
                currSelList = {G_defaultParam}
                currSelected = {G_currSelected}
                lastData = {G_lastData}
                onClickItemSel = {handleParaSel}
                onClickCloseDia = {closeDialog}
                displaySlotIdx = {G_statusState}
                />}
            {G_scaleStatus!==0 && <TpSetMinMax
                scaleStatus = {G_scaleStatus}
                scaleMinMax = {G_scaleMinMax}
                defParamList = {G_defaultParam}
                devInfo = {props.dev}
                onClickCloseDia = {closeDialog}
                onClickSetMinMax = {handleSetMinMax}
                onClickResetMinMax = {handleResetMinMax}
            />}
            {!G_emptyData && <div className='flexOnly spreadBetween tpSettingContainer'>
                <div className='divRelative'>
                    {/* <GoSettings className='hoverPointer' fill="url(#purple-gradient)" onClick={() => callSetMinMaxTp(1)} /> */}
                    <div className="hoverPointer" onClick={() => callSetMinMaxTp(1)}>
                        <svg viewBox="0 0 16 16" width='16px' height='16px'>
                            <linearGradient id="purple-gradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#ff94b2" />
                            <stop offset="100%" stopColor="#7d77ff" />
                            </linearGradient>
                            <GiSettingsKnobs fill="url(#purple-gradient)" />
                        </svg>
                    </div>
                    {/* <GradientIcon /> */}
                    {(G_scaleMinMax[0].min !== null || G_scaleMinMax[0].max !== null) && <BsCircleFill className='tpGraph_smallIcon divAbsolute' style={{top:"5px", left:"12px"}}/>}
                </div>
                <div className='divRelative'>
                    {/* <GoSettings className='hoverPointer' fill="url(#blue-gradient)" onClick={() => callSetMinMaxTp(2)} /> */}
                    <div className='hoverPointer' onClick={() => callSetMinMaxTp(2)}>
                        <svg viewBox="0 0 16 16" width='16px' height='16px'>
                            <linearGradient id="blue-gradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#00ffed" />
                            <stop offset="100%" stopColor="#00b8ba" />
                            </linearGradient>
                            <GiSettingsKnobs fill="url(#blue-gradient)" />
                        </svg>
                    </div>
                    {(G_scaleMinMax[1].min !== null || G_scaleMinMax[1].max !== null) && <BsCircleFill className='tpGraph_smallIcon divAbsolute' style={{top:"5px", left:"10px"}}/>}
                </div>
            </div>}
            {/* <div style={{ position: "relative" }}><PinwheelLoader /></div> */}
            {!G_loaded && <div style={{ position: "relative" }}>
                <div className='divAbsolute'>
                    <div className='divRelative' style={{top:"70px", left:"170px"}}>
                    <PinwheelLoader style={{top:"900px"}}/>
                    </div>
                </div>
            </div>}
            {/* { props.bIsHistoryGraph===true && <div style={{ position: "relative" }}>
                <div className='divAbsolute'>
                    <div className='divRelative' style={{top:"70px", left:"170px"}}>
                    <PinwheelLoader style={{top:"900px"}}/>
                    </div>
                </div>
            </div>} */}
            <div className={`tpGraphContainer ${(G_loaded && G_emptyData)?"divCollapse":""}`}>
                <Line id={`${G_Pos}_linegraph`} data={G_chartData} options={options} />
            </div>
            {G_loaded && !G_emptyData && G_defaultParam.map((u, ind) => (
                <div key={`${G_Pos}_${ind}_para`} className='sortHor tpParamsContainer'>
                    <div onClick={() => toggleGraph(ind)}>
                        <div className='tpIndicator'style={{ backgroundImage: `${G_aHideLine[ind]?"":`var(--g${ind+1})`}`, border: '3px solid var(--grey8)'}}></div>
                    </div>
                    <div className='sortVer tpReadingContainer' onClick={()=>callChooseParaTp(ind+1, u._id)}>
                        <div className='tpReadingTitle'>{u.dataName}</div>
                        <div className='sortHor spreadBetween'>
                            <div className='tpReading'>
                                {u.dataIndex === -1 ? G_lastData[`${u.dataType}`] : G_lastData[`${u.dataType}_${u.dataIndex}`]}
                            </div>
                            <div className='tpUnit stayRight'>{u.dataUnit}</div>
                            <div><HiSelector className='tpUnit spring_marginLeft10 blueText'/></div>
                        </div>
                        <div className='sortHor tpMinMax'>
                            <div>min:</div>
                            <div className='tpMinMax_reading'>{G_minmax[u.dataName].min}</div>
                            <div>, max:</div>
                            <div className='tpMinMax_reading'>{G_minmax[u.dataName].max}</div>
                            <div>, diff:</div>
                            <div className='tpMinMax_reading'>{Math.round((G_minmax[u.dataName].max - G_minmax[u.dataName].min)*100) / 100}</div>
                        </div>
                    </div>
                </div>
            ))}
            {!props.bIsHistoryGraph && G_loaded && G_emptyData &&<TpSbDevNoData/>}

            {/* { props.bIsHistoryGraph===true && !G_loaded && G_emptyData && <div style={{ position: "relative" }}>
                <div className='divRelative'>
                    <div className='divAbsolute' style={{top:"70px", left:"170px"}}>
                    <PinwheelLoader style={{top:"900px"}}/>
                    </div>
                </div>
            </div>} */}
            {props.bIsHistoryGraph===true && G_loaded && G_emptyData &&<TpSbDevNoData/>}

        </div>
    );
}

export default SubTpGraph;