import React, { useState } from "react";
import { Link } from "react-router-dom";
import regUser_V2 from "../../../service/auth/regUser_V2";
import ReCAPTCHA from "react-google-recaptcha";
import userService from "../../../service/auth/userService";
import InputValidation from "../../template/Form/inputValidation";
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';
import { toast } from 'react-toastify';

const UserRegistration = () => {
  // const {
  //   // eslint-disable-next-line
  //   inputs,
  //   setInputs,
  //   errors,
  //   setErrors,
  //   validateEmail,
  //   validatePassword,
  //   validateCfmPassword,
  // } = InputValidation();

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [captchaError, setCaptchaError] = useState();

  // const handleChange = (input) => (e) => {
  //   setInputs({ [input]: e.target.value });
  // };

  const handleOnBlur = (errorId) => e => {
    // console.log(e.target);
    let error = InputValidation(e.target.id, e.target.value, document.getElementById("passInput").value);
    if(error === null) document.getElementById(errorId).innerHTML = "";
    if(error !== null) {
      // console.log(error);
      document.getElementById(errorId).innerHTML = error;
    }
  }

  const handleSubmit = async () => {
    // if (e) e.preventDefault();

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // console.log(errors);
    // if (errors) return;
    if (!captchaStatus) {
      setCaptchaError("Please verify reCAPTCHA");
      return;
    }
    if (
      document.getElementById("emailErrId").innerHTML === ""
      && document.getElementById("passErrId").innerHTML === ""
      && document.getElementById("cfmPassErrId").innerHTML === ""
    )
      doSubmit();
  };

  const doSubmit = async () => {
    try {
      // let email = document.getElementById("email").value;
      // let password = document.getElementById("password").value;

      // let userData = {};
      // userData.username = username;
      // userData.email = email;
      // userData.password = password;
      let userData = getEmailNPassword();
      // console.log(userData);
      const response = await regUser_V2(userData);
      // console.log(response);
      if(response.status === 203) {
        toast(response.data);
        return;
      }
      // localStorage.setItem("token", response.headers["x-auth-token"]);
      // console.log("header Token: ", response);
      // console.log("header Token: ", response.data);
      //   auth.loginWithJwt(response.data);
      // headers["x-auth-token"];
      //   window.location = "/notactive";
      // console.log(response);

      // this.props.history.push("/email/success");
      window.location = "/email/success";
    } catch (error) {
      console.log(error.message);
      toast('Register Error');
    }
  };

  const getEmailNPassword = () => {
    let email = document.getElementById('emailInput').value.trim(); //add trim
    let password = document.getElementById('passInput').value;
    return({email, password});
  };

  const captchaChange = async (value) => {
    // console.log(value);
    try {
      let result = await userService.recaptcha(value);
      // console.log(result);
      if (result.status === 200) {
        setCaptchaStatus(true);
        setCaptchaError(null);
      } else if (result.status === 205) {
        setCaptchaStatus(false);
        setCaptchaError("Invalid verification");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="form-container">
      <div className="form-title">Register</div>
      <div className="form-signup">
      {/* <TpInputTitleAtBox onBlur={handleOnBlur} id='username' type="text" errMsgid='usernameErrId' title='Username' maxLen={255}/> */}
      <TpInputTitleAtBox onBlur={handleOnBlur("emailErrId")} id='emailInput' type="text" errMsgid='emailErrId' title='E-mail' maxLen={255}/>
      <TpInputTitleAtBox onBlur={handleOnBlur("passErrId")} id='passInput' type="password" errMsgid='passErrId' title='Password' maxLen={255}/>
      <TpInputTitleAtBox onBlur={handleOnBlur("cfmPassErrId")} id='cfmPassInput' type="password" errMsgid='cfmPassErrId' title='Confirm Password' maxLen={255}/>
      {/* <div className="form-card">
          <div className="flexVer">
            <label className="form-label">Username</label>
            <input
              className="form-input"
              type="text"
              id="username"
              name="Username"
              onChange={handleChange("username")}
              onBlur={validateUsername}
            ></input>
          </div>
          <div className="form-error">{errors.username}</div>
          <div className="flexVer">
            <label className="form-label">Email Address</label>
            <input
              className="form-input"
              type="email"
              id="email"
              name="Email Address"
              onChange={handleChange("email")}
              onBlur={validateEmail}
            ></input>
          </div>
          <div className="form-error">{errors.email}</div>
          <div className="flexVer">
            <label className="form-label">Password</label>
            <input
              className="form-input"
              type="password"
              id="password"
              name="Password"
              onChange={handleChange("password")}
              onBlur={validatePassword}
            ></input>
          </div>
          <div className="form-error">{errors.password}</div>
          <div className="flexVer">
            <label className="form-label">Confirm Password</label>
            <input
              className="form-input"
              type="password"
              id="cfmPassword"
              name="Confirm Password"
              onChange={handleChange("cfmPassword")}
              onBlur={validateCfmPassword}
            ></input>
          </div>
          <div className="form-error">{errors.cfmPassword}</div>
        </div> */}
        <div align="center" className="form-recaptcha">
          <ReCAPTCHA
            sitekey="6Lcs7g0dAAAAAMTCqCmKiCvSzCJHwq-b7hGCht1K"
            onChange={captchaChange}
          />
          <div className="form-error-center">{captchaError}</div>
        </div>
        <div className="buttonContainer" style={{marginTop:"30px"}}>
          <div className="roundButton" onClick={() => handleSubmit()}>Register</div>
        </div>
        <div className="form-btm">
          Already have an account?
          <Link to="/user/login" className="form-inlink">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
