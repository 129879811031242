import React, { useEffect, useRef, useState } from "react";
import ctrlTp from "../../CtrlCmdTemplate/CtrlTemplate";
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";
import ctrlCmdLib from "../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib";
import v2ctrlFn from "../../../../../../function/V2_Control/V2_ControlFn";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import TpDiaSending from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSending";
import TpDiaSuccess from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess";
import TpDiaFailed from "../../../../Control/Common/Dialog/StatusDialog/TpDiaFailed";
import {RiBubbleChartLine} from "react-icons/ri";
import MRCwpTabSetting from "./MRCwpTab_Setting";

const SubTpMrRogerCWP = (props) => {
  /**------Ctrl Standard 1/2 Start------ */
  const cmdTimeout = 10000; // 10 sec
  const msgTimeout = 3000; // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  const [G_GwID, setG_GwID] = useState();

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refCmdLog = useRef({});
  const refSetting_payload = useRef({});
  /**======Ctrl Standard 1/2 End====== */

  const [G_ShowingTab, setG_ShowingTab] = useState(0);

  const ref_Setting = useRef(); // parent trig child

  useEffect(() => {
    let socket;
    async function startUp() {
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);

      await F_LoadGateway();

      await F_LoadDbInfo();

      let topic = ctrlTp.F_GetIoTopic(props.dev);
      socket.on(topic, async (data) => {
        if(data.hf === 3 && data?.pn?.[0] === 12345) {
          clearTimeout(cmdTimeoutRef.current);
          setG_diaErrMsg("Incorrect Password");
          setG_statusState(3);
        } else if (
          ctrlTp.F_HandleNodeReply(
            data,
            refCmdLog.current,
            cmdTimeoutRef.current,
            setG_statusState
          )
        ) {
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          await handleServerReq(data);
        }
      });
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);
  /**-----------Ctrl Standard 2/2 start------------- */
  const F_LoadGateway = async () => {
    let pairedGw = await v2ctrlFn.getV2_GwPair(props.dev._id);
    if (pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    let { gwPairList } = pairedGw;
    if (!notEmptyArr(gwPairList))
      return toast("No gateway pair to this device");
    setG_GwID(gwPairList[0].gwid);
  };

  const F_LoadDbInfo = async () => {
    let settingInfo = await v2ctrlFn.getV2_MrEeprom_byFn(props.dev._id, 3);
    if(settingInfo.errMsg) return toast(`Cmd Err: ${settingInfo.errMsg}`);
    let pb = [], pi = [];
    if(notEmptyArr(settingInfo)){
      pb = [ ...settingInfo[0].payload.pb ];
      pi = [ ...settingInfo[0].payload.pi ];
    }
    ref_Setting.current.uploadSetting("db", pb, pi);
  }

  const fCmdTimeout = async () => {
    await ctrlTp.F_CmdTimeout(
      refCmdLog.current,
      setG_diaErrMsg,
      setG_statusState,
      cmdTimeoutRef.current
    );
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
  };
  const statusDiaAutoClose = () => {
    handleCloseDia();
  };

  const handleCloseDia = () => {
    // clearTimeout(cmdTimeoutRef.current);
    // clearTimeout(statusTimeoutRef.current);
    setG_statusState(0);
  };

  const F_SendLoraTp = async (fn, payload) => {
    let devInfo = { type: props.ht, devID: props.dev.devID };
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(fn, G_GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    setG_statusState(1); // go to sending
    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  };

  const handleServerReq = async (mqttData) => {
    try {
      let { hf, pb, pi } = mqttData;
      if(hf === 3) {
        let setCwpSetting = v2ctrlFn.setV2_MrEeprom(props.dev._id, hf, refSetting_payload.current);
        if(setCwpSetting.errMsg) toast (setCwpSetting.errMsg);
      } else if (hf === 12) {
        ref_Setting.current.uploadSetting("node", pb, pi);
      }
    } catch (error) {
      toast(`Server Req Err: ${error.message}`);
    }
  };
  /** ===============Ctrl Standard 2/2 end=============== */

  const F_ToPgNo = (nPgNo) => {
    setG_ShowingTab(nPgNo);
  };

  const F_getSettingPayload = (cwpSetting, passCode) => {
    let pb=[cwpSetting.logicActive];
    let pi=[
      parseInt(cwpSetting.ctrlMethod),
      parseInt(cwpSetting.cwpQty),
      parseInt(cwpSetting.MaxQty),
      parseInt(cwpSetting.MinQty),
    ]
    let pn=[passCode];

    return { pb, pi, pn }
  }

  const F_sendSettingToNode = async (cwpSetting, passCode) => {
    // console.log("cwpSetting", cwpSetting);
    // console.log("passCode", passCode);
    let pb=[cwpSetting.logicActive];
    let pi=[
      parseInt(cwpSetting.ctrlMethod),
      parseInt(cwpSetting.cwpQty),
      parseInt(cwpSetting.MaxQty),
      parseInt(cwpSetting.MinQty),
    ]
    let pn=[passCode];
    let payload = {pb, pi, pn};
    await F_SendLoraTp(3, payload);
    refSetting_payload.current = { pb, pi };
  };

  const F_loadNodeSetting = async () => {
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(12, payload);
  }
  
  const F_uploadSettingToDb = async(cwpSetting) => {
    setG_statusState(1);
    let payload = F_getSettingPayload(cwpSetting, 0);
    let { pb, pi } = payload;
    let setCwpSetting = await v2ctrlFn.setV2_MrEeprom(props.dev._id, 3, { pb, pi });
    if(setCwpSetting.success) setG_statusState(2);
    if(setCwpSetting.errMsg){
      setG_diaErrMsg("DB Error");
      setG_statusState(3);
    } 
  }
  

  return (
    <div>
      {G_statusState !== 0 && <div className="spring_dialogBlurBg"></div>}
      {G_statusState === 1 && <TpDiaSending />}
      {G_statusState === 2 && <TpDiaSuccess onClickDiaClose={handleCloseDia} />}
      {G_statusState === 3 && (
        <TpDiaFailed onClickDiaClose={handleCloseDia} diaErrMsg={G_diaErrMsg} />
      )}

      {G_ShowingTab === 0 && (
        <MRCwpTabSetting
          dev={props.dev}
          pos={props.pos}
          gwId={G_GwID}
          handleLoadNodeSetting={F_loadNodeSetting}
          handleLoadDbSetting={F_LoadDbInfo}
          handleSendToNodeSetting={F_sendSettingToNode}
          handleSendToDbSetting={F_uploadSettingToDb}
          ref = {ref_Setting}
        />
      )}
      

      <div className="spring_TpBottomBar sortHor">
        <div
          className={`spring_TpBottomBar_ItemFrame ${
            G_ShowingTab === 0 ? "spring_shadowBox_Small" : ""
          } hoverPointer`}
          onClick={() => F_ToPgNo(0)}
        >
          <RiBubbleChartLine />
          <div className="spring_TpBtmButtonTitle">System</div>
        </div>
        
      </div>

    </div>
  );
};

export default SubTpMrRogerCWP;
