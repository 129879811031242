import { clearBitByIdx, getBitByIdx, setBitByIdx } from "../../../../../function/common/common"

const getForceOverwrite=(nActive, nValue, nIndex)=>{
    // console.log("nValue", nValue);
    // let nNumber = getBitByIdx(nValue, nIndex);
    // console.log("nNumber", nNumber);
    if(getBitByIdx(nActive, nIndex)){
        if(getBitByIdx(nValue, nIndex)){
            return 1;
        }else{
            return 2;
        }
    }else{
        return 0;
    }
}

const setForceOverwrite=(nActive, nValue, nIndex)=>{
    let _nActive = nActive;
    let _nValue = nValue;

    let curState = getForceOverwrite(nActive, nValue, nIndex);
    if(curState===0){   // set bit active, set bit value        
        _nActive = setBitByIdx(nActive, nIndex);
        _nValue = setBitByIdx(nValue, nIndex);
    }else if(curState===1){  // is on state, reset value bit
        _nValue = clearBitByIdx(nValue, nIndex);
    }else{// is off state, reset active bit, reset value bit
        _nActive = clearBitByIdx(nActive, nIndex);
        _nValue = clearBitByIdx(nValue, nIndex);
    }
    return [_nActive, _nValue];
}

const expModule = {
    getForceOverwrite,
    setForceOverwrite,
};
export default expModule;
