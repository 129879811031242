import React from 'react';
import Lottie from 'react-lottie';
import legoAnimation from './LEGO_loader.json';

function LegoLoader() {
    const options = {
        loop: true,
        autoplay: true,
        animationData: legoAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return ( 
        <div id="animationWindow">
            <Lottie options={options} />
        </div> 
    );
}

export default LegoLoader;