import React, { useEffect, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
// import { AiOutlineQrcode } from 'react-icons/ai';
// import { HiOutlineCursorClick } from 'react-icons/hi';
import devFn from '../../../function/device/sensorDevice';
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import {chooseSensor} from '../../../reduxStore/actions/dev_regSensor';

function ChooseDevice(props) {
    // ----redux----
    const dispatch = useDispatch();
    
    // eslint-disable-next-line
    const [buildingOwner_id, setBuildingOwner_id] = useState(2);
    const [confirmStatus, setConfirmStatus] = useState(0);

    useEffect(()=>{
        async function startUp(){
            // await setUser_id(1)
        }
        startUp();

        return ()=>{
            // alert("Choose Device Page Close");
        }
        // eslint-disable-next-line
    }, []);


    const backPrevPg=()=>{
        props.history.push({
            pathname: "/device/add",
            focusStep:1,
        });
    }

    const handleConfirm=async()=>{
        try {
            if(confirmStatus !== 0) {
                console.log('Cmd Busy');
                return 
            }
            await setConfirmStatus(2);
            /** Check user input */
            let SerialNo = document.getElementById('SnInput').value.trim();
            let RegCode = document.getElementById('regCodeInput').value.trim();
            // let devName = document.getElementById('nameInput').value.trim();

            let inputErr =  false;
            /** Serial no is empty */
            if(SerialNo.length === 0) {
                inputErr=true;
                document.getElementById('SnErrId').innerHTML = "*Serial No. cannot be empty";
            }
            /** register code is empty */
            if(RegCode.length === 0){
                inputErr=true;
                document.getElementById('regCodeErrId').innerHTML = "*Register Code cannot be empty";
            } 
            /** name is empty */
            // if(devName.length === 0) {
            //     inputErr=true;
            //     document.getElementById('nameErrId').innerHTML = "*Name cannot be empty";
            // }
            /** return if found any input error */
            if(inputErr) {
                await setConfirmStatus(0);
                return     
            }

            /** search DB, whether this device exist */
            let rel = await devFn.getdevBy_SnRegCode(SerialNo, RegCode);
            /** device not found, or SN and reg code not match */
            if(!rel || !Array.isArray(rel)) {
                document.getElementById('SnErrId').innerHTML = "*Invalid Serial No. / Invalid Register Code";
                document.getElementById('regCodeErrId').innerHTML = "*Invalid Serial No. / Invalid Register Code";                
                await setConfirmStatus(0);
                return 
            }
            /** Check device belongs to users */
            let sensor = rel[0];
            let sensorOwnByOther = await sensorOwnerFn.getBy_ty_DevId(sensor.type, sensor.devID);
            // console.log(sensorOwnByOther);

            if(sensorOwnByOther.msg !=='OK'){       // !ok => device got owner
                /** make sure device is own by this building Owner */
                if(sensorOwnByOther.data[0].owner_id !== buildingOwner_id){
                    toast.error('Device not own by building owner');    
                    await setConfirmStatus(0);
                    return 
                }
            }
            

            /** register owner to DB success */
            const {type, devID} = sensor;
            dispatch(chooseSensor({SerialNo, RegCode, type, devID}));
            

            /** direct to add device page, bring data */
            return props.history.push({
                pathname: "/device/add",
                focusStep:1,
            });
            
        } catch (error) {
            console.log(error.message);
            await setConfirmStatus(0);
        }

    }


    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Add Device</div>
            </div>

            <div style={{marginTop:"20px"}}></div>
            
            {/* <div className="divisionContainer">
                <div className="divisionTitle">Option 1 : </div>
            </div>

            <div className='scanQrCodeContainer'>
                <AiOutlineQrcode className='scanQrCodeIcon'/>
                <div>
                    {"Scan QR Code On Device"}
                </div>
            </div>
            
            <div style={{marginTop:"30px"}}></div>
            <div className="divisionContainer">
                <div className="divisionTitle">Option 2 : </div>
            </div>

            <div className='scanQrCodeContainer'>
                <HiOutlineCursorClick className='scanQrCodeIcon'/>
                <div>
                    {"Click To Select Device"}
                </div>
            </div>
            
            <div style={{marginTop:"30px"}}></div> */}

            <div className="divisionContainer">
                <div className="divisionTitle">Insert Device info </div>
            </div>

            <div style={{marginTop:"30px"}}></div>

            <TpInputTitleAtBox id='SnInput' errMsgid='SnErrId' title='Serial No.' maxLen={20}/>
            <TpInputTitleAtBox id='regCodeInput' errMsgid='regCodeErrId' title='Register Code' maxLen={20}/>
            {/* <TpInputTitleAtBox id='nameInput' errMsgid='nameErrId' title='Name' maxLen={30}/> */}

            <div className="buttonContainer" style={{marginTop:"30px"}}>
                <div className="roundButton" onClick={handleConfirm}>
                    {(confirmStatus === 0)?"Add":"Checking"}                    
                </div>
            </div>
        </div>
    );
}

export default ChooseDevice;
