import React, { useEffect, useRef, useState } from 'react';
import {isEmptyArr, notEmptyArr, sortTemplate} from '../../../../../function/ArrayFn/arrayFn';
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import sensorMngFn from '../../../../../function/sensor/sensorManagementFn';
import DashItemTitleBar from './Common/DashItemTitleBar';
import { useSelector } from 'react-redux';
import { getFullDashDataList } from '../../../../../reduxStore/actions/dash_Data';
import { toast } from 'react-toastify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PinwheelLoader from '../../../Version2a/Template/LoaderTemplate/pinwheelLoader';

function C0003BarInstant(props) {
    const fullList = useSelector(getFullDashDataList);

    const [G_loaded, setG_loaded] = useState(false);
    const [G_Display, setG_Display] = useState({ data: [], barTitle: "", axisTitle: "" });
    const [G_chartData, setG_ChartData] = useState([]);
    // const [G_minMax, setG_minMax] = useState({min: null, max: null});

    const updateDelayRef = useRef();
    

    useEffect(() => {
        // let socket;
        async function startUp() {
            let { compInfo } = props;
            // eslint-disable-next-line
            let { _displayInfo, _loaded } = getData(compInfo.bdDevInvolve_List, fullList);
            if(_loaded){
                
                let _chartData = genChartData(_displayInfo);

                setG_Display(_displayInfo);
                setG_ChartData(_chartData);
                setG_loaded(true);

                updateDelayRef.current = setTimeout(F_Update, 100);
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
            clearTimeout(updateDelayRef.current);  
        }
    }, [props]);

    const getData=(_devInvolve, dataList)=>{
        let devInvolve = [..._devInvolve];
        /** sort the data list */
        let sortKeys = [
            { key: "dataSortIdx", descOrder: false },
        ];
        devInvolve.sort((a, b) => sortTemplate(a, b, sortKeys));

        /** filter and push the data value */
        let _displayInfo = {labels: [], data: []};
        let _loaded = true;
        for (const eachDev of devInvolve) {
            let devData = dataList.filter(c=>
                c.bdDev_id === eachDev.bdDev_id && 
                c.devType === eachDev.devType && 
                c.qty >= 1 && 
                c.queryType === "lastN"
            )
            if(isEmptyArr(devData)) return { _displayInfo, _loaded: false };
            let paraKey = sensorMngFn.getParaKey_int(eachDev.dataType, eachDev.dataIndex);
            
            _displayInfo.labels.push(eachDev.Name);

            try {
                if(notEmptyArr(devData)){
                    if (Object.hasOwnProperty.call(devData[0].data[0], paraKey)) {
                        // let paraValue = devData[0][paraKey];

                        let paraValue = devData[0].data[0][paraKey];
                        if (typeof paraValue === 'undefined') {
                            _displayInfo.data.push(null);
                            continue
                        }
                        _displayInfo.data.push(paraValue);    
                    }
                }                
            } catch (error) {
                toast(`Generate data err(Dash): ${error.message}`);
                _displayInfo.data.push(null);
                continue;
            }   
        }
        
        return { _displayInfo, _loaded };
    }


    const genChartData = (displayInfo) => {
        let mychart = document.getElementById("MyBarChart_instant");                
        if(!mychart) return ({datasets: []});
        let ctx = mychart.getContext("2d");
        let grLn_1 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_2 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_3 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_4 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_5 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_6 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_7 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_8 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_9 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        let grLn_10 = ctx.createLinearGradient(0, 0, 0, mychart.height);
        grLn_1.addColorStop(0, "#ff94b2");
        grLn_1.addColorStop(1, "#7d77ff");
        grLn_2.addColorStop(0, "#00ffed");
        grLn_2.addColorStop(1, "#00b8ba");
        grLn_3.addColorStop(0, "#ffcf1b");
        grLn_3.addColorStop(1, "#ff8b1b");
        grLn_4.addColorStop(0, "#88cdf6");
        grLn_4.addColorStop(1, "#015c92");
        grLn_5.addColorStop(0, "#f35651");
        grLn_5.addColorStop(1, "#bf263c");
        grLn_6.addColorStop(0, "#79096c");
        grLn_6.addColorStop(1, "#00d4ff");
        grLn_7.addColorStop(0, "#09793c");
        grLn_7.addColorStop(1, "#00ff2c");
        grLn_8.addColorStop(0, "#56c914");
        grLn_8.addColorStop(1, "#1999d7");
        grLn_9.addColorStop(0, "#1914c9");
        grLn_9.addColorStop(1, "#d110d2");
        grLn_10.addColorStop(0, "#172c53");
        grLn_10.addColorStop(1, "#10d28d");

        let grArr = [grLn_1, grLn_2, grLn_3, grLn_4, grLn_5, grLn_6, grLn_7, grLn_8, grLn_9, grLn_10];
        return ({
            labels: displayInfo.labels,
            datasets: [{
                data: displayInfo.data,
                backgroundColor: grArr,
                barThickness: 60,
            }]
        });
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: G_Display.barTitle,
                font: {
                    size: 16,
                },
            },
            datalabels: {
                color: '#696969',
                clamp: true,
                anchor: G_Display.data.findIndex(c => c > 0) === -1? 'start' : 'end',
                align: G_Display.data.findIndex(c => c > 0) === -1? 'start' : 'end',
            },
        },
        scales: {
            x: {
                position: G_Display.data.findIndex(c => c > 0) === -1? 'top' : 'bottom', 
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    borderDash: [5, 5],
                },
                ticks: {
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: G_Display.axisTitle,
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: (context) => {
                        // Customize grid line color
                        if (context.tick.value === 0) {
                          return 'rgba(0, 0, 0, 0.1)'; // Color for the 0 grid line
                        } else {
                          return 'rgba(0, 0, 0, 0)'; // Color for other grid lines
                        }
                      },
                    lineWidth: (context) => {
                        // Customize grid line width
                        return context.tick.value === 0 ? 1 : 0; // Width for the 0 grid line and others
                    },
                },
            }
        }
    }

    const F_FitDataToChartData=()=>{
        let { compInfo, auxList } = props;
        let { _displayInfo } = getData(compInfo.bdDevInvolve_List, fullList);

        _displayInfo.barTitle = auxList.find(c => c.auxId === 'barTitle').auxValue;
        _displayInfo.axisTitle = `${auxList.find(c => c.auxId === 'axisName').auxValue} (${auxList.find(c => c.auxId === 'unit').auxValue})`;
        return _displayInfo;

    }

    const F_Update=()=>{        
        let _displayInfo = F_FitDataToChartData();
        let _chartData = genChartData(_displayInfo);

        setG_ChartData(_chartData);
        
        clearTimeout(updateDelayRef.current);
    }

    return ( 
        <div className='spring_dahsItemSize_400_600'>
            <div className='spring_dashItemFrame'>
                <DashItemTitleBar 
                    title={`C3-${props.compInfo.Name}[${props.compInfo._id}]`}                    
                    // onClickManu={()=>handleClickManu(123)}
                    />
                {/* <img src={req~uire(`./${G_groupInfo.imgName}`)} alt='Device Logo' width={100} height={100} /> */}
                
                {G_loaded && <div className=''>
                    <Bar
                        id={`MyBarChart_instant`}
                        // data={G_loaded? G_chartData : {datasets:[]}}
                        data={G_chartData}
                        plugins={[ChartDataLabels]}
                        options={options}
                    />
                </div>}
                {!G_loaded && <div style={{ position: "relative" }}>
                    <div className='divAbsolute'>
                        <div className='divRelative' style={{top:"80px", left:"280px"}}>
                            <PinwheelLoader style={{top:"900px"}}/>
                        </div>
                    </div>
                </div>}
            </div>
        </div>

    );
}

export default C0003BarInstant;