import React, { useState } from 'react';
import { IoIosArrowBack, IoIosAddCircle} from "react-icons/io";
import { ImCancelCircle} from "react-icons/im";
import TpInputNonTitle from './../../template/inputField/Input_nonTitle';
import { useEffect } from 'react';
import cssFn from '../../../function/CSS/cssFunction';
// import validateFn from '../../../function/validate/ValidateFn';
import { storeEmailList } from '../../../reduxStore/actions/dev_shareCfg';
import { useDispatch, useSelector } from 'react-redux';

function ShareAddEmailPg(props) {
    const dispatch = useDispatch();

    const rdx_shareCfgDetails = useSelector(state=> state.dev.shareSensor);

    const [G_maxReceiver] = useState(10);
    const [G_1stLoad, setG_1stLoad] = useState(true);
    const [G_emailList, setG_emailList] = useState([""]);
    const [G_validateErr, setG_validateErr] = useState(false);

    useEffect(()=>{
        async function startUp(){
            /** onli execute when page launch */
            if(G_1stLoad){
                if(rdx_shareCfgDetails.receiverEmail.length > 0){
                    setG_emailList(rdx_shareCfgDetails.receiverEmail);
                    setAddButtonColor(rdx_shareCfgDetails.receiverEmail, true);    /** update add button color */
                }
            }
            setG_1stLoad(false);
            writeEmailTable();
            
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, [G_emailList, G_1stLoad]);
    
    const writeEmailTable=()=>{
        for (let i = 0; i < G_emailList.length; i++) {
            document.getElementById(`emailInput_${i}`).value=G_emailList[i];
        }
        // document.getElementById(`emailInput_New`).value="";
    }

    const backPrevPg=()=>{
        // console.log("back");
        props.history.push({pathname: "/device/share"});
    }
    
    const handleAddEmail=()=>{
        let UiEmail = getInsertedEmail();
        // clearAllErrMsg(UiEmail.length);
        /** allow to add new row if last line is not empty */
        if(UiEmail.at(-1)!=="" && UiEmail.length < G_maxReceiver){
            UiEmail.push("");
        } 
        setG_emailList(UiEmail);
        cssFn.cssShowStatus(false, "addNewButton", 'cfgEmailAddButtonActive');
        if(UiEmail.length >= G_maxReceiver)    cssFn.cssShowStatus(false, "maxErrMsgId", 'divHidden');
    }
    

    const getInsertedEmail=()=>{
        let newEmailList =[];
        for (let i = 0; i < G_emailList.length; i++) {
            let email = document.getElementById(`emailInput_${i}`).value.trim();
            // if(email!=="") newEmailList.push(email);
            newEmailList.push(email);
        }
        
        return newEmailList;
        
    }

    const clearAllErrMsg=(arrLen)=>{
        for (let i = 0; i < arrLen; i++) {
            document.getElementById(`EmailErrId_${i}`).innerHTML=""     // clear error
        }
    }

    const validateEmailInput=(emailList)=>{
        // console.log("validateEmailInput");
        let UiEmail;
        if(emailList) UiEmail=emailList;
        else    UiEmail = getInsertedEmail();
        // console.log(UiEmail);
        /** check email format */
        let invalidFormat = false;
        for (let i = 0; i < UiEmail.length; i++) {
            // if(validateFn.validateEmail(UiEmail[i])===false){
            //     invalidFormat = true;
            //     document.getElementById(`EmailErrId_${i}`).innerHTML="Invalid Format"       // trigger error
            // }else{
                document.getElementById(`EmailErrId_${i}`).innerHTML=""     // clear error
            // }
        }

        /** check empty array */
        let emailEmpty = false;
        for (let i = 0; i < UiEmail.length; i++) {
            if(UiEmail[i].trim()===""){
                emailEmpty = true;
                document.getElementById(`EmailErrId_${i}`).innerHTML="E-mail cannot be empty"
            }else{
                // document.getElementById(`EmailErrId_${i}`).innerHTML=""     // clear error
            }
        }       

        /** check duplicated email */
        let duplicated = false;
        let arrBuffer=[];
        for (let i = 0; i < UiEmail.length; i++) {
            let found = arrBuffer.find(c=>c === UiEmail[i]);
            // console.log(found);
            if(!found) {
                arrBuffer.push(UiEmail[i]);
                // document.getElementById(`EmailErrId_${i}`).innerHTML=""     // clear error
                continue;
            }
            duplicated = true;
            document.getElementById(`EmailErrId_${i}`).innerHTML="Duplicated"       // trigger error
        }


        

        let validateErr = duplicated || emailEmpty || invalidFormat;
        setG_validateErr(validateErr);

        return validateErr;
    }

    const handleAdd=()=>{        
        let valErr = validateEmailInput();
        if(valErr) return
        /** Store into redux */
        let emailList = getInsertedEmail();
        dispatch(storeEmailList({receiverEmail:emailList}));
        /** return to previous page */
        props.history.push({pathname: "/device/share"});

    }

    const handleKeyDown=(event, ind)=>{
        if (event.key === 'Enter') {
            /** call off focus */
            document.getElementById(`emailInput_${ind}`).blur();
        }
    }

    const clearEmail=(ind)=>{
        /** save last email 1st */
        let insertedEmail = getInsertedEmail();
        clearAllErrMsg(insertedEmail.length);

        let _emailList = [...insertedEmail];
        if(_emailList.length < 2) {
            /** click clear on last item, clear content */
            document.getElementById(`emailInput_0`).value="";
            setG_emailList([""]);
            setAddButtonColor([""]);
            return;
        }
        _emailList.splice(ind, 1);  /** delete row */
        setG_emailList(_emailList); /** update to render */

        setAddButtonColor(_emailList, true);    /** update add button color */
        if(G_validateErr)   validateEmailInput(_emailList);
    }

    const setAddButtonColor=(emailArr, bUseArray)=>{       
        if(emailArr.length >= G_maxReceiver) {
            cssFn.cssShowStatus(false, "maxErrMsgId", 'divHidden');
            cssFn.cssShowStatus(false, "addNewButton", 'cfgEmailAddButtonActive');
            return
        }
        cssFn.cssShowStatus(true, "maxErrMsgId", 'divHidden');
        let idName = `emailInput_${emailArr.length-1}`;
        let lastRow;
        if  (bUseArray)lastRow = emailArr.at(-1);
        else    lastRow = document.getElementById(idName).value.trim();
        
        cssFn.cssShowStatus(lastRow !== "", "addNewButton", 'cfgEmailAddButtonActive');
        
    }

    const handleOnChange=()=>{
        setAddButtonColor(G_emailList);
    }
    

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Insert receiver username</div>
            </div>

            <div>
                {G_emailList.length > 0 && G_emailList.map((c,ind)=>
                    <div className='cfgAddEmailContainer' key ={`emailKey_${ind}`} >
                        <div className='cfgAddEmailFrontEnd'>{`${ind+1}.`}</div>
                        <div className='cfgAddEmailContent'>
                            <TpInputNonTitle 
                                // onBlur={handleOnBlur} 
                                id={`emailInput_${ind}`} 
                                errMsgid={`EmailErrId_${ind}`} 
                                maxLen={255}
                                onKeyDown={(event)=>handleKeyDown(event, ind)}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className='cfgAddEmailFrontEnd hoverPointer' 
                        onClick={()=>clearEmail(ind)}
                        style={{color:"var(--ErrColor)"}}>
                            <ImCancelCircle/>
                        </div>
                    </div>
                )}
            </div>

            <div style={{paddingTop:"15px"}} className='cfgAddEmailContainer' key ={`emailKey`} >
                <div className='cfgAddEmailFrontEnd divHidden'>{`${G_emailList.length+1}.`}</div>
                <div className='cfgMaxEmailMsg divHidden'
                id="maxErrMsgId">
                    {`*Maximun share to ${G_maxReceiver} users in one time`}
                </div>                
                <div className='cfgAddEmailFrontEnd'>
                    <div className='cfgAddEmailFrontEnd hoverPointer cfgEmailAddButtondeactive'  
                    id='addNewButton'
                    onClick={handleAddEmail}
                    style={{fontSize:'1.2rem'}}>
                        <IoIosAddCircle/>
                        {/* <ImCancelCircle/> */}
                    </div>
                </div>
            </div>

            
            <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" onClick={handleAdd}>Confirm</div>
            </div>
        </div>
    );
}

export default ShareAddEmailPg;