import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import LegoLoader from '../Version2a/Template/LoaderTemplate/legoLoader';
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import auth from '../../../service/auth/AuthV2';
import V2DataDownloadFn from '../../../function/V2_DataDownload/V2_DataDownloadFn';
import { TiDownload } from "react-icons/ti";
import { sortTemplate } from '../../../function/ArrayFn/arrayFn';
import { MdOutlineHistory } from "react-icons/md";
import timeFn from "../../../function/time/timeFn";
import { TbArrowBackUp } from "react-icons/tb";
import { toast } from 'react-toastify';

function DataDownload_TicketPg(props) {
    const [G_bLoaded, setG_bLoaded] = useState(false); 
    const [G_ticketList, setG_ticketList] = useState([]);
    // eslint-disable-next-line
    const [G_ticketHistoryList, setG_ticketHistoryList] = useState([]);
    const [G_displayList, setG_displayList] = useState([]);
    const [G_ShowHistory, setG_ShowHistory] = useState(0);
    const [G_bdInfo, setG_bdInfo] = useState();
    // const [G_isCooldown, setG_isCooldown] = useState(false);

    const refManuLevel = useRef(0);
    const timerRef = useRef(null);

    useEffect(() => {
        timerRef.current = setInterval(async () => {
            // console.log("Refresh");
            await refreshPg();
        }, 60000);       /** 60000 , 1 min*/

        async function startUp() {
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;

            await refreshPg();

            setG_bLoaded(true);
        }
        
        startUp();
        
        return ()=>{
            clearInterval(timerRef.current);
        }
    }, []);

    const refreshPg = async () => {
        let bdInfo  = localStorageFn.getBdToken();
        let userInfo = auth.getUserInfo();
        
        setG_bdInfo(bdInfo);

        let ticketList = await V2DataDownloadFn.getNotDownloadedTicket(userInfo.user_id, bdInfo._id);
        // console.log(ticketList);
        if(ticketList.length) {
            setG_ticketList(sortArr(ticketList));
            if(G_ShowHistory === 0) setG_displayList(sortArr(ticketList));
        } else {
            setG_ticketList([]);
            setG_displayList([]);
            clearInterval(timerRef.current);
        }
    }

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "unix", descOrder: true },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const backPrevPg=()=>{
        let pathname ="/data/bdoverview"
        if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const handleDownload = async (ticketInfo) => {
        try {
            // console.log(ticketInfo);
            // let bdInfo  = localStorageFn.getBdToken();
            // eslint-disable-next-line
            let zipFileName = ticketInfo.description? ticketInfo.description.replace(/[\\/:\*\?"<>|]/g, '_') : timeFn.convertUnixToFormattedDateTime_UTC8(ticketInfo.unix);
            
            let response = await V2DataDownloadFn.dataDownloadCsvZip(ticketInfo);
            // console.log(response);
            // Create a Blob object from the ZIP data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Create a link element and trigger a click event to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = zipFileName;
            link.click();

            await refreshPg();
        } catch (error) {
            toast.error("Download Error");
            console.log('Error downloading multiple CSV files:', error);
        }
    }

    const toDataDownload_main = () => {
        props.history.push({
            pathname: "/data/download",
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const showRequestHistory = async () => {
        setG_ShowHistory(1);
        setG_bLoaded(false);

        let bdInfo  = localStorageFn.getBdToken();
        let userInfo = auth.getUserInfo();
        let ticketHistoryList = await V2DataDownloadFn.getDownloadedTicket_lastN(userInfo.user_id, bdInfo._id, 20);
        setG_ticketHistoryList(sortArr(ticketHistoryList));
        setG_displayList(sortArr(ticketHistoryList));

        setG_bLoaded(true);
    }

    const showRequestList = () => {
        setG_ShowHistory(0);

        setG_displayList(G_ticketList);
    }

    // const handleRefreshBtn = async () => {
    //     // If cooldown is active, return early
    //     if (G_isCooldown) return;

    //     // Set cooldown to true
    //     setG_isCooldown(true);

    //     // Perform button click action
    //     console.log('Button clicked!');

    //     await refreshPg();

    //     // After a specified cooldown period, reset cooldown
    //     setTimeout(() => {
    //         setG_isCooldown(false);
    //     }, 30000); // Adjust the cooldown duration as needed (in milliseconds)
    // }

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Request List</div>
                {/* <div className='stayRight flexAndBothCenter headerIcon hoverPointer' onClick={handleRefreshBtn}>
                    <MdOutlineRefresh />
                </div> */}
            </div>

            {!G_bLoaded && <LegoLoader />}
            {G_bLoaded && <div>
                {G_ShowHistory === 0 && <div className="sortHor spring_ddTitleContainer spring_infoTitle">
                    <div className='boldText spring_width80per spring_marginLeft10 spring_marginRight5 spring_hideOverflowText'>{G_bdInfo.name}</div>
                    <div className="spring_ddIcon spring_shadowBox_Small stayRight spring_marginRight5 hoverPointer"
                        onClick={showRequestHistory}
                    ><MdOutlineHistory /></div>
                </div>}
                {G_ShowHistory === 1 && <div className="sortHor spring_ddTitleContainer spring_infoTitle">
                    <div className='boldText spring_width80per spring_marginLeft10 spring_hideOverflowText'>[History] {G_bdInfo.name}</div>
                    <div className="spring_ddIcon spring_shadowBox_Small stayRight spring_marginRight5 hoverPointer"
                        onClick={showRequestList}
                    ><TbArrowBackUp /></div>
                </div>}
                
                {G_ShowHistory === 0 && G_ticketList.length === 0 && <div className='spring_inputBox'>
                    <div className={`whiteText greenBgc spring_containerAddDev spring_addDevAllBorder hoverPointer`}
                    onClick={toDataDownload_main}> + Add download request</div>
                </div>}

                {G_displayList.length > 0 && <div className='spring_inputBox spring_listContainer'>
                    {G_displayList.map((u, ind) => (
                        <div key={ `ticket_${ind}`}>
                            {ind!==0 && <div className='divSeperateLine'></div>}
                            <div className='sortHor spring_MarginTopBtm_4px'>
                                <div className='flex_2 spring_smallText'>{timeFn.getDateTimeString_WithSeconds(u.unix)}</div>
                                <div className='flex_4 spring_marginLeft5 spring_marginRight5 spring_sceneVarValue spring_hideOverflowText'>
                                    {u.description? u.description : timeFn.convertUnixToFormattedDateTime_UTC8(u.unix)}
                                </div>
                                <div className='stayRight flexAndBothCenter'>
                                    {u.ready? <TiDownload className='hoverPointer greenText' onClick={() => handleDownload(u)} /> : <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>}
        </div>
    );
}

export default DataDownload_TicketPg;