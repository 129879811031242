import { toast } from "react-toastify";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";

const AC_BrandParaList=[
    {Brand:1, MaxFanSpeed:5, MaxSwingPos:5},
    {Brand:2, MaxFanSpeed:5, MaxSwingPos:5},
]

const F_getMaxFanSpeed=(brand)=>{
    let found = AC_BrandParaList.find(c=>c.Brand === brand);
    if(!found) return 5;
    if(notEmptyArr(found)) return 5;    // default value
    if(Object.hasOwnProperty.call(found, "MaxFanSpeed")){
        return found.MaxFanSpeed;
    }
    return 5;
}
const F_getMaxSwingPos=(brand)=>{
    let found = AC_BrandParaList.find(c=>c.Brand === brand);    
    if(!found) return 5;
    if(notEmptyArr(found)) return 5;    // default value
    if(Object.hasOwnProperty.call(found, "MaxSwingPos")){
        return found.MaxSwingPos;
    }
    return 5;
}

const F_GetInputVal_Number=(eleId)=>{
    if(!document.getElementById(eleId)) return null;
    let nVal = document.getElementById(eleId).value.trim();
    if(nVal===""){
      return null
    }else {
      return nVal;
    }
  }
  
  const F_SetInputVal_Number=(sName, value)=>{
    if(!document.getElementById(sName)) return 
    document.getElementById(sName).value = value;
  }


  const F_GetDefaultFilterDuration=()=>{
    // return 720000;   // 200h
    return 1080000;   // 300h
  }


  const MR_SerialList = [
    { display: "5N1", value: 134217744 },
    { display: "6N1", value: 134217748 },
    { display: "7N1", value: 134217752 },
    { display: "8N1", value: 134217756 },
    { display: "5N2", value: 134217776 },
    { display: "6N2", value: 134217780 },
    { display: "7N2", value: 134217784 },
    { display: "8N2", value: 134217788 },
    { display: "5E1", value: 134217746 },
    { display: "6E1", value: 134217750 },
    { display: "7E1", value: 134217754 },
    { display: "8E1", value: 134217758 },
    { display: "5E2", value: 134217778 },
    { display: "6E2", value: 134217782 },
    { display: "7E2", value: 134217786 },
    { display: "8E2", value: 134217790 },
    { display: "5O1", value: 134217747 },
    { display: "6O1", value: 134217751 },
    { display: "7O1", value: 134217755 },
    { display: "8O1", value: 134217759 },
    { display: "5O2", value: 134217779 },
    { display: "6O2", value: 134217783 },
    { display: "7O2", value: 134217787 },
    { display: "8O2", value: 134217791 }
];

const F_getSerialInfoFromValue = (value) => {
  let found = MR_SerialList.find(c => c.value === value);
  if(found) return found;
  else return toast("Wrong Serial Value");
}

const _F_getMaxFanSpeed = F_getMaxFanSpeed;
export { _F_getMaxFanSpeed as F_getMaxFanSpeed };
const _F_getMaxSwingPos = F_getMaxSwingPos;
export { _F_getMaxSwingPos as F_getMaxSwingPos };
const _F_GetInputVal_Number = F_GetInputVal_Number;
export { _F_GetInputVal_Number as F_GetInputVal_Number };
const _F_SetInputVal_Number = F_SetInputVal_Number;
export { _F_SetInputVal_Number as F_SetInputVal_Number };
const _F_GetDefaultFilterDuration = F_GetDefaultFilterDuration;
export { _F_GetDefaultFilterDuration as F_GetDefaultFilterDuration };
const _F_getSerialInfoFromValue = F_getSerialInfoFromValue;
export { _F_getSerialInfoFromValue as F_getSerialInfoFromValue };