import timeFn from '../../../../component/utilities/timeFn';
import { isEmptyArr } from '../../../../function/ArrayFn/arrayFn';

function determineDevOffline(unixDev){
    if(!unixDev) return true;
    let _unixNow = timeFn.getUnixNow();
    return (_unixNow - unixDev > 600);  // 10 mins
}


function determineDevWeakSignal(SNR, RSSI){
    // return (RSSI < -10 && SNR < 11) 
    // return (RSSI < -100 && SNR < 3) 
    return (SNR < 2) 
}

function determineDevLowBatt(sensorInfo, devLastData){
    let {battVolt} = devLastData;
    // console.log("devLastData", devLastData);
    if(!battVolt) return false;
    return battVolt < 2.9;
    // if(sensorInfo.type === 1 || sensorInfo.type === 2){
    //     // return battVolt < 4;
    //     return battVolt < 3.6;
    // }else{
    //     return battVolt < 3.3;
    // }
}

function determineDevState(floorDev, lastDataGroup, sensorTyList){
    let areaOfflineGroup = [];
    for (const eachFloor of floorDev) {
        let offlineCnt = 0;
        let WeakSignalCnt = 0;
        let lowBattCnt = 0;
        for (const eachDev of eachFloor.devsInFloor) {
            let found = lastDataGroup.find(c=> c.type === eachDev.type && c.bdDev_id===eachDev._id);
            
            /** get offline here */
            if(!found || eachDev.devID===0) {
                offlineCnt+=1;
                continue;
            }

            /** get offline here */
            if(determineDevOffline(found.unix)) offlineCnt+=1;                
            
            /** get weak signal here */
            if(determineDevWeakSignal(found.SNR, found.RSSI)) WeakSignalCnt +=1;                    
            
            /** get low batt here */
            let curSenInfo = sensorTyList.find(c=>c.type === eachDev.type);
            if(!curSenInfo) continue;   // in case sensor list not found
            if(determineDevLowBatt(curSenInfo,found)) lowBattCnt++;
        }
        let {floorName, floor_id} = eachFloor;
        areaOfflineGroup.push({floorName, floor_id, offlineCnt, WeakSignalCnt, lowBattCnt});            
    }
    return areaOfflineGroup;
}

function F_GetDevErrStateCnt(errType ,_StateEachFloor, curFloor){
    if(isEmptyArr(_StateEachFloor)) return 0

    let selFloorState = _StateEachFloor.find(c=> c.floor_id === curFloor.floor_id && c.floorName === curFloor.floorName)
    if(!selFloorState) return 0;
    let errStateCnt = 0;
    if(errType===1){    // batt
        errStateCnt = selFloorState.lowBattCnt;
    }else if(errType===2){      // weak signal
        errStateCnt = selFloorState.WeakSignalCnt;
    }else if(errType===3){      // offline
        errStateCnt = selFloorState.offlineCnt;
    }
    if(!errStateCnt) return 0
    return errStateCnt;
}

const expModule = {
    determineDevOffline,
    determineDevWeakSignal,
    determineDevLowBatt,
    determineDevState,
    F_GetDevErrStateCnt,
};
export default expModule;