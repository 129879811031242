import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import TpDiaInsertString from '../../../../Control/Common/Dialog/Input/TpDiaInsertString';
import { toast } from 'react-toastify';
// import TpDiaGatewayID from '../../../../Control/Common/Dialog/Input/TpDiaGatewayID';
import TpDiaChooseAcBrand from './TpDialog/TpDiaChooseAcBrand';
// import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import v2RjFn from "../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import v2OwnDevFn from '../../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
// import { notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import TpSubSubDiaVarChooseDev from './TpDialog/TpSubSubDiaVarChooseDev';
import { GrRevert } from "react-icons/gr";

const TabSettingEdit=forwardRef((props, ref)=> {
    useImperativeHandle(ref, () => ({
        discard() {
            setG_SettingDisplay(G_SettingDB);
            setG_PairRtrh_Display(G_PairRtrh_DB);
        },
        update() {
            if(props.confirmChange) props.confirmChange(G_SettingDisplay, G_PairRtrh_Display);
        },
        ovewriteDb() {
            setG_SettingDB(G_SettingDisplay);
        },
        async savePairRtrhToDb() {
            let pairRtrhInfo=[
                {
                    varName:`Pair temp.`, 
                    varIdx:0,
                    Var_bdDevId:G_PairRtrh_Display._id,
                    sensorType:G_PairRtrh_Display.ht,
                    dataType:2,
                    dataIndex:0,
                },
                {
                    varName:`Pair humid.`, 
                    varIdx:1,
                    Var_bdDevId:G_PairRtrh_Display._id,
                    sensorType:G_PairRtrh_Display.ht,
                    dataType:2,
                    dataIndex:1,
                },
            ]
            await v2RjFn.V2_RjUpdatePairRtrh(props.dev._id, pairRtrhInfo);
        },
    }));

    const C_DefaultPara={
        nBrand:0,
        sBrandName:"",
        sensorLink : 0,     // 0 = local
        upperBand : 0.1,
        lowerBand : 0.2,
        coolingStrength:5,
        heatingStrength:1,
        rapidPulse:1,
    }
    const C_PairRtrhEmpty={
        Name:"",
        ht:0,
        hi:0,
        _id:0,
    }
    
    const [G_InputPg, setG_InputPg] = useState(0);

    const [G_SettingDisplay, setG_SettingDisplay] = useState({});
    const [G_SettingDB, setG_SettingDB] = useState({});
    const [G_SelSettingKey, setG_SelSettingKey] = useState("");
    const [G_SelSettingName, setG_SelSettingName] = useState("");
    // const [G_GwID, setG_GwID] = useState(0);
    const [G_DevsInBd, setG_DevsInBd] = useState([]);
    const [G_PairRtrh_Display, setG_PairRtrh_Display] = useState([]);
    const [G_PairRtrh_DB, setG_PairRtrh_DB] = useState([]);
    const [G_bLoaded, setG_bLoaded] = useState(false);
    // const [G_SoftwareVer, setG_SoftwareVer] = useState(1.00);


    useEffect(()=>{
        async function startUp(){
            // await GW_loadGwPair(props.dev._id);
            let _AcList = await v2RjFn.V2_RjGetAcBrand();
            
            if(props.lastData){
                if(props.lastData.pi_0){        // got valid last data
                    let nBrand = props.lastData.pi_0;
                    let found = _AcList.find(c=>c.nBrand === nBrand);
                    let sBrandName="";
                    if(found) sBrandName=found.name;
    
                    let _SettingPara={
                        nBrand,
                        sBrandName,
                        sensorLink : props.lastData.pb_4,     // 0 = local
                        upperBand : props.lastData.pf_8,
                        lowerBand : props.lastData.pf_9,
                        coolingStrength:props.lastData.pi_3,
                        heatingStrength:props.lastData.pi_4,
                        rapidPulse:props.lastData.pn_0,
                    }
                    // setG_SoftwareVer(props.lastData.pf_6);
                    setG_SettingDisplay(_SettingPara);
                    setG_SettingDB(_SettingPara);
                }else{      // no data send to server before
                    setG_SettingDisplay(C_DefaultPara);
                }
            }

            /** get rtrh in building */
            let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(props.dev.buidling_id);
            let rtrhInBd = devInBd.filter(c=>c.type === 42 || c.type === 23 || c.type === 1);
            setG_DevsInBd(rtrhInBd);

            /** load pair sensor value */
            let onlineVar = await getOnlineVar(props.dev._id);
            let rtrhPair = onlineVar.find(c=>c.varIdx ===0);
            /** get pair sensor info */
            if(rtrhInBd.length > 0 && rtrhPair){
                let pairRtrh = rtrhInBd.find(c=>c._id === rtrhPair.Var_bdDevId);
                if(!pairRtrh){      // not pairing 
                    setG_PairRtrh_Display(C_PairRtrhEmpty);
                    setG_PairRtrh_DB(C_PairRtrhEmpty);
                }else{
                    let pairInfo = {
                        Name:pairRtrh.name,
                        ht:pairRtrh.type,
                        hi:pairRtrh.devID,
                        _id:pairRtrh._id,
                    }                
                    setG_PairRtrh_Display(pairInfo);
                    setG_PairRtrh_DB(pairInfo);
                }
            }else{
                setG_PairRtrh_Display(C_PairRtrhEmpty);
                setG_PairRtrh_DB(C_PairRtrhEmpty);
            }
            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("Page Close");
        }
        // eslint-disable-next-line
    }, []);    

    const getOnlineVar=async(_id)=>{
        let onlineVar = await v2RjFn.getV2_RjOnlineVar(_id);
        return onlineVar;
    }

    const triggerChanges=()=>{
        if(props.settingChanges) props.settingChanges();
    }

    // const GW_loadGwPair=async (bdDev_id)=>{
    //     try { 
    //         let pairedGw = await v2ctrlFn.getV2_GwPair(bdDev_id);
    //         if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    //         let {gwPairList} = pairedGw;
    //         if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
    //         setG_GwID(gwPairList[0].gwid);
    //     } catch (error) {
    //         toast("loadGwPair err: ", error.message);
    //     }
    // }
    

    const fCloseInputPage=()=>{
        setG_InputPg(0);
    }
    const fCallInputPage=(nPg)=>{
        /** call choose action page */
        setG_InputPg(nPg);
    }

    const handleChangeValue=(Val)=>{
        if(!G_SelSettingKey || G_SelSettingKey.trim()==="") return toast("Variable Key Error");
        let _SettingDisplay = {...G_SettingDisplay};
        let _val = parseFloat(Val);
        if(G_SelSettingKey==="coolingStrength" || G_SelSettingKey==="heatingStrength" ){
            _val = parseInt(Val);
        }
        if(isNaN(_val)) _val = 0;
        _SettingDisplay[G_SelSettingKey] = _val;
        setG_SettingDisplay(_SettingDisplay);
        fCloseInputPage();
        triggerChanges();
    }
    
    const F_ToInputPage=(sName)=>{
        let key = "";
        if(sName === "Upper Band") key = "upperBand";
        else if(sName === "Lower Band") key = "lowerBand";
        else if(sName === "Cooling strength") key = "coolingStrength";
        else if(sName === "Heating strength") key = "heatingStrength";

        setG_SelSettingKey(key);
        setG_SelSettingName(sName);
        setG_InputPg(3);
    }

    const handleSelAc=(selAc)=>{
        let _SettingDisplay = {...G_SettingDisplay};
        _SettingDisplay.sBrandName = selAc.name;
        _SettingDisplay.nBrand = selAc.nBrand;
        setG_SettingDisplay(_SettingDisplay);
        fCloseInputPage();
        triggerChanges();
    }

    const fToggleSensorLink=()=>{
        let _SettingDisplay = {...G_SettingDisplay};
        _SettingDisplay.sensorLink++;
        if(_SettingDisplay.sensorLink > 1) _SettingDisplay.sensorLink =0;        
        setG_SettingDisplay(_SettingDisplay);
        triggerChanges();
    }
    const fToggleRapidPulse=()=>{
        let _SettingDisplay = {...G_SettingDisplay};
        _SettingDisplay.rapidPulse++;
        if(_SettingDisplay.rapidPulse > 3) _SettingDisplay.rapidPulse =1;        
        setG_SettingDisplay(_SettingDisplay);
        triggerChanges();
    }

    

    // const handleSetGwDisplay=(gwid)=>{
    //     setG_GwID(gwid);
    //     /** set GwId on global page */        
    //     if(props.fSetGw_id)     props.fSetGw_id(gwid);
    // }
    
    const handleChooseDev=(devInfo)=>{
        setG_PairRtrh_Display({
            Name:devInfo.name,
            ht:devInfo.type,
            hi:devInfo.devID,
            _id:devInfo._id,
        });
        fCloseInputPage();
        triggerChanges();
    }
    
    const fBackToMoreManu=()=>{
        if(props.fToTab) props.fToTab(31);
        if(props.discardChanges) props.discardChanges();
    }


    return (
        <div className='spring_editScenePg'>
            {G_InputPg===1 && <TpDiaChooseAcBrand
                onclickClose={fCloseInputPage}
                confirmChange={handleSelAc}
                lastData={props.lastData}
                />}
            
            {/* {G_InputPg===11 && <TpDiaGatewayID 
                divInfo = {props.dev} 
                gwPair = {G_GwID} 
                onClickDiaClose = {fCloseInputPage}
                setGwId = {handleSetGwDisplay}
            />} */}
            
            {G_InputPg ===3 && <TpDiaInsertString 
                oldString={G_SelSettingName}
                onclickClose={fCloseInputPage}
                middleText="SET TO"
                isNumber={true}
                confirmChange={handleChangeValue}/>}
                
            {G_InputPg ===4 && <TpSubSubDiaVarChooseDev
                devList = {G_DevsInBd}
                onclickClose={fCloseInputPage}
                chooseDev={handleChooseDev}
                />}
            {!G_bLoaded && <div className='spring_RjEmptyVarList sortVer'>
                <div>Loading...</div>
            </div>}
            {G_bLoaded && <div>

                <div className='sortHor' style={{marginBottom:"5px"}}>
                    <div className="spring_ManuTitle">Air-Con & Control</div>
                    <div className='spring_ManuTitleBackIcon stayRight hoverPointer'
                    onClick={fBackToMoreManu}>
                        <GrRevert/>
                    </div>
                </div>

                <div className='spring_RjSettingGroupTitle'>Air-Con</div>
                <div className='spring_editScheGroup'>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Brand:</div>
                        <div className='spring_editRightItem  blueText hoverPointer ' 
                        onClick={()=>fCallInputPage(1)}>
                            {G_SettingDisplay.sBrandName ===""? 
                                <div>{"< Choose Brand >"}</div>:
                                <div>{G_SettingDisplay.sBrandName}</div>}
                        </div>
                    </div>
                    <div className='spring_editItemBar sortHor divCollapse'>
                        <div className='spring_editLeftTitle'>Sensor link:</div>
                        <div className='spring_editRightItem  blueText hoverPointer' 
                        onClick={fToggleSensorLink}
                        >
                            { (G_SettingDisplay.sensorLink ===0)?
                                <div>Local</div>:
                                <div>Online</div>}
                        </div>
                    </div>         
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Pulse Repeat:</div>
                        <div className='spring_editRightItem  blueText hoverPointer' 
                        onClick={fToggleRapidPulse}
                        >
                            {G_SettingDisplay.rapidPulse}
                            {/* { (G_SettingDisplay.sensorLink ===0)?
                                <div>Local</div>:
                                <div>Online</div>} */}
                        </div>
                    </div>            
                </div>

                {/* <div className='spring_editScheGroup'>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Gateway ID:</div>
                        <div className='spring_editRightItem  blueText hoverPointer'
                        onClick={()=>fCallInputPage(11)}>
                            { (G_GwID>0)?
                                <div>{G_GwID}</div>:
                                <div>{"< Insert Gateway ID >"}</div>}
                            </div>
                    </div>            
                </div> */}

                <div className='spring_RjSettingGroupTitle'>i-Control</div>
                <div className='spring_editScheGroup'>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Pair Sensor:</div>
                        <div className='spring_editRightItem  blueText hoverPointer'
                        onClick={()=>fCallInputPage(4)}
                        >
                            {G_PairRtrh_Display.Name!=="" ?
                                <div>{`${G_PairRtrh_Display.Name}`}</div>:
                                <div>{`< Choose Sensor >`}</div>}
                        </div>
                    </div>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Upper Band:</div>
                        <div className='spring_editRightItem  blueText hoverPointer'
                        onClick={()=>F_ToInputPage("Upper Band")}>
                            {G_SettingDisplay.upperBand}
                        </div>
                    </div>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Lower Band:</div>
                        <div className='spring_editRightItem  blueText hoverPointer ' 
                        onClick={()=>F_ToInputPage("Lower Band")}>
                            {G_SettingDisplay.lowerBand}
                        </div>
                    </div>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Cooling Power:</div>
                        <div className='spring_editRightItem  blueText hoverPointer ' 
                        onClick={()=>F_ToInputPage("Cooling strength")}>
                            {G_SettingDisplay.coolingStrength}
                        </div>
                    </div>         
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Heating Power:</div>
                        <div className='spring_editRightItem  blueText hoverPointer ' 
                        onClick={()=>F_ToInputPage("Heating strength")}>
                            {G_SettingDisplay.heatingStrength}
                        </div>
                    </div>            
                </div>

                {/* <div className='spring_RjSettingGroupTitle'>Advance</div>
                <div className='spring_editScheGroup'>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Feedback Sensor</div>
                        <div className='spring_editRightItem hoverPointer' 
                        onClick={fToFeedbackTab}>
                            <div>{G_SoftwareVer}</div>
                            </div>
                    </div>          
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Infrared Hz</div>
                        <div className='spring_editRightItem'>
                            <div>{G_SoftwareVer}</div>
                            </div>
                    </div>      
                </div> */}

                {/* <div className='spring_RjSettingGroupTitle'>About</div>
                <div className='spring_editScheGroup'>
                    <div className='spring_editItemBar sortHor'>
                        <div className='spring_editLeftTitle'>Software Version:</div>
                        <div className='spring_editRightItem'>
                            <div>{G_SoftwareVer}</div>
                        </div>
                    </div>            
                </div> */}
            </div>}


        </div>
    );
});

export default TabSettingEdit;