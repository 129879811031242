import React from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import './App.css';
import { ToastContainer } from "react-toastify";

// -----------redux -----------------
import cfgStore from './reduxStore/configureStore';
import {Provider} from 'react-redux';
//==========redux end===============

import ViewData_OverviewPg from './g2_Component/Page/viewData_Overview/ViewData_OverviewPg';
import WarningMsg from './g2_Component/Page/PopUpMessage.jsx/WarningMessagePg';
import BuildingOverviewPg from './g2_Component/Page/BuildingOverviewPg/BuildingOverviewPg';
import ChooseDevicePg from './g2_Component/Page/AddDevice/ChooseDevice';
import ChooseBuildingPg from './g2_Component/Page/AddDevice/ChooseBuildingPg';
import AddNewBuilding from './g2_Component/Page/AddDevice/AddNewBuilding';
import ChooseArea from './g2_Component/Page/AddDevice/ChooseArea';
import AddNewArea from './g2_Component/Page/AddDevice/AddNewArea';
import SingleSensor from './g2_Component/Page/SensorInBd/SingleSensor';
import SensorInBdSettingPg from './g2_Component/Page/SensorInBd/SensorInBdSettingPg'; 
import UserRegistration from "./g2_Component/Page/User/UserRegistration_Hook";
import UserForgetPassword from './g2_Component/Page/User/UserForgetPassword';
import UserResendValidationLink from './g2_Component/Page/User/UserResendValidationLink';
import UserEmailValidation from './g2_Component/Page/User/UserEmailValidation_Hook';
import EmailSuccess from './g2_Component/Page/User/EmailSuccess_Hook';
import UserLogin from "./g2_Component/Page/User/UserLogin_Hook";
import ResetPasswordPage from './g2_Component/Page/User/ResetPasswordPage';
import ChangePasswordSuccess from './g2_Component/Page/User/changePasswordSuccess';
import TempLogIn from './g2_Component/Page/tempLogin/TempLogIn';
import TpV2protectedRoute from './g2_Component/template/route/TpV2protectedRoute';
import ShareDevicePg from './g2_Component/Page/ShareDevice/ShareDevicePg';
import ShareSelBuildingPg from './g2_Component/Page/ShareDevice/ShareSelBuildingPg';
import ShareAddEmailPg from './g2_Component/Page/ShareDevice/ShareAddEmailPg';
import EditSharePage from './g2_Component/Page/ShareDevice/EditSharePage';
import ChooseBdOrUserPage from './g2_Component/Page/ShareDevice/ChooseBdOrUserPage';
import Choose2ndPara from './g2_Component/Page/ShareDevice/Choose2ndPara';
// import AddReactionPg from './g2_Component/Page/Reaction/AddReactionPg';
// import AddReactionPg_Condi from './g2_Component/Page/Reaction/AddReactionPg_Condi';
// import AddReactionPg_Condi_selOp from './g2_Component/Page/Reaction/AddReactionPg_Condi_selOp';
// import AddReactionPg_Condi_selSensor from './g2_Component/Page/Reaction/AddReactionPg_Condi_selSensor';
// import AddReactionPg_Condi_selPara from './g2_Component/Page/Reaction/AddReactionPg_Condi_selPara';
// import AddReactionPg_Condi_AddFormula from './g2_Component/Page/Reaction/AddReactionPg_Condi_AddFormula';
// import AddReactionPg_Condi_LoadFormula from './g2_Component/Page/Reaction/AddReactionPg_Condi_LoadFormula';
// import AddAlgoPg from './g2_Component/Page/Reaction/AddAlgoPg';
// import AddAlgoPg_Edit from './g2_Component/Page/Reaction/AddAlgoPg_Edit';
// import AddActionPg from './g2_Component/Page/Reaction/AddActionPg';
// import AddActionPg_ActionList from './g2_Component/Page/Reaction/AddActionPg_ActionList';
// import AddActionPg_ActionList_Tele from './g2_Component/Page/Reaction/AddActionPg_ActionList_Tele';
// import AddActionPg_ActionList_ChooseSub from './g2_Component/Page/Reaction/AddActionPg_ActionList_ChooseSub';
// import AddActionPg_ActionList_NewSubsriber from './g2_Component/Page/Reaction/AddActionPg_ActionList_NewSubsriber';
// import AddActionPg_ActionList_NewGroup from './g2_Component/Page/Reaction/AddActionPg_ActionList_NewGroup';
// import AddActionPg_ActionList_NewGroup_ChooseContact from './g2_Component/Page/Reaction/AddActionPg_ActionList_NewGroup_ChooseContact';
// import AddActionPg_DefaultSubList from './g2_Component/Page/Reaction/AddActionPg_DefaultSubList';
// import AddTriggerPattern from './g2_Component/Page/Reaction/AddTriggerPattern';
// import AddTriggerPattern_ChoosePattern from './g2_Component/Page/Reaction/AddTriggerPattern_ChoosePattern';
// import EditEvent from './g2_Component/Page/Reaction/editEvent';
import SensorInBdGraphPg from './g2_Component/Page/SensorInBd/SensorInBdGraphPg';
import ShareDevPg_V02 from './g2_Component/Page/ShareDevice_V02/ShareDevPg_V02';
import ShareAddEmailPgV_02 from './g2_Component/Page/ShareDevice_V02/ShareAddEmailPgV_02';
import ShareUserEditPg_V02 from './g2_Component/Page/ShareDevice_V02/ShareUserEditPg_V02';
import ControlTemplate from './g2_Component/Page/Control/ControlTemplate';
import PgCondensorLoop from './g2_Component/Page/Control/Application/CondensorLoop/PgCondensorLoop';
import V2aFloorInBd from './g2_Component/Page/Version2a/DeviceView/V2aFloorInBd';
import V2aDeviceInFloor from './g2_Component/Page/Version2a/DeviceView/V2aDeviceInFloor';
import V2aAddDevices from './g2_Component/Page/Version2a/v2aMenu/AddDevice/V2aAddDevices';
import ChooseFloor from './g2_Component/Page/AddDevice/ChooseFloor';
import AddNewFloor from './g2_Component/Page/AddDevice/AddNewFloor';
import V2aSortFloor from './g2_Component/Page/Version2a/v2aMenu/Sort/V2aSortFloor';
import V2aSortArea from './g2_Component/Page/Version2a/v2aMenu/Sort/V2aSortArea';
import V2aRelocateDevice from './g2_Component/Page/Version2a/v2aMenu/ManageDevice/V2aRelocateDevice';
import ChangePassword from './g2_Component/Page/Version2a/v2aMenu/User/changePassword';
import V2aEditShareDevice from './g2_Component/Page/ShareDevice/V2aEditShareDevice';
import TpCompareGraph from './g2_Component/Page/Version2a/Template/FeatureManu/TpCompareGraph';
import DashBoardPg from './g2_Component/Page/Dashboard/DashBoardPg';
import HisDataSensorPg from './g2_Component/Page/HistoryData/HisData_Sensor';
import DataDownload_main from './g2_Component/Page/DataDownload/dataDownload_main';
import DataDownload_checkout from './g2_Component/Page/DataDownload/dataDownload_checkout';
import DataDownload_TicketPg from './g2_Component/Page/DataDownload/dataDownload_TicketPg';


const store = cfgStore();

function App() {
  return (
    <Provider store={store}>
      <div className="mainContent">
        <Switch>
          {/* <TpV2protectedRoute path="/reaction/action/aclist/newgroup/choosecontact" component={AddActionPg_ActionList_NewGroup_ChooseContact} />
        
          <TpV2protectedRoute path="/reaction/add/condi/chooseop" component={AddReactionPg_Condi_selOp} />
          <TpV2protectedRoute path="/reaction/add/condi/selsensor" component={AddReactionPg_Condi_selSensor} />
          <TpV2protectedRoute path="/reaction/add/condi/selpara" component={AddReactionPg_Condi_selPara} />
          <TpV2protectedRoute path="/reaction/add/condi/addformula" component={AddReactionPg_Condi_AddFormula} />
          <TpV2protectedRoute path="/reaction/add/condi/loadformula" component={AddReactionPg_Condi_LoadFormula} />
          <TpV2protectedRoute path="/reaction/action/aclist/tele" component={AddActionPg_ActionList_Tele} />
          <TpV2protectedRoute path="/reaction/action/aclist/choosesub" component={AddActionPg_ActionList_ChooseSub} />
          <TpV2protectedRoute path="/reaction/action/aclist/newsub" component={AddActionPg_ActionList_NewSubsriber} />
          <TpV2protectedRoute path="/reaction/action/aclist/newgroup" component={AddActionPg_ActionList_NewGroup} /> */}
          
          

          {/* <TpV2protectedRoute path="/data/bdoverview/setting" component={BdOverviewSettingPg} /> */}
          <TpV2protectedRoute path="/data/sensorinbd/setting" component={SensorInBdSettingPg} />
          <TpV2protectedRoute path="/data/sensorinbd/graph" component={SensorInBdGraphPg} />

          <TpV2protectedRoute path="/data/download/checkout" component={DataDownload_checkout} />
          <TpV2protectedRoute path="/data/download/tickets" component={DataDownload_TicketPg} />

          <TpV2protectedRoute path="/device/add/addnewbd" component={AddNewBuilding} />    
          <TpV2protectedRoute path="/device/add/addnewfloor" component={AddNewFloor} />    
          <TpV2protectedRoute path="/device/add/addnewarea" component={AddNewArea} />    
          
          
          <TpV2protectedRoute path="/device/share/selbd" component={ShareSelBuildingPg} />
          <TpV2protectedRoute path="/device/share/addemail" component={ShareAddEmailPg} />
          <TpV2protectedRoute path="/device/editshare/choose" component={ChooseBdOrUserPage} />
          <TpV2protectedRoute path="/device/editshare/choose2nd" component={Choose2ndPara} />
          <TpV2protectedRoute path="/device/share/edit" component={V2aEditShareDevice} />
          
          {/* <TpV2protectedRoute path="/reaction/add/condi" component={AddReactionPg_Condi} />    
          <TpV2protectedRoute path="/reaction/algo/edit" component={AddAlgoPg_Edit} />
          <TpV2protectedRoute path="/reaction/action/aclist" component={AddActionPg_ActionList} />
          <TpV2protectedRoute path="/reaction/trigpattern/choosetrig" component={AddTriggerPattern_ChoosePattern} />           */}
          <TpV2protectedRoute path="/device/sharev2/addemail" component={ShareAddEmailPgV_02} />  

          {/* <TpV2protectedRoute path="/data/devoverview/v2amenu" component={V2aMenuPage} />  
          <TpV2protectedRoute path="/data/devoverview/v2amonmenu" component={V2aMonMenuPage} />  
          <TpV2protectedRoute path="/data/devoverview/v2asubmonmenu" component={V2aSubMonMenuPage} />   */}
          
          
          
                   
          
          
          <TpV2protectedRoute path="/data/devoverview" component={ViewData_OverviewPg} />
          {/* <TpV2protectedRoute path="/data/sensorinbd" component={SensorInBd} /> */}
          <TpV2protectedRoute path="/data/deviceinfloor" component={V2aDeviceInFloor} />
          
          <TpV2protectedRoute path="/data/floorinbd" component={V2aFloorInBd} />
          <TpV2protectedRoute path="/data/download" component={DataDownload_main} />
          {/* <Route path="/data/sensorinbd" component={SensorInBd} /> */}
          <TpV2protectedRoute path="/device/singlesensor" component={SingleSensor} />
          <TpV2protectedRoute path="/device/share" component={ShareDevicePg} />
          <TpV2protectedRoute path="/device/editshare" component={EditSharePage} />          
          <TpV2protectedRoute path="/warning/usernotactive" component={WarningMsg} />     
          <TpV2protectedRoute path="/data/bdoverview" component={BuildingOverviewPg} />      
          <TpV2protectedRoute path="/device/choosedev" component={ChooseDevicePg} />   
          <TpV2protectedRoute path="/device/choosebd" component={ChooseBuildingPg} />   
          <TpV2protectedRoute path="/device/choosefloor" component={ChooseFloor} />   
          <TpV2protectedRoute path="/device/choosearea" component={ChooseArea} />   
          {/* <TpV2protectedRoute path="/device/add" component={AddDevicePg} /> */}
          <TpV2protectedRoute path="/device/add" component={V2aAddDevices} />
          {/* <TpV2protectedRoute path="/reaction/add" component={AddReactionPg} />                
          <TpV2protectedRoute path="/reaction/algo" component={AddAlgoPg} />                
          <TpV2protectedRoute path="/reaction/action" component={AddActionPg} />                
          <TpV2protectedRoute path="/reaction/bddefaultsub" component={AddActionPg_DefaultSubList} />                
          <TpV2protectedRoute path="/reaction/trigpattern" component={AddTriggerPattern} /> 
          <TpV2protectedRoute path="/reaction/edit" component={EditEvent} />   */}
          <TpV2protectedRoute path="/device/sharev2" component={ShareDevPg_V02} />  
          <TpV2protectedRoute path="/device/editsharev2" component={ShareUserEditPg_V02} />  
          <TpV2protectedRoute path="/ctrl/template" component={ControlTemplate} />  
          <TpV2protectedRoute path="/ctrl/condensorloop" component={PgCondensorLoop} />  
          <TpV2protectedRoute path="/bd/sortfloor" component={V2aSortFloor} />    
          <TpV2protectedRoute path="/bd/sortarea" component={V2aSortArea} />    
          <TpV2protectedRoute path="/device/relocate" component={V2aRelocateDevice} />    
          <TpV2protectedRoute path="/user/changepw" component={ChangePassword} /> 
          <TpV2protectedRoute path="/graph/compare" component={TpCompareGraph} />
          <TpV2protectedRoute path="/dashboard/main" component={DashBoardPg} />
          <TpV2protectedRoute path="/hisdata/sensor" component={HisDataSensorPg} />
          
          
          

          <Route path="/user/register" component={UserRegistration} />
          <Route path="/user/login" component={UserLogin} />
          <Route path="/user/forgetpassword" component={UserForgetPassword} />
          <Route path="/register/validation/:actToken" component={UserEmailValidation} />
          <Route path="/user/validationlink" component={UserResendValidationLink} />
          <Route path="/email/success" component={EmailSuccess} />
          <Route path="/user/resetpw/:resetToken" component={ResetPasswordPage} />
          <Route path="/pwsuccess" component={ChangePasswordSuccess} />
          
          
          <Route path="/templogin" component={TempLogIn} /> {/* temporary use */}
          

          <Redirect to="/data/bdoverview" />
        </Switch>
      </div>
    <ToastContainer />
    </Provider>
  );
}

export default App;


