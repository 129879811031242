import React, { useEffect } from 'react';

function TpDiaChooseScheAction(props) {

    useEffect(()=>{
        async function startUp(){

        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(opeIdx)=>{
        if(props.confirmChange) {
            props.confirmChange(opeIdx);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_ruleVarListContainer'>
                        <div className='spring_opeContainer' style={{paddingTop:"20px"}}>
                            <div className='spring_scheActionBox hoverPointer' onClick={()=>handleConfirm(1)}>Manual</div>
                            <div className='spring_scheActionBox hoverPointer' onClick={()=>handleConfirm(2)}>i-Control</div>
                            <div className='spring_scheActionBox hoverPointer' onClick={()=>handleConfirm(3)}>Scene</div>
                            <div className='spring_scheActionBox hoverPointer' onClick={()=>handleConfirm(4)}>Off</div>
                            <div className='spring_scheActionBox hoverPointer' onClick={()=>handleConfirm(5)}>Off, Cleaning</div>
                            <div className='spring_scheActionBox hoverPointer divHidden ' >Off, Cleaning</div>
                        </div>
                    </div>         

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaChooseScheAction;