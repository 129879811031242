import http from "../httpService";


async function getBy_bdid_user_accessLvl(buidling_id, user_id, accessLevel) {   
    // console.log("Shared getBy_bdid_user_accessLvl");     
    let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/sensorshared/getbyuserbdaccesslvl";
    
    let body = {
        buidling_id,
        user_id, 
        accessLevel,
    };
    return await http.post(url, body);  
}

async function sensorShare(shareInfo) {   
    let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/sensorshared/sharesensor";
    
    let body = {
        shareInfo
    };
    return await http.post(url, body);  
}

async function getSharedBd_ByUserId_Bd_id(user_id, bd_id) {   
    let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/sensorshared/getsharedbddevbyuseridbdid";
    
    let body = {
        user_id, bd_id
    };
    return await http.post(url, body);  
}

async function getSharedBd_ByUserId_Bd_idv2(user_id, bd_id) {   
    let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/sensorshared/getsharedbdbyuseridbdid";
    
    let body = {
        user_id, bd_id
    };
    return await http.post(url, body);  
}


async function getSharedUserInBd(bd_id) {   
    let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/building/getshareduserbybdiduserid";
    
    let body = {
        bd_id
    };
    return await http.post(url, body);  
}

async function editSharedUserInBd(userList) {   
    let url = process.env.REACT_APP_PUBLIC_URL+"/devrec/building/editshareduser";
    
    let body = {
        userList
    };
    return await http.post(url, body);  
}



const expModule = {  
    getBy_bdid_user_accessLvl,
    sensorShare,
    getSharedBd_ByUserId_Bd_id,
    getSharedUserInBd,
    editSharedUserInBd,
    getSharedBd_ByUserId_Bd_idv2,
};
export default expModule;
