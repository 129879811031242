import React, { useEffect, useState } from 'react';
import {FaTimes, FaMinus} from "react-icons/fa";

function TpDiaFailed(props) {

    const [G_StatusErrMsg, setG_StatusErrMsg] = useState("Failed");

    useEffect(()=>{
        async function startUp(){
            if(props.diaErrMsg){
                setG_StatusErrMsg(props.diaErrMsg);
            }
        }
        startUp();

        return ()=>{

        }
        // eslint-disable-next-line
    }, []);

    const handleClose=()=>{
        if(!props.onClickDiaClose) return;
        props.onClickDiaClose();
    }



    return (
        <div>
            <div className='spring_dialogBoxContainer spring_index110'>
                <div className='spring_dialogBox spring_shadowBox'>
                    <div className='spring_diaCloseBtn spring_shadowBox spring_lightGreyGrdBgc hoverPointer'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>
                    <div className='spring_diaIcon_Bigger spring_diaIconFrame spring_shadowBox spring_failedBgc'>
                        <FaTimes/>                        
                    </div>
                    <div className='spring_diaStatusText'>
                        {G_StatusErrMsg}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpDiaFailed;