import React from 'react';
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";


function TpDiaSetFavBd(props) {

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.confirmBdFav) props.confirmBdFav(props.bdSel);
    }
    

    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox'>
                    <div className='spring_floorBigText spring_Margin_20'>{`${props.bdSel.name}`}</div>
                    <div className='flexAndBothCenter text_2_5'>
                        <div className='flexAndBothCenter'>
                            {props.bdSel.favUnix >0 && <AiFillStar className='yellowText'/>}
                            {(props.bdSel.favUnix <= 0 || !props.bdSel.favUnix )&& <AiOutlineStar />}
                        </div>

                        <div className='flexAndBothCenter'>
                            <BsArrowRight className='text_1_5 spring_Margin_20'/>
                        </div>
                        <div className='flexAndBothCenter'>
                            {(props.bdSel.favUnix <= 0 || !props.bdSel.favUnix )&& <AiFillStar className='yellowText'/>}
                            {props.bdSel.favUnix >0 && <AiOutlineStar />}
                        </div>
                    </div>
                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Discard
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TpDiaSetFavBd;