import React, { useEffect, useRef, useState } from 'react';
import localStorageFn from '../../../../function/localStorage/LocalStorageFn';
import v2aComFn from '../../Version2a/ComFn/V2aComOwnerDevFn';
import auth from '../../../../service/auth/AuthV2';
import { sortTemplate } from '../../../../function/ArrayFn/arrayFn';
import v2OwnDevFn from '../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import TpDiaChooseDevice from '../../Version2a/Template/DialogTemplate/TpDiaChooseDevice';
import { FiTrash2 } from "react-icons/fi";
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import V2DataDownloadFn from '../../../../function/V2_DataDownload/V2_DataDownloadFn';
import { FaPlusSquare } from "react-icons/fa";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

function TpNewRequest(props) {
    const G_MaxChar = 40;

    const [G_bLoaded, setG_bLoaded] = useState(false); 
    const [G_bdDevList_display, setG_bdDevList_display] = useState([]); 
    const [G_bdDevList_toDia, setG_bdDevList_toDia] = useState([]);
    const [G_floorList_toDia, setG_floorList_toDia] = useState([]); 
    const [G_areaList_toDia, setG_areaList_toDia] = useState([]); 
    const [G_bdDevList, setG_bdDevList] = useState([]); 
    const [G_ShowTp, setG_ShowTp] = useState(0);
    const [G_CharCnt, setG_CharCnt] = useState(0);
    const [G_clearCache, setG_clearCache] = useState(false);

    const refManuLevel = useRef(0);

    useEffect(() => {
        async function startUp() {
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;

            let bdInfo  = props.bdInfo;
            let userInfo = auth.getUserInfo();
            // console.log(bdInfo);
            // setG_bdInfo(bdInfo);
            /** get device in Bd */
            let _devInBd = await v2aComFn.getDevInBd(bdInfo._id);   
            /** filter display dev base on user access level */
            let devList = await v2aComFn.filterDevForSharedUser(bdInfo, userInfo, _devInBd);
            devList = sortArr(devList);            
            setG_bdDevList(devList);
            /** load floor in bd */
            let _floorList = await v2OwnDevFn.getRelatedFloor(bdInfo._id);
            
            /** filter floor with device */
            let floorList = v2aComFn.filterFloorWithDev(_floorList, devList);            
            setG_floorList_toDia(floorList);
             
            /** load area in bd */
            let _allAreaList = await v2OwnDevFn.v2aGetAllAreaUnderBd(bdInfo._id);
            /** filter area with device */
            let allAreaList = v2aComFn.filterAreaWithDev(_allAreaList, devList);
            setG_areaList_toDia(allAreaList);

            setG_bLoaded(true);
        }

        startUp();

        return ()=>{
            
        }
    }, []);

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "name", descOrder: false },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    // const backPrevPg=()=>{
    //     let pathname ="/data/bdoverview"
    //     if(refManuLevel.current===2){
    //         pathname="/data/floorinbd"
    //     }else if(refManuLevel.current===3){
    //         pathname="/data/deviceinfloor"
    //     }
        
    //     props.history.push({
    //         pathname,
    //         // _data: { _selBuildingId: this.state.selBuildingId},
    //     });
    // }

    const callAddDev=()=>{
        setG_bdDevList_toDia(G_bdDevList);
        setG_ShowTp(1);
    }

    const updateDisplayTable=(dbList)=>{
        let _displayArr = [];
        for (const eachDev of dbList) {
            if(eachDev.bChecked) _displayArr.push(eachDev);
        }
        setG_bdDevList_display(_displayArr);
    }

    const handleRemove=(devInfo)=>{
        // let _bdDevList = G_bdDevList.filter(c=>c._id!==devInfo._id);
        /** update table display */
        let _bdDevList_display = G_bdDevList_display.filter(c=>c._id!==devInfo._id);
        setG_bdDevList_display(_bdDevList_display);

        /** update DB */
        let idx = G_bdDevList.findIndex(c=>c._id===devInfo._id);
        let _bdDevList = [...G_bdDevList];
        _bdDevList[idx].bChecked = false;
        setG_bdDevList(_bdDevList);
    }

    const handleCloseDiaChoose=()=>{
        setG_ShowTp(0);
    }

    const handleConfirmDevList=(dbList)=>{
        setG_ShowTp(0);
        setG_bdDevList(dbList);
        updateDisplayTable(dbList);
    }

    const handleCheckout = async () => {
        /** get start end month year */
        let startTime = document.getElementById('In_Download_Start').value;
        let endTime = document.getElementById('In_Download_End').value;
        let description = document.getElementById('r_description').value;
        // console.log(startTime);
        // console.log(endTime);
        if(!startTime && !endTime) {
            toast("Please select Month/Year");
            return;
        }
        if(!description) {
            toast("Please insert description");
            return;
        }
        if(G_bdDevList_display.length === 0) {
            toast("Please select device(s)");
            return;
        }

        /** get description */
        // console.log(description);

        /** generate download info list */
        let devInfo = [];
        for (const eachDev of G_bdDevList_display) {
            devInfo.push({
                type: eachDev.type,
                devID: eachDev.devID,
                bdDev_id: eachDev._id,
                name: eachDev.name
            });
        }

        let userInfo = auth.getUserInfo();
        let bdInfo  = localStorageFn.getBdToken();

        let checkoutInfo = {
            bd_id: bdInfo._id,
            user_id: userInfo.user_id,
            description: description,
            inputYearMonthStart: !startTime? endTime : startTime,
            inputYearMonthEnd: !endTime? startTime : endTime,
            devInfo: devInfo,
            clearCache: G_clearCache
        };
        // console.log(checkoutInfo);
        let response = await V2DataDownloadFn.dataDownloadGenTicket(checkoutInfo);
        // console.log(response);
        // if(response === "OK") history.push("/data/download/tickets");
        if(response === "OK") props.handleChangeTab(1);
    }

    // const toTicketPage = () => {
    //     history.push("/data/download/tickets");
    // }

    const fCap40Char = () => {
        let maxChar = G_MaxChar;
        let sRemarks = document.getElementById('r_description').value;
    
        if (sRemarks.length > maxChar) {
          let new_sRemarks = sRemarks.slice(0, maxChar);
          document.getElementById(`r_description`).value = new_sRemarks;
          setG_CharCnt(new_sRemarks.length);
        } else {
          setG_CharCnt(sRemarks.length);
        }
    };

    const handleClearCache = () => {
        setG_clearCache(!G_clearCache);
    }
    
    return ( <div>
        {!G_bLoaded && <p className='flexAndBothCenter'>Loading...</p>}
        {G_bLoaded && <div>
            <div className='spring_inputBox'>
                <div className='spring_RjSettingGroupTitle'>Duration</div>
                <div className='spring_editScheGroup'>
                    <div className='spring_editItemBar sortHor'> 
                        <div className="spring_editLeftTitle">Start Month:</div>
                        <div className='spring_editRightItem  blueText hoverPointer '>
                            <input id='In_Download_Start' type='month' className='spring_ddYearMonthInput'/>
                        </div>
                    </div>
                    <div className='spring_editItemBar sortHor'> 
                        <div className='spring_editLeftTitle'>End Month:</div>
                        <div className='spring_editRightItem  blueText hoverPointer '>
                            <input id='In_Download_End' type='month' className='spring_ddYearMonthInput'/>        
                        </div>    
                    </div>
                </div>
            </div>

            <div className='spring_inputBox'>
                <div className="sortHor">
                    <div className='spring_RjSettingGroupTitle'>Description</div>
                    <div className="spring_RjSettingGroupTitle spring_RjSettingGroupTitle_stayRight">{`${G_MaxChar - G_CharCnt}/${G_MaxChar}`}</div>
                </div>
                <div className="spring_editScheGroup_SmallSpaceContainer">
                    <div className="spring_editScheGroup_SmallSpace">
                        <div className="spring_editItemBar sortHor hoverPointer">
                            <textarea
                                id={`r_description`}
                                rows="2"
                                className="spring_filterTextArea"
                                placeholder="<Insert description>"
                                onChange={fCap40Char}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {G_ShowTp===1 && <TpDiaChooseDevice
                onclickClose = {handleCloseDiaChoose}
                onclickConfirm = {handleConfirmDevList}
                bdDevList = {G_bdDevList_toDia}
                floorList = {G_floorList_toDia}
                areaList = {G_areaList_toDia}
            />}

            <div className='spring_inputBox'>
                <div className="sortHor">
                    <div className='spring_RjSettingGroupTitle'>{`Device(s)`}</div>
                    {G_bdDevList_display.length > 0 && <div className="spring_RjSettingGroupTitle_stayRight hoverPointer" style={{ fontSize: '1rem'}} onClick={callAddDev}>
                        <FaPlusSquare className='greenText' />
                    </div>}
                </div>

                <div className='spring_editScheGroup'>
                    {G_bdDevList_display.map((u, ind)=>(<div key={`dev_${ind}`} >
                        {ind!==0 && <div className='divSeperateLine'></div>}
                        <div className='sortHor spring_TableItemSpace'>
                            <div className='spring_listContainerItem'>{`[${u.devID}] ${u.name}`}</div>
                            <div className='stayRight flexAndBothCenter  hoverPointer square_25'
                            onClick={()=>handleRemove(u)}>
                                <FiTrash2 className='redText'/>
                            </div>
                        </div>
                    </div>))}   
                                
                    <div className={`whiteText greenBgc spring_containerAddDev ${G_bdDevList_display.length > 0 ?"spring_addDevTopBorder" : "spring_addDevAllBorder"} hoverPointer`}
                        onClick={callAddDev}> + Add device(s)</div>

                    </div>
                </div>

                <div className='spring_inputBox'>
                        {!G_clearCache && <div className='sortHor spring_marginLeft10'>
                            <div className='spring_skipClick'>Quick Mode</div>
                            <div className='hoverPointer flexVerCenter spring_scheActive_Icon spring_marginRight5 spring_editIcon' onClick={handleClearCache}>
                                <BsToggleOn />
                            </div>
                        </div>}
                        {G_clearCache && <div className='spring_marginLeft10'>
                            <div className='sortHor'>
                                <div className='spring_skipClick'>Standard Mode</div>
                                <div className='hoverPointer flexVerCenter spring_scheActive_Icon spring_marginRight5 spring_editIcon' onClick={handleClearCache}>
                                    <BsToggleOff />
                                </div>
                            </div>
                            <div className='spring_smallText spring_italicStyle redText'>
                                *** Standard mode will reprocess the data file, which will take longer time
                            </div>
                        </div>}
                </div>

                <div className='sortHor spreadEvenly spring_2BtnBtmMargin'>
                    <div className="roundButton" onClick={handleCheckout}>Checkout</div>
                </div>
            </div>}
    </div> );
}

export default TpNewRequest;