import React from "react";
import { Link } from "react-router-dom";

const ChangePasswordSuccess = () => {
  return (
    <div className="flexCenter" style={{marginTop: "20px"}}>
      Password has been changed.
      <Link to="/user/login" className="form-inlink">
        Back to Login
      </Link>
    </div>
  );
};

export default ChangePasswordSuccess;
