import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { CgMenuGridO } from "react-icons/cg";
import v2OwnDevFn from '../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import localStorageFn  from '../../../../function/localStorage/LocalStorageFn';
import { toast } from 'react-toastify';
import { isEmptyArr, notEmptyArr, sortTemplate } from '../../../../function/ArrayFn/arrayFn';
import v2aComFn from '../ComFn/V2aComOwnerDevFn';
import { RiSignalWifiErrorLine} from 'react-icons/ri';
import { GiBattery25} from 'react-icons/gi';
import { TbUnlink} from 'react-icons/tb';
import v2DataQuery from '../../../../function/V2_Query/V2_QueryBdDevData';
import v2aDevStateConstant from '../ComFn/v2aDevStatusConstant';
import SensorMngtFn from '../../../../function/sensor/sensorManagementFn';
import TpManuFrame from './../Template/Manu/TpManuFrame';
import TpDiaRename from './../Template/DialogTemplate/TpDiaRename';
import TpDiaAddNewGroup from './../Template/DialogTemplate/TpDiaAddNewGroup';
import auth from '../../../../service/auth/AuthV2';
import LegoLoader from '../Template/LoaderTemplate/legoLoader';

function V2aFloorInBd(props) {
    const [G_FloorInBd_Display, setG_FloorInBd_Display] = useState([]);
    const [G_FloorInBd_Db, setG_FloorInBd_Db] = useState([]);
    const [G_devInBd, setG_devInBd] = useState([]);
    const [G_BdInfo, setG_BdInfo] = useState({});
    const [G_StateEachFloor, setG_StateEachFloor] = useState([]);
    const [G_ShowTp, setG_ShowTp] = useState(0);
    const [G_ShareLevel, setG_ShareLevel] = useState(0);
    const [G_bLoaded, setG_bLoaded] = useState(false);

    const refPageClosed = useRef(false);
    const refPgReload = useRef(0);
    const refSkipClose = useRef(false);

    useEffect(()=>{
        async function startUp(){
            try {
                refSkipClose.current=true;
                /** load bd Info */
                let bdInfo  = localStorageFn.getBdToken();
                setG_BdInfo(bdInfo);
                
                let userInfo = auth.getUserInfo();
                if(bdInfo.shareLevel > 0){  /** if this is share account */
                    /**check latest access level */
                    let shareInfo = await v2OwnDevFn.v2aGetShareBd_ByUserId_BdId(bdInfo._id, userInfo.user_id);
                    if(!notEmptyArr(shareInfo)){    // active = 0 will not show here
                        toast("Building access denied");
                        backToBdOvv();
                        return 
                    }
                    if(bdInfo.shareLevel !== shareInfo[0].shareLevel){
                       await localStorageFn.updateBdToken_shareLevel(shareInfo[0].shareLevel);
                    }
                    if(refPageClosed.current) return console.log("Returned 0");   // prevent memory leak
                    setG_ShareLevel(shareInfo[0].shareLevel);
                    // accessLevel = shareInfo[0].shareLevel;
                }

                /** get device in Bd */
                // let devInBd = await getDevInBd(bdInfo._id);       
                let _devInBd = await v2aComFn.getDevInBd(bdInfo._id);  
                if(refPageClosed.current) return console.log("Returned 1");   // prevent memory leak
                
                /** filter device for shared user */
                let devInBd = await v2aComFn.filterDevForSharedUser(bdInfo, userInfo, _devInBd);
                // if(bdInfo.shareLevel > 2){
                //     let sharedDev = await v2OwnDevFn.v2aGetShareBdDev_ByUserId_BdId(bdInfo._id, userInfo.user_id);
                //     if(sharedDev.errMsg){
                //         toast("Load share device err: ", sharedDev.errMsg)
                //         return [];
                //     } 

                //     let tempArrList = [];
                //     for (const eachDev of devInBd) {
                //         let foundIdx = sharedDev.findIndex(c=> c.accessLevel > 0 && c.bdDev_id === eachDev._id);
                //         if(foundIdx >=0 ) tempArrList.push(eachDev);
                //     }
                //     devInBd = tempArrList;
                // }
                setG_devInBd(devInBd);
                /** get floor in Bd */
                let floorInBd = await v2aComFn.getFloorInBd(bdInfo._id);
                if(floorInBd.err) return console.log("Returned 2");
                /** sort floor in db */
                floorInBd = sortFloor_byIdx(floorInBd);
                // floorInBd = v2aComFn.sortFloor_byIdx(floorInBd);

                /** determine number of device in each floor */
                // let floorDev = await getDeviceOnEachFloor(devInBd, floorInBd, bdInfo.shareLevel);
                let floorDev = await v2aComFn.getDeviceOnEachFloor(devInBd, floorInBd, bdInfo.shareLevel);
                if(refPageClosed.current) return console.log("Returned 3");   // prevent memory leak
                setG_FloorInBd_Display(floorDev);
                setG_bLoaded(true);
                /** get devs _id list */
                let dev_idList =[];
                for (const eachDev of devInBd) {
                    dev_idList.push({type: eachDev.type, bdDev_id : eachDev._id});
                }
                /** get typeList in Bd */
                let typeList = await getTypeList(devInBd);     
                if(refPageClosed.current) return console.log("Returned 4");   // prevent memory leak
                if(typeList.errMsg) return toast("Get type list err:", typeList.errMsg);

                /** query each last data from server */
                let lastDataGroup = await v2DataQuery.v2GetData_LastNOfList(dev_idList);
                if(refPageClosed.current) return console.log("Returned 5");   // prevent memory leak
                /** get dev status of each floor*/
                let devStatusEachFloor = determineDevState(floorDev, lastDataGroup, typeList);
                // setG_BdInfo(bdInfo);
                // setG_devInBd(devInBd);
                setG_FloorInBd_Display(floorDev);
                setG_FloorInBd_Db(floorDev);
                setG_StateEachFloor(devStatusEachFloor)
                setG_bLoaded(true);
            } catch (error) {
                toast(error.message)
            } finally{
                setG_bLoaded(true);
            }
        }
        startUp();

        return ()=>{
            if(!refSkipClose.current)    refPageClosed.current = true;
        }
        // eslint-disable-next-line
    }, [refPgReload.current]);

    const sortFloor_byIdx=(floorList)=>{
        let tempArr = [...floorList];
        let sortKeys = [
            { key: "sortIdx", descOrder: false },
            { key: "name", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr
    }

    const getTypeList=async(devInBd)=>{
        let tyList = []
        for (const eachDev of devInBd) {
            let found = tyList.find(c=> c === eachDev.type);
            if(!found) tyList.push(eachDev.type);            
        }
        let sensorList = await SensorMngtFn.getSensorListByTypeList(tyList);
        return sensorList;
    }

    // const getDevInBd = async (bd_id)=>{ //???
    //     let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(bd_id);
    //     if(devInBd.errMsg) {
    //         toast(devInBd.errMsg);
    //         return [];
    //     }
    //     return devInBd;
    // }

    const determineDevState=(floorDev, lastDataGroup, sensorTyList)=>{
        let areaOfflineGroup = [];
        
        for (const eachFloor of floorDev) {
            let offlineCnt = 0;
            let WeakSignalCnt = 0;
            let lowBattCnt = 0;
            for (const eachDev of eachFloor.devsInFloor) {
                let found = lastDataGroup.find(c=> c.type === eachDev.type && c.bdDev_id===eachDev._id);
                /** get offline here */
                if(!found) {
                    offlineCnt+=1;
                    continue;
                }

                /** get offline here */
                if(v2aDevStateConstant.determineDevOffline(found.unix)) offlineCnt+=1;                
                
                /** get weak signal here */
                if(v2aDevStateConstant.determineDevWeakSignal(found.SNR, found.RSSI)) WeakSignalCnt +=1;                    
                
                /** get low batt here */
                let curSenInfo = sensorTyList.find(c=>c.type === eachDev.type);
                if(!curSenInfo) continue;   // in case sensor list not found
                if(v2aDevStateConstant.determineDevLowBatt(curSenInfo,found)) lowBattCnt++;
            }
            let {floorName, floor_id} = eachFloor;
            areaOfflineGroup.push({floorName, floor_id, offlineCnt, WeakSignalCnt, lowBattCnt});            
        }
        return areaOfflineGroup;
    }
    
    
    // const getDeviceOnEachFloor=async(devInBd, bdFloor, shareLevel)=>{
    //     // let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(bd_id);
    //     // if(devInBd.errMsg) return toast(devInBd.errMsg);
    //     /** target  =>  let floorDev = [{floorName : "1st floor", devsInFloor:[{bdDev_id, floor_id, name}]}]   */
    //     /** group by floor */
        
    //     let floorDev = await v2aComFn.F_v2aGetEachFloorDev(bdFloor, devInBd, shareLevel > 2);
    //     return floorDev;
    // }

    // const getFloorInBd=async(bd_id)=>{
    //     let floorInBd = await v2OwnDevFn.getRelatedFloor(bd_id);
    //     if(floorInBd.errMsg) {
    //         toast(floorInBd.errMsg);
    //         return {err:true};
    //     }
    //     // setG_FloorInBd_Display(floorInBd);
    //     // setG_FloorInBd_Db(floorInBd);
    //     return floorInBd
    // }

    const backToBdOvv=()=>{
        props.history.push({
            pathname: "/data/bdoverview",
            // _data: {_selBuildingId: this.state.selBuildingId},
        });
    }

    const handleSearch=()=>{
        let searchInput = document.getElementById('searchInput').value.trim();
        let _FloorInBd_Db = [...G_FloorInBd_Db];
        let newArr = _FloorInBd_Db.filter(c=>c.floorName.toLowerCase().includes(searchInput.toLowerCase()));
        setG_FloorInBd_Display(newArr);
    }

    const handleOpenManual=()=>{
        setG_ShowTp(1);
        // props.history.push({
        //     // pathname: "/data/sensorinbd",
        //     pathname: "/data/devoverview/v2amonmenu",
        //     // _data: {_selBuildingId: this.state.selBuildingId},
        // });
    }
    
    const handleCloseManu=()=>{
        setG_ShowTp(0);
    }

    const handleToDevInFloor=async (floorInfo)=>{
        await localStorageFn.setFloorSelected(floorInfo.floor_id, floorInfo.floorName);
        props.history.push({
            // pathname: "/data/sensorinbd",
            pathname: "/data/deviceinfloor",
            // _data: {_selBuildingId: this.state.selBuildingId},
        });
    }

    const openRenameDia=()=>{
        setG_ShowTp(2);
    }
    const openAddGroupDia=()=>{
        setG_ShowTp(3);
    }
    const handleRename=async (newName)=>{
        /** load bd Info */
        let bdInfo  = localStorageFn.getBdToken();
        let renameRel = await v2OwnDevFn.v2aRenameBd(newName, bdInfo._id)
        if(renameRel.errMsg) {
            setG_ShowTp(0);
            return toast(renameRel.errMsg);
        }
        /** change local state */
        let _BdInfo = {...G_BdInfo};
        _BdInfo.name = newName;
        setG_BdInfo(_BdInfo); 
        /** set local storage */
        await localStorageFn.updateBdToken_Name(newName);
    
        setG_ShowTp(0);
    }

    const handleAddGroup=async(newName)=>{
        let bdInfo  = localStorageFn.getBdToken();
        let rel = await v2OwnDevFn.v2aAddFloor(newName, bdInfo.owner_id, bdInfo._id);
        if(rel.errMsg) return toast(rel.errMsg);
        refPgReload.current++;
        refSkipClose.current=true;
        setG_bLoaded(false);
        setG_ShowTp(0);
    }
    
    const F_GetDevErrStateCnt=(errType ,_StateEachFloor, curFloor)=>{
        if(isEmptyArr(_StateEachFloor)) return -1

        let selFloorState = _StateEachFloor.find(c=> c.floor_id === curFloor.floor_id && c.floorName === curFloor.floorName)
        if(!selFloorState) return 0;
        let errStateCnt = -1;
        if(errType===1){    // batt
            errStateCnt = selFloorState.lowBattCnt;
        }else if(errType===2){      // weak signal
            errStateCnt = selFloorState.WeakSignalCnt;
        }else if(errType===3){      // offline
            errStateCnt = selFloorState.offlineCnt;
        }
        if(!errStateCnt) return 0
        return `${errStateCnt}`;
    }

    return (
        <div>         
            {/* <LegoLoader /> */}
            {!G_bLoaded && <LegoLoader />}   
            {G_ShowTp===1 && <TpManuFrame entryFrom ={"Floor"} 
                onclickClose={handleCloseManu} 
                onclickOpenRenameDia = {openRenameDia}                
                onclickOpenAddGroup = {openAddGroupDia}
                shareLevel = {G_ShareLevel}
                />}
            {G_ShowTp===2 && <TpDiaRename         
                OldName = {G_BdInfo.name} 
                onclickClose={handleCloseManu}
                onclickConfirm = {handleRename}/>}
            {G_ShowTp===3 && <TpDiaAddNewGroup     
                onclickClose={handleCloseManu}
                onclickConfirm = {handleAddGroup}
                existingGroup={G_FloorInBd_Db}
                />}
            

            <div className='headerContainer'>
                <div onClick={backToBdOvv} className='headerIconLeft'>
                    <IoIosArrowBack className="backIcon" />
                </div>
                <div className ='searchBarContainer' style={{margin: "10px"}}>
                        <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                        <div className='searchButton'>
                            <i className='fa fa-search'></i>
                        </div>    
                </div>
                <div onClick={handleOpenManual} className='headerIcon'>
                    <CgMenuGridO/>
                </div>
            </div>

            <div className='devOvvBdTitleBar'>
                <div className='spring_OvvTitleName' >
                    <div className='wrapTextEllipsis devOvvBdName hoverPointer'
                    onClick={backToBdOvv}>{G_BdInfo.name}</div>
                </div>
            </div>
            <div className='devCount sortMiddle'>
                {G_devInBd.length > 1 ?`${G_devInBd.length} Devices`:`${G_devInBd.length} Device`}
            </div>

            

            {G_bLoaded && <div className='spring_floorContainer'>
                {G_FloorInBd_Display.length > 0 && G_FloorInBd_Display.map((u, ind)=>(
                    
                    <div className='sortHor spring_floorFrame hoverPointer' key={`dev_${ind}`} 
                    onClick={()=>handleToDevInFloor(u)}>
                        <div>
                            <div className='spring_floorBigText'>{u.floorName}</div>
                            <div className='spring_floorSmallText spring_MarginTop_n5'>{`${u.devsInFloor.length} Device${u.devsInFloor.length>1?"s":""}`}</div>
                        </div>
                        
                        <div className='spring_statusGroup sortHor'>
                            <div className='sortHor devOvvEachStatus'>
                                <GiBattery25 className='spring_smallText'/>
                                {/* <div className='bdOvvStatusCnt'>                                    
                                    {F_GetDevErrStateCnt(1, G_StateEachFloor, u)}
                                </div> */}
                                <div className={`bdOvvStatusCnt ${F_GetDevErrStateCnt(1, G_StateEachFloor, u) > 0 ?"redText":""}`}>
                                    {F_GetDevErrStateCnt(1, G_StateEachFloor, u)>=0?F_GetDevErrStateCnt(1, G_StateEachFloor, u):"-"}
                                </div>
                            </div>
                            <div className='sortHor devOvvEachStatus'>
                                <RiSignalWifiErrorLine className=''/>
                                {/* <div className='bdOvvStatusCnt'>
                                    {F_GetDevErrStateCnt(2, G_StateEachFloor, u)}
                                </div> */}
                                <div className={`bdOvvStatusCnt ${F_GetDevErrStateCnt(2, G_StateEachFloor, u) > 0 ?"redText":""}`}>
                                    {F_GetDevErrStateCnt(2, G_StateEachFloor, u)>=0?F_GetDevErrStateCnt(2, G_StateEachFloor, u):"-"}
                                </div>
                            </div>
                            <div className='sortHor devOvvEachStatus'>
                                <TbUnlink className=''/>
                                <div className={`bdOvvStatusCnt ${F_GetDevErrStateCnt(3, G_StateEachFloor, u) > 0 ?"redText":""}`}>
                                    {F_GetDevErrStateCnt(3, G_StateEachFloor, u)>=0?F_GetDevErrStateCnt(3, G_StateEachFloor, u):"-"}
                                </div>
                            </div>
                        </div>                                  
                            
                    </div>
                    
                ))}
            </div>}
        </div>
    );
}

export default V2aFloorInBd;