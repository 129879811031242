import React, { useState, useEffect } from "react";
import userService from "../../../service/auth/userService";
import { Link, useParams } from "react-router-dom";

const UserEmailValidation = () => {
  const { actToken } = useParams();
  const [status, setStatus] = useState();

  useEffect(()=>{
    async function startUp(){
      checkTokenExpiry();
    }
    startUp();

    return ()=>{
        // alert("AddDevicePg Page Close");
    }
    // eslint-disable-next-line
  }, [actToken]);


  const checkTokenExpiry = async () => {
    try {
      let result = await userService.setUserActive(actToken);
      console.log(result);
      if (result.status === 201) await setStatus(0);
      else if (result.status === 200) await setStatus(1);
      else if (result.status === 202) await setStatus(2);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      {status === 0 && (
        <div className="email-validation">
          Validation link expired. Click
          <Link to="/user/register" className="form-inlink">
            here
          </Link>
          to resend account activation link.
        </div>
      )}
      {status === 1 && (
        <div className="email-validation">
          Account validation success.
          <Link to="/user/login" className="form-inlink">
            Back to login
          </Link>
        </div>
      )}
      {status === 2 && (
        <div className="email-validation">
          Account already activated.
          <Link to="/user/login" className="form-inlink">
            Back to login
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserEmailValidation;
