import React, { Component } from "react";
import userService from "../../../service/auth/userService";
import { Link } from "react-router-dom";

class UserResendValidationLink extends Component {
  state = {};

  handleSubmit = (e) => {
    e.preventDefault();

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // console.log(errors);
    // if (errors) return;

    this.doSubmit();
  };

  doSubmit = async () => {
    try {
      let email = document.getElementById("email").value;

      const response = await userService.resendActLink(email);
      // localStorage.setItem("token", response.headers["x-auth-token"]);
      console.log("header Token: ", response);
      console.log("header Token: ", response.data);
      //   auth.loginWithJwt(response.data);
      // headers["x-auth-token"];
      //   window.location = "/notactive";
      // console.log(response);

      //   this.props.history.push("/notactive");
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        // toast.error("This username already been registered");
        console.log(ex.response);
        // const errors = { ...this.state.errors };
        // this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="form-container">
        <h1 className="form-title">Resend Validation Link</h1>
        <form className="form-signup" onSubmit={this.handleSubmit}>
          <div className="form-card">
            <label className="form-label">Registered Email Address</label>
            <input
              className="form-input"
              type="email"
              id="email"
              name="Email Address"
            ></input>
          </div>
          <div align="center">
            <button className="form-button">Confirm</button>
          </div>
          <p className="form-btm">
            <Link to="/user/login" className="form-inlink">
              Back to Login
            </Link>
          </p>
        </form>
      </div>
    );
  }
}

export default UserResendValidationLink;
