import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FiTrash2} from "react-icons/fi";
import { toast } from "react-toastify";
import SensorMngtFn from '../../../../../../function/sensor/sensorManagementFn';
import { isEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
// import { MdOutlineFileUpload } from "react-icons/md";
import { FaMicrochip } from "react-icons/fa6";
import { MdCloudCircle } from "react-icons/md";
// import SensorMngtFn from '../../../../../../function';

const SLTabCfgPg = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadLNodeParaPg(mode, _pi) {
      F_FillUpLNodeParaPg(mode, _pi);
    },
  }));
  const [G_MemberList, setG_MemberList] = useState([{para: ""}]);

  const [G_storageMode, setG_storageMode] = useState("db");

  // const ref_UploadDelay = useRef(null);

  useEffect(() => {
    async function startUp() {
      if(props.loadDbCfgPg) await props.loadDbCfgPg();
        // let {pgNo, memberList} = F_ShowUploadedInfo();      
        // ref_UploadDelay.current = setTimeout(()=>fDisplay_Info(memberList, pgNo), 100);  

        // setG_MemberList(memberList);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      // clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.uploadedCfgPg]);

  const F_FillUpLNodeParaPg = (mode, _pi) => {
    if(isEmptyArr(_pi)) {
      setG_MemberList([{para: ""}]);
      document.getElementById(`in_${props.pos}_pgNo`).value = "";
    } else {
      let paraList=[];
      for (let i = 0; i < 8; i++) {
        if(_pi[(i*2)+1] === 0) break;
        if(_pi[(i*2)+1]<1 || _pi[(i*2)+1]>4) continue;
        let sPara = SensorMngtFn.getParaKey_int(_pi[(i*2)+1], _pi[(i*2)+2]);
        paraList.push({para:sPara});
      }
      document.getElementById(`in_${props.pos}_pgNo`).value = _pi[0];
      setG_MemberList(paraList);
    }
    setG_storageMode(mode);
  }

  const F_switchToNoMode = () => {
    if(G_storageMode !== "none") setG_storageMode("none");
  }

  // const fDisplay_Info=(memberList, pgNo)=>{
  //     F_SetInputPlaceHolder(memberList, pgNo);
  //     clearTimeout(ref_UploadDelay.current);
  // }


  // const F_ShowUploadedInfo=()=>{
  //   if(props.uploadedCfgPg.para && props.uploadedCfgPg){        
  //       let memberList = [];
  //       if(notEmptyArr(props.uploadedCfgPg.para)){
  //           for (const eacgCfg of props.uploadedCfgPg.para) {                
  //               if(eacgCfg.dataType<1 || eacgCfg.dataType>4) continue;
  //               let sPara = SensorMngtFn.getParaKey_int(eacgCfg.dataType, eacgCfg.dataIndex);
  //               memberList.push({para:sPara})
  //           }
  //       }        
  //       return {pgNo:props.uploadedCfgPg.pgNo, memberList};
  //   }
  // }

  const F_SetInputPlaceHolder=(MemberList, pgNo)=>{
    for (let i = 0; i < MemberList.length; i++) {
        // document.getElementById(`in_${props.pos}_${i}_devTy`).value = MemberList[i].ht;
        document.getElementById(`in_${props.pos}_${i}_para`).value = MemberList[i].para;
    }
    document.getElementById(`in_${props.pos}_pgNo`).value = pgNo;
  }

  const F_GetConfigInfo=()=>{
    let returnRel = [];
    for (let i = 0; i < G_MemberList.length; i++) {        
        // if(G_MemberList[i].ht <=0 || isNaN(G_MemberList[i].ht)) return ({errMsg: "Invalid Type", index:i, errLoc:"DevType"})

        let splitRel = SensorMngtFn.splitKey_intForm(G_MemberList[i].para)
        if(splitRel.errMsg) return ({errMsg: splitRel.errMsg, index:i, errLoc:"Data"})
        returnRel.push({
            ht:G_MemberList[i].ht, 
            dataType:splitRel.dataType,
            dataIndex:splitRel.dataIndex
        })   
    }
    return returnRel;
  }

  
  // const F_Upload=()=>{
  //   if(props.uploadCfgPg) {/** get the configured information */
  //     let _pgNo = document.getElementById(`in_${props.pos}_pgNo`).value;
  //     if(_pgNo<0 || _pgNo>=10 ) {
  //       return toast("Invalid Page No.(0-9)");
  //     }
  //     props.uploadCfgPg(_pgNo);
  //   }
  // }
  
  const F_AddPara=()=>{
    if(G_MemberList.length>=8) return;
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.push({ht:0, para:""});
    setG_MemberList(_MemberList);
  }
  
  const F_DelPara=(ind)=>{
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.splice(ind, 1);
    setG_MemberList(_MemberList);
    
    F_SetInputPlaceHolder(_MemberList);
  }
  
  const F_UpdateInfo=()=>{
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    for (let i = 0; i < 8; i++) {
      if(document.getElementById(`in_${props.pos}_${i}_para`)){
        // _MemberList[i].ht = document.getElementById(`in_${props.pos}_${i}_devTy`).value;
        _MemberList[i].para = document.getElementById(`in_${props.pos}_${i}_para`).value;
      }
    }
    F_switchToNoMode();
    setG_MemberList(_MemberList);
  }

  
  const F_handleLoadNode = () => {
    if(props.loadNodeCfgPg) props.loadNodeCfgPg();
  }
  
  const F_handleLoadDb = () => {
    if(props.loadDbCfgPg) props.loadDbCfgPg();
  }

  const F_getParaPgInfo = () => {
    /** get the configured information */
    let _pgNo = document.getElementById(`in_${props.pos}_pgNo`).value;
    if(_pgNo<0 || _pgNo>=10 ) {
        return toast("Invalid Page No.(0-9)");
    }
    let a_cfgInfo = F_GetConfigInfo();
    if(a_cfgInfo.errMsg) return toast(`${a_cfgInfo.errLoc} Err: Member ${a_cfgInfo.index+1}, ${a_cfgInfo.errMsg}`)

    return { _pgNo, a_cfgInfo };
  }

  const F_sendToNode=()=>{
    if(props.uploadNodeCfgPg) {
        /** get the configured information */
        // let _pgNo = document.getElementById(`in_${props.pos}_pgNo`).value;
        // if(_pgNo<0 || _pgNo>=10 ) {
        //     return toast("Invalid Page No.(0-9)");
        // }
        // let a_cfgInfo = F_GetConfigInfo();
        // if(a_cfgInfo.errMsg) return toast(`${a_cfgInfo.errLoc} Err: Member ${a_cfgInfo.index+1}, ${a_cfgInfo.errMsg}`)
        let { _pgNo, a_cfgInfo } = F_getParaPgInfo();
        // return 
        props.uploadNodeCfgPg(_pgNo, a_cfgInfo);
    }
  }

  const F_saveToDb = () => {
    if(props.uploadDbCfgPg) {
      let { _pgNo, a_cfgInfo } = F_getParaPgInfo();
      props.uploadDbCfgPg(_pgNo, a_cfgInfo);
    }
  }

  return (
    <div className="spring_SL_TabPg">
      <div className="sortHor">
        <div className="spring_leaderTitle">Para Page No.</div>
        <input style={{width:"30px", fontSize: "1.2rem"}}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown"
            id={`in_${props.pos}_pgNo`}
            onChange={F_switchToNoMode}
        ></input>
        <div className="stayRight" style={{ marginRight: "5px" }}>
        <FaMicrochip
            className={G_storageMode === "node"? "reactCfgButtonOn hoverPointer" : "reactCfgButtonOff hoverPointer"}
            onClick={F_handleLoadNode}
            style={{  marginRight: "10px" }}
          />
          <MdCloudCircle
            className={G_storageMode === "db"? "reactCfgButtonOn hoverPointer" : "reactCfgButtonOff hoverPointer"}
            onClick={F_handleLoadDb}
          />
        </div>
        {/* <MdOutlineFileUpload
          className="reactCfgButtonOn stayRight hoverPointer"
          style={{ marginRight: "18px" }}
            onClick={F_Upload}
        /> */}
      {/* <div className="stayRight hoverPointer" onClick={F_Upload}>Upload</div> */}
      </div>
      <div className="spring_SL_CfgPg_ItemContainer">
        {G_MemberList.map((c, ind) => (
            <div className="spring_Sl_Slot sortHor" key={`${props.pos}_${ind}_key`}>
                <div style={{marginRight:"10px"}}>{`${ind+1}.`}</div>
                {false && <div className="sortHor">
                    <div>DevType :</div>
                    <input style={{width:"50px"}}
                        type={"number"}
                        className=" i_time_contentInput hideNumberUpDown"
                        id={`in_${props.pos}_${ind}_devTy`}
                        onChange={F_UpdateInfo}
                    ></input>
                </div>}
                <div className="sortHor">
                    <div>Data :</div>
                    <input style={{width:"60px"}}
                        className=" i_time_contentInput"
                        id={`in_${props.pos}_${ind}_para`}
                        onChange={F_UpdateInfo}
                        value={c.para? c.para : ""}
                    ></input>
                </div>
                <div className="stayRight flexAndBothCenter hoverPointer" onClick={()=>F_DelPara(ind)}><FiTrash2 className="redText"/></div>
            </div>
        ))}
        {G_MemberList.length < 8 ? 
            <div className="spring_Sl_Slot hoverPointer flexAndBothCenter boldText" onClick={F_AddPara}>+ Add</div> :
            <div className="spring_Sl_Slot hoverPointer"> Reach Max Member</div>}
        {/* {G_MemberList.length < 8 && <div className="spring_Sl_Slot hoverPointer" onClick={F_AddPara}>+ Add</div>} */}

        {G_storageMode === "node" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_saveToDb}>Save to DB</div>}
        {G_storageMode === "db" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Send To Node</div>}
        {G_storageMode === "none" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Send</div>}
        {/* <div className="spring_tpContentSendButton hoverPointer" onClick={F_SetPg}>Send</div> */}
      </div>
    </div>
  );
});

export default SLTabCfgPg;
