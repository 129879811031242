import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";

const MRTabLNode = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadLNode(_pf, _pi) {
      F_FillUpLNode(_pf, _pi);
    },
  }));

  const [G_CommType, setG_CommType] = useState(0);

  useEffect(() => {
    async function startUp() {}
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, []);

  const F_DownladChLNode =()=>{
    if(G_CommType<1 || G_CommType >2) return toast(`Invalid Input (Comm. Type)`);
    let LNodeInfo = {
      Ht:F_GetInputVal_Number(`chLN_Ty_${props.pos}`),
      Hi:F_GetInputVal_Number(`chLN_id_${props.pos}`),
      bdDev_id:F_GetInputVal_Number(`chLN_bdDevId_${props.pos}`),
      commType:G_CommType,
      gwID:F_GetInputVal_Number(`chLN_gwID_${props.pos}`),
      gwId_Dots:F_GetInputVal_Number(`chLN_gwId_LNode_${props.pos}`),
      loraFreq:F_GetInputVal_Number(`chLN_freq_${props.pos}`),
    }

    for (const key in LNodeInfo) {
      if (Object.hasOwnProperty.call(LNodeInfo, key)) {
        if(LNodeInfo[key]===null){
          toast(`Invalid Input (${key})`);
          return
        }        
      }
    }
    let passCode = F_GetInputVal_Number(`chLN_pass_${props.pos}`);
    if (passCode === null) return toast(`Invalid input (Password)`);


    /** validation pass, trigger parent function */    
    if (props.handleDownloadLNode)
      props.handleDownloadLNode(LNodeInfo, passCode);

  }

  const F_handleUplaod = () => {
    if (props.handleUploadLNode) props.handleUploadLNode();
  };

  
  const F_CurCommTypeDisplay=()=>{
    if(G_CommType===1) return 'LoRa'
    else if(G_CommType===2) return 'Broker'
    else return '--'    
  }
  const F_ToggleCommType=()=>{
    if(G_CommType===1)  setG_CommType(2);
    else setG_CommType(1);
  }

  const F_FillUpLNode=(_pf, _pi)=>{
      F_SetInputVal_Number(`chLN_Ty_${props.pos}`, _pi[0]);
      F_SetInputVal_Number(`chLN_id_${props.pos}`, _pi[1]);
      F_SetInputVal_Number(`chLN_bdDevId_${props.pos}`, _pi[2]);
      setG_CommType(_pi[3]);
      F_SetInputVal_Number(`chLN_gwID_${props.pos}`, _pi[4]);
      F_SetInputVal_Number(`chLN_gwId_LNode_${props.pos}`, _pi[5]);

      F_SetInputVal_Number(`chLN_freq_${props.pos}`, _pf[0]);
  }
  
  return (
    <div className="spring_SL_TabPg">
      <div className="sortHor">
        <div className="spring_tpContentTitle">Leader Node</div>
        <MdOutlineFileUpload
          className="reactCfgButtonOn stayRight hoverPointer"
          style={{ marginRight: "20px" }}
          onClick={F_handleUplaod}
        />
      </div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Type ID</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_Ty_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>    
        <div className="sortHor spring_tpContent">
          <div>Device ID</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_id_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>    
        <div className="sortHor spring_tpContent">
          <div>bdDev_id</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_bdDevId_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentTitle">Communication</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Comm. Type</div>
          <div className="stayRight">
            <div className="spring_SL_SelButton hoverPointer"
            onClick={F_ToggleCommType}>
              {F_CurCommTypeDisplay()}
              </div>
          </div>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Mr. Roger Gw ID</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_gwID_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Leader Node Gw ID</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_gwId_LNode_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>LoRa Frequency</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_freq_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentTitle">Security</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Password</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`chLN_pass_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentSendButton hoverPointer"
        onClick={F_DownladChLNode}
      >
        Update
      </div>

      <div style={{marginBottom:"25px"}}></div>
    </div>
  );
});

export default MRTabLNode;
