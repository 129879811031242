import React, { useEffect, useRef, useState } from 'react'
import ctrlTp from '../../CtrlCmdTemplate/CtrlTemplate';
import socketIOClient from 'socket.io-client';
import { toast } from 'react-toastify';
import ctrlCmdLib from '../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';
import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import TpDiaSending from '../../../../Control/Common/Dialog/StatusDialog/TpDiaSending';
import TpDiaSuccess from '../../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess';
import TpDiaFailed from '../../../../Control/Common/Dialog/StatusDialog/TpDiaFailed';
import { RiBubbleChartLine, RiMindMap   } from "react-icons/ri";
import MRTabLNode from '../CommonTp/MRTab_LNode';
import MRChwpTabSetting from './MRChwpTab_Setting';


const SubTpMrRogerCHWP = (props) => {
    
  /**------Ctrl Standard 1/2 Start------ */
  const cmdTimeout = 10000;    // 10 sec
  const msgTimeout = 3000;    // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  const [G_GwID, setG_GwID] = useState();

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refCmdLog = useRef({});
  /**======Ctrl Standard 1/2 End====== */

  const [G_ShowingTab, setG_ShowingTab] = useState(0);  

  const ref_LNode = useRef();   // parent trig child
  const ref_ChwpSetting = useRef();   // parent trig child

  useEffect(() => {
    let socket;
    async function startUp() {
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);       

      await F_LoadGateway();

      let topic = ctrlTp.F_GetIoTopic(props.dev);
      socket.on(topic, async(data) => {
        if(ctrlTp.F_HandleNodeReply(data, refCmdLog.current, cmdTimeoutRef.current, setG_statusState)){
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          await handleServerReq(data);
        }
      });
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);
/**-----------Ctrl Standard 2/2 start------------- */
const F_LoadGateway=async()=>{
    let pairedGw = await v2ctrlFn.getV2_GwPair(props.dev._id);
    if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    let {gwPairList} = pairedGw;
    if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
    setG_GwID(gwPairList[0].gwid);
  }

  const fCmdTimeout=async()=>{
    await ctrlTp.F_CmdTimeout(refCmdLog.current, setG_diaErrMsg,setG_statusState,cmdTimeoutRef.current);
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);            
  }
  const statusDiaAutoClose=()=>{
    handleCloseDia();
  }

  const handleCloseDia=()=>{
    // clearTimeout(cmdTimeoutRef.current);
    // clearTimeout(statusTimeoutRef.current);    
    setG_statusState(0);
  }
  
  const F_SendLoraTp=async(fn, payload)=>{
    let devInfo = {type:props.ht, devID:props.dev.devID};
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(fn, G_GwID, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)    
    setG_statusState(1);     // go to sending
    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  }

  const handleServerReq=async(mqttData)=>{
    try {
      let {hf, pb, pf, pi} = mqttData;
      if(hf===11){
        ref_LNode.current.uploadLNode(pf, pi);
      }else if(hf===12){
        ref_ChwpSetting.current.uploadChwpSetting(pb, pf, pi);
      }
    } catch (error) {
      toast(`Server Req Err: ${error.message}`);
    }
  }
  /** ===============Ctrl Standard 2/2 end=============== */

  const F_ToPgNo=(nPgNo)=>{
    setG_ShowingTab(nPgNo);
  }
  const F_uploadLNode =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(11, payload);
  }
  const F_downloadLNode =async (LNodeInfo, passCode)=>{
    let pi =[
      LNodeInfo.Ht,
      LNodeInfo.Hi,
      LNodeInfo.bdDev_id,
      LNodeInfo.commType,
      LNodeInfo.gwID,
      LNodeInfo.gwId_Dots,
    ];
    let pf =[LNodeInfo.loraFreq];
    let pn=[passCode];
    let payload = {pi, pf, pn};
    await F_SendLoraTp(2, payload);
  }

  
  const F_uploadChwpSetting =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(12, payload);
  }

  const F_downloadChwpSetting =async (ChwpInfo, passCode)=>{
    let pb=[ChwpInfo.logicActive];
    let pi=[
      parseInt(ChwpInfo.Query_OT*1000),
      parseInt(ChwpInfo.RdHzHandling),
      parseInt(ChwpInfo.chwpQty),
    ]
    let pf =[
      ChwpInfo.dpThreshold,
      ChwpInfo.tagetDp,
      ChwpInfo.buf_Top,
      ChwpInfo.buf_Btm,
      ChwpInfo.hzMaxLimit,
      ChwpInfo.hzMinLimit,
      ChwpInfo.RampRate_Fast,
      ChwpInfo.RampRate_Slow,
      ChwpInfo.ZoneRatio_Top,
      ChwpInfo.ZoneRatio_Btm,
    ]
    let pn=[passCode];
    let payload = {pb, pi, pf, pn};
    await F_SendLoraTp(3, payload);
  }
  

  return (
    <div>
      {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
      {G_statusState===1 && <TpDiaSending/>}
      {G_statusState===2 && <TpDiaSuccess onClickDiaClose = {handleCloseDia}/>}
      {G_statusState===3 && <TpDiaFailed onClickDiaClose = {handleCloseDia} diaErrMsg={G_diaErrMsg}/>}

      
      {G_ShowingTab === 0 && <MRChwpTabSetting 
        dev={props.dev}
        pos={props.pos}
        gwId = {G_GwID}    
        handleDownloadSetting={F_downloadChwpSetting}
        handleUploadSetting={F_uploadChwpSetting}    
        ref = {ref_ChwpSetting}
      />}
      {G_ShowingTab === 1 && <MRTabLNode 
        dev={props.dev}
        pos={props.pos}
        handleDownloadLNode={F_downloadLNode}
        handleUploadLNode={F_uploadLNode}    
        ref = {ref_LNode}
      />}
      
        
      <div className='spring_TpBottomBar sortHor'>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 0?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(0)}>
          <RiBubbleChartLine />
          <div className="spring_TpBtmButtonTitle">System</div>
        </div>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 1?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(1)}>
          <RiMindMap   />
          <div className="spring_TpBtmButtonTitle">Leader</div>
        </div>
      </div>
    </div>
  )
}

export default SubTpMrRogerCHWP

