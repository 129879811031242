// import jwtFn from 'jsonwebtoken';
import { jwtSignToken } from "../../service/jwt/joseService";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { pushUnique } from '../ArrayFn/arrayFn';
import timeFn from '../../function/time/timeFn';

let bdTokenKey = "v2BdToken";
let eventCfgTokenKey = "v2EventCfgToken";
let watchListTokenKey = "v2WatchListToken";
let enterManuTokenKey = "V2aManuToken";

export async function setBdToken(bdInfo) {
    // console.log(bdInfo);
    // console.log(process.env.REACT_APP_JWT_INTERNAL_KEY); // Check this value
    try {
        // const token = jwtFn.sign({
        //     // lastCheckUser:timeFn.getUnixNow(),
        //     _id:bdInfo._id,
        //     name:bdInfo.name,
        //     owner_id:bdInfo.owner_id,
        //     floor_id:0,
        //     floorName:"",
        //     shareLevel:bdInfo.shareLevel>1?bdInfo.shareLevel:0,
        //     unix:timeFn.getUnixNow(),
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);
        // console.log(token);

        const payload = {
            _id: bdInfo._id,
            name: bdInfo.name,
            owner_id: bdInfo.owner_id,
            floor_id: 0,
            floorName: "",
            shareLevel: bdInfo.shareLevel > 1 ? bdInfo.shareLevel : 0,
            unix: timeFn.getUnixNow(),
        };
        
        localStorage.setItem(bdTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}

export async function setFloorSelected(_floor_id, _floorName) {
    // console.log(bdInfo);
    try {
        let bdInfo = getBdToken();
        // console.log("bdInfo", bdInfo);
        // const token = jwtFn.sign({
        //     // lastCheckUser:timeFn.getUnixNow(),
        //     _id:bdInfo._id,
        //     name:bdInfo.name,
        //     owner_id:bdInfo.owner_id,
        //     floor_id:_floor_id,
        //     floorName:_floorName,
        //     shareLevel:bdInfo.shareLevel,
        //     unix:bdInfo.unix,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            _id:bdInfo._id,
            name:bdInfo.name,
            owner_id:bdInfo.owner_id,
            floor_id:_floor_id,
            floorName:_floorName,
            shareLevel:bdInfo.shareLevel,
            unix:bdInfo.unix,
        };

        localStorage.setItem(bdTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}

export async function updateBdToken_Name(name) {
    // console.log(bdInfo);
    try {
        let bdInfo = getBdToken();
        // console.log("bdInfo", bdInfo);
        // const token = jwtFn.sign({
        //     // lastCheckUser:timeFn.getUnixNow(),
        //     _id:bdInfo._id,
        //     name:name,
        //     owner_id:bdInfo.owner_id,
        //     floor_id:bdInfo.floor_id,
        //     floorName:bdInfo.floorName,
        //     shareLevel:bdInfo.shareLevel,
        //     unix:bdInfo.unix,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            _id:bdInfo._id,
            name:name,
            owner_id:bdInfo.owner_id,
            floor_id:bdInfo.floor_id,
            floorName:bdInfo.floorName,
            shareLevel:bdInfo.shareLevel,
            unix:bdInfo.unix,
        };

        localStorage.setItem(bdTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error(Bd)');
        return null
    }
}


export async function updateBdToken_FloorName(name) {
    // console.log(bdInfo);
    try {
        let bdInfo = getBdToken();
        // console.log("bdInfo", bdInfo);
        // const token = jwtFn.sign({
        //     // lastCheckUser:timeFn.getUnixNow(),
        //     _id:bdInfo._id,
        //     name:bdInfo.name,
        //     owner_id:bdInfo.owner_id,
        //     floor_id:bdInfo.floor_id,
        //     floorName:name,
        //     shareLevel:bdInfo.shareLevel,
        //     unix:bdInfo.unix,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            _id:bdInfo._id,
            name:bdInfo.name,
            owner_id:bdInfo.owner_id,
            floor_id:bdInfo.floor_id,
            floorName:name,
            shareLevel:bdInfo.shareLevel,
            unix:bdInfo.unix,
        };

        localStorage.setItem(bdTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error(Floor)');
        return null
    }
}

export function getBdToken() {
    try {
        let token = localStorage.getItem(bdTokenKey);
        return jwtDecode(token);   
    } catch (error) {
        return null
    }
}

export function getCurBd_id() {
    try {
        let bdInfo = getBdToken();
        if(!bdInfo || !bdInfo._id) return null
        return bdInfo._id
    } catch (error) {
        return null
    }
}


export async function setEventCfgMode(cfgMode, algo_id) {
    // console.log(bdInfo);
    try {
        // const token = jwtFn.sign({
        //     // lastCheckUser:timeFn.getUnixNow(),
        //     cfgMode,
        //     algo_id,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            cfgMode,
            algo_id,
        };

        localStorage.setItem(eventCfgTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}

export function getCfgMode() {
    try {
        let token = localStorage.getItem(eventCfgTokenKey);
        return jwtDecode(token);   
        // let decoded = jwtDecode(token);   
        // if(!decoded.cfgMode) return null
        // return decoded.cfgMode;
    } catch (error) {
        return null
    }
}

export function getWatchList() {
    try {
        let token = localStorage.getItem(watchListTokenKey);
        return jwtDecode(token);   
    } catch (error) {
        return null
    }
}

export async function addToWatchList(bdDev_id) {
    // console.log(bdInfo);
    try {
        let watchList = getWatchList();
        // console.log(watchList);
        let _id = [];
        if(watchList) {
            _id = pushUnique(watchList._id, bdDev_id);
        } else {
            _id.push(bdDev_id);
        }
        // console.log("_id", _id);

        // const token = jwtFn.sign({_id}, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {_id};

        localStorage.setItem(watchListTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}

export async function removeFromWatchList(bdDev_id) {
    try {
        // console.log("remove list");
        let watchList = getWatchList();
        // console.log(watchList);
        let _id = watchList._id.filter(c => c !== bdDev_id);
        // console.log(_id);
        if(_id.length > 0) {
            // console.log("removed not empty");
            // const token = jwtFn.sign({_id}, process.env.REACT_APP_JWT_INTERNAL_KEY);
            const payload = {_id};

            localStorage.setItem(watchListTokenKey, await jwtSignToken(payload));
        } else {            
            // console.log("removed empty");
            localStorage.removeItem(watchListTokenKey);
        }
        // let found = watchList.find(c=>c.bdDev_id===bdDev_id);
        // if(found) 
    } catch (error) {
        console.log(error.message);
        toast('Remove Watch List Error');
        return null
    }
}

export async function setManuToken(manuLevel) {
    try {
        // const token = jwtFn.sign({
        //     manuLevel,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            manuLevel
        }

        localStorage.setItem(enterManuTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}
export function getManuToken() {
    try {
        let token = localStorage.getItem(enterManuTokenKey);
        return jwtDecode(token);   
    } catch (error) {
        return null
    }
}

export async function updateBdToken_shareLevel(shareLevel) {
    // console.log(bdInfo);
    try {
        let bdInfo = getBdToken();
        // const token = jwtFn.sign({
        //     _id:bdInfo._id,
        //     name:bdInfo.name,
        //     owner_id:bdInfo.owner_id,
        //     floor_id:bdInfo.floor_id,
        //     floorName:bdInfo.floorName,
        //     shareLevel,
        //     unix:bdInfo.unix,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            _id:bdInfo._id,
            name:bdInfo.name,
            owner_id:bdInfo.owner_id,
            floor_id:bdInfo.floor_id,
            floorName:bdInfo.floorName,
            shareLevel,
            unix:bdInfo.unix,
        }

        localStorage.setItem(bdTokenKey, await jwtSignToken(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error(Floor)');
        return null
    }
}

const expModule = {  
    setBdToken,
    getBdToken,
    setEventCfgMode,
    getCfgMode,
    addToWatchList,
    getWatchList,
    removeFromWatchList,
    getCurBd_id,
    setFloorSelected,
    setManuToken,
    getManuToken,
    updateBdToken_Name,
    updateBdToken_FloorName,
    updateBdToken_shareLevel,
};

export default expModule;