import React, { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import { toast } from 'react-toastify';
// import Chart from 'chart.js/auto';
import "chartjs-adapter-moment";
// import zoomPlugin from 'chartjs-plugin-zoom';

function TpLineGraph2(props) {
    const [G_chartData, setG_chartData] = useState({datasets: []});
    const [G_chartOptions, setG_chartOptions] = useState({});
    // const [loaded, setLoaded] = useState(false);

    useEffect(()=>{
        async function startUp(){
            /** load last 4h data */
            // console.log("line graph 2 startup");
            // console.log(props);
            // setLoaded(false);
            let _chartData = genChartData();
            let _chartOptions = genChartOptions();
            // let _axesOptions = genAxes();
            setG_chartData(_chartData);
            setG_chartOptions(_chartOptions);
            // setG_axesOptions(_axesOptions);
            // setLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, [props, props.leftScaleVal, props.rightScaleVal]);

    const genChartData=()=>{
        // console.log("chart data");
        try {
            // let {ovvData, disLines} = props;
            if(!props.ovvData) return;

            let datasets=[];

            let mychart = document.getElementById("watchList-graph");                
            if(!mychart) return ({datasets});
            let ctx = mychart.getContext("2d");
            // console.log(mychart.width);
            let grLn_1 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_2 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_3 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_4 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_5 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_6 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_7 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_8 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_9 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            let grLn_10 = ctx.createLinearGradient(0, 0, mychart.width, 0);
            grLn_1.addColorStop(0, "#ff94b2");
            grLn_1.addColorStop(1, "#7d77ff");
            grLn_2.addColorStop(0, "#00ffed");
            grLn_2.addColorStop(1, "#00b8ba");
            grLn_3.addColorStop(0, "#ffcf1b");
            grLn_3.addColorStop(1, "#ff8b1b");
            grLn_4.addColorStop(0, "#88cdf6");
            grLn_4.addColorStop(1, "#015c92");
            grLn_5.addColorStop(0, "#f35651");
            grLn_5.addColorStop(1, "#bf263c");
            grLn_6.addColorStop(0, "#79096c");
            grLn_6.addColorStop(1, "#00d4ff");
            grLn_7.addColorStop(0, "#09793c");
            grLn_7.addColorStop(1, "#00ff2c");
            grLn_8.addColorStop(0, "#56c914");
            grLn_8.addColorStop(1, "#1999d7");
            grLn_9.addColorStop(0, "#1914c9");
            grLn_9.addColorStop(1, "#d110d2");
            grLn_10.addColorStop(0, "#172c53");
            grLn_10.addColorStop(1, "#10d28d");

            let grArr = [grLn_1, grLn_2, grLn_3, grLn_4, grLn_5, grLn_6, grLn_7, grLn_8, grLn_9, grLn_10];

            let comSetting = {
                lineTension: 0.6,
                fill: null, // hide this will show shadow under line
                pointRadius: "0",
                borderWidth: "3",
            }

            let linesName = [];
            let linesLabel = [];
            for (const eachLine of props.disLines) {
                linesName.push(eachLine.dataKey);
                linesLabel.push(eachLine.title);
            }

            for (let i = 0; i < props.totalLine; i++) {
                // eslint-disable-next-line
                // let lineData = [];
                // if (linesName[i]) lineData = props.ovvData[linesName[i]];
                

                let eachCusSetting = {
                    yAxisID: `y_${i}`,
                    label: linesLabel[i]?linesLabel[i]:"N/A",
                    borderColor: grArr[i],
                    data : props.ovvData[linesName[i]],
                    hidden: linesName[i]?false:true,
                }
                let eachSetting = {...comSetting, ...eachCusSetting};
                // console.log(eachSetting);
                datasets.push(eachSetting);           
            }

            return ({datasets});
        } catch (error) {
            toast("Generate Line Error");
            return null;
        }
    }

    const genChartOptions = () => {
        let _options = {
            scales: genAxes(),
            plugins: {
                legend: {
                    display: false       // lebel on top
                },
                zoom: {
                    pan: {
                      enabled: true,
                      mode: 'x'
                    },
                    zoom: {
                      wheel: {
                        enabled: true
                      },
                      drag: {
                        enabled: true
                      },
                      pinch: {
                        enabled: true
                      },
                      mode: 'x'
                    },
                    limits: {
                        x: {
                            min: props.zoomLimit.min,
                            max: props.zoomLimit.max
                        }
                    }
                }
            },
            tooltips: {
                enabled: true
           },
        }
        return _options;
    }

    const genAxes = () => {
        let axes = {};
        axes.x = {
            type: "time",
            time: { unit: props.unit },
            gridLines: { display: false, color: "#333333" },
        }

        let { disLines, leftScaleVal, rightScaleVal } = props;
    //     console.log(devInfoList);
        let idx = 0;
        let left = false;
        let right = false;
        for(const lineInfo of disLines) {
            // console.log("checked true");
            let display = false;
            let position = null;
            let min, max;
            if (lineInfo.scale === 'left') {
                if(!left) display = true;
                position = "left";
                left = true;
                min = leftScaleVal.min;
                max = leftScaleVal.max;
            } else if (lineInfo.scale === 'right') {
                if(!right) display = true;
                position = "right";
                right = true;
                min = rightScaleVal.min;
                max = rightScaleVal.max;
            }
            axes[`y_${idx}`] = {
                id: `y_${idx}`,
                type: "linear",
                display: display,     // hide the number scale of Y axis
                position: position,
                min: min,
                max: max,
            };
            idx++;
            // if(idx+1 === maxLine) break;
        }
        // console.log(axes);
        return axes;
    }

    // const genY_Axes =()=>{
    //     let { devInfoList } = props;
    //     console.log(devInfoList);
    //     let Y_AxesOption = [];
    //     let idx = 0;
    //     let left = false;
    //     let right = false;
    //     for(const devInfo of devInfoList) {
    //         for(const para of devInfo.para) {
    //             if(para.checked) {
    //                 console.log("checked true");
    //                 let display = false;
    //                 let position = null;
    //                 if (para.leftScale) {
    //                     if(!left) display = true;
    //                     position = "left";
    //                     left = true;
    //                 } else if (para.rightScale) {
    //                     if(!right) display = true;
    //                     position = "right";
    //                     right = true;
    //                 }
    //                 Y_AxesOption.push({
    //                     id: `y_${idx}`,
    //                     type: "linear",
    //                     display: display,     // hide the number scale of Y axis
    //                     position: position,
    //                     ticks: {
    //                         min: para.min,
    //                         max: para.max,
    //                     }
    //                 })
    //                 idx++;
    //             }
    //             // if(idx+1 === maxLine) break;
    //         }
    //         // if(idx+1 === maxLine) break;
    //     }
    //     console.log("yaxes", Y_AxesOption);
    //     return Y_AxesOption;
    // }
     
    // const options = {
    //     scales: G_axesOptions,
    //     plugins: {
    //         legend: {
    //             display: false       // lebel on top
    //         },
    //         zoom: {
    //             pan: {
    //               enabled: true,
    //               mode: 'x'
    //             },
    //             zoom: {
    //               wheel: {
    //                 enabled: true
    //               },
    //               drag: {
    //                 enabled: true
    //               },
    //               pinch: {
    //                 enabled: true
    //               },
    //               mode: 'x'
    //             }
    //         }
    //     },
    //     tooltips: {
    //         enabled: true
    //    },        
    // //    layout: {
    // //        padding: {
    // //             top: 5,
    // //             bottom: 5, 
    // //             left: 5,
    // //             right: 5
    // //        }
    // //    },
    // }

    return (
        <div>
            <Line
                id="watchList-graph"
                data={G_chartData}
                options={G_chartOptions}
            />
        </div>
    )
}

export default TpLineGraph2;