import React, { useEffect, useState } from 'react';
import { FaArrowRight, FaMinus} from "react-icons/fa";
import { notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import queryBdDevFn from '../../../../../../function/V2_Query/V2_QueryBdDevData';
import v2CtrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { toast } from 'react-toastify';

function TpDiaGatewayID(props) {

    const [G_GwList, setG_GwList] = useState([]);
    const [G_GwSel, setG_GwSel] = useState(0);

    useEffect(()=>{
        async function startUp(){         
            let gwPair = loadGwPair();
            if(props.divInfo){
                let {type, bdDev_id, _id} = props.divInfo;
                let _bdDev_id = _id
                if(bdDev_id > 0)    _bdDev_id = bdDev_id;

                let gwList = await loadLast10Data(type, _bdDev_id);
                setG_GwList(gwList);
                presetOtherInputBox(gwList, gwPair);
            }
        }
        startUp();

        return ()=>{
        }
        // eslint-disable-next-line
    }, []);

    const loadGwPair=()=>{
        if(!props.gwPair) return 0
        setG_GwSel(props.gwPair);
        return props.gwPair;
    }

    const loadLast10Data=async(type, bdDev_id)=>{
        try {
            let last10Data = await queryBdDevFn.v2GetData_LastN(type, bdDev_id, 10);
            // let last10Data = await bdDevFn.getLastNData(_devInfo.type, _devInfo._id, 10);            
            if(!notEmptyArr(last10Data)) return [];
            let GwList = [];
            for (const eachData of last10Data) {
                let found = GwList.find(c=>c === eachData.gwID);
                if(!found) GwList.push(eachData.gwID);
            }            
            return GwList;
        } catch (error) {
            console.log("Load last 10 data Err : ",error.message);
            return []
        }
    }
    

    const setGwId=async(gw_id)=>{
        try {            
            
            let {type, bdDev_id, _id} = props.divInfo;
            let _bdDev_id = _id
            if(bdDev_id > 0)    _bdDev_id = bdDev_id;

            // let {type, bdDev_id} = props.divInfo;
            if(!type || !_bdDev_id) return toast ("Invalid device info");
            if(gw_id<1 || isNaN(gw_id))return toast ("Invalid gateway ID");
            let setGwrel = await v2CtrlFn.setV2_GwPair(_bdDev_id, gw_id);
            if(setGwrel.errMsg) toast (setGwrel.errMsg);
            if(setGwrel.success) {
                if(!props.setGwId) return toast ("Gateway display update error");
                props.setGwId(gw_id);
                props.onClickDiaClose();
            }
        } catch (error) {
            console.log("Manual Set Gw Err: ", error.message);
        }
    }    

    const presetOtherInputBox=(_GwList, gwPair)=>{
        let found  = _GwList.find(c=> c === gwPair);
        if(!found) document.getElementById('gwID_cus').value = gwPair;
    }

    const getManualGwId = ()=>{
        let manaulGwId= parseInt(document.getElementById('gwID_cus').value);
        return manaulGwId;
    }

    const handleSetCusGwId=async ()=>{
        let manaulId = getManualGwId();
        await setGwId(manaulId);

    }

    const handleClose=()=>{
        if(!props.onClickDiaClose) return;
        props.onClickDiaClose();
    }
    
    
    return (
        <div>
            <div className='spring_dialogBoxContainer '>
                <div className='spring_manuContainer'></div>

                <div className='spring_dialogBox '>
                    <div className='spring_diaCloseBtn spring_shadowBox spring_lightGreyGrdBgc hoverPointer'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>

                    <div className='spring_diaTitle'>
                        Set Gateway ID
                    </div>
                    {G_GwList.length > 0 && <div className='spring_diaSmallTitle'>Available</div>}
                    {G_GwList.map((u, ind)=>(
                        <div key={`suggestGwKey_${ind}`}
                        className='hoverPointer'
                        onClick={()=>setGwId(u)}>
                            {G_GwSel === u && <div className='spring_gwSelBar spring_shadowBox_BlackOnly spring_greenGrdBgc'>{u}</div>}
                            {G_GwSel !== u && <div className='spring_gwSelBar'>{u}</div>}
                        </div>
                    ))} 

                    <div className='spring_diaSpaceBelow' style={{marginTop:"20px"}}>
                        <div className='spring_diaSmallTitle'>Custome Input</div>
                        <div className='sortHor'>
                            <input id="gwID_cus" className='spring_gwInputBar blueText' type='number'/>
                            <div className='spring_RoundSetButton spring_greenGrdBgc spring_shadowBox_BlackOnly hoverPointer'
                            onClick={handleSetCusGwId}>
                                <FaArrowRight/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default TpDiaGatewayID;