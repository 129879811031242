import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosAdd} from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import cssFn from '../../../function/CSS/cssFunction';
import { chooseArea } from '../../../reduxStore/actions/dev_regSensor';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { toast } from 'react-toastify';

function ChooseArea(props) {
    const dispatch = useDispatch();

    // eslint-disable-next-line
    // const [user_id, setUser_id] = useState(1);
    const [loadAreaList, setLoadAreaList] = useState([]);
    const [displayAreaList, setDisplayAreaList] = useState([]);
    const [selectedArea, setSelectedArea] = useState({});

    const regDevice = useSelector(state=> state.dev.regSensor);

    useEffect(()=>{
        async function startUp(){
             
            let selectedBuilding_id = 0;
            if (!regDevice.bNewBuilding) selectedBuilding_id = regDevice.buildingId;

            await getRelatedArea(selectedBuilding_id);
        }
        startUp();

        return ()=>{
            // alert("Choose Device Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const backPrevPg=()=>{
        props.history.push({
            pathname: "/device/add",
            focusStep:4,
        });
    }

    const getRelatedArea=async (selectedBuilding)=>{
        // let selectedBuilding = 0;
        // if (!regDevice.bNewBuilding) selectedBuilding = regDevice.buildingId;
        // console.log("regDevice.buildingId", regDevice.buildingId);

        let relatedArea = await sensorOwnerFn.v2aGetRelatedArea(selectedBuilding, regDevice.floorId);
        if(relatedArea.errMsg) return toast.dark(relatedArea.errMsg);
        // console.log(relatedArea);
        setLoadAreaList(relatedArea);
        setDisplayAreaList(relatedArea);

    }

    const handleSearch=()=>{
        // console.log('handleSearch');
        let searchInput = document.getElementById('searchInput').value.trim();
        if(searchInput==="") {
            setDisplayAreaList(loadAreaList);
            return
        }
        // console.log(searchInput);
        let filtered = loadAreaList.filter((c)=>c.name.toUpperCase().search(searchInput.toUpperCase()) >= 0);
        // console.log(filtered);
        setDisplayAreaList(filtered);
    }


    const addNewArea=()=>{
        // console.log('addNewBd');
        
        props.history.push({
            pathname: "/device/add/addnewarea",
            // _data: {_devName: devName},
        });
    }

    const selArea=(ind, area)=>{
        /** high light the select area when click area in table list */
        for (let i = 0; i < displayAreaList.length; i++) {
            cssFn.cssShowStatus(i===ind, `areaId_${i}`, 'tableItemSelected');            
        }        
        setSelectedArea(area);

    }

    const handleConfirm=()=>{
        // console.log('handleConfirm');
        // console.log(selectedArea);
        if(!selectedArea._id) return toast.error('No subgroup selected')
        dispatch(chooseArea({areaId:selectedArea._id, bAreaName:selectedArea.name}));
        return props.history.push({
            pathname: "/device/add",
            focusStep:3,
        });
    }
    
    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Select Subgroup</div>
            </div>

            <div className='spaceBothSide testClass' style={{marginTop:"20px"}}>
                <div className="spring_fullButton spring_blueColor hoverPointer" onClick={addNewArea}>
                    <div className='buttonWithIcon '>
                        <IoIosAdd className="backIcon" style={{fontSize:'22px'}}/> New
                    </div>
                </div>

                <div className ='searchBarContainer_non' style={{marginTop:"15px"}}>
                    <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                    <div onClick={handleSearch} className='searchButton'>
                        <i className='fa fa-search'></i>
                    </div>    
                </div>
                <div className='gapOntopTable tableContainerFullWidth' 
                    style={{height: "60vh",
                    color:'var(--titleColor)'
                    }}>
                    {displayAreaList.map((c, ind)=>
                        <div 
                            className='tableItem' 
                            key={`areaKey_${ind}`}
                            id={`areaId_${ind}`}
                            onClick={()=>selArea(ind, c)}
                        > {c.name}</div>
                    )} 
                </div>
                
                <div className="buttonContainer buttonSpreadEvenly" 
                    style={{
                        marginTop:"25px",
                        marginBottom:"20px",
                        }}>
                    <div className="spring_roundButton spring_greyColor hoverPointer" onClick={backPrevPg}>
                        Back
                    </div>
                    <div className="roundButton" onClick={handleConfirm}>Confirm</div>
                </div>
            </div>
        </div>
    );
}

export default ChooseArea;