// import jwtFn from 'jsonwebtoken';
import { jwtSignToken } from '../../service/jwt/joseService';
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

let tokenKey = "v2EditShare";

async function setToken(editShareCfg) {
    // console.log(bdInfo);
    try {
        // const token = jwtFn.sign({
        //     // lastCheckUser:timeFn.getUnixNow(),
        //     user_id:editShareCfg.user_id,
        //     username:editShareCfg.username,
        //     shareLevel:editShareCfg.shareLevel,
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY);

        const payload = {
            user_id:editShareCfg.user_id,
            username:editShareCfg.username,
            shareLevel:editShareCfg.shareLevel,
        }

        localStorage.setItem(tokenKey, await jwtSignToken(payload));
    } catch (error) {
        // console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}

function getToken() {
    try {
        let token = localStorage.getItem(tokenKey);
        return jwtDecode(token);   
    } catch (error) {
        // toast('Get Token Error');
        return null
    }
}

export function clearToken() {
    try {
        localStorage.removeItem(tokenKey);
        return true;
    } catch (error) {
        toast('Clear Token Error');
        return null
    }
}

const expModule = {  
    setToken,
    getToken,
    clearToken,
};

export default expModule;