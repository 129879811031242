import React, { useEffect, useState } from "react";
import { GrRevert } from "react-icons/gr";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { toast } from "react-toastify";
import v2RjFn from "../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import { F_GetDefaultFilterDuration } from "../CommonFn/SubTpCommonFn";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";

const TabSettingFilterDetails = (props) => {
  const G_MaxChar = 80;
  const G_DefaultFilterTime = F_GetDefaultFilterDuration();

  const [G_CleanLevel, setG_CleanLevel] = useState(0);
  const [G_UseDefaultTimer, setG_UseDefaultTimer] = useState(true);
  const [G_CharCnt, setG_CharCnt] = useState(0);
  
  const [G_FilterTime, setG_FilterTime] = useState(0);
  const [G_RunTime, setG_RunTime] = useState(0);

  useEffect(() => {
    async function startUp() {
      await fLoadLastCleanFilterData();
      // load cleaning history
      // get the latest cleaning time minus current running time.
      // convert the latest running time to H
    }
    startUp();

    return () => {
      // alert("Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const fLoadLastCleanFilterData=async()=>{
    let lastCleanInfo = await v2RjFn.V2_RjLastRjCleanFilterInfo(props.dev._id);
        
    let runTimeSinceLastClean;
    let filterDuration = G_DefaultFilterTime;
    if(notEmptyArr(lastCleanInfo)){  // clean before
      runTimeSinceLastClean=props.lastData.pn_3 - lastCleanInfo[0].lastCleanTime;      
      filterDuration = lastCleanInfo[0].filterTime;
    }else{  // is 1st time clean
      runTimeSinceLastClean = props.lastData.pn_3;
    }
    setG_FilterTime(filterDuration);
    setG_RunTime(runTimeSinceLastClean);    

  }
  

  const fBackToFilterPg = () => {
    if (props.fToTab) props.fToTab(35);
    // if(props.discardChanges) props.discardChanges();
  };

  const fSetCleanLvl = (cleanLevel) => {
    setG_CleanLevel(cleanLevel);
    // if(props.discardChanges) props.discardChanges();
  };

  const fToggleUseDefault = () => {
    setG_UseDefaultTimer(!G_UseDefaultTimer);
  };

  const fConfirmCleanFilter = async() => {
    // setG_UseDefaultTimer(!G_UseDefaultTimer);
    let sRemarks = document.getElementById(`r_Filter_${props.pos}`).value;
    let timer=0;
    if (!G_UseDefaultTimer) {
      timer = parseInt(
        document.getElementById(`Rj_FilterTimer_${props.pos}`).value
      );
      if (isNaN(timer)) return toast("Invalid timer value");
      if(timer===0) return toast("Timer value cannot be 0");
    }


    /** set next clean time */
    let nextCleanTime = timer*3600;
    if(G_UseDefaultTimer){
      if(G_CleanLevel===1){
        nextCleanTime = G_FilterTime*1.3;
      }else if(G_CleanLevel===2){
        nextCleanTime = G_FilterTime*1.1;
      }else if(G_CleanLevel===4){
        nextCleanTime = G_FilterTime*0.9;
      }else if(G_CleanLevel===5){
        nextCleanTime = G_FilterTime*0.75;
      }else{
        nextCleanTime = G_FilterTime*1;
      }
    }

    let maintenanceInfo = {
      serviceType:1,
      value_1:G_CleanLevel,    // clean level
      value_2:G_UseDefaultTimer?1:0,    // use default timer
      value_3:G_RunTime,    // last running duration
      value_4:nextCleanTime,    // next suggest timer
      remarks:sRemarks
    }

    let cleanFilterInfo={
      lastCleanTime:props.lastData.pn_3,    // get from last data
      filterTime:nextCleanTime        // next suggest timer
    }

    let logCleanRel = await v2RjFn.V2_RjLogCleanFilter(props.dev._id, maintenanceInfo, cleanFilterInfo);
    if(logCleanRel.errMsg) return toast(`Log clean record err: ${logCleanRel.errMsg}`);
    toast("Success");
    fBackToFilterPg();
    if(props.fCleanFilterSuccess) props.fCleanFilterSuccess();
  };

  const fForceToNonDicimalPoint = () => {
    let timer = parseInt(
      document.getElementById(`Rj_FilterTimer_${props.pos}`).value
    );
    document.getElementById(`Rj_FilterTimer_${props.pos}`).value = timer;
  };

  const fCap80Char = () => {
    let maxChar = G_MaxChar;
    let sRemarks = document.getElementById(`r_Filter_${props.pos}`).value;

    if (sRemarks.length > maxChar) {
      let new_sRemarks = sRemarks.slice(0, maxChar);
      document.getElementById(`r_Filter_${props.pos}`).value = new_sRemarks;
      setG_CharCnt(new_sRemarks.length);
    } else {
      setG_CharCnt(sRemarks.length);
    }
  };

  return (
    <div className="spring_editScenePg">
      <div className="sortHor" style={{ marginBottom: "5px" }}>
        <div className="spring_ManuTitle">Clean Filter</div>
        <div
          className="spring_ManuTitleBackIcon stayRight hoverPointer"
          onClick={fBackToFilterPg}
        >
          <GrRevert />
        </div>
      </div>

      <div className="spring_RjSettingGroupTitle">Service & Maintenance</div>

      <div className="spring_editScheGroup_SmallSpaceContainer">
        <div className="spring_editScheGroup_SmallSpace">
          <div
            className="spring_editItemBar sortHor hoverPointer"
            // onClick={fToFilterTab}
          >
            <div className="spring_editLeftTitle">
              How was the filter condition?
            </div>
            <div className="spring_editRightItem  flexAndBothCenter">
              {/* <BsFillTriangleFill className="rotate90deg text_0_8 marginR_5px blueText" /> */}
            </div>
          </div>
          <div className="flexHorWrap spring_filterLvlSelContainer">
            <div
              className={`spring_rjFilterSel ${
                G_CleanLevel === 1 ? "spring_rjFilterSelected" : ""
              } hoverPointer`}
              onClick={() => fSetCleanLvl(1)}
            >
              Very clean
            </div>
            <div
              className={`spring_rjFilterSel ${
                G_CleanLevel === 2 ? "spring_rjFilterSelected" : ""
              } hoverPointer`}
              onClick={() => fSetCleanLvl(2)}
            >
              Clean
            </div>
            <div
              className={`spring_rjFilterSel ${
                G_CleanLevel === 3 ? "spring_rjFilterSelected" : ""
              } hoverPointer`}
              onClick={() => fSetCleanLvl(3)}
            >
              Moderately Clean
            </div>
            <div
              className={`spring_rjFilterSel ${
                G_CleanLevel === 4 ? "spring_rjFilterSelected" : ""
              } hoverPointer`}
              onClick={() => fSetCleanLvl(4)}
            >
              Dirty
            </div>
            <div
              className={`spring_rjFilterSel ${
                G_CleanLevel === 5 ? "spring_rjFilterSelected" : ""
              } hoverPointer`}
              onClick={() => fSetCleanLvl(5)}
            >
              Very dirty
            </div>
            <div
              className={`spring_rjFilterSel ${
                G_CleanLevel === 6 ? "spring_rjFilterSelected" : ""
              } hoverPointer`}
              onClick={() => fSetCleanLvl(6)}
            >
              I don't know
            </div>
          </div>
        </div>

        <div style={{ margin: "15px 0px" }}></div>

        <div className="spring_RjSettingGroupTitle">Cleaning Reminder</div>
        <div className="spring_editScheGroup_SmallSpaceContainer">
          <div className="spring_editScheGroup_SmallSpace">
            <div
              className="spring_editItemBar sortHor hoverPointer"
              // onClick={fToFbSensorTab}
            >
              <div className="spring_editLeftTitle">Use Suggested</div>
              <div
                className="spring_scheActive_Icon flexAndBothCenter hoverPointer"
                onClick={fToggleUseDefault}
              >
                {!G_UseDefaultTimer ? (
                  <BsToggleOff />
                ) : (
                  <BsToggleOn className="reactCfgButtonOn" />
                )}
              </div>
            </div>

            {!G_UseDefaultTimer && (
              <div className="spring_editItemBar sortHor">
                <div className="spring_editLeftTitle">Timer (h)</div>

                <input
                  style={{ width: "50px", textAlign: "end" }}
                  type={"number"}
                  className=" i_time_contentInput hideNumberUpDown stayRight"
                  id={`Rj_FilterTimer_${props.pos}`}
                  placeholder="--"
                  step="1"
                  onChange={fForceToNonDicimalPoint}
                ></input>
              </div>
            )}
          </div>
        </div>

        <div style={{ margin: "15px 0px" }}></div>

        <div className="sortHor">
          <div className="spring_RjSettingGroupTitle">Remarks (Optional):</div>

          <div className="spring_RjSettingGroupTitle spring_RjSettingGroupTitle_stayRight">{`${G_MaxChar - G_CharCnt}/${G_MaxChar}`}</div>
        </div>

        <div className="spring_editScheGroup_SmallSpaceContainer">
          <div className="spring_editScheGroup_SmallSpace">
            <div
              className="spring_editItemBar sortHor hoverPointer"
              // onClick={fToFbSensorTab}
            >
              <textarea
                id={`r_Filter_${props.pos}`}
                rows="2"
                className="spring_filterTextArea"
                placeholder="<Insert remarks>"
                onChange={fCap80Char}
              />
            </div>
          </div>
        </div>

        <div
          className="spring_fullButton_Green hoverPointer"
          onClick={fConfirmCleanFilter}
        >
          Confirm
        </div>

        <div style={{ margin: "15px 0px" }}></div>
      </div>
    </div>
  );
};

export default TabSettingFilterDetails;
