import React, { useEffect, useRef, useState } from "react";
import ctrlTp from "../../CtrlCmdTemplate/CtrlTemplate";
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";
import ctrlCmdLib from "../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib";
import v2ctrlFn from "../../../../../../function/V2_Control/V2_ControlFn";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import TpDiaSending from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSending";
import TpDiaSuccess from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess";
import TpDiaFailed from "../../../../Control/Common/Dialog/StatusDialog/TpDiaFailed";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineAdjustments } from "react-icons/hi";
import MRVfdSetting from "./MRVfdSetting";
import MRVfdCtrlMethod from "./MRVfdCtrlMethod";

const SubTpMrRogerVFD = (props) => {
  /**------Ctrl Standard 1/2 Start------ */
  const cmdTimeout = 10000; // 10 sec
  const msgTimeout = 3000; // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  const [G_GwID, setG_GwID] = useState();

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refCmdLog = useRef({});
  /**======Ctrl Standard 1/2 End====== */

  const [G_ShowingTab, setG_ShowingTab] = useState(0);

  const ref_VfdSetting = useRef(); // parent trig child
  const ref_CtrlMethod = useRef();   // parent trig child

  const refVfdSetting_payload = useRef({});
  const refCtrlMethod_payload = useRef({});

  useEffect(() => {
    let socket;
    
    async function startUp() {
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);

      await F_LoadGateway();

      let topic = ctrlTp.F_GetIoTopic(props.dev);
      socket.on(topic, async (data) => {
        if((data.hf === 3 || data.hf === 4) && data?.pn?.[0] === 12345) {
            clearTimeout(cmdTimeoutRef.current);
            setG_diaErrMsg("Incorrect Password");
            setG_statusState(3);
        } else if(
          ctrlTp.F_HandleNodeReply(
            data,
            refCmdLog.current,
            cmdTimeoutRef.current,
            setG_statusState
          )
        ) {
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          await handleServerReq(data);
        }
      });

      // if (ref_modbusDevice.current) {
      //   observer.observe(ref_modbusDevice.current, { childList: true, subtree: true });
      // }
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);
      // clearInterval(intervalId); // Ensure interval is cleared on unmount
    };
    // eslint-disable-next-line
  }, []);
  /**-----------Ctrl Standard 2/2 start------------- */
  const F_LoadGateway = async () => {
    let pairedGw = await v2ctrlFn.getV2_GwPair(props.dev._id);
    if (pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    let { gwPairList } = pairedGw;
    if (!notEmptyArr(gwPairList))
      return toast("No gateway pair to this device");
    setG_GwID(gwPairList[0].gwid);
  };

  const F_loadDbVfdSetting = async() => {
    let vfdSettingInfo = await v2ctrlFn.getV2_MrEeprom_byFn(props.dev._id, 3);
    if(vfdSettingInfo.errMsg) return toast(`Cmd Err: ${vfdSettingInfo.errMsg}`);
    let pf = [], pi = [];
    if(notEmptyArr(vfdSettingInfo)){
      pf  = [ ...vfdSettingInfo[0].payload.pf ];
      pi  = [ ...vfdSettingInfo[0].payload.pi ];
    }
    
    ref_VfdSetting.current?.uploadVfdSetting("db", pf, pi);
  }

  const F_loadDbCtrlMethod = async() => {
    let ctrlMethodInfo = await v2ctrlFn.getV2_MrEeprom_byFn(props.dev._id, 4);
    if(ctrlMethodInfo.errMsg) return toast(`Cmd Err: ${ctrlMethodInfo.errMsg}`);
    let pf = [];
    if(notEmptyArr(ctrlMethodInfo)) {
      pf = [ ...ctrlMethodInfo[0].payload.pf ];
    }
    ref_CtrlMethod.current.uploadVfdCtrlMethod("db", pf);
  }

  const fCmdTimeout = async () => {
    await ctrlTp.F_CmdTimeout(
      refCmdLog.current,
      setG_diaErrMsg,
      setG_statusState,
      cmdTimeoutRef.current
    );
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
  };

  const statusDiaAutoClose = () => {
    handleCloseDia();
  };

  const handleCloseDia = () => {
    // clearTimeout(cmdTimeoutRef.current);
    // clearTimeout(statusTimeoutRef.current);
    setG_statusState(0);
  };

  const F_setGwId = (gwid) => {
    setG_GwID(gwid);
  }

  const F_SendLoraTp = async (fn, payload) => {
    // console.log("gateway" ,G_GwID);
    let devInfo = { type: props.ht, devID: props.dev.devID };
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(fn, G_GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    setG_statusState(1); // go to sending
    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  };

  const handleServerReq = async (mqttData) => {
    try {
      let { hf, pi, pf } = mqttData;
      if (hf === 13) {
        // console.log("fn 13");
        // console.log(mqttData);
        ref_VfdSetting.current.uploadVfdSetting("node", pf, pi);
      }else if(hf===14){
        // console.log("fn 14");
        // console.log(mqttData);
        ref_CtrlMethod.current.uploadVfdCtrlMethod("node", pf);
      } else if(hf === 3) {
        let setVfdSetting = v2ctrlFn.setV2_MrEeprom(props.dev._id, hf, refVfdSetting_payload.current);
        if(setVfdSetting.errMsg) toast (setVfdSetting.errMsg);
      } else if(hf === 4) {
        let setVfdCtrlMethod = v2ctrlFn.setV2_MrEeprom(props.dev._id, hf, refCtrlMethod_payload.current);
        if(setVfdCtrlMethod.errMsg) toast (setVfdCtrlMethod.errMsg);
      }
    } catch (error) {
      toast(`Server Req Err: ${error.message}`);
    }
  };
  /** ===============Ctrl Standard 2/2 end=============== */

  const F_ToPgNo = (nPgNo) => {
    setG_ShowingTab(nPgNo);
  };

  const F_getVfdSettingPayload = (vfdSetting) => {
    let pf = [
        parseFloat(vfdSetting.maxHz),
        parseFloat(vfdSetting.minHz),
        parseFloat(vfdSetting.step)
    ];

    let pi=[
      parseInt(vfdSetting.delay)
    ];

    return { pf, pi };
  }

  const F_vfdSettingToNode = async (vfdSetting, passCode) => {
    let { pf, pi } = F_getVfdSettingPayload(vfdSetting);
    let payload = { pf, pi, pn: [passCode] }
    // console.log("payload", payload);
    
    await F_SendLoraTp(3, payload);
    refVfdSetting_payload.current = { pf, pi };
  };

  const F_vfdSettingToDb = async(vfdSetting) => {
    setG_statusState(1);
    let payload = F_getVfdSettingPayload(vfdSetting);
    // console.log(payload);
    let setVfdSettingRel = v2ctrlFn.setV2_MrEeprom(props.dev._id, 3, payload);
    if(setVfdSettingRel.success) setG_statusState(2);
    if(setVfdSettingRel.errMsg){
      setG_diaErrMsg("DB Error");
      setG_statusState(3);
    } 
  }

  const F_loadNodeVfdSetting = async () => {
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(13, payload);
  }

  const F_getCtrlMethodPayload = (ctrlMethodInfo) => {
    let pf = [
        parseFloat(ctrlMethodInfo.targetHz)
    ];

    return { pf };
  }
  
  const F_CtrlMethodToNode = async (ctrlMethodInfo, passCode)=>{
    let { pf } = F_getCtrlMethodPayload(ctrlMethodInfo);
    let payload = { pf, pn: [passCode] }
    // console.log("LNodeInfo", LNodeInfo);
    // console.log("payload", payload);
    
    // return toast("Testing Break")
    await F_SendLoraTp(4, payload);
    refCtrlMethod_payload.current = { pf };
  }

  const F_CtrlMethodToDb = async(ctrlMethodInfo) => {
    setG_statusState(1);
    let payload = F_getCtrlMethodPayload(ctrlMethodInfo);
    // console.log(payload);
    let setVfdCtrlMethodRel = v2ctrlFn.setV2_MrEeprom(props.dev._id, 4, payload);
    if(setVfdCtrlMethodRel.success) setG_statusState(2);
    if(setVfdCtrlMethodRel.errMsg){
      setG_diaErrMsg("DB Error");
      setG_statusState(3);
    } 
  }

  const F_loadNodeCtrlMethod =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(14, payload);
  }

  return (
    <div>
        {G_statusState !== 0 && <div className="spring_dialogBlurBg"></div>}
        {G_statusState === 1 && <TpDiaSending />}
        {G_statusState === 2 && <TpDiaSuccess onClickDiaClose={handleCloseDia} />}
        {G_statusState === 3 && (
            <TpDiaFailed onClickDiaClose={handleCloseDia} diaErrMsg={G_diaErrMsg} />
        )}

        {G_ShowingTab === 0 && <MRVfdSetting 
            dev={props.dev}
            pos={props.pos}
            gwId={G_GwID}
            handleLoadNodeVfdSetting={F_loadNodeVfdSetting}
            handleLoadDbVfdSetting={F_loadDbVfdSetting}
            handleSendToNodeVfdSetting={F_vfdSettingToNode}
            handleSendToDbVfdSetting={F_vfdSettingToDb}
            ref = {ref_VfdSetting}
        />}
        {G_ShowingTab === 1 && (
            <MRVfdCtrlMethod
                dev={props.dev}
                pos={props.pos}
                handleLoadNodeCtrlMethod={F_loadNodeCtrlMethod}
                handleLoadDbCtrlMethod={F_loadDbCtrlMethod}
                handleSendToNodeCtrlMethod={F_CtrlMethodToNode}
                handleSendToDbCtrlMethod={F_CtrlMethodToDb}
                ref = {ref_CtrlMethod}
            />
        )}

        <div className="spring_TpBottomBar sortHor">
            <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 0?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(0)}>
                <AiOutlineSetting />
                <div className="spring_TpBtmButtonTitle">Setting</div>
            </div>
            <div
            className={`spring_TpBottomBar_ItemFrame ${
                G_ShowingTab > 0  ? "spring_shadowBox_Small" : ""
                } hoverPointer`}
            onClick={() => F_ToPgNo(1)}
            >
                <HiOutlineAdjustments />
                <div className="spring_TpBtmButtonTitle">Control</div>
            </div>
        
        </div>
    </div>
  );
};

export default SubTpMrRogerVFD;
