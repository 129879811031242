import v2SystemSer from "./../../service/V2_System/V2_System"

async function V2_GetSystemById(bd_id){   
    try {
        let rel = await v2SystemSer.V2_GetSystemById(bd_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function V2_GetCompAuxByCompId(comp_id){   
    try {
        let rel = await v2SystemSer.V2_GetCompAuxByCompId(comp_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

const expModule = {  
    V2_GetSystemById,
    V2_GetCompAuxByCompId
};
export default expModule;