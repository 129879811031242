import React, { useEffect, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { FiPlus, FiTrash2} from "react-icons/fi";
import { notEmptyArr } from '../../../function/ArrayFn/arrayFn';
// import { C_shareUserLevel } from '../../../v2Constant/v2_userLevel';
import userLvlFn from '../../../v2Constant/v2_userLevel';
import { toast } from 'react-toastify';
import OwnerDevFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import localStorageFn from '../../../function/localStorage/LocalStorageFn';

/**
 * done - add email page
 * click to change access level
 * done - click add to insert email
 */

function ShareDevPg_V02(props) {
    
    const [G_emailList, setG_emailList] = useState([]);
    const [G_bd_id, setG_bd_id] = useState(0);
    // const [G_sharedUserLvlId, setG_sharedUserLvlId] = useState(0);
    const [G_sharedUserLvlObj, setG_sharedUserLvlObj] = useState({});
    const [G_bSharing, setG_bSharing] = useState(false);
    const [G_curUserLvlv, setG_curUserLvlv] = useState(0);

    useEffect(() => {
        async function startUp() {
            loadBd_id();
            loadEmailList();
            let curUserLvlv = await userLvlFn.getCurUserAccessLvl();
            setG_curUserLvlv(curUserLvlv);
            setG_sharedUserLvlObj(userLvlFn.C_shareUserLevel[0]);
            setShareUserLvlDisplay(userLvlFn.C_shareUserLevel[0]);
            
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);
    
    const loadBd_id=()=>{
        let bdInfo = localStorageFn.getBdToken();
        // console.log("bdInfo", bdInfo);    
        if(!bdInfo || !bdInfo._id) {
            props.history.push({pathname: "/"});    // kick out from current page
            return 
        }
        setG_bd_id(bdInfo._id);
    }    

    const handleChangeUserLevel=async()=>{
        
        // let curUserLvlv = await userLvlFn.getCurUserAccessLvl();
        let nextLvlObj = userLvlFn.getNextLvlObjBy_Val(G_sharedUserLvlObj.value, G_curUserLvlv);     
        // setG_sharedUserLvlId(nextLvlObj.value)
        setG_sharedUserLvlObj(nextLvlObj)
        setShareUserLvlDisplay(nextLvlObj)

        // let nIndex = G_sharedUserLvlId+1;
        // if(nIndex>=userLvlFn.C_shareUserLevel.length){
        //     nIndex=0;
        // }
        // setG_sharedUserLvlId(nIndex)
        // setShareUserLvlDisplay(userLvlFn.C_shareUserLevel[nIndex])

    }    

    const setShareUserLvlDisplay=(userLevel)=>{
        document.getElementById('tv_accessRight').innerHTML = userLevel.accessRight;
        document.getElementById('tv_userLevel').innerHTML = userLevel.name;
    }


    const loadEmailList=()=>{
        
        let {_data:emailList} = props.location;
        // console.log("emailList", emailList);
        if(!notEmptyArr(emailList)) return // console.log("Empty List"); // if empty array, return
        setG_emailList(emailList)
    }

    const backPrevPg=(bToBdPage)=>{
        if(bToBdPage){
            props.history.push({pathname: "/data/sensorinbd"});
        }else{
            props.history.push({pathname: "/data/sensorinbd/setting"});
        }
        
    }

    const hanldeAddEmail=()=>{
        props.history.push({
            pathname: "/device/sharev2/addemail",
            _data:G_emailList
        });    
    }

    const hanldeRemoveEmail=(ind)=>{
        let newEmailArr = [...G_emailList]
        newEmailArr.splice(ind, 1);
        setG_emailList(newEmailArr)
    }


    const hanldeShareEmail=async ()=>{
        try {
            if(!G_bSharing){
                // console.log("Come in");
                setG_bSharing(true);
                /** display inserted info */
                let accessLevel = G_sharedUserLvlObj.value;
                if(G_emailList.length<1) return toast('Please add receiver')
    
                let shareRel = await OwnerDevFn.addShareUser(G_bd_id, accessLevel, G_emailList)
                if(!shareRel.success) {
                    toast("Share Device Failed (DB Error)")
                }else{
                    toast("Success")
                    backPrevPg(true);
                }                
            }
        } catch (error) {
            toast("Share User Error (Exp)")
        } finally{
            // console.log("Share Finally");
            setG_bSharing(false);
        }

    }
    
    
    return (
        <div>            
            <div className="backContainer">
                <div className="backIconContainer" onClick={()=>backPrevPg()}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Share</div>
            </div>

            <div className='cfgCard_NoPadding cfgCardColor'
            id={`receiverBar`}>

                <div className='sortHor cardtitle'>
                    <div>Share To</div>
                    <div className='stayRight stayRightElem cfgAddEmailButton'
                    onClick={hanldeAddEmail}>
                        <FiPlus/>
                    </div>
                </div>

                {G_emailList.length > 0 && G_emailList.map((c,ind)=>
                    <div key={`rcvEmailKey_${ind}`}>
                        {ind!==0 && <div className='divSeperateLine'></div>}
                
                        <div className='sortHor'>
                            <div className='cfgEmailWidth listPadding wrapTextEllipsis blueText'
                            key={`rcvEmailKey_1`}>
                                {c}
                            </div>
                            <div className='stayRight stayRightElem cfgAddEmailButton redText'
                            onClick={()=>hanldeRemoveEmail(ind)}>
                                <FiTrash2/>
                            </div>
                        </div>
                    </div>
                )}
                
            </div>

            <div className='cfgCard cfgCardColor'>
                <div className='sortHor'>                    
                    <div className='sortVer'>
                        <div>Access Level</div>
                        <div id='tv_accessRight' className='cfgSmallDesc'>Full access to all devices</div>
                    </div>
                    <div id='tv_userLevel' className='stayRight stayRightElem cfgAccessLevelFrame'
                    onClick={handleChangeUserLevel}>
                        Admin
                    </div>
                </div>
            </div>
            
            <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" onClick={hanldeShareEmail}>Share</div>
            </div>
        </div>
    );
}

export default ShareDevPg_V02;