import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

function TpChooseSensorPara(props) {
    const [G_SelList, setG_SelList] = useState([]);
    const [G_displaySelList, setG_displaySelList] = useState([]);

    useEffect(()=>{
        async function startUp(){
            F_SetSelList();
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const F_SetSelList=()=>{
        if(props.selList && props.currSelList) {
            let _selList = [...props.selList];
            setG_SelList(_selList);
            setG_displaySelList(_selList);
        }
    }
    const handleItemSel=(info)=>{
        if(props.onClickItemSel) {
            // if(info.selected === 2) return;
            if(F_OcupyBuOtherDisplayTab(info)) return;
            props.onClickItemSel(info);
        }
    }

    const handleCloseDialog=()=>{
        if(props.onClickCloseDia) props.onClickCloseDia();
    }

    const handleSearch = () => {
        let searchInput = document.getElementById('tpSensorPara_searchInput').value;
        if(searchInput==="") {
            setG_displaySelList(G_SelList);
            return;
        }
        let filtered = G_SelList.filter((c)=>c.dataName.toUpperCase().search(searchInput.toUpperCase()) >= 0);
        setG_displaySelList(filtered);
    }

    const F_ObjMatch=(obj1, obj2)=>{
        let match = JSON.stringify(obj1) === JSON.stringify(obj2);
        return match
    }

    const F_OcupyBuOtherDisplayTab=(curObj)=>{
        for (let i = 0; i < props.currSelList.length; i++) {            
            if(F_ObjMatch(props.currSelList[i], curObj) &&  // selected
            props.displaySlotIdx !== i+1){    // selected bu other channel
                return true
            }
        }
        return false;
    }
    

    return (
        <div>
            <div className='spring_dialogBoxContainer '>
                <div className='tpDialogFrame'>
                    <div className='divStickOnTop'>
                        <div className ='searchBarContainer_90P stickSearchBar' style={{margin: "20px 15px 15px 15px"}}>
                            <input onChange={handleSearch} id='tpSensorPara_searchInput' className='searchInput' />       
                            <div onClick={handleSearch} className='searchButton'>
                                <i className='fa fa-search'></i>
                            </div>    
                        </div>
                    </div>
                    <div className='tpParamContainer'>
                        {G_displaySelList.map((u, ind_k)=>(
                            // <div className={u.selected === 2? 'tpParamSel sortHor' : 'tpParamSel_clickable tpParamSel sortHor'} key={`dev_${ind_k}`} 
                            <div className={F_OcupyBuOtherDisplayTab(u) ? 'tpParamSel sortHor' : 'tpParamSel_clickable tpParamSel sortHor'} key={`dev_${ind_k}`} 
                            onClick={()=>handleItemSel(u)}>
                                {/* <div className={u.selected === 2 ? 'tpDialog_greyFill tpDialog_checked flexAndBothCenter' : 'tpDialog_checked flexAndBothCenter'}> */}
                                <div className={F_OcupyBuOtherDisplayTab(u) ? 'tpDialog_greyFill tpDialog_checked flexAndBothCenter' : 'tpDialog_checked flexAndBothCenter'}>
                                
                                    {/* {(u.selected === 1 || u.selected === 2) && <FaCheck />} */}
                                    {(F_ObjMatch(u, props.currSelList[0]) || F_ObjMatch(u, props.currSelList[1])) && <FaCheck />}
                                    
                                </div>
                                <div className='sortVer tpDialog_content'>
                                    <div className='tpDialog_name'>{u.dataName}</div>
                                    <div className='flexOnly spreadBetween tpDialog_small'>
                                        <div>{u.dataIndex === -1? props.lastData[`${u.dataType}`] : props.lastData[`${u.dataType}_${u.dataIndex}`]}</div>
                                        <div className='tpDialog_unit'>{u.dataUnit? `(${u.dataUnit})` : ''}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='tpDialogClose divStickOnBottom' onClick={handleCloseDialog}>
                        <AiOutlineCloseCircle />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpChooseSensorPara;