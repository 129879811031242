import React, { Component } from 'react';


class WarningMsg extends Component {
    state = {  }
    render() { 
        return ( <>
        <h3>
            User Not Active
        </h3>
        </> );
    }
}
 
export default WarningMsg;