import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from 'reselect';
import { sortTemplate } from "../../function/ArrayFn/arrayFn";
import { clearBitByIdx, getBitByIdx, setBitByIdx } from "../../function/common/common";
import { condiList } from "../../g2_Component/Page/Reaction/cfgPara/condiOperatorFn";

const actionSelectedList_Init={
    tele:false,
    email:false,
}

const sensor_Init={
    _id:0,
    type:0,
    name:"",
    
    paraName:"",
    paraKey:"",
    paraUnit:"",
}
// eslint-disable-next-line
const condi_init={
    cd_operator:'>=',
    cd_operatorName:'Greater or Equal',
    cd_grdDuration:null,

    cd_condiName:"",
    cd_condiSetpoint:null,    

    cd_delayType:0,
    cd_delayValue:null,
    cd_triggerActive:0,     

    cd_forName:null,
    cd_forFormula:null,
    cd_for_id:0,
    cd_forModeSelected:0,     // for display button purpose

    // cd_varSel:'',      // to determine select sensor page value belong to
    cd_var_x:sensor_Init,
    cd_var_y:sensor_Init,
    cd_var_z:sensor_Init,
    cd_var_a:sensor_Init,
    cd_var_b:sensor_Init,
    cd_var_c:sensor_Init,
    cd_var_s:sensor_Init,   // set point
    cd_var_v:sensor_Init,   // variable

    cd_spType:0,

}

// main structure here
const initState={
    cd_idx:2,           // not in use
    condiList : [],     // not in use

    Condi_A:condi_init,
    Condi_B:condi_init,
    Condi_C:condi_init,
    Condi_X:condi_init,
    Condi_Y:condi_init,
    Condi_Z:condi_init,

    cd_operator:'>=',
    cd_operatorName:'Greater or Equal',
    cd_grdDuration:null,

    cd_sensorType:0,        // exclude in init
    cd_sensorName:"",       // exclude in init
    cd_bdDev_id:0,          // exclude in init
    
    cd_paraName:"",         // exclude in init
    cd_paraKey:"",          // exclude in init
    cd_paraUnit:"",         // exclude in init

    cd_condiName:"",
    cd_condiSetpoint:null,    

    cd_delayType:0,
    cd_delayValue:null,
    cd_triggerActive:0,     

    cd_forName:null,
    cd_forFormula:null,
    cd_for_id:0,
    cd_forModeSelected:0,     // for display button purpose

    cd_varSel:'',      // to determine select sensor page value belong to
    cd_var_x:sensor_Init,
    cd_var_y:sensor_Init,
    cd_var_z:sensor_Init,
    cd_var_a:sensor_Init,
    cd_var_b:sensor_Init,
    cd_var_c:sensor_Init,
    cd_var_s:sensor_Init,   // set point
    cd_var_v:sensor_Init,   // variable

    cd_spType:0,

    condiSelected:"",
    
    ag_sAlgo:'',

    act_tele_subList:[],

    act_tele_newGroupContact:[],
    act_tele_newGroupName:null,

    addNewContact_Mode:0,
    addSub_Mode:0,    
    act_tele_defaultSubList:[],
    act_tele_defSubChecked:true,

    actionSelectedList:actionSelectedList_Init,

    teleBackPgMode:0,

    event_name:null,
    event_desc:null,
    event_trigMode:0,
    dowSel:62,      // weekday only
    event_StartTime:null,
    event_EndTime:null,
    event_RefreshTime:null,

    event_sel_desc:false,
    event_sel_wholeDay:false,
    event_sel_refreshDaily:true,
    event_sel_repeatDaily:true,
}



const cd_initState={
    name :"",
    inputType :0,       // 0= value, 1=function
    setpoint :0,      // 1=> Owner, 2=> Admin, 3=> Guest
    dataKey :"",
    operator:"",
    occurBuffer :0,
    gradientDuration :0,
}

const slice = createSlice({
    name:'reactCfgAlgo',
    initialState:initState,
    reducers:{        
        setCondiName:(state, action)=>{    
            let cd = {...cd_initState};
            cd.name = action.payload.name;
            // fill up previous empty slot
            if(state.condiList.length > state.cd_idx){     // 3 2 , 2 2
                state.condiList[state.cd_idx]=cd;
            }else{
                while (state.condiList.length < state.cd_idx) {
                    state.condiList.push(cd_initState);                
                }
                state.condiList.push(cd);   
            }
            // state.name = action.payload.name;
            // state.bd_id = action.payload.bd_id;            
        },     
        setCurOp:(state, action)=>{    
            state.cd_operator = action.payload.operator;       
            state.cd_operatorName = action.payload.name;       
        },     
        setSelSensor:(state, action)=>{    
            state.cd_bdDev_id = action.payload._id;
            state.cd_sensorType = action.payload.type;     
            state.cd_sensorName = action.payload.name;     
        }, 
        setSelPara:(state, action)=>{    
            state.cd_paraName = action.payload.name;       
            state.cd_paraKey = action.payload._id;
            state.cd_paraUnit = action.payload.unit;
        },    
        clearSelPara:(state, action)=>{    
            state.cd_paraName = initState.cd_paraName;       
            state.cd_paraKey = initState.cd_paraKey;
            state.cd_paraUnit = initState.cd_paraUnit;
        },  
        setName:(state, action)=>{                
            state.cd_condiName = action.payload.name;   
        },    
        setSetpoint:(state, action)=>{                
            state.cd_condiSetpoint = action.payload.setpoint;   
        },    
        resetCondi:(state, action)=>{    
            for (const key in state) {
                if (Object.hasOwnProperty.call(state, key)) {
                    state[key] = initState[key];
                }
            }            
        },
        clearCondiForm:(state, action)=>{    
            for (const key in condi_init) {
                if (Object.hasOwnProperty.call(state, key)) {                    
                    state[key] = initState[key];
                }
            }            
        },
        setDelayType:(state, action)=>{    
            state.cd_delayType = action.payload;       
        },
        setDelayValue:(state, action)=>{    
            state.cd_delayValue = action.payload;       
        },
        setTriggerActive:(state, action)=>{    
            state.cd_triggerActive = action.payload;       
        },
        setGrdDuration:(state, action)=>{    
            state.cd_grdDuration = action.payload;       
        },        
        setFormulaSelected:(state, action)=>{    
            state.cd_forModeSelected = action.payload;       
        },
        setFormulaInfo:(state, action)=>{    
            state.cd_forName = action.payload.name;
            state.cd_forFormula = action.payload.formula;
            state.cd_for_id = action.payload._id;
        },
        setVarSelect:(state, action)=>{    
            state.cd_varSel = action.payload;
        },
        setSensorParaForVar:(state, action)=>{       
                 
            let sensor ={
                _id:state.cd_bdDev_id,
                type:state.cd_sensorType,
                name:state.cd_sensorName,
                
                paraName:action.payload.name,
                paraKey:action.payload._id,
                paraUnit:action.payload.unit,
            }

            state[`cd_var_${state.cd_varSel}`] = sensor;
            // state.cd_var_x = sensor;
        },
        clearEachVar:(state, action)=>{    
            state.cd_var_x = sensor_Init;
            state.cd_var_y = sensor_Init;
            state.cd_var_z = sensor_Init;
            state.cd_var_a = sensor_Init;
            state.cd_var_b = sensor_Init;
            state.cd_var_c = sensor_Init;
            // state.cd_var_s = sensor_Init;
        },  
        setSpType:(state, action)=>{    
            state.cd_spType = action.payload;
        },
        setCondiSelected:(state, action)=>{    
            state.condiSelected = action.payload;
        },
        setToCondiList:(state, action)=>{    
            for (const key in condi_init) {
                if (Object.hasOwnProperty.call(condi_init, key)) {
                    // const element = condi_init[key];
                    state[`Condi_${state.condiSelected}`][key] = state[key];
                }
            }     
        },
        dbInserToCondiList:(state, action)=>{    
            for (const key in condi_init) {
                if (Object.hasOwnProperty.call(condi_init, key)) {
                    // const element = condi_init[key];
                    let condiSel = action.payload.condiSel.toUpperCase();
                    state[`Condi_${condiSel}`][key] = action.payload.condi_redux[key];
                }
            }     
            // dbInserToCondiList("A", {condi_init})
        },
        setToConfiForm:(state, action)=>{    
            for (const key in condi_init) {
                if (Object.hasOwnProperty.call(condi_init, key)) {
                    // const element = condi_init[key];
                    state[key] = state[`Condi_${state.condiSelected}`][key];
                }
            }     
        },
        clearSelectedCondi:(state, action)=>{    
            let condiSelected = action.payload;
            let selPos = condiList.findIndex(c=> c === condiSelected);
            if(selPos<0) return     // invalid 
            /** switch pos, assume 1 */
            for (let i = selPos; i < condiList.length-1; i++) {           
                for (const key in condi_init) {
                    if (Object.hasOwnProperty.call(condi_init, key)) {
                        state[`Condi_${condiList[i]}`][key] = state[`Condi_${condiList[i+1]}`][key]
                    }
                }
            }
            /** clear last condi */
            for (const key in condi_init) {
                if (condi_init.hasOwnProperty.call(condi_init, key)) {
                    state[`Condi_Z`][key] = condi_init[key];                    
                }
            }            
        },
        setAlgo:(state, action)=>{    
            state.ag_sAlgo = action.payload;
        },
        addTeleSubList:(state, action)=>{    
            /** make sure _id is unique */
            let tempSubList = [];
            if(state.addSub_Mode===1) tempSubList=state.act_tele_subList;
            else if(state.addSub_Mode===2) tempSubList=state.act_tele_defaultSubList;
            let duplicated = tempSubList.find(c=>(c.subType === action.payload.subType && c.sub_id === action.payload.sub_id))
            if(!duplicated) tempSubList.push(action.payload)          
            
        },
        removeSubFromList:(state, action)=>{    
            let tempSubList = [];            
            if(state.addSub_Mode===1) tempSubList=state.act_tele_subList;
            else if(state.addSub_Mode===2) tempSubList=state.act_tele_defaultSubList;

            let filtered = tempSubList.filter(c=>(!(c.subType === action.payload.subType && c.sub_id === action.payload.sub_id)))
                   
            if(state.addSub_Mode===1) state.act_tele_subList = filtered;
            else if(state.addSub_Mode===2) state.act_tele_defaultSubList= filtered;

            /** 
            let filtered = tempSubList.filter(c=>(!(c.subType === action.payload.subType && c.sub_id === action.payload.sub_id)))
            tempSubList=filtered;
            */
        },
        setDefaultSubList_arr:(state, action)=>{    
            state.act_tele_defaultSubList = action.payload;
        },
        clearDefaultSubList_arr:(state, action)=>{    
            state.act_tele_defaultSubList = initState.act_tele_defaultSubList;
        },
        setNewGroupName:(state, action)=>{    
            state.act_tele_newGroupName = action.payload;
        },
        addContact_NewGroup:(state, action)=>{    
            /** make sure _id is unique */
            let duplicated = state.act_tele_newGroupContact.find(c=>(c.subType === action.payload.subType && c.sub_id === action.payload.sub_id))
            if(!duplicated) state.act_tele_newGroupContact.push(action.payload)            
        },
        clearNewGroupInfo:(state, action)=>{                
            state.act_tele_newGroupContact=[];
            state.act_tele_newGroupName=null;
        },
        removeContact_NewGroup:(state, action)=>{    
            let filtered = state.act_tele_newGroupContact.filter(c=>(!(c.subType === action.payload.subType && c.sub_id === action.payload.sub_id)))
            state.act_tele_newGroupContact=filtered;
        },
        setAddNewContactMode:(state, action)=>{    
            state.addNewContact_Mode = action.payload;
        },        
        setAddSubMode:(state, action)=>{    
            state.addSub_Mode = action.payload;
        },
        toggleDefSubStatus:(state, action)=>{    
            state.act_tele_defSubChecked = !state.act_tele_defSubChecked;
        },
        setActionSelectedList:(state, action)=>{    
            state.actionSelectedList[action.payload.action] =action.payload.status;
        },
        setTeleBackPg:(state, action)=>{    
            state.teleBackPgMode =action.payload;
        },
        setEvent_trigMode:(state, action)=>{    
            state.event_trigMode =action.payload;
        },
        toggleDow:(state, action)=>{    
            let bitPos = action.payload;
            if(getBitByIdx(state.dowSel, bitPos)){
                state.dowSel = clearBitByIdx(state.dowSel, bitPos);
            }else{
                state.dowSel = setBitByIdx(state.dowSel, bitPos);
            }
        },
        setDow:(state, action)=>{
                state.dowSel = action.payload;
        },
        setEvent_StartTime:(state, action)=>{    
            state.event_StartTime =action.payload;
        },
        setEvent_EndTime:(state, action)=>{    
            state.event_EndTime =action.payload;
        },
        setEvent_RefreshTime:(state, action)=>{    
            state.event_RefreshTime =action.payload;
        },
        setEvent_name:(state, action)=>{    
            state.event_name =action.payload;
        },
        setEvent_desc:(state, action)=>{    
            state.event_desc =action.payload;
        },
        toggelEventSel:(state, action)=>{    
            let eventVar = action.payload;
            // let tempBool = state[eventVar];
            state[eventVar] =!state[eventVar];
        },
        setEventState:(state, action)=>{    // modify latyer
            let eventVar = action.payload.variable;
            // let tempBool = state[eventVar];
            state[eventVar] = action.payload.value;
        },
    }
})


/** Export Action */
export const {
    setCondiName,
    setCurOp,
    setSelSensor,
    setSelPara,
    clearSelPara,
    setName,
    setSetpoint,
    resetCondi,
    clearCondiForm,
    setDelayType,
    setDelayValue,
    setTriggerActive,
    setGrdDuration,
    /** formula */
    setFormulaSelected,
    setFormulaInfo,
    setVarSelect,
    /** set each var sensor */
    setSensorParaForVar,
    clearEachVar,
    /** Setpoint Type */
    setSpType,
    /** save load condi */
    setCondiSelected,
    setToCondiList,
    dbInserToCondiList,
    setToConfiForm,
    clearSelectedCondi,
    /** algo */
    setAlgo,
    /** act tele */
    addTeleSubList,
    removeSubFromList,
    setDefaultSubList_arr,
    clearDefaultSubList_arr,
    setNewGroupName,
    addContact_NewGroup,
    clearNewGroupInfo,
    removeContact_NewGroup,
    setAddNewContactMode,
    setAddSubMode,
    toggleDefSubStatus,
    /** action list  */
    setActionSelectedList,
    setTeleBackPg,
    setEvent_trigMode,
    toggleDow,
    setDow,
    setEvent_StartTime,
    setEvent_EndTime,
    setEvent_RefreshTime,
    setEvent_name,
    setEvent_desc,
    toggelEventSel,
    setEventState
} = slice.actions;


/** reducer */
export default slice.reducer;


/** Action Creator, call action */


/** Selector, read from store */
export const getCurOpInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_operator, cd_operatorName, cd_grdDuration}) => ({cd_operator, cd_operatorName, cd_grdDuration})
)

export const getSelSensorInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_sensorType, cd_sensorName, cd_bdDev_id, cd_varSel}) => 
    ({cd_sensorType, cd_sensorName, cd_bdDev_id, cd_varSel})
)

export const getSelParaInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_paraName, cd_paraKey, cd_paraUnit}) => 
    ({cd_paraName, cd_paraKey, cd_paraUnit})
)

export const getCurConditionInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_condiName, cd_condiSetpoint, cd_spType, condiSelected}) => 
    ({cd_condiName, cd_condiSetpoint, cd_spType, condiSelected})
)

export const getTriggerInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_delayType, cd_delayValue, cd_triggerActive}) => 
    ({cd_delayType, cd_delayValue, cd_triggerActive})
)
export const getFormulaInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_forName, cd_forFormula, cd_for_id, cd_forModeSelected}) =>
    ({cd_forName, cd_forFormula, cd_for_id, cd_forModeSelected})
)
export const getEachVarInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({cd_var_x, cd_var_y, cd_var_z, cd_var_a, cd_var_b, cd_var_c, cd_var_v, cd_var_s}) => 
    ({cd_var_x, cd_var_y, cd_var_z, cd_var_a, cd_var_b, cd_var_c, cd_var_v, cd_var_s})
)
export const getEachCondi = createSelector(
    state=> state.react.reactAlgoCfg,
    ({Condi_A, Condi_B, Condi_C, Condi_X, Condi_Y, Condi_Z}) => 
    ({Condi_A, Condi_B, Condi_C, Condi_X, Condi_Y, Condi_Z})
)
export const getAlgoInfo = createSelector(
    state=> state.react.reactAlgoCfg,
    ({ag_sAlgo}) => 
    ({ag_sAlgo})
)
export const getTeleSubList = createSelector(
    state=> state.react.reactAlgoCfg,
    ({act_tele_subList}) => 
    act_tele_subList        // set like this will pass at 1st level
)
export const getContact_NewGroup = createSelector(
    state=> state.react.reactAlgoCfg,
    ({act_tele_newGroupContact}) => 
    act_tele_newGroupContact        // set like this will pass at 1st level
)
export const getAddNewContactMode = createSelector(
    state=> state.react.reactAlgoCfg,
    ({addNewContact_Mode}) => 
    addNewContact_Mode        // set like this will pass at 1st level
)
export const getNewGroupname = createSelector(
    state=> state.react.reactAlgoCfg,
    ({act_tele_newGroupName}) => 
    act_tele_newGroupName        // set like this will pass at 1st level
)
export const getAddSubMode = createSelector(
    state=> state.react.reactAlgoCfg,
    ({addSub_Mode}) => 
    addSub_Mode        // set like this will pass at 1st level
)
export const getBdDefaultSubList = createSelector(
    state=> state.react.reactAlgoCfg,
    ({act_tele_defaultSubList}) => {
        let sortKeys = [
            { key: "subType", descOrder: true },
            { key: "name", descOrder: false },
        ];
        let _R_BdDefaultSubList = [...act_tele_defaultSubList];
        let sortedList = _R_BdDefaultSubList.sort((a, b) => sortTemplate(a, b, sortKeys));
        return sortedList        // set like this will pass at 1st level
    }
)
export const getDefSubChedked = createSelector(
    state=> state.react.reactAlgoCfg,
    ({act_tele_defSubChecked}) => 
    act_tele_defSubChecked        // set like this will pass at 1st level
)
export const getActionSelectedList = createSelector(
    state=> state.react.reactAlgoCfg,
    ({actionSelectedList}) => 
    actionSelectedList        // set like this will pass at 1st level
)
export const getTeleBackPgMode = createSelector(
    state=> state.react.reactAlgoCfg,
    ({teleBackPgMode}) => 
    teleBackPgMode        // set like this will pass at 1st level
)
export const getEventPara = createSelector(
    state=> state.react.reactAlgoCfg,
    ({event_name, event_desc, event_trigMode, dowSel, event_StartTime, event_EndTime, 
        event_RefreshTime, event_sel_desc, event_sel_wholeDay, event_sel_refreshDaily, event_sel_repeatDaily}) => 
    ({event_name, event_desc, event_trigMode, dowSel, event_StartTime, event_EndTime, 
        event_RefreshTime, event_sel_desc, event_sel_wholeDay, event_sel_refreshDaily, event_sel_repeatDaily})        // set like this will pass at 1st level
)
