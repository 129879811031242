import React, { useEffect, useRef, useState } from "react";
import {
  TbSnowflake,
  TbArrowsMaximize,
  TbArrowsDiagonalMinimize2,
} from "react-icons/tb";
import { IoWarningOutline } from "react-icons/io5";
import { RiRemoteControlLine } from "react-icons/ri";
import {
  MdOutlineCleaningServices,
  MdOutlineWaterDrop,
  MdOutlineThermostat,
  MdFilter,
  MdInsertInvitation,
} from "react-icons/md";
import ctrlCmdLib from "../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib";
import socketIOClient from "socket.io-client";
import TpDiaSending from "./../../../Control/Common/Dialog/StatusDialog/TpDiaSending";
import TpDiaSuccess from "./../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess";
import TpDiaFailed from "./../../../Control/Common/Dialog/StatusDialog/TpDiaFailed";
import CircularSlider from "@fseehawer/react-circular-slider";
// import { F_getMaxFanSpeed, F_getMaxSwingPos } from "./CommonFn/SubTpCommonFn";
import v2QueryFn from "../../../../../function/V2_Query/V2_QueryBdDevData";
import { notEmptyArr } from "../../../../../function/ArrayFn/arrayFn";
import TabScene from "./RogerJuniorTab/TabScene";
import TabEditScene from "./RogerJuniorTab/TabEditScene";
import v2RjFn from "../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import v2ctrlFn from "../../../../../function/V2_Control/V2_ControlFn";
import { toast } from "react-toastify";
import sensorFn from "../../../../../function/sensor/sensorManagementFn";
import { getBitByIdx } from "../../../../../function/common/common";
import TpDiaEditLinkSensor from "./RogerJuniorTab/TpDialog/TpDiaEditLinkSensor";
import {
  F_GetLoRaDataType,
  F_MatchUnit,
  F_RjSortSceneVarList,
} from "./RogerJuniorTab/RjFunction/RjFn";
import ownerSensorFn from "../../../../../function/V2_SensorDevice/v2_SensorOwnerFn";
import TabScheduleList from "./RogerJuniorTab/TabScheduleList";
import TabScheListEdit from "./RogerJuniorTab/TabScheListEdit";
import timeFn from "../../../../../function/time/timeFn";
import TabRefreshPg from "./RogerJuniorTab/TabRefreshPg";
import TabSettingEdit from "./RogerJuniorTab/TabSettingEdit";
// import { FaFan } from "react-icons/fa";
// import { TbAngle } from "react-icons/tb";
import { BsToggleOff, BsToggleOn, BsQuestionCircle } from "react-icons/bs";
import { HiOutlineRefresh, HiOutlineTemplate } from "react-icons/hi";
import TpDiaSwapStatus from "./RogerJuniorTab/TpDialog/TpDiaSwapStatus";
import TpDiaForceTrig from "./RogerJuniorTab/TpDialog/TpDiaForceTrig";
import TpDiaInfo from "./RogerJuniorTab/TpDialog/TpDiaInfo";
import TabSettingFeedbackPair from "./RogerJuniorTab/TabSetting_FeedbackPair";
import TabMorePage from "./RogerJuniorTab/Tab_MorePage";
import TabSettingIrFrequency from "./RogerJuniorTab/TabSetting_IrFrequency";
import TabSettingFilterCleaning from "./RogerJuniorTab/TabSetting_FilterCleaning";
import TabSettingFilterDetails from "./RogerJuniorTab/TabSetting_FilterDetails";
import v2RjJsxFn from "./RogerJuniorTab/RjFunction/RjJsxFn";
import TabSettingRjMaintenanceLog from "./RogerJuniorTab/TabSetting_RjMaintenanceLog";
import { getLocking, getRjAcList, loadAcList_locking, setAcList } from "../../../../../reduxStore/actions/app_rj";
import { useDispatch, useSelector } from "react-redux";


function SubTpRogerJr(props) {
  const dispatch = useDispatch();
  const Rdx_AcList = useSelector(getRjAcList);
  const Rdx_ListLoading = useSelector(getLocking);

  const TotalSche = 10;
  // const C_CurSceneKey = "pi_13";

  const cmdTimeout = 10000; // 10 sec
  const msgTimeout = 3000; // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  const [G_CtrlSP_DB, setG_CtrlSP_DB] = useState(16);
  const [G_CtrlSP_Display, setG_CtrlSP_Display] = useState(16);

  const [G_ctrlPara_DB, setG_ctrlPara_DB] = useState({});
  const [G_ctrlPara_Display, setG_ctrlPara_Display] = useState({});
  const [G_ShowConfirmButton, setG_ShowConfirmButton] = useState(false);
  const [G_Loaded, setG_Loaded] = useState(false);
  const [G_SpDefIndex, setG_SpDefIndex] = useState(16);

  const [G_AcBrand, setG_AcBrand] = useState(1);
  const [G_GwId, setG_GwId] = useState(0);

  const [G_HideGauge, setG_HideGauge] = useState(false);
  const [G_RjTab, setG_RjTab] = useState(0);
  const [G_EditSceneSel, setG_EditSceneSel] = useState(1);
  const [G_SceneList, setG_SceneList] = useState([]);
  const [G_LinkVar, setG_LinkVar] = useState([]);
  const [G_LinkVar_CtrlDis, setG_LinkVar_CtrlDis] = useState([]);
  const [G_LinkVar_DB, setG_LinkVar_DB] = useState([]);
  const [G_VarValue, setG_VarValue] = useState([]);
  const [G_SceneTab, setG_SceneTab] = useState(0);
  const [G_EditVar_Sel, setG_EditVar_Sel] = useState({});
  const [G_EditVar_IdxSel, setG_EditVar_IdxSel] = useState(-1);
  const [G_UpdateVarList, setG_UpdateVarList] = useState(false);
  // schedule
  const [G_EditSche, setG_EditSche] = useState({});
  const [G_ScheList, setG_ScheList] = useState([]);
  const [G_ScheList_DB, setG_ScheList_DB] = useState([]);
  const [G_UpdateScheList, setG_UpdateScheList] = useState(false);
  const [G_ScheIdxSel, setG_ScheIdxSel] = useState(-1);
  // setting
  const [G_LastData, setG_LastData] = useState({});
  const [G_UpdateSettingList, setG_UpdateSettingList] = useState(false);
  // eslint-disable-next-line
  const [G_bInSceneMode, setG_bInSceneMode] = useState(false);
  // eslint-disable-next-line
  const [G_CancelScene, setG_CancelScene] = useState(false);
  const [G_IsGragging, setG_IsGragging] = useState(false);
  const [G_LastScene, setG_LastScene] = useState(0);

  const [G_DiaPg, setG_DiaPg] = useState(0);
  const [G_SkipSchedule, setG_SkipSchedule] = useState(false);
  const [G_SkipSchedule_Db, setG_SkipSchedule_Db] = useState(false);
  const [G_SceneCtrlTab, setG_SceneCtrlTab] = useState(1);

  const [G_UpdateFbSensor, setG_UpdateFbSensor] = useState(false);
  const [G_IrHzChanged, setG_IrHzChanged] = useState(false);

  const [G_bShowCleanFilter, setG_bShowCleanFilter] = useState(false);

  

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refTimeoutHideGauge = useRef(null);
  const refCmdLog = useRef({});
  const refShowConfirmButton = useRef(false);

  const refG_LinkVar = useRef([]);
  const refG_ScheList = useRef([]);
  const refG_IsSaveScene = useRef(0);
  const refG_SkipSche = useRef(false);

  // setting
  const refSetting = useRef();
  const refScene = useRef();
  const refFbSensor = useRef();
  const refIrHz = useRef();
  const refSchedule = useRef();
  const refLastData = useRef();

  
  useEffect(() => {
    let socket;
    let socket_data;
    
    // let socketData;
    async function startUp() {
      /** load AC brand list */
      if(!Rdx_ListLoading){ // 1st time laod
        dispatch(loadAcList_locking()); // lock the 
        let _AcList = await v2RjFn.V2_RjGetAcBrand();
        if(_AcList.errMsg) {
          toast(`Loads AC list Err: ${_AcList.errMsg}. Please refresh page`);
          dispatch(setAcList([]));
        }else{
          dispatch(setAcList(_AcList));
        }
      }

      socket_data = socketIOClient(process.env.REACT_APP_PUBLIC_URL_DATALOG);
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);
      let { dev } = props;
      // if(props.ht) setG_Ht(props.ht);
      await loadControlPara();
      let SceneList = await loadScene();
      let linkVar = await loadLinkVar();
      let newLinkVar = await loadUnit(linkVar);
      setG_SceneList(SceneList);
      // dispatch(rjSetSceneList(SceneList));

      let _LinkVar = F_RjSortSceneVarList(newLinkVar);
      setG_LinkVar_CtrlDis(fFilterLinkVar(_LinkVar));
      setG_LinkVar(_LinkVar);
      setG_LinkVar_DB(_LinkVar);
      await GW_loadGwPair(dev._id);

      /** load schedule  */
      let scheList = await loadScheList(dev._id);
      setG_ScheList(scheList);
      setG_ScheList_DB(scheList); //log DB data

      /** load Filter Condition */
      let filterRel = await v2RjJsxFn.F_CalculateRunTime(dev._id, refLastData.current);
      if(filterRel.filterPercentage >=100) setG_bShowCleanFilter(true);

      setG_Loaded(true);
      let topic = `v2_CtrlCmd_${dev.type}_${dev.devID}`;
      socket.on(topic, async (data) => {
        let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
        if (sentSuccess) {
          clearTimeout(cmdTimeoutRef.current);
          setG_statusState(2);
          /** trigger pass countdown */
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          // toast ("Success")
          await handleServerReq(data);

          // setG_ShowConfirmButton(false);
          // refShowConfirmButton.current=false;
          // setG_CtrlSP_DB(G_CtrlSP_Display);
          // setG_ctrlPara_DB(G_ctrlPara_Display);
        }
        /** handle server request */
      });

      let { type, _id } = props.dev;
      let dataTopic = `v2_${type}_${_id}`;
      socket_data.on(dataTopic, async (data) => {
        await loadControlPara(true);

        // let SceneList = await loadScene();
        // let linkVar = await loadLinkVar();
        // let newLinkVar = await loadUnit(linkVar);

        // let _LinkVar = F_RjSortSceneVarList(newLinkVar);

        // setG_SceneList(SceneList);
        // dispatch(rjSetSceneList(SceneList));
        // setG_LinkVar(_LinkVar);
        // setG_LinkVar_DB(_LinkVar);
        // await GW_loadGwPair(dev._id);

        /** load schedule  */
        // let scheList = await loadScheList(dev._id);
        // setG_ScheList(scheList)
        // setG_ScheList_DB(scheList);      //log DB data
      });
    }
    startUp();

    return () => {
      // alert("Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);
      clearTimeout(refTimeoutHideGauge.current);
      clearTimeout(refTimeoutHideGauge.current);
    };
    // eslint-disable-next-line
  }, []);

  const fFilterLinkVar = (linkVarList) => {
    let filterList = linkVarList.filter((c) => c.varIdx);
    return filterList;
  };

  // const getCurActiveScene=(lastData)=>{
  //     if(!Object.hasOwnProperty.call(lastData, C_CurSceneKey)) return "< Empty >";
  //     // log
  //     let curScene = G_SceneList.find(c=>c.sceneIdx === lastData[C_CurSceneKey]);
  //     if(!curScene) return "";
  //     return curScene.Name;
  // }

  const GW_loadGwPair = async (bdDev_id) => {
    try {
      let pairedGw = await v2ctrlFn.getV2_GwPair(bdDev_id);
      if (pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
      let { gwPairList } = pairedGw;
      if (!notEmptyArr(gwPairList))
        return toast("No gateway pair to this device");
      setG_GwId(gwPairList[0].gwid);

      // setG_GwId(100);     // temporary
    } catch (error) {
      toast("loadGwPair err:", error.message);
    }
  };

  const loadScheList = async (Rj_id) => {
    try {
      let scheList = await v2RjFn.V2_RjGetSchedule(Rj_id);
      if (scheList.errMsg) {
        toast("Load schedule Err: ", scheList.errMsg);
        return [];
      }
      return scheList;
    } catch (error) {
      toast("Load schedule Err(Exp): ", error.message);
      return [];
    }
  };

  const loadLinkVar = async () => {
    try {
      /** load link variable */
      let { dev } = props;
      let linkVar = await v2RjFn.getV2_RjOnlineVar(dev._id);
      return linkVar;
    } catch (error) {
      toast(`loadLinkVar Err: ${error.message}`);
      return [];
    }
  };

  const loadUnit = async (linkVar) => {
    try {
      let typeList = [];
      for (const eachVar of linkVar) {
        let found = typeList.find((c) => c === eachVar.sensorType);
        if (!found) typeList.push(eachVar.sensorType);
      }
      let paraList = await sensorFn.getParaByTypeList(typeList);
      let newlinkVar = [];
      for (const eachVar of linkVar) {
        let found = paraList.find((c) => F_MatchUnit(eachVar, c));
        let newVar = { ...eachVar };
        if (found) newVar.unit = found.dataUnit;
        else newVar.unit = "";
        newlinkVar.push(newVar);
      }
      return newlinkVar;
    } catch (error) {
      toast(`loadLinkVar Err: ${error.message}`);
      return [];
    }
  };

  const loadScene = async () => {
    try {
      let { dev } = props;
      if (!dev) return [];
      let sceneList = await v2RjFn.getV2_RjScene(dev._id);
      if (sceneList.errMsg) {
        toast(`Load scene err: ${sceneList.errMsg}`);
        return [];
      }
      return sceneList;
    } catch (error) {
      toast(`loadScene Err: ${error.message}`);
    }
  };

  const loadControlPara = async (bIoSocketUpdate) => {
    let { dev } = props;
    if (!dev) return false;
    let lastData = await v2QueryFn.v2GetData_LastN(dev.type, dev._id, 1);
    
    let defPara = {
      bOn: false,
      fanSpeed: 5,
      swingPos: 1,
      iCtrl: false,
      clean: true,
      cleaning: false,
      opMode: 0,
      lastScene: 0,
      ctrlState: 0,
      softwareVersion: 0,
      lastMode: 0,

      IrPulse: 0,
      SensorTemp: 0,
      SensorHumid: 0,
    };
    let linkVar = [
      { value: 0, valid: false },
      { value: 0, valid: false },
      { value: 0, valid: false },
      { value: 0, valid: false },
      { value: 0, valid: false },
    ];
    let tempSP = 24.0;
    if (notEmptyArr(lastData)) {
      setG_LastData(lastData[0]);
      refLastData.current = lastData[0];
      setG_AcBrand(lastData[0].pi_0); /** set AC brand */

      defPara.bOn = lastData[0].pb_0; //clean mode
      defPara.clean = lastData[0].pb_1; //clean mode
      defPara.cleaning = lastData[0].pb_2; //clean mode

      if (lastData[0].pf_0) tempSP = lastData[0].pf_0; //set server SP cmd
      // if(lastData[0].pf_2) defPara.fanSpeed= lastData[0].pf_2;  //Fan speed
      // if(lastData[0].pf_3) defPara.swingPos= lastData[0].pf_3;  //swing pos
      if (lastData[0].pf_4) defPara.SensorTemp = lastData[0].pf_4; //swing pos
      if (lastData[0].pf_5) defPara.SensorHumid = lastData[0].pf_5; //swing pos
      if (Object.hasOwnProperty.call(lastData[0], "pf_6"))
        defPara.softwareVersion = lastData[0].pf_6; //swing pos
      if (lastData[0].pf_10) linkVar[0].value = lastData[0].pf_10; // variable 1
      if (lastData[0].pf_11) linkVar[1].value = lastData[0].pf_11; // variable 1
      if (lastData[0].pf_12) linkVar[2].value = lastData[0].pf_12; // variable 1
      if (lastData[0].pf_13) linkVar[3].value = lastData[0].pf_13; // variable 1
      if (lastData[0].pf_14) linkVar[4].value = lastData[0].pf_14; // variable 1

      if (lastData[0].pi_1) defPara.IrPulse = lastData[0].pi_1; //iControl active
      if (lastData[0].pi_2 >= 0) defPara.iCtrl = lastData[0].pi_2 === 10; //iControl active
      if (lastData[0].pi_8) {
        let varValid = lastData[0].pi_8;
        linkVar[0].valid = getBitByIdx(varValid, 2);
        linkVar[1].valid = getBitByIdx(varValid, 3);
        linkVar[2].valid = getBitByIdx(varValid, 4);
        linkVar[3].valid = getBitByIdx(varValid, 5);
        linkVar[4].valid = getBitByIdx(varValid, 6);
      }
      if (Object.hasOwnProperty.call(lastData[0], "pi_2"))
        defPara.ctrlState = lastData[0].pi_2; //swing pos
      if (Object.hasOwnProperty.call(lastData[0], "pi_9"))
        defPara.fanSpeed = lastData[0].pi_9; //Fan speed
      if (Object.hasOwnProperty.call(lastData[0], "pi_10"))
        defPara.swingPos = lastData[0].pi_10; //swing pos
      if (Object.hasOwnProperty.call(lastData[0], "pi_14"))
        defPara.lastMode = lastData[0].pi_14; //swing pos

      /** get control mode */
      let CtrlKey = "pi_2";
      if (Object.hasOwnProperty.call(lastData[0], CtrlKey)) {
        setG_bInSceneMode(lastData[0][CtrlKey] === 20);
      } else {
        setG_bInSceneMode(false);
      }
      if (Object.hasOwnProperty.call(lastData[0], "pb_9")) {
        if (!bIoSocketUpdate) {
          // do not update when IO socket update
          let bSkipSche = lastData[0].pb_9 === 1;
          refG_SkipSche.current = bSkipSche;
          setG_SkipSchedule(bSkipSche);
          setG_SkipSchedule_Db(bSkipSche);
        }
      }
      if (Object.hasOwnProperty.call(lastData[0], "pi_12")) {
        // last scene
        setG_LastScene(lastData[0].pi_12);
      }
      if (Object.hasOwnProperty.call(lastData[0], "pi_13")) {
        // current scene
        let curScene = lastData[0].pi_13;
        // if(defPara.ctrlState>0){
        //     defPara.opMode = defPara.ctrlState/10;
        // }else{      //
        //     if(defPara.bOn ){
        //         if(defPara.ctrlState===20 || (defPara.cleaning && curScene>0)) defPara.opMode =2;   // set to scene mode
        //     }else{
        //         if(curScene>0) defPara.opMode =2;   // set to scene mode
        //     }
        // }

        // if(defPara.ctrlState===20){
        //     if(defPara.bOn ){
        //         if(defPara.ctrlState===20 || (defPara.cleaning && curScene>0)) defPara.opMode =2;   // set to scene mode
        //     }else{
        //         if(curScene>0) defPara.opMode =2;   // set to scene mode
        //     }
        // }else if(defPara.ctrlState===10){
        //     defPara.opMode =1;
        // }
        if (defPara.softwareVersion >= 1.17) {
          defPara.opMode = defPara.lastMode / 10;
        } else {
          if (defPara.ctrlState === 20) {
            if (defPara.bOn) {
              if (
                defPara.ctrlState === 20 ||
                (defPara.cleaning && curScene > 0)
              )
                defPara.opMode = 2; // set to scene mode
            } else {
              if (curScene > 0) defPara.opMode = 2; // set to scene mode
            }
          } else if (defPara.ctrlState === 10) {
            defPara.opMode = 1;
          }
        }
      }
    } else {
      setG_bInSceneMode(false);
    }

    let spIndex = F_getSpIndex(tempSP);
    let SpToSet = F_roundSpDisplay(tempSP, defPara.iCtrl);
    setG_ctrlPara_DB(defPara);
    setG_CtrlSP_DB(SpToSet);
    setG_VarValue(linkVar);

    /** load temperature */
    if (!bIoSocketUpdate) setG_SpDefIndex(spIndex); // set gauge pos
    if (!refShowConfirmButton.current) {
      /** when user key in info */
      /** only update sensor data, and AC pulse info */
      setG_CtrlSP_Display(SpToSet); // set value display
      setG_ctrlPara_Display(defPara);
    }
  };

  const F_roundSpDisplay = (SP, iCtrl) => {
    if (!iCtrl) return Math.floor(SP);
    else return SP;
  };

  const F_getSpIndex = (fTemp) => {
    return (fTemp - 15).toFixed(1) * 10;
    // let indexCount=(parseFloat(fTemp.toFixed(1))-15.0)*10;
    // return `${parseInt(indexCount)}`;
  };

  const statusDiaAutoClose = () => {
    handleCloseDia();
  };

  const handleCloseDia = () => {
    clearTimeout(cmdTimeoutRef.current);
    clearTimeout(statusTimeoutRef.current);
    setG_statusState(0);
    setG_HideGauge(false);
  };

  const handleServerReq = async (mqttData) => {
    try {
      let { hf } = mqttData;
      if (hf === 2) {
        //
        setG_ShowConfirmButton(false);
        refShowConfirmButton.current = false;
        setG_CtrlSP_DB(G_CtrlSP_Display);
        setG_ctrlPara_DB(G_ctrlPara_Display);
        setG_CancelScene(false);
        setG_DiaPg(0); // close dialog page
      } else if (hf === 3) {
        // sync done
        /** reference only */
        let { pf } = mqttData;
        document.getElementById(`in_kW_0`).value = pf[0];
        document.getElementById(`in_kW_1`).value = pf[1];
        document.getElementById(`in_kW_2`).value = pf[2];
        document.getElementById(`in_kW_3`).value = pf[3];

        document.getElementById(`in_retune_0`).value = pf[4];
        document.getElementById(`in_retune_1`).value = pf[5];
        document.getElementById(`in_retune_2`).value = pf[6];
        document.getElementById(`in_retune_3`).value = pf[7];
      } else if (hf === 7) {
        /** server set scene link variable */
        let updateVarList = await v2RjFn.V2_RjUpdateVarList(
          props.dev._id,
          refG_LinkVar.current
        );
        if (updateVarList.errMsg) return toast(updateVarList.errMsg);
        // toast("Success");
        setG_UpdateVarList(false);
      } else if (hf === 8) {
        /** server set schedule */
        /** update databases */
        let saveRel = await v2RjFn.V2_RjSetSchedule(
          props.dev._id,
          refG_ScheList.current
        );
        if (saveRel.errMsg) return toast(saveRel.errMsg);

        setG_ScheList_DB(refG_ScheList.current);
        setG_ScheList(refG_ScheList.current);

        setG_UpdateScheList(false);
        setG_SkipSchedule_Db(refG_SkipSche.current);
      } else if (hf === 9) {
        /** server set setting */
        refSetting.current.ovewriteDb();
        setG_UpdateSettingList(false);
        /** save pair value to databases */
        refSetting.current.savePairRtrhToDb();
      } else if (hf === 11) {
        /** trigger swicth on off Status */
        // setG_ShowConfirmButton(false);
        setG_ShowConfirmButton(false);
        refShowConfirmButton.current = false;
        setG_DiaPg(0);
      } else if (hf === 102) {
        /** trigger scene */
        if (refG_IsSaveScene.current === 1) {
          refScene.current.update();
        } else if (refG_IsSaveScene.current === 2) {
          refScene.current.deleteSce();
        } else {
          // console.log("Trigger Scene Only");
        }
        setG_ShowConfirmButton(false); // close control update bar
        refShowConfirmButton.current = false;
        setG_DiaPg(0);
      } else if (hf === 201) {
        /** server set IR Frequency */
        setG_IrHzChanged(false);
        toast(`Success, Roger Jr. restarted`);
      } else if (hf === 202) {
        /** set Feedback done */
        // save info into databases;
        await refFbSensor.current.saveFeedbackInfoToDb();
        setG_UpdateFbSensor(false);
      } else {
        // only hf==6 will come here?
        setG_ShowConfirmButton(false);
        refShowConfirmButton.current = false;
        setG_CtrlSP_DB(G_CtrlSP_Display);
        setG_ctrlPara_DB(G_ctrlPara_Display);
        setG_DiaPg(0); // close dialog page
      }
    } catch (error) {
      toast("handleServerReq err:", error.message);
    }
  };
  const fCmdTimeout = async () => {
    let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
    if (!checkRel || !checkRel.errMsg) {
      setG_diaErrMsg("Cmd DB Err");
      // toast("Cmd DB Err");
    }
    if (checkRel.errMsg) {
      setG_diaErrMsg(checkRel.errMsg);
      // toast(checkRel.errMsg);
    }
    setG_statusState(3);
    clearTimeout(cmdTimeoutRef.current);
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
    /** failed */
    // setG_ShowConfrimButton(false);
    // setG_CtrlSP_Display(G_CtrlSP_DB);
    // setG_ctrlPara_Display(G_ctrlPara_DB);
  };

  const fTrigOn = async () => {
    if (G_ctrlPara_Display.opMode === 2) {
      // scene mode
      if (G_ctrlPara_Display.bOn) {
        await F_HandleTrigScene({ sceneIdx: G_LastScene }, true);
      } else {
        await F_HandleTrigScene({ sceneIdx: G_LastScene });
      }
    } else {
      if (G_ctrlPara_Display.cleaning) {
        await handleAcCommand(3, G_ctrlPara_Display.iCtrl, false); // 3 = adjust setpoint only
      } else {
        await handleAcCommand(4, G_ctrlPara_Display.iCtrl, false); // 4 = turn on and adjust setpoint
      }
    }
  };

  const handleTurnOnAc = async () => {
    if (G_LastScene <= 0 && G_ctrlPara_Display.opMode === 2)
      return toast("Please choose scene");
    if (G_ctrlPara_Display.bOn) {
      setG_DiaPg(3);
    } else {
      await fTrigOn();
    }
  };

  const fForceTrig = async (bStatus) => {
    if (bStatus === "OFF") {
      await fTrigOff();
    } else if (bStatus === "ON") {
      await fTrigOn();
    }
  };

  const fTrigOff = async () => {
    if (G_ctrlPara_Display.clean) {
      // clean mode actice
      if (G_ctrlPara_Display.cleaning) {
        // cleaning half way
        await handleAcCommand(2, G_ctrlPara_Display.iCtrl, true); // 2 = normal off
      } else {
        await handleAcCommand(5, G_ctrlPara_Display.iCtrl, true); // 5 = clean off
      }
    } else {
      await handleAcCommand(2, G_ctrlPara_Display.iCtrl, true); // 2 = normal off
    }
  };

  const handleTurnOffAc = async () => {
    if (!G_ctrlPara_Display.bOn) {
      setG_DiaPg(4);
    } else {
      await fTrigOff();
    }
  };
  const handleUpdateAcPara = async () => {
    await handleAcCommand(3, G_ctrlPara_Display.iCtrl, false, true); // 3 = adjust setpoint only
  };

  const handleAcCommand = async (nAcReq, iControl, turnOffReq, updatePara) => {
    // setG_HideGauge(true);
    let GwID = G_GwId;
    let _offReq = turnOffReq ? true : false;
    let _updatePara = updatePara ? true : false;

    let pb = [_offReq, G_ctrlPara_Display.clean, _updatePara];
    let pi = [parseInt(nAcReq), G_ctrlPara_Display.opMode]; // setpoint, fan speed, swing pos
    let pf = [
      G_CtrlSP_Display,
      G_ctrlPara_Display.fanSpeed,
      G_ctrlPara_Display.swingPos,
    ];
    let payload = { pb, pi, pf };
    /** get device {type, devID} info */
    let devInfo = { type: props.ht, devID: props.dev.devID };
    // return
    let cmdRel;
    if (iControl) {
      // function 6
      cmdRel = await ctrlCmdLib.v2_CtrlCmd(6, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    } else {
      // function 2
      cmdRel = await ctrlCmdLib.v2_CtrlCmd(2, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    }

    setG_statusState(1); // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  };

  const trigParaChange = () => {
    if (!G_ctrlPara_Display.bOn) return;
    setG_ShowConfirmButton(true);
    refShowConfirmButton.current = true;
  };
  const toggleControlMode = () => {
    /** toggle icontol LED */
    let _ctrlPara_Display = { ...G_ctrlPara_Display };
    _ctrlPara_Display.opMode += 1;
    if (_ctrlPara_Display.opMode > 2) _ctrlPara_Display.opMode = 0;

    _ctrlPara_Display.iCtrl = _ctrlPara_Display.opMode === 1;

    let RoundDisplay = F_roundSpDisplay(
      G_CtrlSP_Display,
      _ctrlPara_Display.iCtrl
    );
    let gaugeIdx = F_getSpIndex(RoundDisplay);
    setG_ctrlPara_Display(_ctrlPara_Display);
    trigParaChange();
    // change display value also
    /** change gauge index */
    setG_SpDefIndex(gaugeIdx);
    setG_CtrlSP_Display(
      F_roundSpDisplay(G_CtrlSP_Display, _ctrlPara_Display.iCtrl)
    );
  };
  const toggleClean = () => {
    let _ctrlPara_Display = { ...G_ctrlPara_Display };
    _ctrlPara_Display.clean = !_ctrlPara_Display.clean;
    setG_ctrlPara_Display(_ctrlPara_Display);
    // trigParaChange();
  };

  const Fin_GetMaxFanSpeed=(acBrand)=>{    
    let matchAc = Rdx_AcList.find(c=>c.nBrand === acBrand);
    if(!matchAc) return 5;
    if(matchAc.fanSpeed<=0) return 0;
    return matchAc.fanSpeed;
  }
  
  const Fin_GetMaxSwingPos=(acBrand)=>{    
    let matchAc = Rdx_AcList.find(c=>c.nBrand === acBrand);
    if(!matchAc) return 5;
    if(matchAc.swingPos<=0) return 0;
    return matchAc.swingPos;
  }
  
  const toggleFanSpeed = () => {
    if (G_ctrlPara_Display.opMode === 2) return;
    let _ctrlPara_Display = { ...G_ctrlPara_Display };
    _ctrlPara_Display.fanSpeed += 1;
    let maxFanSpeed = Fin_GetMaxFanSpeed(G_AcBrand);
    if (_ctrlPara_Display.fanSpeed > maxFanSpeed)
      _ctrlPara_Display.fanSpeed = 0;
    setG_ctrlPara_Display(_ctrlPara_Display);
    trigParaChange();
  };
  const toggleSwingPos = () => {
    if (G_ctrlPara_Display.opMode === 2) return;
    let _ctrlPara_Display = { ...G_ctrlPara_Display };
    _ctrlPara_Display.swingPos += 1;
    // let maxSwingPos = F_getMaxSwingPos(G_AcBrand);
    let maxSwingPos = Fin_GetMaxSwingPos(G_AcBrand);
    
    if (_ctrlPara_Display.swingPos > maxSwingPos)
      _ctrlPara_Display.swingPos = 0;
    setG_ctrlPara_Display(_ctrlPara_Display);
    trigParaChange();
  };

  const handleDiscard = () => {
    // discard control changes
    setG_HideGauge(true);
    setG_ctrlPara_Display(G_ctrlPara_DB);
    setG_ShowConfirmButton(false);
    refShowConfirmButton.current = false;
    /** change Sp index back to initial place*/
    let spIdx = F_getSpIndex(G_CtrlSP_DB);
    // setG_SpDefIndex(spIdx);     // set gauge
    setG_CtrlSP_Display(G_CtrlSP_DB); // set value
    refTimeoutHideGauge.current = setTimeout(() => F_showGauge(spIdx), 1);
    /** reset cancel scene request */
    setG_CancelScene(false);
  };

  const F_showGauge = (trueIdx) => {
    setG_SpDefIndex(trueIdx); // set gauge
    setG_HideGauge(false);
  };

  const handleSave = async () => {
    if (G_ctrlPara_Display.opMode === 2) {
      await F_HandleTrigScene({ sceneIdx: G_LastScene });
      handleDiscard();
    } else {
      handleUpdateAcPara();
    }
  };

  const F_AdjSetpoint = (value) => {
    if (!G_IsGragging) return;
    if (value < 10) return setG_CtrlSP_Display(16);
    if (value > 150) return setG_CtrlSP_Display(30);
    let sp = parseFloat(value * 0.1) + 15;
    if (!G_ctrlPara_Display.iCtrl) {
      sp = Math.floor(sp + 0.5);
    }
    setG_CtrlSP_Display(sp);
    if (sp.toFixed(1) !== G_CtrlSP_DB.toFixed(1)) trigParaChange();
  };

  const F_SetTab = (value) => {
    setG_RjTab(value);
  };
  const F_HandleChooseScene = (sceneIndex) => {
    setG_RjTab(2);
    setG_EditSceneSel(sceneIndex);
  };
  // const F_HandleAddNewScene=async()=>{
  //     /**  insert new scene info here */
  //     setG_RjTab(2);
  //     setG_EditSceneSel(0);       // new scene selected
  // }
  const handleAfterModifyScene = async () => {
    let SceneList = await loadScene();
    setG_SceneList(SceneList);
    setG_RjTab(1);
  };
  const handleDeleteScene = async (sceneInfo) => {
    let condis = [];
    let rules = [];
    let sceInfo = {
      scene: sceneInfo,
      condis,
      rules,
    };
    refG_IsSaveScene.current = 2;
    await sendLoRa_TrigScene(sceInfo, true);
    return;
    // let SceneList = await loadScene();
    // setG_SceneList(SceneList);
    // setG_RjTab(1);
  };
  const handleToScenePg = () => {
    setG_RjTab(1);
  };
  const hanldeCloseEdit = () => {
    setG_SceneTab(1);
    setG_RjTab(1);
  };
  const F_ToDefaultDisPg = () => {
    setG_SceneTab(0);
  };
  const F_ToScheList = () => {
    setG_RjTab(21);
  };

  const F_HandleEditVar = (selVar, idx) => {
    // click edit button to start edit
    setG_EditVar_Sel(selVar);
    setG_EditVar_IdxSel(idx);
    /** load the info of idx selected */
    setG_RjTab(3);
  };
  const handleEditSingleVar_Confirm = (name, sensorInfo, paraInfo) => {
    // click confirm button to end edit
    /** update display list */
    let _LinkVar = [];
    for (const eachVar of G_LinkVar) {
      _LinkVar.push({ ...eachVar });
    }
    /** edited var */
    // let foundIdx = G_LinkVar.findIndex(c=>c.varIdx === G_EditVar_IdxSel+2);

    let newInfoVar = {
      Var_bdDevId: sensorInfo._id,
      dataIndex: paraInfo.dataIndex,
      dataType: F_GetLoRaDataType(paraInfo.dataType),
      sensorType: sensorInfo.type,
      unit: paraInfo.dataUnit,
      varIdx: G_EditVar_IdxSel + 2,
      varName: name,
    };
    // _LinkVar.splice(foundIdx, 1, newInfoVar);
    _LinkVar.splice(G_EditVar_IdxSel, 1, newInfoVar);
    setG_LinkVar(_LinkVar);
    setG_UpdateVarList(true);
    hanldeCloseEdit();
    // setG_RjTab(3);
  };
  const handleDeleteVar = () => {
    /** update display list */
    let _LinkVar = [];
    for (const eachVar of G_LinkVar) {
      _LinkVar.push({ ...eachVar });
    }

    _LinkVar.splice(G_EditVar_IdxSel, 1, { varName: "" });
    setG_LinkVar(_LinkVar);
    setG_UpdateVarList(true); // show button to save changes
    hanldeCloseEdit();
  };

  const Fin_GetG_LinkVar = () => {
    let _LinkVar = [];
    for (const eachVar of G_LinkVar) {
      _LinkVar.push({ ...eachVar });
    }
    return _LinkVar;
  };

  const handleDiscardVarList = () => {
    setG_UpdateVarList(false);
    setG_LinkVar(G_LinkVar_DB);
  };

  const handleSaveVarList = async () => {
    /** get the latest dev_id again */
    let _LinkVar = Fin_GetG_LinkVar();
    let bdDev_idList = [];
    for (const eachVar of _LinkVar) {
      if (eachVar.Var_bdDevId) {
        let found = bdDev_idList.find((c) => c === eachVar.Var_bdDevId);
        if (!found) bdDev_idList.push(eachVar.Var_bdDevId);
      }
    }
    if (notEmptyArr(bdDev_idList)) {
      let bdDevInfoLIst = await ownerSensorFn.getBdDev_By_bdDevIdList(
        bdDev_idList
      );

      for (const eachVar of _LinkVar) {
        let found = bdDevInfoLIst.find((c) => c._id === eachVar.Var_bdDevId);
        if (found) {
          if (found.devID) eachVar.devID = found.devID;
        }
      }
    }

    refG_LinkVar.current = _LinkVar;
    /** send mqtt to device */
    await sendLoRa_VarList(_LinkVar);
  };

  const sendLoRa_VarList = async (varLiat) => {
    let GwID = G_GwId;
    // let pi = [parseInt(nAcReq)];    // setpoint, fan speed, swing pos
    let pi = [];
    for (let i = 0; i < 5; i++) {
      let currVar = varLiat.find((c) => c.varIdx === i + 2);
      if (!currVar) {
        let emptyArr = [0, 0, 0, 0];
        pi.push(...emptyArr);
        continue;
      }
      if (currVar.sensorType) pi.push(currVar.sensorType);
      else pi.push(0);

      if (currVar.devID) pi.push(currVar.devID);
      else pi.push(0);

      if (currVar.dataType) pi.push(currVar.dataType);
      else pi.push(0);

      if (currVar.dataIndex) pi.push(currVar.dataIndex);
      else pi.push(0);
    }

    let payload = { pi };
    /** get device {type, devID} info */
    let devInfo = { type: props.ht, devID: props.dev.devID };
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(7, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

    setG_statusState(1); // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  };

  const F_ToEditSche = (selSche, idx) => {
    setG_EditSche(selSche);
    setG_ScheIdxSel(idx);
    setG_RjTab(22);
  };
  const F_ToEditSche_New = () => {
    /** create empty sche */
    let emptySche = {
      name: "",
      scheType: 2,
      startUnix: timeFn.getUnixNow() + 60, // next min
      DOW: 127,
      Setpoint: 24 /** scene idx */,
      Fan: 5,
      Swing: 0,
      action: 1,
      sceneIdx: 0,
      active: 1,
      priority: false,
    };
    setG_EditSche(emptySche);
    setG_RjTab(22);
  };

  const Fin_GetScheList = () => {
    let _ScheList = [];
    for (const eachSche of G_ScheList) {
      _ScheList.push({ ...eachSche });
    }
    return _ScheList;
  };

  const F_ToggleScheAct = (bScheActStatus) => {
    setG_SkipSchedule(bScheActStatus);
    refG_SkipSche.current = bScheActStatus;
    setG_UpdateScheList(true);
  };
  const F_HandleScheOnChange = () => {
    setG_UpdateScheList(true);
  };

  const F_handleEditSche = (editedSche) => {
    let tempSche = { ...editedSche };
    if (!tempSche.name || tempSche.name.trim() === "") {
      let unixNow = timeFn.getUnixNow();
      tempSche.name = `sche (${timeFn.getUnixDate(
        unixNow
      )}, ${timeFn.getUnixTime(unixNow, false, true)})`;
    }

    let _ScheList = Fin_GetScheList();

    if (Object.hasOwnProperty.call(tempSche, "_id")) {
      // edit from existing schedule
      _ScheList.splice(G_ScheIdxSel, 1, tempSche);
      Fin_G_ScheListChanges(_ScheList);
      return;
    }

    /** handle new schedule */
    tempSche._id = 0;
    _ScheList.push(tempSche);
    // setG_ScheList(_ScheList);
    Fin_G_ScheListChanges(_ScheList);
    // F_ToScheList();    /** turn page */
  };

  const F_handleDelSche = () => {
    let _ScheList = Fin_GetScheList();
    _ScheList.splice(G_ScheIdxSel, 1);
    Fin_G_ScheListChanges(_ScheList);
  };

  const Fin_G_ScheListChanges = (ScheList) => {
    setG_ScheList(ScheList);
    setG_UpdateScheList(true);
    setG_RjTab(21);
  };

  const Fin_GetG_ScheList_DB = () => {
    let _ScheList = [];
    for (const eacList of G_ScheList_DB) {
      _ScheList.push({ ...eacList });
    }
    return _ScheList;
  };

  const handleDiscardScheList = () => {
    let _ScheList_DB = Fin_GetG_ScheList_DB();
    setG_ScheList(_ScheList_DB);
    setG_UpdateScheList(false);
    setG_SkipSchedule(G_SkipSchedule_Db);
    refG_SkipSche.current = G_SkipSchedule_Db;
    setG_RjTab(23);
  };

  const handleRefresh = () => {
    setG_RjTab(21);
  };

  const sendScheduleLoRa = async (scheList) => {
    refG_ScheList.current = scheList;
    await sendLoRa_ScheList(); /** to test */

    /** update databases */
    // let saveRel = await v2RjFn.V2_RjSetSchedule(props.dev._id, G_ScheList);
    // if(saveRel.errMsg) return toast(saveRel.errMsg);
    // toast("Success");
    // setG_UpdateScheList(false);
  };

  const handleSaveScheList = async () => {
    refSchedule.current.saveSchedule();
  };

  const sendLoRa_ScheList = async () => {
    let GwID = G_GwId;
    // let pi = [parseInt(nAcReq)];    // setpoint, fan speed, swing pos
    let pi = [];
    let pf = [];
    let pb = [];

    pb.push(G_SkipSchedule);

    // let pn=[];
    for (let i = 0; i < TotalSche; i++) {
      if (i < G_ScheList.length) {
        // empty schedule
        /** insert start time, pi[0] */
        pi.push(G_ScheList[i].startUnix);
        /** insert Setpoint */
        pf.push(G_ScheList[i].Setpoint);
        /** Ty_dow_fan_swing, pi[1]*/
        let ScTy_dow_fan_swing =
          (G_ScheList[i].scheType << 24) |
          (G_ScheList[i].DOW << 16) |
          (G_ScheList[i].Fan << 8) |
          G_ScheList[i].Swing;
        pi.push(ScTy_dow_fan_swing);
        /** action_sceIdx, pi[2]*/
        let _active = G_ScheList[i].active === 1 ? 1 : 0;
        let _prio = G_ScheList[i].priority === 1 ? 1 : 0;
        let bActive_bPrio_action_sceIdx =
          (_active << 24) |
          (_prio << 16) |
          (G_ScheList[i].action << 8) |
          G_ScheList[i].sceneIdx;
        pi.push(bActive_bPrio_action_sceIdx);
        /** active pb[0]*/
      } else {
        pi.push(0); /** insert start time */
        pf.push(0); /** insert Setpoint */
        pi.push(0); /** Ty_dow_fan_swing, pi[0]*/
        pi.push(0); /** bActive_bPrio_action_sceIdx, pi[1]*/
      }
    }

    let payload = { pf, pi, pb };
    /** get device {type, devID} info */
    let devInfo = { type: props.ht, devID: props.dev.devID };
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(8, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

    setG_statusState(1); // go to sending

    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  };

  const handleSettingChanges = () => {
    setG_UpdateSettingList(true);
  };

  const handleDiscardChanges = () => {
    refSetting.current.discard();
    setG_UpdateSettingList(false);
  };

  const handleSettingUpdate = async () => {
    refSetting.current.update();
  };
  const F_UpdateSetting = async (settingInfo, pairRtrh) => {
    await sendLoRa_Setting(settingInfo, pairRtrh);
  };

  const handleFbSensorChanges = () => {
    setG_UpdateFbSensor(true);
  };

  const handleIrHzChanges = () => {
    setG_IrHzChanged(true);
  };

  const sendLoRa_Setting = async (settingInfo, pairRtrh) => {
    try {
      let GwID = G_GwId;
      // let pi = [parseInt(nAcReq)];    // setpoint, fan speed, swing pos
      let pi = [];
      let pf = [];
      let pb = [];
      let pn = [];
      pi.push(settingInfo.nBrand);
      pi.push(settingInfo.coolingStrength);
      pi.push(settingInfo.heatingStrength);
      pi.push(pairRtrh.ht); // 3
      pi.push(pairRtrh.hi); // 4
      pi.push(2); // temp var type
      pi.push(0); // temp var index
      pi.push(2); // humidity var type
      pi.push(1); // humidity var index
      pf.push(settingInfo.upperBand);
      pf.push(settingInfo.lowerBand);
      pb.push(settingInfo.sensorLink);
      pn.push(settingInfo.rapidPulse);
      let payload = { pf, pi, pb, pn };
      /** get device {type, devID} info */
      let devInfo = { type: props.ht, devID: props.dev.devID };
      let cmdRel = await ctrlCmdLib.v2_CtrlCmd(9, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

      setG_statusState(1); // go to sending

      /** start send command time out */
      cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
      refCmdLog.current = cmdRel.loraPackage;
    } catch (error) {
      toast(`Send Setting Err: ${error.message}`);
    }
  };

  const F_HandleTrigScene = async (sceneInfo, bForceOn) => {
    let sceneDetails = await v2RjFn.getV2_RjSceneInfo(
      props.dev._id,
      sceneInfo.sceneIdx
    );
    if (sceneDetails.errMsg) return toast(sceneDetails.errMsg);
    refG_IsSaveScene.current = 0;
    await sendLoRa_TrigScene(sceneDetails, false, bForceOn);
  };
  const F_HandleUpdateScene = async (sceneInfo, rulesInfo) => {
    let condis = [];
    let rules = [];
    for (const eachRules of rulesInfo) {
      for (const eachCondi of eachRules.condi) {
        condis.push(eachCondi);
      }
      rules.push(eachRules.rule);
    }
    let sceInfo = {
      scene: sceneInfo,
      condis,
      rules,
    };
    refG_IsSaveScene.current = 1;
    await sendLoRa_TrigScene(sceInfo, true);
  };

  const genScenePayload = (sceneDetails, bSaveEeprom, bForceOn) => {
    // rule_AcReq = (foundRule[0].ruleIdx << 8) | foundRule[0].AcReq;
    let _sceneIdx = sceneDetails.scene.sceneIdx;
    if (_sceneIdx <= 0) return { errMsg: "SceneInfoErr" };

    let pb = [bSaveEeprom, bForceOn]; // 0= save scene on local
    let pi = [_sceneIdx];
    let pf = [];
    for (let i = 0; i < 5; i++) {
      if (notEmptyArr(sceneDetails.rules)) {
        let found = sceneDetails.rules.find((c) => c.ruleIdx === i + 1);
        if (found) {
          let fan_swing_AcReq =
            (found.Fan << 16) | (found.Swing << 8) | found.AcReq;
          pi.push(fan_swing_AcReq);
          pi.push(found.Setpoint);

          /** get condis info */
          if (notEmptyArr(sceneDetails.condis)) {
            let filtered = sceneDetails.condis.filter(
              (c) => c.ruleIdx === i + 1
            );
            for (let j = 0; j < 3; j++) {
              if (filtered.length > j) {
                let varIdx_condiOpe =
                  (filtered[j].varIdx << 8) | filtered[j].condiOpe;
                pi.push(varIdx_condiOpe);
                pf.push(filtered[j].targetValue);
              } else {
                // no more, push 0
                pi.push(0);
                pf.push(0);
              }
            }
          }
          continue;
        }
      }
      pi.push(0); // rule fan_swing_AcReq
      pi.push(0); // rule setpoint

      pi.push(0); // condi varIdx_condiOpe
      pi.push(0); // condi varIdx_condiOpe
      pi.push(0); // condi varIdx_condiOpe
      pf.push(0); // condi target value
      pf.push(0); // condi target value
      pf.push(0); // condi target value
    }
    return { pb, pi, pf };
  };

  const sendLoRa_TrigScene = async (sceneDetails, bSaveEeprom, bForceOn) => {
    try {
      if (!Object.hasOwnProperty.call(sceneDetails, "scene"))
        return toast("Please Choose Scene");

      let GwID = G_GwId;
      // return
      let payload = genScenePayload(sceneDetails, bSaveEeprom, bForceOn);
      /** get device {type, devID} info */
      let devInfo = { type: props.ht, devID: props.dev.devID };
      let cmdRel = await ctrlCmdLib.v2_CtrlCmd(102, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

      setG_statusState(1); // go to sending

      /** start send command time out */
      cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
      refCmdLog.current = cmdRel.loraPackage;
    } catch (error) {
      toast(`Send Setting Err: ${error.message}`);
    }
  };

  // const fCancelScene=()=>{
  //     setG_CancelScene(true);
  //     trigParaChange();
  // }

  // const fShowSceneContent=(inScene, inCancel, bSceneComponent)=>{
  //     /**
  //      *      scene   cancel  result
  //      *      1       0       1
  //      *      1       1       0
  //      *      0       0       0
  //      *      0       1       0
  //      */
  //     let result = false;
  //     if(inScene && !inCancel) result = true;
  //     if(!bSceneComponent) result = !result;
  //     return result;
  // }

  const F_IsDragging = (value) => {
    setG_IsGragging(value);
  };

  const handleSwapStatus = () => {
    if (G_ctrlPara_Display.bOn) {
      setG_DiaPg(2);
    } else {
      setG_DiaPg(1);
    }
  };
  const fCloseDia = () => {
    setG_DiaPg(0);
  };
  const fTriggerSwitch = async (nStatus) => {
    if (nStatus === "ON") {
      await sendLoRa_SwitchStatus(true);
      let _ctrlPara_Display = { ...G_ctrlPara_Display };
      _ctrlPara_Display.opMode = 0;
      setG_ctrlPara_Display(_ctrlPara_Display);
    } else if (nStatus === "OFF") {
      await sendLoRa_SwitchStatus(false);
    } else {
      toast("Switch Status Invalid");
    }
  };
  const sendLoRa_SwitchStatus = async (bNewStatus, bCancelSceneOnly) => {
    try {
      let GwID = G_GwId;
      let pb = [bNewStatus, bCancelSceneOnly];
      let payload = { pb };
      /** get device {type, devID} info */
      let devInfo = { type: props.ht, devID: props.dev.devID };
      let cmdRel = await ctrlCmdLib.v2_CtrlCmd(11, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

      setG_statusState(1); // go to sending

      /** start send command time out */
      cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
      refCmdLog.current = cmdRel.loraPackage;
    } catch (error) {
      toast(`Send Setting Err: ${error.message}`);
    }
  };

  const handleSetNewGwId = (gwID) => {
    setG_GwId(gwID);
  };

  const toggelCleanDesc = () => {
    setG_DiaPg(5);
  };

  const getOpModeName = (opMode) => {
    if (opMode === 1) return "i-Control";
    if (opMode === 2) return "Scene";
    return "Manual";
  };

  const Fin_GetSceneName = (sceneList, sceneIdx) => {
    let matchScene = sceneList.find((c) => c.sceneIdx === sceneIdx);
    if (matchScene) return matchScene.Name;
    return "<Choose Scene>";
  };

  const fToScenePg = () => {
    /** 1. discard all changes
     * 2. direct to scene control page
     */
    handleDiscard();
    handleToScenePg();
  };

  const fToVarListPg = () => {
    setG_SceneTab(1);
    F_SetTab(1);
  };

  const fSceneOff = async () => {
    if (G_ctrlPara_Display.ctrlState === 20 || G_ctrlPara_Display.cleaning) {
      if (G_ctrlPara_Display.bOn) {
        // AC status is on
        await fTrigOff();
      } else {
        // AC status is off
        await sendLoRa_SwitchStatus(false, true); // is off,
      }
    } else {
      setG_DiaPg(4);
      // await fTrigOff();
    }
  };

  const fSceneOn = async () => {
    if (G_ctrlPara_Display.ctrlState !== 20) {
      await F_HandleTrigScene({ sceneIdx: G_LastScene });
    } else {
      // await F_HandleTrigScene({sceneIdx:G_LastScene}, true);
      setG_DiaPg(6);
    }
  };
  const fForceOnScene = async () => {
    await F_HandleTrigScene({ sceneIdx: G_LastScene }, true);
  };
  const fTrigSceneDisplay = () => {
    if (G_SceneCtrlTab === 0) setG_SceneCtrlTab(1);
    else setG_SceneCtrlTab(0);
  };
  const handleDiscardChanges_FB = () => {
    setG_UpdateFbSensor(false);
  };
  const handleDiscardChanges_IrHz = () => {
    setG_IrHzChanged(false);
  };

  const handleSendFbSensor = async () => {
    refFbSensor.current.send();
  };
  const handleDiscardFbSensor = async () => {
    refFbSensor.current.discardChanges();
    setG_UpdateFbSensor(false);
  };

  const sendLoRa_FbSensor = async (FbSensor) => {
    FbSensor.GwId_RJ = G_GwId;

    try {
      let GwID = G_GwId;
      let pi = [];
      let pf = [];
      let pb = [];
      let pn = [];

      pb.push(FbSensor.active);

      pi.push(FbSensor.ht);
      pi.push(FbSensor.hi);
      pi.push(FbSensor.comType);
      pi.push(FbSensor.GwId_RJ); // 3
      pi.push(FbSensor.GwId_Feedback); // 4
      pi.push(FbSensor.paraType); // 4
      pi.push(FbSensor.paraIdx); // 4
      pi.push(FbSensor.compareOp); // 4

      pf.push(FbSensor.loraFreq);
      pf.push(FbSensor.onThreshold);

      let payload = { pf, pi, pb, pn };
      // return
      /** get device {type, devID} info */
      let devInfo = { type: props.ht, devID: props.dev.devID };
      let cmdRel = await ctrlCmdLib.v2_CtrlCmd(202, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

      setG_statusState(1); // go to sending

      /** start send command time out */
      cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
      refCmdLog.current = cmdRel.loraPackage;
    } catch (error) {
      toast(`Send Setting Err: ${error.message}`);
    }
  };

  const fTriggerHzIr = () => {
    refIrHz.current.setIrHz();
  };

  const fRevertHzIr=()=>{
    refIrHz.current.revertIrHz();
    setG_IrHzChanged(false);
  }

  const sendLoRa_IrHz = async (IrHzInfo) => {
    // IrHzInfo.GwId_RJ = G_GwId;

    try {
      let GwID = G_GwId;
      let pi = [];
      let pf = [];
      let pb = [];
      let pn = [];

      pi.push(IrHzInfo);

      let payload = { pf, pi, pb, pn };
      // return
      /** get device {type, devID} info */
      let devInfo = { type: props.ht, devID: props.dev.devID };
      let cmdRel = await ctrlCmdLib.v2_CtrlCmd(201, GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

      setG_statusState(1); // go to sending

      /** start send command time out */
      cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
      refCmdLog.current = cmdRel.loraPackage;
    } catch (error) {
      toast(`Send Setting Err: ${error.message}`);
    }
  };

  const fToFilterPage=()=>{
    F_SetTab(35);
  }

  const clearFilterDisplay=()=>{
    setG_bShowCleanFilter(false);
  }

  return (
    <div className="spring_acPage">
      {G_statusState !== 0 && <div className="spring_dialogBlurBg"></div>}
      {G_statusState === 1 && <TpDiaSending />}
      {G_statusState === 2 && <TpDiaSuccess onClickDiaClose={handleCloseDia} />}
      {G_statusState === 3 && (
        <TpDiaFailed onClickDiaClose={handleCloseDia} diaErrMsg={G_diaErrMsg} />
      )}

      {!G_Loaded && (
        <div className="spring_acPg flexAndBothCenter">
          <div>Loading...</div>
        </div>
      )}
      {G_Loaded && (
        <div className="spring_acPg">
          {G_DiaPg === 1 && (
            <TpDiaSwapStatus
              onclickClose={fCloseDia}
              acStatus={1}
              trigSwitch={fTriggerSwitch}
            />
          )}
          {G_DiaPg === 2 && (
            <TpDiaSwapStatus
              onclickClose={fCloseDia}
              acStatus={2}
              trigSwitch={fTriggerSwitch}
            />
          )}
          {G_DiaPg === 3 && (
            <TpDiaForceTrig
              onclickClose={fCloseDia}
              acStatus={1}
              trigSwitch={fForceTrig}
            />
          )}
          {G_DiaPg === 4 && (
            <TpDiaForceTrig
              onclickClose={fCloseDia}
              acStatus={2}
              trigSwitch={fForceTrig}
            />
          )}
          {G_DiaPg === 5 && (
            <TpDiaInfo
              onclickClose={fCloseDia}
              // acStatus={2}
              // trigSwitch={fForceTrig}
            />
          )}
          {G_DiaPg === 6 && (
            <TpDiaForceTrig
              onclickClose={fCloseDia}
              acStatus={3}
              trigSwitch={fForceOnScene}
            />
          )}

          {G_RjTab === 1 && (
            <TabScene
              onClickChooseScene={F_HandleChooseScene}
              // onClickAddNewScene={F_HandleAddNewScene}
              sceneList={G_SceneList}
              varList={G_LinkVar}
              varValue={G_VarValue}
              editVar={F_HandleEditVar}
              pageSel={G_SceneTab}
              backToDefault={F_ToDefaultDisPg}
              trigScene={F_HandleTrigScene}
              lastData={G_LastData}
              // ref = {refScene}
            />
          )}
          {G_RjTab === 2 && (
            <TabEditScene
              // saveScene={handleAfterModifyScene}
              afterModify={handleAfterModifyScene}
              delScene={handleDeleteScene}
              sceneSel={G_EditSceneSel}
              toScenePg={handleToScenePg}
              trigSaveScene={F_HandleUpdateScene}
              dev={props.dev}
              ref={refScene}
              acBrand={G_AcBrand}
            />
          )}
          {G_RjTab === 3 && (
            <TpDiaEditLinkSensor
              // saveScene={handleSaveScene}
              // delScene={handleDeleteScene}
              sceneSel={G_EditSceneSel}
              toScenePg={handleToScenePg}
              dev={props.dev}
              closeEdit={hanldeCloseEdit}
              selVar={G_EditVar_Sel}
              confirmChange={handleEditSingleVar_Confirm}
              deleteVar={handleDeleteVar}
            />
          )}

          {G_RjTab === 21 && (
            <TabScheduleList
              dev={props.dev}
              schelist={G_ScheList}
              scenelist={G_SceneList}
              toEditSche={F_ToEditSche}
              toEditSche_New={F_ToEditSche_New}
              lastData={G_LastData}
              toggleScheActive={F_ToggleScheAct}
              skipSche={G_SkipSchedule}
              scheOnChange={F_HandleScheOnChange}
              ref={refSchedule}
              saveChanges={sendScheduleLoRa}
            />
          )}

          {G_RjTab === 22 && (
            <TabScheListEdit
              dev={props.dev}
              scheSel={G_EditSche}
              sceneList={G_SceneList}
              backToScheList={F_ToScheList}
              saveEdit={F_handleEditSche}
              delSche={F_handleDelSche}
            />
          )}
          {G_RjTab === 23 && <TabRefreshPg backToPg={handleRefresh} />}

          {G_RjTab === 31 && (
            <TabMorePage
              dev={props.dev}
              lastData={G_LastData}
              fSetGw_id={handleSetNewGwId}
              fToTab={F_SetTab}
            />
          )}

          {G_RjTab === 32 && (
            <TabSettingFeedbackPair
              dev={props.dev}
              pos={props.pos}
              fToTab={F_SetTab}
              settingChanges={handleFbSensorChanges}
              discardChanges={handleDiscardChanges_FB}
              ref={refFbSensor}
              confirmSend={sendLoRa_FbSensor}
            />
          )}

          {G_RjTab === 33 && (
            <TabSettingEdit
              dev={props.dev}
              lastData={G_LastData}
              settingChanges={handleSettingChanges}
              discardChanges={handleDiscardChanges}
              ref={refSetting}
              confirmChange={F_UpdateSetting}
              fSetGw_id={handleSetNewGwId}
              fToTab={F_SetTab}
            />
          )}

          {G_RjTab === 34 && (
            <TabSettingIrFrequency
              pos={props.pos}
              dev={props.dev}
              lastData={G_LastData}
              fToTab={F_SetTab}
              settingChanges={handleIrHzChanges}
              discardChanges={handleDiscardChanges_IrHz}
              confirmChange={sendLoRa_IrHz}
              ref={refIrHz}
            />
          )}

          {G_RjTab === 35 && (
            <TabSettingFilterCleaning
              dev={props.dev}
              lastData={G_LastData}
              fToTab={F_SetTab}
              // settingChanges={handleIrHzChanges}
              // discardChanges={handleDiscardChanges_IrHz}
              // confirmChange={sendLoRa_IrHz}
              // ref = {refIrHz}
            />
          )}

          {G_RjTab === 36 && (
            <TabSettingFilterDetails
              dev={props.dev}
              lastData={G_LastData}
              fToTab={F_SetTab}
              fCleanFilterSuccess={clearFilterDisplay}
            />
          )}
          
          {G_RjTab === 37 && (
            <TabSettingRjMaintenanceLog
              dev={props.dev}
              lastData={G_LastData}
              fToTab={F_SetTab}
            />
          )}

      

          {G_RjTab === 0 && (
            <div className="divRelative">
              <div
                className="sortHor spreadEvenly"
                style={{ marginTop: "10px" }}
              >
                <div className="spring_valueBox">
                  <div className="spring_ValBoxIcon">
                    {/* <BiWind/> */}
                    <TbSnowflake />
                  </div>
                  {/* <div className='spring_ValBoxBtm'>{`${G_ctrlPara_Display.IrPulse.toFixed(0)}°C`}</div> */}
                  <div className="spring_ValBoxBtm">{`${
                    G_ctrlPara_Display.IrPulse === 0
                      ? "--"
                      : `${parseFloat(G_ctrlPara_Display.IrPulse).toFixed(0)}°C`
                  }`}</div>
                </div>
                {
                  <div className="spring_valueBox">
                    <div className="spring_ValBoxIcon">
                      <MdOutlineThermostat />
                    </div>
                    <div className="spring_ValBoxBtm">
                      {`${
                        G_ctrlPara_Display.SensorTemp === 0
                          ? "--"
                          : `${parseFloat(
                              G_ctrlPara_Display.SensorTemp
                            ).toFixed(1)}°C`
                      }`}
                    </div>
                  </div>
                }
                {
                  <div className="spring_valueBox">
                    <div className="spring_ValBoxIcon">
                      <MdOutlineWaterDrop />
                    </div>
                    <div className="spring_ValBoxBtm">
                      {`${
                        G_ctrlPara_Display.SensorHumid === 0
                          ? "--"
                          : `${parseFloat(
                              G_ctrlPara_Display.SensorHumid
                            ).toFixed(1)}%`
                      }`}
                    </div>
                  </div>
                }
              </div>

              <div
                className="sortHor flexAndBothCenter"
                style={{ marginTop: "0px" }}
              >
                {G_ctrlPara_Display.opMode !== 2 ? (
                  <div className="flexAndBothCenter  divRelative ">
                    <div className="spring_AcSetpointBox ">
                      {/* refer link : https://github.com/fseehawer/react-circular-slider */}
                      {!G_HideGauge && (
                        <CircularSlider
                          width={120}
                          label="helo "
                          min={0}
                          max={160}
                          labelFontSize="0rem"
                          valueFontSize="0rem"
                          labelColor="#005a00"
                          knobColor="#005a00"
                          knobPosition="bottom"
                          knobSize="25"
                          progressColorFrom="#0000bd"
                          progressColorTo="#009c9a"
                          progressSize={3}
                          trackColor="#ffffff"
                          trackSize={3}
                          // data={G_AcSpStep} //...
                          dataIndex={G_SpDefIndex}
                          onChange={(value) => F_AdjSetpoint(value)}
                          isDragging={(value) => F_IsDragging(value)}
                          // onChange={ value => { setG_CtrlSP((parseFloat(value)*0.1)+16); } }
                        />
                      )}
                    </div>
                    <div className="spring_CtrlSpBox sortHor">
                      <div className="spring_CtrlSpValue">
                        {G_CtrlSP_Display.toFixed(1)}
                      </div>
                      <div className="spring_CtrlSpUnit">°C</div>
                    </div>
                  </div>
                ) : (
                  <div className="divRelative">
                    {G_SceneCtrlTab === 0 ? (
                      <div className="spring_SceneMainContainer sortVer ">
                        <div
                          className="sortHor spring_SceneToggleBar"
                          style={{ marginBottom: "5px" }}
                        >
                          <div className="spring_sceneToggleTitle">Scene:</div>
                          {G_Loaded && (
                            <div
                              className="spring_sceneToggleName wrapTextEllipsis hoverPointer"
                              onClick={fToScenePg}
                            >
                              {Fin_GetSceneName(G_SceneList, G_LastScene)}
                            </div>
                          )}
                          <div
                            className=" spring_sceneMin_Internal hoverPointer"
                            onClick={fTrigSceneDisplay}
                          >
                            {G_SceneCtrlTab === 0 ? (
                              <TbArrowsDiagonalMinimize2 />
                            ) : (
                              <TbArrowsMaximize />
                            )}
                          </div>
                        </div>

                        <div className="spring_SceneTable">
                          {G_LinkVar_CtrlDis.length <= 0 && (
                            <div
                              className="hoverPointer"
                              onClick={fToVarListPg}
                            >
                              <div
                                className="sortHor "
                                style={{ width: "150px" }}
                              >
                                <div
                                  className="spring_SceneMainTitle wrapTextEllipsis"
                                  style={{ marginTop: "2px" }}
                                >
                                  {`Add Variable`}
                                </div>
                                <div className=" wrapTextEllipsis spring_SceneMainValue_addVar">
                                  {`+`}
                                </div>
                              </div>
                            </div>
                          )}

                          {G_LinkVar_CtrlDis.map((c, ind) => (
                            <div
                              key={`linkVarDisKey_${ind}`}
                              className={`${
                                ind % 2 !== 0
                                  ? ind + 1 === G_LinkVar_CtrlDis.length
                                    ? "spring_SceneTableStrip_Last"
                                    : "spring_SceneTableStrip"
                                  : ""
                              }`}
                            >
                              {
                                <div className="sortHor spring_SceneMainRow">
                                  <div className="spring_SceneMainTitle wrapTextEllipsis">
                                    {`${c.varName} (${c.unit})`}
                                  </div>
                                  <div className=" wrapTextEllipsis spring_SceneMainValue">
                                    {`${
                                      G_VarValue[c.varIdx - 2].valid
                                        ? `${parseFloat(
                                            G_VarValue[c.varIdx - 2].value
                                          ).toFixed(1)}`
                                        : "--"
                                    }`}
                                  </div>
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="spring_SceneMainContainer">
                        <div
                          className="sortVer spring_SceneToggleBar"
                          style={{ marginBottom: "5px" }}
                        >
                          <div className="sortHor">
                            <div className="spring_sceneToggleTitle_ZoomIn">
                              Scene:
                            </div>
                            <div
                              className=" spring_sceneMin_Internal hoverPointer"
                              onClick={fTrigSceneDisplay}
                            >
                              {G_SceneCtrlTab === 0 ? (
                                <TbArrowsDiagonalMinimize2 />
                              ) : (
                                <TbArrowsMaximize />
                              )}
                            </div>
                          </div>
                          {G_Loaded && (
                            <div
                              className="spring_sceneToggleZoomIn wrapTextEllipsis hoverPointer"
                              onClick={fToScenePg}
                            >
                              {Fin_GetSceneName(G_SceneList, G_LastScene)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className=" spreadBetween spring_acOnOffContainer">
                  {G_ctrlPara_Display.opMode !== 2 && (
                    <div
                      className={`spring_acOnOffButton spring_shadowBox hoverPointer ${
                        G_ctrlPara_Display.bOn && !G_ctrlPara_Display.cleaning
                          ? "spring_acButtonOn"
                          : ""
                      } `}
                      onClick={handleTurnOnAc}
                    >
                      ON
                    </div>
                  )}
                  {G_ctrlPara_Display.opMode === 2 && (
                    <div
                      className={`spring_acSceneButton spring_shadowBox hoverPointer ${
                        G_ctrlPara_Display.ctrlState === 20
                          ? "spring_acButtonOn"
                          : ""
                      } `}
                      onClick={fSceneOn}
                    >
                      Active
                    </div>
                  )}
                  <div className="divRelative">
                    <div
                      className="spring_rjSwapStateIcon divAbsolute spring_shadowBox flexAndBothCenter hoverPointer"
                      onClick={handleSwapStatus}
                    >
                      <HiOutlineRefresh className="rotate90deg" />
                    </div>
                    <MdOutlineCleaningServices
                      className={`divAbsolute spring_rjCleanningIcon ${
                        G_ctrlPara_Display.cleaning
                          ? "spring_blink"
                          : "divHidden"
                      }`}
                    />
                  </div>
                  {G_ctrlPara_Display.opMode !== 2 && (
                    <div
                      className={`spring_acOnOffButton spring_shadowBox hoverPointer ${
                        G_ctrlPara_Display.cleaning ? "spring_blinkRed" : ""
                      } ${
                        !G_ctrlPara_Display.bOn && !G_ctrlPara_Display.cleaning
                          ? "spring_acButtonOff"
                          : ""
                      }`}
                      onClick={handleTurnOffAc}
                    >
                      OFF
                    </div>
                  )}
                  {G_ctrlPara_Display.opMode === 2 && (
                    <div
                      className={`spring_acSceneButton spring_shadowBox hoverPointer ${
                        G_ctrlPara_Display.cleaning ? "spring_blinkRed" : ""
                      } ${
                        G_ctrlPara_Display.ctrlState !== 20 &&
                        !G_ctrlPara_Display.cleaning
                          ? "spring_acButtonOff"
                          : ""
                      }`}
                      onClick={fSceneOff}
                    >
                      Inactive
                    </div>
                  )}
                </div>
              </div>

              {
                <div className="sortHor spreadEvenly spring_acSettingBar">
                  <div
                    className="spring_ButtonBox hoverPointer"
                    onClick={toggleControlMode}
                  >
                    <div className="spring_butBoxIcon">Control</div>
                    <div>
                      <div className="blueText spring_modeText">
                        {getOpModeName(G_ctrlPara_Display.opMode)}
                      </div>
                    </div>
                    {/* <div className={`text_1_1 spring_RjMainPgBtn `}>
                                {G_ctrlPara_Display.iCtrl ? 
                                    <BsToggleOn className='reactCfgButtonOn'/>:
                                    <BsToggleOff/>}
                            </div> */}
                  </div>
                  <div className="spring_ButtonBox ">
                    <div
                      className="spring_butBoxIcon sortHor hoverPointer"
                      onClick={toggelCleanDesc}
                    >
                      <div style={{ paddingRight: "3px" }}>Clean</div>
                      <BsQuestionCircle />
                    </div>
                    <div
                      className={`text_1_1 spring_RjMainPgBtn hoverPointer`}
                      style={{ padding: "0px 10px" }}
                      onClick={toggleClean}
                    >
                      {G_ctrlPara_Display.clean ? (
                        <BsToggleOn className="reactCfgButtonOn " />
                      ) : (
                        <BsToggleOff />
                      )}
                    </div>
                  </div>
                  <div
                    className={`spring_ButtonBox ${
                      G_ctrlPara_Display.opMode === 2 ? "" : "hoverPointer"
                    }`}
                    onClick={toggleFanSpeed}
                  >
                    <div className="spring_butBoxIcon">Fan</div>
                    <div>
                      <div
                        className={`${
                          G_ctrlPara_Display.opMode === 2 ? "" : "blueText"
                        }`}
                      >{`${
                        G_ctrlPara_Display.fanSpeed > 0
                          ? G_ctrlPara_Display.fanSpeed
                          : "Auto"
                      }`}</div>
                      {/* <div className='blueText '>{`${G_ctrlPara_Display.fanSpeed}`}</div> */}
                    </div>
                  </div>
                  <div
                    className={`spring_ButtonBox ${
                      G_ctrlPara_Display.opMode === 2 ? "" : "hoverPointer"
                    }`}
                    onClick={toggleSwingPos}
                  >
                    <div className="spring_butBoxIcon">Swing</div>
                    <div
                      className={`${
                        G_ctrlPara_Display.opMode === 2 ? "" : "blueText"
                      }`}
                    >{`${
                      G_ctrlPara_Display.swingPos > 0
                        ? G_ctrlPara_Display.swingPos
                        : "Auto"
                    }`}</div>
                  </div>
                </div>
              }
              {G_bShowCleanFilter && <div className="divAbsolute spring_filterCleanContainer">
                <div className="sortHor spring_filterCleanButton hoverPointer"
                onClick={fToFilterPage}
                >
                  <IoWarningOutline style={{ marginRight: "5px" }} />
                  <div>Clean Filter</div>
                </div>
              </div>}
            </div>
          )}
        </div>
      )}

      <div className="divRelative">
        {!G_ShowConfirmButton ? (
          <div className="spring_acBtmBar sortHor spreadEvenly ">
            <div
              className={`spring_btmBarBox hoverPointer ${
                G_RjTab === 0 ? "spring_shadowBox_Small" : ""
              }`}
              onClick={() => F_SetTab(0)}
            >
              <div className="spring_btmBarIcon">
                <RiRemoteControlLine />
              </div>
              <div className="spring_btmBarText">Control</div>
            </div>
            <div
              className={`spring_btmBarBox hoverPointer ${
                G_RjTab >= 1 && G_RjTab < 20 ? "spring_shadowBox_Small" : ""
              }`}
              onClick={() => F_SetTab(1)}
            >
              <div className="spring_btmBarIcon">
                <MdFilter />
              </div>
              <div className="spring_btmBarText">Scene</div>
            </div>
            <div
              className={`spring_btmBarBox hoverPointer ${
                G_RjTab >= 20 && G_RjTab < 30 ? "spring_shadowBox_Small" : ""
              }`}
              onClick={() => F_SetTab(21)}
            >
              <div className="spring_btmBarIcon">
                <MdInsertInvitation />
              </div>
              <div className="spring_btmBarText">Schedule</div>
            </div>
            <div
              className={`spring_btmBarBox hoverPointer ${
                G_RjTab >= 30 && G_RjTab < 40 ? "spring_shadowBox_Small" : ""
              }`}
              onClick={() => F_SetTab(31)}
            >
              <div className="spring_btmBarIcon">
                <HiOutlineTemplate  />
              </div>
              <div className="spring_btmBarText">More</div>
            </div>
          </div>
        ) : (
          <div className="spring_ConfirmButtonBox sortHor divAbsolute spreadEvenly">
            <div
              className="spring_acConfirmButton spring_acConfirm_L hoverPointer"
              onClick={handleDiscard}
            >
              <div>Cancel</div>
            </div>
            <div
              className="spring_acConfirmButton spring_acConfirm_R hoverPointer"
              onClick={handleSave}
            >
              <div>Send</div>
            </div>
          </div>
        )}

        {G_UpdateVarList && (
          <div className="spring_ConfirmButtonBox sortHor divAbsolute spreadEvenly">
            <div
              className="spring_acConfirmButton spring_acConfirm_L hoverPointer"
              onClick={handleDiscardVarList}
            >
              <div>Cancel</div>
            </div>
            <div
              className="spring_acConfirmButton spring_acConfirm_R hoverPointer"
              onClick={handleSaveVarList}
            >
              <div>Update</div>
            </div>
          </div>
        )}

        {G_UpdateScheList && (
          <div className="spring_ConfirmButtonBox sortHor divAbsolute spreadEvenly">
            {G_RjTab !== 21 && <div className="spring_GreyOutBox"></div>}
            <div
              className="spring_acConfirmButton spring_acConfirm_L hoverPointer"
              onClick={handleDiscardScheList}
            >
              <div>Cancel</div>
            </div>
            <div
              className="spring_acConfirmButton spring_acConfirm_R hoverPointer"
              onClick={handleSaveScheList}
            >
              <div>Update</div>
            </div>
          </div>
        )}

        {G_UpdateSettingList && (
          <div className="spring_ConfirmButtonBox sortHor divAbsolute spreadEvenly">
            <div
              className="spring_acConfirmButton spring_acConfirm_L hoverPointer"
              onClick={handleDiscardChanges}
            >
              <div>Cancel</div>
            </div>
            <div
              className="spring_acConfirmButton spring_acConfirm_R hoverPointer"
              onClick={handleSettingUpdate}
            >
              <div>Update</div>
            </div>
          </div>
        )}

        {G_UpdateFbSensor && (
          <div className="spring_ConfirmButtonBox sortHor divAbsolute spreadEvenly">
            <div
              className="spring_acConfirmButton spring_acConfirm_L hoverPointer"
              onClick={handleDiscardFbSensor}
            >
              <div>Cancel</div>
            </div>
            <div
              className="spring_acConfirmButton spring_acConfirm_R hoverPointer"
              onClick={handleSendFbSensor}
            >
              <div>Set</div>
            </div>
          </div>
        )}

        {G_IrHzChanged && (
          <div className="spring_ConfirmButtonBox sortHor divAbsolute spreadEvenly">
            <div
              className="spring_acConfirmButton spring_acConfirm_L hoverPointer"
              onClick={fRevertHzIr}
            >
              <div>Cancel</div>
            </div>
            <div
              className="spring_acConfirmButton spring_acConfirm_R hoverPointer"
              onClick={fTriggerHzIr}
            >
              <div>Set</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubTpRogerJr;
