import React, { useEffect, useRef, useState } from "react";
import MRChTabSystemSetting from "./MRChTab_SystemSetting";
import MRChTabEachSetting from "./MRChTab_EachSetting";
import MRTabLNode from "../CommonTp/MRTab_LNode";
import { RiBubbleChartLine, RiCheckboxBlankCircleLine, RiMindMap   } from "react-icons/ri";
import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { toast } from "react-toastify";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import ctrlCmdLib from '../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';
import socketIOClient from 'socket.io-client';
import TpDiaSending from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSending";
import TpDiaSuccess from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess";
import TpDiaFailed from "../../../../Control/Common/Dialog/StatusDialog/TpDiaFailed";
import ctrlTp from '../../CtrlCmdTemplate/CtrlTemplate';


const SubTpMrRogerCH = (props) => {
  
  /**------Ctrl Standard 1/2 Start------ */
  const cmdTimeout = 10000;    // 10 sec
  const msgTimeout = 3000;    // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  const [G_GwID, setG_GwID] = useState();

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refCmdLog = useRef({});
  /**======Ctrl Standard 1/2 End====== */

  const [G_ShowingTab, setG_ShowingTab] = useState(0);  

  const ref_ChSetting = useRef();   // parent trig child
  const ref_EachChSetting = useRef();   // parent trig child
  const ref_LNode = useRef();   // parent trig child

  useEffect(() => {
    let socket;
    async function startUp() {
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);       

      await F_LoadGateway();

      let topic = ctrlTp.F_GetIoTopic(props.dev);
      socket.on(topic, async(data) => {
        if(ctrlTp.F_HandleNodeReply(data, refCmdLog.current, cmdTimeoutRef.current, setG_statusState)){
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          await handleServerReq(data);
        }
      });
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);

  /**-----------Ctrl Standard 2/2 start------------- */
  const F_LoadGateway=async()=>{
    let pairedGw = await v2ctrlFn.getV2_GwPair(props.dev._id);
    if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    let {gwPairList} = pairedGw;
    if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
    setG_GwID(gwPairList[0].gwid);
  }

  const fCmdTimeout=async()=>{
    await ctrlTp.F_CmdTimeout(refCmdLog.current, setG_diaErrMsg,setG_statusState,cmdTimeoutRef.current);
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);            
  }
  const statusDiaAutoClose=()=>{
    handleCloseDia();
  }

  const handleCloseDia=()=>{
    // clearTimeout(cmdTimeoutRef.current);
    // clearTimeout(statusTimeoutRef.current);    
    setG_statusState(0);
  }
  
  const F_SendLoraTp=async(fn, payload)=>{
    let devInfo = {type:props.ht, devID:props.dev.devID};
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(fn, G_GwID, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)    
    setG_statusState(1);     // go to sending
    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  }

  const handleServerReq=async(mqttData)=>{
    try {
      let {hf, pb, pf, pi} = mqttData;
      if(hf===12){    // server upload CH system setting
        // F_FillUpUploadedSysSetting(pb, pf, pi)
        ref_ChSetting.current.uploadSysSetting(pb, pf, pi);
      }else if (hf===13){   // server upload each CH Setting
        // F_FillUpUploadedEachSysSetting(pf);
        ref_EachChSetting.current.uploadEachChSetting(pf);
      }else if(hf===11){
        // F_FillUpUploadedLNode(pf, pi);
        ref_LNode.current.uploadLNode(pf, pi);
      }
    } catch (error) {
      toast(`Server Req Err: ${error.message}`);
    }
  }
  /** ===============Ctrl Standard 2/2 end=============== */

  
  // const F_FillUpUploadedSysSetting=(_pb, _pf, _pi)=>{
  //   ref_ChSetting.current.uploadSysSetting(_pb, _pf, _pi);
  // }
  // const F_FillUpUploadedEachSysSetting=(_pf)=>{
  //   ref_EachChSetting.current.uploadEachChSetting(_pf);
  // }
  // const F_FillUpUploadedLNode=(_pf, _pi)=>{
  //   ref_LNode.current.uploadLNode(_pf, _pi);
  // }
  


  const F_ToPgNo=(nPgNo)=>{
    setG_ShowingTab(nPgNo);
  }
  

  const F_uploadSysSetting =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(12, payload);
  }


  const F_downloadSysSetting =async (sysSetting, passCode)=>{
    let pb=[sysSetting.active];
    let pi=[
      parseInt(sysSetting.CD*60*1000),
      parseInt(sysSetting.minCap),
      parseInt(sysSetting.maxCap),
      parseInt(sysSetting.queryOt*1000),
      parseInt(sysSetting.chillerActive),
      // parseInt(sysSetting.cycleTime*60*1000),
    ];
    let pf=[sysSetting.kwThreshold]
    let pn=[passCode]
    let payload = {pb, pf, pi, pn};


    await F_SendLoraTp(3, payload);
  }

  const F_uploadEachChSetting =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(13, payload);
  }

  const F_downlodEachChSetting =async (allChSetting, passCode)=>{
    let pf=[];
    for (let i = 0; i < 5; i++) {
      pf.push(allChSetting[i].MaxRla);      
    }    
    for (let i = 0; i < 5; i++) {
      pf.push(allChSetting[i].HiHi);
      pf.push(allChSetting[i].Hi);
      pf.push(allChSetting[i].Lo);
      pf.push(allChSetting[i].LoLo);
    }
    let pn=[passCode]
    let payload = {pf, pn};

    await F_SendLoraTp(4, payload);
  }
  
  const F_uploadLNode =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(11, payload);
  }
  const F_downloadLNode =async (LNodeInfo, passCode)=>{
    let pi =[
      LNodeInfo.Ht,
      LNodeInfo.Hi,
      LNodeInfo.bdDev_id,
      LNodeInfo.commType,
      LNodeInfo.gwID,
      LNodeInfo.gwId_Dots,
    ];
    let pf =[LNodeInfo.loraFreq];
    let pn=[passCode];
    let payload = {pi, pf, pn};
    await F_SendLoraTp(2, payload);
  }
  


  return (
    <div>
      {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
      {G_statusState===1 && <TpDiaSending/>}
      {G_statusState===2 && <TpDiaSuccess onClickDiaClose = {handleCloseDia}/>}
      {G_statusState===3 && <TpDiaFailed onClickDiaClose = {handleCloseDia} diaErrMsg={G_diaErrMsg}/>}

      <div>{G_ShowingTab === 0 && <MRChTabSystemSetting 
        dev={props.dev}
        pos={props.pos}
        gwId = {G_GwID}    
        handleUploadSetting = {F_uploadSysSetting}
        handleDownloadSysSetting = {F_downloadSysSetting}
        ref = {ref_ChSetting}
        />}</div>
      <div>{G_ShowingTab === 1 && <MRChTabEachSetting 
        dev={props.dev}
        pos={props.pos}
        handleDownloadEachChSetting={F_downlodEachChSetting}
        handleUploadEachChSetting={F_uploadEachChSetting}   
        ref = {ref_EachChSetting}             
        />}</div>
      <div>{G_ShowingTab === 2 && <MRTabLNode 
        dev={props.dev}
        pos={props.pos}
        handleDownloadLNode={F_downloadLNode}
        handleUploadLNode={F_uploadLNode}    
        ref = {ref_LNode}
      />}</div>
      
      

      <div className='spring_TpBottomBar sortHor'>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 0?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(0)}>
          <RiBubbleChartLine />
          <div className="spring_TpBtmButtonTitle">System</div>
        </div>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 1?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(1)}>
          <RiCheckboxBlankCircleLine  />
          <div className="spring_TpBtmButtonTitle">Chiller</div>
        </div>
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 2?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(2)}>
          <RiMindMap   />
          <div className="spring_TpBtmButtonTitle">Leader</div>
        </div>
      </div>
    </div>
  );
};

export default SubTpMrRogerCH;
