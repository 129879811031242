import React, { useEffect, useState } from "react";
import v2SystemFn from "../../../function/V2_System/V2_System";
import localStorageFn from "../../../function/localStorage/LocalStorageFn";
import { notEmptyArr, sortTemplate } from "../../../function/ArrayFn/arrayFn";
import TpSystemGroup from "./TpSystemGroup";
import { toast } from "react-toastify";
import { F_CheckExistQueryReq, F_GetQueryIdx, F_getDashItemQueryType } from "./DashboardFunction";
import v2DataQuery from '../../../function/V2_Query/V2_QueryBdDevData';
import { useDispatch, useSelector } from "react-redux";
import { clearDashExpiredData, getFullDashDataList, setNewList } from "../../../reduxStore/actions/dash_Data";
import timeFn from '../../../function/time/timeFn';


const DashBoardPg = () => {
  const dispatch = useDispatch();  
  const reduxDashQueryList = useSelector(getFullDashDataList);

  const C_DataValidTime_s = 180;     /** change to 180 = 3 mins in actual case */

  const [G_systemList, setG_systemList] = useState([]);
  const [G_FullSystemInfo, setG_FullSystemInfo] = useState([]);
  // const [G_allGroupList, setG_allGroupList] = useState([]);
  // const [G_disComponentList, setG_disComponentList] = useState([]);
  const [G_currSystem_id, setG_currSystem_id] = useState(0);
  const [G_currGroupList, setG_currGroupList] = useState([]);
  const [G_currComponentList, setG_currComponentList] = useState([]);
  const [G_allAuxList, setG_allAuxList] = useState([]);

  useEffect(() => {
    async function startUp() {
      /** get whole building dashboard info. */
      let bdInfo = localStorageFn.getBdToken();
      let systemInfo = await v2SystemFn.V2_GetSystemById(bdInfo._id);
      // console.log("systemInfo", systemInfo);
      if(systemInfo.errMsg) return toast(systemInfo.errMsg);
      // let systemInfoWithAux = getAuxInfoToCompList(systemInfo);
      let sortedSysList = sortArr(systemInfo.systemList);

      /** prepare the dashboard info for current system. */
      let curSystemInfo = getCurrentSystemInfo(
        sortedSysList[0]._id,
        systemInfo.groupList,
        systemInfo.componentList
      );

      /** base on current system, generate the query list */      
      let dashItemQueryList = getNewQueryList(curSystemInfo, []);
        // console.log("dashItemQueryList", dashItemQueryList);


      setG_FullSystemInfo(systemInfo);
      setG_systemList(sortedSysList);
      // setG_allGroupList(systemInfo.groupList);
      // setG_disComponentList(systemInfo.componentList);

      setG_currSystem_id(sortedSysList[0]._id);
      setG_currGroupList(curSystemInfo.currGroupList);
      setG_currComponentList(curSystemInfo.currComponentList);
      setG_allAuxList(systemInfo.auxList);

      
      /** query data from databases */
      let dashItemDataList = await v2DataQuery.v2GetDataForDashItem(dashItemQueryList);
      // console.log("dashItemDataList", dashItemDataList);
      /** store the replied data into redux store. */
      dispatch(setNewList(dashItemDataList));
    }
    startUp();

    return () => {
      // alert("Page Close");
    };
    // eslint-disable-next-line
  }, []); 
  
  const getNewQueryList=(curSystemInfo, existingList)=>{
    try {
      let prevList = JSON.parse(JSON.stringify(existingList));
      let dahItemQueryList =[];
      if(Array.isArray(curSystemInfo.currComponentList)){
        // console.log("curSystemInfo.currComponentList", curSystemInfo.currComponentList);
        for (const eachComp of curSystemInfo.currComponentList) {   /** for each component */
          if(Array.isArray(eachComp.bdDevInvolve_List)){
            for (const eachBdDevInvolve of eachComp.bdDevInvolve_List) {    /** for each device under component */                          
            let queryInfo = F_getDashItemQueryType(eachBdDevInvolve, eachComp); /** generate the query item of device */
              for (const eachQuery of queryInfo) {  /** for each query item */                
                /** check if exist (and valid) in existing redux list */
                let existInPrev = F_CheckExistQueryReq(eachQuery, prevList);
                if(existInPrev===2){    /** exist, can skip  */
                  // console.log("Query exist : ", eachQuery);
                  continue;
                }

                let existStatus = F_CheckExistQueryReq(eachQuery, dahItemQueryList);  /** check whether query list exist */
                if(existStatus===0)   dahItemQueryList.push(...queryInfo);  /** not exist, insert */
                else if (existStatus===1) {   /** exist, but not covered */                  
                  let qPos = F_GetQueryIdx(eachQuery, dahItemQueryList);    /** find the location */
                  dahItemQueryList.splice(qPos, 1, eachQuery);    /** replace it */
                }
              }
            }
          }
        }
      }
      return dahItemQueryList;
      
    } catch (error) {
      toast(`Get dashboard item error: ${error.message}`);
      return []
    }
  }

  // const getAuxInfoToCompList = (_systemInfo) => {
  //   const barComponent = [3, 5, 6];
  //   const statusComponent = [4, 7];

  //   for(const eachComp of _systemInfo.componentList) {
  //     let currAux = _systemInfo.auxList.filter(c => c.component_id === eachComp._id);
  //     if(barComponent.findIndex(c=> c===eachComp.componentType) >= 0) {
  //       eachComp.barTitle = currAux.find(c=> c.auxId === 1).auxValue;
  //       eachComp.axisTitle = `${currAux.find(c => c.auxId === 2).auxValue} (${currAux.find(c => c.auxId === 3).auxValue})`;
  //     } else if(statusComponent.findIndex(c=> c===eachComp.componentType) >= 0) {
  //       eachComp.imgTitle = currAux.find(c=> c.auxId === 1).auxValue;
  //       eachComp.imgName = currAux.find(c=> c.auxId === 2).auxValue;
  //     }
  //   }
  //   // console.log(_systemInfo);
  //   return _systemInfo;
  // }

  const getCurrentSystemInfo = (_currSysId, _groupList, _compList) => {
    let currGroupList = sortArr(
      _groupList.filter((c) => c.system_id === _currSysId)
    );
    let currComponentList = _compList.filter((c) => c.system_id === _currSysId);
    let compNotInGroup = currComponentList.filter(
      (o1) => !currGroupList.some((o2) => o1.group_id === o2._id)
    );
    if(notEmptyArr(compNotInGroup)){
      currGroupList.push({ _id: 0, Name: "Others", system_id: _currSysId });
    }
    return { currGroupList, currComponentList };
  };

  const sortArr = (arrInput) => {
    let tempArr = [...arrInput];
    let sortKeys = [
      { key: "sortIdx", descOrder: false },
      { key: "unix", descOrder: false },
      { key: "Name", descOrder: false },
    ];
    tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
    return tempArr;
  };

  const toggleChangeSystemPg = async (system_id) => {
    // console.log("Change to system : ",system_id);    
    /** skip loading same page value */
    if(G_currSystem_id === system_id) return console.log("Same system page");
    
    /** pull redux list and clear the expired data locally ???*/
    dispatch(clearDashExpiredData(C_DataValidTime_s));

    let reduxList = JSON.parse(JSON.stringify(reduxDashQueryList));
    let reduxList_Valid = reduxList.filter(c=>c.loadTime + C_DataValidTime_s > timeFn.getUnixNow());

    /** get current system info */
    let curSystemInfo = getCurrentSystemInfo(
      system_id,
      G_FullSystemInfo.groupList,
      G_FullSystemInfo.componentList
    );

    /** check query list, get not yet query and expired query */    
    let dashItemQueryList = getNewQueryList(curSystemInfo, reduxList_Valid);
    // console.log("New Query List: ", dashItemQueryList);

    /** update and show display frame 1st */
    // console.log(_currGroupCompList);
    setG_currComponentList(curSystemInfo.currComponentList);
    setG_currGroupList(curSystemInfo.currGroupList);
    setG_currSystem_id(system_id);


    /** if got list not yet updated, send query list to backend */
    let dashItemDataList = [];
    if(notEmptyArr(dashItemQueryList)){
      dashItemDataList = await v2DataQuery.v2GetDataForDashItem(dashItemQueryList);  
      if (dashItemDataList.errMsg)  return toast (`Load dashboard item error: ${dashItemDataList.errMsg}`);
    }
    /** combine the backend result and reduxList_Valid, then store in to redux again */
    let combineList = [...reduxList_Valid, ...dashItemDataList];
    dispatch(setNewList(combineList));
    console.log("Query Done");
  };


  return (
    <div className="spring_floatingMenuContainer">
      {G_systemList.length > 0 && (
        <div className="spring_floatingMenu contentCard">
          {G_systemList.map((u, ind) => (
            <div key={`system_${ind}`}>
              {ind !== 0 && <div className="divSeperateLine"></div>}
              <div
                className="spring_padding5 hoverPointer"
                onClick={() => toggleChangeSystemPg(u._id)}
              >
                {u.Name}
              </div>
            </div>
          ))}
        </div>
      )}

      <TpSystemGroup
        currSystem={G_currSystem_id}
        groupList={G_currGroupList}
        sysComponentList={G_currComponentList}
        auxList={G_allAuxList}
        // handleSetCompName = {handleSetCompName}
      />
    </div>
  );
};

export default DashBoardPg;
