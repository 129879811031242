import React, { useEffect, useState } from 'react';
import { sortTemplate } from '../../../../../../../function/ArrayFn/arrayFn';
import v2RjFn from "../../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import { toast } from 'react-toastify';

function TpDiaChooseAcBrand(props) {

    const [G_AcList, setG_AcList] = useState([]); 

    
    useEffect(()=>{
        async function startUp(){
            let _acList = await loadAcList();
            if(props.lastData){
                _acList = _acList.filter(c=> c.supportVersion <= props.lastData.pf_6);
            }
            let sortKeys = [{ key: "name", descOrder: false }];
            _acList.sort((a, b) => sortTemplate(a, b, sortKeys));
            setG_AcList(_acList);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    
    const loadAcList=async ()=>{        
        let _AcList = await v2RjFn.V2_RjGetAcBrand();
        if(_AcList.errMsg) return toast("Loads AC list Err:", _AcList.errMsg);
        return (_AcList);
    }

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(varInfo)=>{
        if(props.confirmChange) {
            props.confirmChange(varInfo);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_diaFixHeight'>
                        <div className='spring_ruleVarListContainer'>
                            {G_AcList.map((c, ind)=>
                                <div key={`varKey_${ind}`} className="spring_ruleVarListBox hoverPointer wrapTextEllipsis"
                                onClick={()=>handleConfirm(c)}
                                >{c.name}</div>
                            )} 
                        </div>
                    </div> 
                                    

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaChooseAcBrand;