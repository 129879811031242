import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from 'reselect';

const initState={
    SerialNo:'',
    RegCode:'',
    type:0,
    devID:0,

    bNewBuilding:false,
    buildingName:'',
    buildingId:0,

    bSkipInserFloor:false,
    bNewFloor:false,
    bFloorName:'',
    floorId:0,        

    bSkipInserArea:false,
    bNewArea:false,
    bAreaName:'',
    areaId:0,        

    devName:'',
}

const slice = createSlice({
    name:'regSensor',
    initialState:{...initState},
    reducers:{
        chooseSensor:(state, action)=>{
            state.SerialNo = action.payload.SerialNo;
            state.RegCode = action.payload.RegCode;
            state.type = action.payload.type;
            state.devID = action.payload.devID;
        },

        chooseBuilding:(state, action)=>{
            if(action.payload.buildingId !== state.buildingId){
                state.bNewFloor=false;
                state.bFloorName='';
                state.floorId=0;    

                state.bNewArea=false;
                state.bAreaName='';
                state.areaId=0;          
            }

            state.buildingId = action.payload.buildingId;
            state.buildingName = action.payload.buildingName;
            state.bNewBuilding = false;
        },        
        addNewBuilding:(state, action)=>{
            /** clear floor */
            state.bNewFloor=false;
            state.bFloorName='';
            state.floorId=0;    
            /** clear area */
            state.bNewArea=false;
            state.bAreaName='';
            state.areaId=0;          

            state.buildingName = action.payload.buildingName;
            state.bNewBuilding = true;
            
        },
        chooseFloor:(state, action)=>{
            if(state.floorId !== action.payload.floorId){
                /** clear area */
                state.bNewArea=false;
                state.bAreaName='';
                state.areaId=0;        
            }

            state.bFloorName = action.payload.bFloorName;
            state.floorId = action.payload.floorId;
            state.bNewFloor = false;
        },
        chooseArea:(state, action)=>{
            state.bAreaName = action.payload.bAreaName;
            state.areaId = action.payload.areaId;
            state.bNewArea = false;
        },
        addNewFloor:(state, action)=>{
            /** clear area */
            state.bNewArea=false;
            state.bAreaName='';
            state.areaId=0;        

            state.bFloorName = action.payload.bFloorName;
            state.bNewFloor = true;
        },
        addNewArea:(state, action)=>{
            state.bAreaName = action.payload.bAreaName;
            state.bNewArea = true;
        },
        setDevName:(state, action)=>{
            state.devName = action.payload.devName;
        },
        setSerialNo:(state, action)=>{
            state.SerialNo = action.payload;
        },
        setRegCode:(state, action)=>{
            state.RegCode = action.payload;
        },
        clearBdAndArea:(state)=>{
            state.bNewBuilding=false;
            state.buildingName='';
            state.buildingId=0;

            state.bSkipInserFloor=false;   
            state.bNewFloor=false;
            state.bFloorName='';
            state.floorId=0;    

            state.bSkipInserArea=false;
            state.bNewArea=false;
            state.bAreaName='';
            state.areaId=0;      
        },

        clearRegSensorInfo:(state)=>{
            for (const key in state) {
                if (Object.hasOwnProperty.call(state, key)) {
                    state[key] = initState[key];
                }
            }
        },
        toggleSkipFloor:(state, action)=>{
            state.bSkipInserFloor = action.payload;
            /** clear floor */
            if(action.payload){     // skip floor 
                state.bNewFloor=false;
                state.bFloorName='';
                state.floorId=0;    
            }
        },
        toggleSkipArea:(state, action)=>{
            state.bSkipInserArea = action.payload;
            /** clear area */
            state.bNewArea=false;
            state.bAreaName='';
            state.areaId=0;      
        },

    }
})

/** Export Action */
export const {
    chooseSensor, 
    chooseBuilding, 
    addNewBuilding,
    chooseArea,
    chooseFloor,
    addNewArea,
    addNewFloor,
    setDevName,
    clearBdAndArea,
    clearRegSensorInfo,
    toggleSkipFloor,
    toggleSkipArea,
    setSerialNo,
    setRegCode,
} = slice.actions;


/** reducer */
export default slice.reducer;


/** Action Creator, call action */


/** Selector, read from store */
export const getRegDevInfo = createSelector(
    state=> state.dev.regSensor,
    ({SerialNo, RegCode, type, devID}) => ({SerialNo, RegCode, type, devID})
)