import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line
import { createSelector } from 'reselect';

const initState={
    bdName:"",
    bd_id:0,    
    accessLevel:3,      // 1=> Owner, 2=> Admin, 3=> Guest
    accessLvlText:"User",
    receiverEmail:[],
    deviceList:[],
    bPressEnter:false,

    chooseMode:"",  // Building, User
    selected_bd_id:0,
    selected_bd_name:"",
    selected_user_id:0,
    selected_user_email:"",
}

const slice = createSlice({
    name:'devShareCfg',
    initialState:{...initState},
    reducers:{        
        setBdInfo:(state, action)=>{    
            state.bdName = action.payload.bdName;
            state.bd_id = action.payload.bd_id;            
        },      
        switchAccessLvl:(state, action)=>{    
            state.accessLevel--;
            if(state.accessLevel<1) state.accessLevel=3;
            getAccessLevelText(state);
            // switch (state.accessLevel) {
            //     case 1:     state.accessLvlText ="Owner";    break;
            //     case 2:     state.accessLvlText ="Admin";    break;
            //     default:
            //         state.accessLevel=3;
            //         state.accessLvlText ="Guest";
            //         break;
            // }
        },  
        storeEmailList:(state, action)=>{    
            state.receiverEmail = [...action.payload.receiverEmail];
        },
        resetCfgInfo:(state)=>{    
            for (const key in state) {
                if (Object.hasOwnProperty.call(state, key)) {
                    state[key] = initState[key];
                }
            }
        },
        setChooseMode:(state, action)=>{    
            state.chooseMode = action.payload.chooseMode;
        },
        setChooseBd_id:(state, action)=>{    
            state.selected_bd_id = action.payload.selected_bd_id;
            state.selected_bd_name = action.payload.selected_bd_name;
        },
        setChooseUser_id:(state, action)=>{    
            state.selected_user_id = action.payload.selected_user_id;
            state.selected_user_email = action.payload.selected_user_email;
        },        
        setAccessLevel:(state, action)=>{
            state.accessLevel = action.payload.accessLevel;
            getAccessLevelText(state);
        },    
    }
})

const getAccessLevelText=(state)=>{
    switch (state.accessLevel) {
        case 1:     state.accessLvlText ="Co-owner";    break;
        case 2:     state.accessLvlText ="Admin";    break;
        default:
            state.accessLevel=3;
            state.accessLvlText ="User";
            break;
    }
}

/** Export Action */
export const {
    setBdInfo,
    switchAccessLvl,
    storeEmailList,
    resetCfgInfo,
    setChooseMode,
    setChooseBd_id,
    setChooseUser_id,
    setAccessLevel,
} = slice.actions;


/** reducer */
export default slice.reducer;


/** Action Creator, call action */


/** Selector, read from store */
export const getEditShareCfg = createSelector(
    state=> state.dev.shareSensor,
    ({chooseMode, selected_bd_id, selected_user_id, selected_bd_name, selected_user_email}) => ({chooseMode, selected_bd_id, selected_user_id, selected_bd_name,selected_user_email})
)
