import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { FaArrowUp, FaArrowDown} from "react-icons/fa";
import { FiEdit3, FiTrash2} from "react-icons/fi";
import { toast } from 'react-toastify';
import { sortTemplate, swapArray } from '../../../../../function/ArrayFn/arrayFn';
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import v2OwnDevFn from '../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import TpDiaRename from '../../Template/DialogTemplate/TpDiaRename';
import TpDiaDelete from './../../Template/DialogTemplate/TpDiaDelete';

function V2aSortArea(props) {
    // eslint-disable-next-line
    const [G_Area_Db, setG_Area_Db] = useState([]);
    const [G_Area_Display, setG_Area_Display] = useState([]);
    const [G_bLoaded, setG_bLoaded] = useState(false);
    const [G_SelAreaInfo, setG_SelAreaInfo] = useState({name:"", _id:0});
    const [G_ShowTp, setG_ShowTp] = useState(0);
    const [G_SortTrigged, setG_SortTrigged] = useState(false);
    const [G_ShareLevel, setG_ShareLevel] = useState(0);

    const refPgReload = useRef(0);

    useEffect(()=>{
        async function startUp(){
            
            /** load bd Info */
            let bdInfo  = localStorageFn.getBdToken();            
            setG_ShareLevel(bdInfo.shareLevel);
            
            /** load area under floor */
            let areaList = await v2OwnDevFn.v2aGetRelatedArea(bdInfo._id, bdInfo.floor_id);
            areaList = sortArr(areaList);
            setG_Area_Db(areaList);
            setG_Area_Display(areaList);

            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, [refPgReload.current]);

    

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "sortIdx", descOrder: false },
            { key: "name", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const backPrevPg=()=>{
        let pathname ="/data/deviceinfloor"
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const handleSortUp=(ind)=>{        
        if(ind<=0) return;
        let _Floor_Display = [...G_Area_Display]
        _Floor_Display = swapArray(_Floor_Display, ind-1,ind);
        setG_Area_Display(_Floor_Display);
        setG_SortTrigged(true)
    }

    const handleSortDown=(ind)=>{
        if(ind>=G_Area_Display.length-1) return;
        let _Floor_Display = [...G_Area_Display]
        _Floor_Display = swapArray(_Floor_Display, ind,ind+1);
        setG_Area_Display(_Floor_Display);
        setG_SortTrigged(true)
    }

    const handleRename=(selInfo)=>{
        setG_SelAreaInfo(selInfo);
        setG_ShowTp(1);
    }
    const handleCloseDia=()=>{
        setG_ShowTp(0);
    }

    const handleDelete=(selInfo)=>{
        setG_SelAreaInfo(selInfo);
        setG_ShowTp(2);
        
    }
    


    const handleConfirmRename=async(newName)=>{
        try {
            let renemeRel = await v2OwnDevFn.v2aRenameArea(newName, G_SelAreaInfo._id);
            if(renemeRel.errMsg) {
                toast(renemeRel.errMsg)
                setG_ShowTp(0);
                return  
            } 
            /** update local state */
            let _Area_Display = [...G_Area_Display];
            let foundIdx = _Area_Display.findIndex(c=>c._id === G_SelAreaInfo._id);
            if(foundIdx<0) {
                toast("Update local state error");
                setG_ShowTp(0);
                return 
            }
            _Area_Display[foundIdx].name = newName;
            setG_Area_Display(_Area_Display);

            setG_ShowTp(0);
        } catch (error) {
            toast(error.message);
        }
    }

    const handleSaveSort=async()=>{
        /** check which floor index and sort is not tally, store into array */
        let _Area_Display = [...G_Area_Display];
        let toSortList =[];
        for (let i = 0; i < _Area_Display.length; i++) {
            if(_Area_Display[i].sortIdx !== i+1) toSortList.push({_id: _Area_Display[i]._id, sortIdx:i+1});
        }
        if(toSortList.length <1) return toast("No changes")
        let updateRel = await v2OwnDevFn.v2aUpdateSortIdx_AreaList(toSortList);
        if(updateRel.errMsg) return toast(updateRel.errMsg);
        if(updateRel.Success) {            
            toast("Success");
            backPrevPg();
            return
        }
        toast("Save error");
    }

    const handleConfirmDelete=async()=>{
        let delRel = await v2OwnDevFn.v2aDeleteArea(G_SelAreaInfo._id);
        if(delRel.errMsg) {
            setG_ShowTp(0);
            toast(delRel.errMsg);
            return
        }
        refPgReload.current++;        
        setG_ShowTp(0);
    }


    return (
        <div>
            {G_ShowTp ===1 && <TpDiaRename 
                OldName = {G_SelAreaInfo.name} 
                onclickClose = {handleCloseDia}
                onclickConfirm = {handleConfirmRename}
                />}
            {G_ShowTp ===2 && <TpDiaDelete 
                OldName = {G_SelAreaInfo.name} 
                onclickClose = {handleCloseDia}
                onclickConfirm = {handleConfirmDelete}
                />}
                

            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Sort</div>
            </div>

            <div className='spring_BdContainer'>
                {G_bLoaded && G_Area_Display.map((u, ind)=>(
                <div key={`sortBd_${ind}`} className='spring_SortFrame sortHor'>
                    <div className='text_1_1'>{u.name}</div>
                    <div className='flexAndBothCenter stayRight'>
                        {G_ShareLevel <= 2 && <FiTrash2 className='spring_SortIcon redText hoverPointer'
                        onClick={()=>handleDelete(u)}/>}
                        <FiEdit3 className='spring_SortIcon blueText hoverPointer'
                        onClick={()=>handleRename(u)}/>
                        <FaArrowUp className='spring_SortIcon reactCfgButtonOn hoverPointer'
                        onClick={()=>handleSortUp(ind)}/>
                        <FaArrowDown className='spring_SortIcon orangeText hoverPointer'
                        onClick={()=>handleSortDown(ind)}/>
                    </div>
                </div>
                ))}
            </div>

            <div className='sortHor spreadEvenly spring_Margin_30'>
                <div className="spring_roundButton spring_greyColor hoverPointer"
                    onClick={backPrevPg}>Back</div>
                {G_SortTrigged && <div className="roundButton " 
                    onClick={handleSaveSort}>Save</div>}
            </div>
        </div>
    );
}

export default V2aSortArea;