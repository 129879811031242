import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import TpSettingSel from './../../template/SelectionList/SettingSelection/TpSettingSelection';
// import { BsFillPlayFill } from "react-icons/bs";
import { TiArrowBack } from "react-icons/ti";
// eslint-disable-next-line
import { FiPlus, FiEdit3, FiShare2, FiLogOut, FiUsers, FiUser} from "react-icons/fi";
import localStorageFn from "../../../function/localStorage/LocalStorageFn";
import { resetCondi } from '../../../reduxStore/actions/react_cfgAlgo';
// eslint-disable-next-line
import { useSelector, useDispatch } from "react-redux";
import auth from "../../../service/auth/AuthV2";
import SharedUserFn from '../../../function/V2_SensorDevice/v2_SensoSharedUserFn';
import OwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';

function SensorInBdSettingPg(props) {
    const dispatch = useDispatch();

    const [G_userAccessLevel, setG_userAccessLevel] = useState(1000);

    useEffect(() => {
        async function startUp() {
            await getCurUserAccessLevel();
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);

    const getCurUserAccessLevel = async () => {
        let {user_id} = auth.getUserInfo();
        let bd_id = localStorageFn.getCurBd_id();
        let ownBdList = await OwnerFn.getOwnBdByUserId_bd_id(user_id, bd_id);
        console.log("ownBdList", ownBdList);
        let ownThisBd = ownBdList.find((c)=>c._id === bd_id);
        if(ownThisBd) {
            setG_userAccessLevel(1)
            return 
        }
        let sharedRecord = await SharedUserFn.getSharedBd_ByUserId_Bd_idv2(user_id, bd_id);
        let minAcceLvl=1000;
        for (const eachRecord of sharedRecord) {
            if(eachRecord.accessLevel < minAcceLvl ) minAcceLvl = eachRecord.accessLevel;
        }
        setG_userAccessLevel(minAcceLvl)
    };

    const backPrevPg = () => {
        props.history.push({ pathname: "/data/sensorinbd" });
    };
      
      
    const editCfgReaction = () => {
        props.history.push({ pathname: "/reaction/edit" });
        
    };  
    const cfgReaction = async () => {
        // console.log("clickLogout");
        // auth.logout();
        props.history.push({ pathname: "/reaction/trigpattern" });
        await localStorageFn.setEventCfgMode('add');
        dispatch(resetCondi());
    };

    const addShareUser = () => {
        props.history.push({ pathname: "/device/sharev2" });
        
    };  
    const editShareUser = () => {
        props.history.push({ pathname: "/device/editsharev2" });
        
    };  
    

    const backToPrev = () => {
        // console.log("clickLogout");
        // auth.logout();
        props.history.push({ pathname: "/data/sensorinbd" });
    };
      
    const handleLogout = () => {
        // console.log("clickLogout");
        auth.logout();
        props.history.push({ pathname: "/" });
      };
      
    return (
        <div>
            <div className="backContainer divStickOnTop">
                <div className="backIconContainer" onClick={backPrevPg}>
                <IoIosArrowBack className="backIcon" />
                </div>
                <div className="backText">Menu</div>
            </div>

            {G_userAccessLevel<30 && <div className='contentCard'>
                <TpSettingSel
                    onClick={addShareUser}
                    iconClass={() => <FiShare2 />}
                    textDisplay="Share"
                />
                <div className='divSeperateLine'></div>
                <TpSettingSel
                    onClick={editShareUser}
                    iconClass={() => <FiEdit3 />}
                    textDisplay="Edit Share List"
                />
            </div> }

            <div className='contentCard'>
                <TpSettingSel
                    onClick={cfgReaction}
                    iconClass={() => <FiPlus />}
                    textDisplay="New event"
                />
                <div className='divSeperateLine'></div>
                <TpSettingSel
                    onClick={editCfgReaction}
                    iconClass={() => <FiEdit3 />}
                    textDisplay="Edit event"
                />
            </div> 

            <div className='contentCard'>
                <TpSettingSel
                    onClick={handleLogout}
                    iconClass={() => <FiLogOut />}
                    textDisplay="Logout"
                />
                <div className='divSeperateLine'></div>
                <TpSettingSel
                    onClick={backToPrev}
                    iconClass={() => <TiArrowBack />}
                    textDisplay="Back"
                />
            </div> 
            
        </div>
    );
}

export default SensorInBdSettingPg;
