import React from "react";
import TpSettingSel from "../../../../template/SelectionList/SettingSelection/TpSettingSelection";
import { FiLogOut, FiPlus, FiUser} from "react-icons/fi";

import { useDispatch } from "react-redux";
import { clearRegSensorInfo } from "../../../../../reduxStore/actions/dev_regSensor";
import auth from "../../../../../service/auth/AuthV2";
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import { useHistory } from 'react-router-dom';


function TpMenuBdPage(props) {
  const manuLvl = 1;

  const history = useHistory();
  const dispatch = useDispatch();

  const clickAdd = async () => {
    try {
      dispatch(clearRegSensorInfo());
      await localStorageFn.setManuToken(manuLvl);
      // window.open("/device/add");
      history.push({pathname: "/device/add"}); 
    } catch (error) {
      console.log(error.message);
    }
  };
  

  const clickEditUser = async () => {
    // console.log("clickEditUser");
    try {
      await localStorageFn.setManuToken(manuLvl);
      history.push({ pathname: "/user/changepw" });
    } catch (error) {
      console.log(error.message);
    }
  };
  const clickLogout = () => {
    // console.log("clickLogout");
    auth.logout();
    history.push({ pathname: "/" });
  };

  return (
    <div>
      

      <div className='contentCard'>
          <TpSettingSel
              onClick={clickAdd}
              iconClass={() => <FiPlus />}
              textDisplay="Add device(s)"
          />
      </div>
      
      
      <div className='contentCard'>
        <TpSettingSel
            onClick={clickEditUser}
            iconClass={() => <FiUser />}
            textDisplay="Change Password"
        />
        <div className='divSeperateLine'></div>
        <TpSettingSel
            onClick={clickLogout}
            iconClass={() => <FiLogOut />}
            textDisplay="Logout"
        />
      </div> 

    </div>
  );
}

export default TpMenuBdPage;

