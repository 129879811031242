import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from 'reselect';



const slice = createSlice({
    name:'devShareCfg',
    initialState:{
        AcList:[],
        bLoading:false,
    },
    reducers:{ 
        setAcList:(state, action)=>{
            state.AcList = action.payload;
        },
        loadAcList_locking:(state)=>{
            state.bLoading = true;
        },
    }
})


/** Export Action */
export const {
    setAcList, 
    loadAcList_locking
} = slice.actions;


/** reducer */
export default slice.reducer;


/** Selector, read from store */
export const getRjAcList = createSelector(
    state=> state.app.appRj,
    ({AcList}) => (AcList)
)
export const getLocking = createSelector(
    state=> state.app.appRj,
    ({bLoading}) => (bLoading)
)