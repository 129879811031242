import React, { useEffect } from 'react';

function TpDiaSwapStatus(props) {

    useEffect(()=>{
        async function startUp(){
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(bStatus)=>{
        if(props.trigSwitch) {
            props.trigSwitch(bStatus);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>
                
                <div className=' spring_dialogBox '>
                    <div className='spring_ruleVarListContainer'>
                        <div className='spring_sync_Title'>Sync</div>
                        <div className='spring_sync_desc'>Actual air-con status?</div>
                        <div className='sortHor'>
                            <div className='spring_sync_button hoverPointer' 
                                onClick={()=>handleConfirm("ON")}>On</div>
                            <div className='spring_sync_button spring_acRedColor hoverPointer'
                                onClick={()=>handleConfirm("OFF")}>Off</div>
                        </div>
                    </div>   

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer"
                        onClick={handleClose}
                        style={{width:"160px", height:"30px", margin:"5px 0px 40px 0px"}}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>


            </div>
        </div>
    );
}

export default TpDiaSwapStatus;