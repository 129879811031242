import React from "react";
import { VscUngroupByRefType } from "react-icons/vsc";
import TpSettingSel from "../../../../template/SelectionList/SettingSelection/TpSettingSelection";
import { FiLogOut, FiPlus, FiUser, FiEdit3, FiShare2} from "react-icons/fi";
import { CgExtensionAdd} from "react-icons/cg";
import { useDispatch } from "react-redux";
import { clearRegSensorInfo } from "../../../../../reduxStore/actions/dev_regSensor";
import auth from "../../../../../service/auth/AuthV2";
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import shareEditToken from '../../../../../function/localStorage/lsEditShareFn';
import { useHistory } from 'react-router-dom';
import { GoLocation } from 'react-icons/go';
import { MdOutlineEditNote, MdOutlineFileDownload } from 'react-icons/md';
import { resetCfgInfo } from "../../../../../reduxStore/actions/dev_shareCfg";


function TpMenuFloorPage(props) {
  const manuLvl = 2;

  const history = useHistory();
  const dispatch = useDispatch();

  const clickAdd = async () => {
    try {
      dispatch(clearRegSensorInfo());
      await localStorageFn.setManuToken(manuLvl);
      // window.open("/device/add");
      history.push({pathname: "/device/add"}); 
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRename = () => {
    try {
      if(props.onclickOpenRenameDia) props.onclickOpenRenameDia();      
    } catch (error) {
      console.log(error.message);
    }
  };
  

  const clickToSort = async () => {
    try {
      await localStorageFn.setManuToken(manuLvl);
      // window.open("/device/add");
      history.push({pathname: "/bd/sortfloor"}); 
    } catch (error) {
      console.log(error.message);
    }
  };
  

  const handleAddFloor = () => {
    try {
      if(props.onclickOpenAddGroup) {
        props.onclickOpenAddGroup();     
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  const clickRelocate = async () => {
    try {
      await localStorageFn.setManuToken(manuLvl);
      // window.open("/device/add");
      history.push({pathname: "/device/relocate"}); 
    } catch (error) {
      console.log(error.message);
    }
  };

  const clickShareDevice = async () => {
    try {
      await localStorageFn.setManuToken(manuLvl);      
      shareEditToken.clearToken();
      dispatch(resetCfgInfo());
      // window.open("/device/add");
      history.push({pathname: "/device/share"}); 
    } catch (error) {
      console.log(error.message);
    }
  };

  
  const clickEditShare = async () => {
    try {
      await localStorageFn.setManuToken(manuLvl);
      // window.open("/device/add");
      history.push({pathname: "/device/share/edit"}); 
    } catch (error) {
      console.log(error.message);
    }
  };
  

  const clickEditUser = async () => {
    // console.log("clickEditUser");
    try {
      await localStorageFn.setManuToken(manuLvl);
      history.push({ pathname: "/user/changepw" });
    } catch (error) {
      console.log(error.message);
    }
  };
  const clickLogout = () => {
    auth.logout();
    history.push({ pathname: "/" });
  };

  const clickDataDownload = async () => {
    try {
      await localStorageFn.setManuToken(manuLvl);
      // window.open("/device/add");
      history.push({pathname: "/data/download"}); 
    } catch (error) {
      console.log(error.message);
    }
  };

  // const clickRequestList = () => {
  //   try {
  //     localStorageFn.setManuToken(manuLvl);
  //     // window.open("/device/add");
  //     history.push({pathname: "/data/download/tickets"}); 
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  return (
    <div>
      {props.shareLevel <= 4 &&  <div className='contentCard'>
          <TpSettingSel
            onClick={handleRename}
            iconClass={() => <FiEdit3 />}
            textDisplay="Rename"
          />
      </div> }
      
      {props.shareLevel <= 4 &&  <div className='contentCard'>
        {props.shareLevel <= 2 && <TpSettingSel
          onClick={handleAddFloor}
          iconClass={() => <CgExtensionAdd />}
          textDisplay="Add new group"
          />}
        {props.shareLevel <= 2 && <div className='divSeperateLine'></div>}
        <TpSettingSel
            onClick={clickToSort}
            iconClass={() => <VscUngroupByRefType />}
            textDisplay="Manage group"
        />
      </div>}
      
      {props.shareLevel <= 4 && <div className='contentCard'>                
        {props.shareLevel <= 2 &&  <TpSettingSel
              onClick={clickAdd}
              iconClass={() => <FiPlus />}
              textDisplay="Add device(s)"
          />}
        {props.shareLevel <= 2 && <div className='divSeperateLine'></div>}
        {props.shareLevel <= 2 && <TpSettingSel
          onClick={clickRelocate}
          iconClass={() => <GoLocation />}
          textDisplay="Move device(s)"
        />}
        {/* {props.shareLevel <= 2 && <div className='divSeperateLine'></div>}
        <TpSettingSel
          onClick={handleSortDev}
          iconClass={() => <BiSort />}
          textDisplay="Sort device(s)"
        /> */}
      </div> }

      
      {props.shareLevel <= 2 && <div className='contentCard'>
        <TpSettingSel
            onClick={clickShareDevice}
            iconClass={() => <FiShare2 />}
            textDisplay="Share Device"
        />
        <div className='divSeperateLine'></div>
        <TpSettingSel
            onClick={clickEditShare}
            iconClass={() => <MdOutlineEditNote />}
            textDisplay="Edit Share"
        />
      </div> }

      <div className='contentCard'>
        <TpSettingSel
            onClick={clickDataDownload}
            iconClass={() => <MdOutlineFileDownload />}
            textDisplay="Data Download"
        />
        {/* <div className='divSeperateLine'></div>
        <TpSettingSel
            onClick={clickRequestList}
            iconClass={() => <MdOutlineListAlt />}
            textDisplay="Request List"
        /> */}
      </div> 

      <div className='contentCard'>
        <TpSettingSel
            onClick={clickEditUser}
            iconClass={() => <FiUser />}
            textDisplay="Change Password"
        />
        <div className='divSeperateLine'></div>
        <TpSettingSel
            onClick={clickLogout}
            iconClass={() => <FiLogOut />}
            textDisplay="Logout"
        />
      </div> 

    </div>
  );
}

export default TpMenuFloorPage;

