import React, { useEffect, useState } from 'react';
import { isEmptyArr, isEmptyObj } from '../../../../../../../function/ArrayFn/arrayFn';
import v2OwnDevFn from '../../../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import sensorFn from '../../../../../../../function/sensor/sensorManagementFn'
import TpSubSubDiaVarChooseDev from './TpSubSubDiaVarChooseDev';
import TpSubSubDiaVarChoosePara from './TpSubSubDiaVarChoosePara';
import { toast } from 'react-toastify';
import { FiTrash2} from "react-icons/fi";
import { F_MatchUnit } from '../RjFunction/RjFn';

function TpDiaEditLinkSensor(props) {

    const [G_DevsInBd, setG_DevsInBd] = useState([]); 
    const [G_TabPage, setG_TabPage] = useState(0); 
    const [G_SelSensor, setG_SelSensor] = useState({name:""}); 
    const [G_PrevSensorType, setG_PrevSensorType] = useState(0); 
    const [G_ParaList, setG_ParaList] = useState([]); 
    const [G_SelPara, setG_SelPara] = useState({dataName:""}); 
    const [G_Loaded, setG_Loaded] = useState(false); 


    useEffect(()=>{
        async function startUp(){
            /** load selected var info */
            if(props.selVar){
                if(isEmptyObj(props.selVar)){
                    // console.log("Empty slot selected");
                }else{
                    if(props.selVar.Var_bdDevId) {
                        await loadVarInfo();
                    }
                }
            }
            setG_Loaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const loadVarInfo=async ()=>{
        /** set var name */
        document.getElementById('in_paraName').value = props.selVar.varName;        

        /** load device info */
        let {Var_bdDevId, sensorType} = props.selVar;
        let devInfo = await v2OwnDevFn.getBdDev_By_bdDevIdList([Var_bdDevId]);
        /** load para selected */
        let paraList = await sensorFn.getParaByTypeList([sensorType]);

        let found = paraList.find((c)=>F_MatchUnit(props.selVar, c));

        setG_SelSensor(devInfo[0]);
        if(found)setG_SelPara(found)
        // for (const eachVar of props.selVar) {
        //     // let newVar = {...eachVar};
        //     // if(found) newVar.unit = found.dataUnit;
        //     // else newVar.unit = "";
        //     // newlinkVar.push(newVar);
        // }
        
    }

    const handleSelSensor=async ()=>{
        /** load all sensor in building */
        if(isEmptyArr(G_DevsInBd)){
            let devInBd = await v2OwnDevFn.v2aGetBdDevInBd(props.dev.buidling_id);
            let devInBdExpOwn = devInBd.filter(c=>c._id !== props.dev._id);
            setG_DevsInBd(devInBdExpOwn);
        }
        setG_TabPage(1);
        /** call a dialog box and display the sensor list */
        
    }

    const handleSelPara=async ()=>{
        /** if sensor not choosed, return  */
        if(isEmptyObj(G_SelSensor)) {
            toast("Please select sensor");
            return
        }
        if(!G_SelSensor._id) return toast("Please select sensor");
        /** if sensor type remain, skip DB query*/
        if(G_PrevSensorType===0 || G_SelSensor.type !== G_PrevSensorType){
            /** load sensor paramter base on sensor type selected ???*/
            let paraList = await sensorFn.getParaByTypeList([ G_SelSensor.type]);
            setG_ParaList(paraList);

            setG_PrevSensorType(G_SelSensor.type);
        }

        setG_TabPage(2);
        
    }

    const handleCloseChoose=()=>{
        setG_TabPage(0);
    }
    const handleChooseDev=(devInfo)=>{
        setG_SelSensor(devInfo);
        setG_SelPara({});   // clear the parameter selected
        handleCloseChoose();
    }
    const handleChoosePara=(paraInfo)=>{
        setG_SelPara(paraInfo);
        handleCloseChoose();
    }
    
    const handleClose=()=>{
        if(props.closeEdit) props.closeEdit();
    }
    const handleConfirm=()=>{
        if(props.confirmChange) {
            /** check if name is not empty */
            let name = document.getElementById("in_paraName").value.trim();
            if(name.trim() === "" ) return toast("Insert name");

            /** check if Sensor not selected */
            if(G_SelSensor._id <=0) return toast("Select sensor");
            
            /** check if paramter not selected */
            if(G_SelPara._id <=0) return toast("Select sensor");

            props.confirmChange(name, G_SelSensor, G_SelPara);
        }
    }
        
    const handleDelVar=()=>{
        if(props.deleteVar) props.deleteVar();
    }

    return (
        <div>
            {G_TabPage ===1 && <TpSubSubDiaVarChooseDev
                devList = {G_DevsInBd}
                onclickClose={handleCloseChoose}
                chooseDev={handleChooseDev}/>}

            {G_TabPage ===2 && <TpSubSubDiaVarChoosePara
                onclickClose={handleCloseChoose}
                paraList={G_ParaList}
                choosePara = {handleChoosePara}/>}


            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>
                
                <div className=' spring_dialogBox '>
                    <div className='spring_editLinkVarContainer'>
                        <div className='spring_editLinkVar_ItemBox'>  
                            <div className='spring_inputTpMidText'>Name</div>
                            <div className="searchBarContainer_non">
                                <input id='in_paraName' 
                                    autoComplete='off'
                                    className='spring_diaSearchInput blueText boldText'
                                    style={{width:"100%", marginRight:"0px"}}
                                    min="16"
                                    max="30"
                                    maxLength={80}
                                    placeholder="<Insert Name>"
                                    // onBlur={F_LimitInputSp}
                                    />
                            </div>    
                        </div>
                        
                        <div className='spring_editLinkVar_ItemBox'>
                            <div className='spring_inputTpMidText'>Sensor</div>
                            {G_Loaded && <div className='spring_editLinkVarselBar blueText hoverPointer wrapTextEllipsis'
                            onClick={handleSelSensor}>
                                {/* {typeof G_SelSensor.name === "undefined"?  */}
                                {G_SelSensor.name? 
                                    <div>{G_SelSensor.name}</div>:
                                    <div className='greyOutText'>{`<Select Sensor>`}</div>}
                            </div>}
                            {!G_Loaded && <div className='spring_editLinkVarselBar'>Loading...</div>}
                        </div>
                        
                        <div className='spring_editLinkVar_ItemBox'>
                            <div className='spring_inputTpMidText'>Parameter</div>
                            {G_Loaded && <div className='spring_editLinkVarselBar blueText hoverPointer'
                            onClick={handleSelPara}>    
                                {/* {typeof G_SelPara.dataName === "undefined"?  */}
                                {G_SelPara.dataName? 
                                    <div>{G_SelPara.dataName}</div>:
                                    <div className='greyOutText'>{`<Select value>`}</div>}
                            </div>}
                            {!G_Loaded && <div className='spring_editLinkVarselBar'>Loading...</div>}
                        </div>
                    </div>

                    <div className='spring_seperationLine'></div>

                    <div className='flexAndBothCenter spring_sceneVarDelTab'>
                        <div className='spring_sceneVarDelText hoverPointer'
                        onClick={handleDelVar}>Remove Variable</div>
                        <div className='spring_sceneVarDelIcon flexAndBothCenter'><FiTrash2/></div>
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaEditLinkSensor;