import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { FiTrash2} from "react-icons/fi";
import TpDiaChooseDevice from '../../Template/DialogTemplate/TpDiaChooseDevice';
import localStorageFn  from '../../../../../function/localStorage/LocalStorageFn';
import v2OwnDevFn from '../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { sortTemplate } from '../../../../../function/ArrayFn/arrayFn';
import TpSubDiaChooseFloor from './../../Template/Subdialog/TpSubDiaChooseFloor';
import TpSubDiaChooseArea from './../../Template/Subdialog/TpSubDiaChooseArea';
import { toast } from 'react-toastify';
import LegoLoader from '../../Template/LoaderTemplate/legoLoader';

function V2aRelocateDevice(props) {
    
    const [G_bdDevList_display, setG_bdDevList_display] = useState([]); 
    
    const [G_bdDevList_toDia, setG_bdDevList_toDia] = useState([]); 
    const [G_floorList_toDia, setG_floorList_toDia] = useState([]); 
    const [G_areaList_toDia, setG_areaList_toDia] = useState([]); 
    const [G_bdDevList, setG_bdDevList] = useState([]); 
    
    const [G_MoveToFloor, setG_MoveToFloor] = useState({floor_id:0, floorName:"Others"}); 
    const [G_MoveToArea, setG_MoveToArea] = useState({area_id:0, areaName:"Others"}); 
    const [G_ShowTp, setG_ShowTp] = useState(0); 
    const [G_bLoaded, setG_bLoaded] = useState(false); 
    
    const refManuLevel = useRef(0);

    useEffect(()=>{
        async function startUp(){
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;
            
            let bdInfo  = localStorageFn.getBdToken();
            /** load device in bd */
            let devList = await v2OwnDevFn.v2aGetBdDevInBd(bdInfo._id);
            devList = sortArr(devList);
            setG_bdDevList(devList);
            /** load floor in bd */
            let floorList = await v2OwnDevFn.getRelatedFloor(bdInfo._id);
            setG_floorList_toDia(floorList);
            /** load area in bd */
            let allAreaList = await v2OwnDevFn.v2aGetAllAreaUnderBd(bdInfo._id);
            setG_areaList_toDia(allAreaList);
            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "name", descOrder: false },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const backPrevPg=()=>{
        let pathname ="/data/floorinbd"
        if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }
    const callAddDev=()=>{
        setG_bdDevList_toDia(G_bdDevList);
        setG_ShowTp(1);
    }
    const handleCloseDiaChoose=()=>{
        setG_ShowTp(0);

    }
    const handleConfirmDevList=(dbList)=>{
        setG_ShowTp(0);
        setG_bdDevList(dbList);
        updateDisplayTable(dbList);
    }

    const updateDisplayTable=(dbList)=>{
        let _displayArr = [];
        for (const eachDev of dbList) {
            if(eachDev.bChecked) _displayArr.push(eachDev);
        }
        setG_bdDevList_display(_displayArr);
    }

    const handleRemove=(devInfo)=>{
        // let _bdDevList = G_bdDevList.filter(c=>c._id!==devInfo._id);
        /** update table display */
        let _bdDevList_display = G_bdDevList_display.filter(c=>c._id!==devInfo._id);
        setG_bdDevList_display(_bdDevList_display);

        /** update DB */
        let idx = G_bdDevList.findIndex(c=>c._id===devInfo._id);
        let _bdDevList = [...G_bdDevList];
        _bdDevList[idx].bChecked = false;
        setG_bdDevList(_bdDevList);
    }
    const handleChooseFloor=()=>{
        setG_ShowTp(2);
    }

    const handleConfirmChooseFloor=(devInfo)=>{
        setG_MoveToArea({area_id:0, areaName:"Others"});
        setG_MoveToFloor(devInfo[0]);
        setG_ShowTp(0);
    }
    
    const handleChooseArea=()=>{
        setG_ShowTp(3);
    }

    const handleConfirmChooseArea=(devInfo)=>{
        setG_MoveToArea(devInfo[0]);
        setG_ShowTp(0);
    }

    const handleMove=async ()=>{
        /** generate device list */
        let devList = [];
        for (const eachDev of G_bdDevList_display) {
            devList.push({_id:eachDev._id, 
                floor_id:G_MoveToFloor.floor_id, 
                area_id:G_MoveToArea.area_id});
        }
        /** update database */
        let updateRel = await v2OwnDevFn.v2aUpdateFloorArea_list(devList);
        if(updateRel.errMsg) toast("Error: ", updateRel.errMsg);
        toast("Success");
        backPrevPg();
    }
    

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Move device</div>
            </div>
            {!G_bLoaded && <LegoLoader />}
            {/* {!G_bLoaded && <div>Loading...</div>} */}
            {G_bLoaded && <div>
                {G_ShowTp===1 && <TpDiaChooseDevice
                    onclickClose = {handleCloseDiaChoose}
                    onclickConfirm = {handleConfirmDevList}
                    bdDevList = {G_bdDevList_toDia}
                    floorList = {G_floorList_toDia}
                    areaList = {G_areaList_toDia}
                    />}

                {G_ShowTp ===2 && <TpSubDiaChooseFloor
                    onclickClose = {handleCloseDiaChoose}
                    onclickConfirm = {handleConfirmChooseFloor}
                    skipShowAll = {true}
                    devInBd={G_bdDevList}
                    floorInBd={G_floorList_toDia}
                    />}
                    
                {G_ShowTp ===3 && <TpSubDiaChooseArea
                    onclickClose = {handleCloseDiaChoose}
                    selectedFloor = {G_MoveToFloor}
                    onclickConfirm = {handleConfirmChooseArea}
                    skipShowAll = {true}           
                    devInBd={G_bdDevList}
                    floorInBd={G_floorList_toDia}
                    areaInDb={G_areaList_toDia}
                    />}


                
                
                <div className='spring_nextInputTitle boldText'>{`Device(s)`}</div>

                <div className='spring_inputBox spring_listContainer'>
                    {G_bdDevList_display.map((u, ind)=>(<div key={`dev_${ind}`} >
                        {ind!==0 && <div className='divSeperateLine'></div>}
                        <div className='sortHor spring_TableItemSpace'>
                            <div className='spring_listContainerItem'>{`[${u.devID}] ${u.name}`}</div>
                            <div className='stayRight flexAndBothCenter  hoverPointer square_25'
                            onClick={()=>handleRemove(u)}>
                                <FiTrash2 className='redText'/>
                            </div>
                        </div>
                    </div>))}   
                    
                    <div className={`whiteText greenBgc spring_containerAddDev ${G_bdDevList_display.length > 0 ?"spring_addDevTopBorder" : "spring_addDevAllBorder"} hoverPointer`}
                    onClick={callAddDev}> + Select device(s)</div>

                </div>
                

                <div className='spring_nextInputTitle boldText'>Move to</div>

                <div className='spring_whiteCardArea'>
                    <div className='sortHor spring_whiteCardItem'>
                        <div className='spring_WCItemTitle'>
                            Group
                        </div>
                        <div>:</div>
                        <div className='spring_WCItemValue hoverPointer'
                        onClick={handleChooseFloor}>
                            {`${G_MoveToFloor.floorName}`}
                        </div>
                    </div>

                    {G_MoveToFloor.floor_id > 0 && <div className='divSeperateLine'></div>}

                    {G_MoveToFloor.floor_id > 0 && <div className='sortHor spring_whiteCardItem'>
                        <div className='spring_WCItemTitle'>
                            Subgroup
                        </div>
                        <div>:</div>
                        <div className='spring_WCItemValue hoverPointer'
                        onClick={handleChooseArea}>
                            {`${G_MoveToArea.areaName}`}
                        </div>
                    </div>}
                </div>

                <div className='sortHor spreadEvenly spring_2BtnBtmMargin'>
                    <div className="spring_roundButton spring_greyColor hoverPointer"
                        onClick={backPrevPg}
                        >Back</div>
                    <div className="roundButton " 
                        onClick={handleMove}
                        >Move</div>
                </div>
            </div>}
        </div>
    );
}

export default V2aRelocateDevice;