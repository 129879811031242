import React, { useEffect, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import auth from '../../../service/auth/AuthV2';
import { setBdInfo } from './../../../reduxStore/actions/dev_shareCfg';
import { useDispatch } from 'react-redux';

function ShareSelBuildingPg(props) {
    const dispatch = useDispatch();
    
    const [G_CoOwnedBd, setG_CoOwnedBd] = useState([]);
    const [G_DisplayBd, setG_DisplayBd] = useState([]);
    const [G_bLoaded, setG_bLoaded] = useState(false);

    useEffect(()=>{

        async function startUp(){
            let user = auth.getUserInfo();
            if(!user) return props.history.push({pathname: "/device/share"});
            let {user_id} = user;
            let coOwnedBd = await loadCoOwnedBuilding(user_id);
            setG_CoOwnedBd(coOwnedBd);
            setG_DisplayBd(coOwnedBd);
            // console.log(user);
            /** load building own by current user */
            setG_bLoaded(true);

        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);
    
    const selBd=(c)=>{
        // console.log("c" , c);
        dispatch(setBdInfo({bdName:c.name, bd_id:c._id}))
        props.history.push({pathname: "/device/share"});
        
    }

    const loadCoOwnedBuilding=async(user_id)=>{
        let bdInvolved = await sensorOwnerFn.getRelatedBd(user_id);
        if(!bdInvolved) return 
        let filtered = bdInvolved.filter(c=>!c.accessLevel || c.accessLevel < 2);
        // console.log("Before", bdInvolved);
        // console.log("After", filtered);
        return filtered;

    }
    const backPrevPg=()=>{
        // console.log("back");
        props.history.push({pathname: "/device/share"});
    }
    const handleSearch=()=>{
        let searchInput = document.getElementById('searchInput').value.trim();
        // console.log(searchInput);
        if(searchInput === "") {
            setG_DisplayBd(G_CoOwnedBd);    // when clear search text, display full array
            return 
        }
        let _coOwnBd = [...G_CoOwnedBd];
        
        let filtered = _coOwnBd.filter((c)=>c.name.toUpperCase().search(searchInput.toUpperCase()) >= 0);
        setG_DisplayBd(filtered);
    }
    

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Choose Building</div>
            </div>

            <div className='cfgSearchBar'>
                <div className ='searchBarContainer' style={{marginRight: "10px"}}>
                    <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                    <div onClick={handleSearch} className='searchButton'>
                        <i className='fa fa-search'></i>
                    </div>    
                </div>
            </div>
            
            <div >
                {G_DisplayBd.length > 0 && G_bLoaded && G_DisplayBd.map((c,ind)=>
                    <div key={`bdRow_${ind}`} className='cfgOptionSel '
                    onClick={()=>selBd(c)}>
                        <div className='wrapTextEllipsis'>{c.name}</div>
                        {/* <div className='wrapTextEllipsis'>hhhasoiudhaoishfoiahsoihdoiahsfoiahsoidhaoisfhoaosijoaihsoidahsoifhaoishdoaishfaishfoiashdoiahsofihaosidhaoishdoiasdh</div> */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ShareSelBuildingPg;