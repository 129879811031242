// import { log } from "joi-browser";
import React, { Component } from "react";
import "../../../css/devSummary.css";
import NodeIcons from "../Icons/NodeIcons";
import * as MdIcons from "react-icons/md";
// import { TransferWithinAStationSharp } from "@material-ui/icons";

class TpDeviceSummary extends Component {
  state = {
    // devSumData:{}
    devSumData: {
      deviceName: "",
      batteryPercentage: 0,
      signalStrength: 0,
      a_display: [
        // {name:'inletTemp', sValue: '23.8 C'},
        // {name:'outletTemp', sValue: '27.8 C'},
        // {name:'humidity', sValue: '58.8 %'},
        // {name:'inletTemp', sValue: '23.8 C'},
      ],
      a_ctrl: [
        // {name: 'ON'},
        // {name: 'OFF'},
      ],
      batteryIcon: "",
      devType: 0,
    },
  };

  async componentDidMount() {
    if (this.props.devSumData) {
      await this.setState({ devSumData: this.props.devSumData });
      await this.setState({ devType: this.props.devType });
      await this.setBatteryIcon();
    }
    console.log(this.state.devType);
  }

  handleClick = (ind) => {
    console.log(`Clicked ${ind}`);
    if (this.props.ctrlCmd) {
      this.props.ctrlCmd(ind);
    }
  };

  nodeIcons = () => {
    if (this.state.devType === 1) {
      return <NodeIcons.RtrhIcon className="nodeIcon" />;
    } else if (this.state.devType === 16) {
      return <NodeIcons.AcControlIcon className="nodeIcon" />;
    }
  };

  setBatteryIcon = async () => {
    let batteryIcon = "fa fa-battery-empty";
    if (this.state.devSumData.batteryPercentage > 90) {
      batteryIcon = "fa fa-battery-full";
    } else if (this.state.devSumData.batteryPercentage > 59) {
      batteryIcon = "fa fa-battery-three-quarters";
    } else if (this.state.devSumData.batteryPercentage > 39) {
      batteryIcon = "fa fa-battery-half";
    } else if (this.state.devSumData.batteryPercentage > 10) {
      batteryIcon = "fa fa-battery-three-quarters";
    }
    await this.setState({ batteryIcon });
  };

  signalStatus = () => {
    if (this.state.devSumData.signalStrength > 90)
      return <MdIcons.MdSignalCellular4Bar className="statusIcon" />;
    else if (this.state.devSumData.signalStrength > 59)
      return <MdIcons.MdSignalCellular3Bar className="statusIcon" />;
    else if (this.state.devSumData.signalStrength > 39)
      return <MdIcons.MdSignalCellular2Bar className="statusIcon" />;
    else if (this.state.devSumData.signalStrength > 10)
      return <MdIcons.MdSignalCellular1Bar className="statusIcon" />;
    else return <MdIcons.MdSignalCellular0Bar className="statusIcon" />;
  };

  render() {
    let devSumData = this.state.devSumData;
    console.log(devSumData);

    return (
      <>
        <div className="devSummaryContainer">
          <div className="minTitle">{devSumData.deviceName}</div>
          <div className="ds_iconSec">
            <div className="flexHorWrapContent">{this.nodeIcons()}</div>
            <div className="flexVer flexHorWrapContent">
              <div className="flexHorWrap">
                <div className="flexDirVer">
                  <i className={`${this.state.batteryIcon} statusIcon`} />
                </div>

                <div className="flexDirVer statusDisplay">{`${devSumData.batteryPercentage}%`}</div>
              </div>
              <div className="flexHorWrap">
                <div className="flexDirVer">{this.signalStatus()}</div>
                <div className="statusDisplay">{devSumData.signalStrength}</div>
              </div>
            </div>
          </div>
          <div className="flexHorWrap ">
            {devSumData.a_display.map((u, ind) => (
              <div className="flexWrap flexDirVer" key={`display_${ind}`}>
                <div className="displayName">{u.name}</div>
                <div className="displayValue">{u.sValue}</div>
              </div>
            ))}
          </div>
          <div className="flexHorWrap">
            {devSumData.a_ctrl.map((u, ind) => (
              <div
                key={`ctrl_${ind}`}
                className="flexHorWrapContent onoff"
                onClick={() => this.handleClick(ind)}
              >
                {u.name}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default TpDeviceSummary;
