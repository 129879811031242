import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../../service/auth/AuthV2";
import timeFn from '../../../function/time/timeFn';
import { toast } from "react-toastify";

function TpV2protectedRoute(props) {
  const [G_AuthErr, setG_AuthErr] = useState();

  useEffect(()=>{
    async function startUp(){        
        // console.log("Mware Start");
        /** if no user login, kick to login page */
        let userInfo = auth.getUserInfo()
        let lastCheck = auth.getLastCheck();
        // console.log(lastCheck);
        if(!userInfo)  return setG_AuthErr(1);
        if(!lastCheck)  return setG_AuthErr(1);        
        /** check activate every 1 mins */
        let unixNow = timeFn.getUnixNow();
        // console.log(unixNow);
        /** token do not have .exp or .iat unix */
        if(!lastCheck.exp || !lastCheck.iat){
          auth.logout();
          return setG_AuthErr(1);
        }
        /** last check User more then 1 mins */
        if( unixNow > lastCheck.exp || unixNow < lastCheck.iat) {
          // console.log("Check user activation");
          let userActive = await auth.checkUserActive();
          // console.log(userActive);
          if(userActive){
            await auth.updateCheckToken();
          }else{  /** user not active */
            auth.logout();
            return setG_AuthErr(1);
          }
        }

        let tokenExpired = unixNow > userInfo.exp;
        // let timeNowBeyond = unixNow < userInfo.iat-10;  // 10 sec buffer

        if(tokenExpired){
          toast('Re-Login Required');
          auth.logout();
          return setG_AuthErr(1);
        }

        // if(timeNowBeyond){
        //   toast('Local System Clock Not Tally');
        // }
        // if(tokenExpired || timeNowBeyond ) {
        //   if(tokenExpired) toast('Re-Login Required');
        //   else if(timeNowBeyond) toast('Local System Clock Not Tally');
        //   auth.logout();
        //   return setG_AuthErr(1);
        // }

        setG_AuthErr(0);
    }
    startUp();

    return ()=>{
        // alert("AddDevicePg Page Close");
    }
    // eslint-disable-next-line
}, [props]);


  return (
    <div>
      {G_AuthErr===0 && <Route  path={props.path} component={props.component}/>}
      {G_AuthErr===1 && <Redirect to={{pathname: "/user/login",state: { from: props.location },}}/>}
    </div>
  );
}

export default TpV2protectedRoute;

