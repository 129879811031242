import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
// import { MdOutlineFileUpload } from "react-icons/md";
import { isEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import { FaMicrochip } from "react-icons/fa6";
import { MdCloudCircle } from "react-icons/md";

const SLTabCfgDots = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadLNodeDots(mode, _pi, _pf) {
      F_FillUpLNodeDots(mode, _pi, _pf);
    },
  }));

  const [G_MemberList, setG_MemberList] = useState([
    { ht: "", hi: "", bdDev_id: "", comType: 1, dotGwID: "", loraFreq: 433.0 },
  ]);
  const [G_UploadedGwID, setG_UploadedGwID] = useState(0);

  const [G_storageMode, setG_storageMode] = useState("db");

  const ref_UploadDelay = useRef(null);

  useEffect(() => {
    // console.log("props", );
    
    async function startUp() {
      if(props.loadDbDots) await props.loadDbDots();
      // let {memlist, gwId} = F_GetDotsData();
      // F_DelayToShowPlaceData(memlist);      
      // setG_UploadedGwID(gwId)
      // setG_MemberList(memlist);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.uploadedDotsInfo]);

  const F_FillUpLNodeDots = (mode, _pi, _pf) => {
    if(isEmptyArr(_pi)) {
      setG_MemberList([
        { ht: "", hi: "", bdDev_id: "", comType: 1, dotGwID: "", loraFreq: 433.0 },
      ]);
      setG_UploadedGwID(0);
    } else {
      let dotsInfo = [];
      for (let i = 0; i < 8; i++) {
        if(_pf[i] === 0) break;
        dotsInfo.push({
          ht:_pi[(i*5)+1], 
          hi:_pi[(i*5)+2], 
          bdDev_id:_pi[(i*5)+3], 
          comType:_pi[(i*5)+4], 
          dotGwID:_pi[(i*5)+5], 

          loraFreq:_pf[i]
        });
      }
      setG_MemberList(dotsInfo);
      setG_UploadedGwID(_pi[0]);
    }
    setG_storageMode(mode);
  }

  // const F_GetDotsData = () => {
  //   let memlist = [];
  //   let gwId = 0;
  //   if(props.uploadedDotsInfo && props.uploadedDotsInfo.dotsInfo){
  //       for (const eachDots of props.uploadedDotsInfo.dotsInfo) {
  //           if(!Fin_invalidNo(eachDots.ht) && !Fin_invalidNo(eachDots.hi)){
  //               memlist.push(eachDots);
  //           }
  //       }
  //   }
  //   if(props.uploadedDotsInfo && props.uploadedDotsInfo.gwID){
  //       gwId = props.uploadedDotsInfo.gwID;
  //   }
  //   return {memlist, gwId}
  // }

  const F_ToggleComType = (ind) => {
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    if (_MemberList[ind].comType === 1) _MemberList[ind].comType = 2;
    else _MemberList[ind].comType = 1;
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);
    if(G_storageMode !== "none") setG_storageMode("none");
  };

  const F_AddDots = () => {
    if (G_MemberList.length >= 8) return;
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.push({
      ht: 0,
      hi: 0,
      bdDev_id: 0,
      comType: 1,
      dotGwID: 0,
      loraFreq: 433.0,
    });
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const F_DeleteDots = (ind) => {
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.splice(ind, 1);
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const F_UpdateInfo=()=>{
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    for (let i = 0; i < 8; i++) {
        if(document.getElementById(`in_${props.pos}_${i}_hi`)){
            _MemberList[i].hi = parseInt(document.getElementById(`in_${props.pos}_${i}_hi`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_ht`)){
            _MemberList[i].ht = parseInt(document.getElementById(`in_${props.pos}_${i}_ht`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_bdDev_id`)){
            _MemberList[i].bdDev_id = parseInt(document.getElementById(`in_${props.pos}_${i}_bdDev_id`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_loraFreq`)){
            _MemberList[i].loraFreq = parseFloat(document.getElementById(`in_${props.pos}_${i}_loraFreq`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_dotGwID`)){
            _MemberList[i].dotGwID = parseInt(document.getElementById(`in_${props.pos}_${i}_dotGwID`).value);
        }
    }
    setG_MemberList(_MemberList);
    if(G_storageMode !== "none") setG_storageMode("none");
  }

  const Fin_SetInput=(i, sPara, _MemberList)=>{
    if(document.getElementById(`in_${props.pos}_${i}_${sPara}`)){
        document.getElementById(`in_${props.pos}_${i}_${sPara}`).value = _MemberList[i][sPara];
    }
  }

  const fDisplay_Info=(_MemberList)=>{
    for (let i = 0; i < 8; i++) {
        Fin_SetInput(i, "hi", _MemberList);
        Fin_SetInput(i, "ht", _MemberList);
        Fin_SetInput(i, "bdDev_id", _MemberList);
        Fin_SetInput(i, "loraFreq", _MemberList);
        Fin_SetInput(i, "dotGwID", _MemberList);
    }
    clearTimeout(ref_UploadDelay.current);
  }

  const F_DelayToShowPlaceData=(_MemberList)=>{
    // let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    ref_UploadDelay.current = setTimeout(()=>fDisplay_Info(_MemberList), 100);      
  }

  const Fin_invalidNo=(num)=>{
    if(isNaN(num) || num<= 0 ){
        return true;
    }
    return false;
  }

  const F_inputErrorCheck = () => {
    for (let i = 0; i < G_MemberList.length; i++) {
      if(Fin_invalidNo(G_MemberList[i].ht)) return toast(`Dots[${i+1}], invalid ht`);
      if(Fin_invalidNo(G_MemberList[i].hi)) return toast(`Dots[${i+1}], invalid hi`);
      if(Fin_invalidNo(G_MemberList[i].bdDev_id)) return toast(`Dots[${i+1}], invalid bdDev_id`);

      if(G_MemberList[i].comType===1){    // broker link
          if(G_MemberList[i].loraFreq < 433 || G_MemberList[i].loraFreq > 440){
              return toast(`Dots[${i+1}], invalid loraFreq`);
          }
      }else{      // direct lora
          if(Fin_invalidNo(G_MemberList[i].dotGwID)) return toast(`Dots[${i+1}], invalid dotGwID`);
      }
  }
  }

  const F_sendToNode=()=>{
    /** get the information */
    if(props.uploadNodeDots){
      F_inputErrorCheck();
      props.uploadNodeDots(G_MemberList);
    }
  }

  const F_handleLoadDb = async() => {
    if(props.loadDbDots) await props.loadDbDots();
  }

  const F_handleLoadNode = () => {
    if(props.loadNodeDots) props.loadNodeDots();
  }

  const F_saveToDb = () => {
    if(props.uploadDbDots) {
      F_inputErrorCheck();
      props.uploadDbDots(G_MemberList);
    }
  }

  return (
    <div className="spring_SL_TabPg_NonPadding" style={{ padding: "0px 10px" }}>
      <div className="sortHor">
        <div className="spring_leaderTitle" style={{ marginLeft: "15px" }}>Dots</div>
        
        <div className="stayRight sortHor" style={{ marginRight: "25px" }}>
          <div className={`${props.gwId!==G_UploadedGwID ?"spring_smallText redText":"spring_smallText"}`} style={{ marginRight: "10px" }}>
              {`GwID: ${G_UploadedGwID}`}
          </div>
          <FaMicrochip
            className={G_storageMode === "node"? "reactCfgButtonOn hoverPointer" : "reactCfgButtonOff hoverPointer"}
            onClick={F_handleLoadNode}
            style={{  marginRight: "10px" }}
          />
          <MdCloudCircle
            className={G_storageMode === "db"? "reactCfgButtonOn hoverPointer" : "reactCfgButtonOff hoverPointer"}
            onClick={F_handleLoadDb}
          />
          {/* <MdOutlineFileUpload
            className="reactCfgButtonOn hoverPointer"
            style={{ marginRight: "30px" }}
              onClick={F_uploadCfgDots}
          /> */}
        </div>
      </div>
      <div className="spring_SL_DotsList">
        {G_MemberList.map((c, ind) => (
          <div className="sortHor" key={`DotList_${props.pos}_${ind}`}>
            <div className="spring_marginRight5">{`${ind + 1}.`}</div>
            <div className="spring_SL_CfgDotsFrame">
              <div className="sortHor">
                <div className="sortHor">
                  <div>ht:</div>
                  <input
                    style={{ width: "45px" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_ht`}
                    onChange={F_UpdateInfo}
                    value={c.ht? c.ht : ""}
                  ></input>
                </div>
                <div className="sortHor">
                  <div>hi:</div>
                  <input
                    style={{ width: "45px" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_hi`}
                    onChange={F_UpdateInfo}
                    value={c.hi? c.hi : ""}
                  ></input>
                </div>
                <div className="sortHor">
                  <div>bdDev_id:</div>
                  <input
                    style={{ width: "45px" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_bdDev_id`}
                    onChange={F_UpdateInfo}
                    value={c.bdDev_id? c.bdDev_id : ""}
                  ></input>
                </div>
              </div>

              <div className="sortHor">
                <div className="sortHor">
                  <div>comType:</div>
                  {c.comType === 1 && (
                    <div
                      className="spring_SL_SelButton hoverPointer"
                      style={{ margin: "0 10px 0 5px" }}
                      onClick={() => F_ToggleComType(ind)}
                    >
                      Direct
                    </div>
                  )}
                  {c.comType === 2 && (
                    <div
                      className="spring_SL_SelButton hoverPointer"
                      style={{ margin: "0 10px 0 5px" }}
                      onClick={() => F_ToggleComType(ind)}
                    >
                      Broker
                    </div>
                  )}
                </div>
                {c.comType === 2 && (
                  <div className="sortHor">
                    <div>dotGwID:</div>
                    <input
                      style={{ width: "30%" }}
                      type={"number"}
                      className=" i_time_contentInput hideNumberUpDown"
                      id={`in_${props.pos}_${ind}_dotGwID`}
                      onChange={F_UpdateInfo}
                      value={c.dotGwID? c.dotGwID : ""}
                    ></input>
                  </div>
                )}
                {c.comType === 1 && (
                  <div className="sortHor">
                    <div>loraFreq:</div>
                    <input
                      style={{ width: "30%" }}
                      type={"number"}
                      className=" i_time_contentInput hideNumberUpDown"
                      id={`in_${props.pos}_${ind}_loraFreq`}
                      onChange={F_UpdateInfo}
                      value={c.loraFreq? c.loraFreq : ""}
                    ></input>
                  </div>
                )}
              </div>
            </div>
            <div
              className="hoverPointer redText"
              style={{ marginLeft: "5px" }}
              onClick={() => F_DeleteDots(ind)}
            >
              <FiTrash2 />
            </div>
          </div>
        ))}
        {G_MemberList.length < 8 && <div className="spring_SL_CfgDotsFrame flexAndBothCenter hoverPointer boldText" onClick={F_AddDots} style={{ margin: "0 20px 0 17px" }}> 
          + Add
        </div>}
        {G_MemberList.length >= 8 && <div className="spring_SL_CfgDotsFrame">
          Max
        </div>}

        {G_storageMode === "node" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_saveToDb}>Save to DB</div>}
        {G_storageMode === "db" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Send To Node</div>}
        {G_storageMode === "none" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Send</div>}
        {/* <div className="spring_tpContentSendButton hoverPointer" 
        onClick={F_DownloadDots}>
          Send
        </div> */}

      </div>
    </div>
  );
});

export default SLTabCfgDots;
