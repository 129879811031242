import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";

const MRChTabEachSetting = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadEachChSetting(_pf) {
      F_FillUpEachChSetting(_pf);
    },
  }));

  // eslint-disable-next-line
  const [G_aChiller, setG_aChiller] = useState([1,2,3,4,5]);

  useEffect(() => {
    async function startUp() {}
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, []);

  const F_DownladEachChSetting =()=>{
    // CD: F_GetInputVal_Number(`sys_cd_${props.pos}`),
    let allChSetting = [];
    for (let i = 0; i < G_aChiller.length; i++) {
      let eachChObj = {
        MaxRla:F_GetInputVal_Number(`in_MaxRla_${props.pos}_${i}`),
        HiHi:F_GetInputVal_Number(`in_HiHi_${props.pos}_${i}`),
        Hi:F_GetInputVal_Number(`in_Hi_${props.pos}_${i}`),
        Lo:F_GetInputVal_Number(`in_Lo_${props.pos}_${i}`),
        LoLo:F_GetInputVal_Number(`in_LoLo_${props.pos}_${i}`),
      }
      /** check each input, if got null, return toast err */
      for (const key in eachChObj) {
        if (Object.hasOwnProperty.call(eachChObj, key)) {
          if(eachChObj[key]===null){
            return toast(`Invalid Input (${key}, ${i+1})`);
          }          
        }
      }
      allChSetting.push(eachChObj);
    }
    let passCode = F_GetInputVal_Number(`in_eachChPass_${props.pos}`);
    if (passCode === null) return toast(`Invalid input (Password)`);

    /** validation pass, trigger parent function */    
    if (props.handleDownloadEachChSetting)
      props.handleDownloadEachChSetting(allChSetting, passCode);

  }
  
  const F_handleUplaod = () => {
    if (props.handleUploadEachChSetting) props.handleUploadEachChSetting();
  };
  
  const F_FillUpEachChSetting=(_pf)=>{
    for (let i = 0; i < 5; i++) {
      F_SetInputVal_Number(`in_MaxRla_${props.pos}_${i}`, _pf[(i*5)]);
      F_SetInputVal_Number(`in_HiHi_${props.pos}_${i}`, _pf[(i*5)+1]);
      F_SetInputVal_Number(`in_Hi_${props.pos}_${i}`, _pf[(i*5)+2]);
      F_SetInputVal_Number(`in_Lo_${props.pos}_${i}`, _pf[(i*5)+3]);
      F_SetInputVal_Number(`in_LoLo_${props.pos}_${i}`, _pf[(i*5)+4]);            
    }
  }
  

  return (
    <div className="spring_SL_TabPg">
      {G_aChiller.map((u, ind) => (
        <div key={`eachChiller_Key_${ind}_${props.pos}`}>
          <div className="sortHor">
            <div className="spring_tpContentTitle">{`Chiller ${ind+1}`}</div>
            {ind===0 && <MdOutlineFileUpload
              className="reactCfgButtonOn stayRight hoverPointer"
              style={{ marginRight: "20px" }}
              onClick={F_handleUplaod}
            />}

          </div>

          <div className="spring_tpContentGroup">
            <div className="sortHor spring_tpContent">
              <div>Max RLA</div>
              <input
                style={{ width: "100px", textAlign: "end" }}
                type={"number"}
                className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                id={`in_MaxRla_${props.pos}_${ind}`}
                placeholder="--"
                // onChange={F_UpdateInfo}
              ></input>
            </div>
            <div className="sortHor spring_tpContent">
              <div>HiHI</div>
              <input
                style={{ width: "100px", textAlign: "end" }}
                type={"number"}
                className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                id={`in_HiHi_${props.pos}_${ind}`}
                placeholder="--"
                // onChange={F_UpdateInfo}
              ></input>
            </div>
            <div className="sortHor spring_tpContent">
              <div>HI</div>              
              <input
                style={{ width: "100px", textAlign: "end" }}
                type={"number"}
                className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                id={`in_Hi_${props.pos}_${ind}`}
                placeholder="--"
                // onChange={F_UpdateInfo}
              ></input>
            </div>
            <div className="sortHor spring_tpContent">
              <div>Lo</div>
              <input
                style={{ width: "100px", textAlign: "end" }}
                type={"number"}
                className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                id={`in_Lo_${props.pos}_${ind}`}
                placeholder="--"
                // onChange={F_UpdateInfo}
              ></input>
            </div>
            <div className="sortHor spring_tpContent">
              <div>LoLo</div>
              <input
                style={{ width: "100px", textAlign: "end" }}
                type={"number"}
                className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                id={`in_LoLo_${props.pos}_${ind}`}
                placeholder="--"
                // onChange={F_UpdateInfo}
              ></input>
            </div>
          </div>
        </div>
      ))}
      
      <div className="spring_tpContentTitle">Security</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Password</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`in_eachChPass_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>
      
      <div className="spring_tpContentSendButton hoverPointer"
        onClick={F_DownladEachChSetting}
      >
        Update
      </div>

      <div style={{ marginBottom: "25px" }}></div>
    </div>
  );
});

export default MRChTabEachSetting;
