import React from "react";
import { Link } from "react-router-dom";

function EmailSuccess() {
  return (
    <div>
      <div className="flexCenter" style={{marginTop: "20px"}}>Email Successfully sent. Please check your mailbox.</div>
      <p className="form-btm">
        <Link to="/user/login" className="form-inlink">
          Back to login
        </Link>
      </p>
    </div>
  );
}

export default EmailSuccess;
