import React, { useState } from 'react';
import { RiApps2Line } from 'react-icons/ri';
import { useHistory } from 'react-router';
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getSelectedList } from '../../../../../reduxStore/actions/dev_selHistoryTrend';
import { isEmptyArr } from '../../../../../function/ArrayFn/arrayFn';

function TpFeatureManu(props) {
    const devSelectedList = useSelector(getSelectedList);

    const [G_bMoveUp, setG_bMoveUp] = useState(false);
    const history = useHistory();

    const handleMoveUpDn=()=>{
        setG_bMoveUp(!G_bMoveUp);
    }
// eslint-disable-next-line
    const toCompareGraph = () => {
        let bdDevList = localStorageFn.getWatchList();
        if(!bdDevList) toast("No sensor selected")
        else history.push("/graph/compare");
    }
// eslint-disable-next-line
    const toDashboard = () => {
        history.push("/dashboard/main");
    }
    const toHistoryData = () => {
        history.push("/hisdata/sensor");
    }

    const F_CheckSomeDevSelected=()=>{
        if(isEmptyArr(devSelectedList)) return false;
        return true
    }
    
    

    return (
        <div>
            {G_bMoveUp && <div className='spring_manuContainer'
            style={{zIndex:"11"}}
            onClick={handleMoveUpDn}
            ></div>}

            <div className={`divAbsolute spring_FeatureManuTab hoverPointer ${G_bMoveUp?"spring_feaTabUp":"spring_feaTabDn"}`}
            style={{zIndex:"12"}}
            onClick={handleMoveUpDn}>
                <RiApps2Line style={{fontSize:"1.5rem", fontWeight:"400"}}/>
                {G_bMoveUp === false && F_CheckSomeDevSelected() === true && <div className='teDevice_devSelIndicator teDevice_devSelIndicatorLoc'></div>}
            </div>
            
            <div className={`divAbsolute ${G_bMoveUp?"spring_feaContentUp":"spring_feaContentDn"}`}
            style={{overflow:"hidden", zIndex:"13"}}>
                {<div className='spring_FeatureManuContainer'>
                    {/* <div className='spring_mainDirBar hoverPointer' onClick={toDashboard}>Dashboard</div> */}
                    <div className='divRelative spring_mainDirBar hoverPointer' onClick={toHistoryData}>
                        <div className='divRelative '>
                            <div>History</div> 
                            {G_bMoveUp === true && F_CheckSomeDevSelected() === true && <div className='teDevice_devSelIndicator teDevice_devSelIndicatorBarLoc'></div>}
                        </div>
                    </div>
                    {/* <div className='spring_mainDirBar hoverPointer' onClick={toCompareGraph}>Graph</div> */}
                </div>}
            </div>
        </div>
    );
}

export default TpFeatureManu;