import React, { useEffect, useState } from 'react';

function TpDiaForceTrig(props) {

    const [G_status, setG_status] = useState("");

    useEffect(()=>{
        async function startUp(){
            if(props.acStatus){
                if(props.acStatus===1){
                    setG_status("ON")
                }else if(props.acStatus===2){
                    setG_status("OFF")
                }else if(props.acStatus===3){
                    setG_status("On Scene")
                }
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.trigSwitch) {
            props.trigSwitch(G_status);
        }
    }
    

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>
                
                <div className=' spring_dialogBox '>
                    <div className='spring_ruleVarListContainer'>
                        <div className='spring_opeContainer' style={{paddingTop:"10px"}}>
                            <div className='spring_sync_Title'>{`Confirm to ${G_status}?`}</div>
                            <div className={`spring_sync_button hoverPointer ${G_status==="OFF"?"spring_acRedColor":""}`}
                            onClick={()=>handleConfirm()} style={{width:"140px"}}>
                                {`Yes, ${G_status==="OFF"?"OFF":"ON"} it.`}
                            </div>
                        </div>
                    </div>   

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer"
                        onClick={handleClose}
                        style={{width:"140px", height:"30px", marginBottom:"40px"}}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>


            </div>
        </div>
    );
}

export default TpDiaForceTrig;