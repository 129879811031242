import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { isEmptyArr, isEmptyObj, notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import { toast } from 'react-toastify';
// import { MdAdd } from "react-icons/md";
// import TpDiaSaveSceneToEeprom from './TpDialog/TpDiaSaveSceneToEeprom';
// import { HiOutlineChip } from "react-icons/hi";

// function TabScene(props) {
const TabScene=forwardRef((props, ref)=> {
    useImperativeHandle(ref, () => ({
        update() {
            // console.log("TabScene update");
            
        },
    }));
    const C_EepromSceneKey = "pi_12";
    const C_CurSceneKey = "pi_13";
    const C_CtrlMode = "pi_2";
    

    const [G_SceneDisplay, setG_SceneDisplay] = useState([]);
    const [G_VarDisplay, setG_VarDisplay] = useState([]);
    const [G_ScenePg, setG_ScenePg] = useState(0);
    const [G_ValueDisplay, seG_ValueDisplay] = useState([]);
    // const [G_DiaPg, setG_DiaPg] = useState(0);
    // const [G_ErpromSceneIdx, setG_ErpromSceneIdx] = useState(0);
    const [G_CurSceneIdx, setG_CurSceneIdx] = useState(0);
    const [G_CtrlMode, setG_CtrlMode] = useState(0);
    // const [G_SelScene, setG_SelScene] = useState({});
    // const [G_FirmwareVersion, setG_FirmwareVersion] = useState(1.13);

    useEffect(()=>{
        async function startUp(){
            // if(props.sceneList) setG_SceneDisplay(props.sceneList);
            loadSceneList();
            loadDisplayVar();
            if(props.varValue) seG_ValueDisplay(props.varValue);
            if(props.pageSel) setG_ScenePg(props.pageSel);
            await fLoadLastData();
            
            if(props.backToDefault)     props.backToDefault();     
        }
        startUp();

        return ()=>{
            // alert("Page Close");
        }
        // eslint-disable-next-line
    }, [props.lastData]);    

    const loadSceneList= ()=>{
        let sceneList = [];
        let emptyScene = {
            Name: "",
            Rj_bdDevId: 0,
            inUse: 0,
            sceneIdx: 0,
            sortIdx: 0,
        }
        for (let i = 0; i < 10; i++) {
            let tempEmpty = {...emptyScene} ;
            tempEmpty.sceneIdx = i+1;
            if (Object.hasOwnProperty.call(props, "sceneList")) {
                let found = props.sceneList.find(c=>c.sceneIdx-1===i);
                if(found)   sceneList.push(found);
                else    sceneList.push(tempEmpty);
            }else{
                sceneList.push(tempEmpty);
            }
        }
        setG_SceneDisplay(sceneList);
    }
    
    const fLoadLastData=async ()=>{
        if(props.lastData) {
            if(isEmptyObj(props.lastData)) return;
            let keyExist = (Object.hasOwnProperty.call(props.lastData, C_EepromSceneKey));            
            if(!keyExist) return toast("Eeprom key not exist");
            keyExist = (Object.hasOwnProperty.call(props.lastData, C_CurSceneKey));            
            if(!keyExist) return toast("Scene key not exist");     
            keyExist = (Object.hasOwnProperty.call(props.lastData, C_CtrlMode));            
            if(!keyExist) return toast("Ctrl Mode key not exist");   

            
            // setG_ErpromSceneIdx(props.lastData[C_EepromSceneKey]);
            setG_CurSceneIdx(props.lastData[C_CurSceneKey]);
            setG_CtrlMode(props.lastData[C_CtrlMode]);
            
        }
    }

    const loadDisplayVar=()=>{
        if(props.varList){
            if(notEmptyArr(props.varList)) {
                let validVar = props.varList.filter(c=>c.varIdx);
                setG_VarDisplay(props.varList);
                if(isEmptyArr(validVar) && props.pageSel===0) return toast(`Click "Variable" tab to add variable`); 
                if(isEmptyArr(validVar)) toast(`Add variable before setup Scene`);
            }
        }
    }
    const handleChooseScene=(sceneIdx)=>{
        if(props.onClickChooseScene) props.onClickChooseScene(sceneIdx);
    }
    // const handelAddNewScene=async ()=>{
    //     if(props.onClickAddNewScene) await props.onClickAddNewScene();
    // }
    const handleChangeTab=async (pgNo)=>{
        setG_ScenePg(pgNo);
    }
    const handleEditLinkVar=async (varInfo, varIdx)=>{
        if(props.editVar) props.editVar(varInfo, varIdx);
    }
    const handleTrigScene=async (scene)=>{
        if(scene.Rj_bdDevId===0) return
        /** show trig scene idx */
        // let trigSceneIdx = scene.sceneIdx;
        /** show eeprom scene idx */
        // if(trigSceneIdx!==G_ErpromSceneIdx){  /** ask user whether to save in eeprom */
        //     setG_SelScene(scene);
        //     setG_DiaPg(1);
        //     return 
        // }else{      /** already in eeprom, skip dialog */
            if(props.trigScene) {
                props.trigScene(scene, false);
            }
        // }
    }
    // const fClosePg=async ()=>{
    //     setG_DiaPg(0);
    // }
    
    // const fTrigScene_DiaConfirm=async(bEeprom)=>{
    //     fClosePg();
    //     if(props.trigScene) {
    //         props.trigScene(G_SelScene, bEeprom);
    //     }
    // }
    
    return (
        <div className='spring_sceneTabPage'>

            {/* {G_DiaPg ===1 && <TpDiaSaveSceneToEeprom
                onclickClose={fClosePg}
                confirmChange={fTrigScene_DiaConfirm}
                />} */}

            <div className='sortHor spreadEvenly'>
                <div className={`spring_scenePgSelItem hoverPointer ${G_ScenePg===0?"spring_shadowBox_Small":""}`}
                onClick={()=>handleChangeTab(0)}>Scene</div>
                <div className={`spring_scenePgSelItem hoverPointer ${G_ScenePg===1?"spring_shadowBox_Small":""}`}
                onClick={()=>handleChangeTab(1)}>Variable</div>
            </div>
            {G_ScenePg === 0 && <div className='spring_sceneBar'>
                {G_SceneDisplay.map((c, ind)=>
                    <div key ={`scenKey_${ind}`}>
                        <div className='sortHor spring_SceneList'>
                            <div className='spring_SceneIdx'>{`${ind+1}.`}</div>
                            <div className={`hoverPointer ${(c.sceneIdx===G_CurSceneIdx && G_CtrlMode === 20) ?"spring_SceneButtonSel":"spring_SceneButton"}`}
                            onClick={()=>handleTrigScene(c)}>
                                <div className={`wrapTextEllipsis ${c.Name.trim()===""?"spring_EmptySceneText":"spring_ValidSceneText hoverPointer"}`}>{`${c.Name.trim()===""?"<Empty Slot>":`${c.Name}`}`}</div>
                            </div>
                            <TbAdjustmentsHorizontal className='blueText hoverPointer' 
                                onClick={()=>handleChooseScene(c.sceneIdx)}/>
                        </div>
                        {/* {G_FirmwareVersion < 1.13 && <div key ={`scenKey_${ind}`} className='spring_SceneBox'>
                            <div>
                                <div className='sortHor spreadBetween'>
                                    <div className='spring_sceIdxText'>{`[${c.sceneIdx}]`}</div>
                                    <div className='spring_sceneSelIcon hoverPointer'
                                        onClick={()=>handleChooseScene(c.sceneIdx)}><TbAdjustmentsHorizontal/></div>
                                </div>
                                <div className={`${(c.sceneIdx===G_CurSceneIdx && G_CtrlMode === 20) ?"spring_sceneTextBox_Sel":"spring_sceneTextBox"} hoverPointer divRelative`}
                                onClick={()=>handleTrigScene(c)}>
                                    <div className='spring_sceneSelName '>
                                        <div>{c.Name}</div>
                                    </div>
                                    {G_ErpromSceneIdx===c.sceneIdx && <HiOutlineChip className='spring_RjSceneChipStatus'/>}
                                </div>
                            </div>
                        </div>} */}
                        


                    </div>
                )} 

                {/* <div className='spring_SceneBox'>
                    <div>
                        <div className='spring_sceneSelIcon hoverPointer'
                        onClick={handelAddNewScene}>
                            < TbAdjustmentsHorizontal className='divHidden'/>
                        </div>
                        {G_SceneDisplay.length <10 && <div className='spring_sceneTextBox hoverPointer'
                        onClick={handelAddNewScene}>
                            <div className=' flexAndBothCenter text_1_2 greenCheck'><MdAdd/></div>
                        </div>}
                        {G_SceneDisplay.length >=10 && <div className='spring_sceneTextBox_non'
                        >
                            <div className=' spring_sceneSelName'>
                                {"Max"}
                            </div>
                        </div>}
                    </div>
                </div> */}

            </div>}

            {G_ScenePg === 1 && <div className={`spring_sceneVarBar `}>
                {G_VarDisplay.length > 0 &&  G_VarDisplay.map((c, ind)=><div key={`varKey_${ind}`}  className=" sortHor " >
                    {c.Var_bdDevId >0 ?<div className={`spring_sceneVarBox sortHor ${G_ValueDisplay[ind].valid || !c._id ? "":"spring_sceneVarBar_invalid"}`}>
                        <div className='boldText spring_linkVarName wrapTextEllipsis'>{c.varName}</div> 
                        <div className={`spring_sceneVarValueBar sortHor`}>
                            {/* <div>{G_ValueDisplay[ind].valid}</div> */}
                            {c._id > 0 && <div className='spring_sceneVarValue'>
                                {`${G_ValueDisplay[ind].valid?parseFloat(G_ValueDisplay[ind].value).toFixed(1):"--"}`}
                            </div> }
                            {c._id > 0 && <div className='spring_sceneVarUnit'>{c.unit}</div> }
                        </div> 
                    </div>:                    
                    <div className='spring_sceneVarBox'>
                        <div className='spring_linkVarName wrapTextEllipsis'>{`<Empty Slot>`}</div>
                    </div> }
                    <div className='spring_varEditBox hoverPointer'
                    onClick={()=>handleEditLinkVar(c, ind)}><TbAdjustmentsHorizontal/></div>
                </div>)} 
                {G_VarDisplay.length <= 0 && <div className='spring_RjEmptyVarList sortVer'>
                        <div>Empty List</div>
                        <div>1. Ensure Roger Jr. Connected To Internet</div>
                        <div>2. Refresh Page</div>
                    </div>}
            </div>}
        </div>
    );
// }
});

export default TabScene;