import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { GrRevert } from "react-icons/gr";
import { toast } from "react-toastify";

const TabSettingIrFrequency = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setIrHz() {
      fMainTrigSetHzIr();
    },
    revertIrHz() {
      fLoadIrHz();
    },
  }));

  useEffect(() => {
    async function startUp() {
      fLoadIrHz();

      // console.log("props.pos", props.pos);
    }
    startUp();

    return () => {
      // alert("Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const fLoadIrHz = () => {
    if (props.lastData) {
      if (Object.hasOwnProperty.call(props.lastData, "pn_2")) {
        let _FreqHz = props.lastData.pn_2;
        document.getElementById(`RjIrHz_${props.pos}`).value = _FreqHz;
      }
    }
  };

  const fMainTrigSetHzIr = () => {
    if (props.confirmChange) {
      // get the
      let hzToSet = parseFloat(
        document.getElementById(`RjIrHz_${props.pos}`).value
      );
      if (isNaN(hzToSet)) return toast(`Invalid Frequency`);
      if (hzToSet < 5000) return toast(`Frequency cannot less then 5000`);
      props.confirmChange(hzToSet);
    }
  };

  const fSettingChanges = () => {
    if (props.settingChanges) props.settingChanges();
  };
  const fBackToPrevPage = () => {
    if (props.fToTab) props.fToTab(31);
    if (props.discardChanges) props.discardChanges();
  };

  return (
    <div className="spring_editScenePg">
      <div className="sortHor" style={{ marginBottom: "5px" }}>
        <div className="spring_ManuTitle">Infrered Hz</div>
        <div
          className="spring_ManuTitleBackIcon stayRight hoverPointer"
          onClick={fBackToPrevPage}
        >
          <GrRevert />
        </div>
      </div>

      <div className="spring_editScheGroup">
        <div className="spring_editItemBar sortHor">
          <div className="spring_editLeftTitle">Frequency (Hz)</div>

          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight"
            id={`RjIrHz_${props.pos}`}
            placeholder="--"
            onChange={fSettingChanges}
          ></input>
        </div>
      </div>
    </div>
  );
});

export default TabSettingIrFrequency;
