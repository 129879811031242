import React from 'react';
 
import { BiLoader } from "react-icons/bi";

function TpSbDevNoData(props) {
    return (
        <div className='flexAndBothCenter sortVer'>
            <div style={{fontSize:"5rem", marginTop:"50px"}}><BiLoader/></div> 
            <div style={{marginTop:"30px"}}>No available data</div>
        </div>
    );
}

export default TpSbDevNoData;