const { toast } = require("react-toastify");

const addStr=(str, index, stringToAdd)=>{
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
}

const isEmptyString = (In_sString) => {
    if (In_sString === null || In_sString === "") return true;
    let sString = In_sString.toString();
    // console.log("sString", sString);
    let _String= sString.trim();
    // console.log("_String", _String);
    if(_String==="") return true;
    return false;
}


const getInputText=(inputId)=>{
    let value = document.getElementById(inputId).value.trim();
    if(isEmptyString(value)) return {emptyString:true, value:""};
    return {validString:true, value};
}

const setInputText=(inputId, value)=>{
    document.getElementById(inputId).value = value;
}

const dowToArrBool=(dow)=>{
    let _dow = dow;
    // let _dow=126;
    let arr_bool=[];
    // console.log(arr_bool);
    for (let i = 0; i < 7; i++) {
        arr_bool.push((_dow>>i)%2===1);
    }
    return arr_bool;
}

const getBitByIdx=(nNumber, nBit)=>{
    return ((nNumber>>nBit)%2===1);
}

const setBitByIdx=(nNumber, nBit)=>{
    return (nNumber | (1<<nBit));
}
const clearBitByIdx=(nNumber, nBit)=>{
    return (nNumber & ~(1<<nBit));
}

const saveInputToRedux=(dispatch, inputId, fnRedux)=>{
    let inputObj = getInputText(inputId);
    // if(inputObj.emptyString) return
    dispatch(fnRedux(inputObj.value));
}

const fnValidateInputPass=(obj_id, bToastErr, sToastMsg)=>{
    let objInput = getInputText(obj_id);    
    // console.log("objInput", objInput);
    if(objInput.emptyString){
        if(bToastErr){
            toast(sToastMsg)
        }
        return false;
    }
    return true;    
}

const fnFillUpChar=(sInput, reqLen, cToFill, bFillFront)=>{
    let _sInput = sInput;
    while (_sInput.length < reqLen) {
        if(bFillFront){
            _sInput=cToFill+_sInput;
        }else{
            _sInput=_sInput+cToFill;
        }
    }
    return _sInput;
}




exports.fnValidateInputPass=fnValidateInputPass;
exports.getInputText=getInputText;
exports.setInputText=setInputText;
exports.isEmptyString=isEmptyString;
exports.addStr=addStr;
exports.dowToArrBool=dowToArrBool;
exports.getBitByIdx=getBitByIdx;
exports.setBitByIdx=setBitByIdx;
exports.clearBitByIdx=clearBitByIdx;
exports.saveInputToRedux=saveInputToRedux;
exports.fnFillUpChar=fnFillUpChar;