import http from "../httpService";


async function getV2_GwPair(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getgwpair";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_GwPair(bdDev_id, gwid) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setgwpair";
    let body = {bdDev_id, gwid};
    return await http.post(url, body);  
}

async function getV2_LNodeDots(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getlnodedots";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_LNodeDots(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setlnodedots";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_LNodeParaPg(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getlnodeparapg";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_LNodeParaPg(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setlnodeparapg";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_CdwpModbusDev(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getcdwpmodbusdev";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_CdwpModbusDev(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setcdwpmodbusdev";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_CdwpVfdFreq(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getcdwpvfdfreq";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_CdwpVfdFreq(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setcdwpvfdfreq";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_CdwpHzThreshold(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getcdwphzthreshold";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_CdwpHzThreshold(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setcdwphzthreshold";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_CwpSetting(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getcwpsetting";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_CwpSetting(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setcwpsetting";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_MrEeprom_byFn(bdDev_id, fn) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getmreeprombyfn";
    let body = {bdDev_id, fn};
    return await http.post(url, body);  
}

async function setV2_MrEeprom(bdDev_id, fn, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setmreeprom";
    let body = {bdDev_id, fn, payload};
    return await http.post(url, body);  
}

const expModule = {  
    getV2_GwPair,
    setV2_GwPair,
    getV2_LNodeDots,
    setV2_LNodeDots,
    getV2_LNodeParaPg,
    setV2_LNodeParaPg,
    getV2_CdwpModbusDev,
    setV2_CdwpModbusDev,
    getV2_CdwpVfdFreq,
    setV2_CdwpVfdFreq,
    getV2_CdwpHzThreshold,
    setV2_CdwpHzThreshold,
    getV2_CwpSetting,
    setV2_CwpSetting,
    getV2_MrEeprom_byFn,
    setV2_MrEeprom
};
export default expModule;