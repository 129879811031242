import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosAdd} from "react-icons/io";
import cssFn from '../../../function/CSS/cssFunction';
import { useDispatch } from 'react-redux';
import {chooseBuilding} from '../../../reduxStore/actions/dev_regSensor';
import auth from '../../../service/auth/AuthV2';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import { toast } from 'react-toastify';

function ChooseBuildingPg(props) {
    const dispatch = useDispatch();

    // eslint-disable-next-line
    // const [user_id, setUser_id] = useState(1);
    // const [relatedBd, setRelatedBd] = useState([]);
    const [loadList, setLoadList] = useState([]);
    const [bdDisplayList, setBdDisplayList] = useState([]);
    const [selectedBd, setSelectedBd] = useState({});

    useEffect(()=>{
        async function startUp(){
            /** need to load user Id before this */
            let {user_id} = auth.getUserInfo();
            await getRelatedBd(user_id);
        }
        startUp();

        return ()=>{
            // alert("Choose Device Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const getRelatedBd=async (user_id)=>{
        // let relatedBd = await sensorOwnerFn.getRelatedBd(user_id);
        let coOwnedBd = await sensorOwnerFn.v2aGetCoOwnedBd_id(user_id);
        if(coOwnedBd.errMsg) return toast.dark(coOwnedBd.errMsg);
        setLoadList(coOwnedBd);
        setBdDisplayList(coOwnedBd);
    }

    const backPrevPg=()=>{
        props.history.push({
            pathname: "/device/add",
            focusStep:2,
        });
    }
    
    const handleSearch=()=>{
        let searchInput = document.getElementById('searchInput').value.trim();
        if(searchInput==="") {
            setBdDisplayList(loadList);
            return
        }
        // console.log(searchInput);
        let filtered = loadList.filter((c)=>c.name.toUpperCase().search(searchInput.toUpperCase()) >= 0);
        // console.log(filtered);
        setBdDisplayList(filtered);
    }

    // const handleBlurSearch=()=>{
    //     // console.log("On Blur");
    //     let searchInput = document.getElementById('searchInput').value.trim();
    //     if(searchInput==="") {
    //         setBdDisplayList(loadList);
    //     }
    // }

    const addNewBd=()=>{
        // console.log("addNewBd");
        
        props.history.push({
            pathname: "/device/add/addnewbd",
            // _data: {_devName: devName},
        });
    }

    const handleConfirm=()=>{        
        // console.log(selectedBd);
        if(!selectedBd._id) return
        dispatch(chooseBuilding({buildingId:selectedBd._id, buildingName:selectedBd.name}));
        
        return props.history.push({
            pathname: "/device/add",
            focusStep:2,
        });
    }

    const selBd=(ind, bd)=>{
        // console.log(`Sel:` , bd);
        // console.log(`Ind:` , ind);
        for (let i = 0; i < bdDisplayList.length; i++) {
            cssFn.cssShowStatus(i===ind, `bdid_${i}`, 'tableItemSelected');            
        }
        setSelectedBd(bd);
    }
    
    
    return (
        <div>
            
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Select Building</div>
            </div>
            <div className='spaceBothSide testClass' style={{marginTop:"20px"}}>
                
                <div className="spring_fullButton spring_blueColor hoverPointer" onClick={addNewBd}>
                    <div className='buttonWithIcon '>
                        <IoIosAdd className="backIcon" style={{fontSize:'22px'}}/> New
                    </div>
                </div>

                <div className ='searchBarContainer_non' style={{marginTop:"15px"}}>
                    <input onChange={handleSearch} id='searchInput' className='searchInput'/>       
                    <div onClick={handleSearch} className='searchButton'>
                        <i className='fa fa-search'></i>
                    </div>    
                </div>


                <div className='gapOntopTable tableContainerFullWidth' 
                    style={{height: "60vh",
                    color:'var(--titleColor)'
                    }}>
                    {bdDisplayList.map((c, ind)=>
                        <div 
                            className='tableItem' 
                            key={`bdKey_${ind}`}
                            id={`bdid_${ind}`}
                            onClick={()=>selBd(ind, c)}
                        > {c.name}</div>
                    )} 
                </div>
                
                <div className="buttonContainer buttonSpreadEvenly" 
                    style={{
                        marginTop:"25px",
                        marginBottom:"20px",
                        }}>
                    <div className="spring_roundButton spring_greyColor hoverPointer" onClick={backPrevPg}>
                        Back
                    </div>
                    <div className="roundButton" onClick={handleConfirm}>Confirm</div>
                </div>
            </div>
        </div>
    );
}

export default ChooseBuildingPg;