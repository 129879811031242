import React, { useEffect, useState } from 'react';
import auth from '../../../../service/auth/AuthV2';
import V2DataDownloadFn from '../../../../function/V2_DataDownload/V2_DataDownloadFn';
import { sortTemplate } from '../../../../function/ArrayFn/arrayFn';
import timeFn from "../../../../function/time/timeFn";
import { toast } from 'react-toastify';

function TpHistoryRequestList(props) {
    const [G_bLoaded, setG_bLoaded] = useState(false); 
    const [G_historyList, setG_historyList] = useState([]);

    const [G_downloadStatus, setG_downloadStatus] = useState(
        Array(G_historyList.length).fill('Download')
    );
    // eslint-disable-next-line
    // const [G_ticketHistoryList, setG_ticketHistoryList] = useState([]);
    // const [G_displayList, setG_displayList] = useState([]);
    // const [G_ShowHistory, setG_ShowHistory] = useState(0);
    // const [G_bdInfo, setG_bdInfo] = useState();
    // const [G_isCooldown, setG_isCooldown] = useState(false);

    useEffect(() => {
        async function startUp() {
            let { bdInfo }  = props;
            let userInfo = auth.getUserInfo();
            
            let ticketHistoryList = await V2DataDownloadFn.getDownloadedTicket_lastN(userInfo.user_id, bdInfo._id, 20);
            if(ticketHistoryList.length) {
                setG_historyList(sortArr(ticketHistoryList));
            }

            let updatedStatus = Array(ticketHistoryList.length).fill('Download');
            // console.log(updatedStatus);
            setG_downloadStatus(updatedStatus);

            setG_bLoaded(true);
        }
        
        startUp();
        
        return ()=>{
            
        }
    }, []);

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "unix", descOrder: true },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const handleDownload = async (ticketInfo, idx) => {
        try {
            const updatedStatus = [...G_downloadStatus];
            updatedStatus[idx] = 'Downloading...';
            setG_downloadStatus(updatedStatus);
            // console.log(ticketInfo);
            // let bdInfo  = localStorageFn.getBdToken();
            // eslint-disable-next-line
            let zipFileName = ticketInfo.description? ticketInfo.description.replace(/[\\/:\*\?"<>|]/g, '_') : timeFn.convertUnixToFormattedDateTime_UTC8(ticketInfo.unix);
            
            let response = await V2DataDownloadFn.dataDownloadCsvZip(ticketInfo);
            // console.log(response);
            // Create a Blob object from the ZIP data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Create a link element and trigger a click event to download the file
            const link = document.createElement('a');
            const downloadUrl = window.URL.createObjectURL(blob);
            link.href = downloadUrl;
            link.download = zipFileName;

            // Append to the DOM to ensure the click event works in all browsers
            document.body.appendChild(link); 
            link.click();

            // Clean up
            document.body.removeChild(link); // Remove the link element
            window.URL.revokeObjectURL(downloadUrl); // Release the memory

            // Update the status back to 'Download' after the download is complete
            const newStatus = [...updatedStatus];
            newStatus[idx] = 'Download';
            setG_downloadStatus(newStatus);
        } catch (error) {
            toast.error("Download Error");
            console.log('Error downloading multiple CSV files:', error);
            const newStatus = [...G_downloadStatus];
            newStatus[idx] = 'Download';
            setG_downloadStatus(newStatus);
        }
    }

    // const clearCache = () => {
    //     if (props.clearCache) props.clearCache();
    // }

    return ( <div>
        {!G_bLoaded && <p className='flexAndBothCenter'>Loading...</p>}
        {G_bLoaded && <div>
            {G_historyList.length > 0 && <div className='spring_inputBox spring_listContainer spring_ddList_scroll'>
                {G_historyList.map((u, ind) => (
                    <div key={ `ticket_${ind}`}>
                        {ind!==0 && <div className='divSeperateLine'></div>}
                        <div className='sortHor spring_MarginTopBtm_4px'>
                            <div className='sortVer spring_marginLeft5 spring_marginRight5 spring_width70per'>
                                <div className='spring_miniText'>{timeFn.getDateTimeString_WithSeconds(u.unix)}</div>
                                <div className='spring_sceneVarValue spring_hideOverflowText'>
                                    {u.description? u.description : timeFn.convertUnixToFormattedDateTime_UTC8(u.unix)}
                                </div>
                            </div>
                            <div className='stayRight flexAndBothCenter'>
                                <div className={G_downloadStatus[ind] === 'Downloading...' ? 'disableClick' : 'hoverPointer spring_downloadText'} onClick={() => handleDownload(u, ind)}>
                                    {G_downloadStatus[ind]}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>}

            {/* {G_historyList.length > 0 && <div className='spring_MarginLeftRight_20Per spring_centerText spring_smallText spring_italicStyle'>
                Info: If there is a problem with your files, you may clear your history<button className='spring_linkButton' onClick={clearCache}>here</button>
            </div>} */}
        </div>}
    </div> );
}

export default TpHistoryRequestList;