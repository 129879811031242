import senOwnSer from "./../../service/V2_SensorDevice/v2_SensorOwner";

async function getRelatedArea(...rest) {
  try {
    let rel = await senOwnSer.getRelatedArea(...rest);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function getRelatedBd(...rest) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getRelatedBd(...rest);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return { errMsg: "DB Err", data: rel.data };
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function getRelatedBdOwnLevel(user_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getRelatedBdOwnLevel(user_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return { errMsg: "DB Err", data: rel.data };
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Connection Error" };
  }
}

async function registerSensor(...rest) {
  try {
    let rel = await senOwnSer.registerSensor(...rest);
    if (rel.errMsg) return { errMsg: rel.errMsg };
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "DB Err. (Exp)" };
  }
}

async function getBy_ty_DevId(...rest) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getBy_ty_DevId(...rest);
    // console.log(rel);
    if (rel.statusText === "OK") {
      if (rel.data.length > 0) return { msg: "Device Owned", data: rel.data };
      return { msg: "OK", data: rel.data };
    }
    return { msg: "DB Err", data: rel.data };
  } catch (error) {
    console.log(error.message);
    return { msg: "DB Err. (Exp)" };
  }
}

async function bdUserAccessRight(user_id, bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.bdUserAccessRight(user_id, bd_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      // return {msg: "OK", data:rel.data};
      // console.log(rel.data);
      if (!rel.data) return false;
      if (rel.data.bdAccess) return true;
      return false;
    }
    return rel.data; // here will return error message
  } catch (error) {
    console.log(error.message);
    return { msg: "Server Err. (Exp)" };
  }
}

async function getBdDev_By_UserId_BdId(user_id, bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getBdDev_By_UserId_BdId(user_id, bd_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      /** rel.data will return owner device and also shared device
       * Need to filter multiple shared Device here */

      /** Filter duplicated data */
      let mixedData = rel.data;
      let uniqueDevList = Array.from(new Set(mixedData.map((a) => a._id))).map(
        (_id) => {
          return mixedData.find((a) => a._id === _id);
        }
      );

      return uniqueDevList;
    }
    return rel.data; // here will return error message
  } catch (error) {
    console.log(error.message);
    return { msg: "Server Err. (Exp)" };
  }
}

async function getBdDev_By_bdDevIdList(bdDev_idList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getBdDev_By_bdDevIdList(bdDev_idList);
    // console.log(rel);
    // if(rel.statusText==='OK'){

    //     return rel.data;
    // }
    return rel.data; // here will return error message
  } catch (error) {
    console.log(error.message);
    return { msg: "Server Err. (Exp)" };
  }
}

function groupDevByArea(areaList, devList, bd_id) {
  let bdDisplayList = [];
  let remainingDev = [...devList];
  for (const area of areaList) {
    /** c.buidling_id === bd_id  ==>  will remove sensor transfer to other building */
    let a_dev = remainingDev.filter(
      (c) => c.area_id === area._id && c.buidling_id === bd_id
    );
    remainingDev = remainingDev.filter(
      (c) => c.area_id !== area._id && c.buidling_id === bd_id
    );
    // console.log(a_dev);
    bdDisplayList.push({ areaName: area.name, devices: a_dev });
  }
  // console.log(remainingDev);
  if (remainingDev.length > 0)
    bdDisplayList.push({ areaName: "Other", devices: remainingDev });
  // console.log(bdDisplayList);
  // setDevsInArea(bdDisplayList);
  return bdDisplayList;
}

async function getCount_BdDev(bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getCount_BdDev(bd_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return { errMsg: "DB Err", data: rel.data };
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function getUniqueUserListByBdList(bdList) {
  try {
    let rel = await senOwnSer.getUniqueUserListByBdList(bdList);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function getBdListByUId(user_id) {
  try {
    let rel = await senOwnSer.getBdListByUId(user_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function addShareUser(bd_id, accessLevel, emailList) {
  try {
    let rel = await senOwnSer.addShareUser(bd_id, accessLevel, emailList);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function getOwnBdByUserId_bd_id(user_id, bd_id) {
  try {
    let rel = await senOwnSer.getOwnBdByUserId_bd_id(user_id, bd_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}


/**-------verison 2a------------ */
async function getRelatedFloor(bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.getRelatedFloor(bd_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetBdDevInBd(bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aGetDevInBd(bd_id);
    // console.log(rel);
    if (rel.statusText === "OK") {
      // if(rel.data.length > 0) return {msg: "Device Owned", data:rel.data};
      return rel.data;
    }
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetRelatedArea(bd_id, floor_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aGetRelatedArea(bd_id, floor_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aRegisterSensor(sensorInfo) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aRegisterSensor(sensorInfo);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aUpdateSortIdx_Bd(sortIdx, bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aUpdateSortIdx_Bd(sortIdx, bd_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}


async function v2aRenameBd(name, bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aRenameBd(name, bd_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}


async function v2aUpdateSortIdx_floorList(floorList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aUpdateSortIdx_floorList(floorList);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}



async function v2aRenameFloor(name, floor_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aRenameFloor(name, floor_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aUpdateSortIdx_AreaList(areaList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aUpdateSortIdx_AreaList(areaList);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}


async function v2aRenameArea(name, area_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aRenameArea(name, area_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aDeleteArea(area_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aDeleteArea(area_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aDeleteFloor(floor_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aDeleteFloor(floor_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aclearFloorArea_id(devList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aclearFloorArea_id(devList);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aclearArea_id(devList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aclearArea_id(devList);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aAddFloor(name, owner_id, buidling_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aAddFloor(name, owner_id, buidling_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aAddArea(name, owner_id, buidling_id, floor_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aAddArea(name, owner_id, buidling_id, floor_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetAllAreaUnderBd(bd_id) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aGetAllAreaUnderBd(bd_id);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}


async function v2aUpdateFloorArea_list(devList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aUpdateFloorArea_list(devList);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aUpdateSortIdx_DevList(devList) {
  // console.log("Cone In");
  try {
    let rel = await senOwnSer.v2aUpdateSortIdx_DevList(devList);
    return rel.data;
    // return {errMsg: "DB Err", data:rel.data};
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aRename_Dev(newName, _id) {
  try {
    let rel = await senOwnSer.v2aRename_Dev(newName, _id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aDelete_Dev(_id) {
  try {
    let rel = await senOwnSer.v2aDelete_Dev(_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aSwap_Dev(owner_id, _id, SerialNo, RegCode) {
  try {
    let rel = await senOwnSer.v2aSwap_Dev(owner_id, _id, SerialNo, RegCode);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aDeteach_Dev(_id) {
  try {
    let rel = await senOwnSer.v2aDeteach_Dev(_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetShareBd_ByUserId_BdId(bd_id, user_id) {
  try {
    let rel = await senOwnSer.v2aGetShareBd_ByUserId_BdId(bd_id, user_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetShareBdDev_ByUserId_BdId(bd_id, user_id) {
  try {
    let rel = await senOwnSer.v2aGetShareBdDev_ByUserId_BdId(bd_id, user_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetCoOwnedBd_id(user_id) {
  try {
    let rel = await senOwnSer.v2aGetCoOwnedBd_id(user_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetBdInfoBy_id(bd_id) {
  try {
    let rel = await senOwnSer.v2aGetBdInfoBy_id(bd_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetAllFloorInBd(bd_id) {
  try {
    let rel = await senOwnSer.v2aGetAllFloorInBd(bd_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetAllAreaInBd(bd_id) {
  try {
    let rel = await senOwnSer.v2aGetAllAreaInBd(bd_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aShareDevice(bd_id, userList, shareLevel, devList) {
  try {
    let rel = await senOwnSer.v2aShareDevice(bd_id, userList, shareLevel, devList);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aGetShareUserOfBd(bd_id) {
  try {
    let rel = await senOwnSer.v2aGetShareUserOfBd(bd_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}

async function v2aDeactivateShareUser(bd_id, shareUser_id) {
  try {
    let rel = await senOwnSer.v2aDeactivateShareUser(bd_id, shareUser_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}
async function getFavBdList(user_id) {
  try {
    let rel = await senOwnSer.getFavBdList(user_id);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}
async function trigFavBd(user_id, bd_id, bFavStatus) {
  try {
    let rel = await senOwnSer.trigFavBd(user_id, bd_id, bFavStatus);
    return rel.data;
  } catch (error) {
    console.log(error.message);
    return { errMsg: "Server Error" };
  }
}



const expModule = {
  getRelatedArea,
  getRelatedBd,
  getRelatedBdOwnLevel,
  getBy_ty_DevId,
  registerSensor,
  bdUserAccessRight,
  getBdDev_By_UserId_BdId,
  getBdDev_By_bdDevIdList,
  groupDevByArea,
  getCount_BdDev,
  getUniqueUserListByBdList,
  getBdListByUId,
  addShareUser,
  getOwnBdByUserId_bd_id,
  getRelatedFloor,
  v2aGetBdDevInBd,
  v2aGetRelatedArea,
  v2aRegisterSensor,
  v2aUpdateSortIdx_Bd,
  v2aRenameBd,
  v2aUpdateSortIdx_floorList,
  v2aRenameFloor,
  v2aUpdateSortIdx_AreaList,
  v2aRenameArea,
  v2aDeleteArea,
  v2aDeleteFloor,
  v2aclearFloorArea_id,
  v2aclearArea_id,
  v2aAddFloor,
  v2aAddArea,
  v2aGetAllAreaUnderBd,
  v2aUpdateFloorArea_list,
  v2aUpdateSortIdx_DevList,
  v2aRename_Dev,
  v2aDelete_Dev,
  v2aSwap_Dev,
  v2aDeteach_Dev,
  v2aGetShareBd_ByUserId_BdId,
  v2aGetShareBdDev_ByUserId_BdId,
  v2aGetCoOwnedBd_id,
  v2aGetBdInfoBy_id,
  v2aGetAllFloorInBd,
  v2aGetAllAreaInBd,
  v2aShareDevice,
  v2aGetShareUserOfBd,
  v2aDeactivateShareUser,
  getFavBdList,
  trigFavBd,
};
export default expModule;
