import React, { useEffect, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import TpSubDiaChooseFloor from './../Subdialog/TpSubDiaChooseFloor';
import TpSubDiaChooseArea from './../Subdialog/TpSubDiaChooseArea';

function TpDiaChooseDevice(props) {

    const [G_dia_bdDevList_Db, setG_dia_bdDevList_Db] = useState([]); 
    const [G_dia_bdDevList_Display, setG_dia_bdDevList_Display] = useState([]); 
    const [G_dia_FloorList_Db, setG_dia_FloorList_Db] = useState([]); 
    const [G_dia_AreaList_Db, setG_dia_AreaList_Db] = useState([]); 

    const [G_SelectedFloor, setG_SelectedFloor] = useState({floor_id:-1, floorName:"All"}); 
    const [G_SelectedArea, setG_SelectedArea] = useState({area_id:-1, areaName:"All"}); 

    const [G_ShowTp, setG_ShowTp] = useState(0); 

    
    useEffect(()=>{
        async function startUp(){
            if(props.bdDevList){
                let _dia_bdDevList_Db=[];
                for (const eachDev of props.bdDevList) {
                    _dia_bdDevList_Db.push({...eachDev});
                }
                let _dia_FloorList_Db = [];
                for (const eachFloor of props.floorList) {
                    _dia_FloorList_Db.push({...eachFloor});
                }
                setG_dia_FloorList_Db(_dia_FloorList_Db);

                let _dia_AreaList_Db =[];
                for (const eachArea of props.areaList) {
                    _dia_AreaList_Db.push({...eachArea});
                }
                setG_dia_AreaList_Db(_dia_AreaList_Db);

                let filteredDevList = filterDev(_dia_bdDevList_Db, _dia_FloorList_Db, _dia_AreaList_Db, G_SelectedFloor.floor_id, G_SelectedArea.area_id);
                
                setG_dia_bdDevList_Display(filteredDevList);
                setG_dia_bdDevList_Db(filteredDevList);
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.onclickConfirm) {
            // let newName = document.getElementById('rename_input').value.trim();
            // if(!newName || newName==="") return toast('Invalid name')
            props.onclickConfirm(G_dia_bdDevList_Db);
        }
    }
    const handleCheckDev=(devInfo)=>{
        let _dia_bdDevList_Display = [];
        for (const eachDev of G_dia_bdDevList_Display) {
            _dia_bdDevList_Display.push({...eachDev});
        }
        // let _dia_bdDevList_Display = [...G_dia_bdDevList_Display];
        let idx = _dia_bdDevList_Display.findIndex(c=>c._id === devInfo._id);
        if(idx <0) return;
        _dia_bdDevList_Display[idx].bChecked=_dia_bdDevList_Display[idx].bChecked?false:true;
        setG_dia_bdDevList_Display(_dia_bdDevList_Display);

        let _dia_bdDevList_Db =[];
        for (const eachDev of G_dia_bdDevList_Db) {
            _dia_bdDevList_Db.push({...eachDev});
        }
        // let _dia_bdDevList_Db = [...G_dia_bdDevList_Db];
        let idx_db = _dia_bdDevList_Db.findIndex(c=>c._id === devInfo._id);
        if(idx_db <0) return;
        _dia_bdDevList_Db[idx_db].bChecked=_dia_bdDevList_Db[idx_db].bChecked?false:true;
        setG_dia_bdDevList_Db(_dia_bdDevList_Db);
    }
    

    const handleSearch=()=>{
        // G_bdDevList_Db
        try {
            let filteredDevList = filterDev(G_dia_bdDevList_Db, G_dia_FloorList_Db, G_dia_AreaList_Db, G_SelectedFloor.floor_id, G_SelectedArea.area_id);
        // setG_dia_bdDevList_Display(filteredDevList);

            let searchInput = document.getElementById('searchInput_chooseDev').value.trim();
            if(searchInput==="") {
                // setG_dia_bdDevList_Display(G_dia_bdDevList_Db);
                setG_dia_bdDevList_Display(filteredDevList);
                return
            }
            // let _dia_bdDevList_Db =[...G_dia_bdDevList_Db];
            let _dia_bdDevList_Db =[...filteredDevList];
            let filtered = _dia_bdDevList_Db.filter((c)=>c.name.toUpperCase().search(searchInput.toUpperCase()) >= 0);
            setG_dia_bdDevList_Display(filtered);
        } catch (error) {
        }
    }

    const chooseFloor=()=>{
        setG_ShowTp(1);
    }

    const chooseArea=()=>{
        setG_ShowTp(2);
    }

    const handleCloseDiaChoose=()=>{
        setG_ShowTp(0);
    }
    const handleConfirmDevList=(selected_Floor)=>{
        document.getElementById('searchInput_chooseDev').value = "";  // clear search input
        let filteredDevList = filterDev(G_dia_bdDevList_Db, G_dia_FloorList_Db, G_dia_AreaList_Db, selected_Floor[0].floor_id, -1);
        setG_dia_bdDevList_Display(filteredDevList);

        setG_SelectedFloor(selected_Floor[0]);
        setG_SelectedArea({area_id:-1, areaName:"All"});    // reset area 
        setG_ShowTp(0);
    }
    const handleConfirmChooseArea=(selected_area)=>{
        document.getElementById('searchInput_chooseDev').value = "";      // clear search input
        let filteredDevList = filterDev(G_dia_bdDevList_Db, G_dia_FloorList_Db, G_dia_AreaList_Db, G_SelectedFloor.floor_id, selected_area[0].area_id);
        setG_dia_bdDevList_Display(filteredDevList);

        setG_SelectedArea(selected_area[0]);
        setG_ShowTp(0);
    }
    
    const filterDev=(devList, floorList, areaList, floor_id, area_id)=>{
        let _dia_bdDevList_Db = [];
        for (const eachDev of devList) {
            _dia_bdDevList_Db.push({...eachDev});
        }
        if(floor_id===-1){      // all
            return _dia_bdDevList_Db;
        }else if(floor_id===0){     // other
            for (const eachFloor of floorList) {
                _dia_bdDevList_Db = _dia_bdDevList_Db.filter(c=> c.floor_id !== eachFloor._id);                
            }
            return _dia_bdDevList_Db;
        }else{
            if(area_id===-1){   // all
                _dia_bdDevList_Db = _dia_bdDevList_Db.filter(c=> c.floor_id === floor_id);
                return _dia_bdDevList_Db;
            }else if(area_id===0){  // others
                _dia_bdDevList_Db = _dia_bdDevList_Db.filter(c=> c.floor_id === floor_id);
                for (const eachArea of areaList) {
                    _dia_bdDevList_Db = _dia_bdDevList_Db.filter(c=> c.area_id !== eachArea._id);
                }
                return _dia_bdDevList_Db;
            }else{
                _dia_bdDevList_Db = _dia_bdDevList_Db.filter(c=> c.floor_id === floor_id && c.area_id === area_id);
                return _dia_bdDevList_Db;
            }
        }
    }

    return (
        <div>
            {G_ShowTp ===1 && <TpSubDiaChooseFloor
                onclickClose = {handleCloseDiaChoose}
                onclickConfirm = {handleConfirmDevList}
                devInBd={G_dia_bdDevList_Db}
                floorInBd={G_dia_FloorList_Db}
                />}
                
            {G_ShowTp ===2 && <TpSubDiaChooseArea
                onclickClose = {handleCloseDiaChoose}
                selectedFloor = {G_SelectedFloor}
                onclickConfirm = {handleConfirmChooseArea}                
                devInBd={G_dia_bdDevList_Db}
                floorInBd={G_dia_FloorList_Db}
                areaInDb={G_dia_AreaList_Db}
                />}

            
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox'>
                                        
                    <div className ='searchBarContainer_non sortHor ' style={{marginTop:"15px", width:"240px"}}>
                        <div style={{width:"80%"}} className="">
                            <input id='searchInput_chooseDev' 
                                onChange={handleSearch} 
                                className='spring_diaSearchInput'
                                autoComplete='off'
                                style={{width:"100%"}}
                                />
                        </div>
                        <div className='stayRight' style={{marginRight:"10px"}}>
                            <FaSearch/>
                        </div>    
                    </div>

                    <div className='spring_diaFilterFrame'>
                        <div className='sortHor hoverPointer '
                        onClick={chooseFloor}>
                            <div className='spring_itemTitle'>Group</div>
                            <div>:</div>
                            <div className='blueText spring_marginLeft5'>{`${G_SelectedFloor.floorName}`}</div>
                        </div>

                        {G_SelectedFloor.floor_id > 0 && <div className='divSeperateLine'></div>}

                        {G_SelectedFloor.floor_id > 0 && <div className='sortHor hoverPointer'
                        onClick={chooseArea}>
                            <div className='spring_itemTitle'>Subgroup</div>
                            <div>:</div>
                            <div className='blueText spring_marginLeft5'>{`${G_SelectedArea.areaName}`}</div>
                        </div>}
                    </div>


                    <div className='spring_diaItemFrame'>
                        {G_dia_bdDevList_Display.map((u, ind)=>(<div key={`devSel_${ind}`} className='sortHor spring_diaItemContianer'>
                            
                            <div className='spring_biggerCheckBox hoverPointer ' 
                            style={{marginBottom:"-4px"}}
                            onClick={()=>handleCheckDev(u)}>
                                {<BsCheckLg className={`${u.bChecked?"":"divHidden"}`}/>}
                            </div>
                            
                            <div className='spring_marginLeft5  wrapTextEllipsis'>
                                {`[${u.devID}] ${u.name}`}
                            </div>
                        </div>

                        ))}       
                                      
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaChooseDevice;