import React, { useEffect, useState } from 'react';
import { BsToggle2On, BsToggle2Off } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { F_RjGetFanSpeed } from '../RjFunction/RjFn';
import { useSelector } from 'react-redux';
import { getRjAcList } from '../../../../../../../reduxStore/actions/app_rj';

function TpDiaChooseAction(props) {
    
  const Rdx_AcList = useSelector(getRjAcList);

    const [G_ToOn, setG_ToOn] = useState(true); 
    const [G_CleanMode, setG_CleanMode] = useState(true); 
    const [G_FanSpeed, setG_FanSpeed] = useState(5); 
    const [G_SwingPos, setG_SwingPos] = useState(0); 

    useEffect(()=>{
        async function startUp(){
            if(props.actionInfo) {
                let maxFanSpeed = Fin_GetMaxFanSpeed(props.acBrand);
                setG_FanSpeed(maxFanSpeed);
                let _AcReq = props.actionInfo.AcReq;
                let _Setpoint = props.actionInfo.Setpoint;
                /** set on off*/
                if(_AcReq===2 || _AcReq===5) setG_ToOn(false);
                else setG_ToOn(true);
                /** set clean mode*/
                if(_AcReq===5) setG_CleanMode(true);
                /** insert setpoint*/
                document.getElementById('in_setpoint').value = (parseFloat(_Setpoint)/10).toFixed(1);
                /** insert Fan and Swing pos */
                if(props.actionInfo.Fan){
                    let _FanSpeed = props.actionInfo.Fan;
                    setG_FanSpeed(_FanSpeed);
                }
                if(props.actionInfo.Swing){
                    let _SwingPos = props.actionInfo.Swing;
                    setG_SwingPos(_SwingPos);
                }
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.confirmChange) {
            let _AcReq = 4;
            let _Setpoint = 0;
            if(!G_ToOn){    //  turn off
                if(G_CleanMode) _AcReq=5;
                else   _AcReq=2;
            }else{
                let inSp = document.getElementById('in_setpoint').value;
                _Setpoint = parseFloat(inSp*10).toFixed(1);
            }
            
            props.confirmChange(_AcReq, _Setpoint, G_FanSpeed, G_SwingPos);
        }
    }
    const F_ToggleOnOff=(status)=>{
        setG_ToOn(status);
    }
    const F_ToggleCelanMode=()=>{
        setG_CleanMode(!G_CleanMode);
    }
    const F_LimitInputSp=()=>{
        let SP = document.getElementById('in_setpoint').value;
        if(SP>30) document.getElementById('in_setpoint').value = 30.0;
        else if(SP<16) document.getElementById('in_setpoint').value = 16.0;
    }
    
  const Fin_GetMaxFanSpeed=(acBrand)=>{    
    let matchAc = Rdx_AcList.find(c=>c.nBrand === acBrand);
    if(!matchAc) return 5;
    if(matchAc.fanSpeed<=0) return 0;
    return matchAc.fanSpeed;
  }
  
  const Fin_GetMaxSwingPos=(acBrand)=>{    
    let matchAc = Rdx_AcList.find(c=>c.nBrand === acBrand);
    if(!matchAc) return 5;
    if(matchAc.swingPos<=0) return 0;
    return matchAc.swingPos;
  }

    const handleToggleFan=()=>{
        let _FanSpeed = G_FanSpeed;
        let maxFanSpeed = Fin_GetMaxFanSpeed(props.acBrand);
        _FanSpeed++;
        if(_FanSpeed>maxFanSpeed) _FanSpeed = 0;
        setG_FanSpeed(_FanSpeed);
    }
    const handleToggleSwing=()=>{
        let _SwingPos = G_SwingPos;
        let maxSwingPos = Fin_GetMaxSwingPos(props.acBrand);
        _SwingPos++;
        if(_SwingPos>maxSwingPos) _SwingPos = 0;
        setG_SwingPos(_SwingPos);
    }

    return (
        <div>
            <div className='spring_subDialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_ruleVarListContainer' style={{paddingTop:"30px"}}>
                        <div className='sortHor spring_ruleActionAreaBar spreadAround'>
                            <div className='sortHor hoverPointer' onClick={()=>F_ToggleOnOff(true)}>
                                <div className='spring_checkBox'> 
                                    {G_ToOn && <FaCheck/>}
                                </div>
                                <div className='boldText'>ON</div>
                            </div>
                            <div className='sortHor hoverPointer' onClick={()=>F_ToggleOnOff(false)}>
                                <div className='spring_checkBox'>                                 
                                    {!G_ToOn && <FaCheck/>}
                                </div>
                                <div className='boldText'>OFF</div>
                            </div>
                        </div>

                        {G_ToOn &&<div className='spring_ruleActionAreaBar' style={{height:"140px"}}>  
                            <div className='spring_inputTpMidText'>SETPOINT</div>
                            <div className="searchBarContainer_non">
                                <input id='in_setpoint' 
                                    autoComplete='off'
                                    className='spring_diaSearchInput blueText'
                                    style={{width:"100%", marginRight:"0px", backgroundColor:"white"}}
                                    min="16"
                                    max="30"
                                    onBlur={F_LimitInputSp}
                                    type={`number`}
                                    />
                            </div>    

                            <div className='sortHor spreadBetween' style={{margin:"10px 0px"}}>
                                <div>
                                    <div className='spring_inputTpMidText'>FAN SPEED</div>
                                    <div className="spring_rjRuleFanSwingButton hoverPointer"
                                    onClick={handleToggleFan}>
                                        {F_RjGetFanSpeed(G_FanSpeed)}
                                    </div>   
                                </div>
                                <div>
                                    <div className='spring_inputTpMidText'>SWING POS</div>
                                    <div className="spring_rjRuleFanSwingButton hoverPointer"
                                    onClick={handleToggleSwing}>
                                        {F_RjGetFanSpeed(G_SwingPos)}
                                    </div>   
                                </div>
                            </div>

                        </div>}

                        {!G_ToOn &&<div className='sortHor spring_ruleActionAreaBar' style={{height:"140px"}}>
                            <div>Clean Mode</div>
                            <div className='hoverPointer spring_cleanModeToggle flexAndBothCenter'
                            onClick={F_ToggleCelanMode}>
                                {G_CleanMode && <BsToggle2On style={{color:"var(--HealthColor"}}/>}
                                {!G_CleanMode && <BsToggle2Off />}
                            </div>
                        </div>}
                    </div>

                                  

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpDiaChooseAction;