import http from "../httpService";

const tokenKey = "token";

export async function setUserActive(actToken) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/setuseractive";
  const body = {
    actToken: actToken,
  };
  return await http.post(url, body);
}

export async function resendActLink(email) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/resendactlink";
  const body = {
    email: email,
  };
  return await http.post(url, body);
}

export async function recaptcha(humanKey) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/recaptcha";
  const body = {
    humanKey: humanKey,
  };
  return await http.post(url, body);
}

export async function login(user) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/login";
  const body = {
    username: user.username,
    password: user.password,
  };
  const { data: jwt } = await http.post(url, body);
  // console.log("registerUrl: ", registerUrl);
  // console.log("jwt: ", jwt);
  // console.log(token);
  await localStorage.setItem(tokenKey, jwt);
}

export async function reqResetPassword(user) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/forgetpassword";
  const body = {
    email: user.email,
  };
  return await http.post(url, body);
}

export async function checkResetLink(token) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/checkrplink";
  const body = {
    token: token,
  };
  return await http.post(url, body);
}

export async function resetPassword(info) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/resetpassword";
  const body = {
    token: info.token,
    password: info.password,
  };
  return await http.post(url, body);
}

export async function changePassword(info) {
  let url = process.env.REACT_APP_PUBLIC_URL + "/user/chgpassword";
  const body = {
    _id: info.user_id,
    password: info.password,
  };
  return await http.post(url, body);
}

const expModule = {
  setUserActive,
  resendActLink,
  recaptcha,
  login,
  reqResetPassword,
  checkResetLink,
  resetPassword,
  changePassword
};

export default expModule;
