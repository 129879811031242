function swapArray(arr, idx_1, idx_2) {
  let aTemp = [...arr];
  aTemp[idx_1] = arr[idx_2];
  aTemp[idx_2] = arr[idx_1];
  return aTemp;
}

// let found = existingItemsOnTable.find((c) => c._id == dbItem._id);

function getDeletedArrItem(oriTable, modifiedTable, uniqueKey) {
  let deletedRow = [];
  for (const oriItem of oriTable) {
    // eslint-disable-next-line
    let found = modifiedTable.find((c) => c[uniqueKey] == oriItem[uniqueKey]);
    if (!found) deletedRow.push(oriItem);
  }
  return deletedRow;
}

function sortByName(a, b) {
  let sA = a.name.toUpperCase();
  let sB = b.name.toUpperCase();

  if (sA < sB) return -1;
  if (sA > sB) return 1;
  return 0;
}

function sortByTy_Loc_Name(a, b) {
  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;
  if (a.location.toUpperCase().trim() < b.location.toUpperCase().trim())
    return -1;
  if (a.location.toUpperCase().trim() > b.location.toUpperCase().trim())
    return 1;
  if (a.name.toUpperCase().trim() < b.name.toUpperCase().trim()) return -1;
  if (a.name.toUpperCase().trim() > b.name.toUpperCase().trim()) return 1;
  return 0;
}

function sortTemplate(a,b, sortKeys){
  if(!Array.isArray(sortKeys)) return 
  for (const sortObj of sortKeys) {
    if(typeof(a[sortObj["key"]])==='string'){
      // console.log('Is String');
      if(a[sortObj["key"]].toUpperCase().trim() < b[sortObj["key"]].toUpperCase().trim()) return (sortObj["descOrder"]) ? 1:-1;
      if(a[sortObj["key"]].toUpperCase().trim() > b[sortObj["key"]].toUpperCase().trim()) return (sortObj["descOrder"]) ? -1:1;
    }else{
      // console.log('Is Not String');
      if(a[sortObj["key"]] < b[sortObj["key"]]) return (sortObj["descOrder"]) ? 1:-1;
      if(a[sortObj["key"]] > b[sortObj["key"]]) return (sortObj["descOrder"]) ? -1:1;
    }
  }
  return 0;
}

function notEmptyArr(arr){
  if(!Array.isArray(arr)) return false;
  if(arr.length<1) return false;
  return true;
}

function isEmptyArr(arr){
  if(!Array.isArray(arr)) return true;
  if(arr.length<1) return true;
  return false;
}

function isEmptyObj(obj){
  return (Object.keys(obj).length < 1);
}

function pushUnique(arr, obj){
  // console.log('``````````````arr````````````', arr);
  if(!Array.isArray(arr)) return [];
  let _arr= [...arr];
  let found = arr.find(c=>c===obj);
  // console.log("found", found);
  if(!found) _arr.push(obj);
  return _arr;
}

const _sortTemplate = sortTemplate;
export { _sortTemplate as sortTemplate };
const _sortByTy_Loc_Name = sortByTy_Loc_Name;
export { _sortByTy_Loc_Name as sortByTy_Loc_Name };
const _sortByName = sortByName;
export { _sortByName as sortByName };
const _getDeletedArrItem = getDeletedArrItem;
export { _getDeletedArrItem as getDeletedArrItem };
const _swapArray = swapArray;
export { _swapArray as swapArray };
const _notEmptyArr = notEmptyArr;
export { _notEmptyArr as notEmptyArr };
const _pushUnique = pushUnique;
export { _pushUnique as pushUnique };
const _isEmptyArr = isEmptyArr;
export { _isEmptyArr as isEmptyArr };
const _isEmptyObj = isEmptyObj;
export { _isEmptyObj as isEmptyObj };