import http from "../httpService";

async function V2_GetSystemById(bd_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2system/getsystemlistbybdid";
    let body = {bd_id};
    return await http.post(url, body);  
}

async function V2_GetCompAuxByCompId(comp_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2system/getcomponentauxlistbycompid";
    let body = {comp_id};
    return await http.post(url, body);  
}

const expModule = {  
    V2_GetSystemById,
    V2_GetCompAuxByCompId
};
export default expModule;