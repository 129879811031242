import React from 'react';
import { FaRegPaperPlane} from "react-icons/fa";

function TpDiaSending(props) {
    
    // const handleClose=()=>{
    //     if(!props.onClickDiaClose) return;
    //     props.onClickDiaClose();
    // }

    return (
        <div>
            <div className='spring_dialogBoxContainer spring_index110'>
                <div className='spring_dialogBox spring_shadowBox'>
                    {/* <div className='spring_diaCloseBtn spring_shadowBox spring_lightGreyGrdBgc hoverPointer'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div> */}
                    <div className='spring_diaIconFrame spring_shadowBox spring_blueGrdBgc'>
                        {/* <div style={{marginLeft:"-30px"}}> */}
                            <FaRegPaperPlane style={{marginLeft:"-4px"}} className='spring_vibration'/>
                        {/* </div> */}
                    </div>
                    <div className='spring_diaStatusText'
                    style={{marginLeft:"7px"}}
                    >
                        Sending...
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpDiaSending;