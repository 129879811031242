import React from 'react';

function PinwheelLoader() {
    return ( 
        <div>
            <div id='pinwheel-loader'></div>
        </div>
    );
}

export default PinwheelLoader;