import ctrlDevSer from '../../../service/controlDev/ctrlDevService';

async function getNItemFromXindex(N, Xindex){
    try {
        let body = {n : N, x:Xindex}
        let result = await ctrlDevSer.getNItemFromXIndex(body);
        console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}


async function send(body){
    try {
        // console.log(body.ts);
        let result = await ctrlDevSer.send(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return 
        // if(result.data) return result.data;        
        return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}


async function getCtrlDev(body){
    try {
        // console.log(body.ts);
        let result = await ctrlDevSer.getCtrlDev(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}

async function setCtrlDev(body){
    try {
        // console.log(body.ts);
        let result = await ctrlDevSer.setCtrlDev(body);
        // console.log(body.ts);
        // console.log(result);
        if(result.status!==200) return ;
        return result.data;
        // if(result.data) return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}


async function v2sendCtrlCmd(topic, loRaPackage, urlSel, ctrlType){
    try {
        let result = await ctrlDevSer.v2sendCtrlCmd(topic, loRaPackage, urlSel, ctrlType);
        if(result.status!==200) return  
        return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}

async function v2getCmdLog(cmdLog){
    try {
        let result = await ctrlDevSer.v2getCmdLog(cmdLog);
        if(result.status!==200) return  
        return result.data;        
    } catch (error) {
        console.log(error.message);
        return
    }
}


const expModule = {  
    send,
    setCtrlDev,
    getCtrlDev,
    getNItemFromXindex,
    v2sendCtrlCmd,
    v2getCmdLog
};
export default expModule;

















// import ctrlDevSer from '../../../service/controlDev/ctrlDevService';

// async function getNItemFromXindex(N, Xindex){
//     try {
//         let body = {n : N, x:Xindex}
//         let result = await ctrlDevSer.getNItemFromXIndex(body);
//         console.log(result);
//         if(result.status!==200) return []
//         return (result.data);
//     } catch (error) {
//         console.log(error.message);
//         return []
//     }
// }


// async function send(body){
//     try {
//         // console.log(body.ts);
//         let result = await ctrlDevSer.send(body);
//         // console.log(body.ts);
//         // console.log(result);
//         if(result.status!==200) return 
//         // if(result.data) return result.data;        
//         return result.data;        
//     } catch (error) {
//         console.log(error.message);
//         return
//     }
// }


// async function getCtrlDev(body){
//     try {
//         // console.log(body.ts);
//         let result = await ctrlDevSer.getCtrlDev(body);
//         // console.log(body.ts);
//         // console.log(result);
//         if(result.status!==200) return ;
//         return result.data;
//         // if(result.data) return result.data;        
//     } catch (error) {
//         console.log(error.message);
//         return
//     }
// }

// async function setCtrlDev(body){
//     try {
//         // console.log(body.ts);
//         let result = await ctrlDevSer.setCtrlDev(body);
//         // console.log(body.ts);
//         // console.log(result);
//         if(result.status!==200) return ;
//         return result.data;
//         // if(result.data) return result.data;        
//     } catch (error) {
//         console.log(error.message);
//         return
//     }
// }




// const expModule = {  
//     send,
//     setCtrlDev,
//     getCtrlDev,
//     getNItemFromXindex,
// };
// export default expModule;