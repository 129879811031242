import React, { useEffect, useRef, useState } from 'react';
import auth from '../../../../service/auth/AuthV2';
import V2DataDownloadFn from '../../../../function/V2_DataDownload/V2_DataDownloadFn';
import { sortTemplate } from '../../../../function/ArrayFn/arrayFn';
import timeFn from "../../../../function/time/timeFn";
import { toast } from 'react-toastify';

function TpRequestList(props) {
    const [G_bLoaded, setG_bLoaded] = useState(false); 
    const [G_ticketList, setG_ticketList] = useState([]);
    
    const [G_downloadStatus, setG_downloadStatus] = useState(
        Array(G_ticketList.length).fill('Download')
    );

    const timerRef = useRef(null);

    useEffect(() => {
        timerRef.current = setInterval(async () => {
            // console.log("Refresh");
            await refreshPg();
        }, 60000);       /** 60000 , 1 min*/

        async function startUp() {
            await refreshPg();

            setG_bLoaded(true);
        }
        
        startUp();
        
        return ()=>{
            clearInterval(timerRef.current);
        }
    }, []);

    const refreshPg = async () => {
        let { bdInfo }  = props;
        let userInfo = auth.getUserInfo();

        let ticketList = await V2DataDownloadFn.getNotDownloadedTicket(userInfo.user_id, bdInfo._id);
        // console.log(ticketList);
        if(ticketList.length) {
            setG_ticketList(sortArr(ticketList));
            // if(G_ShowHistory === 0) setG_displayList(sortArr(ticketList));
        } else {
            setG_ticketList([]);
            // setG_displayList([]);
            clearInterval(timerRef.current);
        }

        let updatedStatus = Array(ticketList.length).fill('Download');
        // console.log(updatedStatus);
        setG_downloadStatus(updatedStatus);
    }

    const sortArr=(arrInput)=>{
        let tempArr = [...arrInput];
        let sortKeys = [
            { key: "unix", descOrder: true },
            // { key: "sortIdx", descOrder: false },
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
    }

    const handleDownload = async (ticketInfo, idx) => {
        try {
            const updatedStatus = [...G_downloadStatus];
            updatedStatus[idx] = 'Downloading...';
            setG_downloadStatus(updatedStatus);
            // console.log(ticketInfo);
            // let bdInfo  = localStorageFn.getBdToken();
            // eslint-disable-next-line
            let zipFileName = ticketInfo.description? ticketInfo.description.replace(/[\\/:\*\?"<>|]/g, '_') : timeFn.convertUnixToFormattedDateTime_UTC8(ticketInfo.unix);
            
            let response = await V2DataDownloadFn.dataDownloadCsvZip(ticketInfo);
            // console.log(response);
            // Create a Blob object from the ZIP data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Create a link element and trigger a click event to download the file
            const link = document.createElement('a');
            const downloadUrl = window.URL.createObjectURL(blob);
            link.href = downloadUrl;
            link.download = zipFileName;

            // Append to the DOM to ensure the click event works in all browsers
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link); // Remove the link element
            window.URL.revokeObjectURL(downloadUrl); // Release the memory

            await refreshPg();
        } catch (error) {
            toast.error("Download Error");
            console.log('Error downloading multiple CSV files:', error);
        }
    }

    const toNewRequestTab = () => {
        if (props.handleChangeTab) props.handleChangeTab(0);
    }

    // const clearCache = () => {
    //     if (props.clearCache) props.clearCache();
    // }

    return ( <div>
        {!G_bLoaded && <p className='flexAndBothCenter'>Loading...</p>}
        {G_bLoaded && <div>
            
            {G_ticketList.length === 0 && <div className='spring_inputBox'>
                <div className={`whiteText greenBgc spring_containerAddDev spring_addDevAllBorder hoverPointer`}
                onClick={toNewRequestTab}> + Add new download request</div>
            </div>}

            {G_ticketList.length > 0 && <div className='spring_inputBox spring_listContainer spring_ddList_scroll'>
                {G_ticketList.map((u, ind) => (
                    <div key={ `ticket_${ind}`}>
                        {ind!==0 && <div className='divSeperateLine'></div>}
                        <div className='sortHor spring_MarginTopBtm_4px'>
                            <div className='sortVer spring_marginLeft5 spring_marginRight5 spring_width70per'>
                                <div className='spring_miniText'>{timeFn.getDateTimeString_WithSeconds(u.unix)}</div>
                                <div className='spring_sceneVarValue spring_hideOverflowText'>
                                    {u.description? u.description : timeFn.convertUnixToFormattedDateTime_UTC8(u.unix)}
                                </div>
                            </div>
                            <div className='stayRight flexAndBothCenter'>
                                {u.ready?
                                    <div className={G_downloadStatus[ind] === 'Downloading...' ? 'disableClick' : 'hoverPointer spring_downloadText'} onClick={() => handleDownload(u, ind)}>
                                        {G_downloadStatus[ind]}
                                    </div>
                                    : <div className="">Preparing...</div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
            {G_ticketList.filter(c => c.ready === 0).length > 0 && <div className='spring_MarginLeftRight_20Per spring_centerText spring_smallText spring_italicStyle redText'>
                Remark: Preparing may take several minutes, please come back later
            </div>}
            {/* {G_ticketList.length > 0 && <div className='spring_MarginLeftRight_20Per spring_centerText spring_smallText spring_italicStyle'>
                Info: If there is a problem with your files, you may clear your history<button className='spring_linkButton' onClick={clearCache}>here</button>
            </div>} */}
        </div>}
    </div> );
}

export default TpRequestList;