import { notEmptyArr } from "../../../../../../../function/ArrayFn/arrayFn";
import v2RjFn from "../../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import { F_GetDefaultFilterDuration } from "../../CommonFn/SubTpCommonFn";

const fGetPercentage = (nUp, nDn) => {
  if (nDn === 0) return 0;
  let nPerc = (nUp / nDn) * 100;
  if (nPerc > 100) return 100;
  return nPerc;
};

const fSecToHour =(sSec)=>{
    let nHour = parseInt(sSec/3600);
    if(nHour<0) nHour=0;
    return nHour;
  }


const F_CalculateRunTime = async (RjBdDev_id, lastData) => {
  let lastCleanInfo = await v2RjFn.V2_RjLastRjCleanFilterInfo(RjBdDev_id);
  let runTimeSinceLastClean;
  let filterDuration_s = F_GetDefaultFilterDuration();
  if (notEmptyArr(lastCleanInfo)) {
    // clean before
    runTimeSinceLastClean =
      lastData.pn_3 - lastCleanInfo[0].lastCleanTime;
    filterDuration_s = lastCleanInfo[0].filterTime;
  } else {
    runTimeSinceLastClean = lastData.pn_3;
  }
  let filterDuration_h = (fSecToHour(filterDuration_s));

  let runTime_h = fSecToHour(runTimeSinceLastClean);
  let runTime_s = runTimeSinceLastClean;

  let filterPercentage = fGetPercentage(runTimeSinceLastClean, filterDuration_s);

  return { filterDuration_s, filterDuration_h, runTime_s, runTime_h, filterPercentage };
};

const expModule = {
    F_CalculateRunTime,
    fSecToHour
};
export default expModule;
