import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";

function TpSetMinMax(props) {
    const [G_errorMin, setG_errorMin] = useState("");
    const [G_errorMax, setG_errorMax] = useState("");

    const handleCloseDialog = () => {
        if(props.onClickCloseDia) props.onClickCloseDia();
    }

    const handleSetMinMax = () => {
        if(props.onClickSetMinMax && G_errorMax === "" && G_errorMin === "") props.onClickSetMinMax();
    }

    const handleResetMinMax = () => {
        if(props.onClickResetMinMax) props.onClickResetMinMax();
    }

    const handleMinMaxDiff = (input_id) => {
        let _input = document.getElementById(input_id).value;
        // console.log(input_id, _input);
        if(input_id.includes('max')) {
            let _minInput = document.getElementById('tpDialog_min').value;
            if(_minInput !== "" && parseFloat(_input) < parseFloat(_minInput)) {
                setG_errorMax("Max cannot be smaller than min");
            } else if(_minInput === _input) {
                setG_errorMax("Max cannot be the same as min");
            } else {
                setG_errorMax("");
                setG_errorMin("");
            }
        } else if(input_id.includes('min')) {
            let _maxInput = document.getElementById('tpDialog_max').value;
            if(_maxInput !== "" && parseFloat(_input) > parseFloat(_maxInput)) {
                setG_errorMin("Min cannot be larger than max");
            } else if(_maxInput === _input) {
                setG_errorMin("Min cannot be the same as max");
            } else {
                setG_errorMin("");
                setG_errorMax("");
            }
        }
    }   

    return ( 
        <div className='spring_dialogBoxContainer'>
            <div className='tpDialogFrame_small'>
                <div className='flexOnly spreadBetween'>
                    <div className='tpDialog_title'>Setting Field for {props.devInfo.name}</div>
                    <div className='tpDialog_closeBtn spring_shadowBox spring_lightGreyGrdBgc' onClick={handleCloseDialog}>
                        <IoMdClose />
                    </div>
                </div>
                <div className='tpDialog_subtitle'>{props.defParamList[props.scaleStatus-1].dataName}</div>
                <div className='buttonContainer tpDialog_button_1'>
                    <div className="roundButton_purple" onClick={handleResetMinMax}>
                        Auto Scale
                    </div>
                </div>
                <div className='tpDialog_minmaxContainer sortHor spreadBetween'>
                    <div className='tpDialog_minmax'>Max</div>
                    <div>
                        <input className='tpDialog_input' id='tpDialog_max' type='number' onChange={() => handleMinMaxDiff('tpDialog_max')} defaultValue={props.scaleMinMax[props.scaleStatus-1].max} />
                        <div id='tpDialog_errorMax' className='tpDialog_errorMinMax'>{G_errorMax}</div>
                    </div>
                </div>
                <div className='tpDialog_minmaxContainer sortHor spreadBetween'>
                    <div className='tpDialog_minmax'>Min</div>
                    <div>
                        <input className='tpDialog_input' id='tpDialog_min' type='number' onChange={() => handleMinMaxDiff('tpDialog_min')} defaultValue={props.scaleMinMax[props.scaleStatus-1].min} />
                        <div id='tpDialog_errorMin' className='tpDialog_errorMinMax'>{G_errorMin}</div>
                    </div>
                </div>
                <div className='buttonContainer tpDialog_buttonCont'>
                    {/* <div style={{ backgroundColor: "var(--ErrColor" }} className="roundButton" onClick={handleResetMinMax}>
                        Reset
                    </div> */}
                    <div id='tpDialog_set' className='roundButton' onClick={handleSetMinMax}>
                        Set
                    </div>  
                </div>
                
            </div>
        </div>
    );
}

export default TpSetMinMax;