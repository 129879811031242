import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown} from "react-icons/md";
import { FaSyncAlt} from "react-icons/fa";
import TpDiaSending from './../../../Common/Dialog/StatusDialog/TpDiaSending';
import TpDiaSuccess from './../../../Common/Dialog/StatusDialog/TpDiaSuccess';
import TpDiaFailed from './../../../Common/Dialog/StatusDialog/TpDiaFailed';
import { C_OpMode_CoolingTower } from '../Constant_CondersorLoop';
import AppUtiFn from '../../../Common/Function/Utilities';
import TpDiaGatewayID from './../../../Common/Dialog/Input/TpDiaGatewayID';
import v2ctrlFn from '../../../../../../function/V2_Control/V2_ControlFn';
import { toast } from 'react-toastify';
import { isEmptyArr, notEmptyArr } from '../../../../../../function/ArrayFn/arrayFn';
import CtCfgFn from '../../../../../../function/V2_AppDevice/V2_CoolingTowerCfgFn';
import ctrlCmdLib from '../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';
import socketIOClient from 'socket.io-client';

/**
 * G_opMode : 0=> Idle, 1=>Active
 * 
 * G_ForceOvewrite[1, 2]
 * 1 => active/selected 
 * 2 => value, true or false
 */

function TpClCoolingTower(props) {
    // const C_devInfo = {bdDev_id:23, type : 38}

    const cmdTimeout = 10000;    // 10 sec
    const msgTimeout = 3000;    // 3 sec

    const [G_showForceOverwrite, setG_showForceOverwrite] = useState(false);
    const [G_statusState, setG_statusState] = useState(0);
    const [G_opMode, setG_opMode] = useState(0);
    const [G_opMode_Db, setG_opMode_Db] = useState(0);
    const [G_ForceOvewrite, setG_ForceOvewrite] = useState([0,0]);
    const [G_ForceOvewrite_Db, setG_ForceOvewrite_Db] = useState([0,0]);
    const [G_gwPair, setG_gwPair] = useState(0);
    const [G_cfgChanged, setG_cfgChanged] = useState(false);
    const [G_diaErrMsg, setG_diaErrMsg] = useState("");
    
    const cmdTimeoutRef = useRef(null);
    const statusTimeoutRef = useRef(null);
    const refCmdLog = useRef({});
    const ctCfgRef = useRef({});

    useEffect(() => {
        let socket;
        async function startUp() {
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);

            await loadGwPair();
            await loadCtCfg();

            
            let topic = `v2_CtrlCmd`;
            socket.on(topic, async(data) => {
                // console.log("data", data);
                let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
                if(sentSuccess) {
                    clearTimeout(cmdTimeoutRef.current);
                    setG_statusState(2);
                    /** trigger pass countdown */
                    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
                    // toast ("Success")
                    await handleServerReq(data);
                }
                /** handle server request */
                
            });
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
            clearTimeout(cmdTimeoutRef.current);
            clearTimeout(statusTimeoutRef.current);            
            socket.disconnect();
        };
        // eslint-disable-next-line
    }, []);

    const handleServerReq=async(mqttData)=>{
        let {hf} = mqttData;
        if(hf===3){         // set configuration success
            let setRel = await CtCfgFn.setV2_CtCfg(ctCfgRef.current);
            if(!setRel.success) return toast("Upload configuration err. Please reload page");
            setG_cfgChanged(false);     // hide save change button
        }else if (hf===4){  // sync configuration success
            if(isEmptyArr(mqttData.pi)) return
            if(mqttData.pi.length!==3) return
            /** set opMode */
            setG_opMode(mqttData.pi[0])
            /**Set force overwrite */
            let _ForceOvewrite = [mqttData.pi[1], mqttData.pi[2]];
            setG_ForceOvewrite(_ForceOvewrite);
            /** save data into DB */
            ctCfgRef.current={
                bdDev_id: props.devInfo.bdDev_id, 
                opMode:mqttData.pi[0], 
                FO_Avtive:mqttData.pi[1],
                FO_Value:mqttData.pi[2]
            }
            let setRel = await CtCfgFn.setV2_CtCfg(ctCfgRef.current);
            if(!setRel.success) return toast("Sync configuration err. Please reload page");
            /** if success update DB  */
            setG_opMode_Db(mqttData.pi[0])
            setG_ForceOvewrite_Db(_ForceOvewrite);
            setG_cfgChanged(false);     // hide save change button
        }
        else if(hf===20002){     // server request schedule
            // /** convert schedule into DB form , {startUnix, endUnix, schType, action, DOW, reserve1, inUse}*/
            // let scheList = devScheLib.sche_loraFormat_to_db(mqttData);
            // /** store DB into database */
            // let setScheRel = await devScheFn.V2_setSche(scheList, refDevInfo.current);
            // if(!setScheRel)return toast("Store DB err");            
            // /** update device display */
            // await loadSchedule();            
            // /** set sync into 3, clear sync_save button */
            // setG_nSync_Save(3);
        }else if(hf===20001){        // set sche to device
            // /** save eventList into DB */
            // let setScheRel = await devScheFn.V2_setSche(refScheList.current, refDevInfo.current);
            // // console.log("setScheRel", setScheRel);
            // if(!setScheRel)return toast("Store DB err");
            // /** set sync into 3, clear sync_save button */
            // setG_nSync_Save(3);
        }   
    }
    
    const statusDiaAutoClose=()=>{
        clearTimeout(cmdTimeoutRef.current);
        clearTimeout(statusTimeoutRef.current);    
        setG_statusState(0);
    }

    const loadCtCfg=async()=>{
        let ctCfg = await CtCfgFn.getV2_CtCfg(props.devInfo.bdDev_id)
        // console.log("ctCfg", ctCfg);
        if(!notEmptyArr(ctCfg)) {
            setG_ForceOvewrite([0,0]);
            setG_ForceOvewrite_Db([0,0]);

            setG_opMode(0);
            setG_opMode_Db(0);
            return toast('No configuration setting before');
        }
        setG_ForceOvewrite([ctCfg[0].FO_Avtive,ctCfg[0].FO_Value]);
        setG_ForceOvewrite_Db([ctCfg[0].FO_Avtive,ctCfg[0].FO_Value]);

        setG_opMode(ctCfg[0].opMode);
        setG_opMode_Db(ctCfg[0].opMode);

        if(ctCfg[0].FO_Avtive > 0 || ctCfg[0].FO_Value >0) setG_showForceOverwrite(true);
    }

    const handleShowForceOverwrite=()=>{
        setG_showForceOverwrite(!G_showForceOverwrite);
    }

    const handleToggleOpMode=()=>{
        let _opMode = G_opMode;
        _opMode++;
        if(_opMode>=C_OpMode_CoolingTower.length) _opMode=0;
        setG_opMode(_opMode);
        setG_cfgChanged(true);
    }

    const getForceOvewriteText=(nIdx)=>{
        let nOp = AppUtiFn.getForceOverwrite(G_ForceOvewrite[0], G_ForceOvewrite[1], nIdx);
        if(nOp===1) return "ON";
        if(nOp===2) return "OFF";
        return "-";
    }

    const handleToggleFOver=(nIdx)=>{
        // let existState = AppUtiFn.getForceOverwrite(G_ForceOvewrite[0], G_ForceOvewrite[1], nIdx);
        let nActive = G_ForceOvewrite[0];
        let nValue = G_ForceOvewrite[1];
        let _ForceOvewrite = AppUtiFn.setForceOverwrite(nActive, nValue, nIdx);
        setG_ForceOvewrite(_ForceOvewrite);        
        setG_cfgChanged(true);
    }

    const loadGwPair=async ()=>{
        try { 
            let pairedGw = await v2ctrlFn.getV2_GwPair(props.devInfo.bdDev_id);
            if(pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
            let {gwPairList} = pairedGw;
            if(!notEmptyArr(gwPairList)) return toast('No gateway pair to this device');
            setG_gwPair(gwPairList[0].gwid);
        } catch (error) {
            console.log("loadGwPair err: ", error.message);
        }
    }

    const handleSetGwDisplay=(gwid)=>{
        setG_gwPair(gwid);
    }

    const handleCallGwId=()=>{
        setG_statusState(11);
    }
    
    const handleTurnOn=async ()=>{
        await triggerOnOff(true);
        setG_statusState(1);
    }
    const handleTurnOff=async ()=>{
        await triggerOnOff(false);
        setG_statusState(1);
    }

    const triggerOnOff=async (bOn)=>{
        let payload={};
        if(bOn)  payload={pi:[1, 1]};       // type, ID, function , [...payload]
        else  payload={pi:[2, 2]};


        /** get gw info */
        if(!G_gwPair) return toast("Invalid Gateway Setting");
        let _gwPair = G_gwPair;
        if(_gwPair < 1) return toast(`No gateway pair to this device`);

        /** get device {type, devID} info */
        
        let devInfo = {type:props.devInfo.ht, devID:props.devInfo.hi}
        
        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(2, _gwPair, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

        setG_statusState(1);     // go to sending
        
        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    const handleDiscard=()=>{
        setG_opMode(G_opMode_Db);
        setG_ForceOvewrite(G_ForceOvewrite_Db);    
        setG_cfgChanged(false);
        // console.log("Discard");
        // setG_statusState(3);     // go to 
    }

    const handleCloseDia=()=>{
        clearTimeout(cmdTimeoutRef.current);
        clearTimeout(statusTimeoutRef.current);    
        setG_statusState(0);
    }

    const fCmdTimeout=async()=>{
        let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
        if(!checkRel || !checkRel.errMsg) {
            setG_diaErrMsg("Cmd DB Err");
            // toast("Cmd DB Err");
        }
        if(checkRel.errMsg) {
            setG_diaErrMsg(checkRel.errMsg);
            // toast(checkRel.errMsg);
        }
        setG_statusState(3);
        clearTimeout(cmdTimeoutRef.current);
        /** trigger failed countdown */
        statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);        
        
    }

    const handleUploadCfg=async ()=>{
        let pi = [G_opMode, ...G_ForceOvewrite];
        console.log("pi", pi);
        let payload = {pi};

        /** get gw info */
        if(G_gwPair < 1) return toast(`No gateway pair to this device`);

        /** get device {type, devID} info */
        let devInfo = {type:props.devInfo.ht, devID:props.devInfo.hi}
        
        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(3, G_gwPair, devInfo, payload, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

        /** log ct configuration */        
        ctCfgRef.current={
            bdDev_id: props.devInfo.bdDev_id, 
            opMode: G_opMode, 
            FO_Avtive: G_ForceOvewrite[0],
            FO_Value: G_ForceOvewrite[1]
        }
        setG_statusState(1);     // go to sending
        
        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    const handleSyncCfg=async ()=>{
        /** get gw info */
        if(G_gwPair < 1) return toast(`No gateway pair to this device`);

        /** get device {type, devID} info */
        let devInfo = {type:props.devInfo.ht, devID:props.devInfo.hi}
        
        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(4, G_gwPair, devInfo, [], 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)

        setG_statusState(1);     // go to sending
        
        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    
    return (
        <div>
            {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
            {G_statusState===1 && <TpDiaSending/>}
            {G_statusState===2 && <TpDiaSuccess onClickDiaClose = {handleCloseDia}/>}
            {G_statusState===3 && <TpDiaFailed onClickDiaClose = {handleCloseDia} diaErrMsg={G_diaErrMsg}/>}
            
            {G_statusState===11 && <TpDiaGatewayID 
                divInfo = {props.devInfo} 
                gwPair = {G_gwPair} 
                onClickDiaClose = {handleCloseDia}
                setGwId = {handleSetGwDisplay}
            />}

            <div className='spring_tpFrame spring_shadowBox'>
                <div className='sortHor'>                    
                    <div className='spring_tpTitle'>Cooling Tower 1</div>
                    {!G_cfgChanged && <div className='hoverPointer spring_syncButton spring_rightInput'  
                    onClick={handleSyncCfg}>
                        {/* Sync */}
                        <FaSyncAlt/>
                    </div>}
                </div>

                <div className='spring_cardFrame'>
                    <div className='spring_ContentHeader sortHor'>
                        <div className='spring_HeaderText'>
                            Operation Mode
                        </div>
                        <div className='spring_rightInput blueText hoverPointer'
                        onClick={handleToggleOpMode}>
                            {`${G_opMode < C_OpMode_CoolingTower.length? C_OpMode_CoolingTower[G_opMode].name:"-"}`}
                        </div>
                    </div>
                </div>
                
                <div className='spring_seperationLine'></div>

                <div className='spring_cardFrame'>
                    <div className='spring_ContentHeader'>
                        <div className='spring_HeaderText'>
                            Manual Control
                        </div>
                        <div className='spring_ContentArea sortHor spreadAround spring_buttonSpace'>
                            <div className='spring_roundButton spring_bgi_GrGreen flexAndBothCenter spring_shadowBox hoverPointer'
                            onClick={handleTurnOn}>
                                ON
                            </div>
                            <div className='spring_roundButton spring_bgi_GrRed flexAndBothCenter spring_shadowBox hoverPointer'
                            onClick={handleTurnOff}>
                                OFF
                            </div>
                        </div>
                    </div>
                </div>

                <div className='spring_seperationLine'></div>

                <div className='spring_cardFrame'>
                    <div className='spring_ContentHeader sortHor hoverPointer'
                    onClick={handleShowForceOverwrite}>
                        <div className='spring_HeaderText'>
                            Force overwrite
                        </div>
                        <div className={`spring_rightInput ${G_showForceOverwrite?"spring_rotate180":"spring_rotate0"}`}>
                            <MdOutlineKeyboardArrowDown/>
                        </div>
                    </div>
                    <div className={`${G_showForceOverwrite?"spring_ContentArea spring_showItemAni":"spring_hideItemAni"}`}>
                        <div className='spring_ContentItem sortHor'>
                            <div>Run</div>
                            <div className='spring_rightInput blueText hoverPointer'
                            onClick={()=>handleToggleFOver(0)}
                            >
                                {getForceOvewriteText(0)}
                            </div>
                        </div>
                        <div className='spring_ContentItem sortHor'>
                            <div>Stop</div>
                            <div className='spring_rightInput blueText hoverPointer'                            
                            onClick={()=>handleToggleFOver(1)}
                            >
                                {getForceOvewriteText(1)}
                            </div>
                        </div>
                        <div className='spring_ContentItem sortHor'>
                            <div>Trip</div>
                            <div className='spring_rightInput blueText hoverPointer'
                            onClick={()=>handleToggleFOver(2)}
                            >
                                {getForceOvewriteText(2)}
                            </div>
                        </div>
                        <div className='spring_ContentItem sortHor'>
                            <div>Maintenance</div>
                            <div className='spring_rightInput blueText hoverPointer'
                            onClick={()=>handleToggleFOver(3)}
                            >
                                {getForceOvewriteText(3)}
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div className='spring_seperationLine'></div>

                <div className=' spring_cardFrame '>
                    <div className='spring_ContentHeader sortHor'>
                        <div className='spring_HeaderText'>
                            Gateway Pair
                        </div>
                        <div className='spring_rightInput blueText hoverPointer'
                        onClick={handleCallGwId}>{`${G_gwPair>0 ?G_gwPair: "-"}`}</div>
                    </div>
                </div>
                
                
                {G_cfgChanged && <div className='sortHor spreadEvenly'>
                    <div className='spring_DiaButton spring_BtmBtn_Grey spring_shadowBox hoverPointer'
                    onClick={handleDiscard}>Discard</div>
                    <div className='spring_DiaButton spring_BtmBtn_Green spring_shadowBox hoverPointer'
                    onClick={handleUploadCfg}>Save</div>
                </div>}

            </div>
        </div>
    );
}

export default TpClCoolingTower;