import areaListSer from '../../service/present_V2/areaList';

async function getByBd_Id(Bd_Id){
    try {
        let result = await areaListSer.getAreaList_Bd_Id(Bd_Id);
        // console.log(result);
        if(result.status!==200) return []
        return (result.data);
    } catch (error) {
        console.log(error.message);
        return []
    }
}


const expModule = {  
    getByBd_Id,
};
export default expModule;