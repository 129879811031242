import React, { useState, useEffect, useRef } from 'react';
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import { IoIosArrowBack} from "react-icons/io";
import auth from '../../../../../service/auth/AuthV2';
import userSvc from '../../../../../service/auth/userService';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ChangePassword(props) {
    const [G_userInfo, setG_userInfo] = useState({});
    const [G_showPassword, setG_showPassword] = useState(false);
    const [G_showCfmPassword, setG_showCfmPassword] = useState(false);

    const refManuLevel = useRef(0);

    useEffect(() => {
        let {manuLevel} = localStorageFn.getManuToken();
        refManuLevel.current = manuLevel;
        let userInfo = auth.getUserInfo();
        if(Object.keys(G_userInfo).length === 0) setG_userInfo(userInfo);
    })

    const backPrevPg = () => {
        let pathname ="/data/bdoverview"
        if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const clearPassErr = (input_id) => {
        document.getElementById(input_id).innerHTML = "";  // clear err text
    }

    const handlePass = () => {
        let _pass = document.getElementById("Pw_Input").value;
        if(!passwordValidation(_pass)) {
            document.getElementById('pwErrId').innerHTML = "Invalid password format";
        }
    }

    const handleCfmPass = () => {
        let _pass = document.getElementById("Pw_Input").value;
        let _cfmPass = document.getElementById("CfmPw_Input").value;
        if(_pass !== _cfmPass) {
            document.getElementById('cfmPwErrId').innerHTML = "Password does not match";
        }
    }

    const passwordValidation = (pw) => {
        var _pwRe = /^(?=.*\d)(?=.*[!@#$%^&*?/.,])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return _pwRe.test(pw);
    }

    const handleConfirm = async () => {
        let _pass = document.getElementById("Pw_Input").value;
        let _cfmPass = document.getElementById("CfmPw_Input").value;
        if(_pass.length && _cfmPass.length && document.getElementById('pwErrId').innerHTML === "") {
            let { user_id } = auth.getUserInfo();
            // console.log(user_id);
            let result = await userSvc.changePassword({user_id: user_id, password: _pass});
            // console.log(result);
            if(result.status === 200) {
                toast("Success");
                backPrevPg();
            }
        }
    }

    const showHidePassword = (idx) => {
        if(idx === 0) setG_showPassword(!G_showPassword);
        else if(idx === 1) setG_showCfmPassword(!G_showCfmPassword);
    }

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>User</div>
            </div>

            <div className='spring_tpTitle spring_nextInputTitle'>Change Password for {G_userInfo.username}</div>

            <div style={{position:'relative'}}>
                <div className='inputContainer_titleAtBox'>
                    <div id = 'pwInstruction' className='inputInstMsg'>
                        Passwords must contain at least 8 characters with a mix of upper and lower case letters, numbers & symbols (!@#$%^&*?/.,)
                    </div>
                    <div className="inputTitle_titleAtBox">
                        New Password
                    </div>
                    <div className='spring_MarginBtm_10'>
                        <div className='sortHor'>
                            <input autoComplete="off" 
                                onChange={() => clearPassErr('pwErrId')}
                                onBlur={handlePass}
                                id="Pw_Input"
                                className="inputText_titleAtBox blueText" 
                                type={G_showPassword? "text" :"password"}
                                maxLength={20}/>
                            <div className='spring_marginLeft_n30' onClick={() => showHidePassword(0)}>{G_showPassword? <FaEyeSlash /> : <FaEye />}</div>
                        </div>
                        <div id = 'pwErrId' className='inputErrMsg'></div>
                    </div>
                    <div className="inputTitle_titleAtBox">
                        Confirm Password
                    </div>
                    <div className='spring_MarginBtm_10'>
                        <div className='sortHor'>
                            <input autoComplete="off" 
                                onChange={() => clearPassErr('cfmPwErrId')}
                                onBlur={handleCfmPass}
                                id='CfmPw_Input'
                                className="inputText_titleAtBox blueText" 
                                type={G_showCfmPassword? "text" :"password"}
                                maxLength={20}/>
                                <div className='spring_marginLeft_n30' onClick={() => showHidePassword(1)}>{G_showCfmPassword? <FaEyeSlash /> : <FaEye />}</div>
                        </div>
                        <div id = 'cfmPwErrId' className='inputErrMsg '></div>
                    </div>
                    
                    <div className="buttonContainer">
                        <div className='roundButton spring_MarginTop_20'
                        onClick={handleConfirm}>Confirm</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;