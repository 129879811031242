import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaMinus} from "react-icons/fa";

function TpDiaSortDevChooseArea(props) {
    const [G_subgroupList, setG_subgroupList] = useState([]);

    useEffect(()=>{
        async function startUp(){
            try {
                if(props.areaGroupList){
                    setG_subgroupList(props.areaGroupList);
                }
            } catch (error) {
                toast(error.message);
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();

    }
    const handleChooseItem=(item)=>{
        if(props.onclickChooseItem) props.onclickChooseItem(item);

    }

    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>


                <div className=' spring_dialogList spring_diaFixedHeight'
                style={{position:"relative"}}>
                    <div className='spring_diaCloseBtnFixedHeight spring_shadowBox spring_lightGreyGrdBgc hoverPointer spring_index2'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>
                    <div className='text_1_2 spring_renameBdName spring_centerText'>Choose subgroup</div>
                                      
                    <div className='spring_disListFrame'
                    style={{height:"270px"}}>
                        {G_subgroupList.map((u, ind)=>(
                            <div key = {`sorArea_${ind}`} className='spring_SortFrameList hoverPointer '
                            onClick={()=>handleChooseItem(u)}>
                                {u.areaName}
                            </div>
                        ))}
                    </div>
                    

                </div>

            </div>
        </div>
    );
}

export default TpDiaSortDevChooseArea;