import React, { useEffect, useState } from "react";
import { GrRevert } from "react-icons/gr";
// import v2RjFn from "../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import v2RjJsxFn from "../RogerJuniorTab/RjFunction/RjJsxFn";
// import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
// import { F_GetDefaultFilterDuration } from "../CommonFn/SubTpCommonFn";

const TabSettingFilterCleaning = (props) => {
    // const G_DefaultFilterTime = F_GetDefaultFilterDuration();
  const [G_FilterPercentage, setG_FilterPercentage] = useState(0);
  const [G_FilterTime, setG_FilterTime] = useState("--");
  const [G_RunTime, setG_RunTime] = useState("--");

  useEffect(() => {
    async function startUp() {
      
      await fCalculateRunTime();
      // load cleaning history
      // get the latest cleaning time minus current running time.
      // convert the latest running time to H
    }
    startUp();

    return () => {
      // alert("Page Close");
    };
    // eslint-disable-next-line
  }, []);


  // const fSecToHour =(sSec)=>{
  //   let nHour = parseInt(sSec/3600);
  //   if(nHour<0) nHour=0;
  //   return nHour;
  // }

  // const fGetPercentage=(nUp, nDn)=>{
  //   if(nDn===0) return 0;
  //   let nPerc = (nUp/nDn)*100;
  //   if(nPerc>100) return 100;
  //   return nPerc;
  // }

  const fCalculateRunTime=async()=>{
    let calRel = await v2RjJsxFn.F_CalculateRunTime(props.dev._id, props.lastData);
    
    setG_FilterTime(calRel.filterDuration_h);
    setG_RunTime(calRel.runTime_h);
    setG_FilterPercentage(calRel.filterPercentage);
    
  }

  const fBackToMoreManu = () => {
    if (props.fToTab) props.fToTab(31);
    // if(props.discardChanges) props.discardChanges();
  };
  
  const fFilterDetails = () => {
    if (props.fToTab) props.fToTab(36);
    // if(props.discardChanges) props.discardChanges();
  };

  

    // const fClickAddRunTime=()=>{
    //     let _FilterPercentage = G_FilterPercentage+5;
    //     if(_FilterPercentage>100){
    //         setG_FilterPercentage(0);
    //     }else{
    //         setG_FilterPercentage(_FilterPercentage);
    //     }
    // }

  return (
    <div className="spring_editScenePg">
      <div className="sortHor" style={{ marginBottom: "5px" }}>
        <div className="spring_ManuTitle">Filter Cleaning</div>
        <div
          className="spring_ManuTitleBackIcon stayRight hoverPointer"
          onClick={fBackToMoreManu}
        >
          <GrRevert />
        </div>
      </div>

      <div className="spring_RjSettingGroupTitle">Filter Condition</div>
      <div className="spring_editScheGroup">
        <div className="spring_editItemBar sortHor">
          <div className="spring_editLeftTitle">Running Hour(s)</div>
          <div
            className="spring_scheActive_Icon flexAndBothCenter hoverPointer"
            // onClick={fToggleActive}
          >
            {`${G_RunTime}/${G_FilterTime}`}
          </div>
        </div>
        <div className="spring_editItemBar divRelative" style={{height:"15px"}}>
            <div className="spring_StatusBar_Grey divAbsolute"></div>
            <div className={`spring_StatusBar_Green divAbsolute`} style={{width:`${G_FilterPercentage}%`}}></div>
            {(G_FilterPercentage>70) && <div className="spring_StatusBar_Yellow divAbsolute" style={{width:`${G_FilterPercentage}%`}}></div>}
            {(G_FilterPercentage>90) && <div className="spring_StatusBar_Red divAbsolute" style={{width:`${G_FilterPercentage}%`}}></div>}
        </div>
      </div>

      <div className="spring_fullButton_Green hoverPointer"
      onClick={fFilterDetails}
      >
        Filter has been cleaned</div>
    </div>
  );
};

export default TabSettingFilterCleaning;
