import React, { useEffect, useState } from 'react';
// import { IoIosArrowBack} from "react-icons/io";
import { FiTrash2} from "react-icons/fi";
import { HiOutlineArrowUp, HiOutlineArrowDown} from "react-icons/hi";
import { swapArray } from '../../../../../function/ArrayFn/arrayFn';
// import { useDispatch, useSelector } from 'react-redux';
// import { getSelectedList,  } from '../../../../../reduxStore/actions/dev_selHistoryTrend';

function TpDiaSortObj(props) {
    // const dispatch = useDispatch();  
    // const Grx_selectedList = useSelector(getSelectedList);

    // const [G_bdDevList_display, setG_bdDevList_display] = useState([]); 
    
    // const [G_bdDevList_toDia, setG_bdDevList_toDia] = useState([]); 
    // const [G_floorList_toDia, setG_floorList_toDia] = useState([]); 
    // const [G_areaList_toDia, setG_areaList_toDia] = useState([]); 
    // const [G_bdDevList, setG_bdDevList] = useState([]); 
    
    // const [G_MoveToFloor, setG_MoveToFloor] = useState({floor_id:0, floorName:"Others"}); 
    // // eslint-disable-next-line
    // const [G_MoveToArea, setG_MoveToArea] = useState({area_id:0, areaName:"Others"}); 
    // const [G_ShowTp, setG_ShowTp] = useState(0); 
    const [G_bLoaded, setG_bLoaded] = useState(false); 

    const [G_ObjectList, setG_ObjectList] = useState([]);
    // const [G_sensorDetailsList, setG_sensorDetailsList] = useState([]);
    
    // const refManuLevel = useRef(0);

    useEffect(()=>{
        async function startUp(){
            let objList = F_LoadPropsObj();
            setG_ObjectList(objList);
            setG_bLoaded(true);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const F_LoadPropsObj=()=>{
        if(props.objList){
            let objList = JSON.parse(JSON.stringify(props.objList));
            if (Array.isArray(objList)) {
                return objList;
            }
        }
        return [];
    }

    const F_handleClose=()=>{
        if(props.hanleCloseDia) props.hanleCloseDia();
    }
    const handleSortUp=(ind)=>{
        if(ind===0) return;
        let _ObjectList = JSON.parse(JSON.stringify(G_ObjectList));
        _ObjectList = swapArray(_ObjectList, ind-1,ind);
        setG_ObjectList(_ObjectList);
    }
    const handleSortDown=(ind)=>{
        let _ObjectList = JSON.parse(JSON.stringify(G_ObjectList));
        if(!Array.isArray(_ObjectList)) return
        if(ind>=_ObjectList.length-1) return;
        _ObjectList = swapArray(_ObjectList, ind,ind+1);
        setG_ObjectList(_ObjectList);
    }
    const handleDeleteObj=(ind)=>{
        let _ObjectList = JSON.parse(JSON.stringify(G_ObjectList));
        if(!Array.isArray(_ObjectList)) return
        _ObjectList.splice(ind, 1);
        setG_ObjectList(_ObjectList);
    }
    const handleConfirm=()=>{
        if(props.onClickSortConfirm) props.onClickSortConfirm(G_ObjectList);
    }
    
    
    

    return (
        <div className='spring_dialogBoxContainer'>
            <div className='spring_manuContainer'></div>

            <div className=' spring_dialogBox spring_DiaSelDev spring_DiaChooseObj_Container'>
                <div className='spring_DiaChooseObj_Title'>Sort Device</div>
                <div className='spring_DiaChooseObj_ItemContainer'>
                    {G_ObjectList.map((u, ind)=>(<div key={`dev_${ind}`} className='sortHor'>
                        <div className='sortHor spring_DiaChooseObj_Item'>
                            <div className='sortVer'>
                                <div className='spring_DiaChooseObj_SmallName'>{`${u.a_sensorDetails[0].name}`}</div>
                                <div className='wrapTextEllipsis spring_DiaChooseObj_Name'>{`${u.dev.name}`}</div>
                            </div>
                            <div className='sortHor stayRight'>
                                <div className='spring_DiaChooseObj_ActionButton flexAndBothCenter hoverPointer square_25'
                                onClick={()=>handleSortUp(ind)}
                                >
                                    <HiOutlineArrowUp className='greenText'/>
                                </div>
                                <div className='spring_DiaChooseObj_ActionButton flexAndBothCenter hoverPointer square_25'
                                onClick={()=>handleSortDown(ind)}
                                >
                                    <HiOutlineArrowDown className='blueText'/>
                                </div>
                                
                            </div>
                        </div>                        
                        <div className='spring_DiaChooseObj_DelButton flexAndBothCenter hoverPointer square_25'
                            onClick={()=>handleDeleteObj(ind)}
                            >
                                <FiTrash2 className='redText'/>
                            </div>
                    </div>))}   
                    {G_bLoaded && G_ObjectList.length ===0 && <div className='spring_DiaChooseObj_EmptyList'>Empty List</div>}                    
                </div>
                
                <div className='sortHor spreadBetween spring_DiaSelDev_ButtonBar'>
                    <div className="spring_roundButton spring_greyColor hoverPointer"
                        onClick={F_handleClose}
                        >Back</div>
                    <div className="roundButton " 
                        onClick={handleConfirm}
                        >Confirm</div>
                </div>

            </div>

        </div>
    );
}

export default TpDiaSortObj;