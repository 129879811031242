import { notEmptyArr } from "../../../../../../../function/ArrayFn/arrayFn";

const F_GetLoRaDataType=(sDataType)=>{
    if(sDataType==="pb") return 1;
    if(sDataType==="pf") return 2;
    if(sDataType==="pi") return 3;
    if(sDataType==="pn") return 4;
}
const F_GetLoRaDataType_px=(nDataType)=>{
    if(nDataType===1) return "pb";
    if(nDataType===2) return "pf";
    if(nDataType===3) return "pi";
    if(nDataType===4) return "pn";
}

const F_MatchUnit=(linkVar, paraInfo)=>{
    if((linkVar.dataIndex === paraInfo.dataIndex) && 
    (linkVar.dataType === F_GetLoRaDataType(paraInfo.dataType)) &&
    (linkVar.sensorType === paraInfo.sensorType)) return true
    return false;
}

const F_RjSupportVersion=(nVersion)=>{
    if(nVersion > 0 && nVersion <= 5) return false;
    return true;
}

const F_RjNotSupportVersion=(nVersion)=>{
    let supported = F_RjSupportVersion(nVersion);
    return supported? false:true;
}

const F_RjSortSceneVarList=(aVarList)=>{
    let list = [];
    if(notEmptyArr(aVarList)) {
        for (let i = 2; i < 7; i++) {   // 2~6
            let found = aVarList.find(c=>c.varIdx===i);
            if(found)   list.push(found);
            else    list.push({varName:""});
        }
    }
    return list;
}

const F_RjScheAction=(nAction)=>{
    if(nAction===1) return "Manual"
    else if(nAction===2) return "i-Control"
    else if(nAction===3) return "Scene"
    else if(nAction===4) return "Off"
    else if(nAction===5) return "Off, Cleaning"
    else return "None"
}

const F_RjGetFanSpeed=(nInt)=>{
    if (nInt===0) return "Auto"
    return nInt;
}

const F_RjScheType=(nAction)=>{
    if(nAction===1) return "Weekly"
    else return "None"
}

const _F_GetLoRaDataType = F_GetLoRaDataType;
export { _F_GetLoRaDataType as F_GetLoRaDataType };
const _F_MatchUnit = F_MatchUnit;
export { _F_MatchUnit as F_MatchUnit };
const _F_GetLoRaDataType_px = F_GetLoRaDataType_px;
export { _F_GetLoRaDataType_px as F_GetLoRaDataType_px };
const _F_RjSupportVersion = F_RjSupportVersion;
export { _F_RjSupportVersion as F_RjSupportVersion };
const _F_RjNotSupportVersion = F_RjNotSupportVersion;
export { _F_RjNotSupportVersion as F_RjNotSupportVersion };
const _F_RjSortSceneVarList = F_RjSortSceneVarList;
export { _F_RjSortSceneVarList as F_RjSortSceneVarList };
const _F_RjScheAction = F_RjScheAction;
export { _F_RjScheAction as F_RjScheAction };
const _F_RjGetFanSpeed = F_RjGetFanSpeed;
export { _F_RjGetFanSpeed as F_RjGetFanSpeed };
const _F_RjScheType = F_RjScheType;
export { _F_RjScheType as F_RjScheType };