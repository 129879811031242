import v2ControlSer from '../../service/V2_Control/V2_ControlSer';

async function getV2_GwPair(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_GwPair(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_GwPair(bdDev_id, gwid){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_GwPair(bdDev_id, gwid);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_LNodeDots(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_LNodeDots(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_LNodeDots(bdDev_id, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_LNodeDots(bdDev_id, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_LNodeParaPg(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_LNodeParaPg(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_LNodeParaPg(bdDev_id, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_LNodeParaPg(bdDev_id, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_CdwpModbusDev(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_CdwpModbusDev(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_CdwpModbusDev(bdDev_id, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_CdwpModbusDev(bdDev_id, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_CdwpVfdFreq(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_CdwpVfdFreq(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_CdwpVfdFreq(bdDev_id, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_CdwpVfdFreq(bdDev_id, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_CdwpHzThreshold(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_CdwpHzThreshold(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_CdwpHzThreshold(bdDev_id, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_CdwpHzThreshold(bdDev_id, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_CwpSetting(bdDev_id){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_CwpSetting(bdDev_id);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_CwpSetting(bdDev_id, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_CwpSetting(bdDev_id, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function getV2_MrEeprom_byFn(bdDev_id, fn){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.getV2_MrEeprom_byFn(bdDev_id, fn);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function setV2_MrEeprom(bdDev_id, fn, payload){    
    // console.log("Cone In");
    try {
        let rel = await v2ControlSer.setV2_MrEeprom(bdDev_id, fn, payload);        
        return rel.data;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

const expModule = {  
    getV2_GwPair,
    setV2_GwPair,
    getV2_LNodeDots,
    setV2_LNodeDots,
    getV2_LNodeParaPg,
    setV2_LNodeParaPg,
    getV2_CdwpModbusDev,
    setV2_CdwpModbusDev,
    getV2_CdwpVfdFreq,
    setV2_CdwpVfdFreq,
    getV2_CdwpHzThreshold,
    setV2_CdwpHzThreshold,
    getV2_CwpSetting,
    setV2_CwpSetting,
    getV2_MrEeprom_byFn,
    setV2_MrEeprom
};
export default expModule;