// import React from "react";
import moment from "moment";
import "moment-timezone";

function getUnixNow(){
  return moment().unix();
}

function getUnixNowForCRC(){
  return (moment().unix() - 1600000000);
}


function getUnixDate(unixTime) {
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format("YYYY-MM-DD");
}

function getUnixTime(unixTime, b12h, bWithSS) {
  let tss = "";
  if (bWithSS) tss = ":ss";
  if (b12h) return moment(unixTime*1000).tz("Asia/Kuala_Lumpur").format(`hh:mm${tss} A`);
  return moment(unixTime * 1000)
    .tz("Asia/Kuala_Lumpur")
    .format(`HH:mm${tss}`);
}

function cHH_mmToUnix(HHmm) {
  let sDate = moment().tz("Asia/Kuala_Lumpur").format("MM/DD/YYYY");
  // let sDate = getUnixDate(now().unix);
  return moment(`${sDate} ${HHmm}`).tz("Asia/Kuala_Lumpur").unix();
}

function cHH_mmToUnix_v2(HHmm) {
  let sDate = moment().tz("Asia/Kuala_Lumpur").format("MM/DD/YYYY");
  // let sDate = getUnixDate(now().unix);
  return moment(`${sDate} ${HHmm}`, 'MM/DD/YYYY HH:mm').tz("Asia/Kuala_Lumpur").unix();
}

function getUnix_from_Date_Time(sDate, sTime) {
  return moment(`${sDate} ${sTime}`).tz("Asia/Kuala_Lumpur").unix();
}

function getUnix_startDate(sDate) {
  return moment(`${sDate} 00:00`).tz("Asia/Kuala_Lumpur").unix();
}

function getUnix_endDate(sDate) {
  return moment(`${sDate} 24:00`).tz("Asia/Kuala_Lumpur").unix();
}

function getUnix_DateTimeLocal_UTC0(dateTimeString){
  return moment.utc(dateTimeString).unix();
}

function getStringInputTime_ByUnix_UTC0(unixTime){
  return moment.unix(unixTime).utc().format('YYYY-MM-DDTHH:mm');
}

function fGetTimeDiff_InDTMS(_start, _end){
  let start ;
  let end ;
  if(_start > _end){
      start = _end;
      end = _start;
  }else{
      start = _start;
      end = _end;
  }
  if(start === null) return "inf";
  let tDiff = end - start;
  let sTime = '';
  let _d = parseInt(tDiff/86400);
  let _remain = tDiff%86400;
  let _h = parseInt((_remain)/3600);
  _remain = _remain%3600;
  let _m = parseInt((_remain/60));
  _remain = _remain%60;
  // let _s = _remain;

  if (_d>0) sTime = `${_d}day `;
  // if (_d>1) sTime = sTime+`${_d}days `;
  if (_h>0) sTime = sTime+`${_h}hour `;
  if (_m>0) sTime = sTime+`${_m}min `;
  if(sTime ==='') sTime = '1min';
  // if (_s>0) sTime = sTime+`${_s}s `;
  return sTime

}

// function fGet_Dow(DOW, nDay){
//   getBitByIdx(DOW, nDay);
// }

function getDateTimeString(nUnixTime){
  return (moment.unix(nUnixTime).format('yyyy-MM-DD, HH:mm'));
}

function getDateTimeString_WithSeconds(nUnixTime){
  return (moment.unix(nUnixTime).format('yyyy-MM-DD, HH:mm:ss'));
}

function convertUnixToFormattedDateTime_UTC8(unixTimestamp, timezone='Asia/Kuala_Lumpur') {
  // Convert Unix timestamp to milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Use moment-timezone to format the date in the specified timezone
  const formattedDateTime = moment.tz(date, timezone).format('YYMMDD_HHmmss');

  return formattedDateTime;
}

const expModule = {
  fGetTimeDiff_InDTMS,
  getUnixNow,
  getUnixNowForCRC,
  cHH_mmToUnix,
  getUnix_startDate,
  getUnix_endDate,
  getUnixDate,
  getUnixTime,
  getUnix_from_Date_Time,
  cHH_mmToUnix_v2,
  getUnix_DateTimeLocal_UTC0,
  getStringInputTime_ByUnix_UTC0,
  getDateTimeString,
  getDateTimeString_WithSeconds,
  convertUnixToFormattedDateTime_UTC8
};
export default expModule;
