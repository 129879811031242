import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import UserService from "../../../service/auth/userService";
import InputValidation from "../../template/Form/inputValidation";
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';
import { toast } from 'react-toastify';

const ResetPasswordPage = () => {
  const { resetToken } = useParams();
  const [status, setStatus] = useState();
  // const [error, setError] = useState();
  // const {
  //   // eslint-disable-next-line
  //   inputs,
  //   setInputs,
  //   errors,
  //   // eslint-disable-next-line
  //   setErrors,
  //   validatePassword,
  //   validateCfmPassword,
  // } = InputValidation();
  // eslint-disable-next-line
  const history = useHistory();

  useEffect(()=>{
    async function startUp(){
      checkToken();
    }
    startUp();

    return ()=>{
        // alert("AddDevicePg Page Close");
    }
    // eslint-disable-next-line
  }, [resetToken]);

  const checkToken = async () => {
    try {
      let result = await UserService.checkResetLink(resetToken);
      // console.log(result);
      await setStatus(result.status);
    } catch (error) {
      console.log(error.message);
    }
  };

  // const handleChange = (input) => (e) => {
  //   setInputs({ [input]: e.target.value });
  // };

  const handleOnBlur = (errorId) => e => {
    // console.log(e.target);
    let error = InputValidation(e.target.id, e.target.value, document.getElementById("passInput").value);
    if(error === null) document.getElementById(errorId).innerHTML = "";
    if(error !== null) {
      // console.log(error);
      document.getElementById(errorId).innerHTML = error;
    }
  }

  const handleSubmit = async (e) => {
    if (document.getElementById("passErrId").innerHTML === ""
      && document.getElementById("cfmPassErrId").innerHTML === "")
      await doSubmit();
    // if (e) e.preventDefault();

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // console.log(errors);
    // if (errors) return;
    // if (!captchaStatus) {
    //   setCaptchaError("Please verify reCAPTCHA");
    //   return;
    // }
    // if (validatePassword && validateCfmPassword) doSubmit();
  };

  const doSubmit = async () => {
    try {
      let password = document.getElementById("passInput").value;
      let info = {};
      info.token = resetToken;
      info.password = password;
      let result = await UserService.resetPassword(info);
      // console.log(result);
      if (result.status === 203) {
        toast(result.data);
        return;
      }

      window.location = "/pwsuccess";
    } catch (ex) {
      console.log(ex);
      toast("Reset Password Error");
    }
  };

  return (
    <>
      {status === 200 && (
        <div className="form-container">
          <h2 className="form-title">Reset Password</h2>
          <div className="form-signin" onSubmit={handleSubmit}>
          <TpInputTitleAtBox onBlur={handleOnBlur("passErrId")} id='passInput' type="password" errMsgid='passErrId' title='New Password' maxLen={255}/>
          <TpInputTitleAtBox onBlur={handleOnBlur("cfmPassErrId")} id='cfmPassInput' type="password" errMsgid='cfmPassErrId' title='Confirm New Password' maxLen={255}/>
          <div className="buttonContainer" style={{marginTop:"40px"}}>
            <div className="roundButton" onClick={() => handleSubmit()}>Submit</div>
          </div>
            {/* <div className="form-card">
              <div className="flexVer">
                <label className="form-label">New Password</label>
                <input
                  className="form-input"
                  type="password"
                  id="password"
                  name="New Password"
                  onChange={handleChange("password")}
                  onBlur={validatePassword}
                ></input>
              </div>
              <div className="form-error">{errors.password}</div>
              <div className="flexVer">
                <label className="form-label">Confirm New Password</label>
                <input
                  className="form-input"
                  type="password"
                  id="cfmPassword"
                  name="Confirm New Password"
                  onChange={handleChange("cfmPassword")}
                  onBlur={validateCfmPassword}
                ></input>
              </div>
              <div className="form-error">{errors.cfmPassword}</div>
            </div> */}
            {/* <div align="center">
              <button className="form-button">Submit</button>
              <div className="form-error">{error}</div>
            </div> */}
          </div>
        </div>
      )}
      {status === 201 && (
        <div>
          Link has expired.
          <Link to="/user/login" className="form-inlink">
            Back to Login
          </Link>
        </div>
      )}
      {status === 202 && (
        <div>
          Link has been used. Please send a new request.
          <Link to="/user/login" className="form-inlink">
            Back to Login
          </Link>
        </div>
      )}
      {status === 203 && (
        <div>
          Link does not exist.
          <Link to="/user/login" className="form-inlink">
            Back to Login
          </Link>
        </div>
      )}
    </>
  );
};

export default ResetPasswordPage;
