import auth from '../service/auth/AuthV2'
import localStorageFn from "../function/localStorage/LocalStorageFn";
import OwnerFn from '../function/V2_SensorDevice/v2_SensorOwnerFn';
import SharedUserFn from '../function/V2_SensorDevice/v2_SensoSharedUserFn';

const C_shareUserLevel=[
    // {name:"Not granted", value:0},
    {name:"Viewer", value:31, accessRight:"Can View devices"},
    {name:"Admin", value:21, accessRight:"Can Edit, and View devices"},
    {name:"Owner", value:11, accessRight:"Full access to all devices"},
]

function getNextLvlObjBy_Val(nVal, curUserLevel){
    let foundIndex = C_shareUserLevel.findIndex((c)=>c.value===nVal);
    // console.log("foundIndex", foundIndex);
    if(foundIndex<0) return {}
    ++foundIndex;
    if(foundIndex>=C_shareUserLevel.length) foundIndex = 0;
    if(C_shareUserLevel[foundIndex].value < curUserLevel) return C_shareUserLevel[0];
    return C_shareUserLevel[foundIndex];

}

function getAccessLevelBy_Val(nVal){
    try {
        let match = C_shareUserLevel.find((c)=> c.value===nVal);
        if(!match) return null        
        return match;
    } catch (error) {
        return null
    }

}

async function getCurUserAccessLvl (){    
    let {user_id} = auth.getUserInfo();
    let bd_id = localStorageFn.getCurBd_id();
    let ownBdList = await OwnerFn.getOwnBdByUserId_bd_id(user_id, bd_id);
    // console.log("ownBdList", ownBdList);
    let ownThisBd = ownBdList.find((c)=>c._id === bd_id);
    if(ownThisBd) {
        // setG_userAccessLevel(1)
        return 1
    }
    let sharedRecord = await SharedUserFn.getSharedBd_ByUserId_Bd_idv2(user_id, bd_id);
    let minAcceLvl=1000;
    for (const eachRecord of sharedRecord) {
        if(eachRecord.accessLevel < minAcceLvl ) minAcceLvl = eachRecord.accessLevel;
    }
    return minAcceLvl
    // setG_userAccessLevel(minAcceLvl)
}



const expModule = {  
    getAccessLevelBy_Val,
    C_shareUserLevel,
    getNextLvlObjBy_Val,
    getCurUserAccessLvl
};

export default expModule;


// exports.getAccessLevelBy_Val=getAccessLevelBy_Val;
// exports.C_shareUserLevel = C_shareUserLevel;
// exports.getNextLvlObjBy_Val=getNextLvlObjBy_Val;
// exports.getCurUserAccessLvl=getCurUserAccessLvl;