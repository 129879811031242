import React from 'react';
import TpScCoolingTower from './../../Application/CondensorLoop/Template/SlaveControl/TpScCoolingTower';


const getSlaveControl=(nType, _devInfo, _masterInfo, _gwPair)=>{
    if(nType===38) return  <TpScCoolingTower devInfo ={_devInfo} masterInfo = {_masterInfo} gwPair = {_gwPair}/>
}



const expModule = {
    getSlaveControl,
};
export default expModule;
