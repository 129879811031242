import { createSelector, createSlice } from "@reduxjs/toolkit";
import timeFn from "../../component/utilities/timeFn";

const slice = createSlice({
    name:'dashDataList',
    initialState:{
        list:[]
    },
    reducers:{
        setNewList:(state, action)=>{
            let newList =[];
            for (const eachData of action.payload) {
                if (!Object.hasOwnProperty.call(eachData, "loadTime")) {
                    eachData.loadTime = timeFn.getUnixNow();                    
                }
                newList.push(eachData);
            }
            state.list = newList;
        },
        clearDashExpiredData:(state, action)=>{
            let validData = state.list.filter(c=>c.loadTime + action.payload > timeFn.getUnixNow());
            state.list = validData;
        }
    }
})


/** Export Action */
export const {
    setNewList,
    clearDashExpiredData,
} = slice.actions;


/** reducer */
export default slice.reducer;

/** Selector, read from store */
export const getFullDashDataList = createSelector(
    state=> state.dashboard.DashboardData,
    ({list}) => (list)
)

