import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
// import { useSelector } from "react-redux";
// import { getRjSceneList } from '../../../../../../reduxStore/actions/app_rj';
import { FaFan } from "react-icons/fa";
import { TbAngle } from "react-icons/tb";
import { MdOutlineCleaningServices } from "react-icons/md";
import { BsFillInfoCircleFill, BsToggleOff, BsToggleOn} from "react-icons/bs";
import { FiAlertCircle, FiEdit3, FiPlus } from "react-icons/fi";
import timeFn from '../../../../../../function/time/timeFn';
import { getBitByIdx } from "../../../../../../function/common/common"
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { swapArray } from '../../../../../../function/ArrayFn/arrayFn';

const TabScheduleList=forwardRef((props, ref) =>{
    useImperativeHandle(ref, () => ({
        saveSchedule() {
            if(props.saveChanges){
                props.saveChanges(G_ScheduleList);
            }
        //   fTriggerSendLoRa();
            // if(props.confirmSend) props.confirmSend();
        },
      }));

    // const Grdx_SceneList = useSelector(getRjSceneList);    
    const [G_ScheduleList, setG_ScheduleList] = useState([]);
    const [G_SceneList, setG_SceneList] = useState([]);
    const [G_SkipSchedule, setG_SkipSchedule] = useState(false);

    // const refPreventNew = useRef();
    
    useEffect(()=>{
        async function startUp(){            
            if(props.schelist){
                setG_ScheduleList(props.schelist);        
            }
            if(props.scenelist){
                setG_SceneList(props.scenelist);                              
            }
            if(props.skipSche){
                setG_SkipSchedule(props.skipSche);
            }
            
            // getLastData();
        }
        startUp();

        return ()=>{
            // alert("Page Close");
        }
        // eslint-disable-next-line
    }, []);    
    const fToggleSkipSche=()=>{
        setG_SkipSchedule(!G_SkipSchedule);
        if(props.toggleScheActive)  props.toggleScheActive(!G_SkipSchedule);
    }

    const getSceneName=(sceIdx)=>{
        let found = G_SceneList.find(c=>c.sceneIdx === sceIdx);
        if(!found)return ""
        return found.Name;
    }
    const getFanSpeed=(idx)=>{
        if(idx===0) return "Auto"
        return idx;
    }

    const fToEditPg=(scheSel, ind)=>{
        if(props.toEditSche){
            // dispatch(rjSetSelSche(scheSel));
            props.toEditSche(scheSel, ind);
        }        
    }

    const fAddNewScene=()=>{
        // if(refPreventNew.current) return;
        if(props.toEditSche_New){
            // dispatch(rjSetSelSche(scheSel));
            props.toEditSche_New();
        }     
    }

    const fShowScheAvailability=(scheInfo)=>{
        if(!scheInfo.active) return false;
        if(scheInfo.scheType===2){      // event type
            if(timeFn.getUnixNow() > scheInfo.startUnix) return false;
            
        }
        // if(scheInfo.)
        return true;
    }
    
    const fScheSwapUp=(index)=>{
        if(index === 0) return ;
        let _ScheduleList = JSON.parse(JSON.stringify(G_ScheduleList));
        let swappedArr = swapArray(_ScheduleList, index, index - 1);
        setG_ScheduleList(swappedArr);
        if(props.scheOnChange)  props.scheOnChange();
    }
    const fScheSwapDown=(index)=>{
        let _ScheduleList = JSON.parse(JSON.stringify(G_ScheduleList));
        if(index>=_ScheduleList.length-1) return 
        let swappedArr = swapArray(_ScheduleList, index, index + 1);
        setG_ScheduleList(swappedArr);
        if(props.scheOnChange)  props.scheOnChange();
    }

    return (
        <div className='spring_scheListPg'>
            <div className='spring_scheListContainer sortHor'>
                <div className='spring_scheActiveContainer sortHor'>
                    <div className='spring_scheActive_Text'>Schedule</div>
                    <div className='spring_scheActive_Icon flexAndBothCenter hoverPointer'
                    onClick={fToggleSkipSche}>
                        {G_SkipSchedule?<BsToggleOff/>:<BsToggleOn className='reactCfgButtonOn'/>}
                        </div>
                </div>
            </div>

            <div className={`${G_SkipSchedule?"divCollapse":""}`}>
                {G_ScheduleList.length >= 10 && <div className='spring_scheListContainer sortHor'>
                    <div className={`spring_scheListPriorityIcon divHidden`} ><FiAlertCircle/></div>
                    <div className='spring_scheListBar flexAndBothCenter' style={{padding:"3px 10px"}}
                    // onClick={fAddNewScene}
                    >
                        <div className='boldText'>{"Max 10 Schedules"}</div>
                        {/* <FiPlus className='' style={{margin:"0px 5px"}}/> */}
                    </div>
                    <div className='spring_scheListEdit divHidden'><FiEdit3/></div>
                </div>}
                {G_ScheduleList.length < 10 && <div className='spring_scheListContainer sortHor'>
                    <div className={`spring_scheListPriorityIcon divHidden`} ><FiAlertCircle/></div>
                    <div className='spring_scheListBar flexAndBothCenter hoverPointer' style={{padding:"3px 10px"}}
                    onClick={fAddNewScene}>
                        <div className='boldText'>{"New"}</div>
                        <FiPlus className='' style={{margin:"0px 5px"}}/>
                    </div>
                    <div className='spring_scheListEdit divHidden'><FiEdit3/></div>
                </div>}

                {G_ScheduleList.map((c, ind)=>
                <div className='spring_scheListContainer sortHor' key={`scheListKey_${ind}`}>
                    <div>
                        <div className={`spring_scheListPriorityIcon spring_colorYellow hoverPointer`} 
                        onClick={()=>fScheSwapUp(ind)}>
                            <FaArrowUp/>
                        </div>
                        <div className={`spring_scheListPriorityIcon ${c.priority>0?"":"divHidden"}`} >
                            <FiAlertCircle/>
                        </div>
                        <div className={`spring_scheListPriorityIcon spring_colorBlue hoverPointer`} 
                        onClick={()=>fScheSwapDown(ind)}>
                            <FaArrowDown/>
                            
                        </div>
                    </div>
                    <div className={`spring_scheListBar ${fShowScheAvailability(c)?"":"spring_greyoutBar"}`}>
                        <div style={{flex:"8"}} className="">
                            <div className='spring_scheListName'>{c.name}</div>
                            <div className='sortHor spring_scheListInfo '>
                                <div className='spring_scheListTime'>{`${timeFn.getUnixTime(c.startUnix)} ,`}</div>
                                <div className='sortHor spring_scheListAction divRelative'>
                                    {(c.action === 1) && <div className='spring_scheListSP'>
                                        {`${parseFloat(c.Setpoint).toFixed(1)}°C`}
                                    </div>}
                                    {(c.action === 2) && <div className='spring_scheListSP sortHor'>
                                        <div>
                                            {`${parseFloat(c.Setpoint).toFixed(1)}°C`}
                                        </div>
                                        <BsFillInfoCircleFill className='spring_scheListIctrlIcon spring_acGreen'/>
                                    </div>}
                                    {(c.action === 3) && <div className='spring_scheListScene wrapTextEllipsis'>
                                        {/* spring_scheListScene */}
                                        {`${getSceneName(c.sceneIdx)}`}
                                    </div>}
                                    {(c.action === 4) && <div className='spring_scheListScene'>
                                        {`OFF`}
                                    </div>}
                                    
                                    {(c.action === 5) && <div className='spring_scheListSP sortHor'>
                                        <div>
                                            {`OFF`}
                                        </div>
                                        <MdOutlineCleaningServices className='spring_scheListIctrlIcon spring_acGreen'/>
                                    </div>}
                                </div>
                            </div>
                            <div className='spring_scheListBtmBar'>
                                {c.scheType === 1 && <div className='sortHor'>
                                    <div className={`spring_ScheList_DowBox spring_SL_Weekend`}>
                                        {getBitByIdx(c.DOW, 0) && <div className='spring_RedDot'></div>}<div>S</div>
                                    </div>
                                    <div className={`spring_ScheList_DowBox`}>
                                        {getBitByIdx(c.DOW, 1) && <div className='spring_RedDot'></div>}<div>M</div>
                                    </div>                                
                                    <div className={`spring_ScheList_DowBox`}>
                                        {getBitByIdx(c.DOW, 2) && <div className='spring_RedDot'></div>}<div>T</div>
                                    </div>
                                    <div className={`spring_ScheList_DowBox`}>
                                        {getBitByIdx(c.DOW, 3) && <div className='spring_RedDot'></div>}<div>W</div>
                                    </div>
                                    <div className={`spring_ScheList_DowBox`}>
                                        {getBitByIdx(c.DOW, 4) && <div className='spring_RedDot'></div>}<div>T</div>
                                    </div>
                                    <div className={`spring_ScheList_DowBox`}>
                                        {getBitByIdx(c.DOW, 5) && <div className='spring_RedDot'></div>}<div>F</div>
                                    </div>
                                    <div className={`spring_ScheList_DowBox spring_SL_Weekend`}>
                                        {getBitByIdx(c.DOW, 6) && <div className='spring_RedDot'></div>}<div>S</div>
                                    </div>
                                </div>}
                                {c.scheType === 2 && 
                                    <div className='spring_scheListEventDate'>
                                        {timeFn.getUnixDate(c.startUnix)}
                                    </div>}
                            </div>
                        </div>
                        {(c.action <= 2) && <div style={{flex:"3"}}>               
                            <div className={`spring_scheListAux sortVer ${((c.action > 2)?"divHidden":"" )}`}>
                                {/* <div>dasd</div>
                                <div>sdas</div> */}
                                <div className='sortHor spring_scheListFan'>
                                    <FaFan className='spring_scheListSmallIcon'/>
                                    <div className='spring_scheListIconVal'>{getFanSpeed(c.Fan)}</div>
                                </div>
                                <div className='sortHor spring_scheListSwing'>
                                    <TbAngle className='spring_scheListSmallIcon'/>
                                    <div className='spring_scheListIconVal'>{getFanSpeed(c.Swing)}</div>
                                </div>
                                {/* {c.action === 2 && <div className='sortHor spring_scheListSwing'>
                                    <BsFillInfoCircleFill className='spring_scheListSmallIcon spring_acGreen'/>
                                    <div className='spring_scheListIconVal divHidden '>1</div>
                                </div>} */}
                            </div>
                        </div>}
                    </div>
                    <div className='spring_scheListEdit hoverPointer'
                    onClick={()=>fToEditPg(c, ind)}>
                        <FiEdit3/>
                    </div>
                </div>
                )} 
                {G_ScheduleList.length < 10 && <div className='spring_scheListContainer sortHor'
                style={{marginBottom:"20px"}}>
                    <div className={`spring_scheListPriorityIcon divHidden`} ><FiAlertCircle/></div>
                    <div className='spring_scheListBar flexAndBothCenter hoverPointer' style={{padding:"3px 10px"}}
                    onClick={fAddNewScene}>
                        <div className='boldText'>{"New"}</div>
                        <FiPlus className='' style={{margin:"0px 5px"}}/>
                    </div>
                    <div className='spring_scheListEdit divHidden'><FiEdit3/></div>
                </div>}
                
                {G_ScheduleList.length >= 10 && <div className='spring_scheListContainer sortHor'
                style={{marginBottom:"20px"}}>
                    <div className={`spring_scheListPriorityIcon divHidden`} ><FiAlertCircle/></div>
                    <div className='spring_scheListBar flexAndBothCenter' style={{padding:"3px 10px"}}
                    // onClick={fAddNewScene}
                    >
                        <div className='boldText'>{"Max 10 Schedules"}</div>
                        {/* <FiPlus className='' style={{margin:"0px 5px"}}/> */}
                    </div>
                    <div className='spring_scheListEdit divHidden'><FiEdit3/></div>
                </div>}

            </div>
        </div>
    );
});

export default TabScheduleList;