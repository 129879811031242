import React, { useEffect, useState } from 'react';

function TpSubSubDiaVarChoosePara(props) {

    const [G_ParaDisplay, setG_ParaDisplay] = useState([]); 

    
    useEffect(()=>{
        async function startUp(){
            if (props.paraList) setG_ParaDisplay(props.paraList);
            
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=(varInfo)=>{
        if(props.choosePara) {
            props.choosePara(varInfo);
        }
    }
    

    return (
        <div > 
            <div className='spring_subDialogBoxContainer' style={{zIndex:"200"}}>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox '>
                    <div className='spring_diaFixHeight'>
                        <div className='spring_ruleVarListContainer'>
                            {G_ParaDisplay.map((c, ind)=>
                                <div key={`varKey_${ind}`} className="spring_editVarBox hoverPointer wrapTextEllipsis"
                                onClick={()=>handleConfirm(c)}
                                >{`${c.dataName}`}</div>
                            )} 
                        </div>          
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Back
                        </div>
                        {/* <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div> */}
                    </div> 
                </div>

            </div>
        </div>
    );
}

export default TpSubSubDiaVarChoosePara;