import React from 'react'
import DashCancelConfirmButton from './../ComponentTemplate/Common/Common/DashCancelConfirmButton';

const C0004Edit = () => {
  return (
    <div>
      C0004Edit
      <DashCancelConfirmButton/>
    </div>
  )
}

export default C0004Edit
