import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
// eslint-disable-next-line
import { Line } from "react-chartjs-2";
import { FiPlus, FiMinus } from "react-icons/fi";
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import ownerSensorFn from "../../../../../function/V2_SensorDevice/v2_SensorOwnerFn";
import v2QueryFn from '../../../../../function/V2_Query/V2_QueryBdDevData';
import timeFn from "../../../../../component/utilities/timeFn";
import { toast } from 'react-toastify';
// eslint-disable-next-line
// import TpCheckbox from "../../../../template/checkBox/TpCheckbox";
// eslint-disable-next-line
// import auth from '../../../service/auth/AuthV2';
// eslint-disable-next-line
// import bdDevSettingFn from "../../../function/V2_SensorDevice/v2_bdDevSettingFn";
import SensorMngtFn from '../../../../../function/sensor/sensorManagementFn';
// eslint-disable-next-line
// import TpSmallInputTitleAtBox from '../../template/inputField/SmallInput_titleAtBox';
import moment from "moment";
import TpLineGraph2 from '../../../../template/v2Graph/TpLineGraph2';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { isEmptyArr } from '../../../../../function/ArrayFn/arrayFn';
// eslint-disable-next-line
// import { BsGraphUp } from "react-icons/bs";

function TpCompareGraph(props) {
    const [G_loaded, setG_loaded] = useState(false);
    const [G_firstLoaded, setG_firstLoaded] = useState(false);
    // eslint-disable-next-line
    const [G_ovvData, setG_ovvData] = useState([]);
    const [G_disLines, setG_disLines] = useState([]);
    /** separate dev info and para info */
    /** separate another state for selected para */
    const [G_devInfoList, setG_devInfoList] = useState([]);
    const [G_paraInfoList, setG_paraInfoList] = useState([]);
    // const [G_selParaList, setG_selParaList] = useState([]);
    const [G_totalLine, setG_totalLine] = useState(0);
    const [G_duration, setG_duration] = useState(60*24);
    const [G_customize, setG_customize] = useState(false);
    const [G_startTime, setG_startTime] = useState(0);
    const [G_endTime, setG_endTime] = useState(0);
    const [G_unit, setG_unit] = useState("hour");
    const [G_loading, setG_loading] = useState(true);
    const [G_defaultData, setG_defaultData] = useState({});
    const [G_loadedDuration, setG_loadedDuration] = useState(10080);
    const [G_leftScaleValue, setG_leftScaleValue] = useState({min: null, max: null});
    const [G_rightScaleValue, setG_rightScaleValue] = useState({min: null, max: null});
    const [G_userSetting, setG_userSetting] = useState(1);
    const [G_zoomLimit, setG_zoomLimit] = useState({min: null, max: null});
    
    const maxLine = 10;

    useEffect(() => {
        async function startUp() {
            if(!G_firstLoaded) {
                /** load bddev list */
                let bdDevList = localStorageFn.getWatchList();
                // console.log(bdDevList);
                if(bdDevList === null) return;
                if(isEmptyArr(bdDevList._id)) return;

                /** load bddev info */
                let bdDev_info = await ownerSensorFn.getBdDev_By_bdDevIdList(bdDevList._id);
                // console.log(bdDev_info);
                if(isEmptyArr(bdDev_info))  return;

                let _devInfo = genDeviceList(bdDev_info);
                let _paraInfo = await genParaInfoList(bdDev_info);
                let _defData = await genDefaultData(bdDev_info, 10080);   // 1 week data
                // console.log(_defData);
                if(bdDev_info.length > 1) setG_totalLine(2);
                else setG_totalLine(bdDev_info.length);
                setG_devInfoList(_devInfo);
                setG_paraInfoList(_paraInfo);
                setG_defaultData(_defData);
                setG_firstLoaded(true);
            }

            await reloadData();

            setG_loaded(true);
            setG_loading(false);
        }

        startUp();
        // eslint-disable-next-line
    }, [G_loaded, G_duration, G_startTime, G_endTime]);

    const reloadData = async () => {
        // if(!firstLoaded) await firstLoad();
        // console.log("reload");
        // setLoading(true);
        if(G_firstLoaded) {
            // console.log("no first load");
            let _currData = {}, _defData, _loadedDuration;
            if(G_duration > G_loadedDuration || 
                (G_customize && moment(G_startTime).unix() < (moment().unix() - (G_loadedDuration*60))) ||
                G_totalLine > Object.keys(G_defaultData).length) {
                    // reload data
                    // console.log("reload data");
                if(G_customize) {
                    /** customize */
                    _defData = await genDefaultData_T1_T2(G_devInfoList, G_startTime, G_endTime);
                    _loadedDuration = (moment().unix() - moment(G_startTime).unix()) / 60;
                } else {
                    /** no customize */
                    _defData = await genDefaultData(G_devInfoList, G_duration);
                    _loadedDuration = G_duration;
                }
                    _currData = _defData;
            } else {
                // no reload data
                // console.log("no reload data");
                for(const devInfo of G_devInfoList) {
                    if(G_customize) {
                        /** customize */
                        _currData[devInfo._id] = G_defaultData[devInfo._id].filter((c) => 
                            c.unix >= moment(G_startTime).unix() && c.unix <= moment(G_endTime).unix());
                    } else {
                         /** no customize */
                         _currData[devInfo._id] = G_defaultData[devInfo._id].filter(c => c.unix > (moment().unix() - (G_duration*60)));
                    }
                    // console.log(_currData[devInfo._id]);
                }
            }

            // console.log(_currData);
            // console.log(_loadedDuration);
            /** getOvvData */
            let _ovvData = genOvvData(_currData);
            // console.log(_ovvData);
            /** getDisLines */
            let _disLines = genDisLines();
            /** getMinMax */
            let _allMinMax = setMinMaxValue(_ovvData, _disLines);
            /** getZoomLimit */
            let _zoomLimit = getZoomLimit(_ovvData);
            /** genStartEndTime */
            let _startEndTime = genStartEndTime(_ovvData);
            /** getUnit */
            let _unit = getUnit(_ovvData);
            /** all setState here */
            setG_ovvData(_ovvData);
            setG_disLines(_disLines);
            setG_leftScaleValue(_allMinMax[0]);
            setG_rightScaleValue(_allMinMax[1]);
            setG_zoomLimit(_zoomLimit);
            if(!G_customize && G_startTime !== _startEndTime[0]) setG_startTime(_startEndTime[0]);
            if(!G_customize && G_endTime !== _startEndTime[1]) setG_endTime(_startEndTime[1]);
            if(_unit) setG_unit(_unit);
            if(_defData) setG_defaultData(_defData);
            if(_loadedDuration) setG_loadedDuration(_loadedDuration);
            // setG_ovvData(ovvData);
            // setG_disLines(disLines);
        }

        // console.log(Object.keys(lnColDis).length);
        // setLoaded(true);
        // setLoading(false);
    }

    const genDeviceList = (bdDev_info) => {
        // console.log(bdDev_info);
        // console.log("gendevicelist");
        let devInfoList = [];
        // let viewList = [];
        // let {user_id} = auth.getUserInfo();
        // console.log(user_id);
        // let iteration = 0;
        for(const bddev of bdDev_info) {
            devInfoList.push({_id: bddev._id, name: bddev.name, type: bddev.type, show: false });
        }
        return devInfoList;
    }

    const genParaInfoList = async (bdDev_info) => {
        // console.log("genParaList");
        let _paraInfoList = [];
        // let viewList = [];
        // let {user_id} = auth.getUserInfo();
        // console.log(user_id);
        let iteration = 0;
        for(const bddev of bdDev_info) {
            let lastData = await v2QueryFn.v2GetData_LastN(bddev.type, bddev._id, 1);
            // console.log(lastData);
            let defParaList = await SensorMngtFn.getParaByTypeList([bddev.type]);
            // console.log(defParaList);
            // let selParaList = [];
            let idx = 0;
            for(const defPara of defParaList) {
                _paraInfoList.push({
                    _id: bddev._id,
                    title: defPara.dataName,
                    dataKey: defPara.dataIndex === -1? defPara.dataType : `${defPara.dataType}_${defPara.dataIndex}`,
                    unit: defPara.dataUnit,
                    checked: (iteration === 0 || iteration === 1) && idx === 0 ? true : false,
                    leftScale: (iteration === 0 || iteration === 1) && idx === 0 && bddev.type === bdDev_info[0].type ? true : false,
                    rightScale: iteration === 1 && idx === 0 && bddev.type !== bdDev_info[0].type? true : false,
                    // min: undefined,
                    // max: undefined
                });
                idx++;
            }
            if(lastData[0].gwID){
                _paraInfoList.push({
                    _id: bddev._id,
                    title: "Gw ID",
                    dataKey : "gwID",
                    unit: "",
                    checked: false,
                    leftScale: false,
                    rightScale: false,
                    // min: undefined,
                    // max: undefined
                })
            }
            if(lastData[0].frequency){
                _paraInfoList.push({
                    _id: bddev._id,
                    title: "Freq.",
                    dataKey : "frequency",
                    unit: "(Mhz)",
                    checked: false,
                    leftScale: false,
                    rightScale: false,
                    // min: undefined,
                    // max: undefined
                })
            }
            // }
            // devInfoList.push({_id: bddev._id, name: bddev.name, type: bddev.type, para: paraList, show: false });
            iteration++;
        }
        // console.log(_paraInfoList);
        return _paraInfoList;
    }

    // const genDefaultSelParaList = (_allParaList) => {
    //     let _selParaList = [];
    //     for(const para of _allParaList) {
    //         if(_selParaList.length === 0) {
    //             para.scale = "left";
    //             _selParaList.push(para);
    //         } else if(_selParaList.length === 1 && _selParaList[0]._id !== para._id) {
    //             para.scale = "right";
    //             _selParaList.push(para);
    //             break;
    //         }
    //     }
    //     return _selParaList;
    // }

    const genDefaultData = async (bdDev_info, defDuration) => {
        // console.log("gendefaultdata");
        let defData = {};
        for (const bdDev of bdDev_info) {
            let temp = await v2QueryFn.v2GetnMinB4nUnix(bdDev.type, bdDev._id, timeFn.getUnixNow(), defDuration);
            // console.log(temp);
            defData[bdDev._id] = temp;
        }
        // console.log(defData);
        return defData;
        // setG_defaultData(defData);
    }

    const genDefaultData_T1_T2 = async (bdDev_info, t1, t2) => {
        // console.log("gendefaultdata_t1_t2");
        let defData = {};
        for (const bdDev of bdDev_info) {
            let temp = await v2QueryFn.v2GetBdDevData_T1_T2(bdDev.type, bdDev._id, moment(t1).unix(), moment(t2).unix());
            // console.log(temp);
            defData[bdDev._id] = temp;
        }
        // console.log(defData);
        return defData;
        // setG_defaultData(defData);
    }

    const genOvvData = (currData) => {
        let _ovvData = {};
        let _paraInfoList = [...G_paraInfoList];
        for(const paraInfo of _paraInfoList) {
            if(paraInfo.checked) {
                if(currData[paraInfo._id].length === 0)
                    _ovvData[`${paraInfo.dataKey}-${paraInfo._id}`] = [];
                else {
                    for (const eachData of currData[paraInfo._id]) {
                        let singleData = {x:eachData.unix*1000, y: eachData[paraInfo.dataKey]};
                        if(!Array.isArray(_ovvData[`${paraInfo.dataKey}-${paraInfo._id}`])) _ovvData[`${paraInfo.dataKey}-${paraInfo._id}`]=[singleData];
                        else _ovvData[`${paraInfo.dataKey}-${paraInfo._id}`].push(singleData);
                    }
                }
            }
        }
        return _ovvData;
    }

    const genDisLines = () => {
        let _disLines = [];
        for(const paraInfo of G_paraInfoList) {
           if(paraInfo.checked) {
                let _currDev = G_devInfoList.filter(c => c._id === paraInfo._id);
                // console.log(_currDev);
                _disLines.push({
                    name: `${_currDev[0].name}`,
                    title: `${paraInfo.title}_${paraInfo._id}`,
                    dataKey: `${paraInfo.dataKey}-${paraInfo._id}`,
                    scale: paraInfo.leftScale? 'left' : 'right'
                });
            }
        }
        return _disLines;
    }

    const genStartEndTime = (ovvData) => {
        let _starttime, _endtime;
        for(const data in ovvData) {
            // console.log(data);
            if(ovvData[data].length > 0) {
                let _currStart = moment.unix(ovvData[data][ovvData[data].length - 1].x/1000).format(moment.HTML5_FMT.DATETIME_LOCAL);
                let _currEnd = moment.unix(ovvData[data][0].x/1000).format(moment.HTML5_FMT.DATETIME_LOCAL);
                if(!_starttime || _starttime > _currStart)
                    _starttime = _currStart;
                if(!_endtime || _endtime < _currEnd)
                    _endtime = _currEnd;
            }
        }
        // let _starttime = moment.unix(ovvData[ovvData.length - 1].unix).format(moment.HTML5_FMT.DATETIME_LOCAL);
        // let _endtime = moment.unix(ovvData[0].unix).format(moment.HTML5_FMT.DATETIME_LOCAL);
        // console.log(_starttime);
        // console.log(_endtime);
        return [_starttime, _endtime];
    }

    const getUnit = (ovvData) => {
        let _unit, _ttlTime;
        for(const data in ovvData) {
            if(ovvData[data].length > 0) {
                let _currTtl = (ovvData[data][0].x - ovvData[data][ovvData[data].length - 1].x) / 60000;
                if(!_ttlTime || _currTtl > _ttlTime) _ttlTime = _currTtl;
            }
        }
        // let ttlTime = (ovvData[0].unix - ovvData[ovvData.length - 1].unix) / 60;
        // console.log(ttlTime);
        if(_ttlTime > 24*60) _unit = "day";
        else if(_ttlTime > 60) _unit = "hour";
        else _unit = "minute";
        
        return _unit;
    }
    
    // const genGraphData = async () => {
    //     // console.log("gengraphdata");
    //     // console.log(totalLine);
    //     // console.log(devInfoList);
    //     // console.log(G_duration);
    //     // console.log(G_loadedDuration);
    //     // console.log(G_defaultData);
    //     let ovvData = [];
    //     let disLines = [];
    //     let tempDevInfo = [...G_devInfoList];
    //     // let currLeft = {min: null, max: null};
    //     // let currRight = {min: null, max: null};
    //     for(const bdDev of tempDevInfo) {
    //         let currData;
    //         if(G_duration > G_loadedDuration || (G_customize && moment(G_startTime).unix() < (moment().unix() - (G_loadedDuration*60))) || G_totalLine > Object.keys(G_defaultData).length) {
    //             // console.log("reload all data");
    //             if(G_customize) currData = await v2QueryFn.v2GetBdDevData_T1_T2(bdDev.type, bdDev._id, moment(G_startTime).unix(), moment(G_endTime).unix());
    //             else {
    //                 setG_loadedDuration(G_duration);
    //                 let defaults = {...G_defaultData};
    //                 currData = await v2QueryFn.v2GetnMinB4nUnix(bdDev.type, bdDev._id, timeFn.getUnixNow(), G_duration);
    //                 defaults[bdDev._id] = currData;
    //                 setG_defaultData(defaults);
    //             }
    //         } else {
    //             console.log("no reload data");
    //             if(G_customize) {
    //                 currData = G_defaultData[bdDev._id].filter((c) => 
    //                     c.unix >= moment(G_startTime).unix() && c.unix <= moment(G_endTime).unix());
    //             } else currData = G_defaultData[bdDev._id].filter(c => c.unix > (moment().unix() - (G_duration*60)));
    //             // console.log(moment().unix() - (duration*60));   
    //         }

    //         console.log(currData);
    //         if(currData.length) {
    //             // console.log(moment.unix(currData[0].unix).format(moment.HTML5_FMT.DATETIME_LOCAL));
    //             // console.log(currData[0].unix);
    //             let starttime = moment.unix(currData[currData.length - 1].unix).format(moment.HTML5_FMT.DATETIME_LOCAL);
    //             let endtime = moment.unix(currData[0].unix).format(moment.HTML5_FMT.DATETIME_LOCAL);
    //             let ttlTime = (currData[0].unix - currData[currData.length - 1].unix) / 60;
    //             console.log(ttlTime);
    //             if(ttlTime > 24*60) setG_unit("day");
    //             else if(ttlTime > 60) setG_unit("hour");
    //             else setG_unit("minute");
    //             // console.log(starttime);
    //             // console.log(endtime);
    //             if(!G_startTime || starttime < G_startTime) {
    //                 setG_startTime(starttime);
    //             }

    //             if(!G_endTime || endtime > G_endTime) {
    //                 setG_endTime(endtime);
    //             }
    //             // setStartTime(currData[currData.length - 1].timestamp > startTime? startTime : currData[currData.length - 1].timestamp);
    //             // setEndTime(currData[0].timestamp < endTime? endTime : currData[0].timestamp);
    //         }
    //         for(const para of bdDev.para) {
    //             if(para.checked) {
    //                 for (const eachData of currData) {
    //                     // if(para.leftScale) {
    //                     //     if(currLeft.min === null || eachData[para.dataKey] < currLeft.min) currLeft.min = eachData[para.dataKey];
    //                     //     if(currLeft.max === null || eachData[para.dataKey] > currLeft.max) currLeft.max = eachData[para.dataKey];
    //                     //     if(!loaded) {
    //                     //         para.min = currLeft.min;
    //                     //         para.max = currLeft.max;
    //                     //     }
    //                     // }
    //                     // if(para.rightScale) {
    //                     //     if(currRight.min === null || eachData[para.dataKey] < currRight.min) currRight.min = eachData[para.dataKey];
    //                     //     if(currRight.max === null || eachData[para.dataKey] > currRight.max) currRight.max = eachData[para.dataKey];
    //                     //     if(!loaded) {
    //                     //         para.min = currRight.min;
    //                     //         para.max = currRight.max;
    //                     //     }
    //                     // }
    //                     let singleData = {x:eachData.unix*1000, y: eachData[para.dataKey]};
    //                     if(!Array.isArray(ovvData[`${para.dataKey}_${bdDev._id}`])) ovvData[`${para.dataKey}_${bdDev._id}`]=[singleData];
    //                     else ovvData[`${para.dataKey}_${bdDev._id}`].push(singleData);
    //                 }
    //                 disLines.push({ name: `${bdDev.name}`, title: `${para.title}_${bdDev._id}`, dataKey: `${para.dataKey}_${bdDev._id}`});
    //             }
    //         }
            
    //     }
    //     console.log(ovvData);
    //     setG_ovvData(ovvData);
    //     console.log(disLines);
    //     // console.log(disLines[0].title.split("_")[0]);
    //     setG_disLines(disLines);
    //     // console.log("loaded", loading);
    //     // if(loading) setMinMaxValue();
    //     // console.log(currMin);
    //     // console.log(currMax);
    //     // setLeftScaleValue(currLeft);
    //     // setRightScaleValue(currRight);
    //     // console.log(startTime);
    //     // console.log(endTime);
    //     // let dataset = genChartData(ovvData, disLines);
    //     // let graphOptions = genOptions();
    //     // console.log(dataset);
    //     // setGraphData(dataset);
    //     // setGraphOptions(graphOptions);
    //     return ovvData;
    // }

    const getZoomLimit = (ovvData) => {
        // console.log("get zoom limit");
        let _zoomLimit = {min: null, max: null};
        for(const data in ovvData) {
            // console.log(data);
            if(ovvData[data].length > 0) {
                if(_zoomLimit.min === null || _zoomLimit.min > ovvData[data][ovvData[data].length - 1].x)
                    _zoomLimit.min = ovvData[data][ovvData[data].length - 1].x;
                if(_zoomLimit.max === null || _zoomLimit.max < ovvData[data][0].x)
                    _zoomLimit.max = ovvData[data][0].x;
            }
        }
        // console.log(_zoomLimit);
        return _zoomLimit;
    }

    const setMinMaxValue = (ovvData, disLines) => {
        // console.log("setminmax");
        // console.log(Object.keys(ovvData).length);
        // console.log(G_totalLine);
        // let tempList = [...G_devInfoList];
        // let _leftScale = [...G_leftScaleValue];
        // let _rightScale = [...G_rightScaleValue];
        let currLeft = {min: null, max: null};
        let currRight = {min: null, max: null};
        // let lsPos = [];
        // let rsPos = [];
        // console.log(ovvData);
        // console.log(tempList);

        for(const line of disLines) {
            // console.log(line);
            if(ovvData[line.dataKey].length === 0) continue;
            let max = Math.max(...ovvData[line.dataKey].map(o => o.y));
            let min = Math.min(...ovvData[line.dataKey].map(o => o.y));
            // console.log(max, min);
            if(line.scale === 'left') {
                if(currLeft.max === null || max > currLeft.max) currLeft.max = Math.ceil(max);
                if(currLeft.min === null || min < currLeft.min) currLeft.min = Math.floor(min);
            } else if(line.scale === 'right') {
                if(currRight.max === null || max > currRight.max) currRight.max = Math.ceil(max + 1);
                if(currRight.min === null || min < currRight.min) currRight.min = Math.floor(min - 1);
            }
        }

        // console.log(currLeft);
        // console.log(currRight);

        // if(currLeft.min === null) currLeft = G_leftScaleValue;
        // if(currRight.min === null) currRight = G_rightScaleValue;
    
        // for(let i = 0; i < tempList.length; i++) {
        //     for(let j = 0; j < tempList[i].para.length; j++) {
        //         if(ovvData[`${tempList[i].para[j].dataKey}_${tempList[i]._id}`]) {
        //             let max = Math.max(...ovvData[`${tempList[i].para[j].dataKey}_${tempList[i]._id}`].map(o => o.y));
        //             // console.log(max);
        //             let min = Math.min(...ovvData[`${tempList[i].para[j].dataKey}_${tempList[i]._id}`].map(o => o.y));
        //             // console.log(min);
        //             if(tempList[i].para[j].leftScale) {
        //                 // let elementClass = document.getElementById(`${tempList[i]._id}_${tempList[i].para[j]}_left`).classList;
        //                 // elementClass.remove("icon-deselect");
        //                 if(currLeft.max === null || max > currLeft.max) currLeft.max = Math.ceil(max);
        //                 if(currLeft.min === null || min < currLeft.min) currLeft.min = Math.floor(min);
        //                 lsPos.push({pos1: i, pos2: j});
        //                     // para.min = currLeft.min;
        //                     // para.max = currLeft.max;
        //                     // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currLeft.min;
        //                     // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currLeft.max;
        //             }
        //             if(tempList[i].para[j].rightScale) {
        //                 // let elementClass = document.getElementById(`${tempList[i]._id}_${tempList[i].para[j]}_right`).classList;
        //                 // elementClass.remove("icon-deselect");
        //                 if(currRight.max === null || max > currRight.max) currRight.max = Math.ceil(max + 1);
        //                 if(currRight.min === null || min < currRight.min) currRight.min = Math.floor(min - 1);
        //                 rsPos.push({pos1: i, pos2: j});
        //                     // para.min = currRight.min;
        //                     // para.max = currRight.max;
        //                     // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currRight.min;
        //                     // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currRight.max;
        //             }
        //         }
        //     }
        // }
        //     // console.log(lsPos);
        //     // console.log(rsPos);
        // let diffLeft, diffRight;
        // if(currLeft.min !== null && currLeft.max !== null) {
        //     if(currLeft.max - currLeft.min > 1000) diffLeft = 100;
        //     else if(currLeft.max - currLeft.min > 100) diffLeft = 10;
        //     else if(currLeft.max - currLeft.min > 50) diffLeft = 5;
        //     else diffLeft = 1;

        //     currLeft.max += diffLeft;
        //     currLeft.min -= diffLeft;
        // }
        // if(currRight.max !== null && currRight.min !== null) {
        //     if(currRight.max - currRight.min > 1000) diffRight = 100;
        //     else if(currRight.max - currRight.min > 100) diffRight = 10;
        //     else if(currRight.max - currRight.min > 50) diffRight = 5;
        //     else diffRight = 1;

        //     currRight.max += diffRight;
        //     currRight.min -= diffRight;
        // }

        // for(const ls of lsPos) {
        //     tempList[ls.pos1].para[ls.pos2].min = currLeft.min;
        //     tempList[ls.pos1].para[ls.pos2].max = currLeft.max;
        //     // document.getElementById(`${tempList[ls.pos1]._id}_${tempList[ls.pos1].para[ls.pos2].dataKey}_min`).value = currLeft.min;
        //     // document.getElementById(`${tempList[ls.pos1]._id}_${tempList[ls.pos1].para[ls.pos2].dataKey}_max`).value = currLeft.max;
        // }
    
        // for(const rs of rsPos) {
        //     tempList[rs.pos1].para[rs.pos2].min = currRight.min;
        //     tempList[rs.pos1].para[rs.pos2].max = currRight.max;
        //     // document.getElementById(`${tempList[rs.pos1]._id}_${tempList[rs.pos1].para[rs.pos2].dataKey}_min`).value = currRight.min;
        //     // document.getElementById(`${tempList[rs.pos1]._id}_${tempList[rs.pos1].para[rs.pos2].dataKey}_max`).value = currRight.max;
        // }
        // document.getElementById("left_Max").value = currLeft.max;
        // document.getElementById("right_Max").value = currRight.max;
        // document.getElementById("left_Min").value = currLeft.min;
        // document.getElementById("right_Min").value = currRight.min;
            // console.log(tempList);

        // console.log(currLeft);
        // console.log(currRight);
        return [currLeft, currRight];
        // setG_leftScaleValue(currLeft);
        // setG_rightScaleValue(currRight);
        // setG_devInfoList(tempList);
    }

    // const genLineInd_ForUi = (ovvData) =>{
    //     let lnColDis = [];
    //     let ctr = 1;
    //     console.log(ovvData);
    //     for (const eachLn in ovvData) {
    //         console.log(eachLn);
    //         lnColDis[eachLn] = ctr;
    //         ctr++;
    //     }
    //     console.log(lnColDis);
    //     return lnColDis;
    // }

    const backPrevPg = () => {
        props.history.push({
            pathname: "/data/sensorinbd",
        });
    }

    const handleCheck = async (param) => {
        if(!param.checked && G_totalLine === maxLine) {
            toast("Only 10 Lines Allowed");
            return;
        }
        if(param.checked) setG_totalLine(G_totalLine - 1);
        else setG_totalLine(G_totalLine + 1);
        // console.log(devInfo, param);
        // let tempList = [...G_devInfoList];
        let _paraList = [...G_paraInfoList];

        for(const para of _paraList) {
            if(param.dataKey === para.dataKey && param._id === para._id) {
                // console.log("found");
                para.checked = !param.checked;
                if(para.leftScale) para.leftScale = false;
                if(para.rightScale) para.rightScale = false;
            }
        }

        setG_paraInfoList(_paraList);

        if(param.checked) {
            // console.log("param checked");
            if(G_userSetting === 1) await handleLeftScale(param);
            else if(G_userSetting === 2) await handleRightScale(param);
        }
        await reloadData();
        // let vList = [...viewList];
        // console.log(tempList);
        // for(const temp of tempList) {
        //     if(temp._id === devInfo._id) {
        //         for(const para of temp.para) {
        //             if(param.dataKey === para.dataKey) {
        //                 console.log(para.checked);
        //                 console.log(para.leftScale);
        //                 console.log(para.rightScale);
        //                 para.checked = !param.checked;
        //                 if(para.leftScale) para.leftScale = false;
        //                 if(para.rightScale) para.rightScale = false;
        //                 // if(param.checked) {
        //                 //     if(userSetting === 1) {
        //                 //         para.leftScale = true;
        //                 //         para.rightScale = false;
        //                 //     }
        //                 //     else if(userSetting === 2){
        //                 //         para.rightScale = true;
        //                 //         para.leftScale = false;
        //                 //     } 
        //                 // }
        //             }
        //         }
        //     }
        // }
        // setG_devInfoList(tempList);
        // console.log(G_totalLine);
        // if(param.checked) {
        //     console.log("param checked");
        //     if(G_userSetting === 1) await handleLeftScale(devInfo, param);
        //     else if(G_userSetting === 2) await handleRightScale(devInfo, param);
        // }
        // await reloadData();
        // else await reloadData();
        // setMinMaxValue();
    }

    const handleCheckFromIndicator = async (disLine) => {
        let _paraList = [...G_paraInfoList];

        for(const para of _paraList) {
            if(para.dataKey === disLine.dataKey.split("-")[0] && para._id === parseInt(disLine.dataKey.split("-")[1])) {
                // console.log("uncheck");
                para.checked = !para.checked;
                if(para.leftScale) para.leftScale = false;
                if(para.rightScale) para.rightScale = false;
            }
        }

        setG_totalLine(G_totalLine - 1);
        setG_paraInfoList(_paraList);
        await reloadData(); 

        // let tempList = [...G_devInfoList];

        // for(const temp of tempList) {
        //     if(temp.name === disLine.name) {
        //         for(const para of temp.para) {
        //             if(para.dataKey === disLine.dataKey.split("_")[0]) {
        //                 para.checked = !para.checked;
        //                 if(para.leftScale) para.leftScale = false;
        //                 if(para.rightScale) para.rightScale = false;
        //             }
        //         }
        //     }
        // }
        // setG_devInfoList(tempList);   
        // await reloadData(); 
    }

    const handleLeftScale = async (param) => {
        // let elementClass = document.getElementById(`${devInfo._id}_${param.dataKey}_left`).classList;
        // if (!param.leftScale) elementClass.remove("icon-deselect");
        // else elementClass.add("icon-deselect");
        if(param.leftScale) {
            document.getElementById(`${param._id}_${param.dataKey}_min`).value = "";
            document.getElementById(`${param._id}_${param.dataKey}_max`).value = "";
        }

        let _paraList = [...G_paraInfoList];

        for(const para of _paraList) {
            if(para._id === param._id) {
                if(param.dataKey === para.dataKey) {
                    para.leftScale = !param.leftScale
                    para.rightScale = false;
                }
            }
        }

        !param.leftScale && param.checked? setG_userSetting(0) : setG_userSetting(1);
        setG_paraInfoList(_paraList);
        await reloadData();

        // let tempList = [...G_devInfoList];

        // for(const temp of tempList) {
        //     for(const para of temp.para) {
        //         // if(para.leftScale === true) {
        //         //     para.leftScale = false;
        //         //     break;
        //         // }
        //         if(temp._id === devInfo._id) {
        //             if(param.dataKey === para.dataKey) {
        //                 para.leftScale = !param.leftScale
        //                 para.rightScale = false;
        //             }
        //         }
        //     }
        // }
        // !param.leftScale && param.checked? setG_userSetting(0) : setG_userSetting(1);
        // await setG_devInfoList(tempList);
        // // setLoading(true);
        // await reloadData();
        // setMinMaxValue();
    }

    const handleRightScale = async (param) => {
        // let elementClass = document.getElementById(`${devInfo._id}_${param.dataKey}_right`).classList;
        // if (!param.leftScale) elementClass.remove("icon-deselect");
        // else elementClass.add("icon-deselect");
        
        if(param.rightScale) {
            document.getElementById(`${param._id}_${param.dataKey}_min`).value = "";
            document.getElementById(`${param._id}_${param.dataKey}_max`).value = "";
        }

        let _paraList = [...G_paraInfoList];

        for(const para of _paraList) {
            if(para._id === param._id) {
                if(param.dataKey === para.dataKey) {
                    para.rightScale = !param.rightScale
                    para.leftScale = false;
                }
            }
        }

        !param.rightScale && param.checked? setG_userSetting(0) : setG_userSetting(2);
        setG_paraInfoList(_paraList);
        await reloadData();

        // let tempList = [...G_devInfoList];

        // for(const temp of tempList) {
        //     for(const para of temp.para) {
        //         // if(para.rightScale === true) {
        //         //     para.rightScale = false;
        //         //     break;
        //         // }
        //         if(temp._id === devInfo._id) {
        //             if(param.dataKey === para.dataKey) {
        //                 para.rightScale = !param.rightScale
        //                 para.leftScale = false;
        //             }
        //         }
        //     }
        // }
        // !param.rightScale && param.checked? setG_userSetting(0) : setG_userSetting(2);
        // await setG_devInfoList(tempList);
        // await reloadData();
        // setMinMaxValue();
    }

    // const handleMin = async (devInfo, param) => {
    //     let min = document.getElementById(`${devInfo._id}_${param.dataKey}_min`).value;
    //     console.log(min);
    //     let tempList = [...G_devInfoList];

    //     for(const temp of tempList) {
    //         if(devInfo._id === temp._id) {
    //             for(const para of temp.para) {
    //                 if(param.dataKey === para.dataKey) {
    //                     if(min === "" || min === null) {
    //                         console.log("min empty");
    //                         para.min = undefined;
    //                     }
    //                     else para.min = parseInt(min);
    //                 }
    //             }
    //         }
    //     }
    //     console.log(tempList);
    //     setG_devInfoList(tempList);
    //     // await reloadData();
    //     await genGraphData();
    //     // setMinMaxValue();
    // }

    // const handleMax = async (devInfo, param) => {
    //     let max = document.getElementById(`${devInfo._id}_${param.dataKey}_max`).value;
    //     console.log(max);

    //     let tempList = [...G_devInfoList];

    //     for(const temp of tempList) {
    //         if(devInfo._id === temp._id) {
    //             for(const para of temp.para) {
    //                 if(param.dataKey === para.dataKey) { 
    //                     if(max === "" || max === null) {
    //                         console.log("max empty");
    //                         para.max = undefined;
    //                     }
    //                     else para.max = parseInt(max);
    //                 }
    //             }
    //         }
    //     }
    //     console.log(tempList);
    //     setG_devInfoList(tempList);
    //     // await reloadData();
    //     await genGraphData();
    //     // setMinMaxValue();
    // }

    const handleAuto = async (scale) => {
        let _allMinMax = setMinMaxValue(G_ovvData, G_disLines);

        if(scale === "left") {
            setG_leftScaleValue(_allMinMax[0]);
            document.getElementById("left_Max").value = _allMinMax[0].max;
            document.getElementById("left_Min").value = _allMinMax[0].min;
        }
        if(scale === "right") {
            setG_rightScaleValue(_allMinMax[1]);
            document.getElementById("right_Max").value = _allMinMax[1].max;
            document.getElementById("right_Min").value = _allMinMax[1].min;
        }
        // document.getElementById(`${devInfo._id}_${param.dataKey}_min`).value = "";
        // document.getElementById(`${devInfo._id}_${param.dataKey}_max`).value = "";

        // let tempList = [...devInfoList];

        // for(const temp of tempList) {
        //     if(devInfo._id === temp._id) {
        //         for(const para of temp.para) {
        //             if(param.dataKey === para.dataKey) {
        //                 para.min = undefined;
        //                 para.max = undefined;
        //             }
        //         }
        //     }
        // }

        // setDevInfoList(tempList);
        // await reloadData();
        // setMinMaxValue(ovvData);
    }

    const handleDuration = async (setting) => {
        let currClass = document.getElementById(`toggle_${G_duration}`).classList;
        currClass.remove("selected");
        let eleClass = document.getElementById(`toggle_${setting}`).classList;
        eleClass.add("selected");
        setG_duration(setting);
        if(setting === "custom") setG_customize(true);
        else {
            // if(setting === 60) setUnit("minute");
            // else if (setting === 1440) setUnit("hour");
            // else if (setting === 10080) setUnit("day");
            // else if (setting === 43200) setUnit("day");
            setG_customize(false);
            setG_loading(true);
            // if(loadedDuration < setting) setFirstLoaded(true);
            // await reloadData();
        }
    }

    const handleStartEndTime = async() => {
        let startTime = document.getElementById("in_startTime").value;
        let endTime = document.getElementById("in_endTime").value;
        // console.log(startTime);
        // console.log(endTime);
        // console.log(moment(startTime).unix());
        setG_loading(true);
        setG_startTime(startTime);
        setG_endTime(endTime);
        setG_loading(false);
        // setG_loaded(false);
        // await reloadData();
    }
    
    const handleLeftMinMax = async () => {
        let currLeft = {min: document.getElementById("left_Min").value === "" ?
                            G_leftScaleValue.min : parseFloat(document.getElementById("left_Min").value), 
                        max: document.getElementById("left_Max").value === "" ?
                            G_leftScaleValue.max : parseFloat(document.getElementById("left_Max").value)};
        // console.log(parseFloat(document.getElementById("left_Min").value));
                            // let currLeft = {min: 0, max: 10};
        // currLeft.min = Number(document.getElementById("left_Min").value);
        // currLeft.max = Number(document.getElementById("left_Max").value);
        // console.log(currLeft);
        setG_leftScaleValue(currLeft);
        // let min = parseFloat(document.getElementById("left_Min").value);
        // let max = parseFloat(document.getElementById("left_Max").value);
        // let _paraList = [...G_paraInfoList];

        // for(const para of _paraList) {
        //     if(para.leftScale === true) {
        //         para.min = currLeft.min;
        //         para.max = currLeft.max;
        //     }
        // }

        // setG_paraInfoList(_paraList);

        // let tempList = [...G_devInfoList];
        // console.log(G_leftScaleValue);

        // for(const temp of tempList) {
        //     for(const para of temp.para) {
        //         console.log(para.leftScale);
        //         if(para.leftScale === true) {
        //             // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currLeft.min;
        //             // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currLeft.max;
        //             // await handleMin(temp, para);
        //             // await handleMax(temp, para);
        //             para.min = currLeft.min;
        //             para.max = currLeft.max;
        //         }
        //         // console.log(para);
        //     }
        // }
        // // console.log(tempList);
        // setG_devInfoList(tempList);
        // await genGraphData();
        // await reloadData();
    }
    
    const handleRightMinMax = async () => {
        let currRight = {min: document.getElementById("right_Min").value === "" ?
                            G_rightScaleValue.min : parseFloat(document.getElementById("right_Min").value), 
                        max: document.getElementById("right_Max").value === "" ?
                            G_rightScaleValue.max : parseFloat(document.getElementById("right_Max").value)};
        // let currLeft = {min: 0, max: 10};
        // currLeft.min = Number(document.getElementById("left_Min").value);
        // currLeft.max = Number(document.getElementById("left_Max").value);
        // console.log(currRight);
        setG_rightScaleValue(currRight);
        // await reloadData();
        // let min = parseFloat(document.getElementById("left_Min").value);
        // let max = parseFloat(document.getElementById("left_Max").value);

        // let _paraList = [...G_paraInfoList];

        // for(const para of _paraList) {
        //     if(para.rightScale === true) {
        //         para.min = currRight.min;
        //         para.max = currRight.max;
        //     }
        // }

        // setG_paraInfoList(_paraList);
        // await reloadData();
        // let tempList = [...G_devInfoList];
        // console.log(leftScaleValue);

        // for(const temp of tempList) {
        //     for(const para of temp.para) {
        //         console.log(para.rightScale);
        //         if(para.rightScale === true) {
        //             // document.getElementById(`${temp._id}_${para.dataKey}_min`).value = currRight.min;
        //             // document.getElementById(`${temp._id}_${para.dataKey}_max`).value = currRight.max;
        //             // await handleMin(temp, para);
        //             // await handleMax(temp, para);
        //             para.min = currRight.min;
        //             para.max = currRight.max;
        //         }
        //         // console.log(para);
        //     }
        // }
        // setG_devInfoList(tempList);
        // await genGraphData();
    }

    const handleShow = (devInfo) => {
        let tempList = [...G_devInfoList];

        for(const temp of tempList) {
            if(devInfo._id === temp._id)
                temp.show = !devInfo.show;
        }
        // console.log(tempList);
        setG_devInfoList(tempList);
    }

    return ( 
        <div>
            {G_loading && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
            {G_loaded && <div>
                <div className="backContainer divStickOnTop">
                    <div className="backIconContainer" onClick={backPrevPg}>
                        <IoIosArrowBack className="backIcon" />
                    </div>
                    <div className="backText">Graph</div>
                </div>

                <div className="buttonContainer" style={{ marginTop: "10px" }}>
                    <div id="toggle_60" className="toggleButton" onClick={() => handleDuration(60)}>1H</div>
                    <div id="toggle_1440" className="toggleButton selected" onClick={() => handleDuration(60*24)}>1D</div>
                    <div id="toggle_10080" className="toggleButton" onClick={() => handleDuration(60*24*7)}>7D</div>
                    <div id="toggle_43200" className="toggleButton" onClick={() => handleDuration(60*24*30)}>1M</div>
                    <div id="toggle_custom" className="toggleButton" onClick={() => handleDuration("custom")}>Customize</div>
                </div>
                
                {/* <div className='flexOnly spreadBetween'>
                    <input 
                        onBlur={() => handleLeftMinMax()} 
                        id="left_Max"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={leftScaleValue.max}
                        style={{display: leftScaleValue.max === null? "none" : "inline-block"}}
                        // value={leftScaleValue}
                    />
                    <input 
                        onBlur={() => handleRightMinMax()} 
                        id="right_Max"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={rightScaleValue.max}
                        style={{display: rightScaleValue.max === null? "none" : "inline-block"}}
                        // value={rightScaleValue}
                    />  
                </div> */}

                {/* {totalLine > 0 && <div className='graphOvvDevFrame'>
                    {devInfoList.map((c, ind) => (
                        <div key={ind}>
                            {c.para.map((d, idx) => (
                                d.checked &&
                                <div key={idx} style={{position: "relative"}}>
                                    {lnColDis[`${d.datakey}_${c._id}`] && <div style={{ backgroundImage:`var(--g${lnColDis[`${d.datakey}_${c._id}`]})`}} className='sensorGraph_LineColor'></div>}
                                    <div>{lnColDis[`${d.datakey}_${c._id}`]} {c.name} {d.title}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>} */}

                <div className='graphOvvDevFrame'>
                {G_totalLine > 0 && <div style={{ display: "flex", flexWrap: "wrap", margin: "5px 10px 15px 10px" }}>
                    {G_disLines.map((c, ind) => (
                        <div key={ind} className='flexOnly' style={{ flex: "1 0 45%" }}>
                            <div style={{ backgroundImage:`var(--h${ind + 1})`}} className='sensorGraph_LineColor'></div>
                            <div className='sensorGraph_Indicator' onClick={() => handleCheckFromIndicator(c)}>{c.name} {c.title.split("_")[0]}</div>
                        </div>
                    ))}
                </div>}

                <TpLineGraph2
                    ovvData={G_ovvData}
                    disLines={G_disLines}
                    devInfoList={G_devInfoList}
                    maxLine={maxLine}
                    totalLine={G_totalLine}
                    unit={G_unit}
                    leftScaleVal={G_leftScaleValue}
                    rightScaleVal={G_rightScaleValue}
                    zoomLimit={G_zoomLimit}
                />
                </div>
                {/* <div className='flexOnly spreadBetween'>
                    <input
                        onBlur={() => handleLeftMinMax()} 
                        id="left_Min"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={leftScaleValue.min}
                        style={{display: leftScaleValue.min === null? "none" : "inline-block"}}
                        // value={leftScaleValue.min}
                    />
                    <input
                        onBlur={() => handleRightMinMax()} 
                        id="right_Min"
                        className="inputText_noTitle"
                        maxLength={20}
                        type="number"
                        defaultValue={rightScaleValue.min}
                        style={{display: rightScaleValue.min === null? "none" : "inline-block"}}
                        // value={rightScaleValue.min}
                    />
                </div> */}

                {G_customize && <div className='flexOnly spreadBetween'>
                    <input
                        className=" i_time_contentInput hideNumberUpDown"
                        // placeholder={`< Insert duration >`}
                        type="datetime-local"
                        id="in_startTime"
                        style={{marginLeft: "10px"}}
                        onBlur={() => handleStartEndTime()}
                        onChange={() => handleStartEndTime()}
                        value={G_startTime}
                    ></input>
                    <input
                        className=" i_time_contentInput hideNumberUpDown"
                        // placeholder={`< Insert duration >`}
                        type="datetime-local"
                        id="in_endTime"
                        style={{marginRight: "10px"}}
                        onBlur={() => handleStartEndTime()}
                        onChange={() => handleStartEndTime()}
                        value={G_endTime}
                    ></input>
                </div>}

                <div className='contentCard'>
                <div className='graphDevOvv_Title'>Graph Settings</div>
                    <div className='flexAndBothCenter' style={{ marginTop: "10px" }}>
                        <div style={{ fontSize: "0.9rem", marginLeft: "10px" }}>Left Max: </div>
                        <input 
                            onBlur={() => handleLeftMinMax()} 
                            id="left_Max"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={G_leftScaleValue.max}
                            // style={{display: leftScaleValue.max === null? "none" : "inline-block"}}
                            // value={leftScaleValue}
                        />
                        <div style={{ fontSize: "0.9rem", marginLeft: "10px" }}>Left Min: </div>
                        <input
                            onBlur={() => handleLeftMinMax()} 
                            id="left_Min"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={G_leftScaleValue.min}
                            // style={{display: leftScaleValue.min === null? "none" : "inline-block"}}
                            // value={leftScaleValue.min}
                        />
                        <div className='roundButtonSmall' onClick={() => handleAuto("left")}>Auto</div>
                    </div>
                    <div className='flexAndBothCenter' style={{ margin: "10px 0 10px 0" }}>
                        <div style={{ fontSize: "0.9rem" }}>Right Max: </div>
                        <input 
                            onBlur={() => handleRightMinMax()} 
                            id="right_Max"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={G_rightScaleValue.max}
                            // style={{display: rightScaleValue.max === null? "none" : "inline-block"}}
                            // value={rightScaleValue}
                        />
                        <div style={{ fontSize: "0.9rem" }}>Right Min: </div>
                        <input
                            onBlur={() => handleRightMinMax()} 
                            id="right_Min"
                            className="inputText_noTitle"
                            maxLength={20}
                            type="number"
                            defaultValue={G_rightScaleValue.min}
                            // style={{display: rightScaleValue.min === null? "none" : "inline-block"}}
                            // value={rightScaleValue.min}
                        />
                        <div className='roundButtonSmall' onClick={() => handleAuto("right")}>Auto</div>
                    </div>
                </div>

                <div>
                    {G_devInfoList.map((c, ind) => (
                        <div className='contentCard' key={ind}>
                            <div className='flexOnly spreadBetween'>
                                <div className='graphDevOvv_Title'>{c.name}</div>
                                <div className='settingItemIcon' onClick={() => handleShow(c)}>
                                    {c.show ? <FiMinus /> : <FiPlus />}
                                </div>
                            </div>
                            {c.show && <div className='sortVer' style={{marginLeft: "10px"}}>
                                {/* <div className='flexOnly'>
                                    <div className='cb-Title'>V</div>
                                    <div className='cb-Title'>L</div>
                                    <div className='cb-Title'>R</div>
                                </div> */}
                                {G_paraInfoList.map((d, idx) => (
                                    c._id === d._id && <div key={idx}>
                                        <div className='flexOnly' style={{marginBottom: "5px"}}>
                                            {/* <TpCheckbox handleCheck={() => handleCheck(c, d)} checked={d.checked} />
                                            <TpCheckbox handleCheck={() => handleLeftScale(c,d)} checked={d.leftScale} />
                                            <TpCheckbox handleCheck={() => handleRightScale(c,d)} checked={d.rightScale} /> */}
                                            <div className='graph-iconstyle' onClick={() => handleCheck(d)}>{d.checked? <FaEye /> : <FaEyeSlash />}</div>
                                            <div id={`${c._id}_${d.dataKey}_left`} className={d.leftScale? 'graph-iconstyle' : 'graph-iconstyle icon-deselect'} onClick={() => handleLeftScale(d)}><i className="fa fa-line-chart" /></div>
                                            <div id={`${c._id}_${d.dataKey}_right`} className={d.rightScale? 'graph-iconstyle' : 'graph-iconstyle icon-deselect'} onClick={() => handleRightScale(d)}><i className="fa fa-line-chart fa-flip-horizontal" /></div>
                                            <div style={{ fontSize: "0.9rem" }}>{d.title}</div>
                                        </div>
                                        {/* {d.checked && <div className='flexOnly' style={{marginBottom: "10px"}}>
                                            <div className='inputContainer_titleAtBox_v2'>
                                                <div className="smallInputTitle_titleAtBox_v2">
                                                    Min
                                                </div>
                                                <div>
                                                    <input 
                                                    onBlur={() => handleMin(c, d)} 
                                                    id={`${c._id}_${d.dataKey}_min`} 
                                                    className="smallInputText_titleAtBox_v2"
                                                    maxLength={20}
                                                    type="number"
                                                    defaultValue={d.leftScale? G_leftScaleValue.min : G_rightScaleValue.min}
                                                    />                    
                                                </div>
                                            </div>
                                            <div className='inputContainer_titleAtBox_v2'>
                                                <div className="smallInputTitle_titleAtBox_v2">
                                                    Max
                                                </div>
                                                <div>
                                                    <input 
                                                    onBlur={() => handleMax(c, d)} 
                                                    id={`${c._id}_${d.dataKey}_max`} 
                                                    className="smallInputText_titleAtBox_v2"
                                                    maxLength={20}
                                                    type="number"
                                                    defaultValue={d.leftScale? G_leftScaleValue.max : G_rightScaleValue.max}
                                                    />                    
                                                </div>
                                            </div>
                                            <div className='roundButtonSmall' onClick={() => handleAuto(d.leftScale? "left" : "right")}>Auto</div>
                                        </div>} */}
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    );
}

export default TpCompareGraph;