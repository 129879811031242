import React from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { useDispatch } from 'react-redux';
import { addNewFloor} from '../../../reduxStore/actions/dev_regSensor';
// import { toast } from "react-toastify";
import TpInputTitleAtBox from '../../template/inputField/Input_titleAtBox';

function AddNewFloor(props) {
    const dispatch = useDispatch();

    const backPrevPg=()=>{
        props.history.push({
            pathname: "/device/choosefloor",
        });
    }
    
    const handleConfirm=()=>{
        let floorName = document.getElementById('NameInput').value.trim();
        if(floorName==='') {
            document.getElementById('NameErrId').innerHTML = "*Name cannot be empty";
            return 
        }
        dispatch(addNewFloor({bFloorName:floorName}));
        
        props.history.push({
            pathname: "/device/add",
            focusStep:3,
            // _data: {_selT1Llist:tableMonList[ind]._id},
        });
    }
    

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Add New Monitoring List</div>
            </div>
            
            <TpInputTitleAtBox id='NameInput' errMsgid='NameErrId' title='Name' maxLen={40}/>
            
            <div className="buttonContainer" style={{marginTop:"250px"}}>
                <div className="roundButton" onClick={handleConfirm}>Confirm</div>
            </div>

        </div>
    );
}

export default AddNewFloor;