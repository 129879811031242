import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';


function TpDiaAddNewGroup(props) {

    const [G_NotAllowAdd, setG_NotAllowAdd] = useState(false);

    useEffect(()=>{
        async function startUp(){
            try {

                if(props.existingGroup){                    
                    let nEmptySubGroup = 0;
                    for (const eachFloor of props.existingGroup) {
                        if(eachFloor.devsInFloor.length < 1) {
                            nEmptySubGroup++;
                        }
                    }                    
                    if(nEmptySubGroup >= 5){
                        setG_NotAllowAdd(true);
                    }
                }
                
            } catch (error) {
                toast(error.message);
            }
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.onclickConfirm) {
            let newName = document.getElementById('rename_input').value.trim();
            if(!newName || newName==="" || newName.toUpperCase()==="OTHERS") return toast('Invalid name')
            props.onclickConfirm(newName);
        }
    }
    


    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                {!G_NotAllowAdd && <div className=' spring_dialogBox'>
                    <div className='spring_renameBdName text_1_5'>{`Add group`}</div>
                                        
                    <div className='flexAndBothCenter spring_Margin_20'>
                        <input id="rename_input" 
                        autoComplete='off'
                        className='spring_FramelessInput blueText'
                        placeholder={"< New group >"}
                        style={{textAlign:"center"}}/>
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Cancel
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Add</div>
                    </div>
                </div>}
                {G_NotAllowAdd && <div className=' spring_dialogBox'>
                    <div className='spring_renameBdName text_1_5 redText'>{`Prohibited`}</div>
                    <div className='spring_diaSmallDesc'>{`Too many group without device(s)`}</div>
                    <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                    onClick={handleClose}>
                        Close
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default TpDiaAddNewGroup;