import devSer from './../../service/deviceManage';


async function getdevBy_SnRegCode(...rest){    
    // console.log("Cone In");
    try {
        let rel = await devSer.getdevBy_SnRegCode(...rest);
        // console.log(rel);
        if(rel.statusText==='OK' && rel.data.length > 0) return rel.data;
        return null;

    } catch (error) {
        console.log(error.message);
        return null
    }
}

function getTyList(_devInbd){
    // console.log(_devInbd);
    /** Filter duplicated data */
    let uniqueTyDev = Array.from(
        new Set(_devInbd.map((a) => a.type))
    ).map((type) => {
        return _devInbd.find((a) => a.type === type);
    });
    // console.log(uniqueTyDev);
    let tyList = [];
    for (const dev of uniqueTyDev) {
        tyList.push(dev.type);
    }
    return tyList;
}

async function verifySnRegCode(SerialNo, RegCode){    
    // console.log("Cone In");
    try {
        let rel = await devSer.verifySnRegCode(SerialNo, RegCode);
        // console.log(rel);
        return rel.data;
        // if(rel.statusText==='OK' && rel.data.length > 0) 
        // return null;
    } catch (error) {
        return ({errMsg:error.message})
    }
}

const expModule = {  
    getdevBy_SnRegCode,
    getTyList,
    verifySnRegCode,
};
export default expModule;