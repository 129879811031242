import React from 'react';
import { toast } from 'react-toastify';


function TpDiaRename(props) {

    const handleClose=()=>{
        if(props.onclickClose) props.onclickClose();
    }
    const handleConfirm=()=>{
        if(props.onclickConfirm) {
            let newName = document.getElementById('rename_input').value.trim();
            if(!newName || newName==="" || newName.toUpperCase()==="OTHERS") return toast('Invalid name')
            props.onclickConfirm(newName);
        }
    }
    

    return (
        <div>
            <div className='spring_dialogBoxContainer'>
                <div className='spring_manuContainer'></div>

                <div className=' spring_dialogBox'>
                    {props.OldName && <div className='text_1_1 spring_renameBdName'>{`${props.OldName}`}</div>}
                                        
                    <div className='flexAndBothCenter text_1'>
                        rename to
                    </div>

                    <div className='flexAndBothCenter spring_Margin_30'>
                        <input id="rename_input" 
                        autoComplete='off'
                        className='spring_FramelessInput blueText'
                        placeholder={"< New name >"}
                        style={{textAlign:"center"}}/>
                    </div>

                    <div className='sortHor'>
                        <div className="spring_roundButton spring_greyColor hoverPointer spring_Margin_20"
                        onClick={handleClose}>
                            Discard
                        </div>
                        <div className="roundButton spring_Margin_20" onClick={handleConfirm}>Confirm</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TpDiaRename;