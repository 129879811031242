import React, { useEffect } from 'react';


function TabRefreshPg(props) {
    useEffect(()=>{
        async function startUp(){
            _backToPage();
        }
        startUp();

        return ()=>{
            // alert("Page Close");
        }
        // eslint-disable-next-line
    }, []);    

    const _backToPage=()=>{
        if(props.backToPg) props.backToPg();
    }

    return (
        <div className='spring_scheListPg'>
            <div>Refreshing...</div>
        </div>
    );
}

export default TabRefreshPg;