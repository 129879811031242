import http from "../../httpService";


async function getV2_RjScene(rj_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getscene";
    let body = {rj_id};
    return await http.post(url, body);  
}

async function getV2_RjSceneInfo(rj_id, sceneIdx) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getsceneinfo";
    let body = {rj_id, sceneIdx};
    return await http.post(url, body);  
}

async function getV2_RjOnlineVar(rj_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getonlinevar";
    let body = {rj_id};
    return await http.post(url, body);  
}

async function V2_RjUpdateScene(Rj_id, scene, rules) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/updatescene";
    let body = {Rj_id, scene, rules};
    return await http.post(url, body);  
}

async function V2_RjDeleteScene(Rj_id, scene) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/deletscene";
    let body = {Rj_id, scene};
    return await http.post(url, body);  
}

async function V2_RjUpdateVarList(Rj_id, varList) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/updatevarlist";
    let body = {Rj_id, varList};
    return await http.post(url, body);  
}

async function V2_RjUpdatePairRtrh(Rj_id, varList) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/updatepairrtrh";
    let body = {Rj_id, varList};
    return await http.post(url, body);  
}

async function V2_RjGetSchedule(Rj_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getrjschedule";
    let body = {Rj_id};
    return await http.post(url, body);  
}

async function V2_RjSetSchedule(Rj_id, scheList) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/saverjschedule";
    let body = {Rj_id, scheList};
    return await http.post(url, body);  
}

async function V2_RjGetAcBrand() {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getrjacbrand";
    let body = {};
    return await http.post(url, body);  
}

async function V2_RjSetFeedbackInfo(bdDev_id, fbInfo) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/setrjfeedbacksensor";
    let body = {bdDev_id, fbInfo};
    return await http.post(url, body);  
}

async function V2_RjGetFeedbackInfo(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getrjfeedbacksensor";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function V2_RjLogCleanFilter(bdDev_id, maintenanceInfo, filterInfo) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/logcleanfilter";
    let body = {bdDev_id, maintenanceInfo, filterInfo};
    return await http.post(url, body);  
}

async function V2_RjLastRjCleanFilterInfo(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getlastcleanfilterinfo";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function V2_RjGetLastNMaintenance(bdDev_id, nCount) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2app/rj/getlastnmaintenancelog";
    let body = {bdDev_id, nCount};
    return await http.post(url, body);  
}


const expModule = {  
    getV2_RjScene,
    getV2_RjSceneInfo,
    getV2_RjOnlineVar,
    V2_RjUpdateScene,
    V2_RjDeleteScene,
    V2_RjUpdateVarList,
    V2_RjUpdatePairRtrh,
    V2_RjGetSchedule,
    V2_RjSetSchedule,
    V2_RjGetAcBrand,
    V2_RjSetFeedbackInfo,
    V2_RjGetFeedbackInfo,
    V2_RjLogCleanFilter,
    V2_RjLastRjCleanFilterInfo,
    V2_RjGetLastNMaintenance,
};
export default expModule;