import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  getEditShareCfg,
  setChooseBd_id,
  setChooseUser_id,
} from "./../../../reduxStore/actions/dev_shareCfg";
import { useDispatch, useSelector } from "react-redux";
import sensorOwnerFn from "../../../function/V2_SensorDevice/v2_SensorOwnerFn";
import AuthV2 from "../../../service/auth/AuthV2";
import { toast } from "react-toastify";

function ChooseBdOrUserPage(props) {
  const editShareCfg = useSelector(getEditShareCfg);
  const dispatch = useDispatch();

  const [G_displayList, setG_displayList] = useState([]);
  const [G_loaded, setG_loaded] = useState(false);

  useEffect(() => {
    async function startUp() {
      if (
        !(
          editShareCfg.chooseMode === "Bd" || editShareCfg.chooseMode === "User"
        )
      )
        props.history.push({ pathname: "/device/editshare" });

      let loadRel = await displaySelectionList();
      if (loadRel) setG_loaded(true);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const displaySelectionList = async () => {
    let newBdList = [];

    if (editShareCfg.chooseMode === "Bd") {
      let bdList = await loadSharedBuilding();
      // console.log(bdList);
      if (!bdList) return;
      for (const bd of bdList) {
        newBdList.push({ title: bd.name, _id: bd._id });
      }
      // setG_displayList(newBdList);
    }

    if (editShareCfg.chooseMode === "User") {
      // let newBdList = [];
      let userList = await loadUser();
      // console.log(userList);
      if (!userList) return;
      for (const user of userList) {
        newBdList.push({ title: user.email, _id: user._id });
      }
    }

    setG_displayList(newBdList);
    return true;
  };

  const loadSharedBuilding = async () => {
    let userinfo = await AuthV2.getUserInfo();
    // console.log(userinfo);

    let relatedBd = await sensorOwnerFn.getRelatedBdOwnLevel(userinfo.user_id);
    if (relatedBd.errMsg) return toast(relatedBd.errMsg);
    // console.log(relatedBd);

    let displayBdList = [];
    for (const bd of relatedBd) {
      let countRel = await sensorOwnerFn.getCount_BdDev(bd._id);
      if (countRel.errMsg) {
        toast("Get Building Count Err");
        continue;
      }
      //   console.log(count);
      if (countRel.count > 0) await displayBdList.push(bd);
    }
    // console.log(displayBdList);
    return displayBdList;
    /**
     * get owned bd
     * select * from V2_OwnerList_bd where owner_id = curUser;
     * push result to combine list
     *
     * get share involve building         *
     * select * from V2_ShareList_bd where user_id = curUser and active =1 and accessLevel =1
     * base on array get, get bd details from V2_OwnerList_bd, then push to combine list
     *
     * combine both BD become building list
     *
     * forof combine building list,
     *  let rel = select * from V2_ShareList_bdDev where buidling_id = eachBd._id and active = 1 limit 1; (use count)
     * let displayBdList=[];
     *  if rel.len > 0, displayBdList.push(building)
     *
     *
     */
  };

  const loadUser = async () => {
    let userinfo = await AuthV2.getUserInfo();
    // console.log(userinfo);

    let relatedBd = await sensorOwnerFn.getRelatedBdOwnLevel(userinfo.user_id);
    // console.log(relatedBd);
    if (relatedBd.errMsg) {
      toast("Read DB Error");
      return;
    }

    let displayBdList = [];
    for (const bd of relatedBd) {
      let count = await sensorOwnerFn.getCount_BdDev(bd._id);
      if (count.errMsg) {
        toast("Get Building Count Err");
        continue;
      }
      //   console.log(count);
      if (count.count > 0) await displayBdList.push(bd);
    }

    let bdList = [];
    for (const bd of displayBdList) {
      /** seatch user involve in bd, active =1 ,  */
      bdList.push(bd._id);
    }
    /** query user list by bdList */
    let idx = 0;
    let sliceSize = 20;
    let userList = [];
    // console.log(bdList);
    do {
      let slicePart = bdList.slice(sliceSize * idx, sliceSize * (idx + 1));
      /** query by getUniqueUserListByBdList */
      let userInfoList = await sensorOwnerFn.getUniqueUserListByBdList(
        slicePart
      );
      if (userInfoList.errMsg) {
        toast(userInfoList.errMsg);
        return;
      }
      for (const eachUser of userInfoList) {
        let foundIdx = userList.findIndex((c) => c._id === eachUser._id);
        // console.log(foundIdx);
        if (foundIdx < 0) userList.push(eachUser);
      }
      // console.log(idx);
      // console.log(userInfoList);
      idx++;
    } while (sliceSize * idx < bdList.length);

    // console.log(userList);
    return userList;
    /** own building
     * select * from V2_ShareList_bd owner_id = curUser;
     *
     *
     * co own building
     * select * from V2_ShareList_bd where user_id = curUser and active =1 and accessLevel =1
     * base on array get, get bd details from V2_OwnerList_bd, then push to combine list
     *
     * combine both BD become building list
     *
     * forof combine building list,
     *  let rel = select * from V2_ShareList_bdDev where buidling_id = eachBd._id and active = 1 limit 1;
     * let combineListWithValidDevice=[];
     *  if rel.len > 0, displayBdList.push(rel)
     *
     * ********filter duplicated user displayBdList********
     * let userList = []
     * forof displayBdList
     *  let found = userList.find(c=>c.user_id === each.user_id);
     *  if(!found) {
     *      query userName from DB base on user_id
     *      userList.push({name:"username", user_id:each.user_id})
     *  }
     *
     */
  };

  const displayChooseCategory = () => {
    if (editShareCfg.chooseMode === "Bd") return "Building";
    if (editShareCfg.chooseMode === "User") return "User";
  };

  const backPrevPg = () => {
    // console.log("back");
    props.history.push({ pathname: "/device/editshare" });
  };

  const selItem = (c) => {
    // console.log(c);
    if (editShareCfg.chooseMode === "Bd")
      dispatch(
        setChooseBd_id({ selected_bd_id: c._id, selected_bd_name: c.title })
      );
    if (editShareCfg.chooseMode === "User")
      dispatch(
        setChooseUser_id({
          selected_user_id: c._id,
          selected_user_email: c.title,
        })
      );
    props.history.push({ pathname: "/device/editshare/choose2nd" });
  };

  return (
    <div>
      <div className="backContainer">
        <div className="backIconContainer" onClick={backPrevPg}>
          <IoIosArrowBack className="backIcon" />
        </div>
        <div className="backText">{`Choose ${displayChooseCategory()}`} </div>
      </div>

      <div>
        {G_displayList.length > 0 &&
          G_loaded &&
          G_displayList.map((c, ind) => (
            <div
              key={`bdRow_${ind}`}
              className="cfgOptionSel "
              onClick={() => selItem(c)}
            >
              <div className="wrapTextEllipsis">{c.title}</div>
              {/* <div className='wrapTextEllipsis'>hhhasoiudhaoishfoiahsoihdoiahsfoiahsoidhaoisfhoaosijoaihsoidahsoifhaoishdoaishfaishfoiashdoiahsofihaosidhaoishdoiasdh</div> */}
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChooseBdOrUserPage;
