import http from "../httpService";


async function getAreaList_Bd_Id(Bd_Id){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/present/area/getarealistbybdid`;
    const body = {   
        Bd_Id:Bd_Id,
    };
    return await http.post(url, body); 
}


const expModule = {  
    getAreaList_Bd_Id,
};
export default expModule;
