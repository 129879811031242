import React from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { useDispatch } from 'react-redux';
import {addNewBuilding} from '../../../reduxStore/actions/dev_regSensor';
import TpInputTitleAtBox from './../../template/inputField/Input_titleAtBox';

function AddNewBuilding(props) {
    const dispatch = useDispatch();

    const backPrevPg=()=>{
        props.history.push({
            pathname: "/device/choosebd",
            // _data: {_selT1Llist:tableMonList[ind]._id},
        });
    }
    
    const handleConfirm=()=>{
        // console.log("Confirm");
        let bdName = document.getElementById('NameInput').value.trim();
        // console.log(bdName);
        if(bdName==='') {
            document.getElementById('NameErrId').innerHTML = "*Name cannot be empty";
            return
        }
        dispatch(addNewBuilding({buildingName:bdName}));
        
        props.history.push({
            pathname: "/device/add",
            focusStep:2,
        });
    }
    

    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Add New Building</div>
            </div>
            
            <TpInputTitleAtBox id='NameInput' errMsgid='NameErrId' title='Name' maxLen={40}/>
            
            <div className="buttonContainer buttonSpreadEvenly" 
                style={{
                    marginTop:"250px",
                    marginBottom:"20px",
                    }}>
                <div className="spring_roundButton spring_greyColor hoverPointer" onClick={backPrevPg}>
                    Back
                </div>
                <div className="roundButton" onClick={handleConfirm}>Confirm</div>
            </div>

            {/* <div className="buttonContainer" style={{marginTop:"250px"}}>
                <div className="roundButton" onClick={handleConfirm}>Confirm</div>
            </div> */}

        </div>
    );
}

export default AddNewBuilding;