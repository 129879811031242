import React, { useEffect, useState } from 'react'
import {sortTemplate} from '../../../function/ArrayFn/arrayFn';
// import TpSysGroupComponent from './TpSysGroupComponent';
import { F_DashDb_To_DisplayFormat } from "./DashboardFunction";
import DashList from './DashList';

function TpSystemGroup(props) {
    // const [G_loaded, setG_loaded] = useState(false);
    // const [G_groupList, setG_groupList] = useState([]);
    // const [G_componentList, setG_componentList] = useState([]);
    const [G_DisplayList, setG_DisplayList] = useState([]);
    const [G_groupName, setG_groupName] = useState([]);

    useEffect(() => {
        async function startUp() {
            if(!props.groupList) return;
            // console.log(props);
            // let currCompList = props.sysComponentList.filter(c => c.system_id === props.currSystem);
            let sortedGroupList = sortArr(props.groupList);
            let _DisplayList = F_DashDb_To_DisplayFormat(props.sysComponentList, sortedGroupList);
            // let compList = groupComponentList(sortedGroupList, props.sysComponentList);
            setG_groupName(sortedGroupList.map(c => c.Name));
            // setG_componentList(compList);
            setG_DisplayList(_DisplayList);
            // setG_loaded(true);
        }
        startUp();
    }, [props]);

    const F_FilterAuxList_ByCompId = (comp_id) => {
        return props.auxList.filter(c => c.component_id === comp_id);
    }

    // eslint-disable-next-line
    const groupComponentList = (groupList, allCompList) => {
        let groupedCompList = {};

        for(const comp of allCompList) {
            let groupId = 0;
            let foundIdx = groupList.findIndex(c => c._id === comp.group_id);
            if(foundIdx > -1) groupId = comp.group_id;
            Array.isArray(groupedCompList[groupId])? groupedCompList[groupId].push(comp) : groupedCompList[groupId] = [comp];
        }

        return groupedCompList;
    }

    const sortArr = (arrInput) => {
        let tempArr = [...arrInput];
        let sortKeys = [
          { key: "sortIdx", descOrder: false },
          { key: "unix", descOrder: false},
          { key: "Name", descOrder: false },
    
        ];
        tempArr.sort((a, b) => sortTemplate(a, b, sortKeys));
        return tempArr;
      }

    return ( 
        <div>
            {/* {G_groupList.map((u, ind) =>
                <div key={`group_${ind}`} className='spring_dashboard_Contianer flexHorCenter_ flexDirCol_'>
                    <div className='spring_DashGroupTitle spring_DashboardMaxWidth'>{u.Name}</div>
                    <div className='spring_Container_T1 spring_DashboardMaxWidth spring_DashboardStartingPoint'>
                        <TpSysGroupComponent componentList = {G_componentList[u._id]} handleSetCompName={props.handleSetCompName} />
                    </div>
                </div>
            )} */}
            <div className="">
                {G_DisplayList.length>0  && G_DisplayList.map((a, ind_a) => (
                    <div key = {`group_${ind_a}`}className="spring_dashboard_Contianer flexHorCenter_ flexDirCol_">
                        <div className="spring_DashGroupTitle spring_DashboardMaxWidth">{G_groupName[ind_a]}</div>
                        <div className="spring_Container_T1 spring_DashboardMaxWidth spring_DashboardStartingPoint">
                        {a.map((c, ind_c) => (
                            <div key={`comp_${ind_a}_${ind_c}`}>
                            <div>
                                {c.map((u, ind_u) => (
                                    <div key={`stackComp_${ind_a}_${ind_c}_${ind_u}`}>
                                    <DashList componentType={u.componentType} compInfo={u}
                                     auxList = {F_FilterAuxList_ByCompId(u._id)} 
                                     curSystemId = {props.currSystem}/>
                                    </div>
                                ))}
                            </div>
                            </div>
                        ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
     );
}

export default TpSystemGroup;