import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import './css/Theme/colorVar.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./component/bars/navsidebar.css";
import "react-pro-sidebar/dist/css/styles.css";
import "./component/customcards.css";
import "react-toastify/dist/ReactToastify.css";
import "./component/custommodal.css";

import './css/Setting/setting.css';
import './css/Setting/addDevice.css';
import './css/Input/input_TitleAtBox.css';
import './css/Input/input_titleWithinBox.css';
import './css/Button/clickButton.css';
import './css/table/tableContiner.css';
import './css/page/pageLayout.css';
import './css/page/contentContainer.css';
import './css/Input/errMsg.css';
import './css/header/searchBar.css';
import './css/page/bdOverview.css';
import './css/common/flexStyle.css';
import './css/common/gridStyle.css';
import './css/common/textStyle.css';
import './css/page/devOverview.css';
import './css/common/displayStyle.css';
import './css/common/colorStyle.css';
import './css/page/singleSnesorPage.css'
import './css/page/configurePg.css';
import './css/common/transform.css';
import './css/page/reactionCfg.css';
import './css/common/loadingStyle.css';
import './css/common/iconStyle.css';
import './css/page/tpGraph.css';
import './css/page/tpDialog.css';
import './css/page/tpDevice.css';
import './css/common/pinwheelLoader.css';
import './css/common/legoLoader.css';
import './css/common/imgStyle.css';

import './css/cssSeries/Spring.css';

import './css/User/form.css'



ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
