import http from "../httpService";


async function getSumList_Bd_Id(Bd_Id){
    let url = `${process.env.REACT_APP_PUBLIC_URL}/present/sum/getsumlistbybdid`;
    const body = {   
        Bd_Id:Bd_Id,
    };
    return await http.post(url, body); 
}


const expModule = {  
    getSumList_Bd_Id,
};
export default expModule;
