import http from "../httpService";


async function getSensorListByVendorId(vendor_id) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/sensormng/getbyvid";
    let body = {vendor_id};
    return await http.post(url, body);  
}

async function regNewSensor(SensorInfo, paraList) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/sensormng/regnewsensor";
    let body = {SensorInfo, paraList};
    return await http.post(url, body);  
}


async function getParaByTypeList(typeList) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/sensormng/getparabytylist";
    let body = {typeList};
    return await http.post(url, body);  
}

async function updateSensorList(sensor) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/sensormng/updatesensor";
    let body = {sensor};
    return await http.post(url, body);  
}

async function updateSensorPara(paraList) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/sensormng/updatesensorparameter";
    let body = {paraList};
    return await http.post(url, body);  
}

async function getSensorListByTypeList(typeList) {    
    let url = process.env.REACT_APP_PUBLIC_URL+"/sensormng/getsensorlistbytylist";
    let body = {typeList};
    return await http.post(url, body);  
}


const expModule = {  
    updateSensorPara,
    updateSensorList,
    regNewSensor,
    getSensorListByVendorId,
    getParaByTypeList,
    getSensorListByTypeList,
};
export default expModule;