import React, { useEffect, useRef, useState} from 'react';
import TpDiaSending from './../../../../Common/Dialog/StatusDialog/TpDiaSending';
import TpDiaSuccess from './../../../../Common/Dialog/StatusDialog/TpDiaSuccess';
import TpDiaFailed from './../../../../Common/Dialog/StatusDialog/TpDiaFailed';
import ctrlCmdLib from '../../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib';
import { toast } from 'react-toastify';
import socketIOClient from 'socket.io-client';


function TpScCoolingTower(props) {
    
    const cmdTimeout = 10000;    // 10 sec
    const msgTimeout = 3000;    // 3 sec

    const [G_statusState, setG_statusState] = useState(0);
    const [G_diaErrMsg, setG_diaErrMsg] = useState("");
    // const [G_gwPair, setG_gwPair] = useState(0);

    
    const refCmdLog = useRef({});
    const cmdTimeoutRef = useRef(null);
    const statusTimeoutRef = useRef(null);

    useEffect(() => {
        let socket;
        async function startUp() {
            socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);

            // console.log(props);
            
            let topic = `v2_CtrlCmd`;
            socket.on(topic, async(data) => {
                let sentSuccess = ctrlCmdLib.validateNodeReply(data, refCmdLog.current);
                if(sentSuccess) {
                    clearTimeout(cmdTimeoutRef.current);
                    setG_statusState(2);
                    /** trigger pass countdown */
                    statusTimeoutRef.current = setTimeout(handleCloseDia, msgTimeout);
                    // toast ("Success")
                    await handleServerReq(data);
                }
                /** handle server request */
                
            });
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
            clearTimeout(cmdTimeoutRef.current);
            clearTimeout(statusTimeoutRef.current);            
            socket.disconnect();
        };
        // eslint-disable-next-line
    }, []);

    
    const handleServerReq=async(mqttData)=>{
        let {hf} = mqttData;
        if(hf===2){         // set configuration success
            console.log("Function 2 done");
            // let setRel = await CtCfgFn.setV2_CtCfg(ctCfgRef.current);
            // if(!setRel.success) return toast("Upload configuration err. Please reload page");
            // setG_cfgChanged(false);     // hide save change button
        }else if (hf===4){  // sync configuration success
            // if(isEmptyArr(mqttData.pi)) return
            // if(mqttData.pi.length!==3) return
            // /** set opMode */
            // setG_opMode(mqttData.pi[0])
            // /**Set force overwrite */
            // let _ForceOvewrite = [mqttData.pi[1], mqttData.pi[2]];
            // setG_ForceOvewrite(_ForceOvewrite);
            // /** save data into DB */
            // ctCfgRef.current={
            //     bdDev_id: props.devInfo.bdDev_id, 
            //     opMode:mqttData.pi[0], 
            //     FO_Avtive:mqttData.pi[1],
            //     FO_Value:mqttData.pi[2]
            // }
            // let setRel = await CtCfgFn.setV2_CtCfg(ctCfgRef.current);
            // if(!setRel.success) return toast("Sync configuration err. Please reload page");
            // /** if success update DB  */
            // setG_opMode_Db(mqttData.pi[0])
            // setG_ForceOvewrite_Db(_ForceOvewrite);
            // setG_cfgChanged(false);     // hide save change button
        }
        else if(hf===20002){     // server request schedule
            // /** convert schedule into DB form , {startUnix, endUnix, schType, action, DOW, reserve1, inUse}*/
            // let scheList = devScheLib.sche_loraFormat_to_db(mqttData);
            // /** store DB into database */
            // let setScheRel = await devScheFn.V2_setSche(scheList, refDevInfo.current);
            // if(!setScheRel)return toast("Store DB err");            
            // /** update device display */
            // await loadSchedule();            
            // /** set sync into 3, clear sync_save button */
            // setG_nSync_Save(3);
        }else if(hf===20001){        // set sche to device
            // /** save eventList into DB */
            // let setScheRel = await devScheFn.V2_setSche(refScheList.current, refDevInfo.current);
            // // console.log("setScheRel", setScheRel);
            // if(!setScheRel)return toast("Store DB err");
            // /** set sync into 3, clear sync_save button */
            // setG_nSync_Save(3);
        }   
    }

    const fCmdTimeout=async()=>{
        let checkRel = await ctrlCmdLib.checkCmdStatus_OnTimeout(refCmdLog.current);
        if(!checkRel || !checkRel.errMsg) {
            setG_diaErrMsg("Cmd DB Err");
            // toast("Cmd DB Err");
        }
        if(checkRel.errMsg) {
            setG_diaErrMsg(checkRel.errMsg);
            // toast(checkRel.errMsg);
        }
        setG_statusState(3);
        clearTimeout(cmdTimeoutRef.current);
        /** trigger failed countdown */
        statusTimeoutRef.current = setTimeout(handleCloseDia, msgTimeout);        
        
    }


    const handleCloseDia=()=>{
        clearTimeout(cmdTimeoutRef.current);
        clearTimeout(statusTimeoutRef.current);    
        setG_statusState(0);
    }

    const handleOn=async ()=>{
        await triggerOnOff(true)
    }

    const handleOff=async ()=>{
        await triggerOnOff(false)

    }
    

    const triggerOnOff=async (bOn)=>{
        let payload={};
        if(bOn)  payload={pi:[props.devInfo.ht, props.devInfo.hi, 2 , 1, 1]};       // type, ID, function , [...payload]
        else  payload={pi:[props.devInfo.ht, props.devInfo.hi, 2, 2, 2]};


        /** get gw info */
        if(!props.gwPair) toast("Gateway info missing");
        let _gwPair = props.gwPair;
        if(_gwPair < 1) return toast(`No gateway pair to this device`);

        /** get device {type, devID} info */    
        let devInfo = {type:props.masterInfo.ht, devID:props.masterInfo.hi}       // insert master type, master id
        
        // console.log("_gwPair", _gwPair);
        let cmdRel = await ctrlCmdLib.v2_CtrlCmd(20100, _gwPair, devInfo, payload, 1, 1);          // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala), ctrlType (1=node, 2= master slave)

        /** log ct configuration */        
        // ctCfgRef.current={
        //     bdDev_id: props.devInfo.bdDev_id, 
        //     opMode: G_opMode, 
        //     FO_Avtive: G_ForceOvewrite[0],
        //     FO_Value: G_ForceOvewrite[1]
        // }
        setG_statusState(1);     // go to sending
        
        /** start send command time out */
        cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
        refCmdLog.current = cmdRel.loraPackage;
    }

    return (
        <div>
            {G_statusState!==0 && <div className='spring_dialogBlurBg'></div>}
            {G_statusState===1 && <TpDiaSending/>}
            {G_statusState===2 && <TpDiaSuccess onClickDiaClose = {handleCloseDia}/>}
            {G_statusState===3 && <TpDiaFailed onClickDiaClose = {handleCloseDia} diaErrMsg={G_diaErrMsg}/>}
            

            <div className='sortHor spring_slaveCtrlGroup'>
                <div>Cooling tower 1</div>
                <div className='spring_slaveButtonGroup sortHor'>
                    <div className='spring_slaveButton spring_shadowBox_BlackOnly spring_greenGrdBgc hoverPointer'
                    onClick={handleOn}>On</div>
                    <div className='spring_slaveButton spring_shadowBox_BlackOnly spring_failedBgc hoverPointer'
                    onClick={handleOff}>Off</div>
                </div>
            </div>
            
        </div>
    );
}

export default TpScCoolingTower;