import React, { useEffect } from 'react';
import { FaMinus} from "react-icons/fa";

function TpDiaLoadSensorInBd(props) {

    useEffect(() => {
        async function startUp() {
            /** load sensor in same building with app id */

        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        };
        // eslint-disable-next-line
    }, []);

    const handleClose=()=>{
        if(!props.onClickDiaClose) return;
        props.onClickDiaClose();
    }

    const handleSearch=()=>{
        console.log("handleSearch");
        
    }

    return (
        <div>
            <div className='spring_dialogBoxContainer '>
                <div className='spring_dialogBox  spring_shadowBox'>
                    <div className='spring_diaCloseBtn spring_shadowBox spring_lightGreyGrdBgc hoverPointer'
                    onClick={handleClose}>
                        <FaMinus/>
                    </div>

                    <div className='spring_diaTitle_SmallMarginTop'>
                        Choose Sensor
                    </div>
                    <div className='sortHor'>
                        <input onChange={handleSearch} id='searchInput' className='spring_diaSearchBar'/>       
                        <div onClick={handleSearch} className='searchButton'>
                            <i className='fa fa-search'></i>
                        </div>   
                    </div>
                    <div>
                        <div>DPM 1</div>
                        <div>Cooling Tower 1</div>
                        <div>Cooling Tower 2</div>
                        <div>Cooling Tower 3</div>
                        <div>Cooling Tower 1</div>
                        <div>Cooling Tower 2</div>
                        <div>Cooling Tower 3</div>
                        <div>Cooling Tower 1</div>
                        <div>Cooling Tower 2</div>
                        <div>Cooling Tower 3</div>
                        <div>Cooling Tower 1</div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default TpDiaLoadSensorInBd;