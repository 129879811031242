import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import { IoIosArrowBack} from "react-icons/io";
import LegoLoader from '../Version2a/Template/LoaderTemplate/legoLoader';
import V2DataDownloadFn from '../../../function/V2_DataDownload/V2_DataDownloadFn';
import V2aComOwnerDevFn from '../Version2a/ComFn/V2aComOwnerDevFn';

function DataDownload_checkout(props) {
    const [G_bLoaded, setG_bLoaded] = useState(false);
    // const [G_downloadInfoList, setG_downloadInfoList] = useState([]);
    const [G_downloadPathList, setG_downlaodPathList] = useState({});

    const location = useLocation();
    const checkoutInfoList = location.state.data;

    const refManuLevel = useRef(0);

    useEffect(() => {
        async function startUp() {
            console.log(checkoutInfoList);
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;
            
            // const _downloadInfoList = checkoutInfoList.map(obj => ({
            //     ...obj,
            //     status: false // Add your new parameter and its value here
            // }));
            // console.log(_downloadInfoList);

            // setG_downloadInfoList(_downloadInfoList);

            setG_bLoaded(true);

            for(const info of checkoutInfoList) {
                let downloadPaths = await genDownloadFile(info);
                setG_downlaodPathList(prevState => ({
                    ...prevState,
                    [info.bdDev_id]: downloadPaths
                }));
                console.log(G_downloadPathList);
            }
            console.log(G_downloadPathList);
        }

        startUp();

        return ()=>{
            
        }
    }, []);

    const genDownloadFile = async (downloadInfo) => {
        let downloadPaths = await V2DataDownloadFn.dataDownloadPrepFile(downloadInfo);
        console.log(downloadPaths);
        return downloadPaths;
    }

    const backPrevPg=()=>{
        let pathname ="/data/download";
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const handleDownload = async () => {
        try {
            let _downloadPathList = { ...G_downloadPathList };
            console.log(_downloadPathList);
            let bdInfo  = localStorageFn.getBdToken();
            let typeList = await V2aComOwnerDevFn.getTypeList(checkoutInfoList);
            console.log(typeList);
            let zipFileName = '';
            let pathList = [];
            for(const info of checkoutInfoList) {
                if(zipFileName === '') {
                    if(info.inputYearMonthStart === info.inputYearMonthEnd) zipFileName = `${bdInfo.name}__${info.inputYearMonthStart}`;
                    else zipFileName = `${bdInfo.name}__${info.inputYearMonthStart}_to_${info.inputYearMonthEnd}`;
                }
                let typeInfo = typeList.find(c => c.type === info.type);
                console.log(typeInfo);
                for(const path of _downloadPathList[info.bdDev_id]) {
                    const firstUnderscoreIndex = path.indexOf('_'); // Find the index of the first underscore
                    const secondUnderscoreIndex = path.indexOf('_', firstUnderscoreIndex + 1); // Find the index of the second underscore
                    const periodIndex = path.lastIndexOf('.'); // Find the index of the last period
                    let monthYear =  path.substring(secondUnderscoreIndex + 1, periodIndex).replace(/_i/g, '');
                    console.log(monthYear);
                    // eslint-disable-next-line
                    pathList.push({name: `${typeInfo.name}__${info.name}__${monthYear}.csv`.replace(/[\\/:\*\?"<>|]/g, '_'), path: path});
                }
            }
            console.log(pathList);
            console.log(zipFileName);
    
            let response = await V2DataDownloadFn.dataDownloadMultiCsv(pathList, zipFileName);
            console.log(response);
            // Create a Blob object from the ZIP data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Create a link element and trigger a click event to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = zipFileName;
            link.click();
        } catch (error) {
            console.log('Error downloading multiple CSV files:', error);
        }
    }

    return ( 
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Data Download</div>
            </div>
            {!G_bLoaded && <LegoLoader />}
            {G_bLoaded && <div>
                <div className='spring_inputBox spring_listContainer'>
                    {checkoutInfoList.map((u, ind) => (
                        <div key={`dev_${ind}`}>
                            {ind!==0 && <div className='divSeperateLine'></div>}
                            <div className='sortHor spring_TableItemSpace'>
                                <div className='spring_listContainerItem'>{`[${u.devID}] ${u.name}`}</div>
                                <div className='stayRight flexAndBothCenter'>
                                    {G_downloadPathList[u.bdDev_id]? `File Ready` : `In Progress...`}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className="sortHor spreadEvenly spring_2BtnBtmMargin spring_roundButton spring_greyColor">
                    File Preparing
                </div> */}
                <div className='sortHor spreadEvenly spring_2BtnBtmMargin'>
                    <div className="roundButton" onClick={handleDownload}>Download</div>
                </div>        
            </div>}
        </div>
    );
}

export default DataDownload_checkout;