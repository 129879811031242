// eslint-disable-next-line 
import React, { useState } from "react";

const InputValidation = (id, value, cfmValue) => {
  if(id === "emailInput") {
    // eslint-disable-next-line 
    let mailFormat =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!mailFormat.test(value)) {
      return "Invalid email address.";
    } else {
      return null;
    }
  } else if(id === "cfmEmailInput") {
    if (value !== cfmValue) {
      return "Email does not match.";
    } else {
      return null;
    }
  } else if (id === "usernameInput") {
    if(value.length < 6) {
      return "Username must be 8 characters and above";
    } else {
      return null;
    }
  } else if(id === "passInput") {
    if (value.length < 8) {
      return "Password must be 8 characters and above";
    } else {
      return null;
    }
  } else if(id === "cfmPassInput") {
    if (value !== cfmValue) {
      return "Password does not match.";
    } else {
      return null;
    }
  }
}

// const InputValidation = () => {
//   const [inputs, setInputs] = useState({
//     username: "",
//     cfmUsername: "",
//     email: "",
//     cfmEmail: "",
//     password: "",
//     cfmPassword: "",
//   });
//   const [errors, setErrors] = useState({
//     username: "",
//     cfmUsername: "",
//     email: "",
//     cfmEmail: "",
//     password: "",
//     cfmPassword: "",
//   });

//   const validateUsername = (e) => {
//     if (inputs.username.length < 6) {
//       console.log("username");
//       setErrors({
//         ...errors,
//         username: "Username must be 6 characters and above",
//       });
//       return false;
//     } else {
//       setErrors({
//         ...errors,
//         username: "",
//       });
//       return true;
//     }
//   };

//   const validateCfmUsername = (e) => {
//     if (inputs.username !== inputs.cfmUsername) {
//       setErrors({
//         ...errors,
//         cfmUsername: "Username does not match.",
//       });
//       return false;
//     } else {
//       setErrors({
//         ...errors,
//         cfmUsername: "",
//       });
//       return true;
//     }
//   };

//   const validateEmail = (e) => {
//     // eslint-disable-next-line
//     let mailFormat =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
//     if (!mailFormat.test(inputs.email)) {
//       return "Invalid email address.";
//     } else {
//       return null;
//     }
//   };

//   const validateCfmEmail = (e) => {
//     if (inputs.email !== inputs.cfmEmail) {
//       return "Email does not match.";
//     } else {
//       return null;
//     }
//   };

//   const validatePassword = (e) => {
//     if (inputs.password.length < 8) {
//       return "Password must be 8 characters and above";
//     } else {
//       return null;
//     }
//   };

//   const validateCfmPassword = (e) => {
//     if (inputs.password !== inputs.cfmPassword) {
//       return "Password does not match.";
//     } else {
//       return null;
//     }
//   };

//   return {
//     validateUsername,
//     validateCfmUsername,
//     validateEmail,
//     validateCfmEmail,
//     validatePassword,
//     validateCfmPassword,
//     inputs,
//     setInputs,
//     errors,
//     setErrors,
//   };
// };

export default InputValidation;
