import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { FiEdit3, FiTrash2} from "react-icons/fi";
import localStorageFn from '../../../function/localStorage/LocalStorageFn';
import sensorOwnerFn from '../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import shareEditToken from '../../../function/localStorage/lsEditShareFn';
import TpDiaDelete from './../Version2a/Template/DialogTemplate/TpDiaDelete';
import { toast } from 'react-toastify';

function V2aEditShareDevice(props) {

    const [G_CoOwn, setG_CoOwn] = useState([]);
    const [G_Admin, setG_Admin] = useState([]);
    const [G_User, setG_User] = useState([]);
    const [G_ShowTp, setG_ShowTp] = useState(0);
    const [G_UserToDel, setG_UserToDel] = useState();
    const [G_ReloadCnt, setG_ReloadCnt] = useState(0);

    const refManuLevel = useRef(0);
    
    useEffect(()=>{
        async function startUp(){  
            try {
                let {manuLevel} = localStorageFn.getManuToken();
                refManuLevel.current = manuLevel;
                /** load shared user */
                
                let bdInfo = localStorageFn.getBdToken();
    
                let sharedUser = await sensorOwnerFn.v2aGetShareUserOfBd(bdInfo._id);
                if(sharedUser.errMsg) return toast(`Err :${sharedUser.errMsg}`);
                setG_CoOwn(sharedUser.filter(c=>c.shareLevel===2));
                setG_Admin(sharedUser.filter(c=>c.shareLevel===4));
                setG_User(sharedUser.filter(c=>c.shareLevel===6));
                
            } catch (error) {
                console.log("V2aEditShareDevice Err: ", error.message);
            }

        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, [G_ReloadCnt]);
    
    
    const backPrevPg=()=>{
        let pathname ="/data/bdoverview"
        if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        props.history.push({pathname});
    }

    const handleEdit=async(userInfo)=>{
        await shareEditToken.setToken({
            user_id:userInfo.shareUser_id,
            username:userInfo.username,
            shareLevel:userInfo.shareLevel,
        })
        
        props.history.push({pathname:"/device/share"});
    }

    const handleDelete=(userInfo)=>{
        setG_ShowTp(1);
        setG_UserToDel(userInfo);
    }
    
    
    const handleCloseDia=()=>{
        setG_ShowTp(0);
    }

    const handleConfirmDelete=async()=>{
        let delRel = await sensorOwnerFn.v2aDeactivateShareUser(G_UserToDel.buidling_id,G_UserToDel.shareUser_id);
        
        if(delRel.errMsg) {
            toast(`Delete Err: ${delRel.errMsg}`);
            handleCloseDia();
            return
        }
        if(delRel.Success) {
            handleCloseDia();
            toast("Success");
            setG_ReloadCnt(G_ReloadCnt+1);
            return
        }
        toast("Delete User Err");
    }

    return (
        <div>
            {G_ShowTp ===1 && <TpDiaDelete 
                OldName = {G_UserToDel.username} 
                onclickClose = {handleCloseDia}
                onclickConfirm = {handleConfirmDelete}
                />}

            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack className="backIcon"/>
                </div>
                <div className='backText'>Edit Share</div>
            </div>

            <div className='cfgSubTitle'>Shared to:</div>

            <div className='cfgSubTitle spring_FloorTitle boldText'>Co-owner</div>
            <div className='spring_cfgCard_nonPadding spring_padding_5px cfgCardColor'>
                {G_CoOwn.map((c,ind)=>
                    <div key ={`keycoown_${ind}`} className='sortHor blueText spring_margin_2_0'>
                    <div className='hoverPointer'
                    onClick={()=>handleEdit(c)}>{c.username}</div>
                    <div className='stayRight'>
                        <FiEdit3  className='hoverPointer spring_marginRight15' onClick={()=>handleEdit(c)}/>
                        <FiTrash2  className='hoverPointer redText'onClick={()=>handleDelete(c)}/>
                    </div>
                </div>
                )}
                {G_CoOwn.length<=0 && <div className='sortHor blueText hoverPointer spring_margin_2_0'>
                    <div className=''>-</div>
                    {/* <FiEdit3 className='stayRight' /> */}
                </div>}
            </div>
            
            <div className='cfgSubTitle spring_FloorTitle boldText'>Admin</div>
            <div className='spring_cfgCard_nonPadding spring_padding_5px cfgCardColor'>
                {G_Admin.map((c,ind)=>
                    <div key ={`keyadmin_${ind}`} className='sortHor blueText spring_margin_2_0'>
                        <div className='hoverPointer'
                        onClick={()=>handleEdit(c)}>{c.username}</div>
                        <div className='stayRight'>
                            <FiEdit3  className='hoverPointer spring_marginRight15' onClick={()=>handleEdit(c)}/>
                            <FiTrash2  className='hoverPointer redText'onClick={()=>handleDelete(c)}/>
                        </div>
                    </div>
                )}
                {G_Admin.length<=0 && <div className='sortHor blueText hoverPointer spring_margin_2_0'>
                    <div className=''>-</div>
                    {/* <FiEdit3 className='stayRight' /> */}
                </div>}
            </div>
            

            <div className='cfgSubTitle spring_FloorTitle boldText'>User</div>
            <div className='spring_cfgCard_nonPadding spring_padding_5px cfgCardColor'>
                {G_User.map((c,ind)=>
                    <div key ={`keyuser_${ind}`} className='sortHor blueText spring_margin_2_0'>
                    <div className='hoverPointer'
                    onClick={()=>handleEdit(c)}>{c.username}</div>
                    <div className='stayRight'>
                        <FiEdit3  className='hoverPointer spring_marginRight15' onClick={()=>handleEdit(c)}/>
                        <FiTrash2  className='hoverPointer redText'onClick={()=>handleDelete(c)}/>
                    </div>
                </div>
                )}
                {G_User.length<=0 && <div className='sortHor blueText hoverPointer spring_margin_2_0'>
                    <div className=''>-</div>
                    {/* <FiEdit3 className='stayRight' /> */}
                </div>}
            </div>
            
        </div>
    );
}

export default V2aEditShareDevice;