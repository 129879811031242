import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isEmptyArr } from "../../function/ArrayFn/arrayFn";
// import timeFn from "../../component/utilities/timeFn";

// eslint-disable-next-line
const devInitData={
    bdDev_id:0,
    ht:0,
    loadTime:0,
    startTime:0,
    endTime:0,
    data:[]
}

const slice = createSlice({
    name:'devSel_HistoryTrend',
    initialState:{
        devSelectedList:[], 
        devDataList:[],
        startUnix:0,
        endUnix:0,
        dataStartUnix:0,
        dataEndUnix:0,
    },
    reducers:{
        addToDevSelList:(state, action)=>{
            let found = state.devSelectedList.filter(c=>c.ht === action.payload.ht && c.bdDev_id === action.payload.bdDev_id)
            if ( isEmptyArr(found)) state.devSelectedList.push(action.payload);
        },
        setNewDevSelList:(state, action)=>{                        
            state.devSelectedList=action.payload;
        },
        removeFromDevSelList:(state, action)=>{
            let foundIdx = state.devSelectedList.findIndex(c=>c.ht === action.payload.ht && c.bdDev_id === action.payload.bdDev_id)
            if (foundIdx>=0) state.devSelectedList.splice(foundIdx, 1);
        },
        setHisDataSet:(state, action)=>{                        
            state.devDataList=action.payload;
        },
        clearSelectedList:(state)=>{
            state.devSelectedList = [];
        },
        setTimeRange:(state, action)=>{                        
            state.startUnix=action.payload.startUnix;
            state.endUnix=action.payload.endUnix;
        },
        clearTimeRange:(state, action)=>{                        
            state.startUnix=0;
            state.endUnix=0;
        },
        setTimeRange_Data:(state, action)=>{                        
            state.dataStartUnix=action.payload.startUnix;
            state.dataEndUnix=action.payload.endUnix;
        },
        clearTimeRange_Data:(state, action)=>{                        
            state.dataStartUnix=0;
            state.dataEndUnix=0;
        },
    }
})


/** Export Action */
export const {
    addToDevSelList,
    removeFromDevSelList,
    setHisDataSet,
    clearSelectedList,
    setNewDevSelList,
    setTimeRange,
    clearTimeRange,
    setTimeRange_Data,
    clearTimeRange_Data
} = slice.actions;


/** reducer */
export default slice.reducer;


/** Selector, read from store */
export const getSelectedList = createSelector(
    state=> state.dev.selectedDev,
    ({devSelectedList}) => (devSelectedList)
)

export const getSelDevData = createSelector(
    state=> state.dev.selectedDev,
    ({devDataList}) => (devDataList)
)
export const getTimeRange = createSelector(
    state=> state.dev.selectedDev,
    ({startUnix, endUnix}) => ({startUnix, endUnix})
)
export const getTimeRange_data = createSelector(
    state=> state.dev.selectedDev,
    ({dataStartUnix, dataEndUnix}) => ({dataStartUnix, dataEndUnix})
)
