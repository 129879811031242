import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";
import { FaMicrochip } from "react-icons/fa6";
import { MdCloudCircle } from "react-icons/md";
import { isEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";

const MRVfdCtrlMethod = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        async uploadVfdCtrlMethod(mode, _pf) {
          F_FillUpVfdCtrlMethod(mode, _pf);
        },
    }));

    const [G_storageMode, setG_storageMode] = useState("db");

    useEffect(() => {
        async function startUp() {
            if(props.handleLoadDbCtrlMethod) props.handleLoadDbCtrlMethod();
        }
        startUp();

        return () => {
        // alert("AddDevicePg Page Close");
        //   clearTimeout(ref_UploadDelay.current);
        };
        // eslint-disable-next-line
    }, [props.gwId]);

    const F_FillUpVfdCtrlMethod = (mode, _pf) => {
        if(isEmptyArr(_pf)) F_SetInputVal_Number(`in_${props.pos}_ctrl_targetHz`, null);
        else F_SetInputVal_Number(`in_${props.pos}_ctrl_targetHz`, _pf[0]);

        setG_storageMode(mode);
    }

    const F_switchToNoMode = () => {
        if(G_storageMode !== "none") setG_storageMode("none");
    }

    const Fin_invalidNo=(num)=>{
        if(isNaN(num) || num<= 0 ){
            return true;
        }
        return false;
    }

    const F_handleLoadNode = () => {
        if(G_storageMode === "node") return;
        if(props.handleLoadNodeCtrlMethod) props.handleLoadNodeCtrlMethod();
    }
    
    const F_handleLoadDb = () => {
        if(G_storageMode === "db") return;
        if(props.handleLoadDbCtrlMethod) props.handleLoadDbCtrlMethod();
    }

    const F_sendToNode = () => {
        if(props.handleSendToNodeCtrlMethod) {
            if(Fin_invalidNo(F_GetInputVal_Number(`in_${props.pos}_ctrl_targetHz`))) return toast(`Invalid Hz`);
            let passCode = F_GetInputVal_Number(`vfd_pass_${props.pos}`);
            if (passCode === null) return toast(`Invalid input (Password)`);
            let ctrlInfo = { targetHz: F_GetInputVal_Number(`in_${props.pos}_ctrl_targetHz`) };
            props.handleSendToNodeCtrlMethod(ctrlInfo, passCode);
        }
    }

    const F_saveToDb = () => {
        if(props.handleSendToDbCtrlMethod) {
            if(Fin_invalidNo(F_GetInputVal_Number(`in_${props.pos}_ctrl_targetHz`))) return toast(`Invalid Hz`);
            let ctrlInfo = { targetHz: F_GetInputVal_Number(`in_${props.pos}_ctrl_targetHz`) };
            props.handleSendToDbCtrlMethod(ctrlInfo);
        }
    }

    return (
        <div className="spring_SL_TabPg">
            <div className="sortHor">
                <div className="spring_tpContentTitle">Control</div>
                <div className="stayRight" style={{ marginRight: "10px" }}>
                <FaMicrochip
                    className={`hoverPointer spring_shadowBox_Small spring_storageModeButton ${G_storageMode === "node"? "reactCfgButtonOn " : "reactCfgButtonOff" }`}
                    style={{ marginRight: "10px" }}
                    onClick={F_handleLoadNode}
                />
                <MdCloudCircle
                    className={`hoverPointer spring_shadowBox_Small spring_storageModeButton ${G_storageMode === "db"? "reactCfgButtonOn " : "reactCfgButtonOff"}`}
                    onClick={F_handleLoadDb}
                />
                </div>
            </div>

            <div className="spring_tpContentGroup">
            <div className="sortHor spring_tpContent">
                <div>Target Hz</div>
                <input
                    style={{ width: "100px", textAlign: "end" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                    id={`in_${props.pos}_ctrl_targetHz`}
                    placeholder="--"
                    onChange={F_switchToNoMode}
                ></input>
            </div>
            </div>

            {/* <div className="sortHor">
                <div>Target Hz:</div>
                <div className="stayRight">
                    <FaMicrochip
                        className={`hoverPointer spring_shadowBox_Small spring_storageModeButton ${G_storageMode === "node"? "reactCfgButtonOn " : "reactCfgButtonOff" }`}
                        style={{ marginRight: "10px" }}
                        onClick={F_handleLoadNode}
                    />
                    <MdCloudCircle
                        className={`hoverPointer spring_shadowBox_Small spring_storageModeButton ${G_storageMode === "db"? "reactCfgButtonOn " : "reactCfgButtonOff"}`}
                        onClick={F_handleLoadDb}
                    />
                </div>
            </div>
            
            <div className="spring_Sl_Slot">
                <input style={{width:"60px"}}
                    className=" i_time_contentInput"
                    id={`in_${props.pos}_ctrl_targetHz`}
                    onChange={F_switchToNoMode}
                ></input>
            </div> */}

            <div className="spring_tpContentTitle">Security</div>
            <div className="spring_tpContentGroup">
                <div className="sortHor spring_tpContent">
                <div>Password</div>
                {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
                <input
                    style={{ width: "100px", textAlign: "end" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
                    id={`vfd_pass_${props.pos}`}
                    placeholder="--"
                    // onChange={F_UpdateInfo}
                ></input>
                </div>
            </div>

            {G_storageMode === "node" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_saveToDb}>Save to DB</div>}
            {G_storageMode === "db" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Send To Node</div>}
            {G_storageMode === "none" && <div className="spring_tpContentSendButton hoverPointer" onClick={F_sendToNode}>Update</div>}
        </div>
    );
});

export default MRVfdCtrlMethod;
