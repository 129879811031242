import React, { useEffect, useState } from "react";
import { GrRevert } from "react-icons/gr";
import v2RjFn from "../../../../../../function/V2_Application/RogerJunior/V2_RogerJuniorFn";
import { toast } from "react-toastify";
import v2RjJsxFn from "./RjFunction/RjJsxFn";
import timeFn from "../../../../../../../src/function/time/timeFn";

const TabSettingRjMaintenanceLog = (props) => {
  const GC_LoadCnt = 10;

  const [G_LogList, setG_LogList] = useState([]);
  const [G_Loaded, setG_Loaded] = useState(false);

  useEffect(() => {
    async function startUp() {
      await fLoadMaintenanceList();
      setG_Loaded(true);
    }
    startUp();

    return () => {
      // alert("Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const fLoadMaintenanceList = async () => {
    let logList = await v2RjFn.V2_RjGetLastNMaintenance(
      props.dev._id,
      GC_LoadCnt
    );
    if (logList.errMsg) return toast("Load maintenance list error.");
    setG_LogList(logList);
  };

  const fBackToMoreManu = () => {
    if (props.fToTab) props.fToTab(31);
    // if(props.discardChanges) props.discardChanges();
  };

  const fFilterCleaniness = (level) => {
    if (level === 1) return "Very Clean";
    else if (level === 2) return "Clean";
    else if (level === 3) return "Moderately Clean";
    else if (level === 4) return "Dirty";
    else if (level === 5) return "Very Dirty";
    else return "";
  };

  return (
    <div className="spring_editScenePg">
      <div className="sortHor" style={{ marginBottom: "5px" }}>
        <div className="spring_ManuTitle">Maintenance Log</div>
        <div
          className="spring_ManuTitleBackIcon stayRight hoverPointer"
          onClick={fBackToMoreManu}
        >
          <GrRevert />
        </div>
      </div>

      {G_Loaded && (
        <div>
          {G_LogList.map((u, ind) => (
            <div
              key={`maintain_Key_${props.pos}_${ind}`}
              className="spring_maintLogContainer"
            >
              <div className="spring_maintLog_DT sortHor">
                <div className="">{timeFn.getDateTimeString(u.unix)}</div>
                <div className="spring_maintLog_RT stayRight">
                  {`Run Time: ${v2RjJsxFn.fSecToHour(u.value_3)} h`}
                </div>
              </div>
              <div className="sortHor spring_maintLog_TitleContainer">
                <div className="spring_maintLog_Title">Clean Filter</div>
                {fFilterCleaniness(u.value_1) !== "" && (
                  <div className="">{`, (${fFilterCleaniness(
                    u.value_1
                  )})`}</div>
                )}
              </div>
              {u.remarks.trim().length > 0 && (
                <div className="spring_mainLog_RemarkContainer">
                  <div className="spring_mainLog_SmallTitle">Remarks:</div>
                  <div className="spring_mainLog_Remark">{u.remarks}</div>
                </div>
              )}
            </div>
          ))}

          {(G_LogList.length <= 0) && <div className="spring_maintLog_Title flexAndBothCenter spring_maintLogContainer"> Empty Record</div>}

          <div style={{ marginBottom: "25px" }}></div>
        </div>
      )}

      

      {!G_Loaded && (
        <div className="spring_100Per flexAndBothCenter">
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
};

export default TabSettingRjMaintenanceLog;
