import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import http from "../httpService";
// import jwtFn from 'jsonwebtoken';
import { jwtSignToken_7dayExpiry } from "../../service/jwt/joseService";
import timeFn from '../../function/time/timeFn';

let tokenKey = "v2Token";
let lastCheckTokenKey = "v2UserCyclicCheck";
let url = process.env.REACT_APP_PUBLIC_URL+"/user/login";
let checkUserUrl = process.env.REACT_APP_PUBLIC_URL+"/user/checkactivation";

http.setV2Jwt(getJwt());
// console.log(getJwt());

function getJwt() {
    return localStorage.getItem(tokenKey);
}


export async function login(para) {
    let body ={
        username : para.username,
        password : para.password
    }
    const { data: jwt , status} = await http.post(url, body);
    // console.log(status);
    if(status!== 200) {
        // toast("Login Error")
        return false;
    }
    localStorage.setItem(tokenKey, jwt);

    http.setV2Jwt(getJwt());
    return true
}

export async function checkUserActive() {    
    try {
        let userInfo = getUserInfo();
        // console.log(userInfo);
        let {user_id, username} = userInfo;
        let body ={
            _id:user_id,
            username,
            // email,
        }
        // console.log(body);
        const {data:userActive} = await http.post(checkUserUrl, body);
        // if(userActive.errMsg) toast(userActive.errMsg);
        return userActive.active > 0;            
    } catch (error) {
        console.log(`checkUserActive Err: ${error.message}`);
        return false;
    }
}

export function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(lastCheckTokenKey);
}

export function getUserInfo() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);        
    } catch (error) {
        // console.log(error.message);
        // toast('Get User Info Error');
        return null
    }
}

export function getLastCheck() {
    try {
        let token = localStorage.getItem(lastCheckTokenKey);
        // console.log(token);
        return jwtDecode(token);   
    } catch (error) {
        // console.log(error.message);
        // toast('Get Last Token Error');
        return null
    }
}

export async function updateCheckToken() {
    try {
        // const token = jwtFn.sign({
        //     lastCheckUser:timeFn.getUnixNow(),
        // }, process.env.REACT_APP_JWT_INTERNAL_KEY, { expiresIn: '7d' });
        const payload = {lastCheckUser:timeFn.getUnixNow(),}
        localStorage.setItem(lastCheckTokenKey, await jwtSignToken_7dayExpiry(payload));
    } catch (error) {
        console.log(error.message);
        toast('Update Check Token Error');
        return null
    }
}


const expModule = {  
    login,
    logout,
    getUserInfo,
    updateCheckToken,
    getLastCheck,
    checkUserActive,
};

export default expModule;
  