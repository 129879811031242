import React, { useEffect, useState } from 'react'
import { HiDotsVertical } from 'react-icons/hi';

const DashItemTitleBar = (props) => {

  const [G_showManu, setG_showManu] = useState(true);
  const [G_ItemHeight, setG_ItemHeight] = useState(356);

  // const itemHeight = useRef(356);

   

  useEffect(() => {
    async function startUp() {
        if(props.heightPortion===2){
          setG_ItemHeight(156)
          // itemHeight.current = 156;
        }
    }
    startUp();

    return ()=>{
        // alert("AddDevicePg Page Close");
    }
  }, [props]);


  const F_CloseManu=()=>{
    setG_showManu(false);
  }

  const handleManuClick=()=>{
      if(props.onClickManu) props.onClickManu();
      setG_showManu(!G_showManu);
  }

  const F_ClickRename=()=>{
    if(props.onClickRename) props.onClickRename();
    F_CloseManu();
  }

  const F_ClickEdit=()=>{
    if(props.onClickEdit) props.onClickEdit();
    F_CloseManu();
  }

  return (
    <div style={{position:'relative' }} >
      <div className='sortHor spring_dashItemTitleBar'>
          <div className='sortMiddle spring_dashItemTitle'>{props.title}</div>
          <div className='spring_dashItemManuIcon hoverPointer' onClick={handleManuClick}><HiDotsVertical/></div>
      </div>
      {G_showManu && <div>
        <div className='spring_dashItemGreyBox' style={{height:`${G_ItemHeight}px`}}
        onClick={F_CloseManu}></div>
        <div className="spring_dashItemManuContainer" style={{position:'absolute'}}>
          <div className='hoverPointer' onClick={F_ClickRename}>Rename</div>
          <div className='hoverPointer' onClick={F_ClickEdit}>Edit</div>
        </div>
      </div>}
    </div>
  )
}

export default DashItemTitleBar
