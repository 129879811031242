import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { useSelector, useDispatch } from 'react-redux';
import { setDevName, toggleSkipFloor, toggleSkipArea, setSerialNo,setRegCode, chooseBuilding, chooseFloor} from './../../../../../reduxStore/actions/dev_regSensor';
import v2SensorOwnerFn from '../../../../../function/V2_SensorDevice/v2_SensorOwnerFn';
import cssFn from '../../../../../function/CSS/cssFunction';
import { toast } from 'react-toastify';
import { BsCheckLg } from 'react-icons/bs';
import devFn from '../../../../../function/device/sensorDevice';
import localStorageFn from '../../../../../function/localStorage/LocalStorageFn';
import auth from '../../../../../service/auth/AuthV2';

function V2aAddDevices(props) {
    const dispatch = useDispatch();
    const regDevice = useSelector(state=> state.dev.regSensor);
    
    const [G_IsOthersGroup, setG_IsOthersGroup] = useState(false);

    const devRef = useRef(null);
    const bdRef = useRef(null);
    const floorRef = useRef(null);
    const areaRef = useRef(null);
    const refManuLevel = useRef(0);

    useEffect(()=>{
        async function startUp(){            
            let {manuLevel} = localStorageFn.getManuToken();
            refManuLevel.current = manuLevel;
            updateLabelDisplay();
            presetSelection();

            if(props.location.focusStep===1) devRef.current.scrollIntoView();
            if(props.location.focusStep===2) bdRef.current.scrollIntoView();
            if(props.location.focusStep===3) floorRef.current.scrollIntoView();
            if(props.location.focusStep===4) areaRef.current.scrollIntoView();

        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    const presetSelection=()=>{
        let bdInfo = localStorageFn.getBdToken();
        if(refManuLevel.current >= 2){
            dispatch(chooseBuilding({buildingId:bdInfo._id,buildingName:bdInfo.name}));

            document.getElementById('Bd_inputId').innerHTML = bdInfo.name;
            cssFn.cssShowStatus(true, 'Bd_inputId', 'darkText');
            cssFn.cssShowStatus(false, 'Bd_inputId', 'blueText');
        }
        if(refManuLevel.current >= 3){
            dispatch(chooseFloor({floorId:bdInfo.floor_id,bFloorName:bdInfo.floorName}));
            
            document.getElementById('Floor_inputId').innerHTML = bdInfo.floorName;
            cssFn.cssShowStatus(true, 'Floor_inputId', 'darkText');
            cssFn.cssShowStatus(false, 'Floor_inputId', 'blueText');

            /** skip select area if floor is others ???*/
            if(bdInfo.floorName === "Others"){
                dispatch(toggleSkipArea(true));
                setG_IsOthersGroup(true);
                /** set check mark color */
            }
        }
    }

    const updateLabelDisplay=()=>{
        if(regDevice.RegCode)   document.getElementById('Rc_Input').value = regDevice.RegCode;
        if(regDevice.SerialNo)  document.getElementById('Sn_Input').value = regDevice.SerialNo;
        if(regDevice.devName)   document.getElementById('Name_Input').value = regDevice.devName;
        if(regDevice.buildingName) {
            document.getElementById('Bd_inputId').innerHTML = regDevice.buildingName;
            cssFn.cssShowStatus(regDevice.buildingName==='', 'Bd_inputId', 'greyOutText');
            cssFn.cssShowStatus(regDevice.buildingName!=='', 'Bd_inputId', 'blueText');
        } 
        if(regDevice.bFloorName){
            document.getElementById('Floor_inputId').innerHTML = regDevice.bFloorName;
            cssFn.cssShowStatus(regDevice.bFloorName==='', 'Floor_inputId', 'greyOutText');
            cssFn.cssShowStatus(regDevice.bFloorName!=='', 'Floor_inputId', 'blueText');
        } 
        if(regDevice.bAreaName){
            document.getElementById('Area_inputId').innerHTML = regDevice.bAreaName;
            cssFn.cssShowStatus(regDevice.bAreaName==='', 'Area_inputId', 'greyOutText');
            cssFn.cssShowStatus(regDevice.bAreaName!=='', 'Area_inputId', 'blueText');
        }
    }
    

    const backPrevPg=()=>{
        let pathname ="/data/bdoverview"
        if(refManuLevel.current===2){
            pathname="/data/floorinbd"
        }else if(refManuLevel.current===3){
            pathname="/data/deviceinfloor"
        }
        
        props.history.push({
            pathname,
            // _data: { _selBuildingId: this.state.selBuildingId},
        });
    }

    const getIncompleteStep=(currentStep)=>{
        if(!regDevice.SerialNo && currentStep >1 ) return 1;
        if(!regDevice.RegCode && currentStep >1 ) return 1;
        if(!regDevice.devName && currentStep >1 ) return 1;

        if(!regDevice.buildingName&& currentStep > 2) return 2;
        if(!regDevice.bSkipInserFloor && !regDevice.bFloorName&& currentStep > 3) return 3;
        if(!regDevice.bSkipInserArea && !regDevice.bAreaName&& currentStep > 4) return 4;
        return 0;
    }

    const checkInputStep=(cur_step)=>{
        let step = getIncompleteStep(cur_step);
        switch (step) {
            case 1:
                document.getElementById('snErrId').innerHTML = "Step 1: Device Info Not Complete";
                devRef.current.scrollIntoView({behavior: "smooth"});
                return false        
            case 2:
                document.getElementById('bdErrId').innerHTML = "Step 2: Building Not Selected";
                bdRef.current.scrollIntoView({behavior: "smooth"});
                return false
            case 3:
                document.getElementById('floorErrId').innerHTML = "Step 3: Group Not Selected";
                floorRef.current.scrollIntoView({behavior: "smooth"});
                return false
            case 4:
                document.getElementById('areaErrId').innerHTML = "Step 4: Subgroup Not Selected";
                floorRef.current.scrollIntoView({behavior: "smooth"});
                return false
            default:
                return true;
        }
    }

    const selBD=()=>{
        if(refManuLevel.current >=2) return
        if(!checkInputStep(2)) return         
        props.history.push({
            pathname: "/device/choosebd",
            // _data: {_selBuildingId: this.state.selBuildingId,},
        });
    }
    

    const selFloor=()=>{
        if(refManuLevel.current >=3) return
        if(!checkInputStep(3)) return 
        props.history.push({
            pathname: "/device/choosefloor",
            // _data: {_selBuildingId: this.state.selBuildingId,},
        });
    }

    const selArea=()=>{
        if(!checkInputStep(4)) return 
        props.history.push({
            pathname: "/device/choosearea",
            // _data: {_selBuildingId: this.state.selBuildingId,},
        });
    }
    
    
    const handleConfirm=async ()=>{
        try {
            /** any input invalid */
            /** get building owner_id */
            let owner_id = 0;
            if(!regDevice.bNewBuilding){     // is new building
                let bdInfo = await v2SensorOwnerFn.v2aGetBdInfoBy_id(regDevice.buildingId);
                owner_id = bdInfo[0].owner_id;
            }else{      // existing building
                let {user_id} = auth.getUserInfo();
                owner_id = user_id;
            }
            let sensorInfo = {...regDevice, bdOwner_id:owner_id};
            if(!checkInputStep(5)) return 
            
            let regStatus = await v2SensorOwnerFn.v2aRegisterSensor(sensorInfo);
            if(regStatus.errMsg) {
                // dispatch(clearBdAndArea());
                // clearLabelDisplay();
                return toast(regStatus.errMsg);
            }
            
            if(regStatus.Success){   /** Success */
                toast("Add Sensor Succes");
                props.history.push({
                    pathname: "data/bdoverview",
                    // _data: {_selBuildingId: this.state.selBuildingId,},
                });
                backPrevPg();
            }
        } catch (error) {
            toast(`Add err: ${error.message}`);
        }
    }

    // const chooseDev=()=>{
    //     props.history.push({
    //         pathname: "/device/choosedev",
    //         //_data: {_selT1Llist:tableMonList[ind]._id,},
    //     });
    // }

    const clearDevInfoErr=()=>{
        document.getElementById('snErrId').innerHTML = "";  // clear err text
    }
    const handleVerify=async ()=>{
        clearDevInfoErr();
        let SerialNo=document.getElementById("Sn_Input").value.trim();
        let RegCode=document.getElementById("Rc_Input").value.trim();
        if(SerialNo==="" || RegCode==="" ) return document.getElementById('snErrId').innerHTML = "Incomplete Serial No. or Register Code";
        let verResult = await devFn.verifySnRegCode(SerialNo, RegCode);
        if(verResult.errMsg) {
            return document.getElementById('snErrId').innerHTML = verResult.errMsg;
        }
        if(verResult.success) toast("Device valid");
        
    }
    const handleSkipFloor=()=>{
        if(refManuLevel.current >=3) return
        if(regDevice.bSkipInserFloor===false){
            dispatch(toggleSkipArea(true));    // if floor skipped, skip area also
        }
        dispatch(toggleSkipFloor(!regDevice.bSkipInserFloor));
        // toggleSkipArea
    }
    const handleSkipArea=()=>{        
        if(G_IsOthersGroup) return;

        if(regDevice.bSkipInserArea===true){
            dispatch(toggleSkipFloor(false));    // if floor skipped, skip area also
        }
        dispatch(toggleSkipArea(!regDevice.bSkipInserArea));
    }
    const handleSetSn=()=>{
        let SerialNo=document.getElementById("Sn_Input").value.trim();
        dispatch(setSerialNo(SerialNo));
            // ,setRegCode
    }
    
    const handleSetRegCode=()=>{
        let RegCode=document.getElementById("Rc_Input").value.trim();
        dispatch(setRegCode(RegCode));
    }
    const handleSetName=()=>{
        let devName = document.getElementById('Name_Input').value.trim();
        // if(devName!=='') 
        dispatch(setDevName({devName}));
    }


    return (
        <div>
            <div className="backContainer">
                <div className="backIconContainer" onClick={backPrevPg}>
                    <IoIosArrowBack/>
                </div>
                <div className='backText'>Add</div>
            </div>

            <div ref={devRef} className='spring_addDevStepTitle'>Step 1 :</div>
            <div className='spring_addDevStep'>Device</div>

            <div style={{position:'relative'}}>
                <div className='inputContainer_titleAtBox'>
                    <div className="inputTitle_titleAtBox">
                        Serial No.
                    </div>
                    <div>
                        <input 
                            onChange={clearDevInfoErr}
                            autoComplete="off" 
                            onBlur={handleSetSn}
                            id="Sn_Input"
                            className="inputText_titleAtBox blueText" 
                            maxLength={20}/>
                    </div>
                    <div className="inputTitle_titleAtBox">
                        Register code
                    </div>
                    <div>
                        <input 
                            onChange={clearDevInfoErr}
                            autoComplete="off" 
                            onBlur={handleSetRegCode}
                            id='Rc_Input'
                            className="inputText_titleAtBox blueText" 
                            maxLength={20}/>
                    </div>
                    <div className="inputTitle_titleAtBox">
                        Name
                    </div>
                    <div>
                        <input autoComplete="off" 
                            onChange={clearDevInfoErr}
                            onBlur={handleSetName}
                            id='Name_Input'
                            className="inputText_titleAtBox blueText" 
                            maxLength={80}/>
                    </div>

                    <div id = 'snErrId' className='inputErrMsg '></div>
                    <div>
                        <div className='spring_verifyButton spring_blueColor stayRight spring_MarginTop_10 hoverPointer'
                        onClick={handleVerify}>Verify</div>
                    </div>
                </div>
            </div>

            
            <div ref={bdRef} className='spring_addDevStepTitle'>Step 2 :</div>
            <div className='spring_addDevStep'>Building</div>


            <div style={{position:'relative'}}>
                <div className='inputContainer_titleAtBox'>
                    <div className="inputTitle_titleAtBox">
                        Name
                    </div>
                    <div>
                        <div 
                            id='Bd_inputId' 
                            className="inputText_titleAtBox greyOutText"
                            onClick={selBD}>
                            {"<Select building>"}
                        </div>
                    </div>
                </div>
                <div id = 'bdErrId' className='inputErrMsg errMsgPadLeft errMsgShiftTop'></div>
            </div>
            
            <div ref={floorRef} className='spring_addDevStepTitle'>Step 3 :</div>
            <div className='spring_addDevStep sortHor'>
                <div>
                    Group
                </div>
                
                {refManuLevel.current < 3 && <div className='sortHor stayRight hoverPointer'
                onClick={handleSkipFloor}>
                    <div className='spring_checkBox'>
                        {regDevice.bSkipInserFloor && <BsCheckLg/>}
                    </div>
                    <div className='spring_skipClick'>skip</div>
                </div>}
            </div>


            {!regDevice.bSkipInserFloor && <div style={{position:'relative'}}>
                <div className='inputContainer_titleAtBox'>
                    <div className="inputTitle_titleAtBox">
                        Name
                    </div>
                    <div>
                        <div 
                            id='Floor_inputId' 
                            className="inputText_titleAtBox greyOutText"
                            onClick={selFloor}>
                            {"<Select group>"}
                        </div>
                    </div>
                </div>
                <div id = 'floorErrId' className='inputErrMsg errMsgPadLeft errMsgShiftTop'></div>
            </div>}
            
            <div ref={areaRef} className='spring_addDevStepTitle'>Step 4 :</div>
            <div className='spring_addDevStep sortHor'>
                <div>
                    Subgroup
                </div>
                <div className='sortHor stayRight hoverPointer'
                onClick={handleSkipArea}>
                    <div className='spring_checkBox'>
                        {regDevice.bSkipInserArea && <BsCheckLg style={{color:`${G_IsOthersGroup?"var(--grey8)":""}`}}/>}
                    </div>
                    <div className='spring_skipClick' style={{color:`${G_IsOthersGroup?"var(--grey8)":""}`}}>skip</div>
                </div>
            </div>
            
            {/* <TpInputTitleAtBox onBlur={NameInputOnBlur} id='NameInput' errMsgid='NameErrId' title='Name' maxLen={40}/> */}
            {!regDevice.bSkipInserArea && <div style={{position:'relative'}}>
                <div className='inputContainer_titleAtBox'>
                    <div className="inputTitle_titleAtBox">
                        Name
                    </div>
                    <div>
                        <div 
                            id='Area_inputId' 
                            className="inputText_titleAtBox greyOutText"
                            onClick={selArea}>
                            {"<Select subgroup>"}
                        </div>
                    </div>
                </div>
                <div id = 'areaErrId' className='inputErrMsg errMsgPadLeft errMsgShiftTop'></div>
            </div>}
            
            <div className="buttonContainer" style={{marginTop:"50px"}}>
                <div className="roundButton" onClick={handleConfirm}>Confirm</div>
            </div>

            <div style={{marginTop:"50px"}}></div>
        </div>
    );
}

export default V2aAddDevices;


