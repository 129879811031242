import http from "../httpService";

async function dataDownloadGenTicket(downloadInfo) {  
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/genticket";
    let body = downloadInfo;
    return await http.post(url, body);
}

async function getNotDownloadedTicket(user_id, bd_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/getnotdownloadedtickets";
    let body = { user_id, bd_id };
    return await http.post(url, body);  
}

async function getDownloadedTicket_lastN(user_id, bd_id, nNumber) {  
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/getdownloadedticketslastn";
    let body = { user_id, bd_id, nNumber };
    return await http.post(url, body);  
}

async function dataDownloadCsvZip(ticketInfo) {
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/csvzip";
    let body = { ticketInfo };
    return await http.post(url, body, {
        responseType: 'blob',
    });  
}

async function dataDownloadPrepFile(downloadInfo) {  
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/prepfile";
    let body = downloadInfo;
    return await http.post(url, body);  
}

async function dataDownloadMultiCsv(pathList, zipFileName) {
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/multicsv";
    let body = { pathList, zipFileName };
    return await http.post(url, body, {
        responseType: 'blob',
    });  
}

async function clearDownloadHistory(user_id, bd_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL_DATADOWNLOAD+"/download/clearcache";
    let body = { user_id, bd_id };
    return await http.post(url, body);  
}

const expModule = {  
    dataDownloadGenTicket,
    getNotDownloadedTicket,
    getDownloadedTicket_lastN,
    dataDownloadCsvZip,
    dataDownloadPrepFile,
    dataDownloadMultiCsv,
    clearDownloadHistory
};
export default expModule;