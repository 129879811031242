import React from 'react';
import { ImCheckboxUnchecked } from "react-icons/im";
import { AiOutlinePlusSquare } from "react-icons/ai";
import TpSmallInputTitleAtBox from './../../template/inputField/SmallInput_titleAtBox';


function SingleSensorParaSlot(props) {
    return (
        <div className='sortHor'>
            <div><ImCheckboxUnchecked/></div>
            <div>
                <div className='sortHor'>
                    <div>
                        Temperature
                    </div>
                    <div>
                        *C
                    </div>
                    <AiOutlinePlusSquare/>
                </div>
                <div className='sortHor'>
                    <div>
                        <TpSmallInputTitleAtBox id='MinInput' errMsgid='MinInputErrId' title='Min' maxLen={40} type='number'/>
                    </div>
                    <div>
                        <TpSmallInputTitleAtBox id='MaxInput' errMsgid='MaxInputErrId' title='Max' maxLen={40} type='number'/>
                    </div>
                    <div>
                        <select className='chartColorLine_2'>
                            <option className='chartColorLine_1'>Hello</option>
                            <option className='chartColorLine_2'>Color 2</option>
                            {/* <option><div className='chartColorLine_2'></div></option> */}
                        </select>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SingleSensorParaSlot;