import http from "../httpService";

let url = process.env.REACT_APP_PUBLIC_URL + "/user/register/validation";

export default async function regUser_V2(user) {
  //   console.log(user);
  const body = {
    // username: user.username,
    email: user.email,
    password: user.password,
  };

  return await http.post(url, body);
}
